import React, {
    ChangeEvent,
    FormEvent,
    useCallback,
    useEffect,
    useState,
} from "react";

import { FeatureName } from "@accurx/auth";
import {
    Button,
    Card,
    Feedback,
    FormFieldWrapper,
    Option,
    Select,
    Spinner,
    Switch,
    Text,
    TextareaAutosize,
} from "@accurx/design";
import { shallowEqual, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useFeatureFlag } from "reduxQuarantine/useFeatureFlag";

import { useSafeAsync } from "api/Api.utils";
import {
    ITemplateAttachmentUploadRequest,
    MessageTemplate,
    MessageTemplateOwner,
    MessageTemplateType,
} from "api/FlemingDtos";
import * as PatientMessagingServerApi from "api/PatientMessagingServer/PatientMessagingServerApi";
import { UserflowEvent, trackUserflowEvent } from "app/account/Userflow";
import { AnalyticsMapper, FlemingAnalyticsTracker } from "app/analytics";
import {
    FlemingAnalyticsTemplateType,
    MessageTemplatePageOrigin,
    TemplateSaveButtonProps,
} from "app/analytics/FlemingAnalytics";
import {
    FILE_DATA_RECEIVED,
    actionCreators as fileUploadActionCreators,
    actionCreators as filesActions,
} from "app/fileUpload/FileUploadActions";
import FileUpload from "app/fileUpload/FileUploadContainer";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { TemplateInList } from "app/workspaceConversations/components/MessageTemplates/MessageTemplates.types";
import { generateSignature } from "shared/MessageHelper";
import { OrganisationHelper } from "shared/OrganisationHelper";
import { useAppSelector, useIsFeatureEnabled } from "store/hooks";

import { actionCreators } from "../MessageTemplatesActions";
import {
    createPreviewUrl,
    getAreFormFieldsValid,
    mapCategoryOptionToMessageTemplateType,
    mapMessageTemplateManagementViewToTemplateInList,
    mapMessageTemplateOwnerToString,
    mapMessageTemplateTypeToOption,
    mapMessageTemplateTypeToOptionValue,
    mapMessageTemplateTypeToString,
} from "../MessageTemplatesHelper";
import {
    ATTACHMENT_ACCEPTS,
    BODY_MAX_LENGTH,
    MAX_FILES_CAN_BE_ATTACHED,
    MAX_FILES_SIZE_IN_MB,
    MESSAGE_TEMPLATE_FORM_TITLE_ID,
    MessageTemplateCategorySelectValues,
    TITLE_MAX_LENGTH,
} from "./ManageMessageTemplateConstants";

type ValidationResult =
    | { isValid: true; errors?: undefined }
    | { isValid: false; errors: string[] };

const createCategoryOptions = (): Option[] => {
    return [
        {
            label: "-- Select --",
            value: mapMessageTemplateTypeToOptionValue(null),
        },
        {
            label: "Message Patient",
            value: mapMessageTemplateTypeToOptionValue(MessageTemplateType.Sms),
        },
        {
            label: "Video Consult",
            value: mapMessageTemplateTypeToOptionValue(
                MessageTemplateType.VideoConsult,
            ),
        },
    ];
};

export type ManageMessageTemplateProps = {
    template: MessageTemplate;
    onCancelAction: () => void;
    onSuccessAction: () => void;
};

export const ManageMessageTemplate = ({
    template,
    onCancelAction,
    onSuccessAction,
}: ManageMessageTemplateProps): JSX.Element => {
    const safeAsync = useSafeAsync();
    const dispatch = useDispatch();
    const categoryOptions = createCategoryOptions();

    const organisationId = useAppSelector(
        ({ account }) => account.selectedOrganisation,
    );
    const organisationName = useAppSelector(({ account }) =>
        OrganisationHelper.getOrganisationName(account),
    );
    const user = useAppSelector(({ account }) => account.user);
    const loggedInProps = useFlemingLoggedInAnalytics();

    const isFlexibleWorkspacesEnabled = useFeatureFlag(
        FeatureName.FlexibleWorkspace,
    );

    const enableOrgWideTemplates = useIsFeatureEnabled(
        FeatureName.WebOrgWideTemplates,
    );
    const webBatchEnabled = useIsFeatureEnabled(FeatureName.WebBatchMessaging);

    // Analytics
    const accountState = useAppSelector(({ account }) => account, shallowEqual);
    const lastMessageTemplateActionLocation = useAppSelector(
        ({ messageTemplates }) =>
            messageTemplates.lastMessageTemplateActionLocation,
    );
    const productSelection = useAppSelector(
        ({ selectProduct }) => selectProduct.productSelection,
    );
    const filesUpload = useAppSelector(
        ({ fileUpload }) => fileUpload,
        shallowEqual,
    );

    const [formDisabled, setFormDisabled] = useState(false);
    const [actionInProgress, setActionInProgress] = useState(false);
    const [canSubmit, setCanSubmit] = useState(true);

    const [type, setType] = useState(MessageTemplateType.Sms);

    const [body, setBody] = useState("");
    const [bodyErrorMessage, setBodyErrorMessage] = useState("");

    const [title, setTitle] = useState("");
    const [titleErrorMessage, setTitleErrorMessage] = useState("");

    const [category, setCategory] = useState<Option>(
        mapMessageTemplateTypeToOption(null, categoryOptions),
    );
    const [categoryErrorMessage, setCategoryErrorMessage] = useState("");

    // We disable the category dropdown if a category is provided to the modal
    const isCategoryForcedDisabled = template.type !== null;

    const [shareTemplateWithOrg, setShareTemplateWithOrg] = useState(false);

    const [isDefault, setIsDefault] = useState(false);

    // Sometimes we want the setAsDefault toggle to be disabled (i.e. when editing a video org-wide template)
    const [isDefaultForcedDisabled, setIsDefaultForcedDisabled] =
        useState(false);

    const userIsApproved = useAppSelector(({ account }) => {
        return OrganisationHelper.getIsApprovedOrgUser(account);
    });

    // Reset file upload on form unmount
    useEffect(() => {
        return () => {
            dispatch(filesActions.resetFileUpload());
        };
    }, [dispatch]);

    // Set all the fields based on existing (or partially existing) template info being passed in
    useEffect(() => {
        if (template !== undefined) {
            setType(template.type || MessageTemplateType.Sms);
            setIsDefault(template.isDefault || false);
            setBody(template.body);
            setTitle(template.title);
            setCategory(
                mapMessageTemplateTypeToOption(template.type, categoryOptions),
            );
            setShareTemplateWithOrg(
                template.owner === MessageTemplateOwner.Organisation,
            );
            dispatch({
                type: FILE_DATA_RECEIVED,
                files: (template.attachedDocuments || []).map(
                    ({ documentId, fileSize, fileName }) => ({
                        id: documentId,
                        file: {
                            name: fileName,
                            size: fileSize,
                            previewUrl: createPreviewUrl({
                                orgId: organisationId,
                                documentId,
                                templateId: template.id,
                            }),
                        },
                    }),
                ),
            });
        }
        // TODO: Address infinite loop caused by adding categoryOptions as a dependency
        // eslint-disable-next-line
    }, [template, organisationId, dispatch]);

    // Ensure default toggle is disabled in certain circumstances
    useEffect(() => {
        const vcAndShareTemplateWithOrg =
            category.value ===
                MessageTemplateCategorySelectValues.VideoConsult &&
            shareTemplateWithOrg;

        // if category is video and org-wide is true, set default to false and disable the toggle
        if (vcAndShareTemplateWithOrg) {
            setIsDefault(false);
            setIsDefaultForcedDisabled(true);
        } else {
            setIsDefaultForcedDisabled(false);
        }
    }, [category.value, shareTemplateWithOrg]);

    const handleTitleChange = (e: ChangeEvent<HTMLInputElement>): void => {
        setTitle(e.target.value);
        setTitleErrorMessage("");

        if (
            !canSubmit &&
            getAreFormFieldsValid([bodyErrorMessage, categoryErrorMessage])
        ) {
            setCanSubmit(true);
        }
    };

    const validateTitleMinLength = (titleValue: string): ValidationResult => {
        const isValid = titleValue.trim().length > 0;
        if (!isValid) {
            const error = "Please add a title";
            setTitleErrorMessage(error);
            return { isValid, errors: [error] };
        }
        setTitleErrorMessage("");
        return { isValid };
    };

    // FYI selected needs to appear as a potential option array because of
    // typing coming from design library. But we know this is a single select
    // with one option selected at a time.
    const handleCategoryChange = (selected: Option | Option[]): void => {
        if (!Array.isArray(selected)) {
            setCategory(selected);
            setCategoryErrorMessage("");

            if (
                !canSubmit &&
                getAreFormFieldsValid([titleErrorMessage, bodyErrorMessage])
            ) {
                setCanSubmit(true);
            }
        }
    };

    const validateCategorySelection = (
        categorySelected: Option,
    ): ValidationResult => {
        const isValid =
            categorySelected.value !==
            MessageTemplateCategorySelectValues.Unselected;

        if (!isValid) {
            const error = "Please select a category";
            setCategoryErrorMessage(error);
            return { isValid, errors: [error] };
        }
        setCategoryErrorMessage("");
        return { isValid };
    };

    const handleBodyChange = (e: ChangeEvent<HTMLTextAreaElement>): void => {
        const bodyValue = e.target.value;
        setBody(bodyValue);

        const isValid = validateBodyMaxLength(bodyValue);

        // only change submit status if the validation changes
        if (
            canSubmit !== isValid.isValid &&
            getAreFormFieldsValid([titleErrorMessage, categoryErrorMessage])
        ) {
            setCanSubmit(!canSubmit);
        }
    };

    const validateBodyLength = (bodyValue: string): ValidationResult => {
        if (bodyValue.trim().length === 0) {
            const error = "Please add a body";
            setBodyErrorMessage(error);
            return { isValid: false, errors: [error] };
        }

        if (bodyValue.length > BODY_MAX_LENGTH) {
            const error = `Sorry, the message must be equal to or less than ${BODY_MAX_LENGTH} characters (${bodyValue.length})`;
            setBodyErrorMessage(error);
            return { isValid: false, errors: [error] };
        }
        setBodyErrorMessage("");
        return { isValid: true };
    };

    const validateBodyMaxLength = (bodyValue: string): ValidationResult => {
        const isValid = bodyValue.length <= BODY_MAX_LENGTH;
        if (!isValid) {
            const error = `Sorry, the message must be equal to or less than ${BODY_MAX_LENGTH} characters (${bodyValue.length})`;
            setBodyErrorMessage(error);
            return { isValid, errors: [error] };
        }
        setBodyErrorMessage("");
        return { isValid };
    };

    const validateForm = useCallback((): ValidationResult => {
        const validationResults = [
            validateBodyLength(body),
            validateTitleMinLength(title),
            validateCategorySelection(category),
        ];

        const isValid = validationResults.every(({ isValid }) => isValid);

        return isValid
            ? { isValid }
            : {
                  isValid,
                  errors: validationResults
                      .map(({ errors }) => errors ?? [])
                      .flat(),
              };
    }, [body, title, category]);

    const handleShareTemplateWithOrg = (): void => {
        setShareTemplateWithOrg((prevState) => !prevState);
    };

    const handleSetDefaultToggleChange = (): void => {
        setIsDefault((prevState) => !prevState);
    };

    const handleConfirmCreateOrEdit = async (): Promise<void> => {
        const formValidationResult = validateForm();
        const isFormValid = formValidationResult.isValid;
        const templateType = mapCategoryOptionToMessageTemplateType(category);

        trackConfirmCreateOrEditButtonClick(templateType, formValidationResult);

        if (!isFormValid) {
            setCanSubmit(false);
            return;
        }

        const ownerSubmitValue = shareTemplateWithOrg
            ? MessageTemplateOwner.Organisation
            : MessageTemplateOwner.User;

        // Only video consult templates can be a default
        const isDefaultValue =
            category.value === MessageTemplateCategorySelectValues.VideoConsult
                ? isDefault
                : false;

        const newTemplate = {
            id: template.id,
            title,
            body,
            isDefault: isDefaultValue,
            type: templateType,
            owner: ownerSubmitValue,
            attachedDocumentIds: filesUpload.files.map(({ id }) => id),
        };

        setActionInProgress(true);
        setFormDisabled(true);

        const { result, success } = await safeAsync(
            PatientMessagingServerApi.createUpdateMessageTemplate({
                organisationId: organisationId,
                messageTemplate: {
                    ...newTemplate,
                    id: template.id?.toString(),
                    type: mapMessageTemplateTypeToString(newTemplate.type),
                    owner: mapMessageTemplateOwnerToString(ownerSubmitValue),
                    attachedDocumentIds: newTemplate.attachedDocumentIds.map(
                        (id) => parseInt(id, 10),
                    ),
                    allowReplyByDefault: false,
                },
            }),
        );

        const analyticsParams = AnalyticsMapper.getMessageTemplateProps(
            newTemplate,
            accountState,
            lastMessageTemplateActionLocation,
            productSelection,
        );

        if (success && result !== null) {
            dispatch(
                actionCreators.getTemplatesForManagementView(
                    {
                        organisationId,
                    },
                    webBatchEnabled,
                    true,
                ),
            );
            const updatedTemplate: TemplateInList =
                mapMessageTemplateManagementViewToTemplateInList(
                    result,
                    organisationId,
                );

            dispatch(
                actionCreators.editOrCreateMessageTemplateSuccess(
                    updatedTemplate,
                ),
            );
            toast(
                <Feedback colour="success" title="Template saved successfully">
                    <Text skinny>You can now use this template</Text>
                </Feedback>,
            );

            FlemingAnalyticsTracker.trackMessageTemplateCreateSuccess(
                analyticsParams,
            );
            trackUserflowEvent(UserflowEvent.MESSAGE_TEMPLATE_CREATED);

            setActionInProgress(false);
            onSuccessAction();
        } else {
            toast(
                <Feedback
                    colour="error"
                    title="Sorry your template failed to save! Please try again"
                />,
            );

            FlemingAnalyticsTracker.trackMessageTemplateCreateFailure(
                analyticsParams,
            );

            setFormDisabled(false);
            setActionInProgress(false);
        }
    };

    const trackConfirmCreateOrEditButtonClick = (
        templateType: MessageTemplateType | null,
        validationResult: ValidationResult,
    ) => {
        const templateLevel = shareTemplateWithOrg ? "Organisation" : "User";

        const templateCreateConfirmButtonClickProps: TemplateSaveButtonProps = {
            ...loggedInProps,
            pageOrigin: getPageOriginForAnalytics(),
            templateName: title,
            templateType: mapMessageTemplateTypeToAnalytics(templateType),
            countAttachment: filesUpload.files.length,
            ...(!validationResult.isValid
                ? {
                      hasError: true,
                      errorReason: validationResult.errors,
                  }
                : { hasError: false }),
            productOrigin: "PatientMessage",
            templateLevel,
            countPatientDetail: 0,
        };

        FlemingAnalyticsTracker.trackTemplateCreateConfirmButtonClick(
            templateCreateConfirmButtonClickProps,
        );
    };

    const getPageOriginForAnalytics = (): MessageTemplatePageOrigin => {
        switch (lastMessageTemplateActionLocation) {
            case "product-page":
            case "product-modal":
                // All usages of this dialog have been removed apart from within patient lists
                return "PatientListPage";
            default:
                return "ManageTemplatePage";
        }
    };

    const mapMessageTemplateTypeToAnalytics = (
        type: MessageTemplateType | null,
    ): FlemingAnalyticsTemplateType | null => {
        switch (type) {
            case MessageTemplateType.Sms:
                return "sms";
            case MessageTemplateType.VideoConsult:
                return "video-consult";
            case null:
            default:
                return null;
        }
    };

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        // We have to stop propagation as this form
        // can be embedded into other forms (i.e. send message form)
        e.stopPropagation();

        handleConfirmCreateOrEdit();
    };

    const renderHeader = (): JSX.Element => {
        return (
            <Text
                variant="subtitle"
                as="h1"
                skinny
                props={{ id: MESSAGE_TEMPLATE_FORM_TITLE_ID }}
            >
                Create a new template
            </Text>
        );
    };

    const renderFooter = (): JSX.Element => {
        return (
            <div className="d-flex align-items-center justify-content-between">
                <div>{actionInProgress && <Spinner dimension="small" />}</div>
                <div className="d-flex">
                    <Button
                        text="Cancel"
                        theme="secondary"
                        onClick={onCancelAction}
                        className="mr-2"
                        disabled={formDisabled}
                        type="button"
                    />
                    <Button
                        type="submit"
                        text="Save"
                        theme="primary"
                        disabled={formDisabled || !canSubmit}
                    />
                </div>
            </div>
        );
    };

    const renderBody = (): JSX.Element => {
        return (
            <>
                <div className="form-group">
                    <FormFieldWrapper
                        label="Title"
                        labelProps={{
                            htmlFor: "message-template-title",
                        }}
                        errors={
                            titleErrorMessage ? [titleErrorMessage] : undefined
                        }
                    >
                        <input
                            id="message-template-title"
                            className="form-control"
                            type="text"
                            placeholder="e.g. Referral template"
                            value={title}
                            disabled={formDisabled}
                            maxLength={TITLE_MAX_LENGTH}
                            onChange={handleTitleChange}
                        />
                    </FormFieldWrapper>
                </div>
                <div className="form-group">
                    {/* Category is the MessageTemplateType */}
                    <FormFieldWrapper
                        label="Category"
                        labelProps={{
                            htmlFor: "message-template-category",
                        }}
                        errors={
                            categoryErrorMessage
                                ? [categoryErrorMessage]
                                : undefined
                        }
                    >
                        <Select
                            data-testid="message-template-category"
                            id="message-template-category"
                            options={categoryOptions}
                            initSelectedValues={mapMessageTemplateTypeToOption(
                                template.type,
                                categoryOptions,
                            )}
                            onChangeHandler={handleCategoryChange}
                            disabled={formDisabled || isCategoryForcedDisabled}
                        />
                    </FormFieldWrapper>
                </div>
                <div className="form-group">
                    <FormFieldWrapper
                        label="Body"
                        labelProps={{
                            htmlFor: "message-template-body",
                        }}
                        errors={
                            bodyErrorMessage ? [bodyErrorMessage] : undefined
                        }
                    >
                        <>
                            <Text>Dear Miss Patient,</Text>
                            {/*We are not adding a maxLength attribute on the textarea as we don't want to limit the characters added to the field, but we are still doing maxLength field validation onChange and on form submit*/}
                            <TextareaAutosize
                                id="message-template-body"
                                className="form-control"
                                placeholder="Add the content of your template by typing here..."
                                value={body}
                                disabled={formDisabled}
                                minRows={6}
                                onChange={handleBodyChange}
                            />
                            <Text
                                variant="note"
                                props={{ className: "float-right" }}
                            >
                                {`${
                                    BODY_MAX_LENGTH - body.length
                                }/${BODY_MAX_LENGTH}`}
                            </Text>
                            {user !== null && (
                                <Text>{generateSignature(user)}</Text>
                            )}
                            {type === MessageTemplateType.VideoConsult && (
                                <Text skinny>
                                    To join, please follow this link: [link will
                                    appear here]
                                </Text>
                            )}
                            <Text>{organisationName}</Text>
                            {enableOrgWideTemplates &&
                                userIsApproved &&
                                !formDisabled && (
                                    <Switch
                                        controlled
                                        checked={shareTemplateWithOrg}
                                        onChange={handleShareTemplateWithOrg}
                                        labelText={`Share template with your ${
                                            isFlexibleWorkspacesEnabled
                                                ? "workspace"
                                                : "organisation"
                                        }`}
                                        disabled={formDisabled}
                                        date-userflow-id="create-template-share-toggle"
                                    />
                                )}
                        </>
                    </FormFieldWrapper>
                </div>
                {category.value ===
                    MessageTemplateCategorySelectValues.VideoConsult && (
                    <div className="form-group">
                        <Switch
                            controlled
                            checked={isDefault}
                            onChange={handleSetDefaultToggleChange}
                            labelText="Set as default template"
                            disabled={formDisabled || isDefaultForcedDisabled}
                        />
                    </div>
                )}
                <div className="form-group">
                    <FileUpload
                        maxFiles={MAX_FILES_CAN_BE_ATTACHED}
                        maxSize={MAX_FILES_SIZE_IN_MB}
                        showNewBadge
                        accept={ATTACHMENT_ACCEPTS}
                        noDrag
                        organisationId={organisationId}
                        onUploadFile={(
                            request: ITemplateAttachmentUploadRequest,
                        ) =>
                            dispatch(
                                fileUploadActionCreators.uploadTemplateAttachment(
                                    request,
                                ),
                            )
                        }
                        onFileRemove={(fileId: string) =>
                            dispatch(
                                fileUploadActionCreators.removeFile(fileId),
                            )
                        }
                        data-userflow-id="create-template-attach-button"
                    />
                    <Text>
                        You can now attach a file with max size{" "}
                        {MAX_FILES_SIZE_IN_MB}MB.
                    </Text>
                    <Feedback
                        colour="information"
                        title="Check you've selected the right attachment"
                    >
                        <Text skinny>
                            This template can be used to message multiple
                            patients. Please do not include attachments which
                            could contain sensitive patient information.
                        </Text>
                    </Feedback>
                </div>
            </>
        );
    };

    return (
        <div>
            <form
                onSubmit={handleSubmit}
                id="message-templates-form"
                aria-labelledby={MESSAGE_TEMPLATE_FORM_TITLE_ID}
            >
                <Card
                    spacing={2}
                    header={renderHeader()}
                    footer={renderFooter()}
                >
                    {renderBody()}
                </Card>
            </form>
        </div>
    );
};
