import { RegisterAccountProductType } from "@accurx/api/account";
import { Text } from "@accurx/design";
import { Redirect, useLocation } from "react-router";
import { Link } from "react-router-dom";

import { formatSearch } from "app/account/Account.helper";
import { LOGIN_LAYOUT_CONSTANTS } from "app/account/LogIn/LogIn.constants";
import {
    InnerStackPanel,
    OuterStackPanel,
} from "app/account/LogIn/LoginStackPanel";
import { LoginPageLayout } from "app/layout/LoginPageLayout/LoginPageLayout";
import { ROUTES } from "shared/Routes";
import { useAppSelector } from "store/hooks";

import { LoginForm } from "../LoginForm";
import { StyledStackPanel } from "./OrgLogin.styles";

export const OrgLogin = (): JSX.Element => {
    const location = useLocation();
    const isLoggedIn = useAppSelector(({ account }) => account.isLoggedIn);

    // Set default product in case none was present
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("product", `${RegisterAccountProductType.Chain}`);

    const searchPreLogin = formatSearch({
        search: searchParams.toString(),
        paramsToRemove: ["Email"],
    });

    const next = searchParams.get("next");
    if (!next) {
        window.sessionStorage.setItem("isManageOrgRedirect", "true");
        // Set the default location if it's not already set
        searchParams.append("next", ROUTES.home);
    }

    if (isLoggedIn) {
        return (
            <Redirect
                to={{
                    ...location,
                    pathname: ROUTES.postlogin,
                    search: searchParams.toString(),
                }}
            />
        );
    }

    return (
        <LoginPageLayout {...LOGIN_LAYOUT_CONSTANTS.organisations}>
            <OuterStackPanel>
                <StyledStackPanel
                    orientation="vertical"
                    gutter={2}
                    horizontalContentAlignment="center"
                >
                    <Text variant="title" as="h1" skinny>
                        Welcome back
                    </Text>
                    <InnerStackPanel horizontalContentAlignment="stretch">
                        <LoginForm
                            product={RegisterAccountProductType.Chain}
                            onLoginSuccess={() => {
                                window.location.href = `${
                                    ROUTES.postlogin
                                }${formatSearch({
                                    search: searchParams.toString(),
                                })}`;
                            }}
                        />
                    </InnerStackPanel>
                    <Text variant="label" skinny>
                        New to Accurx?{" "}
                        <Link to={`${ROUTES.register}${searchPreLogin}`}>
                            Create an account
                        </Link>
                    </Text>
                </StyledStackPanel>
            </OuterStackPanel>
        </LoginPageLayout>
    );
};
