import { Log } from "@accurx/shared";
import { Scope } from "@sentry/types";
import { AnyAction } from "redux";

import { OrganisationHelper } from "shared/OrganisationHelper";

export type AllowedActionFields = Record<AnyAction["type"], string[]>;

/**
 * Transform the action before sending it as a breadcrumb. Use this to
 * manage which actions to send, and handle any private data from actions
 * before it is sent to Sentry.
 *
 * Return null to prevent the action being sent.
 */
const createActionTransformer =
    (allowedActionFields: AllowedActionFields) => (action: AnyAction) => {
        // clone the object so that we are not mutating an action
        const clonedAction: AnyAction = JSON.parse(JSON.stringify(action));

        // actions supplied in allowedActionFields are sent to Sentry in their
        // entirety, but with all string masked. If they have fields listed
        // in their allowed fields list, those will not be masked, all others
        // will
        if (Object.keys(allowedActionFields).includes(clonedAction.type)) {
            const fields = allowedActionFields[clonedAction.type];
            if (fields) {
                maskActionData(clonedAction, fields);
            }
            return clonedAction;
        }

        return { type: clonedAction.type };
    };

/**
 * Called on every state update, configure the Sentry Scope using any
 * relevant/useful data from redux state.
 */
const configureScopeWithState = (scope: Scope, state: ApplicationState) => {
    const user = state.account.user;

    if (user !== null) {
        Log.addGlobalProperties({
            userId: user.accuRxUserId,
            workspaceId: `${state.account.selectedOrganisation}`,
        });
        scope.setTag("user.accuRxUserId", user.accuRxUserId);
        scope.setTag("user.organisation", state.account.selectedOrganisation);
        scope.setTag("user.is2FAed", user.is2FAed);

        const selectedOrg = OrganisationHelper.getOrganisation(state.account);

        if (selectedOrg !== null) {
            scope.setTag(
                "user.isApprovedUser",
                selectedOrg.settings.isApprovedUser,
            );
        }
    }
};

/**
 * Transforms the state before attaching it to a Sentry event. Ensure any
 * PII data is removed/masked before sending sending state to Sentry.
 *
 * Return null to prevent attaching the state.
 */
const stateTransformer = (state: ApplicationState) => {
    // not sending state for now. we would need to employ similar approach
    // to specifying state fields to allow, and then mask the rest.
    return null;
};

export { createActionTransformer, configureScopeWithState, stateTransformer };

/**
 * This function recursively checks every property in an action, and when it
 * encounters a string, checks if it needs masking, and does so if needed.
 * String separators are retained (e.g. forward-slash and hyphen etc).
 */

function maskActionData(
    obj: AnyAction,
    allowedKeys: string[],
): Record<string, unknown> {
    // replace any character that is not a separator (/ - <space> : |)
    const charsToReplaceRegex = /[^/-\s:|]/g;

    for (const key in obj) {
        if (!obj[key]) continue;

        // this covers arrays as well, as typeof [] === "object". yay javascript!
        if (typeof obj[key] === "object") {
            maskActionData(obj[key], allowedKeys);
        }

        if (typeof obj[key] === "string") {
            if (key !== "type" && allowedKeys.includes(key) === false) {
                const value = `${obj[key]}`;
                obj[key] = value.replace(charsToReplaceRegex, "*");
            }
        }
    }

    return obj;
}
