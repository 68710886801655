import * as UI from "@accurx/design";
import { SlotAvailabilityOption } from "domains/self-book/utils/types";

import {
    ErrorPositioningWrapper,
    StyledSearchSelect,
} from "../SelfbookConfigurationForm.styles";

type SlotTypeProps = {
    options: SlotAvailabilityOption[];
    initialOption?: SlotAvailabilityOption;
    onChange: (selected: SlotAvailabilityOption) => void;
    errors: string[] | undefined;
    showLoading?: boolean;
};

export const SlotType = ({
    options,
    initialOption,
    onChange,
    errors,
    showLoading,
}: SlotTypeProps) => {
    const label = "Slot type";
    const id = "slot-type-filter";
    return (
        <UI.Ds.Box py="0.75rem">
            <StyledSearchSelect>
                <UI.FormFieldWrapper
                    label={
                        <UI.Text variant="note" skinny>
                            {label}
                        </UI.Text>
                    }
                    labelProps={{ htmlFor: id }}
                    errors={errors?.map((item) => {
                        return (
                            <ErrorPositioningWrapper>
                                <UI.Text as="span" variant="preview">
                                    {item}
                                </UI.Text>
                            </ErrorPositioningWrapper>
                        );
                    })}
                >
                    <UI.SearchSelect
                        dimension="medium"
                        id={id}
                        label={label}
                        key={`${id}-${
                            initialOption?.label ? initialOption.label : "none"
                        }`}
                        options={options}
                        // Types here are inaccurate, returns Option for single select
                        onChangeHandler={(selected) =>
                            onChange(selected as SlotAvailabilityOption)
                        }
                        initSelectedValues={initialOption}
                        error={errors && errors.length > 0}
                        zIndex={2}
                        showLoading={showLoading}
                    />
                </UI.FormFieldWrapper>
            </StyledSearchSelect>
        </UI.Ds.Box>
    );
};
