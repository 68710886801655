import { useContext } from "react";

import { RealtimeContext } from "domains/realtime/components/RealtimeContext";
import { RealTimeMeta } from "domains/realtime/types";

export const useRealtimeMeta = (): { getMeta: () => RealTimeMeta } => {
    const { getMeta } = useContext(RealtimeContext);

    return { getMeta };
};
