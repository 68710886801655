import React from "react";

import { Text } from "@accurx/design";
import { MAIN_CONTENT_ID } from "layouts/UnifiedLayout/UnifiedLayout";

import { StyledSkipToContentLink } from "./SkipToContentLink.styles";

const SkipToContentLink = (): JSX.Element => {
    return (
        <StyledSkipToContentLink
            href={`${window.location.pathname}${window.location.search}#${MAIN_CONTENT_ID}`}
            id="skipToContent"
        >
            <Text skinny>Skip to content</Text>
        </StyledSkipToContentLink>
    );
};

export { SkipToContentLink };
