import { useRef } from "react";

import { BadgeLevel } from "@accurx/api/ticket";
import type { Conversation, ConversationItem } from "@accurx/concierge/types";
import * as UI from "@accurx/design";
import { Ds } from "@accurx/design";
import type { BadgeProps, IconProps } from "@accurx/design/dist/ds";
import { WithTooltip } from "domains/inbox/components/Tooltip/WithTooltip";
import { useIsEllipsisActive } from "domains/inbox/hooks/util/useIsEllipsisActive";

import { StyledBadge, StyledBadgeText } from "./StatusBadge.styles";

const sharedBadgeProps = {
    radius: "round",
    className: "conversation-preview-badge",
} as const;

export type StatusBadgeProps = {
    status: Conversation["status"];
    conversationItem: ConversationItem;
};

function getGenericNoteSRTextFromBadgeText(badgeText: string) {
    switch (badgeText) {
        case "Booked":
            return "self-book appointment booked";
        case "Did not book":
            return "did not book self-book appointment";
        case "Failed":
        default:
            return "failed to book self-book appointment";
    }
}

export const StatusBadge = ({ status, conversationItem }: StatusBadgeProps) => {
    const textRef = useRef<HTMLElement>(null);
    const isEllipsisActive = useIsEllipsisActive(textRef.current);

    const contentType = conversationItem.contentType;
    const deliveryStatus =
        "deliveryStatus" in conversationItem
            ? conversationItem.deliveryStatus
            : undefined;

    if (status === "Done") {
        return (
            <>
                <UI.VisuallyHidden as="span">
                    done
                    {", "}
                </UI.VisuallyHidden>
                <WithTooltip
                    isEnabled={isEllipsisActive}
                    content={
                        <UI.Text skinny variant="note" colour="white">
                            {"Done"}
                        </UI.Text>
                    }
                    props={{
                        root: { $withEllipsis: true },
                        content: {
                            align: "start",
                            side: "bottom",
                        },
                    }}
                >
                    <StyledBadge
                        color="green"
                        aria-hidden="true"
                        {...sharedBadgeProps}
                    >
                        <Ds.Icon name="Success" appearance="solid" />
                        <StyledBadgeText ref={textRef}>
                            {"Done"}
                        </StyledBadgeText>
                    </StyledBadge>
                </WithTooltip>
            </>
        );
    }

    if (
        contentType === "FailedDeliveryReceiptLink" ||
        contentType === "FallbackFailedLink" ||
        deliveryStatus === "Failed"
    ) {
        return (
            <>
                <UI.VisuallyHidden as="span">
                    failed to deliver
                    {", "}
                </UI.VisuallyHidden>
                <WithTooltip
                    isEnabled={isEllipsisActive}
                    content={
                        <UI.Text skinny variant="note" colour="white">
                            {"Failed"}
                        </UI.Text>
                    }
                    props={{
                        content: {
                            align: "start",
                            side: "bottom",
                        },
                    }}
                >
                    <StyledBadge
                        color="red"
                        aria-hidden="true"
                        {...sharedBadgeProps}
                    >
                        <Ds.Icon name="Warning" appearance="solid" />
                        <StyledBadgeText ref={textRef}>
                            {"Failed"}
                        </StyledBadgeText>
                    </StyledBadge>
                </WithTooltip>
            </>
        );
    }

    if (
        contentType === "PatientSingleResponse" &&
        conversationItem.sections.length === 0
    ) {
        return (
            <>
                <UI.VisuallyHidden as="span">
                    patient did not respond
                    {", "}
                </UI.VisuallyHidden>
                <WithTooltip
                    isEnabled={isEllipsisActive}
                    content={
                        <UI.Text skinny variant="note" colour="white">
                            {"Did not respond"}
                        </UI.Text>
                    }
                    props={{
                        content: {
                            align: "start",
                            side: "bottom",
                        },
                    }}
                >
                    <StyledBadge
                        color="yellow"
                        aria-hidden="true"
                        {...sharedBadgeProps}
                    >
                        <Ds.Icon name="Warning" appearance="solid" />
                        <StyledBadgeText ref={textRef}>
                            {"Did not respond"}
                        </StyledBadgeText>
                    </StyledBadge>
                </WithTooltip>
            </>
        );
    }

    if (contentType === "GenericNote") {
        const badge = conversationItem.badge;
        const badgeColorMap: Record<BadgeLevel, BadgeProps["color"]> = {
            [BadgeLevel.Information]: "green",
            [BadgeLevel.Warning]: "yellow",
            [BadgeLevel.Alarm]: "red",
        } as const;
        const badgeIconMap: Record<BadgeLevel, IconProps["name"]> = {
            [BadgeLevel.Information]: "Success",
            [BadgeLevel.Warning]: "Warning",
            [BadgeLevel.Alarm]: "Failed",
        } as const;

        if (!badge) return null;

        return (
            <>
                <UI.VisuallyHidden as="span">
                    {getGenericNoteSRTextFromBadgeText(badge.text)}
                    {", "}
                </UI.VisuallyHidden>
                <WithTooltip
                    isEnabled={isEllipsisActive}
                    content={
                        <UI.Text skinny variant="note" colour="white">
                            {badge.text}
                        </UI.Text>
                    }
                    props={{
                        content: {
                            align: "start",
                            side: "bottom",
                        },
                    }}
                >
                    <StyledBadge
                        color={badgeColorMap[badge.level]}
                        aria-hidden="true"
                        {...sharedBadgeProps}
                    >
                        <Ds.Icon
                            name={badgeIconMap[badge.level]}
                            appearance="solid"
                        />
                        <StyledBadgeText ref={textRef}>
                            {badge.text}
                        </StyledBadgeText>
                    </StyledBadge>
                </WithTooltip>
            </>
        );
    }

    if (contentType === "FloreyResponseNote") {
        const floreyResponses = conversationItem.sections[0]?.responses ?? [];
        const isSubmitted = floreyResponses.length > 0;
        const text = isSubmitted ? "Submitted" : "Did not complete";

        return (
            <>
                <UI.VisuallyHidden as="span">
                    {isSubmitted
                        ? "florey submitted"
                        : "did not complete florey"}
                    {", "}
                </UI.VisuallyHidden>
                <WithTooltip
                    isEnabled={isEllipsisActive}
                    content={
                        <UI.Text skinny variant="note" colour="white">
                            {text}
                        </UI.Text>
                    }
                    props={{
                        content: {
                            align: "start",
                            side: "bottom",
                        },
                    }}
                >
                    <StyledBadge
                        color={isSubmitted ? "green" : "yellow"}
                        aria-hidden="true"
                        {...sharedBadgeProps}
                    >
                        <Ds.Icon
                            name={isSubmitted ? "Success" : "Warning"}
                            appearance="solid"
                        />
                        <StyledBadgeText ref={textRef}>{text}</StyledBadgeText>
                    </StyledBadge>
                </WithTooltip>
            </>
        );
    }

    return null;
};
