import { MutableRefObject } from "react";

import { useAnalytics } from "@accurx/analytics";
import * as UI from "@accurx/design";
import {
    PatientDemographics,
    useMedicalRecordConnection,
} from "@accurx/native";
import { ErrorImage } from "domains/inbox/components/ErrorImage/ErrorImage";
import { FormEMRPatientDetails } from "domains/inbox/components/PatientSearch/components/forms/FormEMRPatientDetails";
import {
    FormEmis,
    formEmisId,
} from "domains/inbox/components/PatientSearch/components/forms/FormEmis";

import {
    StyledConnectionErrorWrapper,
    StyledFormsDescriptionText,
} from "./PatientSearchEmr.styles";

/**
 * Flow to search for patients via emr
 *
 * This component handles medical record status changes
 */
export const PatientSearchEmr = ({
    onResultsFound,
    autoFocusInputRef,
}: {
    onResultsFound: (data: PatientDemographics[]) => void;
    autoFocusInputRef: MutableRefObject<HTMLInputElement | null>;
}) => {
    const track = useAnalytics();

    const medicalRecordConnection = useMedicalRecordConnection();

    if (medicalRecordConnection.status === "Disconnected") {
        return (
            <StyledConnectionErrorWrapper>
                <ErrorImage alt="Medical record disconnected error" />
                <UI.Text skinny variant="preview">
                    Sorry, it seems like you're disconnected from the medical
                    records system. Try again after connecting to the system.
                </UI.Text>
            </StyledConnectionErrorWrapper>
        );
    }

    if (medicalRecordConnection.system === "Emis") {
        return (
            <FormEmis
                id={formEmisId}
                onResultsFound={onResultsFound}
                onSearchClick={() => {
                    track("PatientCardSearch Button Click", {});
                }}
                onSearchSettled={({ hasError, countResult }) => {
                    track("PatientCardSearch Button Response", {
                        hasError,
                        countResult,
                    });
                }}
                autoFocusInputRef={autoFocusInputRef}
            />
        );
    }

    if (
        medicalRecordConnection.system === "SystmOne" ||
        medicalRecordConnection.system === "Vision"
    ) {
        return (
            <UI.Flex gap="2" flexDirection={"column"}>
                <StyledFormsDescriptionText>
                    Search for patients by their name or NHS number.
                </StyledFormsDescriptionText>
                <FormEMRPatientDetails
                    onResultsFound={onResultsFound}
                    onSearchClick={() => {
                        track("PatientCardSearch Button Click", {});
                    }}
                    onSearchSettled={({ hasError, countResult }) => {
                        track("PatientCardSearch Button Response", {
                            hasError,
                            countResult,
                        });
                    }}
                />
            </UI.Flex>
        );
    }

    return (
        <>
            <StyledConnectionErrorWrapper>
                <ErrorImage alt="Unsupported medical record system error" />
                <UI.Text variant="preview" skinny>
                    Sorry, it seems like we don't support patient search on{" "}
                    {medicalRecordConnection.system ??
                        "the medical records system"}
                    . Please contact support
                </UI.Text>
            </StyledConnectionErrorWrapper>
        </>
    );
};
