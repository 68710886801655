import { useEffect } from "react";

import { useCurrentWorkspace } from "@accurx/auth";
import { Feedback } from "@accurx/design";
import { Redirect } from "react-router";

import { trackCopyToOrgTemplatePageView } from "app/analytics/FlemingAnalytics";
import { useCreateAccumailTemplateMutation } from "app/hooks/mutations";
import { useAccumailTemplateQuery } from "app/hooks/queries";
import { Container, Spinner } from "app/organisations/shared";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { SnomedCode } from "app/sharedComponents/SnomedPicker";

import { textToJson } from "../accumailTemplateEditor";
import { EditTemplatePageHeader } from "./components/EditTemplatePageHeader";
import { TemplateForm } from "./form/TemplateForm";
import { useTemplateFormState } from "./form/useTemplateFormState";

type CopyAccumailTemplateProps = {
    workspaceId: number;
    templateId: string;
};

export const CopyAccumailTemplate = ({
    workspaceId,
    templateId,
}: CopyAccumailTemplateProps) => {
    const { organisationName: workspaceName } = useCurrentWorkspace();
    const { data, isLoading, isError } = useAccumailTemplateQuery({
        workspaceId,
        templateId: templateId,
    });

    const {
        mutate,
        isSuccess,
        isError: isMutationError,
        isLoading: isMutating,
    } = useCreateAccumailTemplateMutation();

    const flemingLoggedInProperties = useFlemingLoggedInAnalytics();
    const isNoncopyableTemplateType =
        data?.templateType !== undefined &&
        data?.templateType !== "UserTemplate";

    useEffect(() => {
        if (isLoading || isError || isNoncopyableTemplateType) {
            return;
        }
        trackCopyToOrgTemplatePageView({
            ...flemingLoggedInProperties,
            productOrigin: "ClinicianMessage",
            templateId: data.id,
        });
    }, [
        flemingLoggedInProperties,
        data,
        isLoading,
        isError,
        isNoncopyableTemplateType,
    ]);

    const doCopy: (
        text: string,
        title: string,
        snomedCodes?: SnomedCode[],
    ) => void = (text, title, snomedCodes) =>
        mutate({
            workspaceId,
            data: {
                content: text,
                title,
                templateType: "WorkspaceTemplate",
                snomedCodes,
            },
        });

    const templateFormState = useTemplateFormState({
        doSave: doCopy,
        type: "WorkspaceTemplate",
        saveMode: "copy",
    });
    const {
        title,
        setTitle,
        editorContent,
        setEditorContent,
        manageTemplatePage,
        setSnomedCodes,
        snomedCodes,
    } = templateFormState;

    if ((isSuccess || isNoncopyableTemplateType) && manageTemplatePage) {
        return <Redirect to={manageTemplatePage} />;
    }

    if (isLoading || isMutating || isError) {
        return (
            <Container>
                {isLoading || isMutating ? (
                    <Spinner />
                ) : (
                    <Feedback title="Unable to fetch template" colour="error">
                        Please refresh the page to try again.
                    </Feedback>
                )}
            </Container>
        );
    }

    const initialContent = textToJson(data.content);
    if (editorContent === undefined) {
        setEditorContent(initialContent);
    }
    if (title === undefined) {
        setTitle(data.title);
    }
    if (snomedCodes === undefined) {
        setSnomedCodes(data.snomedCodes ?? []);
    }

    return (
        <TemplateForm
            header={
                <EditTemplatePageHeader
                    title={`Copy template: "${data.title}"`}
                    subtitle={`from my templates for everyone in ${workspaceName} to use`}
                />
            }
            saveButtonText="Save template"
            initialContent={editorContent ?? initialContent}
            isMutationError={isMutationError}
            {...templateFormState}
        />
    );
};
