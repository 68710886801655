import { Ds, Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledModalHeader = styled(Ds.Modal.Section)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${Tokens.SIZES[1]} ${Tokens.SIZES[2]};
`;

export const StyledModalHeaderDivider = styled.div`
    height: ${Tokens.SIZES[3]};
    background-color: ${Tokens.COLOURS.greyscale.silver};
    margin: 0 ${Tokens.SIZES[1]};
    width: 1px;
`;

export const StyledModalCloseButton = styled(Ds.Modal.CloseButton)`
    align-self: center;
    height: 42px;
    width: 42px;
    display: flex;
    justify-content: center;
`;

export const StyledModalBody = styled(Ds.Flex)`
    flex: 1;
    overflow: hidden;
`;

export const StyledModalSearchPanel = styled(Ds.Flex).attrs({
    flexDirection: "column",
})`
    flex: 1;
    overflow: auto;
    padding: ${Tokens.SIZES[2]} ${Tokens.SIZES[1.5]};

    @media (min-width: ${Tokens.BREAKPOINTS.md}) {
        overflow: hidden;
    }
`;

export const StyledModalDetailsPanel = styled(Ds.Flex).attrs({
    flexDirection: "column",
})`
    @media (min-width: ${Tokens.BREAKPOINTS.md}) {
        border-left: ${Tokens.BORDERS.normal.silver};
        padding: ${Tokens.SIZES[2]};
        width: 50%;
    }
`;
