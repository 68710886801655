import { useState } from "react";

import { BulkAppointmentUploadPatientFileResponse } from "@accurx/api/appointment";
import { useCurrentWorkspace } from "@accurx/auth";
import { Card, Ds, Feedback } from "@accurx/design";
import { Log } from "@accurx/shared";
import { getManualUploadReviewPagePath } from "domains/appointments-manual-upload/Routes";
import { useAppointmentFileUploadMutation } from "domains/appointments-manual-upload/hooks/useAppointmentFileUploadMutation";
import { useHistory } from "react-router-dom";

import { NavSubMenuComponent } from "../../../../app/navbar/NavSubMenuComponent";
import {
    ErrorContainer,
    ErrorList,
    FooterContainer,
    MainContentsContainer,
    MainContentsText,
    PageContainer,
} from "./ManualUploadCreatePage.styles";
import { RequiredColumnsList } from "./components/RequiredColumnsList";
import { UploadFile } from "./components/UploadFile";

const joinValues = (values: string[] | number[]) => {
    return values.length > 1
        ? `${values.slice(0, -1).join(", ")} and ${
              values.at(-1) as string | number
          }`
        : values.join(", ");
};

const mapFileProcessingErrors = (
    uploadData: BulkAppointmentUploadPatientFileResponse,
) => {
    const errors: Array<{ mainMessage: string; details: string }> = [];

    if (uploadData.validationErrors?.parsingErrors) {
        uploadData.validationErrors.parsingErrors.forEach((error) => {
            errors.push({
                mainMessage: `${error.type}:`,
                details: error.message,
            });
        });
    }

    if (uploadData.validationErrors?.dataErrors) {
        uploadData.validationErrors.dataErrors.forEach((error) => {
            const rule = error.rowNumbers.length > 1 ? "s" : "";
            errors.push({
                mainMessage: `${error.type} on row${rule}:`,
                details: joinValues(error.rowNumbers),
            });
        });
    }

    return errors;
};

export const ManualUploadCreatePage = (): JSX.Element => {
    const history = useHistory();
    const workspaceId = useCurrentWorkspace().orgId;
    const [validationError, setValidationError] = useState<string>();

    const {
        mutate: uploadFile,
        status: uploadStatus,
        error: uploadError,
        data: uploadData,
    } = useAppointmentFileUploadMutation();

    const handleFileSelect = (file: File) => {
        uploadFile(
            { workspaceId: workspaceId.toString(), file },
            {
                onSuccess: (data) => {
                    if (data.isUploadSuccess) {
                        if (
                            data.bulkAppointmentImportId === null ||
                            data.bulkAppointmentImportId === undefined
                        ) {
                            Log.error(
                                "Attempted to upload appointments, but bulkAppointmentImportId was null",
                                {
                                    tags: {
                                        logger: "Upload a list of appointments",
                                    },
                                },
                            );
                        } else {
                            history.push(
                                getManualUploadReviewPagePath({
                                    workspaceId,
                                    uploadId: data.bulkAppointmentImportId,
                                }),
                                { data: data.bulkAppointmentItems },
                            );
                        }
                    }
                },
            },
        );
    };

    const handleValidationError = (failureReason?: string) => {
        if (failureReason) {
            setValidationError(failureReason);
        }
    };

    const errorTitleText = "We couldn't upload the file.";
    return (
        <PageContainer>
            <NavSubMenuComponent sticky backCallback={() => history.goBack()} />
            <MainContentsContainer>
                <MainContentsText>
                    <Ds.Text as="h1" weight="bold" size={"large"}>
                        Upload a list of appointments
                    </Ds.Text>
                </MainContentsText>
                {(uploadStatus === "error" ||
                    validationError ||
                    uploadStatus === "success") && (
                    <ErrorContainer>
                        {uploadStatus === "error" && (
                            <Feedback colour="error" title={errorTitleText}>
                                {uploadError.message}
                            </Feedback>
                        )}
                        {validationError && (
                            <Feedback colour="error" title={errorTitleText}>
                                {validationError}
                            </Feedback>
                        )}
                        {uploadStatus === "success" && (
                            <Feedback
                                colour="error"
                                title="Your file has missing or invalid information"
                            >
                                <ErrorList>
                                    {uploadData.validationErrors &&
                                        mapFileProcessingErrors(uploadData).map(
                                            (error, index) => (
                                                <li
                                                    key={`processing-error-${index}`}
                                                >
                                                    <Ds.Text
                                                        as="span"
                                                        weight="bold"
                                                    >
                                                        {error.mainMessage}
                                                    </Ds.Text>{" "}
                                                    <Ds.Text as="span">
                                                        {error.details}
                                                    </Ds.Text>
                                                </li>
                                            ),
                                        )}
                                </ErrorList>
                            </Feedback>
                        )}
                    </ErrorContainer>
                )}
                <Card spacing={2}>
                    <Ds.Flex flexDirection="column" gap="3">
                        <RequiredColumnsList />
                    </Ds.Flex>
                </Card>{" "}
            </MainContentsContainer>
            <FooterContainer>
                <UploadFile
                    onFileSelect={handleFileSelect}
                    onError={handleValidationError}
                    isUploading={uploadStatus === "loading"}
                />
            </FooterContainer>
        </PageContainer>
    );
};
