import { Ds } from "@accurx/design";

import { StyledScrollableFixedHeightContainer } from "../../Compose.styles";
import { ComposeActionStack } from "../../ComposeActionStack";
import { MessageEditorProps } from "../../types/Compose.types";
import { ComposeTextArea } from "../ComposeTextArea/ComposeTextArea";
import { ScheduledSendInfo } from "../ScheduledSendInfo/ScheduledSendInfo";
import { StillLoadingErrors } from "../StillLoadingErrors/StillLoadingErrors";

export const MessageEditor = ({
    onUserTyping,
    assigneeSelector,
    assigneeLabel,
    isHeightRestricted,
}: MessageEditorProps) => {
    return (
        <>
            <StyledScrollableFixedHeightContainer
                $isHeightRestricted={isHeightRestricted || false}
            >
                <ComposeTextArea onUserTyping={onUserTyping} />
                <ComposeActionStack
                    assigneeLabel={
                        assigneeSelector ?? (
                            <Ds.Badge
                                radius="round"
                                color="greyscale"
                                data-testid="assignee-label"
                            >
                                <strong>{assigneeLabel}</strong>
                            </Ds.Badge>
                        )
                    }
                />
            </StyledScrollableFixedHeightContainer>
            <StillLoadingErrors />
            <ScheduledSendInfo />
        </>
    );
};
