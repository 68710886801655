import { Button, TextareaAutosize, Tokens } from "@accurx/design";
import styled from "styled-components";

export const Container = styled.div`
    padding-bottom: ${Tokens.SIZES[4]};
`;

export const StyledTextArea = styled(TextareaAutosize)`
    padding: 0 ${Tokens.SIZES[1.5]};
    border: 1px solid ${Tokens.COLOURS.greyscale.silver};
    border-radius: ${Tokens.BORDERS.radius};
    font-family: ${Tokens.TYPOGRAPHY.body.base.fontFamily};
    font-size: 1rem;
    resize: none !important;
    width: 100%;
    line-height: 2rem;
    display: block;
    margin: ${Tokens.SIZES[1]} 0;
`;

export const StyledGreyBox = styled.div`
    background-color: ${Tokens.COLOURS.greyscale.cloud};
    border-radius: ${Tokens.BORDERS.radius};
    padding: ${Tokens.SIZES[1]};
`;

export const StyledAttachmentButton = styled(Button)`
    margin-top: ${Tokens.SIZES[2]};
`;
