import { ExternalEmailContact } from "@accurx/api/clinician-messaging";
import { Ds } from "@accurx/design";

import {
    ContactListSkeleton,
    SkeletonText,
} from "../ContactSelectCard/ContactSelectCardSkeleton";
import { GroupedContactList } from "../GroupedContactList/GroupedContactList";

type LocalContactsProps = {
    contacts: ExternalEmailContact[];
    isLoading: boolean;
};

export const LocalContacts = ({ contacts, isLoading }: LocalContactsProps) => {
    return isLoading ? (
        <>
            <Ds.Box mb="3">
                <SkeletonText width="190px" />
            </Ds.Box>
            <ContactListSkeleton
                count={6}
                showGroupHeadings={true}
                role="status"
            />
        </>
    ) : (
        <>
            <Ds.Box mb="2">
                <Ds.Text as="h2" weight="bold" color="zinc">
                    Local contacts by distance
                </Ds.Text>
            </Ds.Box>
            <GroupedContactList contacts={contacts} />
        </>
    );
};
