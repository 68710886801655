import {
    BaseAppointmentRemindersResponse,
    GetAppointmentRemindersResponse,
} from "@accurx/api/appointment";
import { IWrappedResult, getApiEndpoint, httpClient } from "@accurx/shared";
import { useMutation, useQueryClient } from "@tanstack/react-query";

type DeleteReminderParameters = {
    workspaceId: string;
    reminderId: number;
};

const deleteReminder = (
    workspaceId: string,
    reminderId: number,
): Promise<IWrappedResult<BaseAppointmentRemindersResponse>> =>
    httpClient.deleteReturnJsonSafeAsync(
        getApiEndpoint({
            path: "/api/appointments/:workspaceId/AppointmentReminders/:appointmentReminderId",
            params: {
                workspaceId,
                appointmentReminderId: reminderId.toString(),
            },
        }),
    );

export const useDeleteReminder = ({
    workspaceId,
    reminderId,
}: DeleteReminderParameters) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationKey: [
            "AppointmentReminders",
            "DeleteReminder",
            { workspaceId, reminderId },
        ],
        mutationFn: async () => {
            const response = await deleteReminder(workspaceId, reminderId);
            if (!response.success) {
                throw new Error(
                    response.error ?? "Could not delete appointment reminder",
                );
            }
        },
        onSuccess() {
            queryClient.setQueryData<GetAppointmentRemindersResponse>(
                [
                    "AppointmentReminders",
                    "RemindersAndFilterOptions",
                    { workspaceId },
                ],
                (previousData) => {
                    if (!previousData) {
                        return;
                    }

                    const updatedReminders =
                        previousData.appointmentReminders.filter(
                            (reminder) => reminder.id !== reminderId,
                        );

                    return {
                        ...previousData,
                        appointmentReminders: updatedReminders,
                    };
                },
            );
        },
    });
};
