import { getApiEndpoint, httpClient, returnDataOrThrow } from "@accurx/shared";
import { useQueries } from "@tanstack/react-query";

/**
 * Some workspaces may have not be contactable via reply from web - perhaps
 * they closed down, got the Accumail feature disabled, or just stopped using it
 * and got marked as inactive. This hook finds all the other workspaces that are
 * participants in the conversation whether they are available / unavailable for contact.
 */

export type UseWorkspaceActivityQueryResultData = {
    isOpenToReplies: boolean;
    practiceUserOnlineStatus: { lastOnlineAt: string }[];
};
export const useWorkspaceActivityQuery = ({
    workspaceIds,
    currentWorkspaceId,
}: {
    workspaceIds: number[];
    currentWorkspaceId: number;
}) => {
    return useQueries({
        queries: workspaceIds.map((workspaceId) => ({
            queryKey: ["WorkspaceActivityQuery", { workspaceIds: workspaceId }],
            queryFn: async () => {
                const response = await httpClient.getReturnJsonSafeAsync(
                    getApiEndpoint({
                        path: "/api/clinicianmessaging/PracticeInformation/workspaces/:workspaceId/activity",
                        params: {
                            workspaceId: workspaceId.toString(),
                        },
                        query: `?organisationId=${currentWorkspaceId}`,
                    }),
                );
                return returnDataOrThrow<UseWorkspaceActivityQueryResultData>(
                    response,
                );
            },
        })),
    });
};
