import { useCurrentWorkspace } from "@accurx/auth";
import { Redirect, generatePath, useLocation } from "react-router-dom";

import { ROUTES_COMPOSE } from "../Routes.constants";
import { RouterState } from "../Routes.types";

export const ComposeToolbarRedirect = () => {
    const location = useLocation<RouterState>();
    const workspaceId = useCurrentWorkspace().orgId;

    return (
        <Redirect
            to={{
                ...location,
                pathname: generatePath(ROUTES_COMPOSE.compose, { workspaceId }),
                state: {
                    ...(location.state || {}),
                    appOrigin: "ComposeToolbar",
                },
            }}
        />
    );
};
