import {
    IWrappedResult,
    JsonResult,
    getApiEndpoint,
    httpClient,
} from "@accurx/shared";

import {
    SettingsUserPassword,
    SettingsUserProfile,
    SettingsUserSignature,
} from "app/account/UserAccountSetting/components/Settings.types";

const ENDPOINTS = {
    accountProfile: "/api/account/Manage/Index",
    accountPassword: "/api/account/ChangePassword",
    accountSignature: "/api/account/Manage/MessageSignature",
    accountSignout: "/api/account/SignOutEverywhere",
    accountCookies: "/api/account/UserCookies",
} as const;

export const getUserProfile = (): Promise<
    IWrappedResult<SettingsUserProfile>
> =>
    httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.accountProfile,
        }),
    );

export const postUserPassword = (
    request: SettingsUserPassword,
): Promise<IWrappedResult<undefined>> =>
    httpClient.postJsonReturnSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.accountPassword,
        }),
        request,
    );

export const getUserSignature = (): Promise<
    IWrappedResult<SettingsUserSignature>
> =>
    httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.accountSignature,
        }),
    );

export const signoutEverywhere = (): Promise<JsonResult> =>
    httpClient.postJsonReturnSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.accountSignout,
        }),
        {},
    );
