import { MouseEventHandler } from "react";

import { Ds } from "@accurx/design";

export function CreateReminderButton({
    onClick,
}: {
    onClick: MouseEventHandler<HTMLButtonElement>;
}) {
    return (
        <Ds.Button onClick={onClick}>
            <Ds.Button.Icon name="Plus" />
            Create appointment reminder
        </Ds.Button>
    );
}
