import { createRouterState as createRouterStatePatientForCompose } from "@accurx/compose";
import { Button } from "@accurx/design";
import { useFullScreenNavigate } from "@accurx/navigation";
import { useHistory, useParams } from "react-router";

import {
    useTrackOnContactDropdownOpen,
    useTrackOnContactDropdownOptionClick,
} from "app/analytics/FlemingAnalytics/IntegratedClinicsEvents";
import ContactDropdown, {
    InviteToVideoConsultOption,
    MessageGpPracticeOption,
    NewConversationOption,
    PatientProfileOption,
    RecordViewOption,
} from "app/sharedComponents/patientContactDropdown";
import { ROUTES, ROUTES_EXTENSION } from "shared/Routes";

type IntegratedClinicURLParams = { clinicId: string; workspaceId: string };

type ContactDropdownButtonProps = {
    dateOfBirth: string;
    nhsNumber: string;
};

export default function ContactDropdownButton({
    dateOfBirth,
    nhsNumber,
}: ContactDropdownButtonProps) {
    const { workspaceId } = useParams<IntegratedClinicURLParams>();
    const {
        push: navigate,
        location: { pathname },
    } = useHistory();
    const trackDropdownOpenClick = useTrackOnContactDropdownOpen();
    const trackDropdownOptionClick = useTrackOnContactDropdownOptionClick();

    const videoConsultInvitePath = ROUTES.send_message;
    const patientProfilePath = pathname + ROUTES_EXTENSION.patientProfile;
    const recordViewPath = pathname + ROUTES_EXTENSION.remoteRecordView;

    const fullScreenNavigate = useFullScreenNavigate();

    return (
        <ContactDropdown
            workspaceId={workspaceId}
            dateOfBirth={dateOfBirth}
            nhsNumber={nhsNumber}
            triggerButton={({ isDropdownOpen, status }) => (
                <Button
                    icon={{ name: "Chat", colour: "blue" }}
                    theme="secondary"
                    text="Contact"
                    disabled={status === "loading"}
                    onClick={() => {
                        if (status !== "loading" && !isDropdownOpen) {
                            trackDropdownOpenClick();
                        }
                    }}
                />
            )}
            dropdownContent={({ data }) => (
                <>
                    <InviteToVideoConsultOption
                        searchResult={data}
                        onClick={() => {
                            trackDropdownOptionClick({
                                selectedItem: "VideoInvite",
                            });
                            navigate(videoConsultInvitePath);
                        }}
                    />
                    <NewConversationOption
                        searchResult={data}
                        onClick={() => {
                            trackDropdownOptionClick({
                                selectedItem: "PatientMessage",
                            });
                            fullScreenNavigate(
                                `/w/${workspaceId}/compose`,
                                createRouterStatePatientForCompose({
                                    patient: data.searchedResult.patient,
                                    patientToken:
                                        data.searchedResult.patientToken,
                                    appOrigin: "Clinics",
                                }),
                            );
                        }}
                    />
                    <RecordViewOption
                        searchResult={data}
                        onClick={() => {
                            trackDropdownOptionClick({
                                selectedItem: "RecordView",
                            });
                            navigate(recordViewPath);
                        }}
                    />
                    <MessageGpPracticeOption
                        searchResult={data}
                        onClick={() => {
                            trackDropdownOptionClick({
                                selectedItem: "ClinicianMessage",
                            });
                            fullScreenNavigate(
                                `/w/${workspaceId}/compose/clinician-compose`,
                                createRouterStatePatientForCompose({
                                    patient: data.searchedResult.patient,
                                    patientToken:
                                        data.searchedResult.patientToken,
                                }),
                            );
                        }}
                    />
                    <PatientProfileOption
                        searchResult={data}
                        onClick={() => {
                            trackDropdownOptionClick({
                                selectedItem: "PatientProfile",
                            });
                            navigate(patientProfilePath);
                        }}
                    />
                </>
            )}
        />
    );
}
