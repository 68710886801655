import React, { useEffect, useMemo } from "react";

import { Owner, Recipient } from "@accurx/api/content";
import { FeatureName, useFeatureFlag } from "@accurx/auth";
import {
    ButtonLink,
    Ds,
    Feedback,
    Grid,
    StackPanel,
    Table,
    Text,
    useTable,
} from "@accurx/design";
import { useMessageTemplates } from "@accurx/message-templates";
import { ArchivedWorkspaceHiddenItemWrapper } from "@accurx/workspace-management";

import { trackManageTemplatePageView } from "app/analytics/FlemingAnalytics";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { useIsUserApproved } from "store/hooks";

import { CategoriesFilter } from "./CategoriesFilter";
import { StyledTableBody } from "./ManageTemplates.styles";
import {
    FEEDBACK_SEARCH_TEXT,
    NO_TEMPLATES_TEXT,
    shouldDisplayCategoryHeader,
} from "./ManageTemplates.utils";
import { TabIds } from "./ManageTemplatesOverview";
import SnomedTag from "./SnomedTag";
import DeleteTemplateButton from "./components/DeleteTemplateButton";
import { TemplateBody } from "./components/TemplateBody";
import {
    routeToCreateWebTemplate,
    routeToEditWebTemplate,
} from "./routeHelpers";
import { displayFormattedRecipient, getDistinctCategories } from "./utils";

const COLUMN_DEFS = [
    {
        header: "Template name",
        id: "title",
    },
    {
        header: "Details",
        id: "body",
    },
    {
        header: "Recipient",
        id: "recipient",
    },
    {
        header: "Category",
        id: "category",
    },
    {
        header: "Actions",
        id: "actions",
    },
];

type OrganisationTemplatesPanelProps = {
    orgId: number;
    orgName: string;
};

export const OrganisationTemplatesPanel = ({
    orgId,
    orgName,
}: OrganisationTemplatesPanelProps): JSX.Element => {
    const isAccumailTemplateEnabled = useFeatureFlag(
        FeatureName.AccumailTemplateOverviewEnabled,
    );

    const filterOptions = isAccumailTemplateEnabled
        ? {
              owner: Owner.Workspace,
          }
        : {
              owner: Owner.Workspace,
              recipient: Recipient.Patient,
          };

    const { data, refetch, status } = useMessageTemplates(orgId, filterOptions);

    const isUserApproved = useIsUserApproved();
    const flemingLoggedInProperties = useFlemingLoggedInAnalytics();

    const sortedTemplates = data?.templates
        .sort((a, b) => a.title.localeCompare(b.title))
        .sort((a, b) => a.category.localeCompare(b.category));

    const displayableColumns = isAccumailTemplateEnabled
        ? COLUMN_DEFS
        : COLUMN_DEFS.filter((columnItem) => columnItem.id !== "recipient");

    const table = useTable({
        data: sortedTemplates ?? [],
        columnDefs: displayableColumns,
    });

    const distinctCategories = useMemo(() => {
        return getDistinctCategories(table.rows);
    }, [table.rows]);
    const countTemplateCategories = distinctCategories.length;

    useEffect(() => {
        trackManageTemplatePageView({
            ...flemingLoggedInProperties,
            countTemplateCategories,
            currentTab: TabIds.OrganisationTemplates,
            productOrigin: "PatientMessage",
        });
    }, [flemingLoggedInProperties, countTemplateCategories]);

    if (!isUserApproved) {
        return (
            <Text skinny>
                Workspace templates are not available for unapproved users.
                Please ask your Admin to approve you.
            </Text>
        );
    }

    return (
        <StackPanel gutter={3} orientation="vertical">
            <Text skinny>
                Here you can create your own templates and they will appear in
                the software for you to use. Each message will still have a
                greeting (e.g. Dear Miss Flynn) and a signature (e.g. {orgName}
                ), so you won't need to create these!
            </Text>
            <ArchivedWorkspaceHiddenItemWrapper>
                <ButtonLink
                    href={routeToCreateWebTemplate({
                        orgId,
                        isOrgTemplate: true,
                    })}
                    text="Create New Workspace Template"
                />
            </ArchivedWorkspaceHiddenItemWrapper>

            {status === "success" && (
                <Table>
                    <Table.Head>
                        <Table.Row>
                            <Table.Header colSpan={displayableColumns.length}>
                                <Grid
                                    columns={{ xs: "1fr", sm: "1fr 1fr" }}
                                    gap="1"
                                >
                                    <StackPanel>
                                        <Text variant={"subtitle"}>
                                            Workspace templates
                                        </Text>
                                        {table.rows.length !== 0 && (
                                            <Text>{FEEDBACK_SEARCH_TEXT}</Text>
                                        )}
                                    </StackPanel>
                                    {table.rows.length && (
                                        <CategoriesFilter
                                            distinctCategories={
                                                distinctCategories
                                            }
                                            onFilterSelect={(filterTemplates) =>
                                                table.actions.filter((row) =>
                                                    filterTemplates(
                                                        row.category,
                                                    ),
                                                )
                                            }
                                            currentTable={
                                                TabIds.OrganisationTemplates
                                            }
                                        />
                                    )}
                                </Grid>
                            </Table.Header>
                        </Table.Row>
                        <Table.Row>
                            {table.columns.map((column) => (
                                <Table.Header
                                    scope={"col"}
                                    key={column.id}
                                    colour="greyscale"
                                >
                                    {column.header}
                                </Table.Header>
                            ))}
                        </Table.Row>
                    </Table.Head>
                    <StyledTableBody>
                        {table.rows.map((row, index) => (
                            <React.Fragment key={row.id}>
                                {shouldDisplayCategoryHeader({
                                    currentRowCategoryHeading: row.category,
                                    previousRowCategoryHeading:
                                        table.rows[index - 1]?.category,
                                    currentIndex: index,
                                }) && (
                                    <Table.Row key={row.category + row.id}>
                                        <Table.Header
                                            colSpan={displayableColumns.length}
                                            scope="colgroup"
                                            colour="greyscale"
                                        >
                                            <Text
                                                skinny
                                                variant="subtitle"
                                                as="span"
                                            >
                                                {row.category}
                                            </Text>
                                        </Table.Header>
                                    </Table.Row>
                                )}
                                <Table.Row>
                                    <Table.Header scope="row">
                                        {row.title}
                                    </Table.Header>
                                    <Table.DataCell>
                                        <StackPanel>
                                            <TemplateBody bodyText={row.body} />
                                            {row.snomedCodes.map(
                                                (snomedCode, index) => (
                                                    <SnomedTag
                                                        key={`${snomedCode.conceptId}-${index}`}
                                                        text={snomedCode.term}
                                                        code={
                                                            snomedCode.conceptId
                                                        }
                                                    />
                                                ),
                                            )}
                                        </StackPanel>
                                    </Table.DataCell>
                                    {isAccumailTemplateEnabled && (
                                        <Table.DataCell>
                                            {
                                                displayFormattedRecipient[
                                                    row.recipient
                                                ]
                                            }
                                        </Table.DataCell>
                                    )}
                                    <Table.DataCell>
                                        {row.category}
                                    </Table.DataCell>
                                    <Table.DataCell>
                                        <ArchivedWorkspaceHiddenItemWrapper>
                                            <StackPanel
                                                orientation="vertical"
                                                horizontalContentAlignment="left"
                                                gutter={1}
                                            >
                                                {!row.isVideo && (
                                                    <ButtonLink
                                                        href={routeToEditWebTemplate(
                                                            {
                                                                orgId,
                                                                templateId:
                                                                    row.id,
                                                                isOrgTemplate:
                                                                    true,
                                                            },
                                                        )}
                                                        text="Edit"
                                                        theme="secondary"
                                                    />
                                                )}
                                                <DeleteTemplateButton
                                                    templateId={parseInt(
                                                        row.id,
                                                        10,
                                                    )}
                                                    templateName={row.title}
                                                    templateAttachmentCount={
                                                        row.attachments.length
                                                    }
                                                    isWorkspaceTemplate
                                                    workspaceId={orgId}
                                                    workspaceName={orgName}
                                                    onSuccess={() => {
                                                        refetch();
                                                    }}
                                                />
                                            </StackPanel>
                                        </ArchivedWorkspaceHiddenItemWrapper>
                                    </Table.DataCell>
                                </Table.Row>
                            </React.Fragment>
                        ))}
                    </StyledTableBody>
                    <Table.Foot>
                        <Table.Row>
                            <Table.DataCell colSpan={displayableColumns.length}>
                                {table.rows.length > 0 ? (
                                    <Ds.Flex justifyContent={"center"}>
                                        <Ds.Flex.Item>
                                            Showing {table.rows.length}
                                        </Ds.Flex.Item>
                                    </Ds.Flex>
                                ) : (
                                    NO_TEMPLATES_TEXT
                                )}
                            </Table.DataCell>
                        </Table.Row>
                    </Table.Foot>
                </Table>
            )}
            {status === "error" && (
                <Feedback
                    colour="error"
                    title="There was a problem loading Workspace templates"
                >
                    <Ds.Text>Reload this page to try again</Ds.Text>
                </Feedback>
            )}
            {status === "loading" && <Ds.Spinner />}
        </StackPanel>
    );
};
