import { useEffect } from "react";

import { FeatureName } from "@accurx/auth";
import { shallowEqual, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { FlemingAnalyticsTracker } from "app/analytics";
import { actionCreators } from "app/selectProduct/SelectProductActions";
import { actionCreators as VideoCompatibilityActions } from "app/selectProduct/VideoCompatibilityActions";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { OrganisationHelper } from "shared/OrganisationHelper";
import { PatientHelper } from "shared/PatientHelper";
import { ROUTES, ROUTES_EXTENSION } from "shared/Routes";
import { useAppSelector } from "store/hooks";

import { FeatureButton } from "../FeatureButton";
import { isVideoConsultDisabled } from "../PatientProfile.helper";

const VideoConsultButton = ({
    userAgent,
}: {
    userAgent: string;
}): JSX.Element | null => {
    const dispatch = useDispatch();
    const history = useHistory();
    const videoCompatibility = useAppSelector(
        ({ videoCompatibility }) => videoCompatibility,
    );
    const patient = useAppSelector(
        ({ searchForPatient }) =>
            PatientHelper.getPatient(searchForPatient.lastResponse),
        shallowEqual,
    );
    const searchPatientOrigin = useAppSelector(
        ({ selectProduct }) => selectProduct.searchPatientOrigin,
    );
    const appointment = useAppSelector(({ patientLists }) => {
        if (searchPatientOrigin === ROUTES.patient_lists) {
            return patientLists.latestSearchPatientListEntry;
        }

        return null;
    });
    const organisation = useAppSelector(({ account }) =>
        OrganisationHelper.getOrganisation(account),
    );
    const loggedInProps = useFlemingLoggedInAnalytics();

    useEffect(() => {
        if (videoCompatibility.hasChecked === false) {
            dispatch(
                VideoCompatibilityActions.checkVideoCompatibility(userAgent),
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const goToSearchVideo = () => {
        dispatch(actionCreators.selectVideoProductType());

        const analyticsProps = {
            ...loggedInProps,
            navigationLocation: "Header" as const,
            optionSelected: "VideoInvite" as const,
            productAvailableForPatient: true, // If this is enabled the product is available
            searchPatientOrigin,
        };

        FlemingAnalyticsTracker.trackProductOptionButtonClick(analyticsProps);

        history.push(ROUTES_EXTENSION.sendMessage, {
            referredFrom: ROUTES_EXTENSION.patientProfile,
        });
    };

    const isVideoConsultEnabledForOrg = OrganisationHelper.isFeatureEnabled(
        organisation,
        FeatureName.VideoConsultWeb,
    );
    if (!isVideoConsultEnabledForOrg) return null;

    const disabledInfo = isVideoConsultDisabled(
        patient,
        appointment,
        videoCompatibility,
    );

    return (
        <FeatureButton
            title="Video Consult"
            icon={{ name: "Video", colour: "blue" }}
            action={goToSearchVideo}
            disabledInfo={disabledInfo}
        />
    );
};

export { VideoConsultButton };
