import {
    MessagePatientRequestBase,
    SelfBookRequestType,
} from "@accurx/api/patient-messaging";
import { OnMessageSendSelfBookLink } from "domains/message-component/components/Compose/types/Compose.types";

export const mapSelfBookToSelfBookDto = (
    selfBookLink: OnMessageSendSelfBookLink | null,
): Exclude<MessagePatientRequestBase["selfBook"], undefined> => {
    return selfBookLink
        ? {
              type: SelfBookRequestType[selfBookLink.appointmentType],
              slotType: selfBookLink.slotType ?? null,
              clinicianGroupIds:
                  selfBookLink.specificClinician?.map(
                      (clinician) => clinician.clinicianGroupId,
                  ) ?? null,
              targetOdsCode:
                  selfBookLink.crossOrgBooking?.targetOdsCode ?? null,
              timeoutDays: selfBookLink.timeoutDays,
              availabilityRestriction: selfBookLink.availabilityRestriction,
              declineSettings: selfBookLink.declineSettings,
              // Not included in the payload
              snomedConceptId: null,
              specificClinicianEnabled: null,
          }
        : null;
};
