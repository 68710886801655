import { useState } from "react";

import { TicketIdentity } from "@accurx/api/patient-messaging";
import {
    useCurrentUser,
    useCurrentWorkspace,
    useFeatureFlag,
} from "@accurx/auth";
import { conversationIdMapper } from "@accurx/concierge";
import { useAssignee } from "@accurx/concierge/hooks/data/useAssignee";
import { Conversation } from "@accurx/concierge/types";
import { patientNhsNumber } from "@accurx/concierge/util/patientNhsNumber";
import * as UI from "@accurx/design";
import { SaveToRecordAction } from "@accurx/inbox";
import {
    Compose,
    ComposePatient,
    ComposeProvider,
    ContactDetail,
    OnMessageSendFn,
    SentItem,
    generatePatientGreeting,
    generateSignature,
    useMaxAttachmentCount,
    usePatientMessageSendAnalytics,
    useSendMessageMutation,
} from "@accurx/message-component";
import {
    useMedicalRecordConnection,
    useNativeSubscription,
} from "@accurx/native";
import { calculateAge } from "@accurx/patient";
import { Log } from "@accurx/shared";
import { isTestPatient } from "domains/compose/utils/isTestPatient";
import { toast } from "react-toastify";

import { formatAssigneeDisplayName } from "../../utils/formatAssigneeDisplayName";
import {
    StyledComposeFeedbacksContainer,
    StyledComposeNewMessageFlex,
} from "./ComposeNewMessage.styles";
import { AssigneeSelector } from "./components/AssigneeSelector/AssigneeSelector";
import { Assignee } from "./components/AssigneeSelector/AssigneeSelector.types";
import { DiscardChangesModal } from "./components/DiscardChangesModal/DiscardChangesModal";
import { SmsPredictionFeedback } from "./components/SmsPredictionFeedback/SmsPredictionFeedback";
import { useAppOrigin } from "./hooks/useAppOrigin";
import { mapSendMessageResultToSaveToRecordData } from "./mappers/mapSendMessageResultToSaveToRecordData";
import { SentItemContentType } from "./mappers/mapSentItemTypeToContentType";

type ComposeNewMessageProps = {
    patient: ComposePatient;
    contactDetails: ContactDetail[];
    onMessageSent: (conversationId: string) => void;
};

export const ComposeNewMessage = ({
    patient,
    contactDetails,
    onMessageSent,
}: ComposeNewMessageProps) => {
    const sendMessageMutation = useSendMessageMutation();
    const { trackPatientMessageSendResponse, trackPatientMessageSendClick } =
        usePatientMessageSendAnalytics();
    const analyticsAppOrigin = useAppOrigin();
    const workspace = useCurrentWorkspace();
    const connection = useMedicalRecordConnection();
    const { data: windowVisibility } = useNativeSubscription(
        "SubscribeWindowOpenStatus",
    );
    const isComposeWindowClosed = windowVisibility?.open === false;

    const [saveToRecordData, setSaveToRecordData] = useState<{
        patientId: string;
        conversationSource: Extract<
            Conversation["source"],
            { system: "Ticket" }
        >;
        itemServerId: string;
        contentType: SentItemContentType;
        snomedCodes: string[];
    } | null>(null);
    const { user } = useCurrentUser();
    // We use a React key to ensure the compose state is reset when the patient changes.
    // We're also using a version here which will increment when a user closes the compose window.
    // This ensures the compose window is reset when it is closed.
    const [patientKeyVersion, setPatientKeyVersion] = useState(1);
    const patientKey = `${patient.externalIds
        .map(({ type, value }) => `${type}${value}`)
        .join("-")}-${patientKeyVersion}`;

    const maxAttachmentCount = useMaxAttachmentCount("PatientMessaging");

    const isCollaborativeWebInboxEnabled = useFeatureFlag(
        "CollaborativeWebInbox",
    );
    const trySendViaNhsApp = useFeatureFlag("SendSmsViaNhsApp");

    const canAssignOnSend = isCollaborativeWebInboxEnabled;

    const defaultAssignee: Assignee = { type: "User", id: user.accuRxUserId };
    const [assignee, setAssignee] = useState<Assignee | null>(null);
    const assigneeSummary = useAssignee(assignee ?? { type: "None" });
    const assigneeLabel = formatAssigneeDisplayName(assigneeSummary);

    const getConversationIdFromTicketIdentity = (
        ticketIdentity: TicketIdentity,
    ) => {
        const conversationId =
            conversationIdMapper.ticket.fromSource(ticketIdentity);

        return conversationId;
    };

    const handleMessageSend: OnMessageSendFn = ({
        isModal,
        errors,
        warnings,
        data,
    }) => {
        // We only save to record if we're connected to EMR
        // and it allows to save to record
        // and compose is set to save to record
        const shouldSaveToRecord =
            connection.status === "Connected" &&
            connection.capabilities.saveToRecord &&
            data.isSaveToRecordEnabled;

        // Always track the click
        trackPatientMessageSendClick({
            conversationId: null,
            isTestPatient: isTestPatient(patientNhsNumber(patient.externalIds)),
            shouldSaveToRecord,
            origin: "/compose",
            isReply: false,
            ...data,
            warnings,
            errors,
            isModal,
            appOrigin: analyticsAppOrigin,
        });

        const isValid = errors.length === 0 && warnings.length === 0;

        // If it's valid, send the message
        if (isValid) {
            sendMessageMutation.mutate(
                {
                    composedMessage: {
                        ...data,
                        // Only send assignee data to the API if assign on send feature is enabled.
                        // If assign on send is not enabled, responses are assigned when a patient
                        // replies, not at the point of sending the original message.
                        assignee: canAssignOnSend ? assignee : null,
                    },
                    options: {
                        patientToken: patient.patientToken ?? null,
                        patientExternalIds: patient.externalIds,
                        patientDemographics: patient.demographics ?? null,
                        workspaceId: workspace.orgId,
                        ticketIdentity: null,
                        trySendViaNhsApp,
                    },
                },
                {
                    onSuccess: (res) => {
                        const sentItems: SentItem[] = [
                            ...(res.messages ?? []),
                            ...(res.emailMessages ?? []),
                            ...(res.nhsAppMessages ?? []),
                        ];

                        if (sentItems.length === 0) {
                            // The response did not contain sms or emails or nhsAppMessages
                            Log.error(
                                "Looks like the message was sent but we got back no sent items to map through",
                            );
                            return;
                        }

                        const item = sentItems[0];

                        const mapped =
                            mapSendMessageResultToSaveToRecordData(item);

                        if (!mapped) {
                            Log.error(
                                "Unable to redirect user to conversation.",
                                {
                                    tags: {
                                        product: "Compose",
                                    },
                                },
                            );
                            return;
                        }

                        // If saving to record, we navigate to the conversation once
                        // saving to record has been initiated
                        if (shouldSaveToRecord) {
                            const snomedCodes = data.template.value?.snomedCodes
                                ? data.template.value.snomedCodes.map(
                                      ({ id }) => id,
                                  )
                                : [];
                            if (mapped.ticketIdentity.id && mapped.patientId) {
                                setSaveToRecordData({
                                    conversationSource: {
                                        system: "Ticket",
                                        ticketIdentity: {
                                            id: mapped.ticketIdentity.id,
                                            type: mapped.ticketIdentity.type,
                                        },
                                    },
                                    patientId: mapped.patientId,
                                    itemServerId: mapped.ticketIdentity.id,
                                    contentType: mapped.contentType,
                                    snomedCodes,
                                });
                            } else {
                                Log.error(
                                    "Could not save message to medical record. Either ticket ID or patient ID missing.",
                                    {
                                        tags: {
                                            product: "Compose",
                                        },
                                    },
                                );
                            }
                        } else {
                            // Otherwise, we're not saving to record and
                            // we navigate to the conversation
                            const conversationId =
                                getConversationIdFromTicketIdentity(
                                    mapped.ticketIdentity,
                                );
                            onMessageSent(conversationId);
                        }
                    },
                    onError: () => {
                        toast(
                            <UI.Feedback
                                title="Something went wrong while trying to send your message"
                                colour="error"
                            />,
                        );
                    },
                    onSettled: (_, err) => {
                        trackPatientMessageSendResponse({
                            isReply: false,
                            conversationId: null,
                            origin: "/compose",
                            shouldSaveToRecord,
                            isTestPatient: isTestPatient(
                                patientNhsNumber(patient.externalIds),
                            ),
                            hasError: !!err,
                            appOrigin: analyticsAppOrigin,
                            ...data,
                        });
                    },
                },
            );
        }
    };

    const handleSetAssignee = (assignee: Assignee | "DEFAULT" | null) => {
        if (assignee === "DEFAULT") {
            setAssignee(defaultAssignee);
        } else {
            setAssignee(assignee);
        }
    };

    const userSignature = generateSignature(user);
    const patientAge = patient.dateOfBirth
        ? calculateAge(patient.dateOfBirth)
        : null;

    return (
        <StyledComposeNewMessageFlex>
            <ComposeProvider
                /*
                When the patient changes, we need to ensure that the compose provider
                and it's children are re-rendered, so that the patients contact details
                and the body of the message are reset.
                */
                key={patientKey}
                settings={{
                    greeting: generatePatientGreeting({
                        ageYear: patientAge,
                        prefixName: patient.prefixName,
                        firstName: patient.firstName,
                        familyName: patient.familyName,
                        nickname: patient.nickname,
                    }),
                    editableBody: "\n",
                    editableSignature: userSignature,
                    maxAttachmentCount,
                    canUseQuestionnaires: isCollaborativeWebInboxEnabled,
                    contactDetails:
                        contactDetails.find((c) => c.method === "Mobile") ??
                        contactDetails[0],
                    conversationParticipant: "WithPatient",
                    templatesComboboxMustOpenDownwards: true,
                    shouldTryToFetchConsent:
                        connection.status === "Connected" &&
                        !isComposeWindowClosed,
                }}
            >
                <StyledComposeFeedbacksContainer>
                    <SmsPredictionFeedback
                        patientExternalIds={patient.externalIds}
                    />
                </StyledComposeFeedbacksContainer>
                <DiscardChangesModal
                    // Increment the patient key to ensure the compose state is reset
                    onClose={() => {
                        setPatientKeyVersion((prev) => prev + 1);
                    }}
                />
                <Compose
                    patient={patient}
                    contactDetails={contactDetails}
                    patientMatchesCurrentMedicalRecordPatient={true}
                    onMessageSend={handleMessageSend}
                    isMessageSending={sendMessageMutation.isLoading}
                    {...(canAssignOnSend
                        ? {
                              assigneeSelector: (
                                  <AssigneeSelector
                                      currentAssignee={
                                          assignee ?? defaultAssignee
                                      }
                                      onAssigneeSelected={setAssignee}
                                  />
                              ),
                          }
                        : {
                              assigneeLabel,
                          })}
                    setAssignee={handleSetAssignee}
                />
            </ComposeProvider>
            {saveToRecordData !== null && (
                <SaveToRecordAction
                    patientId={saveToRecordData.patientId}
                    itemServerId={saveToRecordData.itemServerId}
                    patientExternalIds={patient.externalIds}
                    contentType={saveToRecordData.contentType}
                    conversationSource={saveToRecordData.conversationSource}
                    snomedCodeIds={saveToRecordData.snomedCodes}
                    onSaveToRecordInitiated={() => {
                        const conversationId =
                            getConversationIdFromTicketIdentity(
                                saveToRecordData.conversationSource
                                    .ticketIdentity,
                            );
                        onMessageSent(conversationId);
                    }}
                />
            )}
        </StyledComposeNewMessageFlex>
    );
};
