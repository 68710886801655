import { Ds, Text } from "@accurx/design";
import { Checkbox } from "@accurx/inbox-design-library";

export type SaveToRecordButtonProps = {
    onClick: () => void;
    isLoading: boolean;
    hasFailed: boolean;
    attachments?: {
        enabled: boolean;
        onToggle: (value: boolean) => void;
    };
};

export const SaveToRecordButton = ({
    onClick,
    isLoading,
    hasFailed,
    attachments,
}: SaveToRecordButtonProps) => {
    return (
        <Ds.Flex flexDirection="column" alignItems="flex-start" gap="1.5">
            {attachments && (
                <Ds.Flex.Item>
                    <Checkbox
                        id="includeAttachments"
                        checked={attachments.enabled}
                        onCheckedChange={attachments.onToggle}
                    >
                        <Checkbox.Indicator />
                        <Text skinny variant="preview">
                            Include attachments
                        </Text>
                    </Checkbox>
                </Ds.Flex.Item>
            )}
            <Ds.Flex.Item>
                <Ds.Flex flexDirection="column" gap="0.5">
                    <Ds.Flex.Item>
                        <Ds.Button
                            appearance="secondary"
                            size="small"
                            onClick={onClick}
                            loading={isLoading}
                            loadingMessage="Save to record"
                            data-testid="save-record-button"
                        >
                            <Ds.Button.Icon name="Save" />
                            Save to record
                        </Ds.Button>
                    </Ds.Flex.Item>
                    {hasFailed && !isLoading && (
                        <Ds.Flex alignItems="center" gap="0.5">
                            <Ds.Icon
                                name="Error"
                                appearance="solid"
                                size="small"
                                color="red"
                            />
                            <Text skinny colour="zinc">
                                Failed to save to record
                            </Text>
                        </Ds.Flex>
                    )}
                </Ds.Flex>
            </Ds.Flex.Item>
        </Ds.Flex>
    );
};
