import { useAnalytics } from "@accurx/analytics";
import { useCurrentUser } from "@accurx/auth";
import { usePatient } from "@accurx/concierge/hooks/data/usePatient";
import {
    AssignmentError,
    useUpdateAssigneeMutation,
} from "@accurx/concierge/hooks/mutations/useUpdateAssigneeMutation";
import {
    AssigneeSummary,
    Conversation,
    TeamSummary,
    UserSummary,
} from "@accurx/concierge/types";
import * as UI from "@accurx/design";
import { Hooks, Popover, PopoverTrigger } from "@accurx/design";
import { useToggle } from "@accurx/navigation";
import { ErrorBoundary, Log } from "@accurx/shared";
import { useConversationActionTrackingFields } from "domains/inbox/hooks/util/useConversatonActionAnalytics";
import { getNumberOfAssignments } from "domains/inbox/util/getNumberOfAssignments";
import { toast } from "react-toastify";

import { GenericErrorState } from "../GenericErrorState/GenericErrorState";
import {
    StyledErrorContainer,
    StyledPopoverContent,
} from "./AssigneeSelector.styles";
import { AssigneeSelectorForm } from "./components";
import { AssigneeButton } from "./components/AssigneeButton/AssigneeButton";
import { ValidAssigneeSummary } from "./components/AssigneeSelectorForm/AssigneeSelectorForm.types";
import { getAssigneeTrackingFields } from "./components/AssigneeSelectorForm/getAssigneeTrackingFields";

export type AssigneeSelectorProps = {
    conversation: Conversation;
    users: UserSummary[];
    teams?: TeamSummary[];
    unassignedTeam?: TeamSummary;
    onAssignedSuccess?: (payload: {
        newAssignee: AssigneeSummary;
        previousAssignee: AssigneeSummary;
    }) => void;
};

export const AssigneeSelector = ({
    conversation,
    users,
    teams,
    unassignedTeam,
    onAssignedSuccess,
}: AssigneeSelectorProps): JSX.Element => {
    const { toggle, isOpen } = useToggle();
    const track = useAnalytics();
    const { user } = useCurrentUser();
    const patient = usePatient({
        patientId: conversation.regardingPatientId ?? null,
    });

    const updateAssignee = useUpdateAssigneeMutation({
        onError: (e) => {
            if (e instanceof AssignmentError && e.reason === "AddNoteError") {
                Log.error("Adding a note using the Assignee Selector failed");
                toast(
                    <UI.Feedback
                        title="The assignee was updated but an error occured adding the note"
                        colour="error"
                    />,
                );
                return;
            }
            toast(
                <UI.Feedback
                    title="Failed to re-assign conversation, please try again!"
                    colour="error"
                />,
            );
        },
    });

    const getConversationActionTrackingFields =
        useConversationActionTrackingFields(conversation);

    const { size } = Hooks.useViewportSize();
    const enablePopoverAsModal = size === "xs" || size === "sm";

    const onSubmit = ({
        assignee,
        noteText,
    }: {
        assignee: ValidAssigneeSummary;
        noteText?: string;
    }) => {
        const assigneeTrackingFields = getAssigneeTrackingFields(
            user.accuRxUserId,
            assignee,
            teams ?? [],
            unassignedTeam,
        );

        track("ConversationAction Button Click", {
            ...getConversationActionTrackingFields({
                type: "Assign",
                appOrigin: "ConversationActions",
            }),
            ...assigneeTrackingFields,
            withNote: noteText ? noteText.length > 0 : false,
            noteLength: noteText ? noteText.length : 0,
        });

        updateAssignee.mutate(
            {
                conversation,
                assignee,
                note:
                    noteText && noteText.length > 0
                        ? {
                              text: noteText,
                              patientExternalIds: patient?.externalIds,
                          }
                        : null,
            },
            {
                onSettled: (_data, error) => {
                    track("ConversationAction Button Response", {
                        ...getConversationActionTrackingFields({
                            type: "Assign",
                            appOrigin: "ConversationActions",
                        }),
                        ...assigneeTrackingFields,
                        hasError: !!error,
                        withNote: noteText ? noteText.length > 0 : false,
                        noteLength: noteText ? noteText.length : 0,
                    });

                    toggle();
                    onAssignedSuccess?.({
                        newAssignee: assignee,
                        previousAssignee: conversation.assignee,
                    });
                },
            },
        );
    };

    const onSelectedAssigneeChanged = ({
        assignee,
        noteText,
    }: {
        assignee: ValidAssigneeSummary;
        noteText?: string;
    }) => {
        const {
            assigneeType,
            assigneeUserId,
            assigneeTeamName,
            assigneeTeamType,
            assigneeTeamId,
            isAssignedToSelf,
        } = getAssigneeTrackingFields(
            user.accuRxUserId,
            assignee,
            teams ?? [],
            unassignedTeam,
        );

        const {
            conversationDescription,
            conversationId,
            conversationStartTimestampUtc,
            conversationType,
            conversationParticipant,
            countItemsInConversation,
            countPatientMessageInbound,
            countPatientMessageOutbound,
            conversationRequestType,
        } = getConversationActionTrackingFields({
            type: "Assign",
            appOrigin: "ConversationActions",
        });

        track("ConversationAssignSelect MenuItem Click", {
            assigneeType,
            assigneeUserId,
            assigneeTeamName,
            assigneeTeamType,
            assigneeTeamId,
            isAssignedToSelf,
            conversationDescription,
            conversationId,
            conversationStartTimestampUtc,
            conversationType,
            conversationParticipant,
            countItemsInConversation,
            countPatientMessageInbound,
            countPatientMessageOutbound,
            conversationRequestType,
            withNote: !!noteText && noteText.length > 0,
            numberOfAssignments: getNumberOfAssignments(conversation),
            origin: "/inbox",
        });
    };

    const currentAssignee =
        conversation.assignee.type === "None"
            ? undefined
            : conversation.assignee;

    return (
        <Popover
            open={isOpen}
            onOpenChange={toggle}
            modal={enablePopoverAsModal}
        >
            <PopoverTrigger asChild={true}>
                <AssigneeButton
                    conversation={conversation}
                    onClick={() => {
                        const {
                            conversationDescription,
                            conversationId,
                            conversationStartTimestampUtc,
                            conversationType,
                            conversationParticipant,
                            countItemsInConversation,
                            countPatientMessageInbound,
                            countPatientMessageOutbound,
                            conversationRequestType,
                        } = getConversationActionTrackingFields({
                            type: "Assign",
                            appOrigin: "ConversationActions",
                        });
                        track("ConversationAssignSelect Button Click", {
                            conversationDescription,
                            conversationId,
                            conversationStartTimestampUtc,
                            conversationType,
                            conversationParticipant,
                            countItemsInConversation,
                            countPatientMessageInbound,
                            countPatientMessageOutbound,
                            conversationRequestType,
                            isOpening: !isOpen,
                            numberOfAssignments:
                                getNumberOfAssignments(conversation),
                            origin: "/inbox",
                        });
                    }}
                />
            </PopoverTrigger>
            <StyledPopoverContent
                aria-label="assign this conversation modal"
                align="start"
            >
                <ErrorBoundary
                    fallback={
                        <StyledErrorContainer>
                            <GenericErrorState>
                                <>
                                    Sorry, something went wrong.
                                    <br />
                                    Refresh the page to try again.
                                </>
                            </GenericErrorState>
                        </StyledErrorContainer>
                    }
                >
                    <AssigneeSelectorForm
                        currentAssignee={currentAssignee}
                        users={users}
                        teams={teams}
                        unassignedTeam={unassignedTeam}
                        onSubmit={onSubmit}
                        onCancel={toggle}
                        onSelectedAssigneeChanged={onSelectedAssigneeChanged}
                        isLoading={updateAssignee.isLoading}
                        canAddNote
                    />
                </ErrorBoundary>
            </StyledPopoverContent>
        </Popover>
    );
};
