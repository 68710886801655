import { Button, Ds } from "@accurx/design";

type DeleteReminderModalProps = {
    isOpen: boolean;
    setOpen: (isOpen: boolean) => void;
    areButtonsDisabled: boolean;
    onCancel: () => void;
    onDelete: () => void;
};

export function DeleteReminderModal({
    isOpen,
    setOpen,
    onCancel,
    onDelete,
    areButtonsDisabled,
}: DeleteReminderModalProps) {
    return (
        <Ds.Modal open={isOpen} onOpenChange={setOpen}>
            <Ds.Modal.Section>
                <Ds.Modal.Title>
                    Are you sure you want to delete this appointment reminder?
                </Ds.Modal.Title>
            </Ds.Modal.Section>
            <Ds.Modal.Divider />

            <Ds.Modal.Section className="bg-light">
                <Ds.Text>
                    Deleting this reminder means that any patients who were
                    going to receive this message in the next 24 hours before
                    their scheduled appointment will no longer receive it.
                </Ds.Text>
            </Ds.Modal.Section>
            <Ds.Modal.Divider />
            <Ds.Modal.Section>
                <Ds.Flex alignItems="center" justifyContent="space-between">
                    <Button
                        text="Cancel"
                        theme="secondary"
                        onClick={onCancel}
                        disabled={areButtonsDisabled}
                    />
                    <Button
                        text="Delete this reminder"
                        theme="danger"
                        onClick={onDelete}
                        disabled={areButtonsDisabled}
                    />
                </Ds.Flex>
            </Ds.Modal.Section>
        </Ds.Modal>
    );
}
