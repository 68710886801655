import { useWorkspaceActivityQuery } from "./useWorkspaceActivityQuery";

/**
 * Some workspaces may have not be contactable via reply from web - perhaps
 * they closed down, got the Accumail feature disabled, or just stopped using it
 * and got marked as inactive. This hook finds all the other workspaces that are
 * participants in the conversation whether they are available / unavailable for contact.
 */
export const useUnavailableWorkspaces = ({
    workspaceIds,
    currentWorkspaceId,
}: {
    workspaceIds: number[];
    currentWorkspaceId: number;
}): {
    isLoading: boolean;
    unavailableWorkspaces: number[];
} => {
    const queries = useWorkspaceActivityQuery({
        workspaceIds,
        currentWorkspaceId,
    });

    const unavailableWorkspaces = workspaceIds.filter((_, index) => {
        const query = queries[index];
        if (!query || query.status === "error") return true;
        return query.status === "success" && !query.data.isOpenToReplies;
    });

    const isLoading = queries.some(({ status }) => status === "loading");

    return {
        isLoading,
        unavailableWorkspaces,
    };
};
