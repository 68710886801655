import React, { useEffect } from "react";

import { Accordion, Card, Feedback, Icon, Spinner, Text } from "@accurx/design";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { generatePath } from "react-router-dom";

import { StepsFooter } from "app/sharedComponents/footer/StepsFooter";
import { UpdatingStatus } from "shared/LoadingStatus";
import { ROUTES_WORKSPACE } from "shared/Routes";

import { boosterCopy } from "../VaccineCopy";
import { VaccineCourse } from "../models/VaccineDeliveryDTO";
import { VaccineDeliveryHeader } from "../shared/VaccineDeliveryComponents";
import { getVaccineDeliveryDetails } from "../vaccineInvitesOldPage/vaccineDelivery.actions";
import { initialState as VaccineAllInvitesInitialState } from "../vaccineInvitesOldPage/vaccineDelivery.reducer";
import { VaccineDeliveryComposePatientTable } from "./VaccineDeliveryComposePatientTable";
import {
    VaccineDeliveryAccordionDetails,
    getAllPatientWhoNeedBoosterAndHaveBeenPreviouslyUploaded,
    getAllPatientsWhoNeedFirstJab,
    getAllPatientsWhoNeedSecondJabAndHaveBeenPreviouslyUploaded,
    getPatientsWhoNeedBoosterWhoHaveBeenInvitedForBooster,
    getPatientsWhoNeedBoosterWhoHaveNotBeenInvitedForBoosterAndNotAlreadyUploaded,
    getPatientsWhoNeedSeasonalBoosterWhoHaveBeenInvitedForSeasonalBooster,
    getPatientsWhoNeedSeasonalBoosterWhoHaveNotBeenInvitedForSeasonalBoosterAndNotAlreadyUploaded,
    getPatientsWhoNeedSecondJabWhoHaveBeenInvitedForSecond,
    getPatientsWhoNeedSecondJabWhoHaveNotBeenInvitedForSecondAndNotAlreadyUploaded,
    getTotalSteps,
} from "./VaccineDeliveryUploadAndComposeHelpers";

export const VaccineDeliveryReviewInvites = (): JSX.Element => {
    const practiceId = useSelector(
        ({ practices }: ApplicationState) => practices.selectedPractice,
    );

    const getDetailsStatus = useSelector(
        ({ vaccineDelivery }: ApplicationState) =>
            vaccineDelivery?.getDetailsStatus ||
            VaccineAllInvitesInitialState.getDetailsStatus,
        shallowEqual,
    );
    const vaccineDeliveryId = useSelector(
        ({ vaccineDelivery }: ApplicationState) =>
            vaccineDelivery?.vaccineDeliveryId ||
            VaccineAllInvitesInitialState.vaccineDeliveryId,
        shallowEqual,
    );
    const patientList = useSelector(
        ({ vaccineDelivery }: ApplicationState) =>
            vaccineDelivery?.vaccineDeliveryDetails.items ||
            VaccineAllInvitesInitialState.vaccineDeliveryDetails.items,
        shallowEqual,
    );
    const vaccineCourse = useSelector(
        ({ vaccineDelivery }: ApplicationState) =>
            vaccineDelivery?.vaccineCourse ||
            VaccineAllInvitesInitialState.vaccineCourse,
        shallowEqual,
    );

    const isBooster = vaccineCourse !== VaccineCourse.Primary;
    const boosterLabel = boosterCopy(vaccineCourse);

    const dispatch = useDispatch();

    // Re-fetch the batch to get up-to-date figures on who has been invited and not
    useEffect(() => {
        // Already re-fetched the batch on the UploadSecond page in the normal flow, so no need to re-do it here
        if (isBooster && practiceId && vaccineDeliveryId) {
            dispatch(getVaccineDeliveryDetails(practiceId, vaccineDeliveryId));
        }
    }, [isBooster, dispatch, practiceId, vaccineDeliveryId]); // only run this on the first load of the page

    // Lists to show for primary
    const firstJabPatients = getAllPatientsWhoNeedFirstJab(patientList);
    const secondJabInvitedPatients =
        getPatientsWhoNeedSecondJabWhoHaveBeenInvitedForSecond(patientList);
    const secondJabNotInvitedAndNotAlreadyUploadedPatients =
        getPatientsWhoNeedSecondJabWhoHaveNotBeenInvitedForSecondAndNotAlreadyUploaded(
            patientList,
        );
    const secondJabAlreadyUploadedPatients =
        getAllPatientsWhoNeedSecondJabAndHaveBeenPreviouslyUploaded(
            patientList,
        );

    // Lists to show for booster
    const boosterJabInvitedPatients =
        getPatientsWhoNeedBoosterWhoHaveBeenInvitedForBooster(patientList);
    const seasonalBoosterJabInvitedPatients =
        getPatientsWhoNeedSeasonalBoosterWhoHaveBeenInvitedForSeasonalBooster(
            patientList,
        );
    const boosterJabNotInvitedAndNotAlreadyUploadedPatients =
        getPatientsWhoNeedBoosterWhoHaveNotBeenInvitedForBoosterAndNotAlreadyUploaded(
            patientList,
        );
    const seasonalBoosterJabNotInvitedAndNotAlreadyUploadedPatients =
        getPatientsWhoNeedSeasonalBoosterWhoHaveNotBeenInvitedForSeasonalBoosterAndNotAlreadyUploaded(
            patientList,
        );
    const boosterJabAlreadyUploadedPatients =
        getAllPatientWhoNeedBoosterAndHaveBeenPreviouslyUploaded(patientList);

    const renderAccordions = (
        lists: VaccineDeliveryAccordionDetails[],
    ): JSX.Element[] => {
        return lists.map((details) => {
            return (
                <Accordion
                    key={details.testId}
                    className="mb-2"
                    icon={{ name: "Done", colour: "blue" }}
                    header={`${details.list.length} ${details.listSuffix}`}
                >
                    <VaccineDeliveryComposePatientTable
                        filteredList={details.list}
                        testIdPrefix={details.testId}
                        showNims={false}
                    />
                </Accordion>
            );
        });
    };

    const renderPrimaryAccordions = (): JSX.Element[] => {
        return renderAccordions([
            {
                list: firstJabPatients,
                listSuffix: "patients invited to 1st vaccination",
                testId: "firstJab",
            },
            {
                list: secondJabInvitedPatients,
                listSuffix: "patients invited to 2nd vaccination",
                testId: "secondJabInvited",
            },
            {
                list: secondJabNotInvitedAndNotAlreadyUploadedPatients,
                listSuffix:
                    "patients added to accuBook and awaiting invite to 2nd vaccination",
                testId: "secondJabNotInvited",
            },
        ]);
    };

    const renderBoosterAccordions = (): JSX.Element[] => {
        if (vaccineCourse === VaccineCourse.Booster) {
            return renderAccordions([
                {
                    list: boosterJabInvitedPatients,
                    listSuffix: "patients invited to booster vaccination",
                    testId: "boosterInvited",
                },
                {
                    list: boosterJabNotInvitedAndNotAlreadyUploadedPatients,
                    listSuffix:
                        "patients added to accuBook and awaiting invite to booster vaccination",
                    testId: "boosterNotInvited",
                },
            ]);
        } else {
            return renderAccordions([
                {
                    list: seasonalBoosterJabInvitedPatients,
                    listSuffix: `patients invited to ${boosterLabel} vaccination`,
                    testId: "boosterInvited",
                },
                {
                    list: seasonalBoosterJabNotInvitedAndNotAlreadyUploadedPatients,
                    listSuffix: `patients added to accuBook and awaiting invite to ${boosterLabel} vaccination`,
                    testId: "boosterNotInvited",
                },
            ]);
        }
    };

    const alreadyUploadedPatientsCount = isBooster
        ? boosterJabAlreadyUploadedPatients.length
        : secondJabAlreadyUploadedPatients.length;

    return (
        <div>
            <div className="text-center">
                <VaccineDeliveryHeader
                    stepNumber={vaccineCourse !== VaccineCourse.Primary ? 4 : 6}
                    totalSteps={getTotalSteps(vaccineCourse)}
                    title={"Review uploaded patients"}
                />
            </div>
            <div className="row mb-5">
                <div className="col-8 offset-md-2">
                    {getDetailsStatus === UpdatingStatus.Loading && <Spinner />}
                    {getDetailsStatus === UpdatingStatus.Loaded && (
                        <>
                            <Card props={{ className: "mb-2" }} spacing={2}>
                                <>
                                    <Icon
                                        name="Done"
                                        colour="blue"
                                        size={3}
                                        props={{ className: "mr-2" }}
                                    />
                                    <Text variant="label" as="span" skinny>
                                        {patientList.length} patients uploaded
                                    </Text>
                                    {alreadyUploadedPatientsCount > 0 && (
                                        <Text variant="preview" skinny>
                                            {alreadyUploadedPatientsCount}{" "}
                                            patients already uploaded to
                                            accuBook
                                        </Text>
                                    )}
                                </>
                            </Card>
                            {isBooster
                                ? renderBoosterAccordions()
                                : renderPrimaryAccordions()}
                            {isBooster && (
                                <Feedback
                                    colour="information"
                                    title="What do I need to do next for patients not ready to invite?"
                                >
                                    From your “Manage Patients” tab, you can see
                                    details of previous vaccinations for your
                                    patients and invite those who are getting
                                    close to being eligible.
                                </Feedback>
                            )}
                        </>
                    )}
                    {getDetailsStatus === UpdatingStatus.Failed && (
                        <Text variant="title" colour="red">
                            There was an error loading the upload details,
                            please click below to return to the patient list and
                            manage invites from there
                        </Text>
                    )}
                </div>
            </div>

            <StepsFooter
                forwardText="Continue to all patients list"
                forwardLink={generatePath(
                    ROUTES_WORKSPACE.accubookManagePatients,
                    { workspaceId: practiceId },
                )}
                disabled={false}
            />
        </div>
    );
};

export default VaccineDeliveryReviewInvites;
