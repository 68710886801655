import {
    FeatureName,
    WorkspaceFeatureGuard,
    useCurrentUser,
} from "@accurx/auth";
import { useBrowserEnvironment } from "@accurx/native";
import NotFound from "NotFound";
import { Route, Switch, useParams } from "react-router";

import { Inbox } from "./Inbox";
import { Reload } from "./components";
import { CurrentlyViewingContextProvider } from "./components/CurrentlyViewingContext/CurrentlyViewingContextProvider";
import { InboxContextProvider } from "./components/InboxContext/InboxContextProvider";
import { OnlineUsersContextProvider } from "./components/OnlineUsersContext/OnlineUsersContextProvider";
import { NativeProvider } from "./native/provider";

const InboxWithProviders = () => {
    return (
        <NativeProvider>
            <CurrentlyViewingContextProvider>
                <OnlineUsersContextProvider>
                    <InboxContextProvider>
                        <Inbox />
                    </InboxContextProvider>
                </OnlineUsersContextProvider>
            </CurrentlyViewingContextProvider>
        </NativeProvider>
    );
};

/**
 * Renders simple unified inbox routes
 * without any guarding
 */
const SimpleInbox = () => {
    return <InboxWithProviders />;
};

/**
 * Inbox routes guarded behind
 * workspace not being found or
 * feature flag missing
 */
const GuardedInbox = () => {
    const { user } = useCurrentUser();
    const { workspaceId: searchParam } = useParams<{
        workspaceId?: string;
    }>();

    const workspaceId = searchParam ? parseInt(searchParam, 10) : null;

    const workspace =
        user.organisations.find((org) => org.orgId === workspaceId) ?? null;

    if (workspace === null) {
        return <NotFound />;
    }

    return (
        <WorkspaceFeatureGuard
            all={[FeatureName.AccurxWebEmbeddedUnifiedInbox]}
        >
            <InboxWithProviders />
        </WorkspaceFeatureGuard>
    );
};

export const App = () => {
    const isWebView = useBrowserEnvironment() === "WebView";

    return (
        <Switch>
            <Route path="/inbox/w/:workspaceId">
                {isWebView ? <SimpleInbox /> : <GuardedInbox />}
            </Route>
            <Route path="/inbox">
                <NotFound />
            </Route>
            <Route path="*" component={Reload} />
        </Switch>
    );
};
