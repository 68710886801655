import React, { MouseEvent } from "react";

import { Ds, Text } from "@accurx/design";

import {
    PatientListVideoInviteDetails,
    PatientVideoProgressResponse,
} from "api/FlemingDtos";
import { useAppSelector } from "store/hooks";

interface ProgressDefaultColor {
    badgeLuminosity: "high" | "low";
    hasIcon: boolean;
}

interface VideoProgressData extends ProgressDefaultColor {
    copy: string;
}

const defaultColours: ProgressDefaultColor = {
    badgeLuminosity: "high",
    hasIcon: false,
};

const getVideoProgressData = (
    videoProgress: PatientVideoProgressResponse | undefined,
    hasSentVideoConsultInvite: boolean,
): VideoProgressData | undefined => {
    if (videoProgress) {
        if (videoProgress.numberOfPeopleInCall > 0) {
            return {
                badgeLuminosity: "low",
                hasIcon: true,
                copy:
                    videoProgress.numberOfPeopleInCall === 1
                        ? "1 person on the call"
                        : `${videoProgress.numberOfPeopleInCall} people on the call`,
            };
        }
        if (videoProgress.patientLastSeenInCall) {
            return {
                ...defaultColours,
                copy: "Recipient device set up",
            };
        }
        if (videoProgress.patientSettingUpDevice) {
            return {
                ...defaultColours,
                copy: "Recipient setting up device",
            };
        }
        if (videoProgress.patientClickedLink) {
            return {
                ...defaultColours,
                copy: "Opened Video Consult link",
            };
        }
        if (videoProgress.patientMessageDelivered) {
            return {
                ...defaultColours,
                copy: "Video Consult link received",
            };
        }
        if (videoProgress.patientMessageSent) {
            return {
                ...defaultColours,
                copy: "Video Consult link sent",
            };
        }
        return {
            ...defaultColours,
            copy: "Sending Video Consult link",
        };
    }
    if (hasSentVideoConsultInvite) {
        return {
            ...defaultColours,
            copy: "Video Consult appointment sent",
        };
    }
    return undefined;
};

export interface PatientEntryVideoStatusProps
    extends PatientListVideoInviteDetails {
    hasVideoScheduledInviteSent?: boolean;
}

const PatientEntryVideoStatusComponent = ({
    videoConsultUrl,
    videoConsultId,
    hasSentVideoConsultInvite,
}: PatientEntryVideoStatusProps): JSX.Element | null => {
    const videoProgress = useAppSelector((state) => {
        if (!videoConsultId) {
            return undefined;
        }
        const allVideoStatus =
            state.patientLists.currentListVideoProgressStatus;
        return allVideoStatus?.find(
            (item: PatientVideoProgressResponse) =>
                item.consultationId === videoConsultId,
        );
    });

    const videoProgressData: VideoProgressData | undefined =
        getVideoProgressData(videoProgress, hasSentVideoConsultInvite);

    if (!videoProgressData) {
        return null;
    }

    const handleClick = (e: MouseEvent<HTMLAnchorElement>): void => {
        e.preventDefault();
        window.open(videoConsultUrl);
    };

    return (
        <span className="d-flex align-items-center">
            <Ds.Badge
                color="green"
                luminosity={videoProgressData.badgeLuminosity}
            >
                {videoProgressData.hasIcon && <Ds.Icon name="Tick" />}
                {videoProgressData.copy}
            </Ds.Badge>
            {videoConsultUrl && videoConsultId && (
                <Text
                    variant="link"
                    as="a"
                    props={{
                        href: "#",
                        className: "mb-0 mx-2",
                        onClick: handleClick,
                    }}
                >
                    Join call
                </Text>
            )}
        </span>
    );
};

export default PatientEntryVideoStatusComponent;
