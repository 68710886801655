import { Card, Ds, Feedback, FormFieldWrapper } from "@accurx/design";
import { SharedUrls } from "@accurx/shared";

type TemplateSettingsCardProps = {
    isSendViaIndividualMessageChecked: boolean;
    isSendViaBatchMessageChecked: boolean;
    isAllowPatientsToRespondChecked: boolean;
    onSendViaIndividualMessageChange: (checked: boolean) => void;
    onSendViaBatchMessageChange: (checked: boolean) => void;
    onAllowPatientResponseChange: (checked: boolean) => void;
};

export function TemplateSettingsCard({
    isSendViaIndividualMessageChecked,
    isSendViaBatchMessageChecked,
    isAllowPatientsToRespondChecked,
    onSendViaIndividualMessageChange,
    onSendViaBatchMessageChange,
    onAllowPatientResponseChange,
}: TemplateSettingsCardProps) {
    const disableAllowResponseByDefault = !isSendViaIndividualMessageChecked;

    return (
        <Card
            header={
                <Ds.Text size="large" weight="bold">
                    Template settings
                </Ds.Text>
            }
        >
            <Ds.Flex gap="2" flexDirection="column">
                <FormFieldWrapper
                    as="fieldset"
                    label={<Ds.Text weight="bold">Template available</Ds.Text>}
                >
                    <Ds.Flex
                        gap="2"
                        flexDirection={{
                            xs: "column",
                            sm: "row",
                        }}
                    >
                        <Ds.Checkbox
                            id="sendViaIndividualMessaging"
                            name="sendViaIndividualMessaging"
                            appearance="solid"
                            checked={isSendViaIndividualMessageChecked}
                            onCheckedChange={onSendViaIndividualMessageChange}
                        >
                            <Ds.Checkbox.Indicator />
                            <Ds.Checkbox.Label htmlFor="sendViaIndividualMessaging">
                                Individual
                            </Ds.Checkbox.Label>
                        </Ds.Checkbox>
                        <Ds.Checkbox
                            id="sendViaBatchMessaging"
                            name="sendViaBatchMessaging"
                            appearance="solid"
                            checked={isSendViaBatchMessageChecked}
                            onCheckedChange={onSendViaBatchMessageChange}
                        >
                            <Ds.Checkbox.Indicator />
                            <Ds.Checkbox.Label htmlFor="sendViaBatchMessaging">
                                Batch
                            </Ds.Checkbox.Label>
                        </Ds.Checkbox>
                    </Ds.Flex>
                </FormFieldWrapper>
                <FormFieldWrapper
                    as="fieldset"
                    label={
                        <Ds.Text weight="bold">Default reply setting</Ds.Text>
                    }
                    subLabel={
                        "The patients will be able to respond once, with a photo or a message. Not applicable if sending in batch."
                    }
                >
                    <Ds.Checkbox
                        id="allowPatientsToRespond"
                        appearance="solid"
                        checked={isAllowPatientsToRespondChecked}
                        onCheckedChange={onAllowPatientResponseChange}
                        disabled={disableAllowResponseByDefault}
                    >
                        <Ds.Checkbox.Indicator />
                        <Ds.Checkbox.Label htmlFor="allowPatientsToRespond">
                            Allow patients to respond
                        </Ds.Checkbox.Label>
                    </Ds.Checkbox>
                </FormFieldWrapper>
                {isSendViaBatchMessageChecked &&
                    !disableAllowResponseByDefault && (
                        <Feedback
                            colour={"secondary"}
                            title={
                                "Patients can't respond when you send in batch"
                            }
                        >
                            You can use questionnaires to record patients'
                            responses.{" "}
                            <Ds.Link href={SharedUrls.Floreys} target="_blank">
                                Questionnaires
                            </Ds.Link>
                        </Feedback>
                    )}
            </Ds.Flex>
        </Card>
    );
}
