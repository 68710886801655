import { createMutationHook } from "@accurx/api/QueryClient";
import {
    EmailValidationResponse,
    EmailValidity,
} from "@accurx/api/clinician-messaging";
import { httpClient2 } from "@accurx/shared";

export const EmailValidationKey = "EmailValidation";

type EmailValidationArgs = {
    emailAddress: string;
    workspaceId: number;
};

export const useEmailValidation = createMutationHook<
    EmailValidationArgs,
    EmailValidationResponse
>(() => ({
    mutationKey: [EmailValidationKey],
    mutationFn: async ({ emailAddress, workspaceId }) => {
        const response = await httpClient2
            .post({
                path: "/api/clinicianmessaging/directory/:workspaceId/contacts/validate",
                params: {
                    workspaceId,
                },
                json: { emailAddress },
            })
            .json<EmailValidationResponse>();
        if (response.status === EmailValidity.Valid) {
            return response;
        }

        const message =
            response.status === EmailValidity.Invalid
                ? "Enter a valid email address"
                : "Enter a secure email address";

        throw new EmailValidationError(message, response.status);
    },
}));

export class EmailValidationError extends Error {
    status: EmailValidity;

    constructor(message: string, status: EmailValidity) {
        super(message);
        this.status = status;
        Object.setPrototypeOf(this, EmailValidationError.prototype);
    }
}
