import { Fields, useAnalytics } from "@accurx/analytics";

type FilterName = Extract<Fields, { key: "filterName" }>["value"];

type FilterButtonClickFields = {
    appOrigin: "ConversationList" | "TemplateList";
    filterName: FilterName;
};

type FilteMenuItemClickFields = {
    appOrigin: "Sidebar" | "ConversationList" | "TemplateList";
    filterName: FilterName;
};

export const useConversationFilterAnalytics = () => {
    const track = useAnalytics();

    const trackFilterButtonClick = ({
        appOrigin,
        filterName,
    }: FilterButtonClickFields) => {
        track("ConversationFilter Button Click", {
            appOrigin,
            filterName,
            eventVersion: 2,
        });
    };

    const trackFilterMenuItemClick = ({
        appOrigin,
        filterName,
    }: FilteMenuItemClickFields) => {
        track("ConversationFilter MenuItem Click", {
            appOrigin,
            filterName,
            conversationParticipant: "WithPatient",
            eventVersion: 3,
        });
    };

    return {
        trackFilterButtonClick,
        trackFilterMenuItemClick,
    };
};
