import { usePatient } from "@accurx/concierge/hooks/data/usePatient";
import { GenericNoteItem } from "@accurx/concierge/schemas/ConversationItemSchema";
import * as UI from "@accurx/design";
import { Ds } from "@accurx/design";
import { formatPatientName } from "@accurx/patient";
import {
    ConversationItemBadgeContainer,
    ConversationItemContainer,
    ConversationItemContent,
    ConversationItemFooter,
    ConversationItemHeader,
    ConversationThreadListItem,
} from "domains/inbox/components/ConversationItem/ConversationItem.styles";
import { AccurxLogoAvatar } from "domains/inbox/components/ConversationItem/components/AccurxLogoAvatar/AccurxLogoAvatar";
import { CreatedAtTime } from "domains/inbox/components/ConversationItem/components/CreatedAtTime/CreatedAtTime";
import { MessageNameTag } from "domains/inbox/components/ConversationItem/components/MessageNameTag/MessageNameTag";
import { ConversationItemComponent } from "domains/inbox/components/ConversationItem/types";
import { getItemAlignment } from "domains/inbox/components/ConversationView/components/ConversationThread/conversationThread.utils";
import { userflowIds } from "domains/inbox/util";
import ReactMarkdown from "react-markdown";

const getThemeColour = (
    theme: GenericNoteItem["theme"],
): "blue" | "yellow" | "red" => {
    switch (theme) {
        case "Information":
            return "blue";
        case "Warning":
            return "yellow";
        case "Alarm":
            return "red";
    }
};

export const GenericNoteMessage = ({
    item,
}: ConversationItemComponent<GenericNoteItem>) => {
    const colour = getThemeColour(item.theme);

    const patientId =
        item.createdBy.type === "Patient" ? item.createdBy.id : null;
    const patient = usePatient({ patientId });

    const displayName = patient
        ? formatPatientName(patient)
        : item.fallbackSenderDisplayName;

    return (
        <ConversationThreadListItem
            $align={getItemAlignment({
                item,
            })}
        >
            <ConversationItemContainer
                data-testid={`conversation-item-${item.id}`}
                $color={colour}
            >
                <ConversationItemHeader>
                    <MessageNameTag
                        avatar={
                            item.createdBy.type === "Patient" ? (
                                <Ds.Avatar
                                    size="small"
                                    color={
                                        item.theme === "Information"
                                            ? "blue"
                                            : "greyscale"
                                    }
                                />
                            ) : (
                                <AccurxLogoAvatar />
                            )
                        }
                        name={displayName}
                    />

                    <ConversationItemBadgeContainer
                        data-userflow-id={
                            userflowIds.conversationView.messageHeaderLabel
                        }
                    >
                        <UI.Ds.Badge color={colour} size="small">
                            {item.createdBy.type === "Patient"
                                ? "Patient"
                                : "System"}
                        </UI.Ds.Badge>
                    </ConversationItemBadgeContainer>
                </ConversationItemHeader>
                <ConversationItemContent>
                    <ReactMarkdown linkTarget="_blank">
                        {item.markdown}
                    </ReactMarkdown>
                </ConversationItemContent>
                <ConversationItemFooter>
                    {/*We do not show the readBy tooltip as the user state is not supported for generic notes*/}
                    <div />
                    <CreatedAtTime time={item.createdAt} />
                </ConversationItemFooter>
            </ConversationItemContainer>
        </ConversationThreadListItem>
    );
};
