import { Ds, Feedback } from "@accurx/design";
import { useIsMutating } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { useChangeMedicalRecordPatientMutation } from "../hooks/useChangeMedicalRecordPatientMutation";
import { useMedicalRecordConnection } from "../hooks/useMedicalRecordConnection";
import { PatientExternalId } from "../schemas/PatientExternalIdSchema";
import { getPrincipalSystemFriendlyName } from "../utils/getPrincipalSystemFriendlyName";

export type OpenInEmrButtonProps = {
    patientExternalIds: PatientExternalId[];
};

export const OpenInEmrButton = ({
    patientExternalIds,
}: OpenInEmrButtonProps) => {
    const medicalRecordConnection = useMedicalRecordConnection();
    const { mutate: changeMedicalRecordPatient, isLoading } =
        useChangeMedicalRecordPatientMutation();

    const isMutating = useIsMutating({ mutationKey: ["changeMedicalRecord"] });

    const medicalRecordSystemFriendlyName = getPrincipalSystemFriendlyName(
        medicalRecordConnection.system,
    );
    const shouldShowButton =
        medicalRecordConnection.capabilities?.openInEMR &&
        medicalRecordSystemFriendlyName;

    if (!shouldShowButton) return null;
    return (
        <Ds.Button
            appearance="secondary"
            size="small"
            aria-label={`Open this patient in ${medicalRecordSystemFriendlyName}.`}
            title={`Open this patient in ${medicalRecordSystemFriendlyName}`}
            loadingMessage={medicalRecordSystemFriendlyName}
            onClick={() => {
                changeMedicalRecordPatient(
                    {
                        patientExternalIds,
                    },
                    {
                        onError: () => {
                            toast(
                                <Feedback
                                    title="We had an issue loading this patient in your medical record. Please try again."
                                    colour="error"
                                />,
                            );
                        },
                    },
                );
            }}
            loading={isLoading || isMutating > 0}
        >
            {medicalRecordSystemFriendlyName}
            <Ds.Button.Icon name="OpenWindow" />
        </Ds.Button>
    );
};
