import React from "react";

import { Patient } from "@accurx/api/portal";
import { FeatureName, Organisation } from "@accurx/auth";
import { Spinner } from "@accurx/design";
import { Log } from "@accurx/shared";
import {
    SUPPORT_ARTICLE_WORKSPACE_GUIDANCE,
    WORKSPACE_MANAGEMENT_ROUTES,
    isArchivedWorkspace,
} from "@accurx/workspace-management";
import { connect } from "react-redux";
import {
    Redirect,
    RouteComponentProps,
    Switch,
    generatePath,
    matchPath,
    withRouter,
} from "react-router-dom";
import { SelectedOrganisationController } from "reduxQuarantine/SelectedOrganisationController";
import { SelectedOrganisationContext } from "reduxQuarantine/SelectedOrganisationProvider";

import { AnalyticsMapper, FlemingAnalyticsTracker } from "app/analytics";
import { Routes as OrganisationRoutes } from "app/organisations";
import { WorkspaceRoutes } from "app/workspace/WorkspaceRoutes";
import { PatientHelper } from "shared/PatientHelper";
import {
    ORGANISATION_ROUTES_REDIRECTED,
    PATIENT_LISTS_ID_ROUTE,
    ROUTES,
    ROUTES_BASE,
    ROUTES_CHAIN,
    ROUTES_EXTENSION,
    ROUTES_ORGS,
    ROUTES_PRIMARY,
    ROUTES_REDIRECTED,
    RedirectionPaths,
    redirectUserToChain,
} from "shared/Routes";
import { NEXT_URL_QUERY_PARAM, getStringQuery } from "shared/RoutesHelper";
import { checkCdnAccessibility } from "utils/checkCdnAccessibility";

import NotFound from "../NotFound";
import { LayoutController } from "../layouts/LayoutController";
import { AccurxWebProviders } from "./AccurxWebProviders";
import * as AppActions from "./AppActions";
import {
    AppRoute,
    AppRouteProps,
    GuardedAppRouteRedirectProps,
    SimpleRoute,
} from "./AppRoute";
import { ComposeRoutesWithFullScreenPage } from "./ComposeRoutesWithFullScreenPage/ComposeRoutesWithFullScreenPage";
import { FeatureGuard } from "./FeatureGuard";
import { Home } from "./Home";
import { ManageOrgRedirects } from "./ManageOrgRedirects";
import { PatientProfileRedirect } from "./PatientProfileRedirect";
import * as AccountActions from "./account/AccountActions";
import { AccountPageView } from "./account/AccountPageView";
import type { AccountState } from "./account/AccountState.types";
import { DownloadDesktop } from "./account/Download/DownloadDesktop";
import { ActiveDirectoryLogin } from "./account/LogIn/ActiveDirectoryLogin/ActiveDirectoryLogin";
import { LoginComponent } from "./account/LogIn/LogInComponent";
import { OrgLogin } from "./account/LogIn/OrganisationsLogin/OrgLogin";
import { PostSsoRedirect } from "./account/LogIn/PostSsoRedirect";
import { RecordViewLogin } from "./account/LogIn/RecordViewLogin/RecordViewLogin";
// import { PatientProfile } from "./patientProfile";
import { PostLoginRedirect } from "./account/PostLoginRedirectComponent";
import { ProductLoginRoutesSection } from "./account/ProductLoginRoutesSection";
import { SignOutEverywhere } from "./account/SignOutEverywhere";
import { SsoErrorUnableToLookupOds } from "./account/SsoFailedErrors/SsoErrorUnableToLookupOdsPage";
import { SsoFailed } from "./account/SsoFailedErrors/SsoFailedErrorPage";
import { SsoErrorNoOdsCode } from "./account/SsoFailedErrors/SsoNoOdsCodeErrorPage";
import { ClinicianConversation } from "./clinicianconversation/ClinicianConversation";
import { ClinicianConversationProvider } from "./clinicianconversation/providers";
import { CookiesModal } from "./cookies/CookiesModal";
import { ChooseOrgPage } from "./customQuestionnaireImport/chooseOrg/ChooseOrgPage";
import { DeletedPage } from "./deletedPage/DeletedPage";
import { GuardedRoute } from "./guardedRoutes/GuardedRoute";
import {
    ACCOUNT_SETTINGS_ROUTES,
    AccountSettingsRoutesSection,
} from "./guardedRoutes/routes/AccountSettingsRoutesSection";
import { BatchMessagingRoutesSection } from "./guardedRoutes/routes/BatchMessagingRoutesSection";
import {
    ONBOARDING_ROUTES,
    OnboardingRoutesSection,
} from "./guardedRoutes/routes/OnboardingRoutesSection";
import {
    RECORD_VIEW_ROUTES,
    RecordViewRoutesSection,
} from "./guardedRoutes/routes/RecordViewRoutesSection";
import { Container } from "./layout/Container";
import MessagePractice from "./messagePractice/MessagePracticeContainer";
import { AllPatientLists } from "./patientLists/AllPatientLists";
// TODO remove non-lazy import after code-splitting rollout
import { PatientListPage } from "./patientLists/PatientListPage";
import * as PatientListsStore from "./patientLists/PatientListsReducer";
// TODO remove non-lazy import after code-splitting rollout
import { PracticesRoutes } from "./practices/PracticesRoutes";
import { EXAMPLE_REQUEST_ID } from "./recordView/RecordView.constant";
import { actionCreators as searchForPatientActions } from "./searchForPatient/SearchForPatientActions";
// TODO remove non-lazy import after code-splitting rollout
import SendMessage from "./sendMessage/SendMessageContainer";
import { SLIDEOUT_PORTAL_ID } from "./sharedComponents/SlideOut";
// import { PatientListPage, AllPatientLists } from "./patientLists";
import VideoLobby from "./videoLobby/VideoLobbyContainer";
import { SelectedAllowedOrganisationProvider } from "./workspace/context/SelectedAllowedOrganisationProvider";

//If you update the props, then you need to update code at bottom of file to grab new state from store
export type AppProps = AccountState & {
    patientLists: PatientListsStore.PatientListsState;
} & { is2FAed: boolean } & {
    hasSearchedForPatient: boolean;
    patient: Patient | null | undefined;
} & typeof AppActions.actionCreators &
    typeof AccountActions.actionCreators & {
        // Need to route guard the message history patient if refreshed as you lose the data for the searched patient
        setPatientFromSearchResponse: typeof searchForPatientActions.searchForPatientFinished;
    } & RouteComponentProps<Record<string, string | undefined>>; // ... plus incoming routing parameters

export class App extends React.Component<AppProps> {
    static contextType = SelectedOrganisationContext;
    declare context: React.ContextType<typeof SelectedOrganisationContext>;

    public componentDidMount(): void {
        const search = this.props.location.search;
        const isEmbedded = new URLSearchParams(search).has("embed");

        if (isEmbedded) {
            this.props.setEmbeddedMode(isEmbedded);
        }
    }

    componentDidUpdate(prevProps: AppProps): void {
        // application-wide work that needs to happen at app startup goes here
        const { user, selectedOrganisation } = this.props;

        const hasOrganisationChanged =
            prevProps.selectedOrganisation !== selectedOrganisation;

        /*
         * TODO remove this when we have the answer we're looking for
         * TEMPORARY CHECK TO SEE IF AZURE CDN IS ACCESSIBLE
         *
         * We want to put fleming-client behind a CDN - but the CDN doesn't
         * allow us to specify a static IP, and some customers - e.g. prisons -
         * require a static IP as they lock down their network to only a certain
         * few allowed sites.
         *
         * However it may be that they already allow the Azure CDN as it's quite
         * likely many of them already use Microsoft products or other services
         * which use Microsoft products - so we want to get a sense of how
         * big a problem this might be before we try to come up with a complex
         * brittle fix for it.
         *
         * So for now, when we first load the page with an organisation id set,
         * we try to make a HEAD request to a url behind a CDN and see if it
         * succeeeds, sending an event to RudderStack in either case.
         */
        if (hasOrganisationChanged) {
            checkCdnAccessibility({
                userId: user?.accuRxUserId,
                organisationId: selectedOrganisation,
            });
        }

        /*
        Terms & Conditions
        ====
        if the user has just logged in & we don't know if they have accepted
        the terms, then backend API is not providing required values, so we log an error
        */
        if (
            user &&
            user.accuRxUserId &&
            user.accuRxUserId !== prevProps.user?.accuRxUserId &&
            user.onboarding?.hasAcceptedTermsService === undefined
        ) {
            Log.error(
                "Expect to know from API whether user has accepted terms",
            );
        }
    }

    render(): JSX.Element {
        // MERGE_CHAIN_FLEMING
        // Redirect from web.accurx.com back to chain whilst pages have not been migrated
        if (redirectUserToChain(this.props.location)) {
            return (
                <div className="mt-4">
                    <Spinner />
                </div>
            );
        }

        if (
            matchPath(this.props.location.pathname, {
                path: [
                    WORKSPACE_MANAGEMENT_ROUTES.workspaceGuidance,
                    "/workspace/guidance",
                ],
            })
        ) {
            //  As it's an external redirect, we could not use react router redirect
            window.location.replace(SUPPORT_ARTICLE_WORKSPACE_GUIDANCE);
            return <></>;
        }

        this.trackPageViewIfFromMedicalRecordSystem(
            getStringQuery(this.props.location.search, "externalreferrer"),
        );

        const workspaceId = this.context?.selectedOrgId ?? null;

        // Overlay a cookie modal if the user didn't previously decide about cookies
        const showCookiesModal =
            this.props.user?.onboarding?.hasAcceptedCookies === null;

        const currentWorkspace = this.props.user?.organisations.find(
            ({ orgId }) => orgId === this.context?.selectedOrgId,
        );

        const isArchived =
            currentWorkspace &&
            isArchivedWorkspace(currentWorkspace as Organisation);

        return (
            <>
                <SelectedOrganisationController />
                <Switch>
                    <SimpleRoute exact path="/" component={Home} />

                    {
                        // Note: Redirects must be declared before workspace routes
                        ORGANISATION_ROUTES_REDIRECTED.map(({ from, to }) =>
                            workspaceId ? (
                                <GuardedRoute
                                    key={from}
                                    path={from}
                                    requires={{
                                        authenticationStatus: "LoggedIn",
                                        onboarded: true,
                                    }}
                                >
                                    <Redirect
                                        to={generatePath(to, { workspaceId })}
                                    />
                                </GuardedRoute>
                            ) : (
                                <SimpleRoute
                                    key={from}
                                    path={ORGANISATION_ROUTES_REDIRECTED.map(
                                        ({ from }) => from,
                                    )}
                                >
                                    <Redirect to={ROUTES.joinOrganisation} />
                                </SimpleRoute>
                            ),
                        )
                    }
                    {ROUTES_REDIRECTED.map(({ from, to }: RedirectionPaths) => (
                        <AppRoute
                            key={from}
                            path={from}
                            component={({ location }: AppRouteProps) => (
                                <Redirect
                                    push
                                    to={{
                                        ...location,
                                        pathname: to,
                                    }}
                                />
                            )}
                        />
                    ))}
                    <Redirect
                        exact
                        from={ROUTES_ORGS.root}
                        to={ROUTES_CHAIN.practices}
                    />

                    <Redirect
                        from={ROUTES_BASE.patientsSearch}
                        to={`${ROUTES.home}?patientSearch=true`}
                        exact
                    />
                    <Redirect
                        from={ROUTES_PRIMARY.patients}
                        to={{
                            pathname: ROUTES.home,
                            search: this.props.location.search,
                        }}
                        exact
                    />
                    <SimpleRoute
                        path={[
                            "/practices",
                            "/practices/*",
                            "/organisations/*",
                        ]}
                    >
                        <ManageOrgRedirects />
                    </SimpleRoute>

                    <GuardedRoute
                        /*
                                the order of these routes is important, as, due
                                to prefix matching, if either ROUTES_CHAIN.practices
                                or ROUTES_ORG.root were higher in the list,
                                they would be matched first, and the `:orgId`
                                param of the other routes would not be bound.
                                So, please do not change unless you've taken this
                                into account!
                            */
                        path={[ROUTES_ORGS.org, ROUTES_ORGS.root]}
                        requires={{
                            authenticationStatus: "LoggedIn",
                            onboarded:
                                false /* onboarding check is only for Trust user pages currently */,
                        }}
                        isOrganisationRoute={true}
                    >
                        <OrganisationRoutes />
                    </GuardedRoute>

                    <AppRoute
                        allowRoute={this.isLoggedIn(true)}
                        /*
                                the order of these routes is important, as, due
                                to prefix matching, if either ROUTES_CHAIN.practices
                                or ROUTES_ORG.root were higher in the list,
                                they would be matched first, and the `:orgId`
                                param of the other routes would not be bound.
                                So, please do not change unless you've taken this
                                into account!
                            */
                        path={[
                            ROUTES_CHAIN.practicesOrgId,
                            ROUTES_CHAIN.practices,
                        ]}
                    >
                        <PracticesRoutes />
                    </AppRoute>
                    <AppRoute
                        path={ROUTES.loginOrganisations}
                        allowRoute={{
                            isAllowed: this.props.isLoggedIn !== null,
                        }}
                    >
                        <OrgLogin />
                    </AppRoute>
                    <AppRoute
                        path={ROUTES.loginRecordView}
                        allowRoute={{
                            isAllowed: this.props.isLoggedIn !== null,
                        }}
                    >
                        <RecordViewLogin />
                    </AppRoute>
                    <AppRoute
                        path={ROUTES.loginActiveDirectory}
                        allowRoute={{
                            isAllowed: this.props.isLoggedIn !== null,
                        }}
                    >
                        <ActiveDirectoryLogin />
                    </AppRoute>
                    <AppRoute
                        exact
                        path={ROUTES.downloadDesktop}
                        component={DownloadDesktop}
                    />
                    <AppRoute
                        path={ROUTES.login}
                        allowRoute={{
                            isAllowed: this.props.isLoggedIn !== null,
                        }}
                    >
                        {/* Login page decides its own layout */}
                        <LoginComponent />
                    </AppRoute>
                    <AppRoute
                        path={[
                            ROUTES.checkYourInbox,
                            ROUTES.forgotPassword,
                            ROUTES.resetPassword,
                            ROUTES.resetPasswordConfirm,
                            ROUTES.register,
                            ROUTES.confirmEmailVerify,
                            ROUTES.magicLink,
                        ]}
                    >
                        <ProductLoginRoutesSection />
                    </AppRoute>
                    {/* Redirects don't need to load layout */}
                    <AppRoute
                        path={ROUTES.postSsoRedirect}
                        allowRoute={{
                            isAllowed: this.props.isLoggedIn === true,
                        }}
                    >
                        <PostSsoRedirect />
                    </AppRoute>

                    {/* All other pages use same layout */}
                    <AccurxWebProviders
                        workspaceId={workspaceId}
                        is2FAed={this.props.is2FAed}
                    >
                        <SelectedAllowedOrganisationProvider>
                            <LayoutController>
                                <Switch>
                                    {!isArchived && (
                                        <GuardedRoute
                                            requires={{
                                                authenticationStatus:
                                                    "LoggedInWith2FA",
                                                onboarded: true,
                                            }}
                                            path={[
                                                "/compose",
                                                "/w/:workspaceId/compose",
                                            ]}
                                        >
                                            <ComposeRoutesWithFullScreenPage />
                                        </GuardedRoute>
                                    )}
                                    <GuardedRoute
                                        requires={{
                                            onboarded: true,
                                            authenticationStatus: "LoggedIn",
                                        }}
                                        path={ROUTES_PRIMARY.workspaceBase}
                                    >
                                        <WorkspaceRoutes
                                            twoFactorAuthGuard={this.isTwoFactorAuthed()}
                                        />
                                    </GuardedRoute>
                                    <GuardedRoute
                                        requires={{
                                            authenticationStatus: "LoggedIn",
                                            onboarded: true,
                                        }}
                                        path={[
                                            `${ROUTES_BASE.patientsSearch}/w/:workspaceId/conversations/new`,
                                            `${ROUTES_BASE.patientsSearch}/w/:workspaceId/conversations/:conversationId`,
                                            `${PATIENT_LISTS_ID_ROUTE}/w/:workspaceId/conversations/new`,
                                            `${PATIENT_LISTS_ID_ROUTE}/w/:workspaceId/conversations/:conversationId`,
                                        ]}
                                    >
                                        <Redirect to={ROUTES.home} />
                                    </GuardedRoute>
                                    <GuardedRoute
                                        path={ONBOARDING_ROUTES}
                                        requires={{
                                            authenticationStatus: "LoggedIn",
                                            onboarded: false,
                                        }}
                                    >
                                        <OnboardingRoutesSection />
                                    </GuardedRoute>

                                    <GuardedRoute
                                        requires={{
                                            onboarded: true,
                                            authenticationStatus: "LoggedIn",
                                        }}
                                        path={ROUTES_PRIMARY.batchMessages}
                                    >
                                        <BatchMessagingRoutesSection />
                                    </GuardedRoute>
                                    <GuardedRoute
                                        requires={{
                                            onboarded: true,
                                            authenticationStatus: "LoggedIn",
                                        }}
                                        path={ROUTES.importChooseOrg}
                                        isOrganisationRoute
                                    >
                                        <ChooseOrgPage />
                                    </GuardedRoute>
                                    <Container>
                                        <Switch>
                                            <GuardedRoute
                                                requires={{
                                                    authenticationStatus:
                                                        "LoggedIn",
                                                    onboarded: true,
                                                }}
                                                path={[
                                                    ROUTES.reply_from_web,
                                                    `${PATIENT_LISTS_ID_ROUTE}${ROUTES.reply_from_web}`,
                                                ]}
                                            >
                                                <ClinicianConversationProvider.FromUrlId>
                                                    <ClinicianConversation />
                                                </ClinicianConversationProvider.FromUrlId>
                                            </GuardedRoute>
                                            <GuardedRoute
                                                requires={{
                                                    authenticationStatus:
                                                        "LoggedIn",
                                                    onboarded: true,
                                                }}
                                                path={[
                                                    ROUTES.message_gp,
                                                    `${PATIENT_LISTS_ID_ROUTE}${ROUTES.message_gp}`,
                                                ]}
                                            >
                                                <ClinicianConversationProvider.FromNewConversation>
                                                    <ClinicianConversation />
                                                </ClinicianConversationProvider.FromNewConversation>
                                            </GuardedRoute>
                                            <GuardedRoute
                                                requires={{
                                                    authenticationStatus:
                                                        "LoggedIn",
                                                    onboarded: true,
                                                }}
                                                path={[
                                                    ROUTES.message_gp_test,
                                                    `${PATIENT_LISTS_ID_ROUTE}${ROUTES.message_gp_test}`,
                                                ]}
                                            >
                                                <ClinicianConversationProvider.FromTestConversation>
                                                    <ClinicianConversation />
                                                </ClinicianConversationProvider.FromTestConversation>
                                            </GuardedRoute>
                                            <GuardedRoute
                                                path={ACCOUNT_SETTINGS_ROUTES}
                                                requires={{
                                                    onboarded: true,
                                                    authenticationStatus:
                                                        "LoggedIn",
                                                }}
                                            >
                                                <AccountSettingsRoutesSection />
                                            </GuardedRoute>
                                            <GuardedRoute
                                                requires={{
                                                    onboarded: true,
                                                    authenticationStatus:
                                                        "LoggedIn",
                                                }}
                                                path={[
                                                    ROUTES.video_confirmation,
                                                    `${PATIENT_LISTS_ID_ROUTE}${ROUTES_EXTENSION.videoConfirmation}`,
                                                ]}
                                                exact
                                            >
                                                <FeatureGuard
                                                    all={[
                                                        FeatureName.VideoConsultWeb,
                                                    ]}
                                                >
                                                    <VideoLobby />
                                                </FeatureGuard>
                                            </GuardedRoute>

                                            <GuardedRoute
                                                requires={{
                                                    onboarded: true,
                                                    authenticationStatus:
                                                        "LoggedIn",
                                                }}
                                                path={[
                                                    ROUTES.send_message,
                                                    `${PATIENT_LISTS_ID_ROUTE}${ROUTES_EXTENSION.sendMessage}`,
                                                ]}
                                                exact
                                            >
                                                <FeatureGuard
                                                    all={[
                                                        FeatureName.PatientThreadSMS,
                                                    ]}
                                                >
                                                    <SendMessage
                                                        user={this.props.user}
                                                    />
                                                </FeatureGuard>
                                            </GuardedRoute>
                                            <GuardedRoute
                                                path={RECORD_VIEW_ROUTES}
                                                requires={{
                                                    // Does not require 2FA if using a demo patient
                                                    authenticationStatus:
                                                        this.isDemoPatient()
                                                            ? "LoggedIn"
                                                            : "LoggedInWith2FA",
                                                    onboarded: true,
                                                }}
                                            >
                                                <RecordViewRoutesSection />
                                            </GuardedRoute>

                                            <GuardedRoute
                                                path={[
                                                    ROUTES.message_practice,
                                                    `${PATIENT_LISTS_ID_ROUTE}${ROUTES_EXTENSION.messagePractice}`,
                                                ]}
                                                exact
                                                requires={{
                                                    onboarded: true,
                                                    authenticationStatus:
                                                        "LoggedIn",
                                                }}
                                            >
                                                <FeatureGuard
                                                    all={[
                                                        FeatureName.CaregiverInitiated,
                                                    ]}
                                                >
                                                    <MessagePractice
                                                        user={this.props.user}
                                                    />
                                                </FeatureGuard>
                                            </GuardedRoute>
                                            <GuardedRoute
                                                requires={{
                                                    onboarded: true,
                                                    authenticationStatus:
                                                        "LoggedInWith2FA",
                                                }}
                                                path={[
                                                    ROUTES.patient_lists,
                                                    PATIENT_LISTS_ID_ROUTE,
                                                ]}
                                                exact
                                            >
                                                <PatientListPage />
                                            </GuardedRoute>
                                            <GuardedRoute
                                                requires={{
                                                    onboarded: true,
                                                    authenticationStatus:
                                                        "LoggedIn",
                                                }}
                                                path={
                                                    ROUTES_BASE.patientsListsAll
                                                }
                                                exact
                                            >
                                                <AllPatientLists />
                                            </GuardedRoute>
                                            <GuardedRoute
                                                requires={{
                                                    onboarded: true,
                                                    authenticationStatus:
                                                        "LoggedIn",
                                                }}
                                                path={[
                                                    `${PATIENT_LISTS_ID_ROUTE}${ROUTES_EXTENSION.patientProfile}`,
                                                ]}
                                            >
                                                <PatientProfileRedirect />
                                            </GuardedRoute>

                                            <AppRoute
                                                allowRoute={this.isLoggedIn(
                                                    false,
                                                )}
                                                path={ROUTES.postlogin}
                                            >
                                                <PostLoginRedirect />
                                            </AppRoute>
                                            <GuardedRoute
                                                requires={{
                                                    onboarded: true,
                                                    authenticationStatus:
                                                        "LoggedIn",
                                                }}
                                                path={ROUTES.get_started}
                                                exact
                                            >
                                                {/* Redirect to home page as we are no longer using the Get Started page */}
                                                <Redirect to={ROUTES.home} />
                                            </GuardedRoute>
                                            <AppRoute
                                                path={
                                                    ROUTES.patient_message_history
                                                }
                                                exact
                                                component={DeletedPage}
                                            />
                                            <AppRoute
                                                exact
                                                path={ROUTES.userSignoutConfirm}
                                                component={SignOutEverywhere}
                                            />
                                            <AppRoute
                                                exact
                                                path={ROUTES.sso_failed}
                                                component={SsoFailed}
                                            />
                                            <AppRoute
                                                exact
                                                path={ROUTES.noOdsCode}
                                                component={SsoErrorNoOdsCode}
                                            />
                                            <AppRoute
                                                exact
                                                path={ROUTES.unableToLookupOds}
                                                component={
                                                    SsoErrorUnableToLookupOds
                                                }
                                            />

                                            <GuardedRoute
                                                path="*"
                                                requires={{
                                                    authenticationStatus:
                                                        "LoggedIn",
                                                    onboarded: false,
                                                }}
                                            >
                                                <NotFound />
                                            </GuardedRoute>
                                        </Switch>
                                    </Container>
                                </Switch>
                            </LayoutController>
                        </SelectedAllowedOrganisationProvider>
                    </AccurxWebProviders>
                </Switch>
                <AccountPageView />
                {showCookiesModal && <CookiesModal />}
                <div id={SLIDEOUT_PORTAL_ID}></div>
            </>
        );
    }

    private trackPageViewIfFromMedicalRecordSystem(
        externalReferrer: null | string,
    ) {
        //  Don't track until we know whether the user is logged in, so that if the user is logged in, we can track
        //  against the correct user. This method will be called automatically when this value is populated.
        if (this.props.isLoggedIn === null) {
            return;
        }

        if (
            externalReferrer === "EPRQuickLaunch" &&
            sessionStorage.EPRQuickLaunchTracked === undefined
        ) {
            const analyticsLoggedInProps =
                AnalyticsMapper.getAnalyticsLoggedInProps(this.props);
            FlemingAnalyticsTracker.trackPageLoadFromMedicalRecordSystem({
                ...analyticsLoggedInProps,
                userIsLoggedIn: this.props.isLoggedIn,
            });

            // Flag in session storage so we don't re-trigger this event if page is reloaded
            sessionStorage.EPRQuickLaunchTracked = true;
        }
    }

    private isLoggedIn = (
        isOrgLogin: boolean,
    ): GuardedAppRouteRedirectProps => {
        if (this.props.user === null || !this.props.isLoggedIn) {
            return {
                isAllowed: false,
                redirectTo: `${
                    isOrgLogin ? ROUTES.loginOrganisations : ROUTES.login
                }?${this.encodeQueryParams()}`,
            };
        }
        return { isAllowed: true };
    };

    // TODO: only used where it's passed down to Workspace routes - update WorkspaceRoutes to remove this
    private isTwoFactorAuthed = (): GuardedAppRouteRedirectProps => {
        return {
            isAllowed: this.props.is2FAed,
            redirectTo: `${ROUTES.two_factor_auth}?${this.encodeQueryParams()}`,
        };
    };

    private isDemoPatient = (): boolean => {
        // Checks whether the currently searched patient is a demo patient, or if the "request" URL parameter
        // (used by Record View) is "example". We do this so that for both using the search for patient flow
        // and using direct links to Record View we can determine if this is the demo patient. Always
        // checking the request id could result in users manually fiddling with URL get an error message
        // rather than redirect, but this is not worth guarding against
        const urlRequestId = getStringQuery(window.location.search, "request");
        return (
            PatientHelper.isDemoPatient(this.props.patient?.nhsNumber ?? "") ||
            urlRequestId === EXAMPLE_REQUEST_ID
        );
    };

    // Puts whatever the current path is as the next URL parameter so that when we redirect away from
    // a page we can tell that page to return to the current URL next
    private encodeQueryParams = (): string =>
        `${NEXT_URL_QUERY_PARAM}=${encodeURIComponent(
            this.props.location.pathname + this.props.location.search,
        )}`;
}

// Selects which state properties are merged into the component's props
function mapStateToProps(state: ApplicationState) {
    return {
        ...state.account,
        patientLists: state.patientLists,
        is2FAed: !!state.twoFactor?.isAuthenticated,
        patient: state.searchForPatient?.lastResponse?.searchedResult?.patient,
        hasSearchedForPatient:
            PatientHelper.getPatient(state.searchForPatient?.lastResponse) !==
            null,
    };
}

const appWithRouter = withRouter(
    connect(mapStateToProps, {
        // Selects which action creators are merged into the component's props
        ...AccountActions.actionCreators,
        setPatientFromSearchResponse:
            searchForPatientActions.searchForPatientFinished,
        setEmbeddedMode: AppActions.actionCreators.setEmbeddedMode,
        // Need the final as any in order to pass a prop to App in index.tsx.
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    })(App as any) as any,
);
// Have to wrap in a withRouter in order to get the browser back/forward navigation to work.
// N.B. We think this is due to this class containing some routes that are not component routes.

export default appWithRouter;
