import {
    FormEvent,
    MutableRefObject,
    ReactNode,
    useEffect,
    useState,
} from "react";

import * as UI from "@accurx/design";
import { FormFieldFeedback, Input } from "@accurx/inbox-design-library";
import { PatientDemographics } from "@accurx/native";
import { useSearchForPatientInEmrMutation } from "domains/inbox/hooks/mutations/useSearchForPatientInEmrMutation";

import {
    StyledFormSpacer,
    StyledSearchFormSubmitButtonContainer,
} from "./Forms.styles";
import { NoPatientsFoundFeedback } from "./NoPatientsFoundFeedback";
import { SearchButton } from "./SearchButton";
import { SearchErrorFeedback } from "./SearchErrorFeedback";
import { FormErrorsEmis, FormStateEmis } from "./types";
import { validateEmisForm } from "./validateEmrForms";

export const formEmisId = "search-for-patient-via-emis-form";

type FormEmisProps = {
    id?: string;
    onResultsFound: (data: PatientDemographics[]) => void;
    onSearchClick?: (options: { hasError: boolean }) => void;
    onSearchSettled?: (options: {
        hasError: boolean;
        countResult: number;
    }) => void;
    autoFocusInputRef?: MutableRefObject<HTMLInputElement | null>;
    setIsLoading?: (isLoading: boolean) => void;
    submitButton?: ReactNode;
};

export const FormEmis = ({
    id = formEmisId,
    onResultsFound,
    onSearchClick,
    onSearchSettled,
    autoFocusInputRef,
    setIsLoading,
    submitButton,
}: FormEmisProps) => {
    const [formState, setFormState] = useState<FormStateEmis>({
        searchTerm: "",
    });
    const [formFieldErrors, setFormFieldErrors] = useState<FormErrorsEmis>({});

    const { mutate, status, data } = useSearchForPatientInEmrMutation();

    useEffect(() => {
        setIsLoading?.(status === "loading");
    }, [status, setIsLoading]);

    const handleFormSubmit = (e: FormEvent) => {
        e.preventDefault();

        const { isValid, errors } = validateEmisForm(formState);

        onSearchClick?.({ hasError: !isValid });

        if (isValid) {
            setFormFieldErrors({});
            mutate(formState, {
                onSuccess: (data) => {
                    if (data.results.length > 0) {
                        onResultsFound(data.results);
                    }
                },
                onSettled: (data, error) =>
                    onSearchSettled?.({
                        hasError: !!error,
                        countResult: data?.results.length ?? 0,
                    }),
            });
        } else {
            setFormFieldErrors(errors);
        }
    };

    const handleChange = (
        data: Partial<FormStateEmis>,
        field: keyof FormStateEmis,
    ) => {
        if (formFieldErrors[field]) {
            setFormFieldErrors((prev) => ({
                ...prev,
                [field]: undefined,
            }));
        }
        setFormState((prev) => ({
            ...prev,
            ...data,
        }));
    };

    const searchIsError = status === "error";
    const searchHasNoResults =
        status === "success" && data.results.length === 0;

    return (
        <>
            <form
                onSubmit={handleFormSubmit}
                aria-label={`Search for patient in Emis`}
                aria-describedby={
                    searchHasNoResults
                        ? "patient-search-emr-form-no-results"
                        : searchIsError
                        ? "search-error"
                        : undefined
                }
                id={id}
            >
                <StyledFormSpacer>
                    <div>
                        <UI.Text
                            as="label"
                            variant="note"
                            props={{ htmlFor: "search-term" }}
                            skinny
                        >
                            Enter patient's name, date of birth or NHS number
                        </UI.Text>
                        <Input
                            aria-describedby={
                                formFieldErrors.searchTerm
                                    ? "search-term-error"
                                    : undefined
                            }
                            id="search-term"
                            value={formState.searchTerm}
                            onChange={(e): void =>
                                handleChange(
                                    {
                                        ...formState,
                                        searchTerm: e.target.value,
                                    },
                                    "searchTerm",
                                )
                            }
                            hasErrors={!!formFieldErrors.searchTerm}
                            ref={autoFocusInputRef}
                        />
                        {!!formFieldErrors.searchTerm && (
                            <div id="search-term-error">
                                <FormFieldFeedback
                                    variant="error"
                                    text={formFieldErrors.searchTerm}
                                />
                            </div>
                        )}
                    </div>

                    {submitButton ?? (
                        <StyledSearchFormSubmitButtonContainer>
                            <SearchButton
                                buttonProps={{
                                    form: id,
                                    loading: status === "loading",
                                }}
                            />
                        </StyledSearchFormSubmitButtonContainer>
                    )}
                    {searchHasNoResults && (
                        <NoPatientsFoundFeedback id="patient-search-emr-form-no-results" />
                    )}
                    {!!searchIsError && (
                        <SearchErrorFeedback id="search-error" />
                    )}
                </StyledFormSpacer>
            </form>
        </>
    );
};
