import { Feature } from "@accurx/api/portal";
import { FeatureName } from "@accurx/auth";

import { PatientSearchProductOrigin, PatientSearchResult } from "../types";

/**
 * Since the global patient search uses an additional panel to display patient
 * actions, disabling cards is only relevant for action-specific forms such as
 * the Compose patient search.
 */
export const getDisabledMessage = ({
    productOrigin,
    patient,
}: {
    productOrigin: PatientSearchProductOrigin;
    patient: PatientSearchResult;
}) => {
    if (productOrigin === "PatientSearch") {
        return undefined;
    } else if (
        ["Compose", "VideoInvite"].includes(productOrigin) &&
        patient.deceased
    ) {
        return "This patient is deceased, so you can't send a message";
    } else if (
        productOrigin === "ClinicianCompose" &&
        !canMessagePatientGp(patient.featuresAvailable)
    ) {
        return "You can't message this patient's GP as their practice isn't using Accurx";
    }

    return undefined;
};

export const canMessagePatientGp = (featuresAvailable?: Feature[]) => {
    return featuresAvailable?.some(
        ({ name, isEnabled }) =>
            name === FeatureName.CaregiverInitiated && isEnabled,
    );
};

export const formatGender = (gender?: string | null) => {
    if (!gender) {
        return "Not specified";
    } else if (gender.toLowerCase() === "male") {
        return "M";
    } else if (gender.toLowerCase() === "female") {
        return "F";
    } else {
        return "Not specified";
    }
};

export const formatDisplayAge = (age?: string | null) =>
    age?.replace(/[()]/g, "");

type PatientDisplayName = {
    firstName?: string | null;
    familyName?: string | null;
    prefixName?: string | null;
};

export const formatDisplayName = (
    { firstName, familyName, prefixName }: PatientDisplayName,
    { showPrefix = false } = {},
) => {
    const displayName = `${familyName?.toUpperCase() ?? ""}, ${
        firstName ?? ""
    }`;
    return prefixName && showPrefix
        ? displayName + ` (${prefixName})`
        : displayName;
};
