import { ReactNode, createContext, useContext, useState } from "react";

import * as UI from "@accurx/design";
import { Ds } from "@accurx/design";
import {
    PatientDemographics,
    getPrincipalSystemFriendlyName,
    useBrowserEnvironment,
    useMedicalRecordConnection,
    useNativeSubscription,
} from "@accurx/native";
import { Log } from "@accurx/shared";
import { useHistory, useLocation } from "react-router-dom";

import { mapDemographicsToCurrentPatient } from "../../../utils/mapDemographicsToCurrentPatient";
import { mapRouterStateToCurrentPatient } from "../../../utils/mapRouterStateToCurrentPatient";
import { RouterState } from "../../Routes.types";
import { DisconnectedEmrErrorPage } from "../../components/DisconnectedEmrErrorPage/DisconnectedEmrErrorPage";
import { MedicalRecordPatientUpdater } from "../components/MedicalRecordPatientUpdater/MedicalRecordPatientUpdater";
import { StyledSpinnerWrapper } from "./CurrentPatientProvider.styles";
import { CurrentPatient } from "./CurrentPatientProvider.types";

type CurrentPatientStatusSuccess = {
    currentPatient: CurrentPatient | null;
    setCurrentPatient: (patient: CurrentPatient | null) => void;
    isPatientStale: boolean;
    setIsPatientStale: (stale: boolean) => void;
};

type CurrentPatientContextType = CurrentPatientStatusSuccess;

const CurrentPatientContext = createContext<CurrentPatientContextType | null>(
    null,
);

export const LocationStatePatientProvider = ({
    children,
}: {
    children: ReactNode;
}) => {
    const { state } = useLocation<RouterState>();
    const currentPatientFromState = mapRouterStateToCurrentPatient(state);

    return (
        <CurrentPatientContext.Provider
            value={{
                currentPatient: currentPatientFromState,
                isPatientStale: false,
                setCurrentPatient: () => {
                    Log.warn(
                        "Attempt to set current patient in non-integrated compose environment",
                    );
                },
                setIsPatientStale: () => {
                    Log.warn(
                        "Attempt to set stale patient in non-integrated compose environment",
                    );
                },
            }}
        >
            {children}
        </CurrentPatientContext.Provider>
    );
};

const CurrentPatientLoader = ({
    children,
    nativePatientSubscriptionResult,
    nativePatientSubscriptionStatus,
}: {
    children: ReactNode;
    nativePatientSubscriptionStatus: "success" | "error" | null;
    nativePatientSubscriptionResult: PatientDemographics | null;
}) => {
    const medicalRecordConnection = useMedicalRecordConnection();

    const location = useLocation<RouterState>();
    const history = useHistory();

    const browserEnv = useBrowserEnvironment();
    const [currentPatient, setCurrentPatient] = useState<CurrentPatient | null>(
        browserEnv === "WebView" && nativePatientSubscriptionResult
            ? mapDemographicsToCurrentPatient(nativePatientSubscriptionResult)
            : mapRouterStateToCurrentPatient(location.state),
    );
    const [isPatientStale, setIsPatientStale] = useState(false);

    if (
        medicalRecordConnection.status === "Connected" &&
        !currentPatient &&
        nativePatientSubscriptionStatus === "error"
    ) {
        return (
            <UI.Feedback colour="error">
                There was an error fetching the current patient from the EMR.
            </UI.Feedback>
        );
    } else if (
        !currentPatient &&
        medicalRecordConnection.status === "Disconnected"
    ) {
        return <DisconnectedEmrErrorPage />;
    }

    /**
     * Clear the searched patient from the location state if it exists.
     *
     * It's possible to end up in an infinate loop if we don't clear the
     * patient from location state. This occurs when we clear the patient,
     * it is then set again as the user navigates between compose pages
     * in the background while the window is closed.
     *
     * We should consider refactoring so that the location state patient
     * is cleared as soon as the value is loaded into state.
     */
    const clearSearchedPatientFromLocationState = () => {
        if (location.state?.searchedPatient) {
            history.replace({
                ...location,
                state: {
                    ...location.state,
                    searchedPatient: undefined,
                },
            });
        }
    };

    return (
        <CurrentPatientContext.Provider
            value={{
                currentPatient,
                setCurrentPatient: (patient) => {
                    clearSearchedPatientFromLocationState();
                    setCurrentPatient(patient);
                },
                isPatientStale,
                setIsPatientStale,
            }}
        >
            {browserEnv === "WebView"
                ? medicalRecordConnection.status !== null && (
                      <MedicalRecordPatientUpdater
                          patientFeedPatient={
                              nativePatientSubscriptionResult ?? null
                          }
                          medicalRecordSystemFriendlyName={getPrincipalSystemFriendlyName(
                              medicalRecordConnection.system,
                          )}
                      />
                  )
                : null}

            {children}
        </CurrentPatientContext.Provider>
    );
};

/**
 * This provider is in charge of managing the patient
 * that should be displayed on the Compose page
 */
export const CurrentPatientProvider = ({
    children,
}: {
    children: ReactNode;
}) => {
    const browserEnv = useBrowserEnvironment();

    // Gets updated in the background, only works when connected to a medical record
    const medicalRecordPatientFeed = useNativeSubscription(
        "SubscribeCurrentPatient",
    );

    if (browserEnv !== "WebView") {
        return (
            <CurrentPatientLoader
                nativePatientSubscriptionResult={null}
                nativePatientSubscriptionStatus={null}
            >
                {children}
            </CurrentPatientLoader>
        );
    }

    if (medicalRecordPatientFeed.status === "loading") {
        return (
            <StyledSpinnerWrapper>
                <Ds.Spinner />
            </StyledSpinnerWrapper>
        );
    }

    return (
        <CurrentPatientLoader
            nativePatientSubscriptionResult={
                medicalRecordPatientFeed.data ?? null
            }
            nativePatientSubscriptionStatus={
                medicalRecordPatientFeed.status === "error"
                    ? "error"
                    : "success"
            }
        >
            {children}
        </CurrentPatientLoader>
    );
};

export const useCurrentPatient = () => {
    const context = useContext(CurrentPatientContext);

    if (context === null) {
        throw new Error(
            "useCurrentPatient can only be used by a child of a CurrentPatientProvider.",
        );
    }

    return context;
};
