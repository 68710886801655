import { ReactNode } from "react";

import { Hooks } from "@accurx/design";

import {
    UnifiedLayout,
    UnifiedMobileLayout,
    UnifiedTabletLayout,
} from "./UnifiedLayout/UnifiedLayout";

export const LayoutController = ({ children }: { children?: ReactNode }) => {
    const { size } = Hooks.useViewportSize();
    const isMobile = ["xs", "sm"].includes(size);
    const isTablet = "md" === size;

    if (isMobile) {
        return <UnifiedMobileLayout>{children}</UnifiedMobileLayout>;
    } else if (isTablet) {
        return <UnifiedTabletLayout>{children}</UnifiedTabletLayout>;
    } else {
        return <UnifiedLayout>{children}</UnifiedLayout>;
    }
};
