// TODO: Delete once Manage Org navigation is deleted
import { TabProps } from "@accurx/design";
import keyBy from "lodash/keyBy";

export const TABS: TabProps[] = [
    { text: "Users", tabId: "users" },
    { text: "Available features", tabId: "available-features" },
    { text: "Installed computers", tabId: "installed-computers" },
    { text: "Team notifications", tabId: "team-notifications" },
    { text: "Patient Triage", tabId: "patient-triage" },
    { text: "SMS settings", tabId: "sms-settings" },
    { text: "Profile", tabId: "profile" },
    { text: "Password", tabId: "password" },
    { text: "Message signature", tabId: "message-signature" },
    { text: "Cookies", tabId: "cookies" },
    { text: "Security", tabId: "security" },
    { text: "Terms and Conditions", tabId: "terms-and-conditions" },
];

export const TabLookup = keyBy(TABS, "tabId");
export type Tab = keyof typeof TabLookup;
