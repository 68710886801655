import {
    ChangeEvent,
    FormEvent,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";

import { useAnalytics } from "@accurx/analytics";
import {
    FailedWorkspaceCreation,
    WorkspaceCreationResponse,
} from "@accurx/api/account";
import {
    FeatureName,
    Organisation,
    isFeatureEnabledForWorkspace,
    useCurrentUser,
} from "@accurx/auth";
import {
    ErrorSummaryProvider,
    Feedback,
    Flex,
    Option,
    Text,
} from "@accurx/design";
import { BackButton, getNextUrlOrDefault } from "@accurx/navigation";
import { Log } from "@accurx/shared";
import {
    ROOT_ROUTES,
    WORKSPACE_MANAGEMENT_ROUTES,
    useCreateWorkspaceByNationalCodeMutation,
    useSpecialtiesQuery,
} from "@accurx/workspace-management";
import {
    Redirect,
    generatePath,
    useHistory,
    useLocation,
    useRouteMatch,
} from "react-router-dom";
import { toast } from "react-toastify";
import { useSelectedOrganisation } from "reduxQuarantine/SelectedOrganisationProvider";

import { trackWorkspaceCreatePageView } from "app/analytics/FlemingAnalytics";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { SelectedAllowedOrganisationContext } from "app/workspace/context/SelectedAllowedOrganisationProvider";
import {
    StyledMain,
    StyledPageWrapper,
} from "app/workspace/pages/shared/Layout/Layout.styles";
import {
    FormWithErrorSummary,
    Legend,
} from "app/workspace/pages/shared/WorkspaceDetailsForm/FormLayout";
import {
    StyledButton,
    StyledFlex,
} from "app/workspace/pages/shared/WorkspaceDetailsForm/WorkspaceDetailsForm.styles";
import {
    WorkspaceAdminField,
    WorkspaceDescriptionField,
    WorkspaceNameField,
    WorkspaceSpecialtyField,
} from "app/workspace/pages/shared/WorkspaceDetailsForm/WorkspaceDetailsFormFields";
import { WORKSPACE_NAME_ID } from "app/workspace/pages/shared/WorkspaceDetailsForm/constants";
import { useValidateForm } from "app/workspace/pages/shared/WorkspaceDetailsForm/useValidateForm";
import { UserflowIds } from "app/workspace/pages/shared/constants";
import {
    getSpecialtyAsOption,
    getSpecialtyOptionValue,
    sortSpecialtiesAlphabetically,
} from "app/workspace/pages/shared/specialtyHelpers";
import { ROUTES } from "shared/Routes";

const DUPLICATED_NAME_ERROR_MESSAGE =
    "Workspace name is the same as an existing one. Go back to join the existing workspace or enter a different name.";

const CreateWorkspacePageContent = () => {
    const history = useHistory<{
        workspaceName?: string;
    }>();

    const location = useLocation();

    const { selectedOrgId } = useSelectedOrganisation();

    const { user } = useCurrentUser();

    const currentWorkspace = user.organisations?.find(
        (w) => w.orgId === selectedOrgId,
    );

    const [hasNetworkError, setHasNetworkError] = useState(false);

    const { allowedOrganisation, setAllowedOrganisation } = useContext(
        SelectedAllowedOrganisationContext,
    );

    const isOnboarding = Boolean(useRouteMatch([ROOT_ROUTES.workspaceCreate]));

    const nationalCode =
        isOnboarding && allowedOrganisation
            ? allowedOrganisation.nationalCode
            : currentWorkspace?.nationalCode;

    const supportsMultipleWorkspaces = allowedOrganisation
        ? allowedOrganisation.metadata.supportsMultipleWorkspaces
        : currentWorkspace &&
          isFeatureEnabledForWorkspace(
              FeatureName.FlexibleWorkspace,
              currentWorkspace,
          );

    const {
        data: { specialties = [], noApplicableSpecialty } = {},
        isLoading: isLoadingSpecialties,
        isError: hasSpecialtiesError,
    } = useSpecialtiesQuery();

    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    const {
        validateForm,
        nameError,
        descriptionError,
        specialtyError,
        manuallySetNameError,
        manuallyAddErrorSummary,
    } = useValidateForm();

    const track = useAnalytics();

    const [description, setDescription] = useState<string>("");
    const [name, setName] = useState<string>("");
    const [selectedSpecialtyOption, setSelectedSpecialtyOption] = useState<
        Option | undefined
    >();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        trackWorkspaceCreatePageView(analyticsLoggedInProps);
    }, [analyticsLoggedInProps]);

    const specialtyOptions: Option[] = useMemo(
        () => sortSpecialtiesAlphabetically(specialties),
        [specialties],
    );

    const onSuccess = (data: WorkspaceCreationResponse) => {
        // NOTE: response structure will probably be changed to contain information about an error type

        // there is alredy a check for orgId within mutation
        const workspace = data.workspace as Organisation;

        toast(
            <Feedback
                colour="success"
                title={`Created ${workspace.organisationName}`}
            />,
        );

        setAllowedOrganisation(null);

        history.replace(
            getNextUrlOrDefault(
                history.location.search,
                generatePath(WORKSPACE_MANAGEMENT_ROUTES.inviteUsers, {
                    workspaceId: workspace.orgId,
                }),
            ),
        );
    };

    const onError = (error: Error) => {
        if (error?.cause === FailedWorkspaceCreation.WorkspaceNameDuplicate) {
            manuallySetNameError({
                detailMessage: DUPLICATED_NAME_ERROR_MESSAGE,
                summaryMessage: "",
            });
            manuallyAddErrorSummary({
                id: WORKSPACE_NAME_ID,
                body: DUPLICATED_NAME_ERROR_MESSAGE,
            });
        } else {
            setHasNetworkError(true);
        }

        setIsLoading(false);
    };

    const { mutate } = useCreateWorkspaceByNationalCodeMutation({
        onSuccess,
        onError,
    });

    const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
    };

    const handleDescriptionChange = (e: ChangeEvent<HTMLInputElement>) => {
        setDescription(e.target.value);
    };

    const handleSpecialtyChange = (selectedOption: Option) => {
        setSelectedSpecialtyOption(selectedOption);
    };

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsLoading(true);

        const specialty = getSpecialtyOptionValue(selectedSpecialtyOption);
        const errors = validateForm({ name, description, specialty });
        const isValid = errors.length === 0;

        track("WorkspaceCreate Button Click", {
            hasError: !isValid,
            withDescription: !!description,
            errorReason: errors,
            isExample: false,
            isRecommended: false,
        });

        if (window.Intercom) {
            window.Intercom(
                "trackEvent",
                "click-confirm-join-or-create-workspace",
            );
        }

        if (!isValid) {
            setIsLoading(false);
            return;
        }

        mutate({
            nationalCode,
            name,
            description,
            isRecommended: false,
            isExample: false,
            // Validation function has verified this is a number
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            specialities: [specialty!],
        });
    };

    const hasValidationErrors =
        !!nameError || !!descriptionError || !!specialtyError;

    if (!allowedOrganisation && !currentWorkspace) {
        return (
            <Redirect to={{ ...location, pathname: ROUTES.joinOrganisation }} />
        );
    }

    if (!supportsMultipleWorkspaces) {
        return (
            <StyledPageWrapper>
                <StyledMain gap="3" flexDirection="column">
                    <Feedback
                        colour="error"
                        title="This organisation does not support workspaces"
                    />
                </StyledMain>
            </StyledPageWrapper>
        );
    }

    if (!nationalCode) {
        Log.error("There is no national code to fetch workspaces for");
        return (
            <StyledPageWrapper>
                <StyledMain gap="3" flexDirection="column">
                    <Feedback colour="error" title="Something went wrong" />
                </StyledMain>
            </StyledPageWrapper>
        );
    }

    return (
        <StyledPageWrapper flexDirection="column" justifyContent="flex-start">
            <BackButton backCallback={history.goBack} />
            <StyledMain flexDirection="column">
                {hasSpecialtiesError ? (
                    <Feedback
                        colour="error"
                        title="Sorry, there was a problem. Please refresh the page."
                    />
                ) : (
                    <FormWithErrorSummary
                        hasFormValidationErrors={hasValidationErrors}
                        hasNetworkError={hasNetworkError}
                        onSubmit={handleSubmit}
                    >
                        <Legend>Create new workspace</Legend>
                        <Text variant="body" skinny>
                            Workspaces allow you to collaborate with your
                            colleagues.
                        </Text>
                        <StyledFlex flexDirection="column" gap="3">
                            <WorkspaceNameField
                                onChange={handleNameChange}
                                value={name}
                                error={nameError?.detailMessage}
                            />
                            <WorkspaceDescriptionField
                                onChange={handleDescriptionChange}
                                value={description}
                                error={descriptionError}
                            />
                            <Flex>
                                <WorkspaceSpecialtyField
                                    options={specialtyOptions}
                                    onChange={handleSpecialtyChange}
                                    noApplicableSpecialtyOption={getSpecialtyAsOption(
                                        noApplicableSpecialty,
                                    )}
                                    isLoading={isLoadingSpecialties}
                                    error={specialtyError}
                                />
                            </Flex>
                            <WorkspaceAdminField
                                value={user.fullName || user.email}
                            />
                            <StyledButton
                                type="submit"
                                text="Create workspace"
                                disabled={isLoading}
                                data-userflow-id={
                                    UserflowIds.selectOrganisation.selectButton
                                }
                            />
                        </StyledFlex>
                    </FormWithErrorSummary>
                )}
            </StyledMain>
        </StyledPageWrapper>
    );
};

export const CreateWorkspacePage = () => {
    return (
        <ErrorSummaryProvider>
            <CreateWorkspacePageContent />
        </ErrorSummaryProvider>
    );
};
