import { ReactNode, useEffect, useRef } from "react";

import { MessageBody } from "../MessageBody/MessageBody";
import { MessageSignature } from "../MessageSignature/MessageSignature";

const isAtEndOfTextarea = (el?: HTMLTextAreaElement | null) => {
    if (!el) {
        return false;
    }

    return (
        el.selectionEnd - el.selectionStart === 0 &&
        el.selectionEnd === el.textLength
    );
};
const isAtStartOfTextarea = (el?: HTMLTextAreaElement | null) => {
    if (!el) {
        return false;
    }

    return el.selectionStart - el.selectionEnd === 0 && el.selectionEnd === 0;
};

export const EditableMessage = ({
    children,
    onUserTyping,
}: {
    children?: ReactNode;
    onUserTyping?: () => void;
}) => {
    const bodyRef = useRef<HTMLTextAreaElement>(null);
    const signatureRef = useRef<HTMLTextAreaElement>(null);

    const shouldMoveToBody = useRef(false);
    const shouldMoveToSignature = useRef(false);

    // Ensure textarea is focussed and cursor is placed
    // at the end of the filled area (i.e. after greeting)
    useEffect(() => {
        bodyRef.current?.focus();
        bodyRef.current?.setSelectionRange(
            bodyRef.current.textLength,
            bodyRef.current.textLength,
        );
    }, []);

    return (
        <>
            <MessageBody
                ref={bodyRef}
                onChange={onUserTyping}
                onKeyDown={(e) => {
                    if (
                        (e.key === "ArrowRight" || e.key === "ArrowDown") &&
                        isAtEndOfTextarea(bodyRef.current)
                    ) {
                        shouldMoveToSignature.current = true;
                    }
                }}
                onKeyUp={() => {
                    if (shouldMoveToSignature.current) {
                        signatureRef.current?.focus();
                        signatureRef.current?.setSelectionRange(0, 0);
                        shouldMoveToSignature.current = false;
                    }
                }}
            />

            {children}

            <MessageSignature
                ref={signatureRef}
                onChange={onUserTyping}
                onKeyDown={(e) => {
                    if (
                        (e.key === "ArrowLeft" || e.key === "ArrowUp") &&
                        isAtStartOfTextarea(signatureRef.current)
                    ) {
                        shouldMoveToBody.current = true;
                    }
                }}
                onKeyUp={() => {
                    if (shouldMoveToBody.current) {
                        bodyRef.current?.focus();
                        bodyRef.current?.setSelectionRange(
                            bodyRef.current.textLength,
                            bodyRef.current.textLength,
                        );
                        shouldMoveToBody.current = false;
                    }
                }}
            />
        </>
    );
};
