import { PrincipalSystem } from "../schemas/MedicalRecordConnectionDtoSchema";

export const getPrincipalSystemFriendlyName = (
    system: PrincipalSystem | null,
) => {
    switch (system) {
        case "Emis":
            return "EMIS";
        case "SystmOne":
            return "SystmOne";
        case "Vision":
            return "Vision";
        case "Unknown":
        case "Server":
        case null:
            return "Medical record";
        default: {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const exhaustive: never = system;
            return "";
        }
    }
};
