import { useCurrentWorkspace } from "@accurx/auth";
import { TabProps } from "@accurx/design";
import { generatePath, useHistory, useParams } from "react-router";

import { ROUTES_WORKSPACE } from "shared/Routes";

import { TabId, getTabLookup, getTabs } from "./ManageAccumailTemplates.tabs";

export type UseManageAccumailTemplatesPage = () => {
    tab?: TabProps;
    onTabChange: (tab: string) => void;
    redirect: string;
    orgName: string;
};

export const useManageAccumailTemplatesPage: UseManageAccumailTemplatesPage =
    () => {
        const { organisationName: orgName } = useCurrentWorkspace();
        const history = useHistory();
        const { tab: tabId, workspaceId } = useParams<{
            tab: TabId;
            workspaceId: string;
        }>();

        const tab = getTabLookup(orgName)[tabId || getTabs(orgName)[0].tabId];

        const onTabChange = (tabId: string) => {
            history.push(
                generatePath(ROUTES_WORKSPACE.accumailTemplatesManage, {
                    workspaceId,
                    tab: tabId,
                }),
            );
        };
        // generate a redirect url in case the route contains an invalid tab
        const redirect = generatePath(
            ROUTES_WORKSPACE.accumailTemplatesManage,
            { workspaceId },
        );

        return { tab, onTabChange, redirect, orgName };
    };
