import { Recipient } from "@accurx/api/content";
import { Ds, FormFieldWrapper } from "@accurx/design";

import { displayFormattedRecipient } from "app/practices/manageTemplates/utils";

type RecipientSelectorProps = {
    value: Recipient;
    onValueChange: (value: string) => void;
    disabled: boolean;
};

export function RecipientSelector({
    value,
    disabled,
    onValueChange,
}: RecipientSelectorProps) {
    const id = "intended-recipient";
    const labelId = "intended-recipient-label";

    return (
        <FormFieldWrapper
            label="Intended recipient"
            labelProps={{ id: labelId, htmlFor: id }}
        >
            <Ds.RadioGroup
                id={id}
                name="intended-recipient"
                aria-labelledby={labelId}
                value={value}
                onValueChange={onValueChange}
                disabled={disabled}
            >
                <Ds.Flex flexDirection="column" gap="1">
                    <Ds.RadioGroup.Item value={Recipient.Patient}>
                        <Ds.RadioGroup.Indicator />
                        <Ds.RadioGroup.Label>
                            {displayFormattedRecipient[Recipient.Patient]}
                        </Ds.RadioGroup.Label>
                    </Ds.RadioGroup.Item>
                    <Ds.RadioGroup.Item
                        value={Recipient.HealthcareProfessional}
                    >
                        <Ds.RadioGroup.Indicator />
                        <Ds.RadioGroup.Label>
                            {
                                displayFormattedRecipient[
                                    Recipient.HealthcareProfessional
                                ]
                            }
                        </Ds.RadioGroup.Label>
                    </Ds.RadioGroup.Item>
                </Ds.Flex>
            </Ds.RadioGroup>
        </FormFieldWrapper>
    );
}
