import { useEffect, useRef } from "react";

import { useCurrentWorkspace } from "@accurx/auth";
import {
    NativeApi,
    convertNativeErrorMessageToError,
    sanitizeAndLogError,
    useNativeTransport,
} from "@accurx/native";
import { useInboxLink } from "domains/inbox/hooks/util";
import { SubscribeConversationIdSchema } from "domains/inbox/schemas/SubscribeConversationIdSchema";
import { useHistory } from "react-router-dom";

export const useNativeConversationListener = () => {
    const transport = useNativeTransport();
    const { orgId: workspaceId } = useCurrentWorkspace();
    const history = useHistory();
    const link = useInboxLink();
    const linkToRef = useRef(link.to);

    useEffect(() => {
        if (!transport) return;

        const unsubscribe = NativeApi.subscribe({
            transport,
            request: {
                type: "SubscribeConversationId",
                workspaceId,
            },
            onEvent: (event) => {
                if (!event.success) {
                    sanitizeAndLogError(
                        convertNativeErrorMessageToError(event.error),
                        {
                            domain: "Native API Subscription",
                            subscription: "SubscribeConversationId",
                        },
                    );
                    return;
                }

                const parseResult = SubscribeConversationIdSchema.safeParse(
                    event.data,
                );

                if (!parseResult.success) {
                    sanitizeAndLogError(parseResult.error, {
                        domain: "Native API Subscription",
                        subscription: "SubscribeConversationId",
                    });
                    return;
                }

                const { conversationId } = parseResult.data;

                if (conversationId) {
                    history.push(
                        linkToRef.current("MyInbox", {
                            conversationId,
                            source: "native",
                        }),
                    );
                }
            },
        });
        return () => unsubscribe();
    }, [linkToRef, history, transport, workspaceId]);
};
