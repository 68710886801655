import { ChangeEvent, FormEvent, useEffect, useRef, useState } from "react";

import { Ds, Text } from "@accurx/design";
import { FormFieldFeedback, Input } from "@accurx/inbox-design-library";
import { EmailAddressHelper, MobileNumberHelper } from "@accurx/shared";
import { useCompose } from "domains/message-component/context";
import { PatientExternalId } from "domains/message-component/types";
import { isValidPhoneNumberFormat } from "domains/message-component/utils/isValidPhoneNumberFormat";

import { ContactDetail } from "../../types";
import { PatientCommunicationConsentSummary } from "./CommunicationConsent/PatientCommunicationConsentSummary";

export const CustomContactDetailForm = ({
    patientExternalIds,
    onContactDetailAdded,
    formLabelledById,
    canSubmitEmail,
    inputValue,
    setInputValue,
}: {
    patientExternalIds: PatientExternalId[];
    /**
     * The id of the element labelling the form
     */
    formLabelledById?: string;
    /**
     * What to do after form has been submitted successfully
     */
    onContactDetailAdded: (contactDetail: ContactDetail) => void;
    canSubmitEmail: boolean;
    inputValue: string;
    setInputValue: (val: string) => void;
}) => {
    const { dispatch } = useCompose();

    const [error, setError] = useState("");
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        inputRef.current?.focus();
    }, []);

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();

        const isValidMobile =
            MobileNumberHelper.isValidMobileNumber(inputValue) &&
            isValidPhoneNumberFormat(inputValue);
        const isValidEmail = EmailAddressHelper.isValidEmailAddress(inputValue);

        if (!isValidEmail && !isValidMobile) {
            setError("Enter a valid UK mobile number or email address");
            return;
        }

        if (isValidEmail && !canSubmitEmail) {
            setError(
                "Email is not available with self-book. Enter a mobile number instead.",
            );
            return;
        }

        const contactDetails: ContactDetail = {
            value: inputValue,
            origin: "UserInput",
            method: isValidEmail ? "Email" : "Mobile",
        };

        dispatch({
            type: "UPDATE_CONTACT_DETAILS",
            payload: {
                contactDetails,
            },
        });
        onContactDetailAdded(contactDetails);
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setError("");
        setInputValue(e.currentTarget.value);
    };

    return (
        <form onSubmit={handleSubmit} aria-labelledby={formLabelledById}>
            <Ds.Flex flexDirection={"column"} gap="2">
                <div>
                    <Text
                        as="label"
                        variant="note"
                        skinny
                        props={{ htmlFor: "custom-detail" }}
                        colour="zinc"
                    >
                        Enter mobile number or email
                    </Text>
                    <Input
                        id="custom-detail"
                        value={inputValue}
                        onChange={handleChange}
                        hasErrors={!!error}
                        type="text"
                        autoComplete="off"
                        aria-describedby={error ? "input-error" : undefined}
                        ref={inputRef}
                    />
                    {error && (
                        <FormFieldFeedback
                            text={error}
                            id="input-error"
                            variant="error"
                        />
                    )}
                </div>
                <PatientCommunicationConsentSummary
                    patientExternalIds={patientExternalIds}
                />
                <Ds.Flex justifyContent="end">
                    <Ds.Button type="submit" size="small">
                        Add
                    </Ds.Button>
                </Ds.Flex>
            </Ds.Flex>
        </form>
    );
};
