import { Patient } from "@accurx/api/portal";
import { PatientDemographics } from "@accurx/native";
import { mapPatientsNames } from "@accurx/patient";
import { DateFormatOptions, DateHelpers, Log } from "@accurx/shared";

import { SearchedPatient } from "../pages/Routes.types";

const formatDateShortWithHyphens = (s: string): string => {
    return DateHelpers.formatDate(s, DateFormatOptions.DATE_SHORT_WITH_HYPHENS);
};

export type ComposePatientRouterState = {
    nhsNumber?: Patient["nhsNumber"];
    firstName?: Patient["firstName"];
    familyName?: Patient["familyName"];
    prefixName?: Patient["prefixName"];
    dateOfBirth?: Patient["dateOfBirth"];
    gender?: Patient["gender"];
    mobileNumber?: Patient["mobileNumber"];
    practiceCode?: Patient["practiceCode"];
    practiceName?: Patient["practiceName"];
    patientToken?: string;
};

export const createSearchedPatient = ({
    patient,
    patientToken,
}: {
    patient: ComposePatientRouterState | null;
    patientToken: string;
}): SearchedPatient | null => {
    if (!patient?.nhsNumber) {
        Log.warn(
            "Can't create a searched patient as the result is missing an NHS number",
        );
        return null;
    }
    return {
        source: "Pds",
        patientToken,
        externalIds: [{ type: "NhsNumber", value: patient.nhsNumber }],
        firstName: patient.firstName ?? null,
        familyName: patient.familyName ?? null,
        prefixName: patient.prefixName ?? null,
        nickname: null, // nickname is not provided by PDS search result
        dateOfBirth: patient.dateOfBirth ?? "",
        gender: patient.gender ?? "",
        mobileNumber: patient.mobileNumber ?? null,
        practiceCode: patient.practiceCode ?? null,
        practiceName: patient.practiceName ?? null,
    };
};

export const createSearchedPatientFromDemographics = (
    demographics: PatientDemographics,
): SearchedPatient => {
    const name = mapPatientsNames(demographics.patientName.usual);
    const nickname = mapPatientsNames(demographics.patientName.nickname);

    return {
        source: "Demographics",
        dateOfBirth: demographics.dateOfBirth
            ? formatDateShortWithHyphens(demographics.dateOfBirth)
            : "",
        gender: demographics.gender,
        prefixName: name.prefixName,
        firstName: name.firstName,
        familyName: name.familyName,
        nickname: nickname.firstName,
        mobileNumber: demographics.contact.mobileTelephone,
        homeNumber: demographics.contact.homeTelephone,
        workNumber: demographics.contact.workTelephone,
        email: demographics.contact.email,
        address: demographics.contact.address,
        externalIds: demographics.patientExternalIdentityDto.patientExternalIds,
        demographics,
    };
};
