import React, { ChangeEvent, FormEvent, useState } from "react";

import {
    Button,
    Card,
    Feedback,
    Flex,
    FormFieldWrapper,
    Input,
    Link,
    Text,
} from "@accurx/design";
import { MobileNumberHelper } from "@accurx/shared";

import { StyledFooterLink } from "../TwoFactor.styles";
import { BackButton } from "../shared/FooterButtons";
import { StyledForm } from "./TwoFactorChooseMethod.styles";

export type TwoFactorChooseMethodProps = {
    prevStep: (() => void) | null;
    nextStep: ((inputValue: string) => void) | null;
    error: string;
    requestLoading: boolean;
};

export const TwoFactorChooseMethod = ({
    prevStep,
    nextStep,
    error,
    requestLoading,
}: TwoFactorChooseMethodProps): JSX.Element => {
    const [isEdit, setIsEdit] = useState(false);
    const [mobileNumberInputValue, setMobileNumberInputValue] = useState("");
    const [mobileNumberInputError, setMobileNumberInputError] = useState("");

    const handleMobileChange = (e: ChangeEvent<HTMLInputElement>): void => {
        setMobileNumberInputValue(e.target.value);
        setMobileNumberInputError("");
        setIsEdit(true);
    };

    const validateMobileNumber = (): string => {
        const mobileNumberToUse = mobileNumberInputValue.replace(/\s/g, "");
        const isMobileNumberValid = MobileNumberHelper.validatePhoneNumber(
            mobileNumberToUse,
            true,
        );

        if (!isMobileNumberValid) {
            setMobileNumberInputError("Please enter a valid UK mobile number");
            return "";
        }
        return mobileNumberToUse;
    };

    const handleRegister = (e: FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        const mobileNumber = validateMobileNumber();
        if (mobileNumber) {
            setIsEdit(false);
            nextStep && nextStep(mobileNumber);
        }
    };

    const handleGoBack = (): void => {
        prevStep && prevStep();
    };

    const renderInput = (): JSX.Element => {
        const displayInputError = mobileNumberInputError !== "";
        return (
            <>
                <FormFieldWrapper
                    label="Mobile number to send verification code"
                    labelProps={{
                        htmlFor: "mobileNumber",
                    }}
                    errors={
                        displayInputError ? [mobileNumberInputError] : undefined
                    }
                >
                    <Input
                        id="mobileNumber"
                        type="tel"
                        className="form-control"
                        onChange={handleMobileChange}
                        value={mobileNumberInputValue}
                        placeholder="Enter your number"
                        readOnly={requestLoading}
                        hasErrors={displayInputError}
                    />
                </FormFieldWrapper>
                <Feedback
                    colour="information"
                    title="This must be a phone you can receive SMS messages to whilst using Accurx"
                />
            </>
        );
    };

    // Only display the error if there is one and we are not editing the form
    const displayApiError = error !== "" && !isEdit;

    return (
        // Form is here to handle the "enter" key
        <StyledForm onSubmit={handleRegister} noValidate>
            <Card spacing={3}>
                <Flex flexDirection="column" gap="5">
                    <Flex flexDirection="column" gap="3">
                        {renderInput()}
                        {displayApiError && (
                            <Feedback colour="error" title="An error occured">
                                <Text skinny>{error}</Text>
                            </Feedback>
                        )}
                    </Flex>
                    <Flex
                        justifyContent={prevStep ? "space-between" : "flex-end"}
                    >
                        {prevStep && <BackButton onClick={handleGoBack} />}
                        <Button
                            text="Send code"
                            type="submit"
                            disabled={requestLoading}
                        />
                    </Flex>
                </Flex>
            </Card>
            <StyledFooterLink>
                <Link href="https://www.accurx.com/security" openInNewTab>
                    Learn more about account security
                    <Link.Icon />
                </Link>
            </StyledFooterLink>
        </StyledForm>
    );
};
