import { Ds } from "@accurx/design";

import { TickList, TickListItem } from "./TickList";

export const RequiredColumnsList = () => {
    return (
        <>
            <Ds.Text>Appointment list csv should include:</Ds.Text>

            <TickList aria-label="required columns">
                <TickListItem>
                    <Ds.Text>
                        <Ds.Text as="span" weight="bold">
                            AppointmentId (Optional)
                        </Ds.Text>{" "}
                        An identifier that uniquely identifies an appointment
                    </Ds.Text>
                </TickListItem>
                <TickListItem>
                    <Ds.Text>
                        <Ds.Text as="span" weight="bold">
                            ClinicCode
                        </Ds.Text>{" "}
                        To identify a set of appointments that should be grouped
                        together. Note that this field is used to configure
                        appointment reminders and therefore shouldn't be changed
                        frequently as this will require you to re-setup affected
                        appointment reminders
                    </Ds.Text>
                </TickListItem>
                <TickListItem>
                    <Ds.Text>
                        <Ds.Text as="span" weight="bold">
                            AppointmentStartTime
                        </Ds.Text>{" "}
                        The start time of the appointment. ISO date time e.g.
                        2023-11-06T00:00:00.000
                    </Ds.Text>
                </TickListItem>
                <TickListItem>
                    <Ds.Text>
                        <Ds.Text as="span" weight="bold">
                            AppointmentCategory
                        </Ds.Text>{" "}
                        The appointment type, for example FaceToFaceFirst vs
                        PhoneOrTelemedicineFirst
                    </Ds.Text>
                </TickListItem>
                <TickListItem>
                    <Ds.Text>
                        <Ds.Text as="span" weight="bold">
                            Location (Optional)
                        </Ds.Text>{" "}
                        A human readable location of the appointment, which will
                        be visible to the patient (in appointment reminder text)
                        and clinician
                    </Ds.Text>
                </TickListItem>
                <TickListItem>
                    <Ds.Text>
                        <Ds.Text as="span" weight="bold">
                            HealthcareProfessional (Optional)
                        </Ds.Text>{" "}
                        A single responsible clinician for the appointment which
                        will be visible to the patient (if they choose to amend
                        their appointment) and the clinician
                    </Ds.Text>
                </TickListItem>
                <TickListItem>
                    <Ds.Text>
                        <Ds.Text as="span" weight="bold">
                            PatientNhsNumber
                        </Ds.Text>{" "}
                        The patient’s NHS number{" "}
                    </Ds.Text>
                </TickListItem>
                <TickListItem>
                    <Ds.Text>
                        <Ds.Text as="span" weight="bold">
                            PatientDob
                        </Ds.Text>{" "}
                        Patient's date of birth{" "}
                    </Ds.Text>
                </TickListItem>
            </TickList>
        </>
    );
};
