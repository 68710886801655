import { useCurrentUser, useCurrentWorkspace } from "@accurx/auth";
import { Card, Ds, FormFieldWrapper } from "@accurx/design";
import { calculateSMSFragments } from "@accurx/shared";

import { SingleSnomedPicker } from "app/sharedComponents/SnomedPicker";

import * as helpers from "../ManageTemplatesFormPage.helpers";
import {
    StyledGreyBox,
    StyledTextArea,
} from "../ManageTemplatesFormPage.styles";
import { ManageTemplatesFormHook } from "../ManageTemplatesFormPage.types";
import { Attachments } from "./Attachments/Attachments";

type TemplateContentCardProp = {
    form: ManageTemplatesFormHook;
    mapErrorsByKey: (key: string) => JSX.Element[];
};

export const TemplateContentCard = ({
    form,
    mapErrorsByKey,
}: TemplateContentCardProp) => {
    const user = useCurrentUser();
    const workspace = useCurrentWorkspace();

    const displaySnomedCodes = workspace.latestIntegratedSystem !== undefined;

    /**
     * calculate fragments by passing in the body of the
     * template as well as padding it to length we assume it is
     * accounting for things like practice name and message footer
     */
    const fragments = calculateSMSFragments(
        `${form.values.body}${"a".repeat(helpers.MESSAGE_LENGTH_COUNT_START)}`,
    );

    return (
        <Card
            header={
                <Ds.Text weight="bold" size="large">
                    Template content
                </Ds.Text>
            }
        >
            <Ds.Flex flexDirection="column" gap={"2"}>
                <FormFieldWrapper
                    label="Message"
                    labelProps={{ htmlFor: "message" }}
                    errors={mapErrorsByKey("message")}
                    metaInfo={
                        <Ds.Text
                            as="span"
                            weight="bold"
                            size="small"
                            color={
                                form.characterCounts.body >
                                helpers.MAX_BODY_LENGTH
                                    ? "red"
                                    : "zinc"
                            }
                        >
                            {form.characterCounts.body +
                                helpers.MESSAGE_LENGTH_COUNT_START}{" "}
                            / {helpers.MAX_MESSAGE_FRAGMENT_LENGTH} (
                            {fragments.fragmentCount}{" "}
                            {fragments.fragmentCount === 1
                                ? "fragment"
                                : "fragments"}
                            )
                        </Ds.Text>
                    }
                >
                    <StyledGreyBox>
                        {helpers.MESSAGE_INTRO}
                        <StyledTextArea
                            id="message"
                            value={form.values.body}
                            onChange={form.onChange.body}
                            minRows={3}
                            placeholder="Add the content of your template by typing here..."
                        />
                        <span>
                            {helpers.MESSAGE_OUTRO} {user.user.fullName || ""}
                        </span>
                        <br />
                        <span>{workspace.organisationName}</span>
                    </StyledGreyBox>
                </FormFieldWrapper>

                {form.shouldShowAttachmentsSection && (
                    <Attachments
                        attachments={form.values.attachments}
                        onAddAttachment={form.onAddAttachment}
                        onRemoveAttachment={form.onRemoveAttachment}
                    />
                )}
                {displaySnomedCodes && (
                    <FormFieldWrapper
                        label="SNOMED code (optional)"
                        subLabel={
                            !!form.values.snomedCode
                                ? undefined
                                : "Type the description or concept ID to search."
                        }
                        labelProps={{
                            htmlFor: "snomedCode",
                        }}
                    >
                        <SingleSnomedPicker
                            id="snomedCode"
                            onChange={form.onChange.snomedCode}
                            value={form.values.snomedCode}
                            showMissingSnomedCodeInformation
                            context="patientMessaging"
                        />
                    </FormFieldWrapper>
                )}
            </Ds.Flex>
        </Card>
    );
};
