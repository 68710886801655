import { createMutationHook } from "@accurx/api/QueryClient";
import { useCurrentUser } from "@accurx/auth";
import { getApiEndpoint, httpClient, returnDataOrThrow } from "@accurx/shared";

import { SettingsCookies } from "app/account/UserAccountSetting/components/Settings.types";

export const useUpdateUserCookiesMutation = createMutationHook<
    SettingsCookies,
    undefined
>(() => {
    const { user, update } = useCurrentUser();

    return {
        mutationKey: ["updateUserCookies"],
        mutationFn: async ({ hasAcceptedCookies }) => {
            const response = await httpClient.putJsonReturnSafeAsync(
                getApiEndpoint({
                    path: "/api/account/UserCookies",
                }),
                { hasAcceptedCookies },
            );

            return returnDataOrThrow(response);
        },
        onSuccess: (_data, variables) => {
            const onboarding = user.onboarding && {
                ...user.onboarding,
                hasAcceptedCookies: variables.hasAcceptedCookies,
            };

            update({ ...user, onboarding });
        },
    };
});
