import { ReactNode } from "react";

import { useCurrentWorkspace } from "@accurx/auth";
import { Text } from "@accurx/design";

import { useCurrentUserFullName } from "store/hooks";

import {
    StyledChildContainer,
    StyledContainer,
} from "./TemplateEditorWrapper.styles";

export const TemplateEditorWrapper = ({
    children,
}: {
    children: ReactNode;
}) => {
    const { organisationName: orgName } = useCurrentWorkspace();
    const userName = useCurrentUserFullName();
    return (
        <StyledContainer>
            <Text skinny>Dear Colleague,</Text>
            <Text skinny>
                RE: [Patient name], [Patient date of birth], [Patient NHS no.]
            </Text>
            <StyledChildContainer>{children}</StyledChildContainer>
            <Text skinny>Thanks, {userName}</Text>
            <Text skinny>{orgName}</Text>
        </StyledContainer>
    );
};
