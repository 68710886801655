import React, { Component, MouseEvent } from "react";

import { Button, Text } from "@accurx/design";
import { Log } from "@accurx/shared/dist";
import { Redirect, RouteComponentProps } from "react-router-dom";

import { IUserResponse, PatientListAppointment } from "api/FlemingDtos";
import { NavSubMenuComponent } from "app/navbar/NavSubMenuComponent";
import PatientInfoSubMenu from "app/navbar/PatientInfoSubMenuComponent";
import { WebPatient } from "app/patients/Patient.types";
import { ProductSelection } from "app/selectProduct/ProductSelection";
import { isMessageSentSuccess } from "shared/MessageHelper";
import { PatientHelper } from "shared/PatientHelper";
import { ROUTES, ROUTES_EXTENSION } from "shared/Routes";

import type { AccountState } from "../account/AccountState.types";
import * as FileUploadActions from "../fileUpload/FileUploadActions";
import * as FileUploadStore from "../fileUpload/FileUploadReducer";
import * as MessageTemplatesActions from "../messageTemplates/MessageTemplatesActions";
import * as MessageTemplateStore from "../messageTemplates/MessageTemplatesReducer";
import * as PatientListStore from "../patientLists/PatientListsReducer";
import * as SearchForPatientStore from "../searchForPatient/SearchForPatientReducer";
import * as SelectProductActions from "../selectProduct/SelectProductActions";
import type { SelectProductState } from "../selectProduct/SelectProductState.types";
import * as MessagePatientActions from "./SendMessageActions";
import { SEND_MESSAGE_PAGE_TITLE_ID } from "./SendMessageConstants";
import * as MessagePatientStore from "./SendMessageReducer";
import ConfirmMessage from "./confirmMessage/ConfirmMessageComponent";
import FeedbackMessages from "./sendMessageForm/FeedbackMessagesComponent";
import SendMessageForm from "./sendMessageForm/SendMessageFormComponent";

interface SendMessageType {
    isVideoConsult: boolean;
}

/**
 * Info to be sent to child components in order to
 * render the relevant information
 * i.e. Compose a scheduled video consult or an immediate one
 * */
interface PatientListMessageDetails {
    patientListId?: number;
    appointment?: PatientListAppointment;
}

export type SendMessageProps = { user: IUserResponse } & SendMessageType & // SendMessage expects logged in user, so send in non-null user in props rather than getting nullable user from account state.
    MessagePatientStore.MessagePatientState &
    AccountState &
    FileUploadStore.FileUploadState &
    MessageTemplateStore.MessageTemplatesState & {
        patientLists: PatientListStore.PatientListsState;
    } & typeof MessagePatientActions.actionCreators &
    typeof SelectProductActions.actionCreators &
    typeof FileUploadActions.actionCreators &
    typeof MessageTemplatesActions.actionCreators &
    SearchForPatientStore.SearchPatientState &
    SelectProductState &
    RouteComponentProps<Record<string, string | undefined>>;

class SendMessage extends Component<SendMessageProps> {
    componentDidMount(): void {
        this.props.setTemplateLastActionLocation("product-page");
    }

    componentWillUnmount(): void {
        // We don't want to reset the product / patient when the message is an immediate Video Consult b/c we redirect to the /video-confirmation page and we handle the reset of the patient of the video lobby component unmount
        // We also check whether the message has successfully sent as we want to reset patient data ONLY from the confirmation page
        if (!this.isImmediateVideoConsult && this.getHasSendMessageSuccess) {
            this.props.resetProductType();
        }
    }

    public render(): JSX.Element | null {
        if (this.props.user === null) {
            Log.error("SendMessage expects non-null user prop.");
            return <Redirect to="/" />;
        }

        if (!this.getPatient) {
            return <Redirect to={this.props.searchPatientOrigin} />;
        }

        if (this.getHasSendMessageSuccess) {
            if (this.isImmediateVideoConsult) {
                return <Redirect to={ROUTES_EXTENSION.videoConfirmation} />;
            }
            return this.messagePatientResults();
        } else {
            return this.renderMessagePatient(this.getPatient);
        }
    }

    private messagePatientResults(): JSX.Element | null {
        const patient = this.getPatient;
        if (patient === null) {
            return null;
        }

        return (
            <ConfirmMessage
                patient={patient}
                resetProductChoice={this.resetProductChoice}
            />
        );
    }

    private renderMessagePatient(patient: WebPatient): JSX.Element {
        return (
            // Add margin to make room for Intercom chat
            <div className="row" style={{ marginBottom: "80px" }}>
                <div className="col-sm-12">
                    <NavSubMenuComponent backCallback={this.goBack}>
                        <PatientInfoSubMenu patient={this.getPatient} />
                    </NavSubMenuComponent>
                    <Text
                        variant="subtitle"
                        as="h1"
                        props={{ id: SEND_MESSAGE_PAGE_TITLE_ID }}
                    >
                        {this.props.isVideoConsult
                            ? "Video Consult invitation"
                            : "Compose SMS message"}
                    </Text>
                    <div className="row">
                        <div className="col-sm-12">
                            <FeedbackMessages
                                isVideoConsult={
                                    this.props.productSelection ===
                                    ProductSelection.Video
                                }
                                appointment={
                                    this.patientListDetails.appointment
                                }
                            />
                        </div>
                    </div>
                    <SendMessageForm
                        actionButtons={this.renderFormActionButtons}
                        patientListId={this.patientListDetails.patientListId}
                        appointment={this.patientListDetails.appointment}
                        patient={patient}
                    />
                </div>
            </div>
        );
    }

    private renderFormActionButtons = (
        shouldSendButtonBeDisabled: boolean,
    ): JSX.Element => (
        <div className="row pt-3">
            <div className="col-12 d-flex justify-content-center">
                <Button
                    type="button"
                    onClick={this.goBack}
                    className="mr-2"
                    text="Go back"
                    disabled={this.props.isMessageSending}
                    theme="secondary"
                />
                {this.props.isMessageSending ? (
                    <Button type="button" text="Sending..." disabled />
                ) : (
                    <Button
                        type="submit"
                        text="Send"
                        disabled={shouldSendButtonBeDisabled}
                    />
                )}
            </div>

            {this.getHasSendMessageFailed && (
                <div className="col-sm-12 text-center">
                    <div className="text-danger mt-1">
                        {this.getHasSendMessageErrorMessage !== null
                            ? this.getHasSendMessageErrorMessage
                            : "Sorry your message failed to send! Please try again"}
                    </div>
                </div>
            )}
        </div>
    );

    //#endregion Rendering

    //#region functions

    private goBack = (e?: MouseEvent<HTMLButtonElement>): void => {
        e && e.preventDefault();
        // I could be coming from the select product page OR directly from the patient list
        this.props.history.goBack();
    };

    private resetProductChoice = (): void => {
        this.props.resetProductType();
        this.props.history.push(this.props.searchPatientOrigin);
    };

    //#endRegion functions

    //#region Selectors
    private get getPatient() {
        return PatientHelper.getSelectedPatient(this.props);
    }

    private get getHasSendMessageSuccess(): boolean {
        return (
            (this.props.lastSendMessageResponse &&
                isMessageSentSuccess(
                    this.props.lastSendMessageResponse,
                    this.props.isVideoConsult,
                    this.patientListDetails.appointment?.dateTimeStart || null,
                )) ||
            false
        );
    }

    private get getHasSendMessageFailed(): boolean {
        return (
            (this.props.lastSendMessageResponse &&
                !isMessageSentSuccess(
                    this.props.lastSendMessageResponse,
                    this.props.isVideoConsult,
                    this.patientListDetails.appointment?.dateTimeStart || null,
                )) ||
            false
        );
    }

    private get getHasSendMessageErrorMessage(): string | null {
        return (
            (this.props.lastSendMessageResponse &&
                this.props.lastSendMessageResponse.error) ||
            null
        );
    }

    private get isInPatientListFlow(): boolean {
        return this.props.searchPatientOrigin === ROUTES.patient_lists;
    }

    private get patientListDetails(): PatientListMessageDetails {
        if (this.isInPatientListFlow) {
            return {
                appointment:
                    this.props.patientLists.latestSearchPatientListEntry ||
                    undefined,
                patientListId:
                    this.props.patientLists.latestSearchedPatientListId ||
                    undefined,
            };
        }

        return {
            appointment: undefined,
            patientListId: undefined,
        };
    }

    private get isImmediateVideoConsult(): boolean {
        return (
            this.props.isVideoConsult &&
            // either user has selected Video Consult outside of patient list flow
            (this.patientListDetails.appointment === undefined ||
                // or the patient list entry does not have a dateTimeStart
                this.patientListDetails.appointment.dateTimeStart === null)
        );
    }

    //#endregion Selectors
}

export default SendMessage;
