import { StackPanel, StackPanelProps, Tokens } from "@accurx/design";
import styled from "styled-components";

export const MAX_LOGIN_BOX_WIDTH = 600;

type StyledOuterStackPanelProps = StackPanelProps & {
    backgroundImage?: string;
    compensateForLayoutMargins?: boolean;
};

export const StyledOuterStackPanel = styled(
    StackPanel,
)<StyledOuterStackPanelProps>`
    height: 100%;
    width: 100%;
    padding: ${Tokens.SIZES[4]} ${Tokens.SIZES[2]};
    align-self: center;

    @media (min-width: ${Tokens.BREAKPOINTS.lg}) {
        padding: ${Tokens.SIZES[5]};
        ${({ backgroundImage, compensateForLayoutMargins }) =>
            backgroundImage
                ? `
            background-image: url(${backgroundImage});
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            margin: 0;
            margin-bottom: ${
                compensateForLayoutMargins ? `-${Tokens.SIZES[4]}` : "0"
            };
        `
                : ""}
    }
`;

interface StyledInnerStackPanelProps {
    centerText?: boolean;
    shadow?: boolean;
}

export const LoginBoxShadow =
    "box-shadow: rgb(100 100 111 / 30%) 0px 7px 29px 0px;";

export const StyledInnerStackPanel = styled(
    StackPanel,
)<StyledInnerStackPanelProps>`
    background-color: ${Tokens.COLOURS.greyscale.white};
    padding: ${Tokens.SIZES[3]};
    height: max-content;
    width: 100%;
    max-width: ${MAX_LOGIN_BOX_WIDTH}px;
    border-radius: ${Tokens.SIZES[0.5]};
    border: 1px solid ${Tokens.COLOURS.greyscale.silver};
    ${({ horizontalContentAlignment }) =>
        horizontalContentAlignment === "center" && "text-align: center;"}
    ${({ shadow }) => (shadow ? LoginBoxShadow : "")}
`;
