import { useEffect, useState } from "react";

import { EnrolmentResultReason } from "@accurx/api/patient-messaging";
import { useCurrentWorkspace } from "@accurx/auth";
import { Button, Ds, Flex, Text } from "@accurx/design";
import * as UI from "@accurx/design";
import { format } from "date-fns";
import { useIsComposing } from "domains/message-component/hooks/useIsComposing";
import { useQuestionnaireEnrolmentStatusQuery } from "domains/message-component/hooks/useQuestionnaireEnrolmentStatusQuery";
import {
    PatientExternalId,
    TemplateItem,
} from "domains/message-component/types";
import noop from "lodash/noop";
import { toast } from "react-toastify";

import { AlreadyComposingModal } from "../../../AlreadyComposingModal";

type SelectedTemplateProps = {
    template: TemplateItem;
    onClickTemplate: (value: TemplateItem) => void;
    resetSelectedTemplate: () => void;
    patientExternalIds: PatientExternalId[];
    onIsLoading?: (isLoading: boolean) => void;
};

const formatScheduleDate = (date: string): string => {
    return format(new Date(date), "d MMM yyyy");
};

const getEnrolmentReasonError = (reason: string): string => {
    switch (Number(reason) as EnrolmentResultReason) {
        case EnrolmentResultReason.FailedMustBeTestPatient:
            return "This questionnaire can only be sent to test patients";
        default:
            return "Sorry, something went wrong. Please try again.";
    }
};

export const SelectedTemplate = ({
    template,
    onClickTemplate,
    resetSelectedTemplate,
    patientExternalIds,
    onIsLoading = noop,
}: SelectedTemplateProps): JSX.Element => {
    const workspace = useCurrentWorkspace();
    const isComposing = useIsComposing();

    const { data: questionnaireEnrolment, status } =
        useQuestionnaireEnrolmentStatusQuery(
            {
                workspaceId: workspace.orgId,
                questionnaireId: template.value.id,
                patientExternalIds,
            },
            {
                enabled: template.type === "QuestionnaireTemplate",
                onError: (payload) => {
                    toast(
                        <UI.Feedback
                            title={getEnrolmentReasonError(payload.message)}
                            colour="error"
                        />,
                    );

                    resetSelectedTemplate();
                    onIsLoading(false);
                },
            },
        );

    useEffect(() => {
        onIsLoading(
            status === "loading" && template.type === "QuestionnaireTemplate",
        );
    }, [onIsLoading, status, template.type]);

    const [activeModal, setActiveModal] = useState<
        "replaceMessageWithTemplate" | "patientHasEnrolledInQuestionnarie"
    >();

    useEffect(() => {
        /**
         * There are 2 paths you can follow:
         * 1. Selecting a template which immediately executes the internals of the useEffect
         * 2. Selecting a questionnaire we first call the /floreyenrolmentschedulevalidation to determine if the user has already been enrolled, then we proceed to executes the internals of the useEffect.
         */
        if (
            template.type === "QuestionnaireTemplate" &&
            (status === "loading" || status === "error")
        )
            return;

        if (questionnaireEnrolment?.hasEnrolled) {
            setActiveModal("patientHasEnrolledInQuestionnarie");
            return;
        }

        if (isComposing) {
            setActiveModal("replaceMessageWithTemplate");
            return;
        }

        onClickTemplate(template);
    }, [
        template,
        isComposing,
        status,
        onClickTemplate,
        questionnaireEnrolment?.hasEnrolled,
    ]);

    const onContinueWithSelectedTemplateClick = () => {
        onClickTemplate(template);
    };

    return (
        <>
            {questionnaireEnrolment?.hasEnrolled && (
                <Ds.Modal
                    open={activeModal === "patientHasEnrolledInQuestionnarie"}
                    onOpenChange={resetSelectedTemplate}
                >
                    <Ds.Modal.Section>
                        <Flex flexDirection="column" gap="2">
                            <Ds.Modal.Title>
                                Are you sure you want to select this
                                questionnaire again?
                            </Ds.Modal.Title>
                            <Text>
                                This questionnaire was already sent to this
                                patient on{" "}
                                {formatScheduleDate(
                                    questionnaireEnrolment.schedule
                                        ?.startDate as string,
                                )}
                                . They have until{" "}
                                {formatScheduleDate(
                                    questionnaireEnrolment.schedule
                                        ?.endDate as string,
                                )}{" "}
                                to respond.
                            </Text>
                            <Flex justifyContent="flex-end" gap="2">
                                <Button
                                    theme="secondary"
                                    type="button"
                                    aria-label="Don't proceed with selected questionnaire"
                                    onClick={resetSelectedTemplate}
                                    text="Cancel"
                                />
                                <Button
                                    theme="primary"
                                    type="button"
                                    onClick={
                                        onContinueWithSelectedTemplateClick
                                    }
                                    text="Yes, select questionnaire"
                                />
                            </Flex>
                        </Flex>
                    </Ds.Modal.Section>
                </Ds.Modal>
            )}

            {activeModal === "replaceMessageWithTemplate" && (
                <AlreadyComposingModal
                    onCancel={resetSelectedTemplate}
                    onConfirm={onContinueWithSelectedTemplateClick}
                />
            )}
        </>
    );
};
