import {
    useMedicalRecordConnection,
    useNativeSubscription,
} from "@accurx/native";
import { PatientSearchResult } from "domains/patient/types";
import { mapPatientDemographicsToPatientSearchResult } from "domains/patient/utils/format/mappers";

import { PatientCard } from "../PatientCard/PatientCard";

type CurrentEMRPatientCardProps = {
    onSelect: (patient: PatientSearchResult) => void;
};

export const CurrentEmrPatientCard = ({
    onSelect,
}: CurrentEMRPatientCardProps) => {
    const medicalRecordConnection = useMedicalRecordConnection();
    const medicalRecordPatientFeed = useNativeSubscription(
        "SubscribeCurrentPatient",
    );
    const currentEMRPatientSearchResult = medicalRecordPatientFeed.data
        ? mapPatientDemographicsToPatientSearchResult(
              medicalRecordPatientFeed.data,
          )
        : null;

    return currentEMRPatientSearchResult ? (
        <PatientCard
            patient={currentEMRPatientSearchResult}
            onClick={() => onSelect(currentEMRPatientSearchResult)}
            medicalRecordSystem={medicalRecordConnection.system}
            data-testid={"CurrentEmrPatientCard"}
        />
    ) : null;
};
