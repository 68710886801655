import { TemplateResponse } from "@accurx/api/content";

export const getDistinctCategories = (
    templates: TemplateResponse[],
): string[] => {
    const categories = templates.map((template) => template.category);
    return Array.from(new Set(categories));
};

export const displayFormattedRecipient = {
    Patient: "Patient",
    HealthcareProfessional: "Healthcare provider",
} as const;
