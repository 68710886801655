import { Ds, Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledSearchBox = styled(Ds.Flex).attrs({
    flexDirection: "column",
    gap: "1.5",
})`
    background-color: ${Tokens.COLOURS.greyscale.cloud};
    padding: ${Tokens.SIZES[1.5]};
    margin: 0 ${Tokens.SIZES[0.5]};
    border-radius: ${Tokens.BORDERS.radius};
`;
