import { GetAppointmentRemindersResponse } from "@accurx/api/appointment";
import { HttpError, httpClient2 } from "@accurx/shared";
import { useQuery } from "@tanstack/react-query";

type RemindersAndFilterOptionsParameters = {
    workspaceId: string;
};

export const useRemindersAndFilterOptions = ({
    workspaceId,
}: RemindersAndFilterOptionsParameters) =>
    useQuery({
        queryKey: [
            "AppointmentReminders",
            "RemindersAndFilterOptions",
            { workspaceId },
        ],
        queryFn: async () => {
            try {
                return await httpClient2
                    .get({
                        path: `/api/appointments/AppointmentReminders/${workspaceId}`,
                    })
                    .json<GetAppointmentRemindersResponse>();
            } catch (error) {
                let message = "Request failed";
                if (error instanceof HttpError) {
                    type ExpectedErrorResponse =
                        | { message: string }
                        | undefined;
                    const errorResponse =
                        (await error.response.json()) as ExpectedErrorResponse;
                    if (errorResponse?.message) {
                        message = errorResponse.message;
                    }
                }

                throw new Error(message);
            }
        },
        refetchOnMount: true,
    });
