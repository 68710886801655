import React, { useEffect } from "react";

import { Recipient } from "@accurx/api/content";
import { FeatureName, useCurrentWorkspace, useFeatureFlag } from "@accurx/auth";
import {
    Button,
    Card,
    Ds,
    ErrorSummary,
    Feedback,
    Flex,
    FormFieldWrapper,
    Input,
    Item,
    Text,
    Tokens,
} from "@accurx/design";
import { useMessageTemplate } from "@accurx/message-templates";
import { useParams } from "react-router";

import {
    trackCopyToOrgTemplatePageView,
    trackCreateTemplatePageView,
    trackEditTemplatePageView,
} from "app/analytics/FlemingAnalytics";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";

import * as helpers from "./ManageTemplatesFormPage.helpers";
import {
    useIsOrgTemplate,
    useManageTemplatesForm,
} from "./ManageTemplatesFormPage.hooks";
import { Container } from "./ManageTemplatesFormPage.styles";
import { Params } from "./ManageTemplatesFormPage.types";
import { CategoryModal } from "./components/CategoryModal";
import { CategorySelect } from "./components/CategorySelect";
import { RecipientSelector } from "./components/RecipientSelector/RecipientSelector";
import { TemplateContentCard } from "./components/TemplateContentCard";
import { TemplateSettingsCard } from "./components/TemplateSettingsCard/TemplateSettingsCard";

const FormHeader = ({ title, subheading }: FormHeaderProps): JSX.Element => {
    return (
        <>
            <Ds.Text as="h1" weight="bold" size={"large"} mt={Tokens.SIZES[3]}>
                {title}
            </Ds.Text>
            {subheading && <Ds.Text color={"night"}>{subheading}</Ds.Text>}
        </>
    );
};

type FormHeaderProps = {
    title: string;
    subheading: string;
};

export const ManageTemplatesFormPage = (): JSX.Element => {
    const flemingLoggedInProps = useFlemingLoggedInAnalytics();
    const org = useCurrentWorkspace();
    const { action, templateId } = useParams<Params>();
    const mode = (action as Params["action"]) || "create";
    const { data: template, status } = useMessageTemplate({
        workspaceId: org.orgId,
        templateId: parseInt(templateId),
        enabled: mode !== "create",
    });

    const isTemplateConvergenceRolloutFlagEnabled = useFeatureFlag(
        FeatureName.AccumailTemplateOverviewEnabled,
    );
    const isAccumailEnabled = useFeatureFlag(FeatureName.ExternalEmail);
    const isMessageGPEnabled = useFeatureFlag(FeatureName.CaregiverInitiated);

    const showHealthCareProfessionalFields =
        isTemplateConvergenceRolloutFlagEnabled &&
        (isAccumailEnabled || isMessageGPEnabled);

    const form = useManageTemplatesForm(template);
    const isOrgTemplate = useIsOrgTemplate(template?.owner);

    const onAllowAsSmsChange = (): void => {
        if (form.values.allowAsSms && form.values.allowReplyByDefault) {
            form.onChange.disallowAsSms(
                form.values.allowAsSms,
                form.values.allowReplyByDefault,
            );
        } else {
            form.onChange.allowAsSms(form.values.allowAsSms);
        }
    };
    const onAllowAsBatchChange = (): void => {
        form.onChange.allowAsBatch(form.values.allowAsBatch);
    };

    const onAllowPatientResponseChange = (): void => {
        form.onChange.allowReplyByDefault(form.values.allowReplyByDefault);
    };

    useEffect(() => {
        if (template === null || template === undefined) {
            return;
        }
        switch (mode) {
            case "create":
                trackCreateTemplatePageView({
                    ...flemingLoggedInProps,
                    templateLevel: template.owner,
                    productOrigin: "PatientMessage",
                });
                break;
            case "edit":
                trackEditTemplatePageView({
                    ...flemingLoggedInProps,
                    templateLevel: template.owner,
                    templateId: template.id,
                    productOrigin: "PatientMessage",
                });
                break;
            case "copy":
                trackCopyToOrgTemplatePageView({
                    ...flemingLoggedInProps,
                    templateId: template.id,
                    productOrigin: "PatientMessage",
                });
                break;
        }
    }, [flemingLoggedInProps, mode, template]);

    if (mode !== "create" && status === "loading") {
        return <Ds.Spinner />;
    }

    if (status === "error") {
        return (
            <Feedback
                colour="error"
                title="There was an error loading this page"
            >
                <Ds.Text>Please refresh the page and try again</Ds.Text>
            </Feedback>
        );
    }

    const title: string = (() => {
        switch (mode) {
            case "create":
                return "Create a new template";
            case "edit":
                return `Edit template: "${template?.title}"`;
            case "copy":
                return `Copy template: "${template?.title}"`;
        }
    })();

    const subheading: string = (() => {
        switch (mode) {
            case "create":
                return `This will be available for ${
                    isOrgTemplate
                        ? `everyone in ${org.organisationName}`
                        : `you`
                } to use`;
            case "edit":
                return `These changes will update ${
                    isOrgTemplate
                        ? `the template for everyone in ${org.organisationName}`
                        : `your template`
                }`;
            case "copy":
                return `From my templates for everyone in ${org.organisationName} to use`;
        }
    })();

    const submitText: string = (() => {
        switch (mode) {
            case "create":
            case "copy":
                return form.submitting ? "Saving" : "Save template";
            case "edit":
                return form.submitting ? "Updating" : "Update template";
        }
    })();

    function mapErrorsByKey(key: string) {
        if (form.errorsByKey[key] === undefined) {
            return [];
        }
        return [form.errorsByKey[key].body as JSX.Element];
    }

    const handleSelectCategory = (selectedCategory: string) => {
        if (selectedCategory === "") {
            return;
        }
        // filter by value
        const selectedOptions = form.categories.filter(
            (option) => option.value === selectedCategory,
        );
        const selectedOption = selectedOptions[0];
        form.onChange.categorySelect({
            label: selectedOption.label,
            value: selectedOption.value,
        });
    };

    return (
        <Container>
            <CategoryModal
                isModalOpen={form.addCategoryIsModalOpen}
                handleCloseModal={form.onAddCategoryClose}
                handleAddCategory={form.onAddCategorySubmit}
                formValues={form.addCategoryFormValues}
                onChange={form.addCategoryFormOnChange}
                errors={form.addCategoryFormErrors}
            />
            <Ds.Flex flexDirection="column" gap="3">
                <FormHeader title={title} subheading={subheading} />
                <form onSubmit={form.onSubmit}>
                    <Ds.Flex gap="4" flexDirection="column">
                        <ErrorSummary
                            isShown={form.errorsCount > 0}
                            title="Review the errors in the form"
                        />
                        <Card
                            header={
                                <Text skinny variant="subtitle">
                                    Template details
                                </Text>
                            }
                        >
                            <Ds.Flex flexDirection="column" gap="2">
                                {showHealthCareProfessionalFields && (
                                    <RecipientSelector
                                        value={form.values.recipient}
                                        disabled={mode !== "create"}
                                        onValueChange={(
                                            selectedRecipient: string,
                                        ) =>
                                            form.onChange.recipient(
                                                selectedRecipient as Recipient,
                                            )
                                        }
                                    />
                                )}
                                <FormFieldWrapper
                                    label="Template name"
                                    labelProps={{
                                        htmlFor: "templateName",
                                    }}
                                    errors={mapErrorsByKey("templateName")}
                                    metaInfo={
                                        <Text
                                            variant="note"
                                            as="span"
                                            colour={
                                                form.characterCounts
                                                    .templateName >
                                                helpers.MAX_TEMPLATE_NAME_LENGTH
                                                    ? "red"
                                                    : "zinc"
                                            }
                                        >
                                            {form.characterCounts.templateName}{" "}
                                            / {helpers.MAX_TEMPLATE_NAME_LENGTH}
                                        </Text>
                                    }
                                >
                                    <Input
                                        id="templateName"
                                        value={form.values.templateName}
                                        onChange={form.onChange.templateName}
                                        placeholder="e.g. Opening hours"
                                    />
                                </FormFieldWrapper>
                                <>
                                    <Flex
                                        justifyContent="space-between"
                                        alignItems="start"
                                    >
                                        <Item>
                                            <Text
                                                skinny
                                                as="label"
                                                variant="label"
                                                props={{
                                                    htmlFor: "category-select",
                                                }}
                                            >
                                                Category (optional)
                                            </Text>
                                            <Text skinny>
                                                This will organise templates in
                                                the dropdown.
                                            </Text>
                                        </Item>
                                        <Item>
                                            <Button
                                                onClick={form.onAddCategoryOpen}
                                                type="button"
                                                theme="secondary"
                                                text="Add category"
                                                icon={{ name: "Plus" }}
                                            />
                                        </Item>
                                    </Flex>
                                    {form.categoriesError && (
                                        <Feedback
                                            colour="warning"
                                            title="Unexpected error"
                                            children={
                                                "We couldn't load the Categories. Please refresh."
                                            }
                                        />
                                    )}
                                    <CategorySelect
                                        categoriesError={form.categoriesError}
                                        categoriesStatus={form.categoriesStatus}
                                        categories={form.categories}
                                        addCategorySuccessStatus={
                                            form.addCategorySuccessStatus
                                        }
                                        formValues={form.values}
                                        onChange={handleSelectCategory}
                                    />
                                </>
                            </Ds.Flex>
                        </Card>
                        <TemplateContentCard
                            mapErrorsByKey={mapErrorsByKey}
                            form={form}
                        />
                        {form.values.recipient === Recipient.Patient && (
                            <TemplateSettingsCard
                                isSendViaIndividualMessageChecked={
                                    form.values.allowAsSms
                                }
                                isSendViaBatchMessageChecked={
                                    form.values.allowAsBatch
                                }
                                isAllowPatientsToRespondChecked={
                                    form.values.allowReplyByDefault
                                }
                                onSendViaIndividualMessageChange={
                                    onAllowAsSmsChange
                                }
                                onSendViaBatchMessageChange={
                                    onAllowAsBatchChange
                                }
                                onAllowPatientResponseChange={
                                    onAllowPatientResponseChange
                                }
                            />
                        )}
                        <Ds.Flex
                            flexDirection="row"
                            gap="2"
                            justifyContent="end"
                        >
                            <Button
                                onClick={form.onCancel}
                                text="Cancel"
                                disabled={form.submitting}
                                theme="secondary"
                            />
                            <Button
                                type="submit"
                                text={submitText}
                                disabled={form.submitting}
                                theme="primary"
                            />
                        </Ds.Flex>
                    </Ds.Flex>
                </form>
            </Ds.Flex>
        </Container>
    );
};
