import { Ds, Tokens } from "@accurx/design";
import { SkeletonLoader } from "@accurx/navigation";
import styled from "styled-components";

export const PatientCardSkeleton = ({ role }: { role?: string }) => (
    <StyledSkeletonCard role={role}>
        <StyledSkeletonIcon />
        <Ds.Flex flexDirection="column" gap="1.5">
            <StyledSkeletonText width="200px" />
            <StyledSkeletonText width="300px" />
        </Ds.Flex>
    </StyledSkeletonCard>
);

const StyledSkeletonCard = styled(Ds.Flex)`
    align-items: center;
    gap: ${Tokens.SIZES[1.5]};
    padding: 14px;
`;

const StyledSkeletonIcon = styled(SkeletonLoader)`
    height: 20px;
    width: 14px;
`;

const StyledSkeletonText = styled(SkeletonLoader)`
    margin: ${Tokens.SIZES[0.25]} 0;
`;
