import { Organisation } from "@accurx/auth";
import { Log } from "@accurx/shared";

import {
    MessageTemplate,
    MessageTemplateOwner,
    MessageTemplateType,
    PatientList,
    PatientListSummary,
} from "api/FlemingDtos";
import type { AccountState } from "app/account/AccountState.types";
import { BatchType } from "app/batchMessage/gp/BatchMessage.types";
import { getPatientListEntries } from "app/patientLists/PatientList.helper";
import { TAB_PAGES } from "app/patientProfile/PatientProfile.types";
import { ProductSelection } from "app/selectProduct/ProductSelection";
import { OrganisationHelper } from "shared/OrganisationHelper";

import * as FlemingAnalyticsTracker from "./FlemingAnalytics";
import {
    FlemingLoggedInCustomProperties,
    MessagePracticeAnalyticsProps,
    RecordViewAnalyticsProps,
} from "./FlemingAnalytics";

class AnalyticsMapper {
    static getAnalyticsLoggedInProps = (
        accountState: AccountState,
    ): FlemingLoggedInCustomProperties => {
        const userId = accountState.user?.analyticsUserIdentifier || null;
        const org = OrganisationHelper.getOrganisation(
            accountState,
        ) as Organisation | null;
        const orgId = org?.orgId.toString() || null;
        const orgName = org?.organisationName || null;
        const workspaceSpecialtyList = org?.workspaceSpecialties?.length
            ? org.workspaceSpecialties
            : null;
        const isUserApproved = org?.settings.isApprovedUser ?? null;
        const role = org
            ? org.settings.isAdminUser
                ? "Admin"
                : "Unknown"
            : null;

        return {
            userId,
            CurrentOrganisationId: orgId,
            CurrentOrganisationName: orgName,
            workspaceSpecialtyList,
            isUserApproved,
            userOrganisationRole: role,
        };
    };

    static getAnalyticsProductSelected = (
        productSelected: ProductSelection,
    ): FlemingAnalyticsTracker.FlemingAnalyticsProductType => {
        switch (productSelected) {
            case ProductSelection.Sms:
                return "sms";
            case ProductSelection.Video:
                return "video-consult";
            case ProductSelection.RecordView:
                return "record-access";
            case ProductSelection.MessagePractice:
                return "message-practice";
            case ProductSelection.None:
                return "none";
        }
    };

    //MESSAGE TEMPLATES
    static mapMessageTemplateOwnerToAnalyticsOwner = (
        owner: MessageTemplateOwner | null,
    ): FlemingAnalyticsTracker.MessageTemplateAnalyticsPropsType["owner"] => {
        switch (owner) {
            case MessageTemplateOwner.Organisation:
                return "organisation";
            case MessageTemplateOwner.User:
                return "user";
            case null:
            default:
                return null;
        }
    };

    static getMessageTemplateProps = (
        analyticsTemplateDetails: Pick<
            MessageTemplate,
            "isDefault" | "owner" | "type"
        >,
        accountState: AccountState,
        lastMessageTemplateActionLocation: FlemingAnalyticsTracker.FlemingAnalyticsTemplateLocationType,
        productSelected: ProductSelection,
    ): FlemingAnalyticsTracker.MessageTemplateAnalyticsPropsType => {
        return {
            ...AnalyticsMapper.getAnalyticsLoggedInProps(accountState),
            LocationOfTemplate: lastMessageTemplateActionLocation,
            templateType:
                analyticsTemplateDetails.type ===
                MessageTemplateType.VideoConsult
                    ? "video-consult"
                    : "sms",
            Product:
                AnalyticsMapper.getAnalyticsProductSelected(productSelected),
            isDefault: !!analyticsTemplateDetails.isDefault,
            owner: AnalyticsMapper.mapMessageTemplateOwnerToAnalyticsOwner(
                analyticsTemplateDetails.owner,
            ),
            isFeaturePatientListEnabled: true,
        };
    };

    // PATIENT LISTS

    static getPatientListAnalyticsProps = (
        storeState: ApplicationState,
        listId: number,
    ): FlemingAnalyticsTracker.PatientListAnalyticsType | undefined => {
        let list: PatientList | PatientListSummary | null =
            storeState.patientLists.currentList || null;
        let patientEntriesCount;

        if (list && list.patientListId === listId) {
            patientEntriesCount = getPatientListEntries(
                list.appointmentDays,
            ).length;
        } else {
            list =
                storeState.patientLists.allListSummaries?.find(
                    (l) => l.patientListId === listId,
                ) || null;
            patientEntriesCount = list?.patientCount || 0;
        }

        // Add log error to make sure that analytics is reliable
        if (list === null) {
            Log.warn(
                "getPatientListAnalyticsProps - current list was not found",
                {
                    tags: {
                        logger: "Analytics",
                        patientListId: listId,
                    },
                },
            );
            return;
        }

        return {
            ...AnalyticsMapper.getAnalyticsLoggedInProps(storeState.account),
            countUserList:
                storeState.patientLists?.allListSummaries?.length || 0,
            listSize: patientEntriesCount,
            isOwner: list.isCurrentUserListOwner,
            countShare: list.countShare,
        };
    };

    static getPatientListAnalyticsPropsWithActionOrigin = (
        storeState: ApplicationState,
        listId: number,
    ):
        | FlemingAnalyticsTracker.PatientListAnalyticsMultipleOriginType
        | undefined => {
        const otherProps = AnalyticsMapper.getPatientListAnalyticsProps(
            storeState,
            listId,
        );

        if (otherProps) {
            return {
                pageOrigin: storeState.patientLists.createOrEditListOrigin,
                ...otherProps,
            };
        }
    };

    /**
     * Use only for NEW a patient list that doesn't exist in the store yet
     * */
    static getPatientListAnalyticsPropsForNewlyCreatedList = (
        storeState: ApplicationState,
    ):
        | FlemingAnalyticsTracker.PatientListAnalyticsMultipleOriginType
        | undefined => {
        return {
            ...AnalyticsMapper.getAnalyticsLoggedInProps(storeState.account),
            pageOrigin: storeState.patientLists.createOrEditListOrigin,
            countUserList:
                storeState.patientLists.allListSummaries?.length || 0,
            listSize: 0,
            isOwner: true,
            countShare: 0,
        };
    };

    // RECORD VIEW

    static getRecordViewBaseAnalyticsProps = (
        account: AccountState,
    ): RecordViewAnalyticsProps => {
        return {
            ...AnalyticsMapper.getAnalyticsLoggedInProps(account),
            patientId: null,
            requestId: null,
        };
    };

    // PATIENT PROFILE

    static getAnalyticsPatientProfileTabName = (
        tabId: string,
    ): FlemingAnalyticsTracker.PatientProfileTabName => {
        switch (tabId) {
            case TAB_PAGES.allConversations:
                return "AllConversations";
            case TAB_PAGES.myConversations:
                return "MyConversations";
            case TAB_PAGES.documents:
                return "Documents";
            case TAB_PAGES.overview:
                return "Overview";
            default:
                return "Undefined";
        }
    };

    // MESSAGE PRACTICE

    static getMessagePracticeBaseAnalyticsProps = (
        accountState: AccountState,
    ): MessagePracticeAnalyticsProps => {
        return AnalyticsMapper.getAnalyticsLoggedInProps(accountState);
    };

    // BATCH MESSAGING
    static getBatchType = (
        batchType: BatchType,
        isRudderStackEvent = false,
    ): string => {
        if (isRudderStackEvent && batchType === BatchType.MESSAGE) {
            return "Message";
        }
        return batchType;
    };

    static mapToPascalCase = (...props: string[]): string => {
        const toPascalCase = (str: string): string => {
            return str
                .split(" ")
                .filter((x) => x.length > 0)
                .map((x) => x.charAt(0).toUpperCase() + x.slice(1))
                .join("");
        };

        return props.map((x) => toPascalCase(x)).join("");
    };

    /**
     * This method checks whether or not the PIFU url is inside a message if the PIFU feature flag is turned on.
     * @param isPifuEnabled
     * @param nationalCode The national code of the organisation is used to compose the PT/PIFU url.
     * @param messageInputValue Message text that is going to be sent to the user.
     * @returns a boolean indicating whether or not the PIFU link is within a message
     */
    static messageContainsPIFULink = (
        isPifuEnabled: boolean,
        nationalCode: string | undefined,
        messageInputValue: string,
    ): boolean => {
        // It only checks the link in the url when the PIFU FF is turned on.
        if (isPifuEnabled && nationalCode) {
            //Need to retrieve the url from the backend instead of concatenating it in the frontend.
            //Unfortunately, PIFU can not use the PT status endpoint /api/portal/PatientTriage/134/Status
            //because the commercial team requested that we don't use the PT module which denies access to the aforementioned endpoint.
            //This linear task PIFU-669 was created with the purpose to allow PT users to fetch this information.
            // eslint-disable-next-line no-useless-escape
            const regexUrlPattern = new RegExp(`\/p\/${nationalCode}`, "gmi");
            const withPIFULink = regexUrlPattern.test(messageInputValue);
            return withPIFULink;
        } else {
            return false;
        }
    };
}

export default AnalyticsMapper;
