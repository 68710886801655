import { useCallback, useState } from "react";

import { useCurrentWorkspace } from "@accurx/auth";
import { useNativeTransport } from "@accurx/native";
import { DateHelpers } from "@accurx/shared";

import { useSelfBookSettings } from "../../hooks/useSelfBookSettings";
import { useChangeSelfBookConfigurationMutation } from "../../native/hooks/useChangeSelfBookConfigurationMutation";
import { useSelfBookConfigurationComplete } from "../../native/hooks/useSelfbookConfigurationComplete";
import { useSupportLinkClickMutation } from "../../native/hooks/useSupportLinkClickMutation";
import { useNativeResetSelfBookConfiguration } from "../../native/subscriptions/useNativeResetSelfBookConfiguration";
import { SelfbookConfigurationPayload } from "../../utils/types";
import { SelfbookConfigurationForm } from "../SelfbookConfigurationForm";
import { StyledSelfbookModal } from "../SelfbookConfigurationForm/SelfbookConfigurationForm.styles";

export const SelfbookConfigurationModal = () => {
    const [state, setState] = useState(0);
    const [messageSendTime, setMessageSendTime] = useState<string>(
        DateHelpers.getCurrentTimeStamp(),
    );
    const native = useNativeTransport();
    const currentWorkspace = useCurrentWorkspace();
    const { showCrossOrg, showAppointmentAvailability } = useSelfBookSettings();
    const { mutate: supportLinkClickMutation } = useSupportLinkClickMutation();
    const { mutate: selfbookConfigurationComplete } =
        useSelfBookConfigurationComplete();
    const { mutate: changeSelfBookConfigurationMutation } =
        useChangeSelfBookConfigurationMutation();

    // Fully re-mount form when reset message received
    useNativeResetSelfBookConfiguration((messageSendTime?: string) => {
        const newSafeKeyValue = (state + 1) % 10;
        setState(newSafeKeyValue);
        setMessageSendTime(
            messageSendTime || DateHelpers.getCurrentTimeStamp(),
        );
    });

    const onSupportLinkClick = useCallback(
        (supportLinkUri: string) =>
            supportLinkClickMutation({
                uri: supportLinkUri,
            }),
        [supportLinkClickMutation],
    );
    const onSelfbookConfigComplete = useCallback(
        (selfbookConfigData: SelfbookConfigurationPayload) => {
            selfbookConfigurationComplete({
                validationSuccess: selfbookConfigData.validationSuccess,
            });
        },
        [selfbookConfigurationComplete],
    );
    const onSelfbookConfigChange = useCallback(
        (selfbookConfigData: SelfbookConfigurationPayload) =>
            changeSelfBookConfigurationMutation(selfbookConfigData),
        [changeSelfBookConfigurationMutation],
    );

    return (
        <StyledSelfbookModal>
            {native && (
                <SelfbookConfigurationForm
                    key={state}
                    orgId={currentWorkspace.orgId}
                    sendAt={messageSendTime}
                    organisationName={currentWorkspace.organisationName}
                    showCrossOrg={showCrossOrg}
                    showAppointmentAvailability={showAppointmentAvailability}
                    showCanPatientDeclineSettings={false} // decline settings will be disabled from WPF compose because it is an extra effort which will be redundant soon
                    appOrigin="Compose"
                    marginBottom="-5px" // added negative margin because a 5px bottom margin is added in wpf to avoid issue where webview corners are not rounded
                    onSupportLinkClick={onSupportLinkClick}
                    onSelfbookConfigComplete={onSelfbookConfigComplete}
                    onSelfbookConfigChange={onSelfbookConfigChange}
                />
            )}
        </StyledSelfbookModal>
    );
};
