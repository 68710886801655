import React from "react";

import { Button, Text } from "@accurx/design";
import { DateFormatOptions, DateHelpers } from "@accurx/shared";
import { useSelector } from "react-redux";
import { generatePath, useHistory } from "react-router-dom";

import { NavSubMenuComponent } from "app/navbar/NavSubMenuComponent";
import { ROUTES_WORKSPACE } from "shared/Routes";

export const VaccineListSubMenu = (): JSX.Element => {
    const history = useHistory();

    const practiceId = useSelector(
        ({ practices }: ApplicationState) => practices.selectedPractice,
    );

    const sessionDay = useSelector(
        ({ vaccineRecording }: ApplicationState) =>
            vaccineRecording.session?.sessionDay || null,
    );

    const handleAllSessionsViewClick = (): void => {
        history.push(
            generatePath(ROUTES_WORKSPACE.accubookBookedPatients, {
                workspaceId: practiceId,
            }),
        );
    };

    return (
        <NavSubMenuComponent>
            <div className="d-flex justify-content-between align-items-center">
                <Text variant="title" as="h1" skinny>
                    {sessionDay
                        ? DateHelpers.formatDate(
                              sessionDay,
                              DateFormatOptions.DATE_LONG,
                          )
                        : ""}
                </Text>
                <Button
                    theme="secondary"
                    type="button"
                    onClick={handleAllSessionsViewClick}
                    text="See all lists"
                    className="text-nowrap m-1"
                    icon={{
                        name: "Arrow",
                        style: "Line",
                        rotation: "left",
                    }}
                />
            </div>
        </NavSubMenuComponent>
    );
};
