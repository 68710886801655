import { useCurrentWorkspace } from "@accurx/auth";
import {
    NativeTransportError,
    QuerySearchPatients,
    querySearchPatientsQuery,
    useNativeTransport,
} from "@accurx/native";
import {
    UseMutationOptions,
    useIsMutating,
    useMutation,
} from "@tanstack/react-query";
import {
    FormStateEMRPatientDetails,
    FormStateEmis,
} from "domains/inbox/components/PatientSearch/components/forms/types";

type Variables = Partial<FormStateEmis | FormStateEMRPatientDetails>;

const MUTATION_KEY = ["SearchForPatientInEmr"];

export const useIsAppSearchingForPatient = () =>
    useIsMutating({ mutationKey: MUTATION_KEY }) > 0;

export const useSearchForPatientInEmrMutation = (
    options?: UseMutationOptions<
        QuerySearchPatients,
        NativeTransportError,
        Variables
    >,
) => {
    const transport = useNativeTransport();
    const workspaceId = useCurrentWorkspace().orgId;

    return useMutation({
        mutationKey: MUTATION_KEY,
        mutationFn: async (variables: Variables) => {
            if (!transport)
                throw new NativeTransportError(
                    "SearchPatientsQuery: Called with no native transport",
                );

            return querySearchPatientsQuery(
                { transport, workspaceId },
                variables,
            );
        },
        ...options,
    });
};
