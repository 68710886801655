import { PatientDemographics } from "@accurx/native";
import { MobileNumberHelper } from "@accurx/shared";

import { ContactDetail } from "../reducer.types";
import { normalisePhoneNumber } from "./normalisePhoneNumber";

export const mapPatientDemographicsContactsToContactDetails = ({
    mobileTelephone,
    homeTelephone,
    email,
}: PatientDemographics["contact"]): ContactDetail[] => {
    const contactDetails: ContactDetail[] = [];

    const possibleMobileNumbers = [
        normalisePhoneNumber(mobileTelephone ?? ""),
        normalisePhoneNumber(homeTelephone ?? ""), // fallback to home telephone if mobile is not available
    ];

    const validMobileNumber = possibleMobileNumbers.find((mobileNumber) =>
        MobileNumberHelper.isValidMobileNumber(mobileNumber ?? undefined),
    );

    if (validMobileNumber) {
        contactDetails.push({
            method: "Mobile",
            value: validMobileNumber,
            origin: "EMR",
        });
    }

    if (email) {
        contactDetails.push({
            method: "Email",
            value: email,
            origin: "EMR",
        });
    }

    return contactDetails;
};
