import { Button, Ds, Flex, Text } from "@accurx/design";

type ChangePatientModalProps = {
    medicalRecordSystemFriendlyName: string;
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
};

export const ChangePatientModal = ({
    isOpen,
    onClose,
    onConfirm,
    medicalRecordSystemFriendlyName,
}: ChangePatientModalProps) => (
    <Ds.Modal
        open={isOpen}
        onOpenChange={(open) => {
            if (!open) {
                onClose();
            }
        }}
    >
        <Ds.Modal.Section>
            <Flex flexDirection="column" gap="2">
                <Ds.Modal.Title>
                    The patient open in {medicalRecordSystemFriendlyName} has
                    changed
                </Ds.Modal.Title>
                <Text>
                    Do you want to change the patient you're messaging to match
                    the new {medicalRecordSystemFriendlyName} patient, or go
                    back to your previous patient?
                </Text>
                <Flex justifyContent="flex-end" gap="2">
                    <Button
                        theme="secondary"
                        type="button"
                        data-testid="secondary-button"
                        onClick={onClose}
                        text="Back to message"
                    />
                    <Button
                        theme="primary"
                        type="button"
                        data-testid="primary-button"
                        onClick={onConfirm}
                        text="Change patient"
                    />
                </Flex>
            </Flex>
        </Ds.Modal.Section>
    </Ds.Modal>
);
