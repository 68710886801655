import { ReactNode, useEffect, useState } from "react";

import { useAnalytics } from "@accurx/analytics";
import { MarketClassificationValue } from "@accurx/api/portal";
import {
    FeatureName,
    useCurrentUser,
    useCurrentWorkspace,
    useFeatureFlag,
} from "@accurx/auth";
import { Ds } from "@accurx/design";
import { Accordion, FocusRingSpacing, Sidenav } from "@accurx/navigation";
import {
    ArchivedWorkspaceHiddenItemWrapper,
    hasAccurxInstalled,
    isMyWorkspace,
    useWorkspaceClassifications,
} from "@accurx/workspace-management";
import { userflowIds } from "layouts/constants";
import { generatePath, matchPath, useLocation } from "react-router-dom";

import { isAccubookEnabled } from "app/accubook/utils/isAccubookEnabled";
import { isPatientTriageEnabled } from "app/reporting/utils/isPatientTriageEnabled";
import { ROUTES_BASE, ROUTES_PRIMARY, ROUTES_WORKSPACE } from "shared/Routes";
import { pascalCase } from "shared/formatters/String";

import { getNavigationVersion } from "../../helpers";
import { SecondaryNavLink } from "../SecondaryNavLink";
import { SecondaryNavToggle } from "../SecondaryNavToggle";

type AccordionId =
    | ""
    | "Patient triage"
    | "Appointments"
    | "Reporting"
    | "Workspace"
    | "Account"
    | "AccuBook";

const AppointmentReminderNavLinks = ({
    workspaceId,
}: {
    workspaceId: number;
}) => (
    <>
        <SecondaryNavLink
            to={generatePath(
                ROUTES_WORKSPACE.workspaceAppointmentRemindersManage,
                { workspaceId },
            )}
            userflowId={userflowIds.navigation.secondary.remindersManage}
        >
            Manage reminders
        </SecondaryNavLink>
        <SecondaryNavLink
            to={generatePath(
                ROUTES_WORKSPACE.workspaceAppointmentRemindersSent,
                { workspaceId },
            )}
            userflowId={userflowIds.navigation.secondary.remindersSent}
        >
            Sent reminders
        </SecondaryNavLink>
        <SecondaryNavLink
            to={generatePath(
                ROUTES_WORKSPACE.workspaceAppointmentRemindersChanges,
                { workspaceId },
            )}
            userflowId={
                userflowIds.navigation.secondary.remindersAppointmentChanges
            }
        >
            Appointment changes
        </SecondaryNavLink>
    </>
);

export const SettingsNavigation = () => {
    const { user } = useCurrentUser();
    const workspace = useCurrentWorkspace();
    const { orgId: workspaceId } = workspace;
    const { pathname } = useLocation();

    const { data: { market } = {} } = useWorkspaceClassifications({
        workspaceId,
    });

    const isGeneralPractice =
        market === MarketClassificationValue.GP ||
        market === MarketClassificationValue.PCN;
    const accurxInstalled = hasAccurxInstalled(workspace);
    const isFloreyBuilderEnabled = useFeatureFlag(FeatureName.FloreyBuilder);
    const isWayfinderEnabled = useFeatureFlag(FeatureName.Wayfinder);
    const isRecordAccessEnabled = useFeatureFlag(FeatureName.RecordAccessBeta);
    const isAppointmentRemindersAlphaEnabled = useFeatureFlag(
        FeatureName.AppointmentRemindersAlpha,
    );
    const isEmbeddedPowerBiPOCEnabled = useFeatureFlag(
        FeatureName.EmbeddedPowerBiPOC,
    );
    const isBulkAppointmentImportModuleEnabled = useFeatureFlag(
        FeatureName.BulkAppointmentImportModuleEnabled,
    );

    const isPatientTriageModuleEnabled = isPatientTriageEnabled(workspace);
    const isAccubookModuleEnabled = isAccubookEnabled(workspace);

    const [activeSection, setActiveSection] = useState<AccordionId>("");

    useEffect(() => {
        const isMatchPatientTriage = matchPath(pathname, {
            path: ROUTES_WORKSPACE.workspacePatientTriage,
        });
        const isMatchAppointments = matchPath(pathname, {
            path: [
                ROUTES_WORKSPACE.workspaceAppointmentReminders,
                ROUTES_WORKSPACE.workspaceAppointmentsUpload,
            ],
        });
        const isMatchReporting = matchPath(pathname, {
            path: ROUTES_WORKSPACE.reporting,
        });
        const isMatchAccubook = matchPath(pathname, {
            path: ROUTES_WORKSPACE.accubook,
        });
        const isMatchWorkspaceSettings = matchPath(pathname, {
            path: [
                ROUTES_WORKSPACE.workspaceGeneralSettings,
                ROUTES_WORKSPACE.workspaceUserManagement,
                ROUTES_WORKSPACE.workspaceAvailableFeatures,
                ROUTES_WORKSPACE.workspaceInstalledComputers,
                ROUTES_WORKSPACE.workspaceTeamNotifications,
                ROUTES_WORKSPACE.workspaceSmsSettings,
                ROUTES_WORKSPACE.workspaceResources,
            ],
        });
        const isMatchAccountSettings = matchPath(pathname, {
            path: ROUTES_PRIMARY.account,
        });
        if (isMatchPatientTriage) {
            setActiveSection("Patient triage");
        } else if (isMatchAppointments) {
            setActiveSection("Appointments");
        } else if (isMatchReporting) {
            setActiveSection("Reporting");
        } else if (isMatchAccubook) {
            setActiveSection("AccuBook");
        } else if (isMatchWorkspaceSettings) {
            setActiveSection("Workspace");
        } else if (isMatchAccountSettings) {
            setActiveSection("Account");
        } else {
            setActiveSection("");
        }
    }, [pathname]);

    return (
        <Sidenav.Content>
            <Sidenav.Title>Settings</Sidenav.Title>
            <Accordion.Root
                value={activeSection}
                onValueChange={(val) => setActiveSection(val as AccordionId)}
                collapsible
                $scrollable
            >
                <SecondaryNavLink
                    icon={{ name: "MessageWrite" }}
                    to={generatePath(
                        ROUTES_WORKSPACE.workspaceTemplatesOverview,
                        { workspaceId },
                    )}
                    userflowId={userflowIds.navigation.secondary.templates}
                >
                    Templates
                </SecondaryNavLink>
                {isFloreyBuilderEnabled && (
                    <SecondaryNavLink
                        icon={{ name: "Questionnaire" }}
                        to={generatePath(
                            ROUTES_WORKSPACE.workspaceQuestionnaireBuilder,
                            { workspaceId },
                        )}
                        userflowId={userflowIds.navigation.secondary.templates}
                    >
                        Questionnaires
                    </SecondaryNavLink>
                )}
                {(isRecordAccessEnabled || accurxInstalled) && (
                    <ArchivedWorkspaceHiddenItemWrapper>
                        <SecondaryNavLink
                            icon={{ name: "RecordPerson" }}
                            to={generatePath(
                                ROUTES_WORKSPACE.workspaceMedicalRecords,
                                { workspaceId },
                            )}
                            userflowId={
                                userflowIds.navigation.secondary.recordRequests
                            }
                        >
                            Record requests
                        </SecondaryNavLink>
                    </ArchivedWorkspaceHiddenItemWrapper>
                )}
                <SecondaryNavLink
                    icon={{ name: "TickDouble" }}
                    to={generatePath(
                        ROUTES_WORKSPACE.workspaceDeliveryReceipts,
                        { workspaceId },
                    )}
                    userflowId={userflowIds.navigation.secondary.smsStatus}
                >
                    SMS status
                </SecondaryNavLink>
                {accurxInstalled && workspace.settings.isApprovedUser && (
                    <SecondaryNavLink
                        icon={{ name: "Messages" }}
                        to={generatePath(
                            ROUTES_WORKSPACE.workspaceBatchMessages,
                            { workspaceId },
                        )}
                    >
                        Batch messages
                    </SecondaryNavLink>
                )}
                {(isAppointmentRemindersAlphaEnabled ||
                    isBulkAppointmentImportModuleEnabled) && (
                    <SecondaryNavAccordion
                        name="Appointments"
                        icon={{ name: "Bell" }}
                        isOpen={activeSection === "Appointments"}
                        onClick={() => setActiveSection("Appointments")}
                        userflowId={userflowIds.navigation.secondary.reminders}
                    >
                        {isBulkAppointmentImportModuleEnabled && (
                            <SecondaryNavLink
                                to={generatePath(
                                    ROUTES_WORKSPACE.workspaceAppointmentsUpload,
                                    { workspaceId },
                                )}
                                userflowId={
                                    userflowIds.navigation.secondary
                                        .appointmentsUpload
                                }
                            >
                                Uploaded appointments
                            </SecondaryNavLink>
                        )}
                        {isAppointmentRemindersAlphaEnabled && (
                            <AppointmentReminderNavLinks
                                workspaceId={workspaceId}
                            />
                        )}
                    </SecondaryNavAccordion>
                )}
                {isPatientTriageModuleEnabled && (
                    <SecondaryNavAccordion
                        name="Patient triage"
                        icon={{ name: "preview-Triage" }}
                        isOpen={activeSection === "Patient triage"}
                        onClick={() => setActiveSection("Patient triage")}
                        userflowId={
                            userflowIds.navigation.secondary.patientTriage
                        }
                    >
                        <SecondaryNavLink
                            to={generatePath(
                                ROUTES_WORKSPACE.workspacePatientTriage,
                                { workspaceId },
                            )}
                            analyticsId="WhatIsPatientTriage"
                            isExact
                            userflowId={
                                userflowIds.navigation.secondary
                                    .patientTriageWhatIs
                            }
                        >
                            What is Patient triage?
                        </SecondaryNavLink>
                        <SecondaryNavLink
                            to={generatePath(
                                ROUTES_WORKSPACE.workspacePatientTriageSettings,
                                { workspaceId },
                            )}
                            analyticsId="PatientTriageSettings"
                            userflowId={
                                userflowIds.navigation.secondary
                                    .patientTriageSettings
                            }
                        >
                            Settings
                        </SecondaryNavLink>
                        <SecondaryNavLink
                            to={generatePath(
                                ROUTES_WORKSPACE.workspacePatientTriageSetup,
                                { workspaceId },
                            )}
                            analyticsId="PatientTriageSetup"
                            userflowId={
                                userflowIds.navigation.secondary
                                    .patientTriageSetup
                            }
                        >
                            Set up
                        </SecondaryNavLink>
                    </SecondaryNavAccordion>
                )}
                {workspace.settings.isApprovedUser && (
                    <SecondaryNavAccordion
                        name="Reporting"
                        icon={{ name: "Chart" }}
                        isOpen={activeSection === "Reporting"}
                        onClick={() => setActiveSection("Reporting")}
                        userflowId={userflowIds.navigation.secondary.reporting}
                    >
                        <SecondaryNavLink
                            to={generatePath(ROUTES_WORKSPACE.reporting, {
                                workspaceId,
                            })}
                            analyticsId="ReportingOverview"
                            isExact
                            userflowId={
                                userflowIds.navigation.secondary
                                    .reportingOverview
                            }
                        >
                            Overview
                        </SecondaryNavLink>
                        <SecondaryNavLink
                            to={generatePath(
                                ROUTES_WORKSPACE.reportingTeamUsage,
                                {
                                    workspaceId,
                                },
                            )}
                            analyticsId="ReportingTeamUsage"
                            userflowId={
                                userflowIds.navigation.secondary
                                    .reportingTeamUsage
                            }
                        >
                            Team usage
                        </SecondaryNavLink>
                        {isEmbeddedPowerBiPOCEnabled && (
                            <>
                                {isPatientTriageModuleEnabled && (
                                    <SecondaryNavLink
                                        to={generatePath(
                                            ROUTES_WORKSPACE.reportingPatientTriage,
                                            { workspaceId },
                                        )}
                                        analyticsId="ReportingPatientTriage"
                                        userflowId={
                                            userflowIds.navigation.secondary
                                                .reportingPatientTriage
                                        }
                                    >
                                        Patient triage
                                    </SecondaryNavLink>
                                )}
                                <SecondaryNavLink
                                    to={generatePath(
                                        ROUTES_WORKSPACE.reportingResponseRates,
                                        { workspaceId },
                                    )}
                                    analyticsId="ReportingResponseRates"
                                    userflowId={
                                        userflowIds.navigation.secondary
                                            .reportingResponseRates
                                    }
                                >
                                    Response rates
                                </SecondaryNavLink>
                                <SecondaryNavLink
                                    to={generatePath(
                                        ROUTES_WORKSPACE.reportingFragments,
                                        { workspaceId },
                                    )}
                                    analyticsId="ReportingFragments"
                                    userflowId={
                                        userflowIds.navigation.secondary
                                            .reportingFragments
                                    }
                                >
                                    Fragments
                                </SecondaryNavLink>
                            </>
                        )}
                    </SecondaryNavAccordion>
                )}
                {isGeneralPractice && isAccubookModuleEnabled && (
                    <SecondaryNavAccordion
                        name="AccuBook"
                        icon={{ name: "Syringe" }}
                        isOpen={activeSection === "AccuBook"}
                        onClick={() => setActiveSection("AccuBook")}
                    >
                        <SecondaryNavLink
                            to={generatePath(
                                ROUTES_WORKSPACE.accubookManagePatients,
                                { workspaceId },
                            )}
                            analyticsId="AccubookManagePatients"
                        >
                            Manage patients
                        </SecondaryNavLink>
                        <SecondaryNavLink
                            to={generatePath(
                                ROUTES_WORKSPACE.accubookManageClinics,
                                { workspaceId },
                            )}
                            analyticsId="AccubookManageClinics"
                        >
                            Manage clinics
                        </SecondaryNavLink>
                        <SecondaryNavLink
                            to={generatePath(
                                ROUTES_WORKSPACE.accubookBookedPatients,
                                { workspaceId },
                            )}
                            analyticsId="AccubookBookedClinics"
                        >
                            Booked patients
                        </SecondaryNavLink>
                        <SecondaryNavLink
                            to={generatePath(
                                ROUTES_WORKSPACE.accubookDeliverySites,
                                { workspaceId },
                            )}
                            analyticsId="AccubookDeliverySites"
                        >
                            Delivery sites
                        </SecondaryNavLink>
                        <SecondaryNavLink
                            to={generatePath(
                                ROUTES_WORKSPACE.accubookDashboard,
                                { workspaceId },
                            )}
                            analyticsId="AccubookDashboard"
                        >
                            Dashboard
                        </SecondaryNavLink>
                    </SecondaryNavAccordion>
                )}
                <FocusRingSpacing>
                    <Sidenav.Divider />
                </FocusRingSpacing>
                <SecondaryNavAccordion
                    name="Workspace"
                    icon={{ name: "Carehome" }}
                    isOpen={activeSection === "Workspace"}
                    onClick={() => setActiveSection("Workspace")}
                    userflowId={
                        userflowIds.navigation.secondary.workspaceSettings
                    }
                >
                    <SecondaryNavLink
                        to={generatePath(
                            ROUTES_WORKSPACE.workspaceGeneralSettings,
                            { workspaceId },
                        )}
                        userflowId={
                            userflowIds.navigation.secondary
                                .workspaceSettingsName
                        }
                    >
                        Workspace name
                    </SecondaryNavLink>
                    {workspace.settings.isApprovedUser && (
                        <SecondaryNavLink
                            to={generatePath(
                                ROUTES_WORKSPACE.workspaceUserManagement,
                                { workspaceId },
                            )}
                            userflowId={
                                userflowIds.navigation.secondary
                                    .workspaceSettingsUsers
                            }
                        >
                            Users
                        </SecondaryNavLink>
                    )}
                    {isGeneralPractice && (
                        <SecondaryNavLink
                            to={generatePath(
                                ROUTES_WORKSPACE.workspaceAvailableFeatures,
                                { workspaceId },
                            )}
                            userflowId={
                                userflowIds.navigation.secondary
                                    .workspaceSettingsAvailableFeatures
                            }
                        >
                            Available features
                        </SecondaryNavLink>
                    )}
                    {accurxInstalled && (
                        <SecondaryNavLink
                            to={generatePath(
                                ROUTES_WORKSPACE.workspaceInstalledComputers,
                                { workspaceId },
                            )}
                            userflowId={
                                userflowIds.navigation.secondary
                                    .workspaceSettingsInstalledComputers
                            }
                        >
                            Installed computers
                        </SecondaryNavLink>
                    )}
                    {!isMyWorkspace(workspace) && (
                        <SecondaryNavLink
                            to={generatePath(
                                ROUTES_WORKSPACE.workspaceTeamNotifications,
                                { workspaceId },
                            )}
                            userflowId={
                                userflowIds.navigation.secondary
                                    .workspaceSettingsTeamNotifications
                            }
                        >
                            Team notifications
                        </SecondaryNavLink>
                    )}
                    <SecondaryNavLink
                        to={generatePath(
                            ROUTES_WORKSPACE.workspaceSmsSettings,
                            { workspaceId },
                        )}
                        userflowId={
                            userflowIds.navigation.secondary
                                .workspaceSettingsSmsSenderId
                        }
                    >
                        SMS sender ID
                    </SecondaryNavLink>
                    {isWayfinderEnabled && (
                        <SecondaryNavLink
                            to={generatePath(
                                ROUTES_WORKSPACE.workspaceResources,
                                { workspaceId },
                            )}
                            userflowId={
                                userflowIds.navigation.secondary
                                    .workspaceSettingsResources
                            }
                        >
                            Resources
                        </SecondaryNavLink>
                    )}
                </SecondaryNavAccordion>

                <SecondaryNavAccordion
                    name="Account"
                    icon={{ name: "preview-Account" }}
                    isOpen={activeSection === "Account"}
                    onClick={() => setActiveSection("Account")}
                    userflowId={
                        userflowIds.navigation.secondary.accountSettings
                    }
                >
                    <SecondaryNavLink
                        to={ROUTES_BASE.accountUserNameAndEmail}
                        userflowId={
                            userflowIds.navigation.secondary
                                .accountSettingsNameAndEmail
                        }
                    >
                        Name and email
                    </SecondaryNavLink>
                    {!user.isAuthenticatedExternally && (
                        <SecondaryNavLink to={ROUTES_BASE.accountUserPassword}>
                            Password
                        </SecondaryNavLink>
                    )}
                    <SecondaryNavLink
                        to={ROUTES_BASE.accountUserWorkspaceAccess}
                        userflowId={
                            userflowIds.navigation.secondary
                                .accountSettingsYourWorkspaces
                        }
                    >
                        Your workspaces
                    </SecondaryNavLink>
                    <SecondaryNavLink
                        to={ROUTES_BASE.accountUserManageSignature}
                        userflowId={
                            userflowIds.navigation.secondary
                                .accountSettingsMessageSignature
                        }
                    >
                        Message signature
                    </SecondaryNavLink>
                    <SecondaryNavLink
                        to={ROUTES_BASE.accountUserCookies}
                        userflowId={
                            userflowIds.navigation.secondary
                                .accountSettingsCookies
                        }
                    >
                        Cookies
                    </SecondaryNavLink>
                    <SecondaryNavLink
                        to={ROUTES_BASE.accountUserSecurity}
                        userflowId={
                            userflowIds.navigation.secondary
                                .accountSettingsSecurity
                        }
                    >
                        Security
                    </SecondaryNavLink>
                    <SecondaryNavLink
                        to={ROUTES_BASE.accountUserTermsAndConditions}
                        userflowId={
                            userflowIds.navigation.secondary
                                .accountSettingsTermsAndConditions
                        }
                    >
                        Terms and conditions
                    </SecondaryNavLink>
                </SecondaryNavAccordion>
            </Accordion.Root>
        </Sidenav.Content>
    );
};

const SecondaryNavAccordion = ({
    name,
    icon,
    isOpen,
    onClick,
    children,
    userflowId,
}: {
    name: string;
    icon?: Pick<Ds.IconProps, "name" | "appearance" | "rotate">;
    isOpen: boolean;
    onClick?: () => void;
    children?: ReactNode;
    userflowId?: string;
}) => {
    const track = useAnalytics();

    const handleClick = () => {
        onClick && onClick();
        track("NavigationSubMenu Button Click", {
            navigationOptionSelected: pascalCase(name),
            navigationVersion: getNavigationVersion(),
            buttonType: "Accordion",
            eventVersion: 3,
        });
    };

    return (
        <Accordion.Item.Level2 $scrollable={false} value={name}>
            <Accordion.Header>
                <Accordion.Trigger asChild>
                    <SecondaryNavToggle
                        icon={icon}
                        isOpen={isOpen}
                        onClick={handleClick}
                        userflowId={userflowId}
                    >
                        {name}
                    </SecondaryNavToggle>
                </Accordion.Trigger>
            </Accordion.Header>
            <Accordion.Content>
                <Accordion.Indented>{children}</Accordion.Indented>
            </Accordion.Content>
        </Accordion.Item.Level2>
    );
};
