import { ExternalEmailContact } from "@accurx/api/clinician-messaging";
import { useCurrentWorkspace } from "@accurx/auth";
import { Ds } from "@accurx/design";
import { useFrequentContactsQuery } from "domains/directory/hooks/queries/useFrequentContactsQuery";

import { ContactSelectCard } from "../ContactSelectCard/ContactSelectCard";
import {
    ContactListSkeleton,
    SkeletonText,
} from "../ContactSelectCard/ContactSelectCardSkeleton";

const FREQUENT_CONTACTS_LIMIT = 6;

export const FrequentContacts = () => {
    const { orgId: workspaceId } = useCurrentWorkspace();
    const { data: contacts = [], isLoading } = useFrequentContactsQuery({
        workspaceId,
        limit: FREQUENT_CONTACTS_LIMIT,
    });
    return (
        <>
            {isLoading ? (
                <>
                    <SkeletonText width="150px" />
                    <ContactListSkeleton
                        count={FREQUENT_CONTACTS_LIMIT}
                        role="status"
                    />
                </>
            ) : (
                <>
                    <Ds.Box pb="0.5">
                        <Ds.Text as="h2" weight="bold" color="zinc">
                            Frequently contacted
                        </Ds.Text>
                    </Ds.Box>
                    <ContactList contacts={contacts} />
                </>
            )}
        </>
    );
};

const ContactList = ({ contacts }: { contacts: ExternalEmailContact[] }) => {
    return (
        <Ds.Flex flexDirection="column" gap="1">
            {contacts.map((contact) => {
                const serviceName = contact.service?.name;
                const institutionName = contact.institution?.name;
                return (
                    <ContactSelectCard
                        contact={contact}
                        data-testid={contact.id}
                        key={contact.id}
                    >
                        <Ds.Text color="metal">
                            {institutionName}
                            {institutionName && serviceName && (
                                <>&nbsp;&bull;&nbsp;</>
                            )}
                            {serviceName}
                        </Ds.Text>
                    </ContactSelectCard>
                );
            })}
        </Ds.Flex>
    );
};
