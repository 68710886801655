import { Conversation } from "@accurx/concierge/types";
import { getConversationRequestType } from "domains/inbox/util/getConversationRequestType";
import { getConversationServerId } from "domains/inbox/util/getConversationServerId";
import { getConversationType } from "domains/inbox/util/getConversationType";

type AppOrigin = "ConversationActions" | "MessageActions";

export const useConversationAnalyticsFields = ({
    conversation,
    appOrigin,
}: {
    conversation: Conversation;
    appOrigin?: AppOrigin;
}) => {
    return {
        conversationType: getConversationType(conversation),
        conversationRequestType: getConversationRequestType(conversation),
        conversationId: getConversationServerId(conversation),
        conversationStartTimestampUtc: conversation.items[0].createdAt,
        appOrigin,
    };
};
