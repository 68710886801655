import { useState } from "react";

import { GPRequestResult } from "@accurx/api/portal";
import { FeatureName, useCurrentWorkspace, useFeatureFlag } from "@accurx/auth";
import { createRouterState as createRouterStatePatientForCompose } from "@accurx/compose";
import {
    Button,
    Ds,
    Feedback,
    Popover,
    PopoverContent,
    PopoverTrigger,
    Tokens,
} from "@accurx/design";
import { PopoverItemLink } from "@accurx/navigation";
import {
    PatientSearchResult,
    formatDisplayAge,
    formatDisplayName,
    formatGender,
    mapPatientSearchResultToPdsResult,
} from "@accurx/patient";
import { NhsNumberHelpers } from "@accurx/shared";
import { ArchivedWorkspaceHiddenItemWrapper } from "@accurx/workspace-management";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { Link, generatePath } from "react-router-dom";
import { useAnalytics } from "reduxQuarantine/useAnalytics";
import styled from "styled-components";

import { actionCreators } from "app/selectProduct/SelectProductActions";
import useSavePatientSearchToStore from "app/sharedComponents/patientContactDropdown/hooks/useSavePatientSearchToStore";

import { PatientGpDetails } from "../PatientGpDetails/PatientGpDetails";
import { PatientSearchTooltip } from "../PatientSearchTooltip/PatientSearchTooltip";

export type PatientDetailsProps = {
    patient: PatientSearchResult;
};

export const PatientDetails = ({ patient }: PatientDetailsProps) => {
    const saveToStore = useSavePatientSearchToStore();

    const handlePatientAction = () =>
        saveToStore(mapPatientSearchResultToPdsResult(patient));

    const displayName = formatDisplayName(patient, { showPrefix: true });
    const displayAge = formatDisplayAge(patient.ageDisplay);
    const displayGender = formatGender(patient.gender);
    const displayNhsNumber = NhsNumberHelpers.formatNhsNumber(
        String(patient.nhsNumber),
    );

    return (
        <>
            <Ds.Text as="h3" size="large" weight="bold" mb="1">
                {displayName} (
                {patient.deceased
                    ? "Deceased"
                    : `${displayAge}, ${displayGender}`}
                )
            </Ds.Text>
            <Ds.Text mb="2">
                NHS: {displayNhsNumber} • DOB:{" "}
                {patient.dateOfBirth?.replaceAll("-", " ")}
            </Ds.Text>

            <PatientActions
                patient={patient}
                onPatientAction={handlePatientAction}
            />

            {patient.deceased && (
                <Ds.Box mt="1">
                    <Feedback
                        colour="warning"
                        title="This patient is deceased, so you can’t take some actions"
                    />
                </Ds.Box>
            )}

            <StyledDivider />

            <Ds.Box py="1" mb="1">
                <Ds.Text as="h4" weight="bold" color="zinc">
                    Care network
                </Ds.Text>
            </Ds.Box>
            <PatientGpDetails patient={patient} />
        </>
    );
};

const PatientActions = ({
    patient,
    onPatientAction,
}: {
    patient: PatientSearchResult;
    onPatientAction: () => void;
}) => {
    const track = useAnalytics();
    const dispatch = useDispatch();
    const originLocation = useLocation();
    const { orgId: workspaceId } = useCurrentWorkspace();

    const isVideoConsultEnabled = useFeatureFlag(FeatureName.VideoConsultWeb);
    const isMessagingEnabled = useFeatureFlag(FeatureName.PatientThreadSMS);

    const showVideoConsultAction =
        isVideoConsultEnabled && isMessagingEnabled && !patient.deceased;

    const showRecordViewAction =
        useFeatureFlag(FeatureName.RecordAccessBeta) &&
        patient.recordViewSupported === GPRequestResult.Success;

    const showMoreActions = showVideoConsultAction || showRecordViewAction;

    const [isActionsMenuOpen, setActionsMenuOpen] = useState(false);

    const trackContactOptionClick = (
        optionSelected:
            | "MessagePatient"
            | "PatientProfile"
            | "VideoInvite"
            | "RecordView",
    ) => {
        track("PatientContact MenuItem Click", {
            optionSelected,
            productOrigin: "PatientSearch",
            navigationVersion: "Unified",
            eventVersion: 3,
        });
    };

    const composePath = {
        pathname: `/w/${workspaceId}/compose`,
        state: {
            ...createRouterStatePatientForCompose({
                patient,
                appOrigin: "PatientSearch",
            }),
            originLocation,
        },
    };

    const handleClickPatientProfile = () => {
        trackContactOptionClick("PatientProfile");
        onPatientAction();
    };

    const handleClickVideo = () => {
        setActionsMenuOpen(false);
        onPatientAction();
        dispatch(actionCreators.selectVideoProductType());
        trackContactOptionClick("VideoInvite");
    };

    const handleClickRecordView = () => {
        setActionsMenuOpen(false);
        onPatientAction();
        dispatch(actionCreators.selectRecordViewType());
        trackContactOptionClick("RecordView");
    };

    return (
        <Ds.Flex gap="1">
            <ArchivedWorkspaceHiddenItemWrapper>
                {patient.deceased ? (
                    <Button
                        theme="secondary"
                        text="Message"
                        icon={{ name: "preview-Message", colour: "blue" }}
                        disabled={true}
                    />
                ) : (
                    <Link
                        to={composePath}
                        aria-label="Message patient"
                        onClick={() =>
                            trackContactOptionClick("MessagePatient")
                        }
                    >
                        <Button
                            as="span"
                            theme="secondary"
                            text="Message"
                            icon={{ name: "preview-Message", colour: "blue" }}
                        />
                    </Link>
                )}
            </ArchivedWorkspaceHiddenItemWrapper>
            <Link
                to={generatePath("/w/:workspaceId/patient-profile", {
                    workspaceId,
                })}
                onClick={handleClickPatientProfile}
            >
                <Button
                    as="span"
                    theme="secondary"
                    text="View profile"
                    icon={{ name: "Person", colour: "blue" }}
                />
            </Link>
            <ArchivedWorkspaceHiddenItemWrapper>
                {showMoreActions && (
                    <Popover
                        open={isActionsMenuOpen}
                        onOpenChange={setActionsMenuOpen}
                    >
                        <PatientSearchTooltip text="More options">
                            <PopoverTrigger>
                                <Button
                                    theme="secondary"
                                    icon={{
                                        name: "More",
                                        colour: "blue",
                                        title: "More",
                                        style: "Fill",
                                    }}
                                />
                            </PopoverTrigger>
                        </PatientSearchTooltip>
                        <PopoverContent>
                            {showVideoConsultAction && (
                                <PopoverItemLink
                                    to="/send-message"
                                    onClick={handleClickVideo}
                                >
                                    <Ds.Icon
                                        name="Video"
                                        size="small"
                                        color="stone"
                                    />
                                    <Ds.Text weight="bold">
                                        Video consult
                                    </Ds.Text>
                                </PopoverItemLink>
                            )}
                            {showRecordViewAction && (
                                <PopoverItemLink
                                    to="/remote-record-view"
                                    onClick={handleClickRecordView}
                                >
                                    <Ds.Icon
                                        name="RecordPerson"
                                        size="small"
                                        color="stone"
                                    />
                                    <Ds.Text weight="bold">
                                        Request record
                                    </Ds.Text>
                                </PopoverItemLink>
                            )}
                        </PopoverContent>
                    </Popover>
                )}
            </ArchivedWorkspaceHiddenItemWrapper>
        </Ds.Flex>
    );
};

const StyledDivider = styled.div`
    background-color: ${Tokens.COLOURS.greyscale.silver};
    height: 1px;
    margin: ${Tokens.SIZES[2]} 0 ${Tokens.SIZES[1]};
`;
