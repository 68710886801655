import { useCurrentWorkspace } from "@accurx/auth";
import { usePatient } from "@accurx/concierge/hooks/data/usePatient";
import { usePatientRequestTime } from "@accurx/concierge/hooks/data/usePatientRequestTime";
import { useSetPatientRequestTimeMutation } from "@accurx/concierge/hooks/mutations/useSetPatientRequestTimeMutation";
import { useUpdatePatientDemographicsMutation } from "@accurx/concierge/hooks/mutations/useUpdatePatientDemographicsMutation";
import { PatientSummary } from "@accurx/concierge/types";
import { mapPatientDemographicsToPatientSummary } from "@accurx/concierge/util/mapPatientDemographicsToPatientSummary";
import {
    NativeTransportError,
    queryPatientDemographics,
    useMedicalRecordConnection,
    useNativeTransport,
} from "@accurx/native";
import { Log } from "@accurx/shared";
import { useQuery } from "@tanstack/react-query";
import { getPatientDemographicsQueryKey } from "domains/inbox/hooks/queries/usePatientDemographicsQuery";
import { checkIsPatientSummaryComplete } from "domains/inbox/util/checkIsPatientSummaryComplete";

const HOUR_IN_MS = 60 * 60 * 1000;

export const usePatientSummaryBackgroundFixer = (
    patientId: PatientSummary["patientId"] | null | undefined,
): { isPatientSummaryFixing: boolean } => {
    const patient = usePatient({
        patientId: patientId ?? null,
    });

    const patientWasFetched = usePatientRequestTime({
        patientId: patientId ?? null,
        type: "fetch",
    });

    const updatePatientDemographics = useUpdatePatientDemographicsMutation();
    const setPatientUpdateInfo = useSetPatientRequestTimeMutation();

    const transport = useNativeTransport();
    const workspaceId = useCurrentWorkspace().orgId;
    const isConnected = useMedicalRecordConnection().status === "Connected";

    const isPatientSummaryComplete = checkIsPatientSummaryComplete(patient);
    const patientNeedsUpdating = !!(
        patientId &&
        !!patient &&
        !patientWasFetched &&
        !isPatientSummaryComplete
    );
    const enabled = !!transport && isConnected && patientNeedsUpdating;

    const queryKey = getPatientDemographicsQueryKey(patient?.externalIds);

    const query = useQuery({
        queryKey,
        queryFn: async () => {
            if (!transport)
                throw new NativeTransportError(
                    "PatientSummaryBackgroundFixer.PatientDemographicsQuery: Called with no native transport",
                );

            if (!patient?.externalIds) {
                throw new Error(
                    "No patient external id provided to fetch patient dempgraphics",
                );
            }

            try {
                const { patientDemographics, serverPatientId } =
                    await queryPatientDemographics(
                        { transport, workspaceId },
                        {
                            patientExternalIdentityDto: {
                                patientExternalIds: patient.externalIds,
                            },
                            pushToServer: true,
                        },
                    );

                if (serverPatientId !== patientId) {
                    const message =
                        "Update received via QueryPatientDemographics contains serverPatientId that doesn't match patientId";
                    Log.warn(message);
                    throw new Error(message);
                }

                const updatedPatient = mapPatientDemographicsToPatientSummary({
                    patientSummary: patient,
                    patientDemographics: patientDemographics,
                });

                await updatePatientDemographics.mutateAsync(updatedPatient);

                return patientDemographics;
            } catch (e) {
                // we have to reject here as query with the same wuery key is used for reply (when we need to fetch contacts)
                return Promise.reject(e);
            } finally {
                // in any case we would like to mark that patiet demographics was fetched (even if it fails)
                // not to request it again within an hour
                setPatientUpdateInfo.mutate({
                    patientId: patient.patientId,
                    type: "fetch",
                    time: new Date().toISOString(),
                });

                setTimeout(() => {
                    setPatientUpdateInfo.mutate({
                        patientId: patient.patientId,
                        type: "fetch",
                        time: null,
                    });
                }, HOUR_IN_MS);
            }
        },
        enabled,
    });

    return { isPatientSummaryFixing: !!enabled && query.isFetching };
};
