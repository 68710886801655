import * as UI from "@accurx/design";
import styled from "styled-components";

const { COLOURS, SIZES } = UI.Tokens;

export const StyledPopoverContent = styled(UI.PopoverContent)`
    width: 196px;
    padding: 0 0 4px 0;
    border: 1px solid rgba(25, 28, 77, 0.1);
`;

export const StyledPopoverTitleText = styled(UI.Text).attrs({
    as: "span",
})`
    margin: 0;
    font-weight: 700;
    color: ${COLOURS.greyscale.night};
`;

export const StyledPopoverItem = styled(UI.PopoverItem)<{ active?: boolean }>`
    width: calc(100% - ${SIZES[2]});
    font-size: 14px;
    margin: ${SIZES[0.5]} ${SIZES[1]};
    padding: ${SIZES[1]};
    color: ${COLOURS.greyscale.zinc};
    &:focus {
        outline: none !important;
        box-shadow: 0 0 0 2px var(--ds-color-white),
            0 0 0 4px var(--ds-color-blue-100) !important;
    }

    ${({ active }) =>
        active &&
        `
        background-color: var(--ds-color-blue-100);
        border: 1px solid var(--ds-color-blue-100);
        color: ${COLOURS.greyscale.white};
    `};
`;

export const StyledPopoverTitle = styled(UI.PopoverItem)`
    background: ${COLOURS.greyscale.cloud};
    border-bottom: 1px solid ${COLOURS.greyscale.silver};
    margin: 0 0 ${SIZES[1]} 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
`;

export const StyledErrorContainer = styled.div`
    padding: ${UI.Tokens.SIZES[1]} 0 ${UI.Tokens.SIZES[4]} 0;
`;
