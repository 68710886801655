import React, { useState } from "react";

import * as UI from "@accurx/design";
import { AppointmentTypeValue } from "domains/self-book/utils/types";

import { CustomRadio } from "../../CustomRadio";
import { SubTitlePositioning } from "../SelfbookConfigurationForm.styles";
import { RadioTextWrapper } from "./AppointmentType.styles";

type AppointmentTypeOption = {
    id: string;
    label: string;
    value: AppointmentTypeValue;
};

const radioInputs: AppointmentTypeOption[] = [
    { id: "faceToFace", label: "Face-to-face", value: "FaceToFace" },
    { id: "telephone", label: "Telephone", value: "Telephone" },
];

type AppointmentTypeProps = {
    selectedAppointmentType: AppointmentTypeValue;
    onChange: React.ChangeEventHandler<HTMLInputElement>;
    errors?: string[];
};

export const AppointmentType = ({
    selectedAppointmentType,
    onChange,
    errors,
}: AppointmentTypeProps) => {
    const [selectedValue, setSelectedValue] = useState<string>(
        selectedAppointmentType,
    );

    const onAppointmentTypeChange = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setSelectedValue(event.target.value);
        onChange(event);
    };

    const isFaceToFace = selectedValue === "FaceToFace";

    return (
        <UI.Ds.Box py="0.75rem">
            <UI.Text variant="note">Appointment type</UI.Text>
            <UI.Grid
                columns={{ xs: "1fr 1fr", sm: "1fr", xl: "1fr 1fr" }}
                gap="0.25 1"
            >
                {radioInputs.map((item) => (
                    <UI.Cell key={item.value}>
                        <CustomRadio
                            name={"appointment-type"}
                            theme="bordered"
                            id={item.value}
                            label={
                                <RadioTextWrapper>
                                    <UI.Text skinny variant="preview">
                                        {item.label}
                                    </UI.Text>
                                </RadioTextWrapper>
                            }
                            value={item.value}
                            checkedValue={selectedValue}
                            onChange={onAppointmentTypeChange}
                        />
                    </UI.Cell>
                ))}
            </UI.Grid>
            <UI.Flex alignItems="flex-start">
                <UI.Icon name={isFaceToFace ? "Team" : "Phone"} size={3} />
                <SubTitlePositioning>
                    <UI.Text variant="preview" skinny>
                        {isFaceToFace
                            ? "Patient can select an exact appointment time."
                            : "Patient can select an AM or PM window for a phone call."}
                    </UI.Text>
                </SubTitlePositioning>
            </UI.Flex>
            {errors && (
                <UI.FormFieldFeedback
                    text={
                        <UI.Text as="span" skinny variant="preview">
                            {errors}
                        </UI.Text>
                    }
                    variant="error"
                />
            )}
        </UI.Ds.Box>
    );
};
