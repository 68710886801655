import {
    NativeFetchError,
    PatientDemographics,
    useNativeSubscription,
} from "@accurx/native";

import { QueryHook } from "./types";

export type UseCurrentPatientQuery = QueryHook<
    undefined,
    PatientDemographics | null,
    NativeFetchError
>;

export const useCurrentPatientQuery: UseCurrentPatientQuery = () => {
    return useNativeSubscription("SubscribeCurrentPatient");
};
