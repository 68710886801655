import React, { ReactNode } from "react";

import { ButtonLink, Ds } from "@accurx/design";
import { i } from "images";

import { ChainAnalyticsTracker } from "app/analytics";
import {
    PatientTriageSettingObjectName,
    PatientTriageSettingObjectType,
} from "app/analytics/ChainAnalytics";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";

import { WhatIsPatientTriageTableContainer } from "./WhatIsPatientTriage.styles";
import { WhatIsPatientTriageProps } from "./WhatIsPatientTriage.types";

const Heading = ({ children }: { children: ReactNode }) => (
    <Ds.Text size="large" weight="bold" as="h3" mt="1.5" mb="0.5">
        {children}
    </Ds.Text>
);

export const WhatIsPatientTriage = ({
    practiceLink,
}: WhatIsPatientTriageProps): JSX.Element => {
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    function track() {
        ChainAnalyticsTracker.trackPatientTriageCreateRequestButtonClick({
            ...analyticsLoggedInProps,
            objectName: PatientTriageSettingObjectName.CREATE_REQUEST,
            objectType: PatientTriageSettingObjectType.BUTTON,
        });
    }

    return (
        <>
            <Ds.Text className="mb-4">
                Patient Triage frees up your phone lines and saves you time with
                simple online access for your patients
            </Ds.Text>
            <ButtonLink
                onClick={track}
                href={practiceLink}
                target="_blank"
                rel="noopener noreferrer"
                dimension="large"
                theme="primary"
                text="Create a patient request to see how it works"
            />

            <WhatIsPatientTriageTableContainer>
                <div className="row align-items-start">
                    <div className="col">
                        <img
                            src={i("patientTriage/accessible.png")}
                            alt="Send request"
                        />
                        <Heading>Accessible</Heading>
                        <Ds.Text>
                            Easy to complete free-text forms, with no need to
                            register or download an app, enables all your
                            patients to request medical guidance online.
                        </Ds.Text>
                    </div>
                    <div className="col">
                        <img
                            src={i("patientTriage/simple.png")}
                            alt="Simple inbox"
                        />
                        <Heading>Simple</Heading>
                        <Ds.Text>
                            Short and simple patient requests can be quickly
                            reviewed and triaged, with the ability to assign to
                            your teams all within the Accurx toolbar.
                        </Ds.Text>
                    </div>
                    <div className="col">
                        <img
                            src={i("patientTriage/integrated.png")}
                            alt="Save to record"
                        />
                        <Heading>Integrated</Heading>
                        <Ds.Text>
                            Match and open the patient in the patient record
                            system, as well as save requests to the record with
                            the correct SNOMED code, in one-click, from the
                            inbox.
                        </Ds.Text>
                    </div>
                </div>
            </WhatIsPatientTriageTableContainer>
        </>
    );
};
