import {
    Fragment,
    KeyboardEvent,
    useCallback,
    useEffect,
    useState,
} from "react";

import { FeatureName, Organisation } from "@accurx/auth";
import { createRouterState as createRouterStatePatientForCompose } from "@accurx/compose";
import { Button, Card, Feedback, Icon, Text } from "@accurx/design";
import { useFullScreenNavigate } from "@accurx/navigation";
import {
    DateFormatOptions,
    DateHelpers,
    NhsNumberHelpers,
} from "@accurx/shared";
import { isArchivedWorkspace } from "@accurx/workspace-management";
import { shallowEqual, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { useMeaningfulActionAnalyticsProps } from "reduxQuarantine/useMeaningfulActionAnalyticsProps";

import FlemingApi from "api/FlemingApiClient";
import {
    PatientListAppointment,
    PatientListPatientDetail,
} from "api/FlemingDtos";
import { AnalyticsMapper, FlemingAnalyticsTracker } from "app/analytics";
import { actionCreators as messageTemplatesActionCreators } from "app/messageTemplates/MessageTemplatesActions";
import { useSelectedPatient } from "app/patients/hooks";
import { actionCreators as searchForPatientActions } from "app/searchForPatient/SearchForPatientActions";
import { ProductSelection } from "app/selectProduct/ProductSelection";
import { actionCreators as selectProductActionCreators } from "app/selectProduct/SelectProductActions";
import SendMessageForm from "app/sendMessage/sendMessageForm/SendMessageFormComponent";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { useNavigateToWorkspaceRoute } from "app/workspace/hooks";
import { OrganisationHelper } from "shared/OrganisationHelper";
import { PatientHelper } from "shared/PatientHelper";
import { ROUTES, ROUTES_EXTENSION } from "shared/Routes";
import { findBaseRoute } from "shared/RoutesHelper";
import { sentenceCase } from "shared/formatters/String";
import { useAppSelector } from "store/hooks";

import { getPatientListIsBulkAdding } from "../BulkUpload/PatientListBulkUpload.helper";
import { ConfirmDeleteAppointments } from "../ConfirmDelete/ConfirmDeleteAppointmentsModal";
import PatientEntryVideoStatus from "../PatientEntryVideoStatusComponent";
import { actionCreators as patientListActions } from "../PatientListsActions";
import {
    DROPDOWN_DEFAULT_OPTIONS,
    DROPDOWN_ITEMS,
    FeatureDropdownItem,
    isAnytimeAppointment,
    isScheduledAppointment,
    isScheduledAppointmentInPast,
    patientFeatureDisabledMessage,
} from "./PatientEntry.helper";
import {
    StyledContent,
    StyledEntryCardWrapper,
    StyledPatientDetails,
} from "./PatientEntryCard.styles";
import { UnusableButtonInDropdown } from "./UnusableButtonInDropdown";

const AnalyticsProductSelectLocation =
    FlemingAnalyticsTracker.ProductSelectLocation;

export interface PatientEntryCardProps {
    appointment: PatientListAppointment;
    patient: PatientListPatientDetail;
    isReadonly: boolean;
}

export const PatientEntryCard = ({
    patient,
    appointment,
    isReadonly,
}: PatientEntryCardProps): JSX.Element | null => {
    const dispatch = useDispatch();
    const history = useHistory();
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();
    const { navigateToWorkspaceRoute } = useNavigateToWorkspaceRoute();
    const fullScreenNavigate = useFullScreenNavigate();

    const commonProductSelectionAnalyticsProps: Omit<
        FlemingAnalyticsTracker.SelectProductAnalyticsProps,
        "Product"
    > = useAppSelector(() => {
        return {
            ...analyticsLoggedInProps,
            context: AnalyticsProductSelectLocation.PatientListDropdown,
            searchPatientOrigin: ROUTES.patient_lists,
        };
    }, shallowEqual);

    const analyticsPatientListProps = useAppSelector((state) => {
        const listId = state.patientLists.currentList?.patientListId;
        if (!listId) return undefined;
        return AnalyticsMapper.getPatientListAnalyticsProps(state, listId);
    });
    const meaningfulActionProps = useMeaningfulActionAnalyticsProps();

    const organisation = useAppSelector(
        ({ account }) => OrganisationHelper.getOrganisation(account),
        shallowEqual,
    );
    const isArchived = organisation
        ? isArchivedWorkspace(organisation as Organisation)
        : false;

    const patientResultFound = useAppSelector(
        ({ searchForPatient }) =>
            PatientHelper.getPatient(searchForPatient.lastResponse),
        shallowEqual,
    );
    const patientResultFoundToken = useAppSelector(({ searchForPatient }) =>
        PatientHelper.getPatientToken(searchForPatient.lastResponse),
    );
    const selectedPatient = useSelectedPatient();

    const currentPatientList = useAppSelector(
        ({ patientLists }) => patientLists.currentList,
    );

    const patientListId = currentPatientList?.patientListId;

    const isSearching = useAppSelector(
        ({ searchForPatient }) => searchForPatient.isSearching,
    );
    const currentListPatientsBulkAdding = useAppSelector(({ patientLists }) =>
        getPatientListIsBulkAdding(patientLists),
    );
    const isRemoving = useAppSelector(
        ({ patientLists }) => patientLists.currentListPatientRemoving,
    );
    const isBatchRemoving = useAppSelector(
        ({ patientLists }) => patientLists.currentListPatientsBatchRemoving,
    );
    const currentListPatientAdding = useAppSelector(
        ({ patientLists }) => patientLists.currentListPatientAdding,
    );
    const deletingListLoading = useAppSelector(
        ({ patientLists }) => patientLists.deletingListLoading,
    );

    const [isMoreOpen, setIsMoreOpen] = useState(false);
    const [isContactOpen, setIsContactOpen] = useState(false);
    const [isVideoConsultModalOpen, setVideoConsultModalOpen] = useState(false);

    const [isConfirmDeletePatientModalOpen, setConfirmDeletePatientModalOpen] =
        useState(false);
    const [scheduledVideoConsults, setScheduledVideoConsults] = useState<
        PatientListAppointment[]
    >([]);

    const shouldActionButtonsBeDisabled =
        isSearching ||
        isRemoving ||
        isBatchRemoving ||
        currentListPatientAdding ||
        currentListPatientsBulkAdding ||
        deletingListLoading;

    const handleSearchPatientFailure = useCallback(() => {
        if (isContactOpen && !isSearching && !patientResultFound) {
            setIsContactOpen(false);
            toast(
                Feedback({
                    colour: "error",
                    title: "Something went wrong",
                    content: "Searching for this patient failed",
                }),
            );
            const analyticsProps = {
                ...analyticsLoggedInProps,
                patientListType: currentPatientList?.listType,
                isNhsNumberSet: !!patient.nhsNumber,
            };
            FlemingAnalyticsTracker.trackPatientListSearchForPatientFailed(
                analyticsProps,
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isContactOpen, isSearching, patientResultFound]);

    useEffect((): void => {
        handleSearchPatientFailure();
    }, [handleSearchPatientFailure]);

    // Upon clicking on "Contact" button, we perform a search of the patient to get latest details
    // so we can show/hide dropdown items based on patient status (i.e. disable SMS for deceased patients)
    const performPatientSearch = (): void => {
        const dobParts = DateHelpers.getDateParts(
            patient.dateOfBirth as string,
        );
        if (!dobParts || !organisation || !patient.nhsNumber) return;

        if (patientListId) {
            // This will always be set when we are on a patient list
            dispatch(
                patientListActions.setPatientListEntrySearchedAction(
                    patientListId,
                    appointment,
                ),
            );
        }

        dispatch(
            searchForPatientActions.searchForPatient({
                nhsNumber: patient.nhsNumber,
                dateOfBirthYear: dobParts.y,
                dateOfBirthMonth: dobParts.m,
                dateOfBirthDay: dobParts.d,
                organisationId: organisation.orgId,
            }),
        );
    };

    const handleSelectProductClick = (product: ProductSelection): void => {
        const analyticsProps = {
            ...commonProductSelectionAnalyticsProps,
            Product: AnalyticsMapper.getAnalyticsProductSelected(product),
        };

        if (product !== ProductSelection.None) {
            FlemingAnalyticsTracker.trackProductSelected(analyticsProps);
        }

        switch (product) {
            case ProductSelection.Video:
                FlemingAnalyticsTracker.trackPatientListContactItemClicked({
                    selectedItem: "VideoInvite",
                    ...analyticsLoggedInProps,
                });
                dispatch(
                    messageTemplatesActionCreators.setTemplateLastActionLocation(
                        "product-modal",
                    ),
                );
                dispatch(selectProductActionCreators.selectVideoProductType());
                setVideoConsultModalOpen(true);

                break;

            case ProductSelection.Sms:
                FlemingAnalyticsTracker.trackPatientListContactItemClicked({
                    selectedItem: "PatientMessage",
                    ...analyticsLoggedInProps,
                });
                if (organisation?.orgId) {
                    fullScreenNavigate(
                        `/w/${organisation.orgId}/compose`,
                        createRouterStatePatientForCompose({
                            patient: patientResultFound,
                            patientToken: patientResultFoundToken,
                            appOrigin: "PatientList",
                        }),
                    );
                }

                break;

            case ProductSelection.RecordView:
                FlemingAnalyticsTracker.trackPatientListContactItemClicked({
                    selectedItem: "RecordView",
                    ...analyticsLoggedInProps,
                });
                dispatch(selectProductActionCreators.selectRecordViewType());
                history.push(
                    `${findBaseRoute(history.location.pathname)}${
                        ROUTES_EXTENSION.remoteRecordView
                    }`,
                    {
                        referredFrom: ROUTES.patient_lists,
                    },
                );

                break;

            case ProductSelection.MessagePractice:
                FlemingAnalyticsTracker.trackPatientListContactItemClicked({
                    selectedItem: "ClinicianMessage",
                    ...analyticsLoggedInProps,
                });
                if (organisation?.orgId) {
                    fullScreenNavigate(
                        `/w/${organisation.orgId}/compose/clinician-compose`,
                        createRouterStatePatientForCompose({
                            patient: patientResultFound,
                            patientToken: patientResultFoundToken,
                            appOrigin: "PatientList",
                        }),
                    );
                }

                break;

            // In case no product needs to be selected, go to the product page!
            case ProductSelection.None:
            default:
                FlemingAnalyticsTracker.trackPatientListContactItemClicked({
                    selectedItem: "PatientProfile",
                    ...analyticsLoggedInProps,
                });
                navigateToWorkspaceRoute(
                    findBaseRoute(history.location.pathname) +
                        ROUTES_EXTENSION.patientProfile,
                );

                break;
        }
    };

    const handleRemove = async (): Promise<void> => {
        if (isArchived) {
            return;
        }
        if (patientListId && organisation) {
            if (
                isAnytimeAppointment(appointment) ||
                isScheduledAppointmentInPast(appointment)
            ) {
                handleConfirmDeletePatient();
                return;
            }

            const { success, result } =
                await FlemingApi.checkVideoConsultCancellations({
                    organisationId: organisation.orgId,
                    patientListId,
                    patientListEntryIds: [appointment.id],
                });

            if (success === true && result !== null) {
                if (result.scheduledVideoConsults.length > 0) {
                    setScheduledVideoConsults(result.scheduledVideoConsults);
                    setConfirmDeletePatientModalOpen(true);
                } else {
                    handleConfirmDeletePatient();
                }
            } else {
                toast(
                    Feedback({
                        colour: "error",
                        title: "Sorry, something went wrong",
                        content:
                            "We were unable to remove this appointment. Please refresh the page and try again.",
                    }),
                );
            }
        }

        handleToggleMore();
    };

    const handleGoToVideoConsult = (): void => {
        // Since we open in a new tab, I still want to close the dropdown, for once the user comes back to the list tab
        handleContactToggle();

        window.open(appointment.videoConsultUrl);
    };

    //#region Dropdown handlers

    const handleToggleMore = (): void => {
        if (!shouldActionButtonsBeDisabled) {
            setIsMoreOpen((prevState) => !prevState);
        }
    };

    const handleToggleVideoConsultModal = useCallback((): void => {
        if (isVideoConsultModalOpen) {
            dispatch(selectProductActionCreators.resetProductType());
        }
        setVideoConsultModalOpen((prevState) => !prevState);
    }, [isVideoConsultModalOpen, dispatch]);

    // confirm delete actions
    const handleConfirmDeletePatient = useCallback(() => {
        const nhsNumber = selectedPatient?.nhsNumber || "";

        analyticsPatientListProps &&
            FlemingAnalyticsTracker.trackPatientListRemovePatientClicked({
                ...analyticsPatientListProps,
                ...meaningfulActionProps,
                isTestPatient: PatientHelper.isDemoPatient(nhsNumber),
            });
        if (patientListId && organisation) {
            dispatch(
                patientListActions.removePatientsFromList({
                    patientListId,
                    organisationId: organisation.orgId,
                    patientListEntries: [appointment],
                }),
            );
        }
        setConfirmDeletePatientModalOpen(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patientListId, organisation, dispatch, appointment]);

    const handleCancelDeletePatient = useCallback(() => {
        setConfirmDeletePatientModalOpen(false);
    }, []);
    // end confirm delete actions

    // TODO: investigate if we can change DropdownToggle to be Button component

    const getFeatureStatusForAnalytics = (feature: FeatureName): string => {
        const isEnabledForOrg = OrganisationHelper.isFeatureEnabled(
            organisation,
            feature,
        );

        const isEnabledForPatient =
            patientFeatureDisabledMessage(
                patientResultFound,
                feature,
                appointment,
            ) === null;
        if (isEnabledForOrg && isEnabledForPatient) {
            return "AvailableForUserAndPatient";
        }
        if (!isEnabledForOrg && !isEnabledForPatient) {
            return "NotAvailableForUserNorPatient";
        }

        return `NotAvailableFor${!isEnabledForPatient ? "Patient" : "User"}`;
    };

    const handleContactToggle = (): void => {
        if (!shouldActionButtonsBeDisabled) {
            if (!isSearching) {
                // Search for the patient right at the point of opening the contact dropdown
                if (!isContactOpen) {
                    performPatientSearch();
                    FlemingAnalyticsTracker.trackPatientListContactButtonClicked(
                        {
                            messagePracticeStatus: getFeatureStatusForAnalytics(
                                FeatureName.CaregiverInitiated,
                            ),
                            ...analyticsLoggedInProps,
                        },
                    );
                }
                setIsContactOpen((prevState) => !prevState);
            }
        }
    };

    const actionOnEnter =
        (action: () => void) =>
        (e: KeyboardEvent<HTMLButtonElement>): void => {
            if (e.key === "Enter") action();
        };

    const handleSelectProductKeyUp =
        (productSelection: ProductSelection) =>
        (e: KeyboardEvent<HTMLButtonElement>) =>
            actionOnEnter(() => handleSelectProductClick(productSelection))(e);

    //#endregion Dropdown handlers

    //#region Render
    const renderStandardDropdownOption = ({
        feature,
        icon,
        product,
        text,
        id,
    }: FeatureDropdownItem): JSX.Element | null => {
        if (
            feature &&
            !OrganisationHelper.isFeatureEnabled(organisation, feature)
        )
            return null;

        if (id === "Record" && isArchived) {
            return null;
        }

        if (id === "MessageGP" && isArchived) {
            return null;
        }

        if (id === "Sms" && isArchived) {
            return null;
        }

        const featureDisabledMessage = feature
            ? patientFeatureDisabledMessage(
                  patientResultFound,
                  feature,
                  appointment,
              )
            : null;
        return (
            <Fragment key={text}>
                {featureDisabledMessage === null ? (
                    <Button
                        theme="transparent"
                        icon={{ name: icon }}
                        text={text}
                        className="text-nowrap w-100"
                        onClick={(): void => handleSelectProductClick(product)}
                        onKeyUp={handleSelectProductKeyUp(product)}
                        disabled={shouldActionButtonsBeDisabled}
                        dimension="large"
                    />
                ) : (
                    <UnusableButtonInDropdown
                        text={text}
                        iconName={icon}
                        errorText={featureDisabledMessage}
                    />
                )}
            </Fragment>
        );
    };

    // Render VC related options: Join VC & Invite to VC
    const renderVideoConsultDropdownOptions = (): JSX.Element | null => {
        if (
            !OrganisationHelper.isFeatureEnabled(
                organisation,
                FeatureName.VideoConsultWeb,
            )
        ) {
            return null;
        }

        return (
            <>
                {!!appointment.videoConsultId && (
                    <Button
                        theme="transparent"
                        icon={{ name: "OpenWindow" }}
                        text={
                            appointment.dateTimeStart
                                ? DROPDOWN_ITEMS.goToScheduledVideoConsult
                                : DROPDOWN_ITEMS.goToVideoConsult
                        }
                        className="text-nowrap w-100"
                        onClick={handleGoToVideoConsult}
                        onKeyUp={actionOnEnter(handleGoToVideoConsult)}
                        disabled={shouldActionButtonsBeDisabled}
                        dimension="large"
                    />
                )}
                {!isArchived &&
                    renderStandardDropdownOption({
                        feature: FeatureName.VideoConsultWeb,
                        icon: "Video",
                        product: ProductSelection.Video,
                        text: appointment.dateTimeStart
                            ? DROPDOWN_ITEMS.scheduledVideoConsultInvite
                            : DROPDOWN_ITEMS.videoConsultInvite,
                    })}
            </>
        );
    };

    const renderPatientEntryCardHeader = (): JSX.Element | undefined => {
        if (isScheduledAppointment(appointment)) {
            return (
                <div className="d-flex align-items-center">
                    <span className="d-block mr-2">
                        <Icon name="Clock" size={4} />
                    </span>
                    <Text skinny variant="label">
                        {DateHelpers.formatTime(
                            appointment.dateTimeStart,
                            DateFormatOptions.TIME,
                        )}
                    </Text>
                </div>
            );
        }
    };

    return (
        <Card spacing={2} header={renderPatientEntryCardHeader()}>
            <>
                <StyledEntryCardWrapper>
                    <StyledContent>
                        <Text variant="label">
                            {patient.displayName || "Unknown name"}
                        </Text>
                        <PatientEntryVideoStatus
                            videoConsultId={appointment.videoConsultId}
                            videoConsultUrl={appointment.videoConsultUrl}
                            hasSentVideoConsultInvite={
                                appointment.hasSentVideoConsultInvite
                            }
                        />
                        <StyledPatientDetails>
                            <Text variant="body" skinny>
                                NHS no:{" "}
                                {patient.nhsNumber
                                    ? NhsNumberHelpers.formatNhsNumber(
                                          patient.nhsNumber,
                                      )
                                    : "Unknown"}
                            </Text>
                            <Text variant="body" skinny>
                                Born:{" "}
                                {DateHelpers.formatDate(
                                    patient.dateOfBirth as string,
                                    DateFormatOptions.NHS_MANUAL_DATE_FORMAT,
                                )}{" "}
                                {patient.ageDisplay && `${patient.ageDisplay}`}
                            </Text>
                            <Text variant="body" skinny>
                                Gender: {sentenceCase(patient.gender as string)}
                            </Text>
                        </StyledPatientDetails>
                    </StyledContent>
                    <div className="d-flex align-items-center">
                        {isReadonly === false && !isArchived && (
                            <Dropdown
                                isOpen={isMoreOpen}
                                toggle={handleToggleMore}
                                className="mr-3"
                                disabled={shouldActionButtonsBeDisabled}
                            >
                                <DropdownToggle
                                    tag="div"
                                    data-toggle="dropdown"
                                    aria-expanded={isMoreOpen}
                                >
                                    <Button
                                        aria-label="Patient options"
                                        icon={{
                                            name: "More",
                                            style: "Fill",
                                            title: "Patient options",
                                            id: "patient-options-btn",
                                        }}
                                        theme="secondary"
                                        disabled={shouldActionButtonsBeDisabled}
                                        onKeyUp={actionOnEnter(
                                            handleToggleMore,
                                        )}
                                    />
                                </DropdownToggle>
                                <DropdownMenu
                                    data-testid="options-dropdown-menu"
                                    right={true}
                                >
                                    <Button
                                        theme="transparent"
                                        icon={{ name: "Bin", colour: "red" }}
                                        text="Remove from list"
                                        className="text-nowrap w-100"
                                        onClick={handleRemove}
                                        onKeyUp={actionOnEnter(handleRemove)}
                                        data-testid="removePatientButton"
                                        disabled={shouldActionButtonsBeDisabled}
                                    />
                                </DropdownMenu>
                            </Dropdown>
                        )}
                        <Dropdown
                            isOpen={isContactOpen && !isSearching}
                            toggle={handleContactToggle}
                        >
                            <DropdownToggle
                                tag="div"
                                data-toggle="dropdown"
                                aria-expanded={isContactOpen}
                            >
                                <Button
                                    icon={{ name: "Chat", colour: "blue" }}
                                    theme="secondary"
                                    text="Contact"
                                    onKeyUp={actionOnEnter(handleContactToggle)}
                                    disabled={shouldActionButtonsBeDisabled}
                                />
                            </DropdownToggle>
                            <DropdownMenu
                                right={true}
                                className="p-1"
                                data-testid="contact-dropdown-menu"
                            >
                                {renderVideoConsultDropdownOptions()}
                                {DROPDOWN_DEFAULT_OPTIONS.map(
                                    renderStandardDropdownOption,
                                )}
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                    {isVideoConsultModalOpen && selectedPatient && (
                        <SendMessageForm
                            isModalOpen={isVideoConsultModalOpen}
                            handleToggleModal={handleToggleVideoConsultModal}
                            patientListId={patientListId}
                            appointment={appointment}
                            patient={selectedPatient}
                        />
                    )}
                    {currentPatientList && isConfirmDeletePatientModalOpen && (
                        <ConfirmDeleteAppointments
                            affectedDates={
                                appointment.dateTimeStart !== null
                                    ? [appointment.dateTimeStart]
                                    : null
                            }
                            listDetails={{
                                listName: currentPatientList.name,
                                isCurrentUserListOwner:
                                    currentPatientList.isCurrentUserListOwner,
                                listType: currentPatientList.listType,
                            }}
                            appointmentsWithVideoConsultsToDelete={
                                scheduledVideoConsults
                            }
                            patientName={patient.displayName || undefined}
                            handleConfirm={handleConfirmDeletePatient}
                            handleCancel={handleCancelDeletePatient}
                        />
                    )}
                </StyledEntryCardWrapper>
            </>
        </Card>
    );

    //#endregion Render
};
