import React from "react";

import { RegisterAccountProductType } from "@accurx/api/account";
import { useAccurxWebTitle } from "@accurx/navigation";
import { Log } from "@accurx/shared";
import { Redirect, useHistory, useLocation } from "react-router-dom";

import { LoginPageLayout } from "app/layout/LoginPageLayout/LoginPageLayout";
import { ROUTES } from "shared/Routes";
import { nextUrlMatchesRoute } from "shared/RoutesHelper";
import { useAppSelector } from "store/hooks";

import { ClinicianConversationLoginPage } from "./ClinicianConversationLoginPage";
import { LOGIN_LAYOUT_CONSTANTS } from "./LogIn.constants";
import { Login } from "./TrustsLogin/Login";
import { useGetProductTypeFromUrl } from "./hooks";

const POST_LOGIN_ROUTE = ROUTES.postlogin;

export const LoginComponent = () => {
    useAccurxWebTitle("Log in");

    const location = useLocation();
    const history = useHistory();
    const product = useGetProductTypeFromUrl();
    const queryParams = new URLSearchParams(location.search);

    /**
     * If the user is arriving from a Desktop source (product=1) then redirect them back to the desktop once they're finished logging in.
     *
     * RegisterAccountProductType here feels weird, but that's what useGetProductTypeFromUrl uses and that's the only type it returns.
     */
    if (
        queryParams.has("product") &&
        product === RegisterAccountProductType.Chain &&
        !queryParams.has("next")
    ) {
        queryParams.set("next", ROUTES.desktopLaunch);
        queryParams.set("loginMethod", "Email");
        history.replace({
            pathname: location.pathname,
            search: queryParams.toString(),
        });
    }

    /**
     * Determines the next route that the user should hit after logging in either
     * by NHS.NET SSO or manual log in.
     * We always redirect to the postlogin page, preserving any query parameters
     * such as the ?next parameter. PostLoginRedirectComponent then owns figuring
     * out where to next redirect the user
     * */
    const nextRouteAfterLogin = {
        route: POST_LOGIN_ROUTE,
        search: queryParams.toString() || "",
    };

    const isLoggedIn = useAppSelector(({ account }) => account.isLoggedIn);

    // isLoggedIn is a ternary value
    // - null / We don't know if the user is logged in
    // - true / We know the user is logged in /
    // - false / We know the user is not logged in
    if (isLoggedIn === null) {
        Log.error(
            "Login expects non-null isLoggedIn prop. Login component should only be used once Home component has determined whether user is logged in or not.",
        );
        return <Redirect to={{ ...location, pathname: ROUTES.home }} />;
    }

    if (isLoggedIn) {
        const { route, search } = nextRouteAfterLogin;
        return (
            <Redirect
                to={{
                    ...location,
                    pathname: route,
                    search,
                }}
            />
        );
    }

    if (nextUrlMatchesRoute(location.search, ROUTES.reply_from_web)) {
        return (
            <LoginPageLayout {...LOGIN_LAYOUT_CONSTANTS.clinicianConversation}>
                <ClinicianConversationLoginPage />
            </LoginPageLayout>
        );
    }

    // Default login page for trusts
    return <Login />;
};
