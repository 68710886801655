import { useCurrentWorkspace } from "@accurx/auth";
import { Redirect, Route, Switch, generatePath } from "react-router";

import {
    AppointmentChangesPage,
    CreateReminderPage,
    EditReminderPage,
    ManageRemindersPage,
    SentRemindersPage,
} from "./pages";

const basePathForAppointmentReminders =
    "/w/:workspaceId/settings/appointment-reminders";

const createPagePath = `${basePathForAppointmentReminders}/create`;
const editPagePath = `${basePathForAppointmentReminders}/manage/:reminderId`;
const managePagePath = `${basePathForAppointmentReminders}/manage`;
const sentPagePath = `${basePathForAppointmentReminders}/sent`;
const changesPagePath = `${basePathForAppointmentReminders}/changes`;

export function AppointmentRemindersRoutes() {
    const workspace = useCurrentWorkspace();
    const { orgId: workspaceId } = workspace;

    return (
        <Switch>
            <Route path={createPagePath} exact>
                <CreateReminderPage />
            </Route>
            <Route path={editPagePath} exact>
                <EditReminderPage />
            </Route>
            <Route path={basePathForAppointmentReminders} exact>
                <Redirect to={generatePath(managePagePath, { workspaceId })} />
            </Route>
            <Route path={managePagePath} exact>
                <ManageRemindersPage />
            </Route>
            <Route path={sentPagePath} exact>
                <SentRemindersPage />
            </Route>
            <Route path={changesPagePath} exact>
                <AppointmentChangesPage />
            </Route>
            <Route
                exact
                path={`${basePathForAppointmentReminders}/:reminderId`}
                render={({ match }) => (
                    <Redirect
                        to={generatePath(editPagePath, {
                            workspaceId,
                            reminderId: match.params.reminderId,
                        })}
                    />
                )}
            />
        </Switch>
    );
}

export const getManagePagePath = (workspaceId: number | string) => {
    return generatePath(managePagePath, { workspaceId });
};

export const getCreatePagePath = (workspaceId: number | string) => {
    return generatePath(createPagePath, { workspaceId });
};

export const getEditPagePath = (
    workspaceId: number | string,
    reminderId: number,
) => {
    return generatePath(editPagePath, { workspaceId, reminderId });
};
