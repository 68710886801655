import { AppointmentChangeDeadline } from "@accurx/api/appointment";

const appointmentChangeDeadlineTextMapping: Record<
    AppointmentChangeDeadline,
    string
> = {
    OneHour: "1 hour",
    OneWorkingDay: "1 working day",
    ThreeWorkingDays: "3 working days",
};

export const getSupportedAmendMessage = (
    changeDeadline: AppointmentChangeDeadline,
) => {
    const amendDeadline = appointmentChangeDeadlineTextMapping[changeDeadline];

    return `Patients will be able to amend the appointment up to ${amendDeadline} before the appointment is due.`;
};
