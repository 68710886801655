import { Ds, Tokens } from "@accurx/design";
import styled from "styled-components";

type FilterWithSingleItemProps = {
    label: string;
    sublabel?: string;
    value: string;
};

export function FilterWithSingleItem({
    label,
    sublabel,
    value,
}: FilterWithSingleItemProps) {
    return (
        <>
            <Ds.Text weight="bold">{label}</Ds.Text>
            {sublabel && <Ds.Text>{sublabel}</Ds.Text>}
            <FakeDisabledSelect>
                <Ds.Badge color="greyscale">{value}</Ds.Badge>
            </FakeDisabledSelect>
        </>
    );
}

const FakeDisabledSelect = styled.div`
    width: 100%;
    padding: ${Tokens.SIZES[1.5]};
    background-color: ${Tokens.COLOURS.greyscale.cloud};
    border-radius: ${Tokens.BORDERS.radius};
`;
