import { SortDirection } from "@accurx/design";
import { Log } from "@accurx/shared";

import { storageAvailable } from "shared/storage/featureDetection";

import { AssignmentData } from "./shared.types";

const PINNED_ITEMS_STORAGE_KEY = "live_dashboard__pinned_items";

const ASCENDING = "asc";
const DESCENDING = "desc";

export const COLUMN_SORT_DIRECTION: Record<
    string,
    { original: SortDirection[]; reverse: SortDirection[] }
> = {
    name: {
        original: [DESCENDING, ASCENDING],
        reverse: [DESCENDING, DESCENDING],
    },
    totalCurrentAssigned: {
        original: [DESCENDING, DESCENDING, DESCENDING],
        reverse: [DESCENDING, ASCENDING, ASCENDING],
    },
    totalTodayAssigned: {
        original: [DESCENDING, DESCENDING, DESCENDING],
        reverse: [DESCENDING, ASCENDING, ASCENDING],
    },
    totalTodayDone: {
        original: [DESCENDING, DESCENDING],
        reverse: [DESCENDING, ASCENDING],
    },
};

export const getItemId = (item: AssignmentData, workspaceId: string): string =>
    `${item.id}-${item.type}-${workspaceId}`;

export const storePinnedItems = (items: string[]): void => {
    if (!storageAvailable("localStorage")) return;
    localStorage.setItem(PINNED_ITEMS_STORAGE_KEY, JSON.stringify(items));
};

export const getPinnedItems = (): string[] => {
    if (!storageAvailable("localStorage")) return [];
    const serializedItems = localStorage.getItem(PINNED_ITEMS_STORAGE_KEY);
    try {
        const items: string[] = JSON.parse(serializedItems ?? "[]");
        return items;
    } catch (error) {
        Log.error("LiveAssignmentDashboard.utils.getPinnedItems", error);
        return [];
    }
};
