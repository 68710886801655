import { Ds } from "@accurx/design";
import styled from "styled-components";

export const StyledDropdownMenuContent = styled(Ds.DropdownMenu.Content)`
    max-height: 500px;
    width: 248px;
`;

export const StyledDropdownMenuItem = styled(Ds.DropdownMenu.Item)`
    display: flex;
    gap: 0.5rem;
    padding: 0.75rem;

    &:hover {
        text-decoration: none;
    }
`;
