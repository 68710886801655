import React from "react";

import { useAccurxWebTitle } from "@accurx/navigation";

import { AccountPageLayout } from "./AccountPageLayout";
import { SettingsSecurity } from "./components/SettingsSecurity";

const Security = () => {
    useAccurxWebTitle("Update security settings");
    return (
        <AccountPageLayout title={"Security"}>
            <SettingsSecurity hideHeader isChain={false} />
        </AccountPageLayout>
    );
};
export { Security };
