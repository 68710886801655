import { useEffect } from "react";

import { Button, Feedback, Text } from "@accurx/design";
import { useHistory } from "react-router";
import { generatePath } from "react-router-dom";

import { trackManageTemplatePageView } from "app/analytics/FlemingAnalytics";
import { useDeleteAccumailTemplateMutation } from "app/hooks/mutations";
import { useAccumailTemplatesQuery } from "app/hooks/queries";
import { Spinner } from "app/organisations/shared";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { ROUTES_WORKSPACE } from "shared/Routes";

import { AccumailTemplateList } from "../../templateList/AccumailTemplateList";
import { IntroductionContainer } from "../ManageAccumailTemplates.styles";
import { templateTypeToCreateParam } from "../ManageAccumailTemplates.tabs";

export const ManageWorkspaceTemplates = ({
    workspaceId,
    orgName,
}: {
    workspaceId: number;
    orgName: string;
}) => {
    const { isLoading, isError, data } = useAccumailTemplatesQuery({
        workspaceId: workspaceId,
        templateType: "WorkspaceTemplate",
    });

    const history = useHistory();
    const flemingLoggedInProperties = useFlemingLoggedInAnalytics();
    useEffect(() => {
        trackManageTemplatePageView({
            ...flemingLoggedInProperties,
            countTemplateCategories: 0, // Accumail templates don't have categories
            currentTab: "OrganisationTemplates",
            productOrigin: "ClinicianMessage",
        });
    }, [flemingLoggedInProperties]);

    const { mutate: deleteTemplate, isError: deleteError } =
        useDeleteAccumailTemplateMutation();

    const handleClickCreateNewTemplate = () => {
        history.push(
            generatePath(ROUTES_WORKSPACE.accumailTemplatesCreate, {
                workspaceId,
                type: templateTypeToCreateParam("WorkspaceTemplate"),
            }),
        );
    };

    return (
        <>
            <IntroductionContainer>
                <Text variant="subtitle" as="h2">
                    {orgName} Templates
                </Text>
                <Text>
                    These are templates created by users from your organisation.
                    When creating a new template, a greeting (e.g. Dear Miss
                    Flynn) and a signature (e.g. {orgName}) will be included
                    automatically, so you won't need to add these.
                </Text>
                <Button
                    text="Create New Template"
                    onClick={handleClickCreateNewTemplate}
                />
            </IntroductionContainer>
            {isLoading && <Spinner />}
            {(isError || deleteError) && (
                <Feedback
                    title={
                        deleteError
                            ? "There was a problem deleting the template"
                            : "Unable to fetch templates"
                    }
                    colour="error"
                >
                    Please refresh the page to try again.
                </Feedback>
            )}
            {!isLoading && !(isError || deleteError) && (
                <AccumailTemplateList
                    tableTitle="Organisation Templates"
                    data={data}
                    hasActions={true}
                    onDelete={(templateId) => {
                        deleteTemplate({
                            workspaceId,
                            templateId,
                            templateType: "WorkspaceTemplate",
                        });
                    }}
                />
            )}
        </>
    );
};
