import { ChangeEvent, FormEvent, forwardRef, useEffect, useState } from "react";

import { Ds, Input } from "@accurx/design";
import { StyledFormFieldWrapper } from "@accurx/navigation";
import { useEmrPatientSearch } from "domains/patient/hooks/mutations/useEmrPatientSearch";
import { PatientSearchResult } from "domains/patient/types";

import {
    EmrNameSearchState,
    FIELDS_ID,
    FORM_ACTION,
    FORM_ID,
    FORM_LABELS,
    FormAction,
    INIT_STATE,
    TEST_ID,
} from "../PatientSearchForm.helpers";
import {
    EmrNameSearchError,
    EmrNameSearchSchema,
    formatErrors,
} from "../PatientSearchFormSchema";

export type EmrNameSearchProps = {
    formData: EmrNameSearchState;
    formAction: React.Dispatch<FormAction<EmrNameSearchState>>;
    onSubmit: (data: PatientSearchResult[]) => void;
    onError: (error: Error) => void;
    onValidationError: (errorReason: string[]) => void;
    onClear?: () => void;
    isFormDisabled?: boolean;
    onLoading?: (isLoading: boolean) => void;
    children: React.ReactChild;
};

export const EmrNameSearch = forwardRef<HTMLFormElement, EmrNameSearchProps>(
    (
        {
            formData,
            formAction,
            onSubmit,
            onError,
            onValidationError,
            onClear,
            onLoading,
            isFormDisabled,
            children,
        },
        forwardedRef,
    ) => {
        const { mutate, isLoading } = useEmrPatientSearch({
            onError: (error) => onError(error),
            onSuccess: (data) => onSubmit(data),
        });
        const [fieldErrors, setFieldErrors] =
            useState<EmrNameSearchError | null>(null);

        const isDirty = formData.firstName || formData.lastName;

        useEffect(() => {
            onLoading?.(isLoading);
        }, [isLoading, onLoading]);

        const handleFirstNameChange = (
            e: ChangeEvent<HTMLInputElement>,
        ): void => {
            formAction({
                type: FORM_ACTION.UPDATE,
                payload: { firstName: e.target.value },
            });
            checkFormIsCleared({
                ...formData,
                firstName: e.target.value,
            });
        };

        const handleLastNameChange = (
            e: ChangeEvent<HTMLInputElement>,
        ): void => {
            formAction({
                type: FORM_ACTION.UPDATE,
                payload: { lastName: e.target.value },
            });
            checkFormIsCleared({
                ...formData,
                lastName: e.target.value,
            });
        };

        const checkFormIsCleared = (formData: EmrNameSearchState) => {
            if (
                JSON.stringify(formData) === JSON.stringify(INIT_STATE.EMR_NAME)
            ) {
                onClear?.();
            }
        };

        const handleClear = () => {
            setFieldErrors(null);
            formAction({
                type: FORM_ACTION.UPDATE,
                payload: INIT_STATE.EMR_NAME,
            });
            onClear?.();
        };

        const handleSubmit = (e: FormEvent) => {
            e.preventDefault();

            const result = EmrNameSearchSchema.safeParse(formData);
            if (result.success) {
                setFieldErrors(null);
                mutate(formData);
            } else {
                const submitErrors = formatErrors<EmrNameSearchError>(
                    result.error,
                );
                setFieldErrors(submitErrors);
                onValidationError(
                    [submitErrors.firstName, submitErrors.lastName].filter(
                        (errorReason) => errorReason !== undefined,
                    ) as string[], // Despite filtering TS still consider this to be Array<string | undefined>
                );
            }
        };

        return (
            <form
                ref={forwardedRef}
                onSubmit={handleSubmit}
                id={FORM_ID.EMR}
                data-testid={TEST_ID.EMR}
                noValidate
            >
                <Ds.Flex gap="1.5" flexDirection="column">
                    <Ds.Flex gap="1">
                        <StyledFormFieldWrapper
                            label={FORM_LABELS.firstName}
                            labelProps={{
                                htmlFor: `${FORM_ID.EMR}-input-${FIELDS_ID.FIRST_NAME}`,
                            }}
                            errors={[fieldErrors?.firstName ?? null]}
                        >
                            <Input
                                autoFocus
                                id={`${FORM_ID.EMR}-input-${FIELDS_ID.FIRST_NAME}`}
                                value={formData.firstName}
                                onChange={handleFirstNameChange}
                                disabled={isFormDisabled}
                                data-userflow-id="patient-search-first-name-input"
                                hasErrors={Boolean(fieldErrors?.firstName)}
                            />
                        </StyledFormFieldWrapper>
                        <StyledFormFieldWrapper
                            label={FORM_LABELS.lastName}
                            labelProps={{
                                htmlFor: `${FORM_ID.EMR}-input-${FIELDS_ID.LAST_NAME}`,
                            }}
                            errors={[fieldErrors?.lastName ?? null]}
                        >
                            <Input
                                id={`${FORM_ID.EMR}-input-${FIELDS_ID.LAST_NAME}`}
                                value={formData.lastName}
                                onChange={handleLastNameChange}
                                disabled={isFormDisabled}
                                data-userflow-id="patient-search-last-name-input"
                                hasErrors={Boolean(fieldErrors?.lastName)}
                            />
                        </StyledFormFieldWrapper>
                    </Ds.Flex>
                    <Ds.Flex gap="1" justifyContent="space-between">
                        {children}
                        <Ds.Flex justifyContent="flex-end">
                            <Ds.Flex gap="1">
                                {isDirty && (
                                    <Ds.Button
                                        type="button"
                                        appearance="tertiary"
                                        onClick={handleClear}
                                    >
                                        Clear
                                    </Ds.Button>
                                )}
                                <Ds.Button
                                    type="submit"
                                    disabled={isFormDisabled}
                                    aria-label="Search for a patient"
                                >
                                    <Ds.Button.Icon name="Search" />
                                </Ds.Button>
                            </Ds.Flex>
                        </Ds.Flex>
                    </Ds.Flex>
                </Ds.Flex>
            </form>
        );
    },
);
