import { useCurrentWorkspace } from "@accurx/auth";
import { usePatient } from "@accurx/concierge/hooks/data/usePatient";
import { PatientSummary } from "@accurx/concierge/types";
import {
    NativeTransportError,
    mutateSyncPatientDemographics,
    useNativeTransport,
} from "@accurx/native";
import { useQuery } from "@tanstack/react-query";
import { checkIsPatientSummaryComplete } from "domains/inbox/util/checkIsPatientSummaryComplete";

const HOURS_IN_MS = 60 * 60 * 1000;
const WEEK_IN_MS = 7 * 24 * HOURS_IN_MS;

const isDemographicsStale = (patient?: PatientSummary): boolean => {
    if (!patient) {
        return false; // no patient - no stale data ¯\_(ツ)_/¯
    }

    if (!patient.demographicsLastRefreshed) {
        // if patient summary doesn't have demographicsLastRefreshed,
        // "QueryPatientDemographics" is called (as data are not fulfilled)
        return false;
    }
    return (
        Date.now() - new Date(patient.demographicsLastRefreshed).getTime() >
        WEEK_IN_MS
    );
};

export const useStalePatientSummaryBackgroundFixer = (
    patientId: PatientSummary["patientId"] | null,
): void => {
    const patient = usePatient({
        patientId: patientId,
    });

    const transport = useNativeTransport();
    const workspaceId = useCurrentWorkspace().orgId;

    const enabled =
        !!transport &&
        isDemographicsStale(patient) &&
        checkIsPatientSummaryComplete(patient);

    useQuery({
        queryKey: [
            "SyncPatientDemographics",
            {
                patientId,
            },
        ],
        queryFn: async () => {
            if (!transport)
                throw new NativeTransportError(
                    "StalePatientSummaryBackgroundFixer.SyncPatientDemographicsQuery: Called with no native transport",
                );

            if (!patient?.externalIds) {
                throw new Error(
                    "No patient external id provided to update patient dempgraphics",
                );
            }

            await mutateSyncPatientDemographics(
                { transport, workspaceId },
                {
                    patientExternalIdentityDto: {
                        patientExternalIds: patient.externalIds,
                    },
                },
            );
            // syncPatientDemographics doesn't return any data,
            // but react query could not return undefined
            return { success: true };
        },
        enabled,
        // we want to rerequest demographics update (if needed)
        // only after an hour
        staleTime: HOURS_IN_MS,
        cacheTime: HOURS_IN_MS,
    });
};
