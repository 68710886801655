import { createRouterState } from "@accurx/compose";
import { Button, Card, Icon, Text } from "@accurx/design";
import {
    useFullScreenNavigate,
    usePracticeUserAvailablityQuery,
} from "@accurx/navigation";
import { ArchivedWorkspaceHiddenItemWrapper } from "@accurx/workspace-management";
import { useHistory } from "react-router-dom";

import { FlemingAnalyticsTracker } from "app/analytics";
import { PracticeOnlineStatus } from "app/clinicianconversation/practiceAvailabilityBadge/PracticeOnlineStatusComponent";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { StackPanel } from "app/sharedComponents/StackPanel";
import { SkeletonLabel } from "app/sharedComponents/loadingSkeleton/SkeletonText";
import { OrganisationHelper } from "shared/OrganisationHelper";
import { findBaseRoute, getMessagePracticeUrl } from "shared/RoutesHelper";
import { useAppSelector } from "store/hooks";

import { StyledButtonWrapper } from "./GpCard.styles";
import { isTestPatient } from "./isTestPatient";
import {
    StyledCardColumn,
    StyledContainer,
    StyledFlex,
    StyledSectionHeader,
} from "./tabPanels/OverviewPanel.styles";
import { useMessagePracticeAvailabilityWithPatientData } from "./useMessagePracticeAvailabilityWithPatientData";

const GpCard = (): JSX.Element => {
    const history = useHistory();

    const { canMessageGp, patient } =
        useMessagePracticeAvailabilityWithPatientData();

    const loggedInProps = useFlemingLoggedInAnalytics();
    const searchPatientOrigin = useAppSelector(
        ({ selectProduct }) => selectProduct.searchPatientOrigin,
    );

    const organisation = useAppSelector(({ account }) =>
        OrganisationHelper.getOrganisation(account),
    );

    const lastResponse = useAppSelector(
        (state) => state.searchForPatient.lastResponse,
    );
    const testPatient = isTestPatient(patient);

    const orgId = organisation?.orgId;

    const fullScreenNavigate = useFullScreenNavigate();

    const allowMessageGp = canMessageGp;

    const redirectToMessageGp = () => {
        const analyticsProps = {
            ...loggedInProps,
            navigationLocation: "Overview" as const,
            optionSelected: "MessagePractice" as const,
            productAvailableForPatient: true, // If this is enabled the product is available
            searchPatientOrigin,
        };

        analyticsProps &&
            FlemingAnalyticsTracker.trackProductOptionButtonClick(
                analyticsProps,
            );

        if (orgId) {
            fullScreenNavigate(
                `/w/${orgId}/compose/clinician-compose`,
                createRouterState({
                    patient: patient ?? null,
                    patientToken:
                        lastResponse?.searchedResult?.patientToken ?? null,
                    appOrigin: "PatientProfile",
                }),
            );
        } else {
            history.push(
                `${findBaseRoute(
                    history.location.pathname,
                )}${getMessagePracticeUrl(organisation)}${
                    testPatient ? "-test" : ""
                }`,
                {
                    appOrigin: "MessagePractice",
                },
            );
        }
    };

    const getHeader = () => {
        if (!patient) {
            return <SkeletonLabel charCount={20} />;
        }

        return (
            (patient?.firstName ? patient.firstName + "'s " : "") +
            "GP practice"
        );
    };

    const getPracticeName = () => {
        if (!patient) {
            return <SkeletonLabel charCount={20} />;
        }

        if (patient?.practiceName) {
            return patient.practiceName;
        }

        if (patient?.practiceCode) {
            return `Practice with ODS code ${patient.practiceCode}`;
        }

        if (testPatient) {
            return "Test GP Practice";
        }

        return "Practice name unknown";
    };

    const availablityQuery = usePracticeUserAvailablityQuery({
        organisationId: orgId,
        practiceCodeToQuery: patient?.practiceCode ?? undefined,
    });

    return (
        <StackPanel gutter={2}>
            <StyledFlex>
                <Icon name="Doctor" size={4} halo={{ colour: "blue" }} />
                <StyledSectionHeader forwardedAs="h5" variant="label">
                    {getHeader()}
                </StyledSectionHeader>
            </StyledFlex>
            <Card isElevated spacing={2} variant="regular">
                <StackPanel>
                    <StyledContainer>
                        <StyledCardColumn>
                            <Text variant="label" skinny>
                                {getPracticeName()}
                            </Text>
                            {patient?.practiceAddress && (
                                <Text variant="body">
                                    {patient?.practiceAddress}
                                </Text>
                            )}
                            {patient?.practicePhoneNumber && (
                                <Text variant="body">
                                    {patient?.practicePhoneNumber}
                                </Text>
                            )}
                        </StyledCardColumn>
                        {allowMessageGp && testPatient && (
                            <PracticeOnlineStatus
                                status="success"
                                data={{
                                    isAvailable: true,
                                    activity: {
                                        lastOnlineDaysAgo: 1,
                                        numberOfUsers: 3,
                                    },
                                }}
                            />
                        )}
                        {allowMessageGp && !testPatient && (
                            <PracticeOnlineStatus {...availablityQuery} />
                        )}
                    </StyledContainer>
                    <ArchivedWorkspaceHiddenItemWrapper>
                        <StyledButtonWrapper>
                            {allowMessageGp && (
                                <Button
                                    data-userflow-id="message-gp-patient-profile-gp-card"
                                    theme="secondary"
                                    text="Message GP practice"
                                    icon={{
                                        colour: "blue",
                                        name: "Mention",
                                    }}
                                    onClick={redirectToMessageGp}
                                />
                            )}
                        </StyledButtonWrapper>
                    </ArchivedWorkspaceHiddenItemWrapper>
                </StackPanel>
            </Card>
        </StackPanel>
    );
};

export { GpCard };
