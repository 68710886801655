import { useCurrentWorkspace } from "@accurx/auth";
import {
    NativeTransportError,
    queryPatientDemographics,
    useNativeTransport,
} from "@accurx/native";
import { mapDemographicsToCurrentPatient } from "domains/compose/utils/mapDemographicsToCurrentPatient";

import { CurrentPatient } from "../../context/CurrentPatientProvider.types";

export const useRefreshPatientDemographics = () => {
    const transport = useNativeTransport();
    const workspaceId = useCurrentWorkspace().orgId;

    return async (currentPatient: CurrentPatient) => {
        if (!transport) {
            throw new NativeTransportError(
                "useRefreshPatientDemographics: Called with no native transport",
            );
        }

        const { patientDemographics } = await queryPatientDemographics(
            { transport, workspaceId },
            {
                patientExternalIdentityDto: {
                    patientExternalIds: currentPatient.patient.externalIds,
                },
                pushToServer: false,
            },
        );
        return mapDemographicsToCurrentPatient(patientDemographics);
    };
};
