import { useEffect } from "react";

import { useCurrentWorkspace } from "@accurx/auth";
import { Feedback } from "@accurx/design";
import { useAccurxWebTitle } from "@accurx/navigation";
import { ArchivedWorkspaceHiddenItemWrapper } from "@accurx/workspace-management";
import { useDispatch } from "react-redux";
import { generatePath, useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import {
    resetConfirmMessageSuccess,
    resetIsBatchScheduled,
} from "app/batchMessage/gp/BatchMessage.actions";
import { BatchMessageStatuses } from "app/batchMessage/gp/components/overview/BatchMessageStatuses";
import { StatusesLabelsContext } from "app/batchMessage/gp/components/overview/BatchMessageStatusesContext";
import { StyledContainer } from "app/layout/Container";
import { PageHeader, PageHeaderType } from "app/layout/PageHeader";
import { StyledSendBatchButton } from "app/organisations/allMessages/AllMessagesPage.styles";
import { ROUTES_WORKSPACE } from "shared/Routes";
import { useAppSelector } from "store/hooks";

const statusLabels = ["Scheduled", "Sending", "Done", "Cancelled"];

export const BatchMessageOverviewPage = (): JSX.Element => {
    useAccurxWebTitle("Batch messages");

    const history = useHistory();
    const dispatch = useDispatch();
    const { orgId, latestIntegratedSystem } = useCurrentWorkspace();

    // Store
    const confirmMessageSuccess = useAppSelector(
        ({ batchMessage }) => batchMessage.confirmMessageSuccess,
    );
    const isBatchScheduled = useAppSelector(
        ({ batchMessage }) => batchMessage.isBatchScheduled,
    );

    useEffect(() => {
        if (confirmMessageSuccess) {
            toast(
                Feedback({
                    colour: "success",
                    title: isBatchScheduled ? "Message saved" : "Message sent",
                }),
            );
            dispatch(resetConfirmMessageSuccess());
            dispatch(resetIsBatchScheduled());
        }
    }, [dispatch, confirmMessageSuccess, isBatchScheduled]);

    const handleNavigateToSendBatch = () => {
        history.push(
            generatePath(
                latestIntegratedSystem
                    ? ROUTES_WORKSPACE.batchMessageGpChoose
                    : ROUTES_WORKSPACE.batchMessageTrustUpload,
                { workspaceId: String(orgId) },
            ),
            { from: history.location.pathname },
        );
    };

    return (
        <StyledContainer>
            <PageHeader type={PageHeaderType.ListPage} title="Batch messages" />
            <ArchivedWorkspaceHiddenItemWrapper>
                <StyledSendBatchButton
                    onClick={handleNavigateToSendBatch}
                    icon={{
                        name: "Arrow",
                        colour: "white",
                        placement: "end",
                        rotation: "right",
                    }}
                    text="Send a batch message"
                />
            </ArchivedWorkspaceHiddenItemWrapper>
            <StatusesLabelsContext.Provider value={statusLabels}>
                <BatchMessageStatuses
                    workspaceId={(orgId as number).toString()}
                    pageOrigin="AccurxWeb"
                />
            </StatusesLabelsContext.Provider>
        </StyledContainer>
    );
};
