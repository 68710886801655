import { useMemo, useState } from "react";

import { QuestionnaireTemplate } from "@accurx/message-templates";
import { QuickViewPortal } from "@accurx/quick-view";
import { useCompose } from "domains/message-component/context";
import { useIsComposing } from "domains/message-component/hooks/useIsComposing";
import {
    PatientExternalId,
    TemplateItem,
} from "domains/message-component/types";

import { useValidatePendingQuestionnaire } from "../../hooks/useValidatePendingQuestionnaire";
import { useTemplatesAnalytics } from "../../useTemplatesAnalytics";
import { AlreadyComposingModal } from "../AlreadyComposingModal";
import { MessageTemplateSelector } from "../MessageTemplateSelector/MessageTemplateSelector";
import { RemoveTemplateButton } from "../RemoveTemplateButton/RemoveTemplateButton";
import { DisabledCombobox } from "../TemplatesCombobox/DisabledCombobox";
import { TemplatesCombobox } from "../TemplatesCombobox/TemplatesCombobox";
import { sortTemplates } from "../TemplatesCombobox/sortTemplates";

type TemplatesBrowserProps = {
    templates: TemplateItem[];
    patientExternalIds: PatientExternalId[];
    disabled?: boolean;
    /** When provided, a link will appear at the bottom of the preview quick view */
    manageTemplatesUrl?: string;
    onQuestionnaireSelected?: (template: QuestionnaireTemplate) => void;
    onQuestionnaireRemoved?: () => void;
};

export const TemplatesBrowser = ({
    templates,
    patientExternalIds,
    disabled = false,
    manageTemplatesUrl,
    onQuestionnaireSelected,
    onQuestionnaireRemoved,
}: TemplatesBrowserProps) => {
    const { state, dispatch } = useCompose();
    const isComposing = useIsComposing();
    const [pendingTemplate, setPendingTemplate] = useState<TemplateItem | null>(
        null,
    );
    const [isTemplatePreviewOpen, setIsTemplatePreviewOpen] = useState(false);
    useValidatePendingQuestionnaire(patientExternalIds);

    const sortedTemplates = useMemo(
        () => sortTemplates(templates),
        [templates],
    );

    const events = useTemplatesAnalytics();

    if (state.template.value) {
        return (
            <RemoveTemplateButton
                template={state.template}
                onClick={() => {
                    dispatch({
                        type: "REMOVE_TEMPLATE",
                    });
                    if (state.template.type === "QuestionnaireTemplate") {
                        onQuestionnaireRemoved?.();
                    }
                }}
            />
        );
    }

    const selectTemplate = (templateItem: TemplateItem) => {
        if (isComposing) {
            setPendingTemplate(templateItem);
            return;
        }

        confirmTemplateSelection(templateItem);
    };

    const confirmTemplateSelection = (templateItem: TemplateItem) => {
        setPendingTemplate(null);
        setIsTemplatePreviewOpen(false);

        if (templateItem.type === "QuestionnaireTemplate") {
            dispatch({
                type: "ADD_PENDING_QUESTIONNAIRE_TEMPLATE",
                payload: { pendingTemplate: templateItem.value },
            });
            onQuestionnaireSelected?.(templateItem.value);
            return;
        }

        dispatch({
            type: "ADD_MESSAGE_TEMPLATE",
            payload: { template: templateItem.value },
        });
    };

    const handlePreviewOptionSelected = () => {
        setIsTemplatePreviewOpen(true);
    };

    const labelPlaceholder = templates.some(
        (t) => t.type === "QuestionnaireTemplate",
    )
        ? "Search templates and questionnaires"
        : "Search templates";

    return (
        <>
            {disabled ||
            pendingTemplate ||
            state.template.type === "PendingQuestionnaireTemplate" ? (
                <DisabledCombobox
                    value={
                        pendingTemplate?.value.title ??
                        state.template.pendingValue?.title ??
                        ""
                    }
                    labelPlaceholder={labelPlaceholder}
                />
            ) : (
                <TemplatesCombobox
                    templates={sortedTemplates}
                    onItemSelected={selectTemplate}
                    onPreviewOptionSelected={handlePreviewOptionSelected}
                    labelPlaceholder={labelPlaceholder}
                />
            )}
            {pendingTemplate && (
                <AlreadyComposingModal
                    onConfirm={() => confirmTemplateSelection(pendingTemplate)}
                    onCancel={() => setPendingTemplate(null)}
                />
            )}
            <QuickViewPortal
                onClose={() => {
                    setIsTemplatePreviewOpen(false);
                    events.trackTemplateBrowseCloseButtonClick();
                }}
                isOpen={isTemplatePreviewOpen}
                autoFocus={false}
            >
                <MessageTemplateSelector
                    templates={templates}
                    onClickTemplate={confirmTemplateSelection}
                    patientExternalIds={patientExternalIds}
                    manageTemplatesLink={manageTemplatesUrl}
                />
            </QuickViewPortal>
        </>
    );
};
