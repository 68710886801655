import { ComponentType } from "react";

import { AvailabilityRestriction } from "@accurx/api/patient-messaging";
import type {
    ComposeStateType,
    ContactDetail,
    SelfBookLink,
} from "domains/message-component/reducer.types";
import { ComposePatient } from "domains/message-component/schemas/ComposePatientSchema";

export type OnMessageSendSelfBookLink = Pick<
    SelfBookLink,
    | "appointmentType"
    | "slotType"
    | "timeoutDays"
    | "specificClinician"
    | "crossOrgBooking"
    | "declineSettings"
> & {
    availabilityRestriction: AvailabilityRestriction;
};

export type OnMessageSendFnComposeData = {
    characterCount: number;
    fragmentCount: number;
    isUnicode: boolean;
} & Pick<
    ComposeStateType,
    | "attachments"
    | "contactDetails"
    | "isPatientResponseEnabled"
    | "isSaveToRecordEnabled"
    | "messageBody"
    | "messageSignature"
    | "nhsAdviceLink"
    | "sendAt"
    | "template"
> & {
        selfBookLink: OnMessageSendSelfBookLink | null;
    };

export type OnClinicianMessageSendFnComposeData = Pick<
    ComposeStateType,
    "attachments" | "messageBody" | "messageSignature" | "template"
>;

type OnMessageSendArgs = {
    data: OnMessageSendFnComposeData;
    isModal: boolean;
    errors: string[];
    warnings: string[];
};

type OnClinicianMessageSendArgs = {
    data: OnClinicianMessageSendFnComposeData;
    errors: string[];
    isModal: boolean;
    warnings: string[];
};

export type OnMessageSendFn = (args: OnMessageSendArgs) => void;
export type OnClinicianMessageSendFn = (
    args: OnClinicianMessageSendArgs,
) => void;

export type ComposeAssigneeSelectorComponent = ComponentType<{
    defaultAssignee: {
        id: string;
        type: "User" | "Team";
    };
}>;

// Either pass an assignee, or a string of "DEFAULT"
// to have the assignee reset to the default assignee
type SetAssignee = (
    assignee:
        | {
              id: string;
              type: "User" | "Team";
          }
        | "DEFAULT"
        | null,
) => void;

/**
 * Used to display who the patient response will come back to
 *
 * Can pass a string or a custom component
 *
 * If none is passed,
 * it will default to a string of "You"
 * */
export type ComposeAssignee =
    | {
          assigneeLabel?: string;
          assigneeSelector?: never;
          setAssignee?: SetAssignee;
          resetAssignee?: never;
      }
    | {
          assigneeLabel?: never;
          assigneeSelector: JSX.Element;
          setAssignee: SetAssignee;
      };

export type MessageActionsControlBarProps = {
    setAssignee?: SetAssignee;
    patient: ComposePatient;
};

export type MessageEditorProps = {
    onUserTyping?: () => void;
    assigneeSelector?: JSX.Element;
    assigneeLabel?: string;
    isHeightRestricted?: boolean;
};

export type ComposeProps = {
    contactDetails?: ContactDetail[];
    patient: ComposePatient;
    /**
     * Lets compose know whether the patient we're
     * replying to is the same as the current medical record patient
     *
     * - null: it is undetermined whether they are the same
     * - true: current medical record patient & compose patient are the same
     * - false: current medical record patient & compose patient are different
     */
    patientMatchesCurrentMedicalRecordPatient: boolean | null;
    onMessageSend: OnMessageSendFn;
    isMessageSending: boolean;
    /** [Optional] Do something when user is typing in a message */
    onUserTyping?: () => void;
    /** When provided, a minimise button will appear */
    onMinimiseClick?: () => void;
} & ComposeAssignee &
    ExpandedInfo;

export type ClinicianComposeProps = {
    onMessageSend: OnClinicianMessageSendFn;
    isMessageSending: boolean;
    recipient: {
        name: string;
    } & ({ workspaceId?: number } | { practiceCode?: string });
    /** When provided, a minimise button will appear */
    onMinimiseClick?: () => void;
    templateSelectorMustOpenDownwards?: boolean;
} & ExpandedInfo;

/* Only allow onExpandClick to be provided when the isHeightRestricted is
 * provided, but allow isHeightRestricted to be optioanlly supplied in
 * isolation. */
type ExpandedInfo =
    | {
          onExpandClick?: never;
          isHeightRestricted?: boolean;
      }
    | {
          onExpandClick: () => void;
          isHeightRestricted: boolean;
      };

export type { ContactDetail };

/** Used in analytics events to know where in the product the user came from when using compose */
export const AppOrigin = {
    Clinics: "Clinics",
    NavigationNewMessage: "NavigationNewMessage",
    NavigationNewMessageDropdown: "NavigationNewMessageDropdown",
    PatientList: "PatientList",
    PatientProfile: "PatientProfile",
    PatientSearch: "PatientSearch",
    MessagePractice: "MessagePractice",
    Inbox: "Inbox",
    SwitchApp: "SwitchApp",
    ComposeToolbar: "ComposeToolbar",
} as const;
