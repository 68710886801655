import { ComposeStateType } from "./reducer.types";

export const SELF_BOOK_MESSAGE_BODY = "We'd like you to book an appointment";

const MAX_MESSAGE_LENGTH = 612;

export { MAX_MESSAGE_LENGTH };

export const EMPTY_STATE: ComposeStateType = {
    originalGreeting: "",
    originalBody: "",
    originalSignature: "",
    contactDetails: { value: "", method: "Mobile", origin: "Conversation" },
    messageBody: "",
    messageSignature: "",
    attachments: [],
    isPatientResponseEnabled: false,
    // This is mostly used for presentation for the "Save" checkbox when a user is connected to a EMR, for non-integrated users they will not see the "Save" checkbox as that is controlled by whether canSaveToRecord is enabled/disabled. We safe guard non-integared users from calling save to record by wrapping all the save to record actions within a canSaveToRecord check.
    isSaveToRecordEnabled: true,
    errors: {},
    template: { type: "NoTemplate" },
    maxAttachmentCount: 1,
    sendAt: null,
    selfBookLink: null,
    nhsAdviceLink: null,
    canUseQuestionnaires: true,
    conversationParticipant: "WithPatient",
    isScheduling: false,
    isSelfbookConfigurationOpen: false,
    isMultipleMessages: false,
};
