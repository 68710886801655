import type {
    AppointmentChangeDeadline,
    AppointmentSupportedChanges,
} from "@accurx/api/appointment";
import { FeatureName, useFeatureFlag } from "@accurx/auth";
import { Card, Ds } from "@accurx/design";

import { getSupportedAmendMessage } from "../utils";

interface SendTimeInputProps {
    oneWeekReminderEnabled: boolean;
    toggleOneWeekReminderEnabled: (newValue: boolean) => void;
    threeWorkingDayReminderEnabled: boolean;
    toggleThreeDaysReminderEnabled: (newValue: boolean) => void;
    changeDeadline: AppointmentChangeDeadline;
    supportedChanges: AppointmentSupportedChanges;
}

export const SendTimeInput = ({
    oneWeekReminderEnabled,
    toggleOneWeekReminderEnabled,
    threeWorkingDayReminderEnabled,
    toggleThreeDaysReminderEnabled,
    changeDeadline,
    supportedChanges,
}: SendTimeInputProps) => {
    const newSchedulingView = useFeatureFlag(
        FeatureName.AppointmentRemindersConfigAmendmentsUIv1,
    );

    return (
        <Card
            spacing={3}
            header={
                <Ds.Text size="large" weight="bold" as="h2">
                    Scheduling
                </Ds.Text>
            }
        >
            <Ds.Text mb="1.5">
                A reminder will be sent 1 working day before the appointment.
                {!newSchedulingView &&
                    ` ${getSupportedAmendMessage(changeDeadline)}`}
            </Ds.Text>
            <Ds.Flex flexDirection="column" gap="1">
                <Ds.Text size="medium" weight="bold">
                    Additional reminders
                </Ds.Text>
                <Ds.Checkbox
                    id="three-days-reminder-checkbox"
                    checked={threeWorkingDayReminderEnabled}
                    appearance="ghost"
                    onCheckedChange={() =>
                        toggleThreeDaysReminderEnabled(
                            !threeWorkingDayReminderEnabled,
                        )
                    }
                >
                    <Ds.Checkbox.Indicator />
                    <Ds.Checkbox.Label>
                        3 working days before appointment
                    </Ds.Checkbox.Label>
                </Ds.Checkbox>
                <Ds.Checkbox
                    id="one-week-reminder-checkbox"
                    checked={oneWeekReminderEnabled}
                    appearance="ghost"
                    onCheckedChange={() =>
                        toggleOneWeekReminderEnabled(!oneWeekReminderEnabled)
                    }
                >
                    <Ds.Checkbox.Indicator />
                    <Ds.Checkbox.Label>
                        1 week before appointment
                    </Ds.Checkbox.Label>
                </Ds.Checkbox>
            </Ds.Flex>
        </Card>
    );
};
