import { ChangeEvent, FormEvent, useEffect, useState } from "react";

import {
    Button,
    Card,
    Ds,
    Feedback,
    Icon,
    Spinner,
    Text,
} from "@accurx/design";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useHistory } from "react-router-dom";

import { NavSubMenuComponent } from "app/navbar/NavSubMenuComponent";
import { Breadcrumb } from "app/practices/breadcrumb/Breadcrumb";
import { LoadingStatus } from "shared/LoadingStatus";
import { OrganisationHelper } from "shared/OrganisationHelper";
import { ROUTES_WORKSPACE } from "shared/Routes";
import { useAppSelector } from "store/hooks";

import {
    getSiteSetups,
    postSiteSetupLinks,
    postSiteSetupLocation,
    searchOdsCode,
} from "./SiteSetup.actions";
import { initialState as SiteSetupInitialState } from "./SiteSetup.reducer";
import {
    VaccineSiteLink,
    VaccineSiteSetup as VaccineSiteSetupDto,
} from "./SiteSetup.types";
import VaccineSiteSetupTable from "./SiteSetupTable";

export const VaccineSiteSetup = ({
    noHeader = false,
}: {
    noHeader?: boolean;
}): JSX.Element => {
    const history = useHistory();
    const dispatch = useDispatch();

    const practiceId = useAppSelector(
        ({ practices }) => practices.selectedPractice,
    );

    const practiceName = useAppSelector(({ account }) =>
        OrganisationHelper.getOrganisationName(account),
    );

    const {
        siteSetupLoading,
        siteSetups,
        siteSetupId,
        siteSetupLinks,
        siteSetupLinksUpdating,
        siteSetupError,
        siteSetupLocation,
        siteSetupLocationUpdating,
        siteSetupLocationError,
        siteSetupLinksError,
        siteSetupOdsCodeSearching,
        siteSetupOdsCodeSearchingError,
        siteSetupOdsCodeSearchingPracticeName,
    } = useSelector(
        ({ vaccineSiteSetup }: ApplicationState) =>
            vaccineSiteSetup || SiteSetupInitialState,
    );
    const urlParams = {
        organisationId: practiceId,
        siteSetupId,
    };

    const [locationText, setLocationText] = useState("");
    const [nationalCode, setNationalCode] = useState("");
    const [linksInputValidationMessage, setLinksInputValidationMessage] =
        useState("");
    const [nationalCodeValidationMessage, setNationalCodeValidationMessage] =
        useState("");
    const [searchedPracticeCode, setSearchedPracticeCode] = useState(""); // Store an ODS code when hitting search

    // Load SiteSetup data
    useEffect(() => {
        if (siteSetupLoading === LoadingStatus.Initial && practiceId) {
            dispatch(getSiteSetups(practiceId, null));
        }
    }, [dispatch, siteSetupLoading, practiceId]);

    useEffect(() => {
        if (siteSetupLocation) {
            setLocationText(siteSetupLocation);
        }
    }, [siteSetupLocation]);

    const handleSiteChange = (e: ChangeEvent<HTMLSelectElement>): void => {
        const setupId = e.target.value;
        dispatch(getSiteSetups(practiceId, setupId));
    };

    const handleSiteAddressChange = (
        e: ChangeEvent<HTMLInputElement>,
    ): void => {
        setLocationText(e.target.value);
    };

    const handleSiteAddressSubmit = (e: FormEvent<HTMLFormElement>): void => {
        e.preventDefault();

        if (!locationText) {
            return;
        }

        dispatch(postSiteSetupLocation(urlParams, { locationText }));
    };

    const handlePracticesCodesInput = (
        e: ChangeEvent<HTMLInputElement>,
    ): void => {
        setNationalCode(e.target.value);
    };

    const handlePracticesCodesSubmit = (
        e: FormEvent<HTMLFormElement>,
    ): void => {
        e.preventDefault();
        setSearchedPracticeCode("");

        if (!siteSetupId) {
            setLinksInputValidationMessage(
                "Please enter your Delivery site address before continuing",
            );
            return;
        }

        if (!nationalCode) {
            setLinksInputValidationMessage(
                "Practice ODS code is required, please add one before saving",
            );
            return;
        }

        dispatch(postSiteSetupLinks(urlParams, { nationalCode }));
        setLinksInputValidationMessage("");
        setNationalCode("");
    };

    const handlePracticeCodeSearch = (e: FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        if (!nationalCode) {
            setNationalCodeValidationMessage(
                "Practice ODS code is required, please add one before searching",
            );
            return;
        }
        setSearchedPracticeCode(nationalCode);
        dispatch(
            searchOdsCode({
                organisationId: practiceId,
                siteSetupId,
                odsCode: nationalCode,
            }),
        );
    };

    const handleContinue = (): void => {
        history.push(
            generatePath(ROUTES_WORKSPACE.accubookManagePatients, {
                workspaceId: practiceId,
            }),
        );
    };

    const renderSwitchSites = (): JSX.Element => (
        <div className="mb-5">
            <Text
                variant="label"
                as="label"
                props={{ htmlFor: "switch-sites" }}
            >
                Switch delivery sites
            </Text>
            <Text>
                You manage multiple delivery sites, to switch between them
                please choose from the options below.
            </Text>
            <select
                id="switch-sites"
                className="form-control"
                onChange={handleSiteChange}
                disabled={siteSetupLocationUpdating}
                defaultValue={siteSetupId}
            >
                {!siteSetupId && (
                    <option key="please-select" value="">
                        (Please select a site)
                    </option>
                )}
                {siteSetups?.map((x: VaccineSiteSetupDto) => (
                    <option key={x.id} value={x.id}>
                        {x.locationText}
                    </option>
                ))}
            </select>
        </div>
    );

    const renderAddress = (): JSX.Element => (
        <form className="mb-5" onSubmit={handleSiteAddressSubmit}>
            <div className="mb-5">
                <Text
                    variant="label"
                    as="label"
                    props={{ htmlFor: "site-address" }}
                >
                    Delivery site address
                </Text>
                <Text>
                    This is the site where the jabs will be administered. This
                    will be used to tell your patients where to go.
                </Text>
                <div className="d-flex">
                    <input
                        id="site-address"
                        type="text"
                        className="form-control mr-1"
                        onChange={handleSiteAddressChange}
                        value={locationText}
                        disabled={
                            siteSetupLocationUpdating ||
                            (!siteSetupId &&
                                siteSetups &&
                                siteSetups.length > 1) ||
                            false
                        }
                        autoComplete="off"
                        placeholder="Address"
                    />
                    <Button
                        theme="secondary"
                        type="submit"
                        disabled={
                            siteSetupLocationUpdating ||
                            (!siteSetupId &&
                                siteSetups &&
                                siteSetups.length > 1) ||
                            false
                        }
                        text={siteSetupId ? "Change" : "Create"}
                    />
                </div>
                {siteSetupLocationUpdating && <Spinner />}
                {siteSetupLocationError && (
                    <div className="d-flex">
                        <Icon colour="red" name="Error" size={3} theme="Fill" />
                        <Text skinny>{siteSetupLocationError}</Text>
                    </div>
                )}
            </div>
        </form>
    );

    const renderPracticeCode = (): JSX.Element => (
        <>
            <form className="mb-1" onSubmit={handlePracticeCodeSearch}>
                <Text
                    variant="label"
                    as="label"
                    props={{ htmlFor: "practices-codes" }}
                >
                    Practice codes
                </Text>
                <Text as="label">
                    Please enter the ODS codes of the practices that will be
                    inviting their patients to this delivery site.{" "}
                    <b>Practices can now be invited to more than one site.</b>{" "}
                    Things to note before adding a new practice:
                    <ul>
                        <li>
                            Any patients they have already invited, or will
                            invite in the future will count towards your
                            capacity allowance
                        </li>
                        <li>
                            As soon as they accept your invite, your delivery
                            site will be shown to their patients to book into,
                            so make sure you've got published clinics set up for
                            them!
                        </li>
                        <li>
                            You can't pick and choose which clinics show to
                            patients - once a practice is invited to your site,
                            patients can see all published clinics to book into
                        </li>
                    </ul>
                </Text>
                {Boolean(siteSetupLinks?.length) && (
                    <div className="mb-2">
                        {siteSetupLinks.map((link: VaccineSiteLink) => (
                            <Ds.Badge
                                key={link.nationalCode}
                                color="greyscale"
                                className="mr-1 mb-1"
                            >
                                {`${link.nationalCode} - ${link.name}${
                                    !link.isApproved ? " (pending)" : ""
                                }`}
                            </Ds.Badge>
                        ))}
                    </div>
                )}
                <div className="d-flex">
                    <input
                        id="practices-codes"
                        type="text"
                        className="form-control mr-1"
                        onChange={handlePracticesCodesInput}
                        disabled={!siteSetupId}
                        value={nationalCode}
                        autoComplete="off"
                    />
                    <Button
                        theme="secondary"
                        type="submit"
                        text={"Search"}
                        disabled={!siteSetupId || siteSetupOdsCodeSearching}
                    />
                </div>
                {(nationalCodeValidationMessage ||
                    siteSetupOdsCodeSearchingError) && (
                    <div className="d-flex">
                        <Icon colour="red" name="Error" size={3} theme="Fill" />
                        <Text skinny>
                            {nationalCodeValidationMessage ||
                                siteSetupOdsCodeSearchingError}
                        </Text>
                    </div>
                )}
            </form>
            <form className="mb-5" onSubmit={handlePracticesCodesSubmit}>
                {!siteSetupOdsCodeSearching &&
                    !siteSetupOdsCodeSearchingError &&
                    siteSetupOdsCodeSearchingPracticeName && (
                        <Card>
                            <div className="d-flex justify-content-between">
                                <Text>
                                    Selected practice:{" "}
                                    {siteSetupOdsCodeSearchingPracticeName} (
                                    {searchedPracticeCode})
                                </Text>
                                <Button
                                    theme="secondary"
                                    type="submit"
                                    text={"Add"}
                                    disabled={siteSetupLinksUpdating}
                                    style={{ height: "2.5rem" }}
                                />
                            </div>
                        </Card>
                    )}
                {(linksInputValidationMessage || siteSetupLinksError) && (
                    <div className="d-flex">
                        <Icon colour="red" name="Error" size={3} theme="Fill" />
                        <Text skinny>
                            {linksInputValidationMessage || siteSetupLinksError}
                        </Text>
                    </div>
                )}
            </form>
        </>
    );

    if (
        siteSetupLoading === LoadingStatus.Initial ||
        siteSetupLoading === LoadingStatus.Loading
    ) {
        return <Spinner />;
    }

    if (siteSetupError) {
        return <Feedback colour="error" title={siteSetupError} content="" />;
    }

    return (
        <>
            {!noHeader && (
                <>
                    <NavSubMenuComponent>
                        <Breadcrumb title="Manage Clinics" wrapper={false} />
                        <Text variant="title" as="h1" skinny className="mt-2">
                            COVID-19 Vaccine
                            {practiceName && (
                                <Text
                                    variant="subtitle"
                                    as="span"
                                    props={{ className: "mx-1" }}
                                    skinny
                                >
                                    {` · ${practiceName}`}
                                </Text>
                            )}
                        </Text>
                    </NavSubMenuComponent>
                    <Text as="h2" variant="subtitle">
                        Set up delivery sites
                    </Text>
                </>
            )}
            <div className="row">
                <div className="col-12 col-sm-8 col-lg-5">
                    {siteSetups && siteSetups.length > 1 && renderSwitchSites()}
                    {renderAddress()}
                    {renderPracticeCode()}
                </div>
            </div>
            {siteSetupId && (
                <div className="mb-5">
                    <VaccineSiteSetupTable initialShowPastSessions={false} />
                </div>
            )}
            <div className="d-flex justify-content-center mb-5">
                <Button
                    type="button"
                    className="m-1"
                    onClick={handleContinue}
                    disabled={!siteSetupId}
                    text={"Continue"}
                />
            </div>
        </>
    );
};

export default VaccineSiteSetup;
