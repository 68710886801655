import { PatientExternalId } from "@accurx/concierge/types";
import intersectionBy from "lodash/intersectionBy";

export const isSamePatient = (
    currentPatientExternalIds?: PatientExternalId[],
    newPatientExternalIds?: PatientExternalId[],
): boolean => {
    if (!currentPatientExternalIds || !newPatientExternalIds) return false;

    /**
     * We compare external ids arrays here because:
     * 1. we don't have internal ids in PatientDemographics,
     *    we'd have to fetch them via an API call if we needed those
     * 2. both patients come from the medical record integration, so data should
     *    be consistent/the same
     */
    const matchingIds = intersectionBy(
        currentPatientExternalIds,
        newPatientExternalIds,
        (id) => JSON.stringify(id),
    );

    return matchingIds.length > 0;
};
