import { DeclineSettings as DeclineSettingsData } from "@accurx/api/patient-messaging";
import { Ds, FormFieldFeedback, FormFieldWrapper } from "@accurx/design";
import {
    CanPatientDecline,
    DeclineSettingsErrors,
} from "domains/self-book/utils/types";

import { CustomRadio } from "../../CustomRadio";
import { RadioTextWrapper } from "../AppointmentType/AppointmentType.styles";
import { StyledBox, StyledTextArea } from "./DeclineSettings.styles";
import { SnomedCodeSelect } from "./SnomedCodeSelect";

const URL_HOW_DECLINE_WORKS =
    "https://support.accurx.com/en/articles/6820076-self-book-how-decline-works-for-patients-when-using-batch-self-book";

type DeclineSettingsProps = {
    initialOption: {
        declineSettingsData: DeclineSettingsData;
        canPatientDecline: CanPatientDecline;
    };
    onDeclineSettingsChange: (
        canPatientDecline: CanPatientDecline,
        declineSettingsData: DeclineSettingsData,
    ) => void;
    errors?: DeclineSettingsErrors;
    showLoading?: boolean;
};

type DeclineSettingsOption = {
    id: string;
    label: string;
    value: CanPatientDecline;
};

const radioInputs: DeclineSettingsOption[] = [
    { id: "no-decline-settings", label: "No", value: "No" },
    { id: "yes-decline-settings", label: "Yes", value: "Yes" },
];

export const DeclineSettings = ({
    initialOption,
    onDeclineSettingsChange,
    errors,
}: DeclineSettingsProps) => {
    const label = "Allow patients to decline";
    const id = "allow-patients-decline";

    const onAllowPatientChange = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        const canPatientDecline = event.target.value as CanPatientDecline;
        onDeclineSettingsChange(
            canPatientDecline,
            initialOption.declineSettingsData,
        );
    };

    const onDeclineMessageChange = (declineConfirmMessage: string) => {
        const updatedDeclineSettingsData = {
            ...initialOption.declineSettingsData,
            confirmationText: declineConfirmMessage,
        };

        //setDeclineSettingsData(updatedDeclineSettingsData);
        onDeclineSettingsChange(
            initialOption.canPatientDecline,
            updatedDeclineSettingsData,
        );
    };

    const onSnomedCodeChange = (snomedConceptId: string) => {
        const updatedDeclineSettingsData = {
            ...initialOption.declineSettingsData,
            snomedConceptId: snomedConceptId,
        };

        onDeclineSettingsChange(
            initialOption.canPatientDecline,
            updatedDeclineSettingsData,
        );
    };

    return (
        <Ds.Box py="0.75rem">
            <FormFieldWrapper
                label={
                    <Ds.Text size="small" weight="bold">
                        {label}
                    </Ds.Text>
                }
                labelProps={{ htmlFor: id }}
            >
                <>
                    {radioInputs.map((item) => (
                        <Ds.Grid.Cell key={item.label} role="radio">
                            <CustomRadio
                                name={"decline-settings-option"}
                                id={item.value}
                                label={
                                    <RadioTextWrapper>
                                        <Ds.Text size="small">
                                            {item.label}
                                        </Ds.Text>
                                    </RadioTextWrapper>
                                }
                                value={item.value}
                                checkedValue={initialOption.canPatientDecline}
                                onChange={onAllowPatientChange}
                            />
                        </Ds.Grid.Cell>
                    ))}
                    {initialOption.canPatientDecline === "Yes" && (
                        <StyledBox>
                            <Ds.Text size="small" weight="bold">
                                Decline message
                            </Ds.Text>
                            <Ds.Text size="small">
                                Shown to patients before they decline
                            </Ds.Text>
                            <StyledTextArea
                                autoComplete="off"
                                placeholder="write decline message"
                                value={
                                    initialOption.declineSettingsData
                                        .confirmationText as string
                                }
                                onChange={(event) =>
                                    onDeclineMessageChange(event.target.value)
                                }
                                id="decline-message"
                                aria-label="decline message"
                                maxLength={4000}
                            />
                            {errors?.confirmationTextError && (
                                <FormFieldFeedback
                                    text={
                                        <Ds.Text size="small" as="span">
                                            {errors.confirmationTextError}
                                        </Ds.Text>
                                    }
                                    as="span"
                                    variant="error"
                                />
                            )}
                            <Ds.Text size="small" weight="bold" mt="2">
                                Decline SNOMED code
                            </Ds.Text>
                            <Ds.Text size="small">
                                Type the description or concept ID to search
                            </Ds.Text>
                            <SnomedCodeSelect
                                selectedSnomedCode={
                                    initialOption.declineSettingsData
                                        .snomedConceptId ?? ""
                                }
                                onSnomedCodeSelect={onSnomedCodeChange}
                                errors={errors?.snomedCodeError}
                            />

                            <Ds.Link to={URL_HOW_DECLINE_WORKS} target="_blank">
                                <Ds.Text as="span" size="small">
                                    See how decline works
                                </Ds.Text>
                            </Ds.Link>
                        </StyledBox>
                    )}
                </>
            </FormFieldWrapper>
        </Ds.Box>
    );
};
