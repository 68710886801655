import * as React from "react";

import {
    Card,
    Spinner,
    Table,
    Text,
    usePagination,
    useTable,
} from "@accurx/design";
import { getApiEndpoint } from "@accurx/shared";

import { PATIENT_INITIATED_API_ENDPOINTS } from "../../../../../constants/ApiEndpoints";
import { NoReceptionDataPrompt } from "../../../shared/NoReceptionDataPrompt/NoReceptionDataPrompt";
import { RequestSource, RequestType } from "../../../shared/shared.constants";
import { IRequestSource, IRequestType } from "../../../shared/shared.types";
import { useFetchAtIntervals } from "../../hooks/useFetchAtIntervals";
import { ColumnHeaderContent } from "../ColumnHeaderContent";
import { LiveStatusBadge } from "../LiveStatusBadge";
import { OwnerCellContent } from "../OwnerCellContent";
import { RequestFilters } from "../RequestFilters";
import { RequestsCellContent } from "../RequestsCellContent";
import { PAGE_SIZES, TablePagination } from "../TablePagination";
import { AssignmentData, RequestByType } from "../shared/shared.types";
import {
    COLUMN_SORT_DIRECTION,
    getItemId,
    getPinnedItems,
} from "../shared/shared.utils";
import { LIVE_ASSIGNMENT_FETCH_INTERVAL } from "./LiveAssignmentDashboard.constants";
import {
    NoReceptionDataCard,
    NoReceptionDataWrapper,
    StyledContainer,
    StyledEmptyTableDataCell,
    StyledHeaderWrapper,
    StyledTableBody,
    StyledTableDataCell,
    StyledTableHeader,
    StyledTableWrapper,
} from "./LiveAssignmentDashboard.styles";

const getAssignmentMapper = ({ adminCount, medicalCount }: RequestByType) => ({
    [RequestType.Admin]: {
        [RequestSource.Patients]: adminCount.patientFlow,
        [RequestSource.Reception]: adminCount.receptionFlow,
        [RequestSource.PatientsAndReception]:
            adminCount.patientFlow + adminCount.receptionFlow,
    },
    [RequestType.Medical]: {
        [RequestSource.Patients]: medicalCount.patientFlow,
        [RequestSource.Reception]: medicalCount.receptionFlow,
        [RequestSource.PatientsAndReception]:
            medicalCount.patientFlow + medicalCount.receptionFlow,
    },
    [RequestType.MedicalAndAdmin]: {
        [RequestSource.Patients]:
            adminCount.patientFlow + medicalCount.patientFlow,
        [RequestSource.Reception]:
            adminCount.receptionFlow + medicalCount.receptionFlow,
        [RequestSource.PatientsAndReception]:
            adminCount.patientFlow +
            medicalCount.patientFlow +
            adminCount.receptionFlow +
            medicalCount.receptionFlow,
    },
});

/**
 * Minimum number of rows we need in order to correctly
 * display the no data modal. This value is used to determine
 * the amount of padding needed by subtracting it from the number
 * of rows we do have.
 */
const MINIMUM_ROWS_FOR_NO_DATA_MODAL = 6;

type LiveAssignmentDashboardProps = {
    organisationId: string;
};

export const LiveAssignmentDashboard = ({
    organisationId,
}: LiveAssignmentDashboardProps): JSX.Element => {
    const [requestType, setRequestType] = React.useState<IRequestType>(
        RequestType.MedicalAndAdmin,
    );
    const [requestSource, setRequestSource] = React.useState<IRequestSource>(
        RequestSource.PatientsAndReception,
    );
    const [pinnedItemIds, setPinnedItemIds] =
        React.useState<string[]>(getPinnedItems);

    const getAggregateRequestCount = React.useCallback(
        (assignment: RequestByType) => {
            const mapper = getAssignmentMapper(assignment);
            const group = mapper[requestType];
            return group[requestSource];
        },
        [requestType, requestSource],
    );

    const isItemPinned = React.useCallback(
        (item: AssignmentData) =>
            pinnedItemIds.includes(getItemId(item, organisationId)),
        [pinnedItemIds, organisationId],
    );

    const endpoint = React.useMemo(
        () =>
            getApiEndpoint({
                path: PATIENT_INITIATED_API_ENDPOINTS.assignments,
                params: { organisationId },
            }),
        [organisationId],
    );

    const { data, lastSuccessfulFetch, error } = useFetchAtIntervals<{
        assigneeData: AssignmentData[];
    }>(endpoint, LIVE_ASSIGNMENT_FETCH_INTERVAL);

    const formattedData = React.useMemo(
        () =>
            data?.assigneeData.map((item) => ({
                ...item,
                isPinned: isItemPinned(item),
                totalCurrentAssigned: getAggregateRequestCount(
                    item.assignments.totalCurrent.assigned,
                ),
                urgentCurrentAssigned: getAggregateRequestCount(
                    item.assignments.totalCurrent.assigned.urgentData,
                ),
                totalTodayAssigned: getAggregateRequestCount(
                    item.assignments.totalToday.assigned,
                ),
                urgentTodayAssigned: getAggregateRequestCount(
                    item.assignments.totalToday.assigned.urgentData,
                ),
                totalTodayDone: getAggregateRequestCount(
                    item.assignments.totalToday.done,
                ),
            })),
        [data, getAggregateRequestCount, isItemPinned],
    );

    const table = useTable({
        data: formattedData ?? [],
        columnDefs: [
            {
                header: "Assignee",
                id: "name",
                sort: {
                    selector: ["isPinned", (row) => row.name.toLowerCase()],
                    direction: COLUMN_SORT_DIRECTION["name"].original,
                },
            },
            {
                header: "Open requests",
                id: "totalCurrentAssigned",
                sort: {
                    selector: [
                        "isPinned",
                        "totalCurrentAssigned",
                        "urgentCurrentAssigned",
                    ],
                    direction:
                        COLUMN_SORT_DIRECTION["totalCurrentAssigned"].original,
                },
            },
            {
                header: "Assigned to",
                id: "totalTodayAssigned",
                sort: {
                    selector: [
                        "isPinned",
                        "totalTodayAssigned",
                        "urgentTodayAssigned",
                    ],
                    direction:
                        COLUMN_SORT_DIRECTION["totalTodayAssigned"].original,
                },
            },
            {
                header: "Marked done",
                id: "totalTodayDone",
                sort: {
                    selector: ["isPinned", "totalTodayDone"],
                    direction: COLUMN_SORT_DIRECTION["totalTodayDone"].original,
                },
            },
        ],
        defaultOptions: {
            sortColumnId: "totalCurrentAssigned",
            sortDirection:
                COLUMN_SORT_DIRECTION["totalCurrentAssigned"].original,
        },
    });
    const {
        currentPage,
        currentPageSize,
        totalPages,
        onNext,
        onPrevious,
        goToPage,
        onPageSizeChange,
        paginate,
    } = usePagination({
        defaultPage: 1,
        defaultPageSize: PAGE_SIZES[1],
        total: table.rows.length,
        isCycleEnabled: true,
    });

    const handleFilter = (filterFn: (row: AssignmentData) => boolean) => {
        table.actions.filter(filterFn);
        goToPage(1);
    };

    const showBorderBottom = (
        row: AssignmentData,
        index: number,
        allRows: AssignmentData[],
    ) => {
        if (!isItemPinned(row)) return false;
        const isLastItem = index === allRows.length - 1;
        if (isItemPinned(row) && isLastItem) return true;
        if (isItemPinned(row) && !isItemPinned(allRows[index + 1])) return true;
    };

    const isEmptyDataSet = formattedData?.every(
        (data) =>
            data.totalCurrentAssigned +
                data.totalTodayAssigned +
                data.totalTodayDone ===
            0,
    );

    const paginatedRows = paginate(table.rows);

    return (
        <StyledContainer>
            <Card>
                <StyledHeaderWrapper>
                    <Text skinny variant="subtitle">
                        Requests by assignee
                    </Text>
                    <LiveStatusBadge lastFetched={lastSuccessfulFetch} />
                </StyledHeaderWrapper>
                <RequestFilters
                    onFilterByText={handleFilter}
                    requestType={requestType}
                    requestSource={requestSource}
                    onFilterByRequestType={setRequestType}
                    onFilterByRequestSource={setRequestSource}
                />
                <StyledTableWrapper>
                    <Table>
                        <Table.ColumnGroup>
                            {table.columns.map((column) => (
                                <Table.Column
                                    key={column.id}
                                    width={column.id === "name" ? "40%" : "20%"}
                                    colour={
                                        column.metadata.isSorted
                                            ? "blue"
                                            : "transparent"
                                    }
                                />
                            ))}
                        </Table.ColumnGroup>
                        <Table.Head>
                            <Table.Row>
                                <StyledTableHeader />
                                <StyledTableHeader>Current</StyledTableHeader>
                                <StyledTableHeader colSpan={2}>
                                    Today
                                </StyledTableHeader>
                            </Table.Row>
                            <Table.Row>
                                {table.columns.map((column) => (
                                    <StyledTableHeader
                                        key={column.id}
                                        scope="col"
                                    >
                                        <ColumnHeaderContent
                                            column={column}
                                            sortDirection={
                                                table.metadata.sortDirection
                                            }
                                        />
                                    </StyledTableHeader>
                                ))}
                            </Table.Row>
                        </Table.Head>
                        <StyledTableBody>
                            {paginatedRows.map((row, index, allRows) => (
                                <Table.Row
                                    key={getItemId(row, organisationId)}
                                    isBorderBottom={showBorderBottom(
                                        row,
                                        index,
                                        allRows,
                                    )}
                                >
                                    <StyledTableHeader scope="row">
                                        <OwnerCellContent
                                            workspaceId={organisationId}
                                            totalRows={table.rows.length}
                                            row={row}
                                            onItemPin={setPinnedItemIds}
                                            isItemPinned={isItemPinned}
                                        />
                                    </StyledTableHeader>
                                    <StyledTableDataCell>
                                        <RequestsCellContent
                                            totalRequests={getAggregateRequestCount(
                                                row.assignments.totalCurrent
                                                    .assigned,
                                            )}
                                            urgentRequests={getAggregateRequestCount(
                                                row.assignments.totalCurrent
                                                    .assigned.urgentData,
                                            )}
                                        />
                                    </StyledTableDataCell>
                                    <StyledTableDataCell>
                                        <RequestsCellContent
                                            totalRequests={getAggregateRequestCount(
                                                row.assignments.totalToday
                                                    .assigned,
                                            )}
                                            urgentRequests={getAggregateRequestCount(
                                                row.assignments.totalToday
                                                    .assigned.urgentData,
                                            )}
                                        />
                                    </StyledTableDataCell>
                                    <StyledTableDataCell>
                                        {getAggregateRequestCount(
                                            row.assignments.totalToday.done,
                                        )}
                                    </StyledTableDataCell>
                                </Table.Row>
                            ))}

                            {requestSource === RequestSource.Reception &&
                                isEmptyDataSet && (
                                    <>
                                        {paginatedRows.length <
                                            MINIMUM_ROWS_FOR_NO_DATA_MODAL && (
                                            <Table.Row>
                                                <StyledEmptyTableDataCell
                                                    $padding={
                                                        MINIMUM_ROWS_FOR_NO_DATA_MODAL -
                                                        paginatedRows.length
                                                    }
                                                    colSpan={4}
                                                />
                                            </Table.Row>
                                        )}
                                        <NoReceptionDataWrapper>
                                            <NoReceptionDataCard>
                                                <NoReceptionDataPrompt
                                                    sectionName="Requests by assignee"
                                                    requestSource={
                                                        requestSource
                                                    }
                                                    requestType={requestType}
                                                />
                                            </NoReceptionDataCard>
                                        </NoReceptionDataWrapper>
                                    </>
                                )}
                        </StyledTableBody>
                    </Table>
                </StyledTableWrapper>

                {!lastSuccessfulFetch && !error && (
                    <Spinner hiddenLabel="Loading users" />
                )}

                {!lastSuccessfulFetch && error && (
                    <Text variant="label">{error}</Text>
                )}

                <TablePagination
                    total={table.rows.length}
                    totalPages={totalPages}
                    currentPage={currentPage}
                    currentPageSize={currentPageSize}
                    onNext={onNext}
                    onPrevious={onPrevious}
                    onPageSizeChange={onPageSizeChange}
                />
            </Card>
        </StyledContainer>
    );
};
