import { ReactNode, useMemo, useState } from "react";

import { useAuth, useCurrentUser } from "@accurx/auth";
import { Ds, Popover, PopoverTrigger } from "@accurx/design";
import {
    NavTooltip,
    StyledDivider,
    StyledPopoverContent,
    StyledPopoverItem,
} from "@accurx/navigation";
import { cleanUpSession } from "appLifecycle";
import { StyledTriggerButton } from "layouts/UnifiedLayout/UnifiedLayout.styles";
import { userflowIds } from "layouts/constants";
import { useHistory } from "react-router-dom";
import { useSelectedOrganisation } from "reduxQuarantine/SelectedOrganisationProvider";
import { useAnalytics } from "reduxQuarantine/useAnalytics";

import { HelpPopoverContent } from "../HelpPopover/HelpPopover";
import { PopoverSubMenu } from "./PopoverSubMenu";
import { WorkspaceMenuHeader } from "./WorkspaceMenuHeader";

export const SimpleWorkspaceMenu = ({
    children,
    isMobile,
}: {
    children?: ReactNode;
    isMobile?: boolean;
}) => {
    const track = useAnalytics();
    const history = useHistory();

    const { logOut } = useAuth();
    const { user } = useCurrentUser();
    const { selectedOrgId } = useSelectedOrganisation();

    const [popoverOpen, setPopoverOpen] = useState(false);

    // Mobile-specific
    const [helpMenuOpen, setHelpMenuOpen] = useState(false);

    const workspace =
        (user.isLoggedIn &&
            user.organisations.find(({ orgId }) => orgId === selectedOrgId)) ||
        undefined;

    const settingsButtonLabel = useMemo(() => {
        if (workspace) {
            return workspace.organisationName;
        } else if (user.isLoggedIn) {
            return user.fullName;
        } else {
            return undefined;
        }
    }, [user, workspace]);

    const handleOpenChange = (open: boolean) => {
        if (open) {
            setHelpMenuOpen(false);
            setPopoverOpen(true);
            track("NavigationMenu Button Click", {
                origin: history.location.pathname,
                navigationOptionSelected: "WorkspaceMenu",
                navigationVersion: "Unified",
                eventVersion: 2,
            });
        } else {
            setPopoverOpen(false);
        }
    };

    const handleHelpMenuClick = () => {
        setHelpMenuOpen(true);
        track("NavigationAccount MenuItem Click", {
            eventVersion: 2,
            navigationVersion: "Unified",
            origin: history.location.pathname,
            optionSelected: "Help",
        });
    };

    const handleLogOut = () => {
        track("NavigationAccount MenuItem Click", {
            eventVersion: 2,
            navigationVersion: "Unified",
            origin: history.location.pathname,
            optionSelected: "Logout",
        });
        setPopoverOpen(false);
        logOut({ cleanUpFn: cleanUpSession });
    };

    return (
        <Popover open={popoverOpen} onOpenChange={handleOpenChange}>
            {isMobile || !workspace ? (
                <PopoverTrigger asChild>
                    {children || (
                        <StyledTriggerButton aria-label="Workspace menu">
                            <Ds.Icon name="Cog" size="small" color="white" />
                            {settingsButtonLabel}
                        </StyledTriggerButton>
                    )}
                </PopoverTrigger>
            ) : (
                <NavTooltip text="Workspace menu">
                    <PopoverTrigger asChild>
                        <StyledTriggerButton aria-label="Workspace menu">
                            <Ds.Icon name="Cog" size="small" color="white" />
                            {settingsButtonLabel}
                        </StyledTriggerButton>
                    </PopoverTrigger>
                </NavTooltip>
            )}
            {helpMenuOpen ? (
                <HelpPopoverContent onClose={() => setPopoverOpen(false)} />
            ) : (
                <StyledPopoverContent side="bottom" align="end">
                    <Ds.Box m="1" mb="1.5">
                        <WorkspaceMenuHeader
                            workspace={workspace}
                            user={user}
                        />
                    </Ds.Box>
                    <StyledDivider />
                    {isMobile && (
                        <>
                            <PopoverSubMenu
                                onClick={handleHelpMenuClick}
                                data-userflow-id={
                                    userflowIds.navigation.primary
                                        .workspaceMenuHelp
                                }
                            >
                                <Ds.Icon
                                    name="Question"
                                    appearance="outline"
                                    size="small"
                                    color="stone"
                                />
                                <Ds.Text weight="bold" color="zinc">
                                    Help
                                </Ds.Text>
                            </PopoverSubMenu>
                            <StyledDivider />
                        </>
                    )}
                    <StyledPopoverItem onClick={handleLogOut}>
                        <Ds.Icon
                            name="preview-Logout"
                            size="small"
                            color="stone"
                        />
                        <Ds.Text weight="bold" color="zinc">
                            Log out
                        </Ds.Text>
                    </StyledPopoverItem>
                </StyledPopoverContent>
            )}
        </Popover>
    );
};
