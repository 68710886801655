import { useRef, useState } from "react";

import { useAnalytics } from "@accurx/analytics";
import { useCurrentWorkspace } from "@accurx/auth";
import { Ds, Feedback, Hooks, Tokens } from "@accurx/design";
import {
    MobileModalHeader,
    ModalHeader,
    PatientResults,
    PatientSearchForm,
    PatientSearchFormHandle,
    PatientSearchResult,
    mapPatientSearchResultToPdsResult,
    useLatestPatientSearchQuery,
    usePatientSearchViewAnalytics,
} from "@accurx/patient";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";

import { actionCreators } from "app/selectProduct/SelectProductActions";
import useSavePatientSearchToStore from "app/sharedComponents/patientContactDropdown/hooks/useSavePatientSearchToStore";

export interface VideoConsultModalProps {
    modalOpen: boolean;
    setModalOpen: (open: boolean) => void;
}

export const VideoConsultModal = ({
    modalOpen,
    setModalOpen,
}: VideoConsultModalProps) => {
    const { orgId } = useCurrentWorkspace();
    const track = useAnalytics();
    const history = useHistory();
    const dispatch = useDispatch();

    const { size } = Hooks.useViewportSize();
    const isMobileSize = ["xs", "sm"].includes(size);

    const saveToStore = useSavePatientSearchToStore();
    const { data: latestPatientSearch } = useLatestPatientSearchQuery(
        { organisationId: orgId, search: "" },
        { enabled: false },
    );
    const formRef = useRef<PatientSearchFormHandle>(null);

    const [searchResults, setSearchResults] = useState<
        PatientSearchResult[] | null
    >(null);
    const [isSearching, setIsSearching] = useState<boolean>(false);
    const [isFormDisabled, setIsFormDisabled] = useState<boolean>(false);

    const isFirstExperience = latestPatientSearch?.patients.length === 0;

    usePatientSearchViewAnalytics({
        isDetailPrePopulated: false,
        searchPatientOrigin: "VideoInvite",
    });

    const showErrorToast = () => {
        toast(
            <Feedback
                colour="error"
                title="Couldn't load patient details. Please try again."
            />,
        );
    };

    const handleTestPatient = () => {
        formRef.current?.useTestPatient();
        track("PatientSearchTestPatient Button Click", {
            productOrigin: "VideoInvite",
        });
    };

    const handleSelectPatient = (searchResult: PatientSearchResult | null) => {
        if (searchResult) {
            saveToStore(mapPatientSearchResultToPdsResult(searchResult));
            dispatch(actionCreators.selectVideoProductType());
            setModalOpen(false);
            history.push("/send-message");
        } else {
            showErrorToast();
        }
    };

    return (
        <StyledModal
            size="large"
            open={modalOpen}
            onOpenChange={setModalOpen}
            fullscreen="lg"
        >
            {isMobileSize ? (
                <MobileModalHeader
                    onClickTestPatient={
                        isFirstExperience || isFormDisabled
                            ? undefined
                            : handleTestPatient
                    }
                />
            ) : (
                <ModalHeader
                    title="Search for patient to invite"
                    onClickTestPatient={
                        isFirstExperience || isFormDisabled
                            ? undefined
                            : handleTestPatient
                    }
                />
            )}
            <Ds.Modal.Divider />
            <StyledModalBody gap="1.5">
                <PatientSearchForm
                    ref={formRef}
                    searchMethods={["BASIC", "ADVANCED"]}
                    onSearchResult={setSearchResults}
                    onSearchError={showErrorToast}
                    onLoading={setIsSearching}
                    isFormDisabled={isFormDisabled}
                    productOrigin="VideoInvite"
                />
                <PatientResults
                    onSelect={handleSelectPatient}
                    onClickTestPatient={handleTestPatient}
                    onToggleFilter={setIsFormDisabled}
                    results={searchResults}
                    isLoading={isSearching}
                    productOrigin="VideoInvite"
                />
            </StyledModalBody>
        </StyledModal>
    );
};

const StyledModal = styled(Ds.Modal)`
    @media (min-width: ${Tokens.BREAKPOINTS.lg}) {
        display: flex;
        flex-direction: column;
        min-height: 700px;
        max-height: 90%;
        overflow: hidden;
    }
`;

const StyledModalBody = styled(Ds.Flex)`
    flex: 1;
    overflow: hidden;
    flex-direction: column;

    padding: ${Tokens.SIZES[3]};
`;
