import {
    QuerySearchPatients,
    QuerySearchPatientsSchema,
} from "domains/native/schemas/QuerySearchPatientsSchema";

import { NativeApi } from "../NativeApi";
import { createEndpoint } from "../api.utils";

export type SearchPatientsQueryPayload = {
    searchTerm?: string;
    nhsNumber?: string;
    firstName?: string;
    lastName?: string;
    dateOfBirth?: string;
};

export const querySearchPatientsQuery = createEndpoint<
    QuerySearchPatients,
    SearchPatientsQueryPayload
>("QuerySearchPatients", async (context, payload) => {
    const result = await NativeApi.request({
        transport: context.transport,
        request: {
            type: "QuerySearchPatients",
            workspaceId: context.workspaceId,
            payload: payload,
        },
    });
    return QuerySearchPatientsSchema.parse(result);
});
