import { SelfbookConfigurationPayload } from "@accurx/self-book";

import { SelfBookLink } from "../reducer.types";

export const mapSelfBookFormDataToSelfBookLink = (
    formData: SelfbookConfigurationPayload,
): SelfBookLink => {
    return {
        appointmentType: formData.appointmentType,
        slotType: formData.slotType,
        specificClinician: formData.specificClinician,
        timeoutDays: formData.timeoutDays,
        crossOrgBooking: formData.crossOrgBooking,
        appointmentAvailabilityPeriod: formData.appointmentAvailabilityPeriod,
        declineSettings: formData.declineSettings,
    };
};
