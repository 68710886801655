import { PatientTriageRequestItem } from "@accurx/concierge/types";
import { normalisePhoneNumber } from "@accurx/message-component";
import { MobileNumberHelper } from "@accurx/shared";
import flatten from "lodash/flatten";

const questionMap = {
    patient: "Phone number",
    proxy: "Proxy contact number",
    patientPreferred: "Patient preferred contact number",
} as const;

type TriageNumberType = keyof typeof questionMap;

type TriageQuestionWithNumber = (typeof questionMap)[keyof typeof questionMap];

export type TriageContactNumber = {
    type: TriageNumberType;
    value: string;
};

const questionToType = (
    question: TriageQuestionWithNumber,
): TriageNumberType => {
    switch (question) {
        case "Patient preferred contact number":
            return "patientPreferred";
        case "Phone number":
            return "patient";
        case "Proxy contact number":
            return "proxy";
    }
};

const isAnswerWithValidNumber = (
    response: Pick<
        PatientTriageRequestItem["sections"][number]["responses"][number],
        "question" | "answer"
    >,
): response is { question: TriageQuestionWithNumber; answer: string } => {
    const { question, answer } = response;

    const isValidQuestion =
        !!question && Object.values<string>(questionMap).includes(question);

    return (
        isValidQuestion &&
        !!answer &&
        MobileNumberHelper.isValidMobileNumber(answer)
    );
};

/**
 * Finds valid numbers in a triage requests
 * and returns the normalised UK formatted number back
 */
export const findValidNumbersFromTriageRequest = (
    item: PatientTriageRequestItem,
): TriageContactNumber[] =>
    flatten(item.sections.map(({ responses }) => responses)).reduce<
        TriageContactNumber[]
    >((items, current) => {
        const isValid = isAnswerWithValidNumber(current);
        if (!isValid) {
            return items;
        }
        const normalised = normalisePhoneNumber(current.answer);
        return normalised
            ? [
                  ...items,
                  {
                      type: questionToType(current.question),
                      value: normalised,
                  },
              ]
            : items;
    }, []);
