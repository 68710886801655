import {
    ChangeEvent,
    ClipboardEvent,
    FormEvent,
    forwardRef,
    useEffect,
    useState,
} from "react";

import { Button, Ds, Input } from "@accurx/design";
import { StyledFormFieldWrapper } from "@accurx/navigation";
import { useBasicPatientSearch } from "domains/patient/hooks/mutations/useBasicPatientSearch";
import { PatientSearchResult } from "domains/patient/types";

import { DateInput, DateInputValue } from "../DateInput/DateInput";
import {
    BasicSearchState,
    FIELDS_ID,
    FORM_ACTION,
    FORM_ID,
    FORM_LABELS,
    FormAction,
    INIT_STATE,
    TEST_ID,
    sanitise,
} from "../PatientSearchForm.helpers";
import {
    BasicSearchError,
    BasicSearchSchema,
    formatErrors,
} from "../PatientSearchFormSchema";

export type BasicSearchProps = {
    formData: BasicSearchState;
    formAction: React.Dispatch<FormAction<BasicSearchState>>;
    onSubmit: (data: PatientSearchResult[]) => void;
    onError: (error: Error) => void;
    onValidationError: (errorReason: string[]) => void;
    onClear: () => void;
    isFormDisabled?: boolean;
    onLoading?: (isLoading: boolean) => void;
    children?: React.ReactChild;
};

export const BasicSearch = forwardRef<HTMLFormElement, BasicSearchProps>(
    (
        {
            formData,
            formAction,
            onSubmit,
            onError,
            onValidationError,
            onClear,
            onLoading,
            isFormDisabled,
            children,
        },
        forwardedRef,
    ) => {
        const { mutate, isLoading } = useBasicPatientSearch({
            onError: (error) => onError(error),
            onSuccess: (data) => onSubmit(data),
        });

        const [fieldErrors, setFieldErrors] = useState<BasicSearchError | null>(
            null,
        );

        const isDirty =
            formData.nhsNumber ||
            formData.dateOfBirth.day ||
            formData.dateOfBirth.month ||
            formData.dateOfBirth.year;

        useEffect(() => {
            onLoading?.(isLoading);
        }, [isLoading, onLoading]);

        const handleNhsNumberChange = (
            e: ChangeEvent<HTMLInputElement>,
        ): void => {
            const nhsNumber = sanitise(e.target.value);
            formAction({ type: FORM_ACTION.UPDATE, payload: { nhsNumber } });
            checkFormIsCleared({
                nhsNumber,
                dateOfBirth: formData.dateOfBirth,
            });
        };

        // Dedicated paste handler for NHS numbers formatted with spaces
        const handleNhsNumberPaste = (
            e: ClipboardEvent<HTMLInputElement>,
        ): void => {
            e.preventDefault();
            const nhsNumber = sanitise(e.clipboardData.getData("Text"));
            formAction({ type: FORM_ACTION.UPDATE, payload: { nhsNumber } });
        };

        const handleDateChange = (
            _: ChangeEvent<HTMLInputElement> | ClipboardEvent<HTMLInputElement>,
            dateOfBirth: DateInputValue,
        ) => {
            formAction({ type: FORM_ACTION.UPDATE, payload: { dateOfBirth } });
            checkFormIsCleared({
                nhsNumber: formData.nhsNumber,
                dateOfBirth,
            });
        };

        const checkFormIsCleared = (formData: {
            nhsNumber: string;
            dateOfBirth: DateInputValue;
        }) => {
            if (JSON.stringify(formData) === JSON.stringify(INIT_STATE.BASIC)) {
                onClear();
            }
        };

        const handleSubmit = (e: FormEvent) => {
            e.preventDefault();
            const result = BasicSearchSchema.safeParse(formData);
            if (result.success) {
                setFieldErrors(null);
                mutate(formData);
            } else {
                const submitErrors = formatErrors<BasicSearchError>(
                    result.error,
                );
                setFieldErrors(submitErrors);
                onValidationError(
                    [submitErrors.nhsNumber, submitErrors.dateOfBirth].filter(
                        (errorReason) => errorReason !== undefined,
                    ) as string[], // Despite filtering TS still consider this to be Array<string | undefined>
                );
            }
        };

        const handleClear = () => {
            setFieldErrors(null);
            formAction({ type: FORM_ACTION.UPDATE, payload: INIT_STATE.BASIC });
            onClear();
        };

        return (
            <form
                ref={forwardedRef}
                onSubmit={handleSubmit}
                id={FORM_ID.BASIC}
                data-testid={TEST_ID.BASIC}
                noValidate
            >
                <Ds.Flex flexDirection="column" gap="1.5">
                    <StyledFormFieldWrapper
                        label={FORM_LABELS.nhsNumber}
                        labelProps={{
                            htmlFor: `${FORM_ID.BASIC_TWO_FACTORED}-input-${FIELDS_ID.NHS_NUMBER}`,
                        }}
                        errors={[fieldErrors?.nhsNumber ?? null]}
                    >
                        <Input
                            autoFocus
                            id={`${FORM_ID.BASIC_TWO_FACTORED}-input-${FIELDS_ID.NHS_NUMBER}`}
                            value={formData.nhsNumber}
                            type="text"
                            inputMode="numeric"
                            pattern="[0-9]*"
                            minLength={10}
                            maxLength={10}
                            required
                            onChange={handleNhsNumberChange}
                            onPaste={handleNhsNumberPaste}
                            disabled={isFormDisabled}
                            data-userflow-id="patient-search-nhs-number-input"
                            hasErrors={Boolean(fieldErrors?.nhsNumber)}
                        />
                    </StyledFormFieldWrapper>
                    <DateInput
                        label={FORM_LABELS.date}
                        onChange={handleDateChange}
                        value={formData.dateOfBirth}
                        disabled={isFormDisabled}
                        error={fieldErrors?.dateOfBirth}
                    />
                    <Ds.Flex
                        justifyContent={children ? "space-between" : "flex-end"}
                    >
                        {children}
                        <Ds.Flex justifyContent="flex-end">
                            <Ds.Flex gap="1">
                                {isDirty && (
                                    <Button
                                        type="button"
                                        text="Clear"
                                        theme="transparent"
                                        onClick={handleClear}
                                    />
                                )}
                                <Button
                                    type="submit"
                                    aria-label="Search for a patient"
                                    icon={{
                                        name: "Search",
                                        title: "Search for a patient",
                                        id: "search-btn",
                                    }}
                                    disabled={isFormDisabled}
                                />
                            </Ds.Flex>
                        </Ds.Flex>
                    </Ds.Flex>
                </Ds.Flex>
            </form>
        );
    },
);
