import React, { useEffect, useState } from "react";

import { useCurrentWorkspace } from "@accurx/auth";
import { Card, Feedback, Icon, Spinner, Text, Tokens } from "@accurx/design";
import {
    DateFormatOptions,
    DateHelpers,
    IWrappedResult,
    Log,
    WrappedResult,
} from "@accurx/shared";
import { formatNhsNumber } from "@accurx/shared/dist/NhsNumberHelper";
import { shallowEqual, useSelector } from "react-redux";
import { generatePath, useHistory } from "react-router-dom";

import ChainApiClient from "api/VaccineApiHelper";
import { ChainAnalyticsTracker } from "app/analytics";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { StepsFooter } from "app/sharedComponents/footer/StepsFooter";
import { UpdatingStatus } from "shared/LoadingStatus";
import { OrganisationHelper } from "shared/OrganisationHelper";
import { ROUTES_WORKSPACE } from "shared/Routes";
import { useAppSelector } from "store/hooks";

import { MaxBatchSMSSize } from "../Vaccine.type";
import {
    apptLinkSignaturePreviewCopy,
    boosterCopy,
    boosterInviteMessage,
    calculateMessageLength,
    characterCountWarning,
    characterLimitBreachedError,
    patientGreetingCopy,
    practiceSignature,
    reminderMessage,
    seasonalBoosterInviteMessage,
    secondInviteMessage,
} from "../VaccineCopy";
import {
    VaccineCourse,
    VaccineInviteAction,
    VaccineInviteActionData,
    VaccinePatientInvited,
} from "../models/VaccineDeliveryDTO";
import { VaccineDeliveryHeader } from "../shared/VaccineDeliveryComponents";
import {
    BulkActionContext,
    VaccineBulkActions,
} from "../vaccineBulkActions/VaccineBulkActions.component";
import { initialState as VaccinesDeliveryInitialState } from "../vaccineInvitesOldPage/vaccineDelivery.reducer";
import { StyledTextArea } from "./VaccineSmsPreview.styles";

// Known eslint issue for enums
// eslint-disable-next-line @typescript-eslint/no-unused-vars
enum BulkReminderStage {
    Writing,
    Loading,
    Complete,
}

// Known eslint issue for enums
// eslint-disable-next-line @typescript-eslint/no-unused-vars
enum ComponentMode {
    None,
    ReminderSingle,
    ReminderBulk,
    SecondBookingSingle,
    SecondBookingBulk,
    BoosterBookingSingle,
    BoosterBookingBulk,
}

// This component is used for both Reminders and Invites to Second Booking
export const VaccineSmsPreview = (): JSX.Element => {
    const history = useHistory();
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    const { orgId } = useCurrentWorkspace();
    const selectedPractice = orgId.toString();

    const { inviteActionData } = useSelector(
        ({ vaccineDelivery }: ApplicationState) =>
            vaccineDelivery || VaccinesDeliveryInitialState,
    );

    const practices = useSelector(
        ({ practices }: ApplicationState) => practices,
        shallowEqual,
    );

    const practiceName = useAppSelector(({ account }) =>
        OrganisationHelper.getOrganisationName(account),
    );

    const vaccineCourse = useSelector(
        ({ vaccineDelivery }: ApplicationState) =>
            vaccineDelivery?.vaccineCourse ||
            VaccinesDeliveryInitialState.vaccineCourse,
        shallowEqual,
    );
    const boosterLabel = boosterCopy(vaccineCourse);

    useEffect(() => {
        if (inviteActionData.invites.length < 1) {
            // Need to have at least 1 invite to do a reminder on
            handleBackToInviteList();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inviteActionData.invites.length]);

    const [canSendReminder, setCanSendReminder] = useState<boolean>(false);
    const [canSendReminderStatus, setCanSendReminderStatus] =
        useState<UpdatingStatus>(UpdatingStatus.Initial);

    const [messageSignature, setMessageSignature] = useState<string>(
        practiceSignature(practiceName),
    ); // signature (end of SMS)
    const [characterCount, setCharacterCount] = useState<number>(0);
    const [sendSmsStatus, setSendSmsStatus] = useState<UpdatingStatus>(
        UpdatingStatus.Initial,
    );

    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [bulkReminderStage, setBulkReminderStage] =
        useState<BulkReminderStage>(BulkReminderStage.Writing);

    const generateComponentMode = (
        inviteActionData: VaccineInviteActionData,
    ): ComponentMode => {
        switch (inviteActionData.action) {
            case VaccineInviteAction.InviteToSecondBooking:
                return inviteActionData.invites.length > 1
                    ? ComponentMode.SecondBookingBulk
                    : ComponentMode.SecondBookingSingle;
            case VaccineInviteAction.Reminder:
                return inviteActionData.invites.length > 1
                    ? ComponentMode.ReminderBulk
                    : ComponentMode.ReminderSingle;
            case VaccineInviteAction.InviteToBoosterBooking:
                return inviteActionData.invites.length > 1
                    ? ComponentMode.BoosterBookingBulk
                    : ComponentMode.BoosterBookingSingle;
            default:
                return ComponentMode.None;
        }
    };

    const componentMode = generateComponentMode(inviteActionData);

    const isSecondBookingInvite =
        inviteActionData.action === VaccineInviteAction.InviteToSecondBooking;
    const isBooster =
        inviteActionData.action === VaccineInviteAction.InviteToBoosterBooking;
    const isSmsBodyEditable = isBooster; // Separate variable for easier extending in the future
    const isBulkAction = inviteActionData.invites.length > 1;
    const invites = inviteActionData.invites;

    const defaultMessageTemplate: string = (() => {
        if (
            vaccineCourse === VaccineCourse.BoosterAutumn2022 ||
            vaccineCourse === VaccineCourse.BoosterSpring2022 ||
            vaccineCourse === VaccineCourse.BoosterSpring2023 ||
            vaccineCourse === VaccineCourse.BoosterAutumn2023 ||
            vaccineCourse === VaccineCourse.BoosterSpring2024 ||
            vaccineCourse === VaccineCourse.BoosterAutumn2024
        ) {
            return seasonalBoosterInviteMessage(boosterLabel);
        } else if (isBooster) {
            return boosterInviteMessage;
        } else if (isSecondBookingInvite) {
            return secondInviteMessage;
        }
        return reminderMessage;
    })();

    const [messageTemplate, setMessageTemplate] = useState(
        defaultMessageTemplate,
    ); // just for boosters, you can also edit the middle of the SMS

    useEffect(() => {
        if (
            canSendReminderStatus === UpdatingStatus.Initial &&
            componentMode === ComponentMode.ReminderSingle
        ) {
            // Only do this check if in single flow for reminders
            checkCanSendReminder(invites[0]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [canSendReminderStatus, invites, componentMode]);

    useEffect(() => {
        setCharacterCount(
            calculateMessageLength(
                `${patientGreetingCopy}\n\n${messageTemplate}\n${apptLinkSignaturePreviewCopy}\n\n${messageSignature}`, // mock the assembled SMS
                0,
            ),
        );
    }, [messageSignature, practiceName, messageTemplate]);

    const updateMessageTemplate = (
        event: React.ChangeEvent<HTMLTextAreaElement>,
    ): void => {
        const messageTemplate = event.target.value;
        setMessageTemplate(messageTemplate);
    };

    const updateMessageSignature = (
        event: React.ChangeEvent<HTMLTextAreaElement>,
    ): void => {
        const messageSignature = event.target.value;
        setMessageSignature(messageSignature);
    };

    const handleBackToInviteList = (): void => {
        history.push(
            generatePath(ROUTES_WORKSPACE.accubookManagePatients, {
                workspaceId: selectedPractice,
            }),
        );
    };

    const checkCanSendReminder = async (
        patient: VaccinePatientInvited,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ): Promise<any> => {
        setCanSendReminderStatus(UpdatingStatus.Loading);
        const resp = await ChainApiClient.checkCanSendInviteReminder(
            selectedPractice,
            patient.inviteBatchId,
            patient.inviteId,
        );
        if (resp.success && resp.result?.success) {
            setCanSendReminder(true);
        } else {
            setErrorMessage(
                resp.result?.error ??
                    resp.error ??
                    "Please go back and try again",
            );
        }
        setCanSendReminderStatus(UpdatingStatus.Loaded);
    };

    const sendSms = async (
        patient: VaccinePatientInvited,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ): Promise<IWrappedResult<any>> => {
        switch (componentMode) {
            case ComponentMode.BoosterBookingSingle:
            case ComponentMode.BoosterBookingBulk:
                ChainAnalyticsTracker.trackVaccineInviteToBoosterBooking(
                    analyticsLoggedInProps,
                );
                return await ChainApiClient.sendInviteToBoosterBooking(
                    selectedPractice,
                    patient.inviteBatchId,
                    patient.inviteId,
                    {
                        messageTemplate: messageTemplate,
                        customSmsSignature: messageSignature,
                    },
                );
            case ComponentMode.SecondBookingSingle:
            case ComponentMode.SecondBookingBulk:
                ChainAnalyticsTracker.trackVaccineInviteToSecondBooking(
                    analyticsLoggedInProps,
                );
                return await ChainApiClient.sendInviteToSecondBooking(
                    selectedPractice,
                    patient.inviteBatchId,
                    patient.inviteId,
                    { customSmsSignature: messageSignature },
                );
            case ComponentMode.ReminderSingle:
            case ComponentMode.ReminderBulk:
                ChainAnalyticsTracker.trackVaccineSendInviteReminder(
                    analyticsLoggedInProps,
                );
                return await ChainApiClient.sendInviteReminder(
                    selectedPractice,
                    patient.inviteBatchId,
                    patient.inviteId,
                    { customSmsSignature: messageSignature },
                );
            default:
                Log.error(
                    `Trying to send a reminder/invite SMS but in the incorrect component mode - ${componentMode}`,
                );
                return WrappedResult.Fail();
        }
    };

    const handleSendSms = async (
        patient: VaccinePatientInvited,
    ): Promise<void> => {
        setSendSmsStatus(UpdatingStatus.Loading);
        const result = await sendSms(patient);
        setSendSmsStatus(UpdatingStatus.Loaded);
        if (result.success) {
            handleBackToInviteList();
        } else {
            setErrorMessage(result.error ?? "Please go back and try again");
        }
    };

    const handleSendSmsButton = (): void => {
        if (isBulkAction) {
            setBulkReminderStage(BulkReminderStage.Loading);
        } else {
            handleSendSms(invites[0]);
        }
    };

    const promiseForBulkSendReminder = async (
        patient: VaccinePatientInvited,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ): Promise<IWrappedResult<any>> => {
        const check = await ChainApiClient.checkCanSendInviteReminder(
            selectedPractice,
            patient.inviteBatchId,
            patient.inviteId,
        );
        if (!check.success || !check.result?.success) {
            return WrappedResult.Fail(check.result?.error ?? check.error);
        }

        return await ChainApiClient.sendInviteReminder(
            selectedPractice,
            patient.inviteBatchId,
            patient.inviteId,
            { customSmsSignature: messageSignature },
        );
    };

    const promiseForBulkSendSecondBookingInvite = (
        patient: VaccinePatientInvited,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ): Promise<IWrappedResult<any>> => {
        return ChainApiClient.sendInviteToSecondBooking(
            selectedPractice,
            patient.inviteBatchId,
            patient.inviteId,
            { customSmsSignature: messageSignature },
        );
    };

    const promiseForBulkSendBoosterBookingInvite = (
        patient: VaccinePatientInvited,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ): Promise<IWrappedResult<any>> => {
        return ChainApiClient.sendInviteToBoosterBooking(
            selectedPractice,
            patient.inviteBatchId,
            patient.inviteId,
            {
                messageTemplate: messageTemplate,
                customSmsSignature: messageSignature,
            },
        );
    };

    const generateHeader = (componentMode: ComponentMode): string => {
        switch (componentMode) {
            case ComponentMode.ReminderSingle:
                return "Send a reminder";
            case ComponentMode.SecondBookingSingle:
                return invites[0].sendMessages
                    ? "Send second invitation"
                    : "Invite to 2nd vaccination";
            case ComponentMode.BoosterBookingSingle:
                return invites[0].sendMessages
                    ? `Send ${boosterLabel} invitation`
                    : `Invite to ${boosterLabel} vaccination`;
            case ComponentMode.ReminderBulk:
                return "Send " + invites.length + " reminders";
            case ComponentMode.SecondBookingBulk:
                return "Send " + invites.length + " second invitations";
            case ComponentMode.BoosterBookingBulk:
                return (
                    "Send " + invites.length + ` ${boosterLabel} invitations`
                );
            default:
                return "Send messages";
        }
    };

    const generateSendButtonCopy = (componentMode: ComponentMode): string => {
        switch (componentMode) {
            case ComponentMode.ReminderSingle:
            case ComponentMode.ReminderBulk:
            case ComponentMode.SecondBookingBulk:
            case ComponentMode.BoosterBookingBulk:
                return "Send";
            case ComponentMode.SecondBookingSingle:
                return invites[0].sendMessages
                    ? "Send"
                    : "Invite to 2nd vaccination";
            case ComponentMode.BoosterBookingSingle:
                return invites[0].sendMessages
                    ? "Send"
                    : `Invite to ${boosterLabel} vaccination`;
            default:
                return "Send";
        }
    };

    const renderSingleInviteInfo = (
        invite: VaccinePatientInvited,
    ): JSX.Element => {
        return (
            <>
                <Card spacing={1.5} props={{ className: "mb-2 w-100" }}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <Text
                            variant="label"
                            props={{
                                className: "mx-3",
                                "data-testid": "nameColumn",
                            }}
                        >
                            {invite.patientName}
                        </Text>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                flexGrow: 1,
                                justifyContent: "flex-end",
                            }}
                        >
                            <Text
                                props={{
                                    className: "mx-2",
                                    "data-testid": "nameColumn",
                                }}
                            >
                                NHS:{" "}
                                {formatNhsNumber(
                                    invite.patientExternalIdentity
                                        .patientExternalIds[0].value,
                                )}
                            </Text>
                            <Text
                                props={{
                                    className: "mx-2",
                                    "data-testid": "nameColumn",
                                }}
                            >
                                Born:{" "}
                                {DateHelpers.formatDate(
                                    invite.dateOfBirth,
                                    DateFormatOptions.DATE_SHORT_WITH_SLASH,
                                )}
                            </Text>
                        </div>
                    </div>
                </Card>
            </>
        );
    };

    const renderSmsPreview = (): JSX.Element => {
        return (
            <div className="mt-4 w-100">
                <Text variant="label">Message</Text>
                <StyledTextArea
                    className="w-100 p-3"
                    style={{
                        color: Tokens.COLOURS.greyscale.stone,
                        borderWidth: "1px 1px 0px 1px",
                    }}
                    disabled
                    value={patientGreetingCopy}
                    data-testid="greeting"
                    minRows={1}
                />
                <StyledTextArea
                    className="w-100 p-3"
                    style={{
                        borderWidth: "0px 1px 0px 1px",
                        color: `${
                            isSmsBodyEditable
                                ? "inherit"
                                : Tokens.COLOURS.greyscale.stone
                        }`,
                    }}
                    disabled={isSmsBodyEditable ? false : true}
                    value={messageTemplate}
                    onChange={updateMessageTemplate}
                    data-testid="template"
                    minRows={1}
                />
                <StyledTextArea
                    className="w-100 p-3"
                    style={{
                        color: Tokens.COLOURS.greyscale.stone,
                        // will be first textarea on primary so need the top border
                        borderWidth: "0px 1px 0px 1px",
                    }}
                    disabled
                    value={apptLinkSignaturePreviewCopy}
                    data-testid="apptLink"
                />
                <StyledTextArea
                    className="w-100 p-3"
                    style={{
                        borderWidth: "0px 1px 1px 1px",
                    }}
                    value={messageSignature}
                    onChange={updateMessageSignature}
                    data-testid="signature"
                />

                <div
                    style={{
                        paddingRight: "1rem",
                        display: "flex",
                        alignSelf: "flex-end",
                    }}
                    className="mt-1"
                >
                    <Icon
                        theme="Fill"
                        name="Warning"
                        size={3}
                        colour="orange"
                    />
                    <Text
                        as="span"
                        variant="preview"
                        props={{
                            style: { color: errorColour },
                        }}
                    >
                        {characterLimitBreached
                            ? characterLimitBreachedError
                            : characterCountWarning}
                    </Text>
                    <Text
                        as="span"
                        variant="preview"
                        props={{
                            className: "ml-1",
                            "data-testid": "characterCount",
                            style: { color: errorColour },
                        }}
                    >
                        {characterCount}/{MaxBatchSMSSize}
                    </Text>
                </div>
            </div>
        );
    };

    const renderWritingStage = (): JSX.Element => {
        // We only check if we can send a reminder for a single reminder
        if (
            componentMode === ComponentMode.ReminderSingle &&
            canSendReminderStatus === UpdatingStatus.Loading
        ) {
            return <Spinner />;
        }

        // For a single reminder we would check if a reminder can be sent
        // For single second/booster invites we need the patients to be textable
        // For bulk invites we always show the textarea
        const canSendSms =
            canSendReminderStatus === UpdatingStatus.Loaded ||
            (componentMode === ComponentMode.SecondBookingSingle &&
                invites[0].sendMessages) ||
            (componentMode === ComponentMode.BoosterBookingSingle &&
                invites[0].sendMessages) ||
            componentMode === ComponentMode.SecondBookingBulk ||
            componentMode === ComponentMode.BoosterBookingBulk ||
            componentMode === ComponentMode.ReminderBulk;

        return (
            <>
                {canSendSms && renderSmsPreview()}
                <StepsFooter
                    backText="Back"
                    backClickFunction={handleBackToInviteList}
                    forwardText={
                        sendSmsStatus === UpdatingStatus.Loading
                            ? "Sending.."
                            : sendButtonCopy
                    }
                    forwardClickFunction={handleSendSmsButton}
                    disabled={
                        (componentMode === ComponentMode.ReminderSingle &&
                            !canSendReminder) ||
                        sendSmsStatus === UpdatingStatus.Loading ||
                        characterLimitBreached
                    }
                />
            </>
        );
    };

    // #region Render bulk action

    const renderBulkReminder = (): JSX.Element => {
        const successListSubtitle =
            "These patients have been sent SMS reminders to book their vaccination.";
        const failedListSubtitle =
            "We couldn’t send these patients a reminder as they’ve received one in the last 24 hours.";

        return (
            <VaccineBulkActions
                practices={practices}
                practiceId={selectedPractice}
                context={BulkActionContext.SendAReminder}
                invites={invites}
                processFn={promiseForBulkSendReminder}
                onBackToInviteList={handleBackToInviteList}
                successListTitle="Reminders sent"
                successListSubTitle={successListSubtitle}
                failedListTitle="Failed"
                failedListSubTitle={failedListSubtitle}
            />
        );
    };

    const renderBulkSecondInvite = (): JSX.Element => {
        const successListSubtitle =
            "These patients have been sent an SMS to book their second vaccination.";
        const failedListSubtitle =
            "There was a problem inviting these patients. Please try to invite them again.";
        const thirdOptionListSubTitle =
            "Patients who cannot be sent an SMS invite, and need to manually be booked in";
        const thirdOptionSuccessResultMapFn = (
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            result: IWrappedResult<any>,
        ): boolean => result.result && !result.result.smsSent;

        return (
            <VaccineBulkActions
                practices={practices}
                practiceId={selectedPractice}
                context={BulkActionContext.InviteToSecond}
                invites={invites}
                processFn={promiseForBulkSendSecondBookingInvite}
                onBackToInviteList={handleBackToInviteList}
                successListTitle="Second invitations sent"
                successListSubTitle={successListSubtitle}
                failedListTitle="Failed"
                failedListSubTitle={failedListSubtitle}
                thirdOptionListTitle="To manually book"
                thirdOptionListSubTitle={thirdOptionListSubTitle}
                thirdOptionSuccessResultMapFn={thirdOptionSuccessResultMapFn}
            />
        );
    };

    const renderBulkBoosterInvite = (): JSX.Element => {
        const successListSubtitle = `These patients have been sent an SMS to book their ${boosterLabel} vaccination.`;
        const failedListSubtitle =
            "There was a problem inviting these patients. Please try to invite them again.";
        const thirdOptionListSubTitle =
            "Patients who cannot be sent an SMS invite, and need to manually be booked in";
        const thirdOptionSuccessResultMapFn = (
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            result: IWrappedResult<any>,
        ): boolean => result.result && !result.result.smsSent;

        return (
            <VaccineBulkActions
                practices={practices}
                practiceId={selectedPractice}
                context={BulkActionContext.InviteToBooster}
                invites={invites}
                processFn={promiseForBulkSendBoosterBookingInvite}
                onBackToInviteList={handleBackToInviteList}
                successListTitle="Booster invitations sent"
                successListSubTitle={successListSubtitle}
                failedListTitle="Failed"
                failedListSubTitle={failedListSubtitle}
                thirdOptionListTitle="To manually book"
                thirdOptionListSubTitle={thirdOptionListSubTitle}
                thirdOptionSuccessResultMapFn={thirdOptionSuccessResultMapFn}
            />
        );
    };

    const renderBulkAction = (): JSX.Element => {
        switch (componentMode) {
            case ComponentMode.BoosterBookingBulk:
                return renderBulkBoosterInvite();
            case ComponentMode.SecondBookingBulk:
                return renderBulkSecondInvite();
            default:
                return renderBulkReminder();
        }
    };

    // #endregion Render bulk action

    const characterLimitBreached = characterCount > MaxBatchSMSSize;
    const errorColour = characterLimitBreached
        ? Tokens.COLOURS.primary.red["130"]
        : Tokens.COLOURS.greyscale.night;
    const header = generateHeader(componentMode);
    const sendButtonCopy = generateSendButtonCopy(componentMode);

    return (
        <div
            style={{
                display: "flex",
                flexFlow: "column",
                alignItems: "center",
                marginTop: "1rem",
                marginBottom: "6rem",
            }}
        >
            <div
                style={{
                    width: "60%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <VaccineDeliveryHeader title={header} />
                {[
                    ComponentMode.ReminderSingle,
                    ComponentMode.SecondBookingSingle,
                    ComponentMode.BoosterBookingSingle,
                ].includes(componentMode) &&
                    invites[0] /* Single action flow */ &&
                    renderSingleInviteInfo(invites[0])}
                {errorMessage && (
                    <Feedback
                        props={{
                            style: { whiteSpace: "pre-line", width: "70%" },
                        }}
                        colour="error"
                        title="Something went wrong"
                        content={errorMessage}
                    />
                )}
                {(componentMode === ComponentMode.SecondBookingSingle ||
                    componentMode === ComponentMode.BoosterBookingSingle) &&
                    !invites[0].sendMessages && (
                        <Text>
                            The patient you have selected does not have a mobile
                            number to receive an SMS invite. You will need to
                            book them in manually after activating their invite.
                        </Text>
                    )}

                {bulkReminderStage ===
                BulkReminderStage.Writing /* This is the default so also includes single reminder flow */
                    ? renderWritingStage()
                    : renderBulkAction()}
            </div>
        </div>
    );
};

export default VaccineSmsPreview;
