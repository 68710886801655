import { ExternalEmailContact } from "@accurx/api/clinician-messaging";
import { Ds, Feedback } from "@accurx/design";

import {
    ContactListSkeleton,
    SkeletonText,
} from "../ContactSelectCard/ContactSelectCardSkeleton";
import { GroupedContactList } from "../GroupedContactList/GroupedContactList";

type SearchResultsProps = {
    contacts: ExternalEmailContact[];
    isLoading: boolean;
};

export const SearchResults = ({ contacts, isLoading }: SearchResultsProps) => {
    return isLoading ? (
        <>
            <Ds.Box mb="3">
                <SkeletonText width="210px" />
            </Ds.Box>
            <ContactListSkeleton
                count={6}
                showGroupHeadings={true}
                role="status"
            />
        </>
    ) : (
        <>
            <Ds.Box mb="2">
                <Ds.Text as="h2" weight="bold" color="zinc">
                    {contacts.length} results sorted by distance
                </Ds.Text>
            </Ds.Box>
            {contacts.length ? (
                <GroupedContactList contacts={contacts} />
            ) : (
                <Feedback colour="warning" title="No results found">
                    Try searching again with different details
                </Feedback>
            )}
        </>
    );
};
