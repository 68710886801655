import { ReactNode, useEffect } from "react";

import { LoggedInOrOutUser, useAuth } from "@accurx/auth";
import { ComposeRoutes } from "@accurx/compose";
import { ConciergeProvider } from "@accurx/concierge";
import { TooltipProvider } from "@accurx/design";
import { App as Inbox } from "@accurx/inbox";
import { getEmbedMode } from "@accurx/native";
import { ToastContainer } from "@accurx/navigation";
import { QuickViewProvider } from "@accurx/quick-view";
import { SelfbookRoutes } from "@accurx/self-book";
import { Log } from "@accurx/shared";
import { GlobalAdminPendingRequestsModal } from "@accurx/workspace-management";
import { GlobalStyleManager } from "GlobalStyleManager";
import { NoNavigationLayout } from "layouts/UnifiedLayout/UnifiedLayout";
import { Redirect, Switch, useHistory, useRouteMatch } from "react-router-dom";
import { useSelectedOrganisation } from "reduxQuarantine/SelectedOrganisationProvider";
import { useFeatureFlag } from "reduxQuarantine/useFeatureFlag";

import AccurxWeb from "app/App";
import { SimpleRoute } from "app/AppRoute";
import { TrustWebInboxProviders } from "app/TrustWebInboxProviders";
import { ComposeNewMessageProvider } from "app/context/ComposeNewMessageProvider";
import { GuardedRoute } from "app/guardedRoutes/GuardedRoute";
import { DevToolbar } from "app/layout/devToolbar/DevToolbar";
import { withLoginCheck } from "app/withLoginCheck";
import { GlobalArchivedWorkspaceModal } from "app/workspace/components/GlobalArchivedWorkspaceModal/GlobalArchivedWorkspaceModal";
import { GlobalMyWorkspaceModal } from "app/workspace/components/GlobalMyWorkspaceModal/GlobalMyWorkspaceModal";

import {
    DEPRECATED_COMPOSE_ROUTE,
    ROUTES_COMPOSE,
} from "./domains/compose/pages/Routes.constants";
import { ROUTES, ROUTES_CHAIN, ROUTES_ORGS } from "./shared/Routes";
import { useWorkspaceValidator } from "./shared/useWorkspaceValidator";

/**
 * Horrible, but until we pull logged out pages out of the tree
 * this feels like the best compromise
 */
const OptionalConciergeProvider = ({
    children,
    workspaceId,
    user,
    isTrustedDevice,
    conciergeType,
}: {
    user: LoggedInOrOutUser;
    workspaceId?: number | null;
    isTrustedDevice: boolean;
    conciergeType: "Full" | "Lite";
    children: ReactNode;
}) => {
    const isValidWorkspace = useWorkspaceValidator();
    const enabled = useFeatureFlag("AccurxWebEmbeddedUnifiedInbox");
    const { embedMode } = getEmbedMode();
    const hasClinicianMessagingInboxFlag = useFeatureFlag(
        "UnifiedInboxAccumailSandboxBuild",
    );
    const hasDebugFeatureFlag = useFeatureFlag("UnifiedInboxDebugMode");

    if (!user.isLoggedIn || !workspaceId || !isValidWorkspace)
        return <>{children}</>;

    return (
        <ConciergeProvider
            userId={user.accuRxUserId}
            workspaceId={workspaceId}
            isTrustedDevice={
                isTrustedDevice &&
                /* TODO remove this once the inbox is fully live
                 * this is a temporary hack to avoid us
                 * spinning up the full concierge layer for
                 * workspaces where the new inbox hasn't been enabled yet
                 * */
                enabled
            }
            withClinicianMessaging={
                hasClinicianMessagingInboxFlag && embedMode === "Web"
            }
            shouldInitialiseUnreadsAndConversationGroups={
                conciergeType === "Full"
            }
            isDebugModeEnabled={hasDebugFeatureFlag}
        >
            {children}
        </ConciergeProvider>
    );
};

const WebApp = ({
    user,
    selectedOrgId,
}: {
    user: LoggedInOrOutUser;
    selectedOrgId: number | null;
}) => {
    const isValidWorkspace = useWorkspaceValidator();
    const isManageOrg = useRouteMatch({
        path: [ROUTES_ORGS.root, ROUTES_CHAIN.practices],
    });

    if (!isValidWorkspace) {
        return (
            <Redirect to={isManageOrg ? ROUTES_CHAIN.practices : ROUTES.home} />
        );
    }

    return (
        <TrustWebInboxProviders
            workspaceId={selectedOrgId}
            is2FAed={user.isLoggedIn ? user.is2FAed : false}
            currentUserId={user.isLoggedIn ? user.accuRxUserId : undefined}
        >
            <GlobalAdminPendingRequestsModal />
            <GlobalMyWorkspaceModal />
            <GlobalArchivedWorkspaceModal />
            <Switch>
                <GuardedRoute
                    path={["/inbox/*", "/inbox"]}
                    requires={{
                        authenticationStatus: "LoggedInWithTrustedDevice",
                        onboarded: true,
                    }}
                >
                    <ComposeNewMessageProvider>
                        <Inbox />
                    </ComposeNewMessageProvider>
                </GuardedRoute>
                <SimpleRoute
                    path={[
                        "/w/:workspaceId/self-book/*",
                        "/w/:workspaceId/self-book",
                    ]}
                >
                    <SelfbookRoutes />
                </SimpleRoute>
                <SimpleRoute path="*">
                    <GlobalStyleManager>
                        <AccurxWeb />
                    </GlobalStyleManager>
                </SimpleRoute>
            </Switch>
        </TrustWebInboxProviders>
    );
};

export const BaseApp = withLoginCheck(() => {
    const history = useHistory();
    const { user } = useAuth();
    const { selectedOrgId } = useSelectedOrganisation();
    const { embedMode } = getEmbedMode();

    const isDevEnvironment =
        window.location.host.includes("localhost") ||
        window.location.host.includes(".dev.accurx.com");

    useEffect(() => {
        // track usage of Ctrl/Cmd+F to see if and where people use it
        const ctrlF = (e: KeyboardEvent) => {
            if ((e.ctrlKey || e.metaKey) && e.keyCode === 70) {
                const tags = {
                    path: history.location.pathname,
                    querystring: history.location.search,
                    organisation: selectedOrgId,
                    user: "accuRxUserId" in user ? user.accuRxUserId : "",
                    isInWebView: embedMode === "Desktop",
                };
                Log.info("User triggered browsers Find (Ctrl/Cmd+F)", { tags });
            }
        };

        window.addEventListener("keydown", ctrlF);
        return () => window.removeEventListener("keydown", ctrlF);
    }, [history, user, selectedOrgId, embedMode]);

    return (
        <TooltipProvider>
            <QuickViewProvider>
                <ToastContainer enableMultiContainer />
                {/* If the user is coming from WebView2, we only allow them to visit the inbox, and not the rest of Accurx Web. In these cases, guarding against
             login and 2FA statuses are handled by the inbox itself. Otherwise, they are coming from a browser and they can use the whole app.
             */}
                {embedMode === "Desktop" ? (
                    <Switch>
                        <SimpleRoute path={["/inbox/*", "/inbox"]}>
                            <OptionalConciergeProvider
                                user={user}
                                workspaceId={selectedOrgId}
                                isTrustedDevice={user.isTrustedDevice}
                                conciergeType="Full"
                            >
                                <ComposeNewMessageProvider>
                                    <Inbox />
                                </ComposeNewMessageProvider>
                            </OptionalConciergeProvider>
                        </SimpleRoute>
                        <SimpleRoute
                            path={[
                                "/w/:workspaceId/self-book/*",
                                "/w/:workspaceId/self-book",
                            ]}
                        >
                            <SelfbookRoutes />
                        </SimpleRoute>
                        <SimpleRoute
                            path={[
                                ROUTES_COMPOSE.compose,
                                DEPRECATED_COMPOSE_ROUTE,
                            ]}
                        >
                            <OptionalConciergeProvider
                                user={user}
                                workspaceId={selectedOrgId}
                                isTrustedDevice={user.isTrustedDevice}
                                conciergeType="Lite"
                            >
                                <NoNavigationLayout>
                                    <ComposeRoutes />
                                </NoNavigationLayout>
                            </OptionalConciergeProvider>
                        </SimpleRoute>
                    </Switch>
                ) : (
                    <OptionalConciergeProvider
                        user={user}
                        workspaceId={selectedOrgId}
                        isTrustedDevice={user.isTrustedDevice}
                        conciergeType="Full"
                    >
                        <WebApp user={user} selectedOrgId={selectedOrgId} />
                    </OptionalConciergeProvider>
                )}
                {isDevEnvironment && <DevToolbar />}
            </QuickViewProvider>
        </TooltipProvider>
    );
});
