import {
    AppointmentCancellationSupport,
    AppointmentChangeDeadline,
    AppointmentRebookingSupport,
} from "@accurx/api/appointment";
import {
    Card,
    CollapsibleInfo,
    Ds,
    FormFieldWrapper,
    Option,
    Select,
} from "@accurx/design";

export type AppointmentChangesProps = {
    changeDeadline: AppointmentChangeDeadline;
    cancellationSupport: AppointmentCancellationSupport;
    rebookingSupport: AppointmentRebookingSupport;
    onDeadlineChange: (
        selectedChangeDeadline: AppointmentChangeDeadline,
    ) => void;
    onCancellationSupportChange: (
        cancellationSupport: AppointmentCancellationSupport,
    ) => void;
    onRebookingSupportChange: (
        rebookingSupport: AppointmentRebookingSupport,
    ) => void;
    /** Determines whether to show the options to change amendment config */
    is1WayOrg: boolean;
    errors?: string[];
};

export const AppointmentChanges = (props: AppointmentChangesProps) => {
    const {
        changeDeadline,
        cancellationSupport,
        rebookingSupport,
        onDeadlineChange,
        onCancellationSupportChange,
        onRebookingSupportChange,
        is1WayOrg,
        errors,
    } = props;

    const onDeadlineChangeHandler = (option: Option) => {
        onDeadlineChange(option.value as AppointmentChangeDeadline);
    };

    const DeadlineOptions: Option[] = [
        {
            label: "1 hour before appointment",
            value: AppointmentChangeDeadline.OneHour,
        },
        {
            label: "1 working day before appointment",
            value: AppointmentChangeDeadline.OneWorkingDay,
        },
        {
            label: "3 working days before appointment",
            value: AppointmentChangeDeadline.ThreeWorkingDays,
        },
    ];

    const selectedDeadline =
        DeadlineOptions.find(
            (option) =>
                (option.value as AppointmentChangeDeadline) === changeDeadline,
        ) || DeadlineOptions[0];

    return (
        <Card
            spacing={3}
            header={
                <Ds.Text size="large" weight="bold" as="h2">
                    Appointment changes
                </Ds.Text>
            }
        >
            {is1WayOrg ? (
                <Ds.Text>
                    Patients can request to cancel or rebook appointments, which
                    will need to be reviewed manually. All appointment changes
                    can be found in the inbox.
                </Ds.Text>
            ) : (
                <>
                    <Ds.Text weight="bold" mb="1">
                        Cancellation
                    </Ds.Text>
                    <Ds.RadioGroup
                        appearance="ghost"
                        defaultValue={cancellationSupport}
                        onValueChange={(item) =>
                            onCancellationSupportChange(
                                item as AppointmentCancellationSupport,
                            )
                        }
                    >
                        <Ds.Flex flexDirection="column" gap="1">
                            <Ds.RadioGroup.Item
                                value={AppointmentCancellationSupport.Automatic}
                            >
                                <Ds.RadioGroup.Indicator />
                                <Ds.RadioGroup.Label>
                                    Patients can cancel and their appointment
                                    will be removed automatically
                                </Ds.RadioGroup.Label>
                            </Ds.RadioGroup.Item>
                            <Ds.RadioGroup.Item
                                value={AppointmentCancellationSupport.Manual}
                            >
                                <Ds.RadioGroup.Indicator />
                                <Ds.RadioGroup.Label>
                                    Patients can request to cancel
                                </Ds.RadioGroup.Label>
                            </Ds.RadioGroup.Item>
                        </Ds.Flex>
                    </Ds.RadioGroup>

                    <Ds.Text weight="bold" mt="3" mb="1">
                        Rebooking
                    </Ds.Text>
                    <Ds.Checkbox
                        appearance="ghost"
                        defaultChecked={
                            rebookingSupport ===
                            AppointmentRebookingSupport.Manual
                        }
                        onCheckedChange={(canRequestRebook) =>
                            onRebookingSupportChange(
                                canRequestRebook
                                    ? AppointmentRebookingSupport.Manual
                                    : AppointmentRebookingSupport.NotSupported,
                            )
                        }
                    >
                        <Ds.Checkbox.Indicator />
                        <Ds.Checkbox.Label>
                            Patients can request to rebook
                        </Ds.Checkbox.Label>
                    </Ds.Checkbox>

                    <CollapsibleInfo title="How patient requests work">
                        <Ds.Text>
                            Cancellation and rebooking requests need to be
                            reviewed manually. All appointment changes can be
                            found in the inbox.
                        </Ds.Text>
                    </CollapsibleInfo>
                </>
            )}

            <Ds.Box mt="3">
                <FormFieldWrapper label="Deadline" errors={errors}>
                    <Select
                        options={DeadlineOptions}
                        id="appointmentChangeDeadline"
                        error={errors && errors.length > 0}
                        onChangeHandler={(option) =>
                            onDeadlineChangeHandler(option as Option)
                        }
                        initSelectedValues={selectedDeadline}
                    />
                </FormFieldWrapper>
            </Ds.Box>
        </Card>
    );
};
