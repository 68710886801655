import { useEffect } from "react";

import { FeatureName } from "@accurx/auth";
import { createRouterState as createRouterStatePatientForCompose } from "@accurx/compose";
import { Feedback, StackPanel, TabProps, Tabs } from "@accurx/design";
import { useFullScreenNavigate } from "@accurx/navigation";
import { ArchivedWorkspaceHiddenItemWrapper } from "@accurx/workspace-management";
import { shallowEqual } from "react-redux";
import {
    Redirect,
    generatePath,
    useHistory,
    useLocation,
    useParams,
} from "react-router-dom";

import { AnalyticsMapper, FlemingAnalyticsTracker } from "app/analytics";
import { NavSubMenuComponent } from "app/navbar/NavSubMenuComponent";
import PatientInfoSubMenu from "app/navbar/PatientInfoSubMenuComponent";
import {
    TourTestPatient,
    getTourMode,
    isTourActive,
} from "app/onboarding/Onboarding.helper";
import { useSelectedPatient } from "app/patients/hooks";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { OrganisationHelper } from "shared/OrganisationHelper";
import { PatientHelper } from "shared/PatientHelper";
import { ROUTES, ROUTES_EXTENSION, ROUTES_PRIMARY } from "shared/Routes";
import { findBaseRoute, getStringQuery } from "shared/RoutesHelper";
import { useAppSelector, useCurrentUserId } from "store/hooks";

import { GPServicesButton } from "./GPServicesButton";
import { MessagePatientButton } from "./MessagePatientButton";
import {
    isMessageGpDisabled,
    isRecordViewDisabled,
} from "./PatientProfile.helper";
import {
    StyledButtonsContainer,
    StyledPatientInfoMenu,
    StyledTabPanels,
} from "./PatientProfile.styles";
import { TAB_PAGES, TabPageType } from "./PatientProfile.types";
import { VideoConsultButton } from "./VideoConsultButton";
import { DocumentsPanel } from "./tabPanels/DocumentsPanel";
import { OverviewPanel } from "./tabPanels/OverviewPanel";
import { PatientConversationsPanel } from "./tabPanels/PatientConversationsPanel";
import { usePatientProfileTabs } from "./usePatientProfileTabs";

const TOUR_MESSAGES = {
    [TourTestPatient.RecordView]: `Click the "GP Services" button and then click "View GP record"`,
    [TourTestPatient.Sms]: `Click the "Message patient" button`,
    [TourTestPatient.Video]: `Click the "Video Consult" button`,
};

const searchParamIsTabPageType = (
    selectedTab: string | undefined,
    availableTabs: TabProps<TabPageType>[],
): selectedTab is TabPageType => {
    return (
        selectedTab !== undefined &&
        availableTabs.find((tab) => tab.tabId === selectedTab) !== undefined
    );
};

export const PatientProfile = ({
    userAgent,
}: {
    userAgent: string;
}): JSX.Element | null => {
    const tabs = usePatientProfileTabs();

    let { selectedTab } = useParams<{ selectedTab: string }>();
    const { search } = useLocation<
        { showPatientMessageModal: boolean | null | undefined } | undefined
    >();

    const querySelectedTab = getStringQuery(search, "tab");

    if (selectedTab === undefined && querySelectedTab) {
        selectedTab = querySelectedTab;
    }

    const defaultSelectedTab: TabPageType = searchParamIsTabPageType(
        selectedTab,
        tabs,
    )
        ? selectedTab
        : TAB_PAGES.overview;

    const history = useHistory();
    const patient = useSelectedPatient();
    const accuRxUserId = useCurrentUserId() || "";

    const tourMode = getTourMode(accuRxUserId);

    const organisation = useAppSelector(
        ({ account }) => OrganisationHelper.getOrganisation(account),
        shallowEqual,
    );
    const { workspaceId } = useParams<{ workspaceId: string }>();

    const fullScreenNavigate = useFullScreenNavigate();
    const patientResult = useAppSelector(
        ({ searchForPatient }) =>
            PatientHelper.getPatient(searchForPatient.lastResponse),
        shallowEqual,
    );
    const patientResultToken = useAppSelector(({ searchForPatient }) =>
        PatientHelper.getPatientToken(searchForPatient.lastResponse),
    );

    const loggedInProps = useFlemingLoggedInAnalytics();

    const renderTabPanel = (tabId: TabPageType): JSX.Element | null => {
        switch (tabId) {
            case TAB_PAGES.allConversations:
                return (
                    <PatientConversationsPanel
                        conversationGroupType={"workspace"}
                    />
                );

            case TAB_PAGES.myConversations:
                return (
                    <PatientConversationsPanel conversationGroupType={"user"} />
                );

            case TAB_PAGES.documents:
                return <DocumentsPanel />;
            case TAB_PAGES.overview:
            default:
                return <OverviewPanel />;
        }
    };

    useEffect(() => {
        const isRecordViewEnabledForPatient =
            isRecordViewDisabled(patientResult) === null;
        const messagePracticeStatus = () => {
            const isEnabledForOrg = OrganisationHelper.isFeatureEnabled(
                organisation,
                FeatureName.CaregiverInitiated,
            );

            const isEnabledForPatient =
                isMessageGpDisabled(patientResult) === null;
            if (isEnabledForOrg && isEnabledForPatient) {
                return "AvailableForUserAndPatient";
            }
            if (!isEnabledForOrg && !isEnabledForPatient) {
                return "NotAvailableForUserNorPatient";
            }

            return `NotAvailableFor${
                !isEnabledForPatient ? "Patient" : "User"
            }`;
        };

        const analytics = {
            ...loggedInProps,
            messagePracticeStatus: messagePracticeStatus(),
            isRecordViewEnabledForPatient,
        };

        FlemingAnalyticsTracker.trackPatientProfilePageView(analytics);
        // eslint-disable-next-line
    }, [loggedInProps]);

    // Only redirect back to home if there is no patient token available
    // in all other circumstances we expect to be able to render this page
    // N.B. That we use getPatientTokenWithStorageFallback here so that the page
    // will not redirect away when we've searched and put the token into storage,
    // but are still in the process of saving the token to the redux store
    if (!PatientHelper.getPatientTokenWithStorageFallback(null)) {
        return <Redirect to={ROUTES.home} />;
    }

    const getMessagePatientButtonCallback = (): (() => void) => {
        return () => {
            fullScreenNavigate(
                `/w/${workspaceId}/compose`,
                createRouterStatePatientForCompose({
                    patient: patientResult,
                    patientToken: patientResultToken,
                    appOrigin: "PatientProfile",
                }),
            );
        };
    };

    const onTabChange = (tabId: string) => {
        FlemingAnalyticsTracker.trackPatientProfileTabClick({
            ...loggedInProps,
            patientProfileTabName:
                AnalyticsMapper.getAnalyticsPatientProfileTabName(tabId),
        });

        history.replace(
            generatePath(ROUTES_PRIMARY.workspaceBase, {
                workspaceId,
            }) +
                findBaseRoute(history.location.pathname) +
                ROUTES_EXTENSION.patientProfile +
                `/${tabId}`,
        );
    };

    return (
        <>
            <Tabs
                defaultSelected={defaultSelectedTab}
                onTabChange={onTabChange}
            >
                <NavSubMenuComponent
                    contentExtendsToBottomEdge
                    backCallback={history.goBack}
                >
                    <StyledPatientInfoMenu>
                        <PatientInfoSubMenu patient={patient}>
                            <StackPanel gutter={1.5}>
                                {isTourActive(tourMode) && (
                                    <div data-testid="patient-profile-tour">
                                        <Feedback
                                            colour="learning"
                                            title={TOUR_MESSAGES[tourMode]}
                                        />
                                    </div>
                                )}
                                <ArchivedWorkspaceHiddenItemWrapper>
                                    <StyledButtonsContainer>
                                        <MessagePatientButton
                                            actionCallback={getMessagePatientButtonCallback()}
                                        />
                                        <VideoConsultButton
                                            userAgent={userAgent}
                                        />

                                        <GPServicesButton />
                                    </StyledButtonsContainer>
                                </ArchivedWorkspaceHiddenItemWrapper>
                            </StackPanel>
                        </PatientInfoSubMenu>
                    </StyledPatientInfoMenu>
                    <Tabs.TabList tabs={tabs} />
                </NavSubMenuComponent>
                <StyledTabPanels>
                    {tabs.map((tab) => (
                        <Tabs.Panel key={tab.tabId} tabId={tab.tabId}>
                            {renderTabPanel(tab.tabId)}
                        </Tabs.Panel>
                    ))}
                </StyledTabPanels>
            </Tabs>
        </>
    );
};

export default PatientProfile;
