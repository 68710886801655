import {
    PatientDemographicDetails,
    PatientNameUse,
} from "@accurx/api/patient-messaging";
import { PatientDemographics } from "@accurx/native";

import { mapPatientExternalIdsToPatientExternalIdentityDto } from "./mapPatientExternalIdsToPatientExternalIdentityDto";

export const mapPatientDemographicsToPatientDemographicDetailsDto = ({
    patientDemographics,
    demographicMobileNumber,
}: {
    patientDemographics: PatientDemographics;
    demographicMobileNumber: string | null;
}): PatientDemographicDetails => {
    const { patientExternalIds } =
        mapPatientExternalIdsToPatientExternalIdentityDto(
            patientDemographics.patientExternalIdentityDto.patientExternalIds,
        );

    return {
        patientExternalIds,
        demographicMobileNumber,
        names: [
            {
                ...patientDemographics.patientName.nickname,
                use: PatientNameUse.Nickname,
            },
            {
                ...patientDemographics.patientName.usual,
                use: PatientNameUse.Usual,
            },
        ],
        dateOfBirth: patientDemographics.dateOfBirth,
        gender: patientDemographics.gender,
    };
};
