import { composeInitialMessageBody } from "domains/message-component/composeMessage";
import { EMPTY_STATE } from "domains/message-component/constants";
import type { ContactDetail } from "domains/message-component/reducer.types";

export type ComposeProviderSettings = {
    greeting: string;
    editableBody: string;
    editableSignature: string;
    contactDetails?: Partial<ContactDetail>;
    maxAttachmentCount: number;
    canUseQuestionnaires: boolean;
    conversationParticipant: "WithHcp" | "WithPatient";
    templatesComboboxMustOpenDownwards?: boolean;
    /** If true, message-component will try to fetch patient consent from the EMR, if possible */
    shouldTryToFetchConsent?: boolean;
};

export const convertMessageSettingsToMessageState = (
    settings: ComposeProviderSettings,
) => ({
    ...EMPTY_STATE,
    originalGreeting: settings.greeting,
    originalBody: settings.editableBody,
    originalSignature: settings.editableSignature,
    messageBody: composeInitialMessageBody({
        messageBody: settings.editableBody,
        messageGreeting: settings.greeting,
    }),
    messageSignature: settings.editableSignature,
    contactDetails: {
        ...EMPTY_STATE.contactDetails,
        ...settings.contactDetails,
    },
    maxAttachmentCount: settings.maxAttachmentCount,
    errors: EMPTY_STATE.errors,
    canUseQuestionnaires: settings.canUseQuestionnaires,
    conversationParticipant: settings.conversationParticipant,
});
