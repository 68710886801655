import { useCurrentWorkspace } from "@accurx/auth";
import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import { NativeFetchError, NativeTransportError } from "domains/native/errors";
import { useNativeTransport } from "domains/native/hooks/useNativeTransport";

import { mutateClearPatientCache } from "../api/endpoints/mutateClearPatientCache";
import { PatientExternalId } from "../schemas/PatientExternalIdSchema";

export type ClearPatientCacheMutationVariables = {
    patientExternalIds: PatientExternalId[];
};

export const useMutateClearPatientCache = (
    options?: UseMutationOptions<
        unknown,
        NativeFetchError | NativeTransportError,
        ClearPatientCacheMutationVariables
    >,
) => {
    const transport = useNativeTransport();
    const workspaceId = useCurrentWorkspace().orgId;

    return useMutation(async ({ patientExternalIds }) => {
        if (!transport) {
            throw new NativeTransportError(
                "MutateClearPatientCache: Called with no native transport",
            );
        }

        await mutateClearPatientCache(
            { transport, workspaceId },
            { patientExternalIdentityDto: { patientExternalIds } },
        );
    }, options);
};
