import { useEffect } from "react";

import { useCurrentWorkspace } from "@accurx/auth";
import { patientNhsNumber } from "@accurx/concierge/util/patientNhsNumber";
import {
    useMedicalRecordConnection,
    useNativeSubscription,
} from "@accurx/native";
import { PatientSearchResult } from "@accurx/patient";
import { ClinicianPatientSearch } from "domains/compose/pages/search/components/ComposePatientSearch/ClinicianPatientSearch";
import { useHistory } from "react-router-dom";

import { DisconnectedEmrErrorPage } from "../components/DisconnectedEmrErrorPage/DisconnectedEmrErrorPage";
import { useCurrentPatient } from "../compose/context/CurrentPatientProvider";
import { useAppOrigin } from "../compose/hooks/useAppOrigin";
import { useComposeVariant } from "../compose/hooks/useComposeVariant";
import { ComposePatientSearch } from "./components/ComposePatientSearch/ComposePatientSearch";
import { PatientSearchWrapper } from "./components/PatientSearchWrapper/PatientSearchWrapper";
import { handleSelectPatient } from "./handleSearch.utils";

const supportedSystems: ReturnType<
    typeof useMedicalRecordConnection
>["system"][] = ["Emis", "SystmOne", "Vision"];

export const ComposeSearchPage = () => {
    const history = useHistory();
    const workspace = useCurrentWorkspace();
    const workspaceId = workspace.orgId;
    const composeVariant = useComposeVariant();
    const appOrigin = useAppOrigin();
    const medicalRecordConnection = useMedicalRecordConnection();

    const { data: windowVisibility } = useNativeSubscription(
        "SubscribeWindowOpenStatus",
    );
    const isComposeWindowClosed = windowVisibility?.open === false;
    const { currentPatient } = useCurrentPatient();

    /**
     * Auto-redirect to the compose page
     * if a patient has been selected in the EMR
     * and the compose window is closed
     */
    useEffect(() => {
        if (isComposeWindowClosed && currentPatient?.patient) {
            handleSelectPatient({
                workspaceId,
                result: {
                    ...currentPatient.patient,
                    source: "Demographics",
                    firstName: currentPatient.patient.firstName ?? "",
                    familyName: currentPatient.patient.familyName ?? "",
                    prefixName: currentPatient.patient.prefixName ?? "",
                    patientToken:
                        currentPatient.patient.patientToken ?? undefined,
                    nhsNumber:
                        patientNhsNumber(currentPatient.patient.externalIds) ??
                        null,
                    mobileNumber:
                        currentPatient.contactDetails.find(
                            (contact) => contact.method === "Mobile",
                        )?.value ?? null,
                },
                history,
                composeVariant,
                appOrigin: appOrigin ?? undefined,
            });
        }
    }, [
        isComposeWindowClosed,
        currentPatient,
        workspaceId,
        history,
        composeVariant,
        appOrigin,
    ]);

    const onSelectPatient = (result: PatientSearchResult) => {
        handleSelectPatient({
            workspaceId,
            result,
            history,
            composeVariant,
            appOrigin: appOrigin ?? undefined,
        });
    };

    return medicalRecordConnection.status === "Disconnected" ||
        (medicalRecordConnection.status === "Connected" &&
            !supportedSystems.includes(medicalRecordConnection.system)) ? (
        <DisconnectedEmrErrorPage />
    ) : (
        <PatientSearchWrapper>
            {composeVariant === "Clinician" ? (
                <ClinicianPatientSearch onSelect={onSelectPatient} />
            ) : (
                <ComposePatientSearch onSelect={onSelectPatient} />
            )}
        </PatientSearchWrapper>
    );
};
