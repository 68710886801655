import React, { ReactNode } from "react";

import { useFullScreenToggle, useToggle } from "@accurx/navigation";
import {
    QUICK_VIEW_CONTAINER_ID,
    useQuickViewContext,
} from "@accurx/quick-view";
import { GlobalArchivedWorkspaceBanner } from "layouts/Global/GlobalArchivedWorkspaceBanner/GlobalArchivedWorkspaceBanner";

import { SkipToContentLink } from "app/layout/skipToContentLink";
import { PORTAL_ID_NAV_SUBMENU } from "app/navbar/NavSubMenuComponent";
import { useSimplifiedNavigation } from "store/hooks";

import { PAGE_CONTENT_ID, PORTAL_ID_APP_HEADER } from "../constants";
import { MobileNavigationModal } from "./Mobile/MobileNavigationModal/MobileNavigationModal";
import { TabletNavigationDrawer } from "./Mobile/TabletNavigationDrawer/TabletNavigationDrawer";
import { MobileHeader } from "./MobileHeader/MobileHeader";
import { UnifiedMobilePrimaryNavigation } from "./PrimaryNavigation/UnifiedMobilePrimaryNavigation";
import { UnifiedPrimaryNavigation } from "./PrimaryNavigation/UnifiedPrimaryNavigation";
import { UnifiedSimplePrimaryNavigation } from "./PrimaryNavigation/UnifiedSimplePrimaryNavigation";
import { UnifiedSecondaryNavigation } from "./SecondaryNavigation/UnifiedSecondaryNavigation";
import {
    PageContainer,
    QuickViewContainer,
    StyledLayout,
    StyledMainContent,
    StyledQuickViewWrapper,
    StyledWrapper,
} from "./UnifiedLayout.styles";
import { UnifiedLayoutContext } from "./UnifiedLayoutContext";

export const MAIN_CONTENT_ID = "main-content";

const MainContent = ({ children }: { children?: ReactNode }) => {
    const quickView = useQuickViewContext();

    return (
        <StyledMainContent id={MAIN_CONTENT_ID}>
            <div id={PORTAL_ID_APP_HEADER} />
            <StyledQuickViewWrapper $quickViewIsOpen={quickView.isOpen}>
                <PageContainer id={PAGE_CONTENT_ID}>
                    <div id={PORTAL_ID_NAV_SUBMENU}></div>
                    {children}
                </PageContainer>
                <QuickViewContainer id={QUICK_VIEW_CONTAINER_ID} />
            </StyledQuickViewWrapper>
        </StyledMainContent>
    );
};

export const NoNavigationLayout = ({ children }: { children?: ReactNode }) => {
    return (
        <StyledLayout data-testid="unified-layout">
            <StyledWrapper $isBordered $includeTopBorder>
                <MainContent>{children}</MainContent>
            </StyledWrapper>
        </StyledLayout>
    );
};

export const UnifiedLayout = ({ children }: { children?: ReactNode }) => {
    const isSimpleNav = useSimplifiedNavigation();
    const secondaryNav = useToggle({ isOpen: true });
    const fullScreen = useFullScreenToggle();

    return (
        <UnifiedLayoutContext.Provider value={{ secondaryNav, fullScreen }}>
            <StyledLayout data-testid="unified-layout">
                <SkipToContentLink />
                <GlobalArchivedWorkspaceBanner />
                {isSimpleNav ? (
                    <UnifiedSimplePrimaryNavigation />
                ) : (
                    <UnifiedPrimaryNavigation />
                )}
                <StyledWrapper $isBordered={fullScreen.isBordered}>
                    {!isSimpleNav && !fullScreen.isOpen && (
                        <UnifiedSecondaryNavigation />
                    )}
                    <MainContent>{children}</MainContent>
                </StyledWrapper>
            </StyledLayout>
        </UnifiedLayoutContext.Provider>
    );
};

export const UnifiedTabletLayout = ({ children }: { children?: ReactNode }) => {
    const isSimpleNav = useSimplifiedNavigation();
    const secondaryNav = useToggle();
    const fullScreen = useFullScreenToggle();

    return (
        <UnifiedLayoutContext.Provider value={{ secondaryNav, fullScreen }}>
            <StyledLayout data-testid="unified-tablet-layout">
                <SkipToContentLink />
                <GlobalArchivedWorkspaceBanner />
                {isSimpleNav ? (
                    <UnifiedSimplePrimaryNavigation />
                ) : (
                    <UnifiedPrimaryNavigation />
                )}
                {!fullScreen.isOpen && !isSimpleNav && <MobileHeader />}
                <StyledWrapper $isBordered={fullScreen.isBordered}>
                    <MainContent>{children}</MainContent>
                </StyledWrapper>
            </StyledLayout>
            <TabletNavigationDrawer
                isOpen={secondaryNav.isOpen}
                toggleOpen={secondaryNav.setIsOpen}
            >
                <UnifiedSecondaryNavigation />
            </TabletNavigationDrawer>
        </UnifiedLayoutContext.Provider>
    );
};

export const UnifiedMobileLayout = ({ children }: { children?: ReactNode }) => {
    const isSimpleNav = useSimplifiedNavigation();
    const secondaryNav = useToggle();
    const fullScreen = useFullScreenToggle();

    return (
        <UnifiedLayoutContext.Provider value={{ secondaryNav, fullScreen }}>
            <StyledLayout data-testid="unified-mobile-layout">
                <SkipToContentLink />
                <GlobalArchivedWorkspaceBanner />
                {!fullScreen.isOpen && !isSimpleNav && <MobileHeader />}
                <StyledWrapper>
                    <MainContent>{children}</MainContent>
                </StyledWrapper>
                <UnifiedMobilePrimaryNavigation />
            </StyledLayout>
            <MobileNavigationModal
                open={secondaryNav.isOpen}
                onOpenChange={secondaryNav.setIsOpen}
            >
                <UnifiedSecondaryNavigation />
            </MobileNavigationModal>
        </UnifiedLayoutContext.Provider>
    );
};
