import { ReactNode } from "react";

import { Ds } from "@accurx/design";
import { StyledModalDetailsPanel } from "@accurx/navigation";
import { i } from "images";

import { PatientSearchResult } from "../../../types";
import { PatientDetailsPanelSkeleton } from "./PatientDetailsPanelSkeleton";

export type PatientDetailsPanelProps = {
    patient: PatientSearchResult | null;
    children?: ReactNode;
    isLoading?: boolean;
    isEmpty: boolean;
};

export const PatientDetailsPanel = ({
    patient,
    children,
    isLoading = false,
    isEmpty = false,
}: PatientDetailsPanelProps) => {
    if (isLoading) {
        return (
            <StyledModalDetailsPanel role="status">
                <PatientDetailsPanelSkeleton />
            </StyledModalDetailsPanel>
        );
    }

    if (isEmpty) {
        return <StyledModalDetailsPanel>{null}</StyledModalDetailsPanel>;
    }

    if (!patient) {
        return (
            <StyledModalDetailsPanel>
                <PatientDetailsEmptyState />
            </StyledModalDetailsPanel>
        );
    }

    return <StyledModalDetailsPanel>{children}</StyledModalDetailsPanel>;
};

const PatientDetailsEmptyState = () => {
    return (
        <Ds.Flex alignItems="center" style={{ flex: 1 }}>
            <Ds.Box width="190px" m="0 auto">
                <Ds.Flex
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    style={{ textAlign: "center" }}
                >
                    <img
                        src={i("illustrations/Group.png")}
                        width={80}
                        height={80}
                        alt=""
                    />
                    <Ds.Text color="metal" mt="2">
                        Search or select a patient to see their details here
                    </Ds.Text>
                </Ds.Flex>
            </Ds.Box>
        </Ds.Flex>
    );
};
