import { ReactNode, useEffect } from "react";

import { FeatureName } from "@accurx/auth";
import {
    ButtonLink,
    Card,
    Ds,
    Feedback,
    Icon,
    Spinner,
    Tabs,
    Text,
} from "@accurx/design";
import { useAccurxWebTitle } from "@accurx/navigation";
import { shallowEqual, useDispatch } from "react-redux";
import { Redirect, generatePath, useHistory } from "react-router-dom";

import { MedicalRecordResult, RecordViewRequest } from "api/FlemingDtos";
import { getDownloadUrlRecordNoAuthXML } from "api/RecordViewApi";
import { AnalyticsMapper, FlemingAnalyticsTracker } from "app/analytics";
import { TourTestPatient, getTourMode } from "app/onboarding/Onboarding.helper";
import { OrganisationHelper } from "shared/OrganisationHelper";
import { PatientHelper } from "shared/PatientHelper";
import { ROUTES, ROUTES_WORKSPACE } from "shared/Routes";
import { getStringQuery } from "shared/RoutesHelper";
import {
    useAppSelector,
    useCurrentUserId,
    useIsFeatureEnabled,
} from "store/hooks";

import { NavSubMenuComponent } from "../navbar/NavSubMenuComponent";
import PatientInfoSubMenu from "../navbar/PatientInfoSubMenuComponent";
import { DemoPatientFeedback } from "./DemoPatientFeedback";
import { EXAMPLE_REQUEST_ID } from "./RecordView.constant";
import { canUseNoAuthRecordViewInternal } from "./RecordView.helper";
import { actionCreators } from "./RecordViewActions";
import {
    Allergies,
    ExcludedCodesWarning,
    GpConsultations,
    GpDetails,
    HealthStatus,
    Immunisations,
    Investigations,
    Medications,
    PatientDetailsFull,
    PatientDetailsSummary,
    Problems,
} from "./medicalRecord";
import { getAllergiesBadgeProps } from "./medicalRecord/Allergies";
import { CardSpacer } from "./medicalRecord/CardSpacer";
import { MedicalRecordColumnProvider } from "./medicalRecord/MedicalRecordColumnContext";

enum MedicalRecordStateTypes {
    Loading = "loading",
    Error = "error",
    Loaded = "loaded",
}

const resultReturned = (record: RecordViewRequest | null): boolean => {
    return (
        !!record &&
        !!record.result &&
        (record.result.status === "RequestSucceeded" ||
            record.result.status === "RequestTimedOut")
    );
};

const isValidRequestId = (value: string | null): value is string => {
    return value !== null && value !== "";
};

const MedicalRecord = (): JSX.Element => {
    const history = useHistory();
    const dispatch = useDispatch();
    const isRecordViewEnabled = useIsFeatureEnabled(
        FeatureName.RecordViewPromptOptInWeb,
    );
    const isGpOptedIn = useIsFeatureEnabled(FeatureName.RecordViewGpOptIn);

    // STORE SELECTORS

    const isInEmbeddedMode = useAppSelector(({ app }) => app.isEmbedded);

    const baseAnalyticsProps = useAppSelector(
        ({ account }) =>
            AnalyticsMapper.getRecordViewBaseAnalyticsProps(account),
        shallowEqual,
    );
    const viewMedicalRecordOrigin = useAppSelector(
        ({ recordView }) => recordView.viewMedicalRecordOrigin,
    );

    const isInternalRecordNoAuthAllowed = useAppSelector(
        ({ account, searchForPatient }) =>
            canUseNoAuthRecordViewInternal(
                account,
                searchForPatient.lastResponse,
            ),
    );
    const isInternalRecordNoAuthLoading = useAppSelector(
        ({ recordView }) => recordView.isRecordNoAuthLoading,
    );

    const patientToken = useAppSelector(({ searchForPatient }) =>
        PatientHelper.getPatientToken(searchForPatient.lastResponse),
    );
    const patient = useAppSelector(({ searchForPatient }) =>
        PatientHelper.getPatient(searchForPatient.lastResponse),
    );
    const organisation = useAppSelector(({ account }) =>
        OrganisationHelper.getOrganisation(account),
    );
    const organisationId = organisation?.orgId || null;
    const medicalRecordStatus = useAppSelector(({ recordView }) => {
        return recordView.record?.result.status || null;
    });
    const hasMedicalRecordResult = useAppSelector(({ recordView }) => {
        return resultReturned(recordView.record);
    });
    const medicalRecord = useAppSelector(
        ({ recordView }) => recordView.record?.result.medicalRecord || null,
        shallowEqual,
    );
    const medicalRecordFormat = useAppSelector(
        ({ recordView }) =>
            recordView.record?.result.medicalRecordFormat || null,
        shallowEqual,
    );

    let status = MedicalRecordStateTypes.Loading;
    if (medicalRecordStatus === "Error fetching record") {
        status = MedicalRecordStateTypes.Error;
    } else if (hasMedicalRecordResult) {
        status = MedicalRecordStateTypes.Loaded;
    }

    let title = "Medical record";
    switch (status) {
        case MedicalRecordStateTypes.Loading:
            title = "Retrieving medical record";
            break;
        case MedicalRecordStateTypes.Error:
            title = "Failed to load medical record";
            break;
        case MedicalRecordStateTypes.Loaded:
            title = "View patients medical record";
            break;
        default:
            break;
    }

    useAccurxWebTitle(title);

    // we use comma syntax for arrays in query strings, hence why we're only
    // checking for the presence of arrays in that format
    const requestIdUrlParam = getStringQuery(
        history.location.search,
        "request",
    );

    // use the id in the query string
    const medicalRecordRequestId = isValidRequestId(requestIdUrlParam)
        ? requestIdUrlParam
        : "";

    const isDemoPatient = medicalRecordRequestId === EXAMPLE_REQUEST_ID;
    // Show a link to opt in to RV if this is example patient, a feature flag allowing this prompt
    // is on (so only visible for GPs) and the GP practice has not opted into Record View yet
    const showOptInLink =
        isDemoPatient && isRecordViewEnabled && isGpOptedIn === false;

    // Reset record view state on component unload
    useEffect(() => {
        return () => {
            dispatch(actionCreators.resetRecordView());
        };
    }, [dispatch]);

    useEffect(() => {
        // don't try to fetch anything
        if (
            isInternalRecordNoAuthLoading ||
            status === MedicalRecordStateTypes.Loaded ||
            status === MedicalRecordStateTypes.Error
        ) {
            return;
        }

        // fetch interal record without the auth flow
        if (isInternalRecordNoAuthAllowed) {
            dispatch(
                actionCreators.getRecordNoAuth({
                    patientToken,
                    organisationId,
                    isGpOwnPatient: false,
                }),
            );
            return;
        }

        // fetch example record for demo patient
        if (isDemoPatient) {
            dispatch(actionCreators.getExampleRecord(organisationId));
            return;
        }

        // get a real record
        const timer = window.setInterval(() => {
            dispatch(
                actionCreators.getRecord({
                    RequestId: medicalRecordRequestId,
                    OrganisationId: organisationId,
                }),
            );
        }, 3000);

        return (): void => {
            timer && window.clearInterval(timer);
        };
    }, [
        dispatch,
        status,
        medicalRecordRequestId,
        patientToken,
        organisationId,
        isDemoPatient,
        isInternalRecordNoAuthLoading,
        isInternalRecordNoAuthAllowed,
    ]);

    // Send analytics for record load result
    useEffect(() => {
        if (status === MedicalRecordStateTypes.Loading) return;

        if (!baseAnalyticsProps) return;

        const analyticsProps = {
            ...baseAnalyticsProps,
            requestId: medicalRecordRequestId,
            recordViewOrigin: viewMedicalRecordOrigin,
            isTestPatient: isDemoPatient,
            recordSource: medicalRecordFormat,
        };

        if (status === MedicalRecordStateTypes.Loaded) {
            FlemingAnalyticsTracker.trackRecordViewViewedRecordSuccess({
                ...analyticsProps,
                referrer: "",
            });
            window.Intercom("trackEvent", "record-view-load");
        }

        if (status === MedicalRecordStateTypes.Error) {
            FlemingAnalyticsTracker.trackRecordViewViewedRecordFailed({
                ...analyticsProps,
                referrer: "",
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status]);

    // We have an unusable request id
    // or there is not a valid patient token to make a no auth request
    const isInvalidRequestId =
        isValidRequestId(medicalRecordRequestId) === false;
    const canRequestWithoutValidRequestId =
        isInternalRecordNoAuthAllowed && patientToken && organisationId;
    if (isInvalidRequestId && !canRequestWithoutValidRequestId) {
        return <Redirect to={ROUTES.record_view_medical_record_requests} />;
    }

    const getNoAuthXMLDownloadUrl = (): string => {
        return getDownloadUrlRecordNoAuthXML({
            patientToken,
            organisationId,
        });
    };

    const getRecordViewOptInLink = (): string | null => {
        if (organisationId == null) {
            return null;
        }
        return `/practices/${organisationId}/record-view-opt-in?referrer=examplepatient`;
    };
    const optInLink = showOptInLink ? getRecordViewOptInLink() : null;

    const exitMedicalRecordView = () => history.goBack();

    const recordsTableLink = (
        <Ds.Link
            to={generatePath(ROUTES_WORKSPACE.workspaceMedicalRecords, {
                workspaceId: organisationId as number,
            })}
        >
            Records table
        </Ds.Link>
    );
    const renderMedicalRecordState = (): JSX.Element => {
        switch (status) {
            case MedicalRecordStateTypes.Loading:
                return (
                    <>
                        <NavSubMenuComponent
                            backCallback={
                                isInEmbeddedMode
                                    ? undefined
                                    : exitMedicalRecordView
                            }
                        >
                            <PatientInfoSubMenu patient={patient} />
                        </NavSubMenuComponent>
                        <Card>
                            <div className="d-flex flex-column">
                                <div className="d-flex justify-content-center">
                                    <Spinner />
                                </div>
                                <CardSpacer spacing="medium" />
                                <Text skinny>
                                    Almost there! We’re just requesting the
                                    record for you. This usually takes less than
                                    a minute.
                                </Text>

                                {!isInEmbeddedMode && (
                                    <>
                                        <CardSpacer spacing="medium" />
                                        <Ds.Text>
                                            Feel free to leave this page, and
                                            check the {recordsTableLink} page
                                            for updates.
                                        </Ds.Text>
                                    </>
                                )}
                            </div>
                        </Card>
                    </>
                );
            case MedicalRecordStateTypes.Error:
                return (
                    <Feedback
                        title="Something went wrong"
                        content="Couldn't load the medical record, please try again"
                        colour="error"
                    />
                );
            case MedicalRecordStateTypes.Loaded:
                if (medicalRecord !== null) {
                    return (
                        <MedicalRecordContents
                            record={medicalRecord}
                            isDemoPatient={isDemoPatient}
                            optInLink={optInLink}
                        />
                    );
                }
                return <></>;
            default:
                return <></>;
        }
    };

    return (
        <div className="bottom-spacing">
            {isInternalRecordNoAuthAllowed &&
                status === MedicalRecordStateTypes.Loaded && (
                    <ButtonLink
                        icon={{ name: "Save" }}
                        href={getNoAuthXMLDownloadUrl()}
                        download
                        text="Download XML no PII"
                        rel="noreferrer noopener"
                        className="text-nowrap ml-3 mb-3 text-decoration-none"
                    />
                )}
            {renderMedicalRecordState()}
        </div>
    );
};

const PanelLeftHandSide = ({
    children,
}: {
    children: ReactNode;
}): JSX.Element => {
    return (
        <MedicalRecordColumnProvider value={{ column: "left" }}>
            <div className="order-1 order-lg-0 col-md-12 col-lg-8">
                {children}
                <CardSpacer spacing="medium" />
            </div>
        </MedicalRecordColumnProvider>
    );
};

const PanelRightHandSide = ({
    children,
}: {
    children: ReactNode;
}): JSX.Element => {
    return (
        <MedicalRecordColumnProvider value={{ column: "right" }}>
            <div className="col-md-12 col-lg-4">
                {children}
                <CardSpacer spacing="medium" />
            </div>
        </MedicalRecordColumnProvider>
    );
};

enum RecordViewTabIds {
    Problems = "problems",
    MedicationsAllergies = "medications-allergies",
    Investigations = "investigations",
    Immunisations = "immunisations",
    GpConsultations = "gp-consultations",
    GpPatientDetails = "gp-patient-details",
}

type MedicalRecordContentsProps = {
    record: MedicalRecordResult;
    isDemoPatient: boolean;
    optInLink: string | null; // Null if we don't want an opt-in banner, otherwise has link to opt-in page
};

const MedicalRecordContents = ({
    record,
    isDemoPatient,
    optInLink,
}: MedicalRecordContentsProps): JSX.Element => {
    const isInEmbeddedMode = useAppSelector(({ app }) => app.isEmbedded);
    const accuRxUserId = useCurrentUserId() || "";
    const tourMode = getTourMode(accuRxUserId);
    const isTourVisible =
        isDemoPatient &&
        (tourMode === TourTestPatient.RecordView ||
            tourMode === TourTestPatient.RecordViewEnded);
    const history = useHistory();
    const dispatch = useDispatch();

    let tabs = [
        { tabId: RecordViewTabIds.Problems, text: "Problems" },
        {
            tabId: RecordViewTabIds.MedicationsAllergies,
            text: "Medications & allergies",
        },
        { tabId: RecordViewTabIds.Investigations, text: "Investigations" },
        { tabId: RecordViewTabIds.Immunisations, text: "Immunisations" },
        { tabId: RecordViewTabIds.GpConsultations, text: "GP consultations" },
        {
            tabId: RecordViewTabIds.GpPatientDetails,
            text: "GP & patient details",
        },
    ];

    if (record.gpConsultations === null) {
        //Don't show consultations tab if null returned from the server for consultations
        tabs = tabs.filter(
            (tab) => tab.tabId !== RecordViewTabIds.GpConsultations,
        );
    }

    const renderTabPanelContents = (tabId: RecordViewTabIds): JSX.Element => {
        switch (tabId) {
            case RecordViewTabIds.Problems:
                return (
                    <>
                        <PanelLeftHandSide>
                            <Problems
                                activeProblems={record.activeProblems}
                                otherProblems={record.otherProblems}
                                pastProblems={record.pastProblems}
                                potentialProblems={record.potentialProblems}
                            />
                        </PanelLeftHandSide>
                        <PanelRightHandSide>
                            <DemoPatientFeedback
                                isDemoPatient={isDemoPatient}
                            />
                            <ExcludedCodesWarning />
                        </PanelRightHandSide>
                    </>
                );
            case RecordViewTabIds.MedicationsAllergies:
                return (
                    <>
                        <PanelLeftHandSide>
                            <Medications
                                currentMedications={record.currentMedications}
                                pastMedications={record.pastMedications}
                            />
                        </PanelLeftHandSide>
                        <PanelRightHandSide>
                            <DemoPatientFeedback
                                isDemoPatient={isDemoPatient}
                            />
                            <Allergies allergies={record.allergies} />
                        </PanelRightHandSide>
                    </>
                );
            case RecordViewTabIds.Investigations:
                return (
                    <>
                        <PanelLeftHandSide>
                            <Investigations
                                investigations={record.investigations}
                            />
                        </PanelLeftHandSide>
                        <PanelRightHandSide>
                            <DemoPatientFeedback
                                isDemoPatient={isDemoPatient}
                            />
                            <HealthStatus healthStatus={record.healthStatus} />
                        </PanelRightHandSide>
                    </>
                );
            case RecordViewTabIds.Immunisations:
                return (
                    <>
                        <PanelLeftHandSide>
                            <Immunisations
                                immunisations={record.immunisations}
                            />
                        </PanelLeftHandSide>
                        <PanelRightHandSide>
                            <DemoPatientFeedback
                                isDemoPatient={isDemoPatient}
                            />
                            <Allergies allergies={record.allergies} />
                        </PanelRightHandSide>
                    </>
                );
            case RecordViewTabIds.GpConsultations:
                if (record.gpConsultations === null) {
                    return <></>;
                }
                return (
                    <>
                        <PanelLeftHandSide>
                            <GpConsultations
                                gpConsultations={record.gpConsultations}
                            />
                        </PanelLeftHandSide>
                        <PanelRightHandSide>
                            <DemoPatientFeedback
                                isDemoPatient={isDemoPatient}
                            />
                            <GpDetails {...record.gp} />
                        </PanelRightHandSide>
                    </>
                );
            case RecordViewTabIds.GpPatientDetails:
                return (
                    <>
                        <PanelLeftHandSide>
                            <GpDetails {...record.gp} />
                            <CardSpacer spacing="medium" />
                            <PatientDetailsFull {...record.patient} />
                        </PanelLeftHandSide>
                        <PanelRightHandSide>
                            <DemoPatientFeedback
                                isDemoPatient={isDemoPatient}
                            />
                        </PanelRightHandSide>
                    </>
                );
            default:
                console.error(`tabId ${tabId} not recognised`);
                return <></>;
        }
    };

    const goBackToProductSelection = (): void => {
        dispatch(actionCreators.resetRecordView());
        history.goBack();
    };

    return (
        <Tabs>
            {/* Header */}
            <NavSubMenuComponent
                contentExtendsToBottomEdge={true}
                sticky
                backCallback={
                    isInEmbeddedMode ? undefined : goBackToProductSelection
                }
            >
                <PatientDetailsSummary
                    patient={record.patient}
                    allergyBadge={getAllergiesBadgeProps(record.allergies)}
                />
                <Tabs.TabList tabs={tabs} />
            </NavSubMenuComponent>
            <CardSpacer spacing="tiny" />
            {isDemoPatient && (
                <>
                    {optInLink && (
                        <>
                            <Card spacingBody={1.5}>
                                <div className="justify-content-between d-flex align-items-center">
                                    <Text
                                        variant="label"
                                        props={{ className: "mr-3" }}
                                    >
                                        <Icon
                                            theme="Fill"
                                            name="Warning"
                                            halo={{ colour: "yellow" }}
                                            size={4}
                                            props={{ className: "mr-2" }}
                                        />
                                        <span>
                                            Switch on Record View to view your
                                            own patients' records anywhere,
                                            anytime
                                        </span>
                                    </Text>
                                    <ButtonLink
                                        className="text-nowrap"
                                        text="Get started"
                                        href={optInLink}
                                    />
                                </div>
                            </Card>
                            <CardSpacer spacing="small" />
                        </>
                    )}
                    {isTourVisible && (
                        <Feedback
                            title="Congratulations! You're viewing a patient's GP record"
                            content="Look through all of the sections to see the type of information available."
                            colour="learning"
                        />
                    )}
                    <CardSpacer spacing="small" />
                </>
            )}
            {/* Tab panels */}
            {tabs.map(({ tabId }) => (
                <Tabs.Panel key={tabId} tabId={tabId}>
                    <div className="row">{renderTabPanelContents(tabId)}</div>
                </Tabs.Panel>
            ))}
        </Tabs>
    );
};

export default MedicalRecord;
