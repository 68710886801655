import React, { useState } from "react";

import { Button, Feedback, Link, Text } from "@accurx/design";
import { useDispatch } from "react-redux";
import { generatePath } from "react-router-dom";
import { toast } from "react-toastify";

import { MessageTemplateOwner, MessageTemplateType } from "api/FlemingDtos";
import * as PatientMessagingServerApi from "api/PatientMessagingServer/PatientMessagingServerApi";
import { UserflowEvent, trackUserflowEvent } from "app/account/Userflow";
import { FlemingAnalyticsTracker } from "app/analytics";
import { MessageTemplatePageOrigin } from "app/analytics/FlemingAnalytics";
import { CreateMessageTemplateForm } from "app/messageTemplates/CreateMessageTemplateForm";
import { actionCreators } from "app/messageTemplates/MessageTemplatesActions";
import {
    mapMessageTemplateManagementViewToTemplateInList,
    mapMessageTemplateOwnerToString,
    mapMessageTemplateTypeToString,
} from "app/messageTemplates/MessageTemplatesHelper";
import {
    BODY_MAX_LENGTH,
    TITLE_MAX_LENGTH,
} from "app/messageTemplates/messageTemplatesEditCreate/ManageMessageTemplateConstants";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { OrganisationHelper } from "shared/OrganisationHelper";
import { ROUTES_WORKSPACE } from "shared/Routes";
import { useWorkspaceId } from "shared/concierge/conversations/hooks";
import {
    PatientNhsAppMessageConversationItem,
    PatientSmsConversationItem,
} from "shared/concierge/conversations/types/item.types";
import { useAppSelector } from "store/hooks";

import { TemplateInList } from "../../MessageTemplates/MessageTemplates.types";
import {
    StyledBody,
    StyledButtonGroup,
    StyledContainer,
} from "./CustomTemplate.styles";

export type CustomTemplateProps = {
    onSuccess: () => void;
    onCancel: () => void;
    patientConversationItem:
        | PatientSmsConversationItem
        | PatientNhsAppMessageConversationItem;
    patientInfo: string[] | null;
};

type ValidationResult =
    | { isValid: true; errors?: undefined }
    | { isValid: false; errors: string[] };

export const CustomTemplate = ({
    onSuccess,
    onCancel,
    patientConversationItem,
    patientInfo,
}: CustomTemplateProps) => {
    const [titleError, setTitleError] = useState("");
    const [title, setTitle] = useState("");
    const [bodyError, setBodyError] = useState("");
    const [body, setBody] = useState(patientConversationItem.body);
    const [disableSaveButton, setDisableSaveButton] = useState(false);
    const loggedInProps = useFlemingLoggedInAnalytics();

    const dispatch = useDispatch();

    const orgName = useAppSelector(({ account }) =>
        OrganisationHelper.getOrganisationName(account),
    );

    const workspaceId = useWorkspaceId();

    const isValidTitle = (): ValidationResult => {
        setTitleError("");
        if (title.trim().length === 0) {
            const error = "Please add a title";
            setTitleError(error);
            return { isValid: false, errors: [error] };
        }

        if (title.length > TITLE_MAX_LENGTH) {
            const error = `Title must be less than or equal to ${TITLE_MAX_LENGTH} characters.`;
            setTitleError(error);
            return { isValid: false, errors: [error] };
        }

        return { isValid: true };
    };

    const isValidBody = (): ValidationResult => {
        setBodyError("");
        if (body.trim().length === 0) {
            const error = "Please add a body";
            setBodyError(error);
            return { isValid: false, errors: [error] };
        }

        if (body.length > BODY_MAX_LENGTH) {
            const error = `Body must be less than or equal to ${BODY_MAX_LENGTH} characters.`;
            setBodyError(error);
            return { isValid: false, errors: [error] };
        }

        const hasPatientInfoInBody =
            patientInfo &&
            patientInfo.some((patientData) => {
                return body.includes(patientData);
            });

        if (hasPatientInfoInBody) {
            const error = "Please remove patient information";
            setBodyError(error);
            return { isValid: false, errors: [error] };
        }

        return { isValid: true };
    };

    const isValidTemplate = (): ValidationResult => {
        const validTitle = isValidTitle();
        const validBody = isValidBody();

        if (validTitle.isValid && validBody.isValid) {
            return { isValid: true };
        }

        return {
            isValid: false,
            errors: [...(validTitle.errors ?? []), ...(validBody.errors ?? [])],
        };
    };

    const handleCreateTemplate = async () => {
        const validationResult = isValidTemplate();
        const hasError = !validationResult.isValid;
        const pageOrigin: MessageTemplatePageOrigin =
            "WebInboxConversationQuickView";
        const templateCreateConfirmButtonClickProps = {
            ...loggedInProps,
            pageOrigin,
            templateName: title,
        };

        const errorInformation = hasError
            ? {
                  hasError,
                  errorReason: validationResult.errors,
              }
            : { hasError };

        const templateLevel = mapMessageTemplateOwnerToString(
            MessageTemplateOwner.User,
        );

        FlemingAnalyticsTracker.trackTemplateCreateConfirmButtonClick({
            ...templateCreateConfirmButtonClickProps,
            ...errorInformation,
            templateType: "sms",
            countAttachment: 0,
            templateLevel,
            productOrigin: "PatientMessage",
            countPatientDetail: 0,
        });

        if (hasError) {
            return;
        }
        setDisableSaveButton(true);

        const { result, success } =
            await PatientMessagingServerApi.createUpdateMessageTemplate({
                organisationId: workspaceId,
                messageTemplate: {
                    id: undefined,
                    type: mapMessageTemplateTypeToString(
                        MessageTemplateType.Sms,
                    ),
                    title,
                    body,
                    owner: templateLevel,
                    isDefault: false,
                    allowReplyByDefault: false,
                },
            });

        const hasSucceeded = result && success;

        FlemingAnalyticsTracker.trackTemplateCreateConfirmButtonResponse({
            ...templateCreateConfirmButtonClickProps,
            templateType: "sms",
            countAttachment: 0,
            hasError: hasSucceeded !== true,
        });

        if (hasSucceeded) {
            const updatedTemplate: TemplateInList =
                mapMessageTemplateManagementViewToTemplateInList(
                    result,
                    workspaceId,
                );
            dispatch(
                actionCreators.editOrCreateMessageTemplateSuccess(
                    updatedTemplate,
                ),
            );
            onSuccess();
            toast(
                Feedback({
                    colour: "success",
                    title: "Template created successfully.",
                    iconName: "Success",
                }),
            );
            trackUserflowEvent(UserflowEvent.MESSAGE_TEMPLATE_CREATED);
        } else {
            toast(
                Feedback({
                    colour: "error",
                    title: "Something went wrong saving your template. Please try again.",
                    iconName: "Failed",
                }),
            );
        }

        setDisableSaveButton(false);
    };

    return (
        <StyledContainer>
            <StyledBody>
                <Text as="h2" skinny variant="subtitle">
                    Create custom template
                </Text>
                <Text skinny variant="body">
                    {
                        "Templates that you create here will be added to the template list. "
                    }
                    <Link
                        text="Manage your templates here"
                        href={generatePath(
                            ROUTES_WORKSPACE.workspaceTemplatesOverview,
                            { workspaceId: workspaceId },
                        )}
                        openInNewTab
                    />
                </Text>
                <Feedback
                    colour="information"
                    title="Make sure to remove all patient information and links from the template"
                />
                <div>
                    <CreateMessageTemplateForm
                        title={title}
                        titleError={titleError}
                        onTitleChange={setTitle}
                        body={body}
                        bodyError={bodyError}
                        onBodyChange={setBody}
                        templateType={MessageTemplateType.Sms}
                        orgName={orgName}
                    />
                </div>
            </StyledBody>
            <StyledButtonGroup>
                <Button
                    icon={{
                        name: "Bin",
                    }}
                    onClick={onCancel}
                    text="Cancel"
                    theme="danger"
                />
                <Button
                    disabled={disableSaveButton}
                    type="submit"
                    text="Save and close"
                    theme="primary"
                    onClick={handleCreateTemplate}
                />
            </StyledButtonGroup>
        </StyledContainer>
    );
};
