import { useState } from "react";

import { Ds } from "@accurx/design";
import { useIsMutating } from "@tanstack/react-query";
import { useDirectory } from "domains/directory/context/DirectoryProvider";
import { EmailValidationKey } from "domains/directory/hooks/mutations/useEmailValidationMutation";
import styled from "styled-components";

import { DirectoryModalFooter } from "../DirectoryModal/DirectoryModalFooter";
import { EmailValidation } from "./EmailValidation/EmailValidation";

export const DirectoryAddContact = () => {
    const isValidatingEmail =
        useIsMutating({ mutationKey: [EmailValidationKey] }) > 0;
    const { setView } = useDirectory();
    const [email, setEmail] = useState("");
    return (
        <>
            <Ds.Flex.Item flex="1 1 0">
                <DirectoryPageContainer mt="3">
                    <Ds.Text
                        mb="1"
                        as="h1"
                        size="large"
                        weight="bold"
                        color="night"
                    >
                        Add contact
                    </Ds.Text>
                    <Ds.Text mb="2" color="night">
                        Contact will only be visible to your workspace
                    </Ds.Text>
                    {email ? (
                        <Ds.Text>Add contact with email {email}</Ds.Text>
                    ) : (
                        <EmailValidation onValid={setEmail} />
                    )}
                </DirectoryPageContainer>
            </Ds.Flex.Item>
            <DirectoryModalFooter>
                <DirectoryPageContainer>
                    <Ds.Flex justifyContent="space-between">
                        <Ds.Button
                            type="button"
                            appearance="tertiary"
                            onClick={() => setView("Search")}
                        >
                            Cancel
                        </Ds.Button>
                        <Ds.Button
                            type="submit"
                            disabled={isValidatingEmail}
                            form="add-contact"
                        >
                            Continue
                        </Ds.Button>
                    </Ds.Flex>
                </DirectoryPageContainer>
            </DirectoryModalFooter>
        </>
    );
};

const DirectoryPageContainer = styled(Ds.Box).attrs({
    mx: { xs: "2", lg: "30%" },
})``;
