import { useState } from "react";

import { useCurrentWorkspace, useFeatureFlag } from "@accurx/auth";
import * as UI from "@accurx/design";
import { useMedicalRecordConnection } from "@accurx/native";
import { appointmentAvailabilityPeriodCalculation } from "@accurx/self-book";
import { containsAbuseWord } from "@accurx/shared/dist/AbuseWordsHelper";
import { isPast } from "date-fns";
import { useCompose } from "domains/message-component/context";
import {
    ComposeActionsTypes,
    ComposeWarning,
} from "domains/message-component/reducer.types";
import { PatientExternalId } from "domains/message-component/types";
import { hasError } from "domains/message-component/utils/hasError";
import { validateMessage } from "domains/message-component/utils/validateMessage";

import { ComposeProps } from "../../types";
import { OnMessageSendFn } from "../../types/Compose.types";
import { useCommunicationConsent } from "../RecipientSelector/CommunicationConsent/ConsentAggregate/useCommunicationConsent";
import { SendSplitButton } from "../SendSplitButton/SendSplitButton";
import { AbuseWordCheckModal } from "./AbuseWordCheckModal";
import { ConsentStillLoadingModal } from "./ConsentStillLoadingModal";
import { DeclinedConsentModal } from "./DeclinedConsentModal";
import { FailedAttachmentWarningModal } from "./FailedAttachmentWarningModal";
import {
    ForgotAllowReplyModal,
    listOfForgotReplyPhrases,
} from "./ForgotAllowReplyModal";
import { MixedConsentModal } from "./MixedConsentModal";
import { SaveToRecordWithDifferentEmrPatientModal } from "./SaveToRecordWithDifferentEmrPatient";
import { ScheduledTimeHasPassedModal } from "./ScheduledTimeHasPassedModal";

type SendMessageProps = Pick<
    ComposeProps,
    "patientMatchesCurrentMedicalRecordPatient"
> & {
    patientExternalIds: PatientExternalId[];
    characterCount: number;
    fragmentCount: number;
    isUnicode?: boolean;
    onMessageSend: OnMessageSendFn;
    isLoading: boolean;
};

export const SendMessage = ({
    patientMatchesCurrentMedicalRecordPatient,
    patientExternalIds,
    characterCount,
    fragmentCount,
    isUnicode,
    onMessageSend,
    isLoading,
}: SendMessageProps) => {
    const workspace = useCurrentWorkspace();
    const { state, dispatch } = useCompose();
    const [warnings, setWarnings] = useState<ComposeWarning[]>([]);
    const [allowReplyWarningTriggers, setAllowReplyWarningTrigger] = useState<
        string[]
    >([]);

    function clearWarnings() {
        setAllowReplyWarningTrigger([]);
        setWarnings([]);
    }
    // Medical record
    const connection = useMedicalRecordConnection();

    const isAllowReplyReminderEnabled = useFeatureFlag(
        "ComposeAllowReplyReminder",
    );

    const communicationConsent = useCommunicationConsent({
        patientExternalIds,
    });

    const shouldSaveToRecord =
        connection.status === "Connected" &&
        connection.capabilities.saveToRecord &&
        state.isSaveToRecordEnabled;

    const handleValidatedMessageSendClick = (args: {
        /**
         * Whether the button to send has been clicked
         * from a warning modal
         */
        isModal: boolean;
        /** One of the warning modals warns that we are trying to
         * schedule the message in the past, so this is to override
         * state value without waiting for state to update
         * */
        sendNow?: true;
        warnings: string[];
        errors: string[];
    }) => {
        const currentDate = new Date();
        const sendTime =
            state.sendAt === null ? currentDate : state.sendAt.sendAtDateTime;
        const sendTimeAsDateTime = args.sendNow
            ? currentDate
            : new Date(sendTime);

        const calculatedFutureDate = appointmentAvailabilityPeriodCalculation(
            sendTimeAsDateTime,
            state.selfBookLink?.appointmentAvailabilityPeriod,
        );

        const selfBookOptions = state.selfBookLink
            ? {
                  ...state.selfBookLink,
                  availabilityRestriction: {
                      from: sendTimeAsDateTime.toISOString(),
                      to: calculatedFutureDate.toISOString(),
                  },
              }
            : null;

        onMessageSend({
            errors: args.errors,
            warnings: args.warnings,
            isModal: args.isModal,
            data: {
                characterCount,
                fragmentCount,
                isUnicode: !!isUnicode,
                contactDetails: state.contactDetails,
                attachments: state.attachments.filter(
                    ({ status }) => status === "success",
                ),
                isPatientResponseEnabled: state.isPatientResponseEnabled,
                isSaveToRecordEnabled: state.isSaveToRecordEnabled,
                messageBody: state.messageBody,
                messageSignature: state.messageSignature,
                nhsAdviceLink: state.nhsAdviceLink,
                template: state.template,
                selfBookLink: selfBookOptions,
                sendAt: args.sendNow ? null : state.sendAt,
            },
        });
    };

    const getCurrentWarnings = (): ComposeWarning[] => {
        const warnings: ComposeWarning[] = [];

        if (
            containsAbuseWord(`${state.messageBody} ${state.messageSignature}`)
        ) {
            warnings.push("ABUSE_WORD");
        }

        if (state.attachments.some(({ status }) => status === "error")) {
            warnings.push("FAILED_ATTACHMENT");
        }

        if (
            shouldSaveToRecord &&
            // Important to check for false value as null means undetermined
            patientMatchesCurrentMedicalRecordPatient === false
        ) {
            warnings.push("SAVE_TO_RECORD_WITH_DIFFERENT_EMR_PATIENT");
        }

        /* Check isFetching as we cannot reply on status === "loading", as
        /* as the query will be in this state when disabled */
        if (communicationConsent?.isFetching) {
            warnings.push("CONSENT_STILL_LOADING");
        }

        if (communicationConsent?.status === "success") {
            const consentAggregate =
                state.contactDetails.method === "Email"
                    ? communicationConsent.data.emailConsent.consentAggregate
                    : communicationConsent.data.smsConsent.consentAggregate;
            if (consentAggregate === "Mixed") {
                warnings.push("MIXED_CONSENT");
            }
            if (consentAggregate === "AllDeclined") {
                warnings.push("DECLINED_CONSENT");
            }
        }

        if (state.sendAt && isPast(new Date(state.sendAt.sendAtDateTime))) {
            warnings.push("SCHEDULED_TIME_HAS_PASSED");
        }

        if (
            isAllowReplyReminderEnabled &&
            !state.isPatientResponseEnabled &&
            !state.selfBookLink &&
            state.template.type !== "QuestionnaireTemplate" &&
            state.template.type !== "PendingQuestionnaireTemplate"
        ) {
            const matchingTriggers = listOfForgotReplyPhrases.flatMap(
                ({ label, regex }) => {
                    if (state.messageBody.match(regex)) {
                        return [label];
                    }
                    return [];
                },
            );
            setAllowReplyWarningTrigger(matchingTriggers);

            if (matchingTriggers.length > 0) {
                warnings.push("FORGOT_ALLOW_REPLY");
            }
        }

        return warnings;
    };

    const handleMessageValidation = () => {
        const errors = validateMessage({
            workspaceName: workspace.organisationName,
            originalGreeting: state.originalGreeting,
            originalSignature: state.originalSignature,
            messageBody: state.messageBody,
            messageSignature: state.messageSignature,
            nhsAdviceLink: state.nhsAdviceLink,
            withSelfBookLink: !!state.selfBookLink,
            mobileNumber:
                state.contactDetails.method === "Mobile"
                    ? state.contactDetails.value
                    : null,
            emailAddress:
                state.contactDetails.method === "Email"
                    ? state.contactDetails.value
                    : null,
            attachments: state.attachments,
            withPatientResponseEnabled: state.isPatientResponseEnabled,
            template:
                state.template.type !== "NoTemplate" ? state.template : null,
        });

        const hasErrors = hasError(errors);
        const analyticsErrors: string[] = hasErrors
            ? (Object.values(errors).filter(
                  (error) => typeof error === "string",
              ) as string[])
            : [];

        // Either sets new errors or clears them if there's any
        dispatch({
            type: "SET_ERRORS",
            payload: errors,
        });

        if (hasErrors) {
            handleValidatedMessageSendClick({
                errors: analyticsErrors,
                warnings: [],
                isModal: false,
            });
            return;
        }

        const warnings = getCurrentWarnings();
        if (warnings.length > 0) {
            setWarnings(warnings);
            handleValidatedMessageSendClick({
                errors: analyticsErrors,
                warnings,
                isModal: false,
            });
            return;
        }

        handleValidatedMessageSendClick({
            isModal: false,
            errors: [],
            warnings: [],
        });
    };

    const trySendMessageOrNextWarning = () => {
        if (warnings.length > 1) {
            setWarnings((prev) => prev.slice(1));
        } else {
            clearWarnings();
            handleValidatedMessageSendClick({
                isModal: true,
                errors: [],
                warnings: [],
                /**
                 * We need to manually trigger the mutation with sendNow=true when
                 * the user chooses the sendNow option because there could be a
                 * race condition with updating state.sendAt to null and
                 * gathering the updated state properties for the mutation request
                 */
                sendNow:
                    warnings[0] === "SCHEDULED_TIME_HAS_PASSED"
                        ? true
                        : undefined,
            });
        }
    };

    return (
        <>
            <SendSplitButton
                isDropdownLoading={false}
                isSending={isLoading}
                sendMessageHandler={handleMessageValidation}
            >
                {state.sendAt ? (
                    <UI.Icon
                        name="Clock"
                        colour="white"
                        size={3}
                        theme="Line"
                        aria-hidden={true}
                    />
                ) : (
                    <UI.Icon
                        name="Send"
                        colour="white"
                        size={3}
                        theme="Fill"
                        aria-hidden={true}
                    />
                )}
                <span>Send</span>
            </SendSplitButton>
            <AbuseWordCheckModal
                isOpen={warnings[0] === "ABUSE_WORD"}
                onClose={clearWarnings}
                onSendMessage={() => {
                    trySendMessageOrNextWarning();
                }}
            />
            <ForgotAllowReplyModal
                isOpen={warnings[0] === "FORGOT_ALLOW_REPLY"}
                onClose={clearWarnings}
                triggers={allowReplyWarningTriggers}
                onSendMessage={() => {
                    trySendMessageOrNextWarning();
                }}
            />
            <FailedAttachmentWarningModal
                isOpen={warnings[0] === "FAILED_ATTACHMENT"}
                onClose={clearWarnings}
                onClickSendMessageWithoutAttachment={() => {
                    trySendMessageOrNextWarning();
                }}
            />
            {connection.status === "Connected" && (
                <SaveToRecordWithDifferentEmrPatientModal
                    isOpen={
                        warnings[0] ===
                        "SAVE_TO_RECORD_WITH_DIFFERENT_EMR_PATIENT"
                    }
                    onCancel={clearWarnings}
                    onSendMessage={() => {
                        trySendMessageOrNextWarning();
                    }}
                    medicalRecordSystem={connection.system}
                />
            )}

            {communicationConsent !== null && (
                <UI.VisuallyHidden>
                    <span
                        data-testid={`consent-status-${communicationConsent.status}`}
                    />
                </UI.VisuallyHidden>
            )}

            <MixedConsentModal
                isOpen={warnings[0] === "MIXED_CONSENT"}
                onClose={clearWarnings}
                onSendMessage={() => {
                    trySendMessageOrNextWarning();
                }}
                method={state.contactDetails.method}
            />

            <DeclinedConsentModal
                isOpen={warnings[0] === "DECLINED_CONSENT"}
                onClose={clearWarnings}
                onSendMessage={() => {
                    trySendMessageOrNextWarning();
                }}
                method={state.contactDetails.method}
            />

            <ConsentStillLoadingModal
                isOpen={warnings[0] === "CONSENT_STILL_LOADING"}
                onClose={clearWarnings}
                onSendMessage={() => {
                    trySendMessageOrNextWarning();
                }}
            />

            {state.sendAt?.sendAtDateTime && (
                <ScheduledTimeHasPassedModal
                    isOpen={warnings[0] === "SCHEDULED_TIME_HAS_PASSED"}
                    onClose={clearWarnings}
                    onSendMessage={() => {
                        dispatch({
                            type: ComposeActionsTypes.UpdateSendAt,
                            payload: {
                                sendAt: null,
                            },
                        });

                        trySendMessageOrNextWarning();
                    }}
                    date={new Date(state.sendAt.sendAtDateTime)}
                />
            )}
        </>
    );
};
