import { Conversation } from "@accurx/concierge/schemas/ConversationSchema";
import * as UI from "@accurx/design";
import { Pill } from "@accurx/inbox-design-library";
import {
    useMarkAsDoneMutation,
    useMarkAsOpenMutation,
} from "domains/inbox/hooks/mutations";
import { useViewportSize } from "domains/inbox/hooks/util/";
import { useConversationActionAnalytics } from "domains/inbox/hooks/util/useConversatonActionAnalytics";
import { userflowIds } from "domains/inbox/util";
import { toast } from "react-toastify";

import {
    StyledDoneButton,
    StyledDoneLoadingIconWrapper,
    StyledReopenText,
} from "./ToggleStatusActionButton.styles";

export type ToggleStatusActionButtonProps = {
    conversation: Conversation;
    productOriginAnalytics: Parameters<
        typeof useConversationActionAnalytics
    >[0]["productOrigin"];
};

export const ToggleStatusActionButton = ({
    conversation,
    productOriginAnalytics,
}: ToggleStatusActionButtonProps) => {
    const analytics = useConversationActionAnalytics({
        conversation,
        productOrigin: productOriginAnalytics,
    });
    const size = useViewportSize();
    const markDoneText = size !== "xs" ? "Mark done" : "Done";

    const markAsDone = useMarkAsDoneMutation({
        onSuccess: () =>
            analytics.trackConversationActionButtonResponse("Done", false),
        onError: () => {
            analytics.trackConversationActionButtonResponse("Done", true);
            toast(
                <UI.Feedback title="Action failed" colour="error">
                    <UI.Text as="span">
                        Sorry, we couldn't mark this conversation as done
                    </UI.Text>
                </UI.Feedback>,
            );
        },
    });
    const markAsOpen = useMarkAsOpenMutation({
        onSuccess: () =>
            analytics.trackConversationActionButtonResponse("Reopen", false),
        onError: () => {
            analytics.trackConversationActionButtonResponse("Reopen", true);
            toast(
                <UI.Feedback title="Action failed" colour="error">
                    <UI.Text as="span">
                        Sorry, we couldn't reopen this conversation
                    </UI.Text>
                </UI.Feedback>,
            );
        },
    });

    const onClickMarkAsDoneButton = () => {
        analytics.trackConversationActionButtonClick("Done");
        markAsDone.mutate(conversation);
    };
    const onClickMarkAsOpenButton = () => {
        analytics.trackConversationActionButtonClick("Reopen");
        markAsOpen.mutate(conversation);
    };

    switch (conversation.status) {
        case "Open":
            return (
                <Pill.Button
                    radius="full"
                    onClick={onClickMarkAsDoneButton}
                    aria-label="Mark conversation as done"
                    data-userflow-id={
                        userflowIds.conversationView.markDoneButton
                    }
                    isLoading={markAsDone.isLoading}
                >
                    <Pill.Text>{markDoneText}</Pill.Text>
                </Pill.Button>
            );

        case "Done":
            return (
                <StyledDoneButton
                    radius="full"
                    onClick={onClickMarkAsOpenButton}
                    isLoading={markAsOpen.isLoading}
                    aria-label="Reopen conversation"
                >
                    <StyledDoneLoadingIconWrapper
                        $visibility={
                            markAsOpen.isLoading ? "visible" : "hidden"
                        }
                    >
                        <Pill.Icon isLoading />
                    </StyledDoneLoadingIconWrapper>
                    {/**
                     * Kept this in the DOM so that the button width does not change
                     * when the spinner replaces the text when the button is clicked
                     */}
                    <StyledReopenText
                        $colour={
                            markAsOpen.isLoading ? "transparent" : undefined
                        }
                    >
                        Reopen
                    </StyledReopenText>
                </StyledDoneButton>
            );
        case "None":
            return null;
    }
};
