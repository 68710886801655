import { ReactElement, ReactNode } from "react";

import { Feedback } from "@accurx/design";
import * as Sentry from "@sentry/react";
import { toast } from "react-toastify";

export const ProductErrorBoundary = ({
    name,
    fallback,
    children,
    showToast,
}: {
    name: "PatientSearch" | "Directory";
    fallback?: ReactElement;
    showToast?: boolean;
    children: ReactNode;
}) => {
    const handleToast = () => {
        toast(
            <Feedback
                title="Sorry, something went wrong. Refresh the page to try again."
                colour="error"
            />,
        );
    };

    return (
        <Sentry.ErrorBoundary
            beforeCapture={(scope) => scope.setTag("product", name)}
            fallback={fallback}
            onError={showToast ? handleToast : undefined}
        >
            {children}
        </Sentry.ErrorBoundary>
    );
};
