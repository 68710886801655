import { Ds, TextareaAutosize } from "@accurx/design";
import { BORDERS, TYPOGRAPHY } from "@accurx/design/dist/styles/tokens";
import styled from "styled-components";

export const StyledTextArea = styled(TextareaAutosize)`
    width: 100%;
    height: 100%;
    resize: none;
    padding: 0.5rem;
    display: block;
    font-size: ${TYPOGRAPHY.preview.desktop.fontSize};
    border: 1px solid #8185a8;
    border-radius: ${BORDERS.radius};
    &:focus {
        border: 1px solid #191c4dff;
        outline: none;
    }
    overflow: hidden; /* Because of autosize, having scrollbar causes the width to change so the calculated height is wrong */
`;

export const StyledBox = styled(Ds.Box)`
    border-left: 4px solid #e0e1eb;
    padding-left: 1rem;
    margin-left: 0.4rem;
`;

export const StyledSelectWrapper = styled.div`
    margin-bottom: 1rem;
    margin-top: 0.25rem;
    * {
        font-size: 14px;
    }
`;
