import * as UI from "@accurx/design";
import {
    ChooseClinicianValue,
    ClinicianAvailabilityOption,
} from "domains/self-book/utils/types";

import { CustomRadio } from "../../CustomRadio";
import { RadioTextWrapper } from "../AppointmentType/AppointmentType.styles";
import {
    ErrorPositioningWrapper,
    StyledSearchSelect,
} from "../SelfbookConfigurationForm.styles";
import { StyledHeader } from "./ChooseClinician.styles";

type ChooseClinicianProps = {
    options: ClinicianAvailabilityOption[];
    initialOption:
        | {
              clinicians: ClinicianAvailabilityOption[];
              clinicianType: ChooseClinicianValue;
          }
        | undefined;
    onChange: (
        selected: ClinicianAvailabilityOption[] | undefined,
        chooseClinicianType: ChooseClinicianValue,
    ) => void;
    errors: string[] | undefined;
    showLoading?: boolean;
};

type ChooseClinicianOption = {
    id: string;
    label: string;
    value: ChooseClinicianValue;
};

const radioInputs: ChooseClinicianOption[] = [
    { id: "anyClinician", label: "Any clinician", value: "AnyClinician" },
    {
        id: "specificClinicians",
        label: "Specific clinicians",
        value: "SpecificClinicians",
    },
];

export const ChooseClinician = ({
    options,
    initialOption,
    onChange,
    errors,
    showLoading,
}: ChooseClinicianProps) => {
    const label = "Clinicians available for booking";
    const id = "choose-clinician-filter";

    const onChooseClinicianTypeChange = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        const clinicianType = event.target.value as ChooseClinicianValue;
        onChange(initialOption?.clinicians, clinicianType);
    };

    return (
        <UI.Ds.Box py="0.75rem">
            <StyledSearchSelect>
                <UI.FormFieldWrapper
                    label={
                        <StyledHeader>
                            <UI.Text variant="note" skinny>
                                {label}
                            </UI.Text>
                        </StyledHeader>
                    }
                    labelProps={{ htmlFor: id }}
                    errors={errors?.map((item) => {
                        return (
                            <ErrorPositioningWrapper>
                                <UI.Text as="span" variant="preview">
                                    {item}
                                </UI.Text>
                            </ErrorPositioningWrapper>
                        );
                    })}
                >
                    <>
                        {radioInputs.map((item) => (
                            <UI.Cell key={item.label}>
                                <CustomRadio
                                    name={"choose-clinician-type"}
                                    id={item.value}
                                    label={
                                        <RadioTextWrapper>
                                            <UI.Text variant="preview" skinny>
                                                {item.label}
                                            </UI.Text>
                                        </RadioTextWrapper>
                                    }
                                    value={item.value}
                                    checkedValue={initialOption?.clinicianType}
                                    onChange={onChooseClinicianTypeChange}
                                />
                            </UI.Cell>
                        ))}
                        {initialOption?.clinicianType ===
                            "SpecificClinicians" && (
                            <UI.SearchSelect
                                id={id}
                                key={initialOption.clinicians
                                    .flatMap((item) => item.count)
                                    .toString()}
                                label={label}
                                options={options}
                                dimension="medium"
                                handleCallbacks={{
                                    onClearAll: () => {
                                        onChange(
                                            [],
                                            initialOption.clinicianType,
                                        );
                                    },
                                    onCollapse: (selected) =>
                                        onChange(
                                            selected as ClinicianAvailabilityOption[],
                                            initialOption.clinicianType,
                                        ),
                                    onRemove: (selected) => {
                                        onChange(
                                            selected as ClinicianAvailabilityOption[],
                                            initialOption.clinicianType,
                                        );
                                    },
                                }}
                                defaultCopy={{
                                    placeholder: "Search for a name",
                                }}
                                initSelectedValues={initialOption.clinicians}
                                error={errors && errors.length > 0}
                                theme="MULTISELECT"
                                showLoading={showLoading}
                            />
                        )}
                    </>
                </UI.FormFieldWrapper>
            </StyledSearchSelect>
        </UI.Ds.Box>
    );
};
