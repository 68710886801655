import * as UI from "@accurx/design";
import { OrganisationOption } from "domains/self-book/utils/types";

import {
    ErrorPositioningWrapper,
    StyledSingleSelect,
} from "../SelfbookConfigurationForm.styles";

type OrganisationProps = {
    options: OrganisationOption[];
    initialOption?: OrganisationOption;
    onChange: (selected: OrganisationOption) => void;
    errors: string[] | undefined;
};

export const ChooseOrganisation = ({
    options,
    initialOption,
    onChange,
    errors,
}: OrganisationProps) => {
    const label = "Organisation";
    const id = "organisation-filter";
    return (
        <UI.Ds.Box py="0.75rem">
            <UI.FormFieldWrapper
                label={
                    <UI.Text variant="note" skinny>
                        {label}
                    </UI.Text>
                }
                labelProps={{ htmlFor: id }}
                errors={errors?.map((item) => {
                    return (
                        <ErrorPositioningWrapper>
                            <UI.Text as="span" variant="preview">
                                {item}
                            </UI.Text>
                        </ErrorPositioningWrapper>
                    );
                })}
            >
                <StyledSingleSelect>
                    <UI.SearchSelect
                        id={id}
                        dimension="medium"
                        label={label}
                        options={options}
                        // Types here are inaccurate, returns Option for single select
                        onChangeHandler={(selected) =>
                            onChange(selected as OrganisationOption)
                        }
                        initSelectedValues={initialOption}
                        error={errors && errors.length > 0}
                        zIndex={3}
                    />
                </StyledSingleSelect>
            </UI.FormFieldWrapper>
        </UI.Ds.Box>
    );
};
