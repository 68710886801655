import React from "react";

import { FeatureName } from "@accurx/auth";
import { Redirect, Switch, generatePath } from "react-router";

import { SimpleRoute } from "app/AppRoute";
import { FeatureGuard } from "app/FeatureGuard";
import { AccubookPage } from "app/organisations/accubook/AccubookPage";
import { AllMessagesPage } from "app/organisations/allMessages/AllMessagesPage";
import { AppointmentRemindersPage } from "app/organisations/appointmentReminders/AppointmentRemindersPage";
import { HomePage } from "app/organisations/home/HomePage";
import {
    useCurrentOrganisation,
    useLoadPractices,
    useResetView,
} from "app/organisations/hooks";
import { AccumailEditTemplatePage } from "app/practices/accumail/editTemplate/EditTemplatePage";
import { ManageAccumailTemplates } from "app/practices/accumail/manageTemplates/ManageAccumailTemplates";
import { ROUTES_CHAIN, ROUTES_ORGS } from "shared/Routes";
import { useIsFeatureEnabled } from "store/hooks";

import { PracticesLayout } from "./shared/Layout";

export const Routes = (): JSX.Element => {
    useResetView();
    useLoadPractices();
    const org = useCurrentOrganisation();
    const enabled = useIsFeatureEnabled(FeatureName.NewManageOrganisationPage);

    if (!enabled) {
        const fallback = org
            ? ROUTES_CHAIN.practicesDetails
            : ROUTES_CHAIN.practices;

        return (
            <Switch>
                <Redirect
                    from={ROUTES_ORGS.accubook.replace(
                        ":tab?",
                        "manage-patients",
                    )}
                    to={ROUTES_CHAIN.practicesVaccineAllPatientsInvited}
                />
                <Redirect
                    from={ROUTES_ORGS.accubook.replace(
                        ":tab?",
                        "manage-clinics",
                    )}
                    to={ROUTES_CHAIN.practicesVaccineSiteSetup}
                />
                <Redirect
                    from={ROUTES_ORGS.accubook.replace(
                        ":tab?",
                        "booked-patients",
                    )}
                    to={ROUTES_CHAIN.practicesVaccineBookedClinics}
                />
                <Redirect
                    from={ROUTES_ORGS.accubook.replace(
                        ":tab?",
                        "delivery-sites",
                    )}
                    to={ROUTES_CHAIN.practicesVaccineApprovalRequests}
                />
                <Redirect
                    from={ROUTES_ORGS.accubook.replace(":tab?", "dashboard")}
                    to={ROUTES_CHAIN.practicesVaccineCapacityDashboard}
                />
                <Redirect
                    from={ROUTES_ORGS.appointmentReminders}
                    to={ROUTES_CHAIN.practicesAppointmentReminder}
                />

                <Redirect
                    from={ROUTES_ORGS.allMessages.replace(
                        ":tab?",
                        "batch-messages",
                    )}
                    to={ROUTES_CHAIN.practicesBatchMessaging}
                />

                <Redirect
                    from={ROUTES_ORGS.org}
                    to={ROUTES_CHAIN.practicesDetails}
                />

                <Redirect to={fallback} />
            </Switch>
        );
    }

    return (
        <PracticesLayout>
            <Switch>
                {org && (
                    <Switch>
                        <SimpleRoute path={ROUTES_ORGS.org} exact>
                            <HomePage />
                        </SimpleRoute>
                        <SimpleRoute path={ROUTES_ORGS.accubook}>
                            <AccubookPage />
                        </SimpleRoute>
                        <SimpleRoute path={ROUTES_ORGS.allMessages}>
                            <AllMessagesPage />
                        </SimpleRoute>
                        <SimpleRoute path={ROUTES_ORGS.appointmentReminders}>
                            <AppointmentRemindersPage />
                        </SimpleRoute>
                        <SimpleRoute
                            path={ROUTES_ORGS.accumailManageTemplatesCreate}
                        >
                            <FeatureGuard all={[FeatureName.ExternalEmail]}>
                                <AccumailEditTemplatePage saveMode="create" />
                            </FeatureGuard>
                        </SimpleRoute>
                        <SimpleRoute
                            path={[ROUTES_ORGS.accumailManageTemplatesEdit]}
                        >
                            <FeatureGuard all={[FeatureName.ExternalEmail]}>
                                <AccumailEditTemplatePage saveMode="edit" />
                            </FeatureGuard>
                        </SimpleRoute>
                        <SimpleRoute
                            path={[ROUTES_ORGS.accumailManageTemplatesCopy]}
                        >
                            <FeatureGuard all={[FeatureName.ExternalEmail]}>
                                <AccumailEditTemplatePage saveMode="copy" />
                            </FeatureGuard>
                        </SimpleRoute>

                        <SimpleRoute
                            path={[ROUTES_ORGS.accumailManageTemplates]}
                        >
                            <FeatureGuard all={[FeatureName.ExternalEmail]}>
                                <ManageAccumailTemplates />
                            </FeatureGuard>
                        </SimpleRoute>
                        <Redirect
                            to={generatePath(ROUTES_ORGS.org, {
                                orgId: org.orgId,
                            })}
                        />
                    </Switch>
                )}
                <Redirect to={ROUTES_CHAIN.practices} />
            </Switch>
        </PracticesLayout>
    );
};

export default Routes;
