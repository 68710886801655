import { patientNhsNumber } from "@accurx/concierge/util/patientNhsNumber";
import { PatientDemographics } from "@accurx/native";
import { mapPatientsNames } from "@accurx/patient";
import { FormPdsResult } from "domains/inbox/components/PatientSearch/components/forms/types";
import { formatDateShortWithHyphens } from "domains/inbox/util/format/date";

import { PatientSearchResult } from "./types";

export const mapPatientDemographicsToPatientSearchResult = (
    data: PatientDemographics,
): PatientSearchResult => {
    const names = mapPatientsNames(data.patientName.usual);
    return {
        nhsNumber:
            patientNhsNumber(
                data.patientExternalIdentityDto.patientExternalIds,
            ) ?? null,
        dateOfBirth: data.dateOfBirth
            ? formatDateShortWithHyphens(data.dateOfBirth)
            : null,
        gender: data.gender,
        firstName: names.firstName,
        familyName: names.familyName,
        externalIds: data.patientExternalIdentityDto.patientExternalIds,
    };
};

export const mapPdsResultToPatientSearchResult = ({
    patient,
    patientToken,
}: FormPdsResult): PatientSearchResult => {
    return {
        nhsNumber: patient.nhsNumber,
        dateOfBirth: patient.dateOfBirth
            ? formatDateShortWithHyphens(patient.dateOfBirth)
            : null,
        gender: patient.gender ?? "Unknown",
        firstName: patient.firstName ?? "",
        familyName: patient.familyName ?? "",
        patientToken: patientToken ?? undefined,
    };
};
