import { useAnalytics } from "@accurx/analytics";
import { convertAvailabilityPeriodToDays } from "domains/self-book/utils/appointmentAvailabilityPeriodConversions";
import {
    AppointmentAvailabilityPeriod,
    AppointmentTypeValue,
    SelfbookConfigurationPayload,
} from "domains/self-book/utils/types";

export type AppOrigin = "Compose" | "UnifiedInbox";

type UseSelfBookConfigurationAnalyticsArgs = {
    appOrigin: AppOrigin;
};

export const useSelfBookConfigurationAnalytics = ({
    appOrigin,
}: UseSelfBookConfigurationAnalyticsArgs) => {
    const track = useAnalytics();

    const trackingFields = {
        appOrigin,
    };

    const trackSlotTypeMenuItemClick = ({
        slotName,
        error,
    }: {
        slotName: string;
        error: string[] | undefined;
    }) => {
        track("BookingSlotType MenuItem Click", {
            ...trackingFields,
            slotName,
            errorReason: error,
            hasError: !!error,
        });
    };
    const trackAppointmentTypeOptionSelect = ({
        appointmentType,
    }: {
        appointmentType: AppointmentTypeValue;
    }) => {
        track("BookingAppointmentType Option Select", {
            ...trackingFields,
            appointmentType,
        });
    };

    const trackBookingInviteCloseButtonClick = () => {
        track("BookingInviteClose Button Click", {
            ...trackingFields,
        });
    };

    const trackBookingAddConfirmButtonClick = ({
        selfBookConfig,
        defaultAppointmentAvailabilityPeriod,
    }: {
        selfBookConfig: SelfbookConfigurationPayload;
        defaultAppointmentAvailabilityPeriod: AppointmentAvailabilityPeriod;
    }) => {
        const defaultAppointmentAvailabilityDays =
            convertAvailabilityPeriodToDays(
                defaultAppointmentAvailabilityPeriod,
            );
        const appointmentAvailabilityDays = convertAvailabilityPeriodToDays(
            selfBookConfig.appointmentAvailabilityPeriod,
        );

        track("ConversationBookingAddConfirm Button Click", {
            ...trackingFields,
            appointmentType: selfBookConfig.appointmentType,
            slotName: selfBookConfig.slotType,
            clinicianGroupIdList: selfBookConfig.specificClinician?.map(
                (x) => x.clinicianGroupId,
            ),
            defaultAppointmentAvailabilityDays,
            appointmentAvailabilityDays,
            withAppointmentAvailability:
                defaultAppointmentAvailabilityDays !==
                appointmentAvailabilityDays,
        });
    };

    const trackBookingResourceLinkClick = ({
        resourceLink,
    }: {
        resourceLink: string;
    }) => {
        track("BookingResource Link Click", {
            ...trackingFields,
            resourceLink,
        });
    };

    return {
        trackSlotTypeMenuItemClick,
        trackAppointmentTypeOptionSelect,
        trackBookingInviteCloseButtonClick,
        trackBookingAddConfirmButtonClick,
        trackBookingResourceLinkClick,
    };
};
