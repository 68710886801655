import { usePatient } from "@accurx/concierge/hooks/data/usePatient";
import { Conversation } from "@accurx/concierge/types";
import { PatientName, formatPatientName } from "@accurx/patient";
import { PatientNotFound } from "domains/inbox/components/PatientNotFound/PatientNotFound";

export const ConversationPreviewPatientName = ({
    conversation,
}: {
    conversation: Conversation;
}) => {
    const patient = usePatient({ patientId: conversation.regardingPatientId });
    const patientName = formatPatientName({
        firstName: patient?.firstName,
        familyName: patient?.familyName,
    });
    return patient ? (
        <PatientName
            name={patientName}
            ageYear={patient.ageYear}
            gender={patient.gender}
            textProps={{ className: "text-item" }}
        />
    ) : (
        <PatientNotFound />
    );
};
