import React from "react";

import { FeatureName } from "@accurx/auth";
import { createRouterState } from "@accurx/compose";
import { useFullScreenNavigate } from "@accurx/navigation";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { FlemingAnalyticsTracker } from "app/analytics";
import { actionCreators } from "app/selectProduct/SelectProductActions";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { OrganisationHelper } from "shared/OrganisationHelper";
import { PatientHelper } from "shared/PatientHelper";
import { ROUTES_EXTENSION } from "shared/Routes";
import { findBaseRoute, getTwoFactorRouteWithNext } from "shared/RoutesHelper";
import { useAppSelector } from "store/hooks";

import { FeatureButton, Option } from "../FeatureButton";
import {
    isMessageGpDisabled,
    isRecordViewDisabled,
} from "../PatientProfile.helper";

const GPServicesButton = () => {
    const lastResponse = useAppSelector(
        (state) => state.searchForPatient.lastResponse,
    );
    const loggedInProps = useFlemingLoggedInAnalytics();
    const searchPatientOrigin = useAppSelector(
        ({ selectProduct }) => selectProduct.searchPatientOrigin,
    );
    const commonProps = {
        ...loggedInProps,
        navigationLocation: "Header" as const,
        searchPatientOrigin,
    };
    const patient = PatientHelper.getPatient(lastResponse);
    const organisation = useAppSelector(({ account }) =>
        OrganisationHelper.getOrganisation(account),
    );
    const history = useHistory();
    const dispatch = useDispatch();
    const is2FAed = useAppSelector(
        ({ twoFactor }) => twoFactor.isAuthenticated,
    );
    const isDemoPatient = PatientHelper.isDemoPatient(patient?.nhsNumber ?? "");

    const fullScreenNavigate = useFullScreenNavigate();

    const redirectToRecordView = () => {
        dispatch(actionCreators.selectRecordViewType());

        const analyticsProps = {
            ...commonProps,
            optionSelected: "RecordView" as const,
            productAvailableForPatient: true, // If this is enabled the product is available
        };

        analyticsProps &&
            FlemingAnalyticsTracker.trackProductOptionMenuItemClick(
                analyticsProps,
            );

        const route =
            !is2FAed && !isDemoPatient
                ? getTwoFactorRouteWithNext(
                      `${findBaseRoute(history.location.pathname)}${
                          ROUTES_EXTENSION.remoteRecordView
                      }`,
                  )
                : `${findBaseRoute(history.location.pathname)}${
                      ROUTES_EXTENSION.remoteRecordView
                  }`;

        history.push(route, { referredFrom: ROUTES_EXTENSION.patientProfile });
    };

    const redirectToMessagePractice = () => {
        dispatch(actionCreators.selectMessagePracticeProductType());
        const analyticsProps = {
            ...commonProps,
            optionSelected: "MessagePractice" as const,
            productAvailableForPatient: true, // If this is enabled the product is available
        };

        analyticsProps &&
            FlemingAnalyticsTracker.trackProductOptionMenuItemClick(
                analyticsProps,
            );

        if (organisation?.orgId) {
            fullScreenNavigate(
                `/w/${organisation.orgId}/compose/clinician-compose`,
                createRouterState({
                    patient: patient,
                    patientToken:
                        lastResponse?.searchedResult?.patientToken ?? null,
                    appOrigin: "PatientProfile",
                }),
            );
        }
    };

    const recordViewOption: Option = {
        title: "View GP record",
        icon: { name: "Record", colour: "night" },
        action: redirectToRecordView,
        disabledInfo: isRecordViewDisabled(patient),
    };

    const messageGpOption: Option = {
        title: "Message GP practice",
        icon: { name: "Mention", colour: "night" },
        action: redirectToMessagePractice,
        userflowId: "message-gp-patient-profile-gp-services-dropdown",
        disabledInfo: isMessageGpDisabled(patient),
    };

    const isAccuMailEnabledForUser = OrganisationHelper.isFeatureEnabled(
        organisation,
        FeatureName.CaregiverInitiated,
    );

    const options: Option[] = isAccuMailEnabledForUser
        ? [recordViewOption, messageGpOption]
        : [recordViewOption];

    if (!options.length) {
        return null;
    }

    return (
        <FeatureButton
            title="GP services"
            icon={{ name: "Doctor", colour: "blue" }}
            options={options}
        />
    );
};

export { GPServicesButton };
