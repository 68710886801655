import {
    MessagePatientResponse,
    TicketIdentity,
} from "@accurx/api/patient-messaging";
import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import { mapPatientDemographicsToPatientDemographicDetailsDto } from "domains/message-component/api/mappers/mapPatientDemographicsToPatientDemographicDetailsDto";

import {
    messagePatientWithExternalIdentity,
    messagePatientWithToken,
} from "../../api";
import { mapPatientExternalIdsToPatientExternalIdentityDto } from "../../api/mappers/mapPatientExternalIdsToPatientExternalIdentityDto";
import { mapSendMessageVariablesToBaseSendMessageRequest } from "../../api/mappers/mapSendMessageVariablesToBaseSendMessageRequest";
import { OnMessageSendFnComposeData } from "../../components/Compose/types/Compose.types";
import { ComposePatient } from "../../schemas/ComposePatientSchema";

export type AssignOnSendAssignee = {
    type: "User" | "Team";
    id: string;
};

export type SendMessageMutationVariables = {
    composedMessage: OnMessageSendFnComposeData & {
        /** When null, if someone will be assigned to it, it will be the current user */
        assignee: AssignOnSendAssignee | null;
    };
    /** Information to send additionally to the composed message */
    options: {
        /** Whether to use the with token endpoint or the with external id one */
        patientToken: string | null;
        /** Patient's external identifiers, could be nhs number, emis etc */
        patientExternalIds: ComposePatient["externalIds"];
        /** Patient demographics from the EMR. These will be saved in the DB */
        patientDemographics: ComposePatient["demographics"];
        /** The workspace the user sent the message from */
        workspaceId: number;
        /** Whether to reply or initiate a new conversation */
        ticketIdentity: TicketIdentity | null;
        /** Whether the message should be sent via NHS App */
        trySendViaNhsApp: boolean;
    };
};

export const useSendMessageMutation = (
    options?: UseMutationOptions<
        MessagePatientResponse,
        Error,
        SendMessageMutationVariables
    >,
) => {
    const mutation = useMutation<
        MessagePatientResponse,
        Error,
        SendMessageMutationVariables
    >({
        mutationKey: ["SendMessage"],
        mutationFn: async (
            args: SendMessageMutationVariables,
        ): Promise<MessagePatientResponse> => {
            const baseRequestPayload =
                mapSendMessageVariablesToBaseSendMessageRequest(args);

            // if there is a token, use it, otherwise try with id
            if (args.options.patientToken) {
                return messagePatientWithToken({
                    ...baseRequestPayload,
                    patientToken: args.options.patientToken,
                    useMobileNumberFromPds:
                        args.composedMessage.contactDetails.origin === "PDS",
                });
            }

            const demographicsMobileNumber =
                args.composedMessage.contactDetails.method === "Mobile" &&
                args.composedMessage.contactDetails.origin === "EMR"
                    ? args.composedMessage.contactDetails.value
                    : null;

            return messagePatientWithExternalIdentity({
                ...baseRequestPayload,
                patientExternalIdentity:
                    mapPatientExternalIdsToPatientExternalIdentityDto(
                        args.options.patientExternalIds,
                    ),
                /** We only need to send this value if the number we want to send the message to is from the EMR.
                 * This is so the server knows to do a fresh EMR query at the time of sending the message,
                 * in the case of scheduled messages. If this value is not set, or is not equal to the mobileNumber
                 * we send, then the number from the EMR will not be used.
                 */
                demographicsMobileNumber,
                patientDemographics: args.options.patientDemographics
                    ? mapPatientDemographicsToPatientDemographicDetailsDto({
                          patientDemographics: args.options.patientDemographics,
                          demographicMobileNumber: demographicsMobileNumber,
                      })
                    : null,
            });
        },
        ...options,
        // Figured it better to allow the user to handle the connection errors themselves. React query default to online meaning it waits for a connection to come back before attempting to send the message. I think it's safer for the user to know the message hasn't been sent so they can check their PCs network connection rather then thinking it has been sent.
        networkMode: "always",
    });

    return mutation;
};
