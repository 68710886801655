import * as UI from "@accurx/design";
import { TextareaAutosize } from "@accurx/design";
import styled from "styled-components";

const { COLOURS, TYPOGRAPHY } = UI.Tokens;

export const StyledInnerTextarea = styled(TextareaAutosize).attrs({
    spellCheck: true,
})`
    display: block;
    border: none;
    color: ${COLOURS.greyscale.night};
    font-size: ${TYPOGRAPHY.preview.desktop.fontSize};
    line-height: ${TYPOGRAPHY.preview.desktop.lineHeight};

    background: transparent;
    overflow: hidden;

    /* Compensate for padding on outer text area */
    padding: ${UI.Tokens.SIZES[0.5]};
    margin: -${UI.Tokens.SIZES[0.5]};

    &:focus-visible {
        outline: none;
    }
`;
