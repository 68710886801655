import { ReactNode, createContext, useContext, useReducer } from "react";

import {
    type ComposeProviderSettings,
    convertMessageSettingsToMessageState,
} from "domains/message-component/utils/convertMessageSettingsToMessageState";
import isEqual from "lodash/isEqual";
import omit from "lodash/omit";

import { composeReducer } from "./reducer";
import type { ComposeContextType, ComposeStateType } from "./reducer.types";

export const ComposeContext = createContext<ComposeContextType | null>(null);

const isEqualWithExclusions = (
    state1: ComposeStateType,
    state2: ComposeStateType,
    exclusions: (keyof ComposeStateType)[],
) => isEqual(omit(state1, exclusions), omit(state2, exclusions));

export const ComposeProvider = ({
    children,
    settings,
}: {
    children: ReactNode;
    settings: ComposeProviderSettings;
}) => {
    const initialState: ComposeStateType =
        convertMessageSettingsToMessageState(settings);
    const [state, dispatch] = useReducer(composeReducer, initialState);
    const isComposing =
        state.isMultipleMessages ||
        !isEqualWithExclusions(state, initialState, [
            "isScheduling",
            "contactDetails",
        ]);

    return (
        <ComposeContext.Provider
            value={{
                state,
                isComposing,
                shouldTryToFetchConsent:
                    settings.shouldTryToFetchConsent ?? false,
                dispatch,
                templatesComboboxMustOpenDownwards:
                    settings.templatesComboboxMustOpenDownwards,
            }}
        >
            {children}
        </ComposeContext.Provider>
    );
};

export const useCompose = () => {
    const context = useContext(ComposeContext);

    if (context === null) {
        throw new Error("useCompose must be used within an ComposeProvider");
    }
    return context;
};

export const useAllMessagesCompose = (): ComposeContextType => {
    const context = useContext(ComposeContext);

    if (context === null) {
        throw new Error(
            "useAllMessagesCompose must be used within an ComposeProvider",
        );
    }

    return {
        ...context,
        state: {
            ...context.state,
            isMultipleMessages: false,
        },
    };
};
