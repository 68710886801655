import { RegionClassification } from "@accurx/api/portal";
import { RouteComponentProps } from "react-router-dom";

import type { AccountState } from "app/account/AccountState.types";
import { PracticesReducerState } from "app/practices/Practices.reducer";
import { FeatureFlag } from "app/practices/Practices.types";
import { UpdatingStatus, UpdatingStatusType } from "shared/LoadingStatus";
import { Values } from "shared/types/utils.types";

import * as Actions from "../PatientTriage.actions";

type OutOfHoursSettings = {
    outOfHoursAllowAdminRequests: boolean | null;
    outOfHoursDisableFromHour: number | null;
    outOfHoursDisableToHour: number | null;
    outOfHoursSuspendPatientTriage: boolean;
};

export const Days = {
    Sunday: 0,
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6,
} as const;

export type Day = Values<typeof Days>;

export type WorkingHours = {
    day?: Day;
    limit: number | null;
    from: string | null;
    to: string | null;
};

export type WorkingHoursSuspensionMessageSettings = {
    messageHeader: string | null;
};

export type BankHolidayWorkingHours = Omit<WorkingHours, "day">;

export type WorkingHoursSettings = {
    suspendRequestsOutOfHours: boolean;
    allowAdminOutOfHours: boolean;
    excludeAdminFromLimit: boolean;
    workingHours: WorkingHours[];
    bankHolidayWorkingHours: BankHolidayWorkingHours;
    suspensionMessageHeader?: string | null;
};

export interface PatientTriageState {
    mostRecentlyClickedEdit: string;
    hasClickedEditWelcomePage: boolean;
    hasClickedEditSubmissionPage: boolean;
    hasClickedEditSubmissionSms: boolean;
    hasClickedEditOohPage: boolean;
    hasClickEditScheduleOoh: boolean;
    hasClickEditPTPage: boolean;
    welcomePageMessage: string | null;
    welcomePageMessageEditing: string | null;
    submissionPageMessage: string | null;
    submissionPageMessageEditing: string | null;
    submissionPageSms: string | null;
    submissionPageSmsEditing: string | null;
    outOfHoursPageMessage: string | null;
    outOfHoursPageMessageEditing: string | null;
    ptPageMessage: {
        disabledMessageHeading: string | null;
        disabledMessageBody: string | null;
    };
    ptPageMessageEdit: {
        disabledMessageHeading: string | null;
        disabledMessageBody: string | null;
    };

    // Suspend PT properties
    suspendPTUntil: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    suspendPTAdminRequests: boolean;
    suspendPTDisableSwitch: boolean;
    suspendPTDisableReason: string;
    suspendPTDisableComment: string;
    suspendPTEditing: boolean;
    suspendPTHasUpdated: boolean;
    suspendPTPrevState: {
        suspendPTUntil: any; // eslint-disable-line @typescript-eslint/no-explicit-any
        suspendPTAdminRequests: boolean | null;
        suspendPTDisableSwitch: boolean | null;
        suspendPTDisableReason: string;
        suspendPTDisableComment: string;
    };

    // Repeat prescription properties
    isPrescriptionEnabled: boolean;
    isPrescriptionRedirectEnabled: boolean;
    isPrescriptionFormEnabled: boolean;
    prescriptionRedirectTitle: string;
    prescriptionRedirectUrl: string;
    isPrescriptionEditing: boolean;
    prescriptionPrevState: {
        isPrescriptionEnabled: boolean;
        isPrescriptionRedirectEnabled: boolean;
        isPrescriptionFormEnabled: boolean;
        prescriptionRedirectTitle: string;
        prescriptionRedirectUrl: string;
    };

    outOfHoursEnabled: boolean | null;
    outOfHoursSettings: OutOfHoursSettings;
    outOfHoursPrevState: {
        outOfHoursEnabled: boolean | null;
        outOfHoursSettings?: OutOfHoursSettings;
    };
    hasUpdatedWelcomePageMessage: boolean;
    hasUpdatedSubmissionPageMessage: boolean;
    hasUpdatedSubmissionSmsMessage: boolean;
    hasUpdatedOohPageMessage: boolean;
    hasUpdatedPTPageMessage: boolean;
    hasUpdatedDisableUntilSettings: boolean;
    hasUpdatedOohSettings: boolean;
    pulledInitialPrescriptionValue: boolean;
    pulledInitialDisableUntilValue: boolean;
    pulledInitialOutOfHoursValue: boolean;
    pulledInitialWelcomePageMessage: boolean;
    pulledInitialSubmissionPageMessage: boolean;
    pulledInitialSubmissionSmsMessage: boolean;
    pulledInitialOohMessage: boolean;
    pulledInitialOohPageValue: boolean;
    sentLoadedAnalytics: boolean | null;
    urlError: boolean;
    isUrlEmpty: boolean;
    isTitleEmpty: boolean;
    hasUpdatedResponseTimeSettings: boolean | null;
    flagAllowAdminRequests: boolean;
    flagIsSet: boolean;
    flagPatientTriageDashboardsEnabled: boolean;
}

export interface PatientTriageStatus {
    allowAdminRequests: boolean;
    disabledMessageBody: string | null;
    disabledMessageHeading: string | null;
    disableUntilDate: string | null;
    enabled: boolean;
    enableByDate: string | null;
    isAdmin: boolean;
    outOfHoursEnabled: boolean;
    outOfHoursPageMessage: string | null;
    outOfHoursSettings: OutOfHoursSettings | null;
    submissionPageMessage: string | null;
    submissionPageSms: string | null;
    responseTimeWorkingDays: number;
    url: string;
    welcomePageMessage: string | null;
    patientSelfReferralSettings: {
        patientSelfReferralEnabled: boolean;
        patientSelfReferralMessage: string | null;
    };
    prescriptionSettings: {
        isEnabled: boolean;
        isRedirectEnabled: boolean;
        redirectSettings: {
            isPrescriptionFormEnabled: boolean;
            title: string;
            url: string;
        };
    };
    workingHoursSettings: WorkingHoursSettings;
    region: RegionClassification;
}

export interface PatientTriageData {
    accountState: AccountState;
    actions: typeof Actions;
    loadingStatus: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    practiceId: string;
    practiceName: string;
    practices: PracticesReducerState;
    status: PatientTriageStatus;
    triageSettingsUpdatingStatus: UpdatingStatusType;
    updatingStatus: UpdatingStatus;
    features: FeatureFlag[];
}

export type PatientTriageProps = PatientTriageData &
    RouteComponentProps<Record<string, string | undefined>>;

export type DashboardData = {
    date: string;
    type: string;
    isReception: boolean;
};

export enum TabIds {
    WhatIsPatientTriage = "What is Patient Triage?",
    Settings = "Settings",
    SetUpPatientTriage = "Set up Patient Triage",
}
