import { useState } from "react";

import {
    Ds,
    Popover,
    PopoverContent,
    PopoverItemButton,
    PopoverTrigger,
} from "@accurx/design";
import { Icon } from "@accurx/design/dist/ds";
import {
    ModalBackButton,
    ModalMoreButton,
    StyledModalHeader,
    StyledModalHeaderDivider,
} from "@accurx/navigation";
import { ArchivedWorkspaceHiddenItemWrapper } from "@accurx/workspace-management";
import styled from "styled-components";

export type ModalHeaderProps = {
    title?: string;
    onClickTestPatient?: (state: boolean) => void;
    onBack?: () => void;
    isSecondPanel?: boolean;
};

export const ModalHeader = ({
    title,
    onClickTestPatient,
}: ModalHeaderProps) => (
    <StyledModalHeader>
        <Ds.Modal.Title>{title || "Search patients"}</Ds.Modal.Title>

        <Ds.Flex justifyContent="flex-end" gap="0.5" alignItems="center">
            <ArchivedWorkspaceHiddenItemWrapper>
                {onClickTestPatient && (
                    <>
                        <Ds.Button
                            onClick={() => onClickTestPatient(true)}
                            appearance="tertiary"
                        >
                            Use test patient
                        </Ds.Button>
                        <StyledModalHeaderDivider />
                    </>
                )}
            </ArchivedWorkspaceHiddenItemWrapper>
            <Ds.Modal.CloseButton />
        </Ds.Flex>
    </StyledModalHeader>
);

export const MobileModalHeader = ({
    onClickTestPatient,
    onBack,
    title,
    isSecondPanel = false,
}: ModalHeaderProps) => {
    const [open, setOpen] = useState(false);

    const handleClickTestPatient = () => {
        onClickTestPatient?.(true);
        setOpen(false);
    };

    return (
        <StyledModalHeader>
            {isSecondPanel ? (
                <ModalBackButton onClick={onBack} />
            ) : (
                <Ds.Modal.CloseButton />
            )}
            <Ds.Modal.Title>{title || "Search patients"}</Ds.Modal.Title>
            {!isSecondPanel && onClickTestPatient ? (
                <Popover open={open} onOpenChange={setOpen} modal>
                    <PopoverTrigger asChild>
                        <ModalMoreButton />
                    </PopoverTrigger>
                    <PopoverContent>
                        <PopoverItemButton onClick={handleClickTestPatient}>
                            <Icon name="RecordPerson" size="small" />
                            <Ds.Text weight="bold">Use test patient</Ds.Text>
                        </PopoverItemButton>
                    </PopoverContent>
                </Popover>
            ) : (
                <ButtonPlaceholder />
            )}
        </StyledModalHeader>
    );
};

const ButtonPlaceholder = styled.div`
    height: 42px;
    width: 42px;
`;
