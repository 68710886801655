import { AppointmentReminder } from "@accurx/api/appointment";
import { useCurrentWorkspace } from "@accurx/auth";
import { Card, Ds, Feedback, Flex, Tokens } from "@accurx/design";
import styled from "styled-components";

import { OrganisationHelper } from "../../../../shared/OrganisationHelper";
import {
    useReminderConfigOptions,
    useRemindersAndFilterOptions,
} from "../../hooks";
import { CreateReminderButton } from "../../pages/OverviewPage/components/CreateReminderButton";
import { StyledSpinnerPosition } from "../AppointmentReminder.styles";
import { PreviewCard } from "./PreviewCard";
import { getFilterSummaryForReminder } from "./getFilterSummaryForReminder";

const ListWrapper = styled.ul`
    padding: 0;

    display: flex;
    flex-direction: column;

    gap: ${Tokens.SIZES["1"]};

    list-style: none;
`;

type RemindersListProps = {
    onCreate: () => void;
    onEdit: (reminder: AppointmentReminder) => void;
    workspaceId: number;
    workspaceName?: string;
};

export const RemindersList = ({
    onCreate,
    onEdit,
    workspaceId,
    workspaceName,
}: RemindersListProps) => {
    const { status, data } = useRemindersAndFilterOptions({
        workspaceId: workspaceId.toString(),
    });
    const { data: configOptions, status: configStatus } =
        useReminderConfigOptions({ workspaceId: workspaceId.toString() });
    const workspace = useCurrentWorkspace();
    const isAdminUser = OrganisationHelper.getIsAdminOrgUser(workspace);

    if (status === "loading" || configStatus === "loading") {
        return (
            <StyledSpinnerPosition>
                <Ds.Spinner />
            </StyledSpinnerPosition>
        );
    }

    if (status === "error" || configStatus === "error") {
        return (
            <Feedback
                colour="error"
                iconName="Failed"
                title="Error loading reminders"
            >
                <Ds.Text my="1">
                    There was an issue loading your appointment reminders, try
                    again later.
                </Ds.Text>
            </Feedback>
        );
    }

    if (!data.appointmentReminders.length) {
        return (
            <Card variant="dashed" props={{ style: { textAlign: "center" } }}>
                <Flex flexDirection="column" alignItems="center" gap="1">
                    <Ds.Text as="h2" size="large" weight="bold">
                        Set up reminders
                    </Ds.Text>
                    <Ds.Text>
                        You don't have any appointment reminders set up yet.
                    </Ds.Text>
                    {isAdminUser && <CreateReminderButton onClick={onCreate} />}
                </Flex>
            </Card>
        );
    }

    return (
        <ListWrapper>
            {data.appointmentReminders.map((reminder) => {
                return (
                    <li key={`reminder-preview-${reminder.id}`}>
                        <PreviewCard
                            reminder={reminder}
                            workspaceId={workspaceId}
                            workspaceName={workspaceName}
                            workspaceHasMultipleSites={
                                data.siteNames.length > 0
                            }
                            badgeText={getFilterSummaryForReminder({
                                reminder,
                                availableFilters:
                                    configOptions.availableFilters,
                                appointmentTypes: data.appointmentTypes,
                                allSiteNames: data.siteNames,
                            })}
                            onEdit={() => {
                                onEdit(reminder);
                            }}
                            isAdminUser={isAdminUser}
                        />
                    </li>
                );
            })}
        </ListWrapper>
    );
};
