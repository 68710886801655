import { ErrorSummaryProvider } from "@accurx/design";

import { StyledContainer } from "app/layout/Container";
import { ManageTemplatesFormPage } from "app/practices/manageTemplatesForm";

export const EditTemplatePage = (): JSX.Element => {
    return (
        <StyledContainer>
            <ErrorSummaryProvider>
                <ManageTemplatesFormPage />
            </ErrorSummaryProvider>
        </StyledContainer>
    );
};
