import * as UI from "@accurx/design";
import { Ds } from "@accurx/design";
import { QuestionnaireTemplate } from "@accurx/message-templates";
import { MessageEditor } from "domains/message-component/components/Compose/components/MessageEditor/MessageEditor";
import {
    useAllMessagesCompose,
    useCompose,
} from "domains/message-component/context";
import { useCharacterCount } from "domains/message-component/hooks/useCharacterCount";

import {
    StyledContainer,
    StyledFlexGrowContainer,
    StyledSendButtonContainer,
} from "./Compose.styles";
import { ExpandMinimiseBar } from "./components/ExpandMinimiseBar/ExpandMinimiseBar";
import { MessageActionsControlBar } from "./components/MessageActionsControlBar/MessageActionsControlBar";
import { Templates } from "./components/MessageTemplates/Templates";
import { RecipientSelector } from "./components/RecipientSelector/RecipientSelector";
import { SendMessage } from "./components/SendMessage/SendMessage";
import type { ComposeProps } from "./types";

export const Compose = ({
    contactDetails = [],
    assigneeLabel = "You",
    assigneeSelector,
    patient,
    patientMatchesCurrentMedicalRecordPatient,
    onMessageSend,
    isMessageSending,
    onUserTyping,
    onMinimiseClick,
    isHeightRestricted,
    onExpandClick,
    setAssignee,
}: ComposeProps) => {
    const { state } = useCompose();
    const { state: allMessages } = useAllMessagesCompose();

    const selfBookLinkSelected = state.selfBookLink !== null;

    const { characterCount, fragmentCount, isUnicode } = useCharacterCount();

    const { size: viewPort } = UI.Hooks.useViewportSize();
    const isMobileSize = viewPort === "xs" || viewPort === "sm";

    const onQuestionnaireSelected = (questionnaire: QuestionnaireTemplate) => {
        if (questionnaire.defaultUserGroupId) {
            setAssignee?.({
                type: "Team",
                id: questionnaire.defaultUserGroupId.toString(),
            });
        } else {
            setAssignee?.("DEFAULT");
        }
    };

    return (
        <StyledContainer>
            <ExpandMinimiseBar
                onMinimiseClick={onMinimiseClick}
                onExpandClick={onExpandClick}
                isHeightRestricted={isHeightRestricted ?? false}
            >
                <RecipientSelector
                    patientExternalIds={patient.externalIds}
                    contactDetails={contactDetails}
                    shouldDisableEmail={
                        state.isSelfbookConfigurationOpen ||
                        selfBookLinkSelected
                    }
                    forceOpenSide={isHeightRestricted ? "top" : "bottom"}
                />
            </ExpandMinimiseBar>
            <StyledFlexGrowContainer>
                <UI.Grid rows="2">
                    <UI.Item>
                        <Templates
                            disabled={
                                state.isSelfbookConfigurationOpen ||
                                state.isScheduling
                            }
                            patientExternalIds={patient.externalIds}
                            onQuestionnaireSelected={onQuestionnaireSelected}
                            onQuestionnaireRemoved={() => setAssignee?.(null)}
                        />
                    </UI.Item>
                </UI.Grid>
                <MessageEditor
                    onUserTyping={onUserTyping}
                    assigneeSelector={assigneeSelector}
                    assigneeLabel={assigneeLabel}
                    isHeightRestricted={isHeightRestricted}
                />
                <Ds.Flex
                    justifyContent="space-between"
                    flexWrap="wrap"
                    alignItems={isMobileSize ? "flex-end" : "flex-start"}
                    gap="1"
                >
                    {!allMessages.isMultipleMessages && (
                        <Ds.Flex.Item flex="1 0 auto">
                            <MessageActionsControlBar
                                setAssignee={setAssignee}
                                patient={patient}
                            />
                        </Ds.Flex.Item>
                    )}

                    <StyledSendButtonContainer>
                        <SendMessage
                            patientMatchesCurrentMedicalRecordPatient={
                                patientMatchesCurrentMedicalRecordPatient
                            }
                            patientExternalIds={patient.externalIds}
                            characterCount={characterCount}
                            fragmentCount={fragmentCount}
                            isUnicode={isUnicode}
                            onMessageSend={onMessageSend}
                            isLoading={isMessageSending}
                        />
                    </StyledSendButtonContainer>
                </Ds.Flex>
            </StyledFlexGrowContainer>
        </StyledContainer>
    );
};
