import { createMutationHook } from "@accurx/api/QueryClient";
import { useCurrentUser } from "@accurx/auth";
import { getApiEndpoint, httpClient, returnDataOrThrow } from "@accurx/shared";

import { SettingsUserProfile } from "app/account/UserAccountSetting/components/Settings.types";

export const useUpdateUserProfileMutation = createMutationHook<
    { email: string; fullName: string },
    SettingsUserProfile
>(() => {
    const { user, update } = useCurrentUser();

    return {
        mutationKey: ["updateUserProfile"],
        mutationFn: async ({ email, fullName }) => {
            const response = await httpClient.postJsonReturnJsonSafeAsync(
                getApiEndpoint({
                    path: "/api/account/Manage/Index",
                }),
                { email, fullName },
            );

            return returnDataOrThrow(response);
        },
        onSuccess: (data) => {
            update({
                ...user,
                email: data.email,
                fullName: data.fullName,
            });
        },
    };
});
