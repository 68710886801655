import { ChangeEvent, FormEvent, forwardRef, useEffect, useState } from "react";

import { Ds, Input } from "@accurx/design";
import { StyledClearButton, StyledFormFieldWrapper } from "@accurx/navigation";
import { useEmrPatientSearch } from "domains/patient/hooks/mutations/useEmrPatientSearch";
import { PatientSearchResult } from "domains/patient/types";

import {
    EmisSearchState,
    FIELDS_ID,
    FORM_ACTION,
    FORM_ID,
    FORM_LABELS,
    FormAction,
    INIT_STATE,
    TEST_ID,
} from "../PatientSearchForm.helpers";
import {
    EmisSearchError,
    EmisSearchSchema,
    formatErrors,
} from "../PatientSearchFormSchema";

export type EmisSearchProps = {
    formData: EmisSearchState;
    formAction: React.Dispatch<FormAction<EmisSearchState>>;
    onSubmit: (data: PatientSearchResult[]) => void;
    onError: (error: Error) => void;
    onValidationError: (errorReason: string[]) => void;
    onClear: () => void;
    isFormDisabled?: boolean;
    onLoading?: (isLoading: boolean) => void;
};

export const EmisSearch = forwardRef<HTMLFormElement, EmisSearchProps>(
    (
        {
            formData,
            formAction,
            onSubmit,
            onError,
            onValidationError,
            onClear,
            onLoading,
            isFormDisabled,
        },
        forwardedRef,
    ) => {
        const { mutate, isLoading } = useEmrPatientSearch({
            onError: (error) => onError(error),
            onSuccess: (data) => onSubmit(data),
        });
        const [fieldErrors, setFieldErrors] = useState<EmisSearchError | null>(
            null,
        );

        useEffect(() => {
            onLoading?.(isLoading);
        }, [isLoading, onLoading]);

        const handleSearchTermChange = (
            e: ChangeEvent<HTMLInputElement>,
        ): void => {
            formAction({
                type: FORM_ACTION.UPDATE,
                payload: { searchTerm: e.target.value },
            });
            checkFormIsCleared({ searchTerm: e.target.value });
        };

        const checkFormIsCleared = (formData: EmisSearchState) => {
            if (!formData.searchTerm) {
                onClear();
            }
        };

        const handleSubmit = (e: FormEvent) => {
            e.preventDefault();

            const result = EmisSearchSchema.safeParse(formData);
            if (result.success) {
                setFieldErrors(null);
                mutate(formData);
            } else {
                const submitErrors = formatErrors<EmisSearchError>(
                    result.error,
                );
                setFieldErrors(submitErrors);
                if (submitErrors.searchTerm) {
                    onValidationError([submitErrors.searchTerm]);
                }
            }
        };

        const handleClear = () => {
            setFieldErrors(null);
            formAction({
                type: FORM_ACTION.UPDATE,
                payload: INIT_STATE.EMIS,
            });
            onClear();
        };

        return (
            <form
                ref={forwardedRef}
                onSubmit={handleSubmit}
                id={FORM_ID.EMIS}
                data-testid={TEST_ID.EMIS}
                noValidate
            >
                <Ds.Flex gap="1.5" flexDirection="column">
                    <Ds.Flex
                        gap="1"
                        alignItems={fieldErrors ? "center" : "flex-end"}
                    >
                        <StyledFormFieldWrapper
                            label={FORM_LABELS.searchTerm}
                            labelProps={{
                                htmlFor: `${FORM_ID.EMIS}-input-${FIELDS_ID.SEARCH_TERM}`,
                            }}
                            errors={[fieldErrors?.searchTerm ?? null]}
                        >
                            <Input
                                autoFocus
                                id={`${FORM_ID.EMIS}-input-${FIELDS_ID.SEARCH_TERM}`}
                                value={formData.searchTerm}
                                type="text"
                                required
                                onChange={handleSearchTermChange}
                                disabled={isFormDisabled}
                                data-userflow-id="patient-search-search-term-input"
                                hasErrors={Boolean(fieldErrors?.searchTerm)}
                            />
                            {formData.searchTerm && (
                                <StyledClearButton
                                    type="button"
                                    onClick={handleClear}
                                >
                                    <Ds.Text weight="bold">Clear</Ds.Text>
                                </StyledClearButton>
                            )}
                        </StyledFormFieldWrapper>
                        <Ds.Button type="submit" disabled={isFormDisabled}>
                            <Ds.Button.Icon
                                name="Search"
                                aria-label="Search for a patient"
                            />
                        </Ds.Button>
                    </Ds.Flex>
                </Ds.Flex>
            </form>
        );
    },
);
