import { useCurrentWorkspace } from "@accurx/auth";
import { NavTabs } from "@accurx/navigation";
import { AppSettings } from "@accurx/shared";
import { generatePath, useHistory, useLocation } from "react-router-dom";

import {
    ReportingPageViewProps,
    trackReportingPageView,
} from "app/analytics/FlemingAnalytics/ReportingEvents";
import { StyledContainer } from "app/layout/Container";
import { PageHeader, PageHeaderType } from "app/layout/PageHeader";
import { NavSubMenuComponent } from "app/navbar/NavSubMenuComponent";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { ROUTES_WORKSPACE } from "shared/Routes";

import { PowerBIReport } from "../components/PowerBIReport";

const TABS = {
    questionnaire: { tabId: "questionnaire", text: "Questionnaire" },
    booking: { tabId: "booking", text: "Booking" },
} as const;
type TabId = keyof typeof TABS;

const REPORT_NAME =
    AppSettings.accurxEnvironment === "production"
        ? "Patient Response"
        : "Patient Response [DEV]";

const TRACKING_REPORT_NAME = "ResponseRatesReport";
const ORIGIN_PROPS = {
    navigationOrigin: "Web",
    pageOrigin: "ResponseRatesReport",
} as const;

export const ResponseRatesPage = () => {
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();
    const { state } = useLocation<
        Partial<ReportingPageViewProps> | undefined
    >();
    const history = useHistory();
    const { orgId: workspaceId } = useCurrentWorkspace();

    const handleTabDisplay = (nextTabId: string) => {
        trackReportingPageView({
            ...analyticsLoggedInProps,
            navigationOrigin: state?.navigationOrigin ?? "URL",
            pageOrigin: state?.pageOrigin,
            currentTab: TABS[nextTabId as TabId].text,
            reportName: TRACKING_REPORT_NAME,
        });
    };

    return (
        <>
            <NavSubMenuComponent
                backCallback={() =>
                    history.push(
                        generatePath(ROUTES_WORKSPACE.reporting, {
                            workspaceId,
                        }),
                    )
                }
            />
            <StyledContainer>
                <PageHeader
                    title="Response rates"
                    type={PageHeaderType.ListPage}
                />
                <NavTabs
                    baseRoute={ROUTES_WORKSPACE.reportingResponseRates}
                    defaultTabId={TABS.questionnaire.tabId}
                    onTabDisplay={handleTabDisplay}
                    routeState={ORIGIN_PROPS}
                >
                    <NavTabs.Tabs tabs={Object.values(TABS)} />

                    <NavTabs.Panel tabId={TABS.questionnaire.tabId}>
                        <PowerBIReport
                            pageName="Questionnaire"
                            reportName={REPORT_NAME}
                            pageOrigin={TRACKING_REPORT_NAME}
                            leftAligned
                        />
                    </NavTabs.Panel>
                    <NavTabs.Panel tabId={TABS.booking.tabId}>
                        <PowerBIReport
                            pageName="Booking"
                            reportName={REPORT_NAME}
                            pageOrigin={TRACKING_REPORT_NAME}
                            leftAligned
                        />
                    </NavTabs.Panel>
                </NavTabs>
            </StyledContainer>
        </>
    );
};
