import { useCurrentWorkspace } from "@accurx/auth";
import { usePatient } from "@accurx/concierge/hooks/data/usePatient";
import {
    PatientResponse,
    PatientSingleResponseItem,
} from "@accurx/concierge/types";
import * as UI from "@accurx/design";
import { Ds } from "@accurx/design";
import { Log } from "@accurx/shared";
import {
    ConversationItemBadgeContainer,
    ConversationItemContainer,
    ConversationItemContent,
    ConversationItemFooter,
    ConversationItemHeader,
    ConversationThreadListItem,
} from "domains/inbox/components/ConversationItem/ConversationItem.styles";
import { CreatedAtTime } from "domains/inbox/components/ConversationItem/components/CreatedAtTime/CreatedAtTime";
import { FreeText } from "domains/inbox/components/ConversationItem/components/FreeText/FreeText";
import { AttachmentsContainerWithLabel } from "domains/inbox/components/ConversationItem/components/ItemAttachments/AttachmentsContainerWithLabel";
import { ItemAttachments } from "domains/inbox/components/ConversationItem/components/ItemAttachments/ItemAttachments";
import { PatientNameTag } from "domains/inbox/components/ConversationItem/components/PatientNameTag/PatientNameTag";
import { ReadByTooltip } from "domains/inbox/components/ConversationItem/components/ReadByTooltip/ReadByTooltip";
import { SaveItemAction } from "domains/inbox/components/ConversationItem/components/SaveItemAction/SaveItemAction";
import { ConversationItemComponent } from "domains/inbox/components/ConversationItem/types";
import { useGetPatientToClinicianMessageInfo } from "domains/inbox/components/ConversationItem/useCopyInfo";
import { getItemAlignment } from "domains/inbox/components/ConversationView/components/ConversationThread/conversationThread.utils";
import { useItemAttachmentAnalytics } from "domains/inbox/hooks/util/useItemAttachmentAnalytics";
import { userflowIds } from "domains/inbox/util";

import { AccurxLogoAvatar } from "../../components/AccurxLogoAvatar/AccurxLogoAvatar";
import { MessageNameTag } from "../../components/MessageNameTag/MessageNameTag";

const getSingleResponse = (
    item: PatientSingleResponseItem,
): PatientResponse | undefined => {
    return item.sections[0]?.responses[0];
};

const getResponseAnswer = (
    response: PatientResponse | undefined,
): string | undefined => {
    const answer = response?.answer?.trim();
    return answer && answer !== "" ? answer : undefined;
};

const DidNotRespond = ({ item }: { item: PatientSingleResponseItem }) => {
    return (
        <ConversationThreadListItem
            $align={getItemAlignment({
                item,
            })}
        >
            <ConversationItemContainer
                data-testid={`conversation-item-${item.id}`}
                $color={"yellow"}
            >
                <ConversationItemHeader>
                    <MessageNameTag
                        avatar={<AccurxLogoAvatar />}
                        name="Accurx"
                    />
                    <div
                        data-userflow-id={
                            userflowIds.conversationView.messageHeaderLabel
                        }
                    >
                        <UI.Ds.Badge color="yellow" size="small">
                            System
                        </UI.Ds.Badge>
                    </div>
                </ConversationItemHeader>
                <ConversationItemContent>
                    <p>Patient has not completed their patient response.</p>
                </ConversationItemContent>
                <ConversationItemFooter>
                    <ReadByTooltip readDataByUserId={item.readDataByUserId} />
                    <CreatedAtTime time={item.createdAt} />
                </ConversationItemFooter>
            </ConversationItemContainer>
        </ConversationThreadListItem>
    );
};

export const PatientSingleResponse = ({
    item,
    conversation,
}: ConversationItemComponent<PatientSingleResponseItem>) => {
    const workspaceId = useCurrentWorkspace().orgId;
    const patientId = item.createdBy.id;
    const patient = usePatient({ patientId });
    const response = getSingleResponse(item);
    const answer = getResponseAnswer(response);
    const answerToDisplay = answer ?? "This message has no content";
    const attachments = response?.attachments ?? [];

    const copyInfo = useGetPatientToClinicianMessageInfo({
        patientId: conversation.regardingPatientId,
        messageBody: answerToDisplay,
        dateCreatedAt: item.createdAt,
    });

    const {
        trackConversationAttachmentPreviewLinkClick,
        trackConversationAttachmentRemoveButtonClick,
        trackConversationAttachmentRemoveMenuItemClick,
    } = useItemAttachmentAnalytics({ conversation });

    if (item.sections.length === 0) {
        return <DidNotRespond item={item} />;
    }

    return (
        <ConversationThreadListItem
            $align={getItemAlignment({
                item,
            })}
        >
            <ConversationItemContainer
                data-testid={`conversation-item-${item.id}`}
                $color={patient ? "blue" : "yellow"}
            >
                <ConversationItemHeader>
                    <PatientNameTag patientId={patientId} />
                    <ConversationItemBadgeContainer
                        data-userflow-id={
                            userflowIds.conversationView.messageHeaderLabel
                        }
                    >
                        <UI.Ds.Badge
                            color={patient ? "blue" : "yellow"}
                            size="small"
                        >
                            Patient
                        </UI.Ds.Badge>
                    </ConversationItemBadgeContainer>
                </ConversationItemHeader>
                <ConversationItemContent>
                    <Ds.Flex flexDirection="column" gap="3">
                        <FreeText>{answerToDisplay}</FreeText>
                        <Ds.Flex.Item>
                            {!!attachments.length && (
                                <AttachmentsContainerWithLabel>
                                    <ItemAttachments
                                        attachments={attachments}
                                        itemId={item.id}
                                        conversationId={conversation.id}
                                        patientId={patientId}
                                        getAttachmentUrl={({ attachment }) => {
                                            if (!patientId) {
                                                Log.warn(
                                                    `No patient ID for attachment ${attachment.id}`,
                                                );
                                                return `/api/conversation/web/patientupload/${workspaceId}/undefined/${attachment.id}`;
                                            }
                                            return `/api/conversation/web/patientupload/${workspaceId}/${patientId}/${attachment.id}`;
                                        }}
                                        onItemPreview={
                                            trackConversationAttachmentPreviewLinkClick
                                        }
                                        onItemDeleteClick={
                                            trackConversationAttachmentRemoveButtonClick
                                        }
                                        onItemDeleteConfirm={({ reason }) => {
                                            trackConversationAttachmentRemoveMenuItemClick(
                                                reason,
                                            );
                                        }}
                                    />
                                </AttachmentsContainerWithLabel>
                            )}
                        </Ds.Flex.Item>
                        <Ds.Flex.Item>
                            <SaveItemAction
                                conversation={conversation}
                                item={item}
                                getTextToCopy={copyInfo}
                                hasAttachments={!!attachments.length}
                            />
                        </Ds.Flex.Item>
                    </Ds.Flex>
                </ConversationItemContent>
                <ConversationItemFooter>
                    <ReadByTooltip readDataByUserId={item.readDataByUserId} />
                    <CreatedAtTime time={item.createdAt} />
                </ConversationItemFooter>
            </ConversationItemContainer>
        </ConversationThreadListItem>
    );
};
