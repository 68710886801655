import { FeatureName, Organisation, getFeatureOverrides } from "../..";

type HasFeatureArgs = {
    feature: FeatureName;
    workspace: Organisation;
};

export const hasFeature = ({ feature, workspace }: HasFeatureArgs) => {
    const overrides = getFeatureOverrides();
    if (feature in overrides) return overrides[feature];

    const foundFeature = workspace.settings.features.find(
        (orgFeatureFlag) => orgFeatureFlag.name === feature,
    );
    return foundFeature?.isEnabled === true;
};
