import { RouterState } from "../pages/Routes.types";
import { CurrentPatient } from "../pages/compose/context/CurrentPatientProvider.types";
import { mapSearchedPatientToContactDetails } from "./mapSearchedPatientToContactDetails";

export const mapRouterStateToCurrentPatient = (
    state: RouterState | null,
): CurrentPatient | null =>
    state?.searchedPatient
        ? {
              patient: state.searchedPatient,
              contactDetails: mapSearchedPatientToContactDetails(
                  state.searchedPatient,
              ),
          }
        : null;
