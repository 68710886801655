import { FormFieldWrapper, Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledFormFieldWrapper = styled(FormFieldWrapper)`
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: ${Tokens.SIZES[0.5]};

    & > div {
        position: relative;
    }

    & > label {
        margin: 0;
    }
`;

export const StyledClearButton = styled.button`
    background: none;
    border: none;
    border-radius: ${Tokens.BORDERS.radius};
    position: absolute;
    right: ${Tokens.SIZES[1]};
    top: 50%;
    transform: translateY(-50%);
`;
