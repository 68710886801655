import { useState } from "react";

import {
    NativeTransportError,
    PatientExternalIdentityDto,
    queryPatientDemographics,
    useNativeTransport,
} from "@accurx/native";

export const useQueryPatientDemographics = (workspaceId: number) => {
    const transport = useNativeTransport();

    const [isFetching, setIsFetching] = useState(false);

    const queryDemographics = async (
        patientExternalIdentityDto: PatientExternalIdentityDto,
    ) => {
        if (!transport) {
            throw new NativeTransportError(
                "Native transport was missing when opening compose from the inbox",
            );
        }
        setIsFetching(true);

        const { patientDemographics } = await queryPatientDemographics(
            { transport, workspaceId },
            {
                patientExternalIdentityDto,
                pushToServer: false,
            },
        );

        setIsFetching(false);

        return patientDemographics;
    };

    return { queryDemographics, isFetching };
};
