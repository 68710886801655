import { useEffect } from "react";

import { useCurrentWorkspace } from "@accurx/auth";
import { Flex, Spinner } from "@accurx/design/dist/ds";
import { PatientSearchResult, useReplayPatientSearch } from "@accurx/patient";
import { Log } from "@accurx/shared";
import { generatePath, useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";

import { ROUTES_COMPOSE } from "../Routes.constants";
import { useComposeVariant } from "../compose/hooks/useComposeVariant";
import { handleSelectPatient } from "./handleSearch.utils";

const LoadingWrapper = styled(Flex)`
    height: 100%;
    width: 100%;
`;

export const ComposeBackgroundSearchPage = () => {
    const history = useHistory();
    const workspaceId = useCurrentWorkspace().orgId;
    const composeVariant = useComposeVariant();
    const location = useLocation();
    const params = new URLSearchParams(location.search);

    const token = params.get("token");

    const { mutate: getPatientFromToken, isLoading } = useReplayPatientSearch({
        onSuccess: (results: PatientSearchResult[]) => {
            handleSelectPatient({
                workspaceId,
                result: results[0],
                history,
                composeVariant,
                appOrigin: "SwitchApp",
            });
        },
        onError: (error: Error) => onError(error),
    });

    const onError = (error: Error) => {
        Log.error("Error performing background patient search in compose", {
            tags: {
                errorName: error.name,
                errorMessage: error.message,
                product: "Compose",
            },
        });

        history.push(
            generatePath(ROUTES_COMPOSE.search, {
                workspaceId,
            }),
            { appOrigin: "SwitchApp" },
        );
    };

    useEffect(() => {
        if (!token) {
            onError(
                new Error("Could not get patient details", {
                    cause: "Could not get token from url",
                }),
            );
            return;
        }
        getPatientFromToken({ token, workspaceId });
        // run this effect only once, upon mount.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {isLoading && (
                <LoadingWrapper alignItems="center" justifyContent="center">
                    <Spinner />
                </LoadingWrapper>
            )}
        </>
    );
};
