import { NativeApi } from "domains/native/api/NativeApi";
import { createEndpoint } from "domains/native/api/api.utils";
import { PatientExternalIdentityDto } from "domains/native/schemas/PatientExternalIdentityDtoSchema";

export type MutateClearPatientCachePayload = {
    patientExternalIdentityDto: PatientExternalIdentityDto;
};

export const mutateClearPatientCache = createEndpoint<
    void,
    MutateClearPatientCachePayload
>("MutateClearPatientCache", async (context, payload) => {
    await NativeApi.request({
        transport: context.transport,
        request: {
            type: "MutateClearPatientCache",
            workspaceId: context.workspaceId,
            payload,
        },
    });
});
