import { Route, Switch, generatePath } from "react-router";

import {
    ManualUploadCreatePage,
    ManualUploadOverviewPage,
    ManualUploadReviewPage,
} from "./pages";

const basePathForUploadAppointments =
    "/w/:workspaceId/settings/appointments-manual-upload";

const manualUploadOverviewPagePath = `${basePathForUploadAppointments}`;
const manualUploadCreatePagePath = `${basePathForUploadAppointments}/new`;
const manualUploadReviewPagePath = `${basePathForUploadAppointments}/:uploadId/review`;

export function AppointmentsManualUploadRoutes() {
    return (
        <Switch>
            <Route path={manualUploadOverviewPagePath} exact>
                <ManualUploadOverviewPage />
            </Route>
            <Route path={manualUploadCreatePagePath} exact>
                <ManualUploadCreatePage />
            </Route>
            <Route path={manualUploadReviewPagePath} exact>
                <ManualUploadReviewPage />
            </Route>
        </Switch>
    );
}

export const getManualUploadCreatePagePath = ({
    workspaceId,
}: {
    workspaceId: number | string;
}) => {
    return generatePath(manualUploadCreatePagePath, { workspaceId });
};

export const getManualUploadOverviewPagePath = ({
    workspaceId,
}: {
    workspaceId: number | string;
}) => {
    return generatePath(manualUploadOverviewPagePath, { workspaceId });
};

export const getManualUploadReviewPagePath = ({
    workspaceId,
    uploadId,
}: {
    workspaceId: number | string;
    uploadId: string;
}) => {
    return generatePath(manualUploadReviewPagePath, { workspaceId, uploadId });
};
