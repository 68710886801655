import { useEffect } from "react";

import { useCurrentWorkspace } from "@accurx/auth";
import { Redirect } from "react-router";

import { trackCreateTemplatePageView } from "app/analytics/FlemingAnalytics";
import { useCreateAccumailTemplateMutation } from "app/hooks/mutations";
import { Spinner } from "app/organisations/shared";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { SnomedCode } from "app/sharedComponents/SnomedPicker";

import { AccumailTemplateType } from "../templates.types";
import { EditTemplatePageHeader } from "./components/EditTemplatePageHeader";
import { TemplateForm } from "./form/TemplateForm";
import { useTemplateFormState } from "./form/useTemplateFormState";

type CreateAccumailTemplateProps = {
    type: AccumailTemplateType;
};

export const CreateAccumailTemplate = ({
    type,
}: CreateAccumailTemplateProps) => {
    const { orgId: workspaceId, organisationName: workspaceName } =
        useCurrentWorkspace();

    const {
        mutate,
        isSuccess,
        isError: isMutationError,
        isLoading: isMutating,
    } = useCreateAccumailTemplateMutation();

    const doCreate: (
        text: string,
        title: string,
        snomedCodes?: SnomedCode[],
    ) => void = (text, title, snomedCodes) => {
        mutate({
            workspaceId,
            data: {
                content: text,
                title,
                snomedCodes,
                templateType: type,
            },
        });
    };

    const templateLevel = type === "UserTemplate" ? "User" : "Organisation";

    const templateFormState = useTemplateFormState({
        doSave: doCreate,
        type,
        saveMode: "create",
    });
    const { editorContent, manageTemplatePage } = templateFormState;

    const flemingLoggedInProperties = useFlemingLoggedInAnalytics();

    useEffect(() => {
        trackCreateTemplatePageView({
            ...flemingLoggedInProperties,
            templateLevel,
            productOrigin: "ClinicianMessage",
        });
    }, [flemingLoggedInProperties, type, templateLevel]);

    if (isSuccess && manageTemplatePage) {
        return <Redirect to={manageTemplatePage} />;
    }

    if (isMutating) {
        return <Spinner />;
    }

    const subtitle =
        type === "UserTemplate"
            ? "This will only be available for you to use"
            : `This will be available for everyone in ${workspaceName} to use`;

    return (
        <TemplateForm
            header={
                <EditTemplatePageHeader
                    title="Create a new template"
                    subtitle={subtitle}
                />
            }
            saveButtonText="Save template"
            initialContent={editorContent}
            isMutationError={isMutationError}
            {...templateFormState}
        />
    );
};
