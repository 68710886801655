import { useState } from "react";

import { AppointmentReminderFilterAttribute } from "@accurx/api/appointment";
import { FeatureName, useFeatureFlag } from "@accurx/auth";
import {
    Ds,
    Feedback,
    Input,
    Pagination,
    Table,
    usePagination,
    useTable,
} from "@accurx/design";
import { useFuzzyFilter } from "@accurx/hooks";
import { useAccurxWebTitle } from "@accurx/navigation";
import { DateFormatOptions, DateHelpers } from "@accurx/shared";
import { getChangeRequestStatusMessage } from "domains/appointment-reminders/utils/getChangeRequestStatusMessage";

import { COLUMN_SORT_DIRECTIONS } from "../constants";
import { useChangeRequests } from "../hooks/useChangeRequestsQuery";
import { useReminderConfigOptions } from "../hooks/useRemindersConfigOptions";
import {
    StyledPaginationContainer,
    StyledSpinnerPosition,
} from "./AppointmentReminder.styles";
import { useSortingColumns } from "./SortableTable/SortableTable";
import { SortableTableHeading } from "./SortableTable/SortableTableHeading";

interface ChangeRequestsProps {
    workspaceId: string;
}

export const ChangeRequests = ({
    workspaceId,
}: ChangeRequestsProps): JSX.Element => {
    useAccurxWebTitle("View appointment reminders change requests");

    const { data, status } = useChangeRequests({
        workspaceId,
    });

    const isClinicianNameEnabled = useFeatureFlag(
        FeatureName.AppointmentReminderClinicianName,
    );

    const { data: remindersAndFilterOptions } = useReminderConfigOptions({
        workspaceId: workspaceId.toString(),
    });
    const availableFilters = remindersAndFilterOptions?.availableFilters;

    const [searchTerm, setSearchTerm] = useState("");

    const handleSearch = (searchInput: string) => {
        goToPage(1);
        setSearchTerm(searchInput);
    };

    const changeRequests =
        status === "success" ? data.appointmentChangeRequests : [];

    const tableRows = useFuzzyFilter(changeRequests, searchTerm, {
        keys: ["patientName", "externalIdDisplay.value", "slotType"],
    }); // externalIdDisplay.value because externalIdDisplay is an object of type and value properties
    const sorting = useSortingColumns();

    const slotHeader = availableFilters?.includes(
        AppointmentReminderFilterAttribute.SlotTypes,
    )
        ? "Slot type"
        : "Clinic";

    const table = useTable({
        data: tableRows,
        columnDefs: [
            {
                id: "externalIdDisplay",
                header: "NHS number",
            },
            {
                id: "patientName",
                header: "Patient name",
            },
            {
                id: "appointmentStartTime",
                header: "Appointment date",
                sort: {
                    direction:
                        COLUMN_SORT_DIRECTIONS.appointmentStartTime[
                            COLUMN_SORT_DIRECTIONS.appointmentStartTime.initial
                        ],
                    selector: (row) => row.appointmentStartTime,
                },
            },
            {
                id: "appointmentChangedAt",
                header: availableFilters?.includes(
                    AppointmentReminderFilterAttribute.SlotTypes,
                )
                    ? "Cancellation date"
                    : "Request date",
                sort: {
                    direction:
                        COLUMN_SORT_DIRECTIONS.appointmentChangedAt[
                            COLUMN_SORT_DIRECTIONS.appointmentChangedAt.initial
                        ],
                    selector: (row) => row.appointmentChangedAt,
                },
            },
            {
                id: "clinicianName",
                header: "Clinicians",
            },
            {
                id: "slotType",
                header: slotHeader,
            },
            {
                id: "type",
                header: "Status",
            },
        ],
        defaultOptions: {
            sortColumnId: sorting.sortColumn,
            sortDirection: sorting.sortDirection,
        },
    });

    const { currentPage, totalPages, onNext, onPrevious, paginate, goToPage } =
        usePagination({
            defaultPage: 1,
            defaultPageSize: 50,
            total: table.rows.length,
            isCycleEnabled: false,
        });

    if (status === "loading") {
        return (
            <StyledSpinnerPosition>
                <Ds.Spinner />
            </StyledSpinnerPosition>
        );
    }

    if (status === "error") {
        return (
            <Feedback
                colour="error"
                iconName="Failed"
                title="There was an issue retrieving appointment change requests"
            />
        );
    }

    const searchPlaceholder = `Search by NHS Number, Patient’s name or ${slotHeader}`;

    if (changeRequests.length === 0) {
        return (
            <Feedback colour="information" title="There are no change requests">
                <Ds.Text>
                    When a patient chooses to change their appointment from the
                    reminder message, it will appear here.
                </Ds.Text>
            </Feedback>
        );
    }

    return (
        <Ds.Flex flexDirection="column" gap="1">
            <Feedback title="Appointment cancellations are also in the inbox">
                <Ds.Text>
                    Use the notification alerts, tracking features and patient
                    provided cancellation reasons to better manage and track
                    appointment changes.
                </Ds.Text>
            </Feedback>
            <div className="appointment-reminder-search-bar-wrapper">
                <Input
                    type="search"
                    placeholder={searchPlaceholder}
                    value={searchTerm}
                    onChange={(e) => handleSearch(e.target.value)}
                />
            </div>
            <Table>
                <Table.ColumnGroup>
                    {table.columns.map((column) => (
                        <Table.Column key={column.id} />
                    ))}
                </Table.ColumnGroup>
                <Table.Head>
                    <Table.Row>
                        {table.columns.map((column) => (
                            <Table.Header
                                key={column.id}
                                scope="col"
                                hidden={
                                    column.id === "clinicianName" &&
                                    !isClinicianNameEnabled
                                }
                            >
                                {column.metadata.isSortable ? (
                                    <SortableTableHeading
                                        key={column.id}
                                        currentSortDetails={{
                                            sortColumnId: sorting.sortColumn,
                                            sortDirection:
                                                sorting.sortDirection,
                                        }}
                                        onSortApplied={(newSortingApplied) => {
                                            sorting.updateSorting(
                                                newSortingApplied,
                                            );
                                        }}
                                        tableColumn={column}
                                    />
                                ) : (
                                    <>{column.header}</>
                                )}
                            </Table.Header>
                        ))}
                    </Table.Row>
                </Table.Head>
                <Table.Body>
                    {paginate(table.rows).map((row, index) => (
                        <Table.Row
                            key={`${row.externalIdDisplay.value}-${index}`}
                        >
                            <Table.DataCell>
                                {row.externalIdDisplay.value}
                            </Table.DataCell>
                            <Table.DataCell>{row.patientName}</Table.DataCell>
                            <Table.DataCell>
                                {DateHelpers.formatDate(
                                    row.appointmentStartTime,
                                    DateFormatOptions.DATE_TIME_SHORT_WITH_SLASH,
                                )}
                            </Table.DataCell>
                            <Table.DataCell>
                                {DateHelpers.formatDate(
                                    row.appointmentChangedAt,
                                    DateFormatOptions.DATE_TIME_SHORT_WITH_SLASH,
                                )}
                            </Table.DataCell>

                            {isClinicianNameEnabled ? (
                                <Table.DataCell>
                                    {row.clinicianName !== null &&
                                    row.clinicianName !== undefined
                                        ? row.clinicianName.join(", ")
                                        : null}
                                </Table.DataCell>
                            ) : null}
                            <Table.DataCell>{row.slotType}</Table.DataCell>
                            <Table.DataCell>
                                {getChangeRequestStatusMessage(
                                    row.changeRequestType,
                                    row.status,
                                )}
                            </Table.DataCell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
            <StyledPaginationContainer>
                <Pagination>
                    <Pagination.Navigation
                        currentPage={currentPage}
                        totalPages={totalPages}
                    >
                        {currentPage > 1 && (
                            <>
                                <Pagination.PreviousButton
                                    onPrevious={onPrevious}
                                />
                            </>
                        )}
                        {currentPage < totalPages && (
                            <>
                                <Pagination.NextButton onNext={onNext} />
                            </>
                        )}
                    </Pagination.Navigation>
                </Pagination>
            </StyledPaginationContainer>
        </Ds.Flex>
    );
};
