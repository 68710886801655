import { useState } from "react";

import {
    Recipient,
    SendAvailability,
    TemplateResponse,
    UpdateTemplate,
} from "@accurx/api/content";
import { Ds } from "@accurx/design";
import { EditTemplateMutationProps } from "@accurx/message-templates";
import { UseMutateFunction } from "@tanstack/react-query";

import { FlemingAnalyticsTracker } from "app/analytics";
import { useIsUserApproved } from "store/hooks";

import { useFlemingLoggedInAnalytics } from "../../sessionAnalytics/useFlemingLoggedInAnalytics";
import { StyledVisibilityHidden } from "./ManageTemplates.styles";

type SendTypeSwitchesType = {
    orgId: number;
    template: TemplateResponse;
    updateSendType: UseMutateFunction<
        UpdateTemplate,
        Error,
        EditTemplateMutationProps
    >;
};

export const SendTypeSwitches = ({
    orgId,
    template,
    updateSendType,
}: SendTypeSwitchesType): JSX.Element => {
    const flemingLoggedInProps = useFlemingLoggedInAnalytics();

    const [sendAvailability, setSendAvailability] = useState<
        SendAvailability[]
    >(template.sendAvailability);

    const isUserApproved: boolean = useIsUserApproved();

    const handleOnChangeSendType = (
        sendType: SendAvailability,
        isEnabled: boolean,
    ): void => {
        const updatedSendAvailability = isEnabled
            ? [...sendAvailability, sendType]
            : sendAvailability.filter((type) => type !== sendType);

        setSendAvailability(updatedSendAvailability);

        FlemingAnalyticsTracker.trackChangeAvailability({
            ...flemingLoggedInProps,
            templateLevel: "AccuRx",
            templateId: template.id,
            newValue: isEnabled,
            oldValue: !isEnabled,
            availabilityType: sendType,
        });

        updateSendType({
            workspaceId: orgId,
            templateId: template.id,
            template: {
                ...template,
                sendAvailability: updatedSendAvailability,
            },
        });
    };

    return template.recipient === Recipient.Patient ? (
        <Ds.Flex flexDirection="column" gap="1">
            <Ds.Switch
                id={`individual-switch-${template.id}`}
                size="small"
                checked={sendAvailability.includes(SendAvailability.Individual)}
                disabled={!isUserApproved}
                onCheckedChange={(checked) => {
                    handleOnChangeSendType(
                        SendAvailability.Individual,
                        checked,
                    );
                }}
            >
                <Ds.Switch.Toggle />
                <Ds.Switch.Label>
                    {/* To provide unique labels when there are multiple in a page - making visibility hidden for design purposes. */}
                    <StyledVisibilityHidden>{`${template.title} Availability `}</StyledVisibilityHidden>
                    Individual
                </Ds.Switch.Label>
            </Ds.Switch>
            <Ds.Switch
                id={`batch-switch-${template.id}`}
                size="small"
                checked={sendAvailability.includes(SendAvailability.Batch)}
                disabled={!isUserApproved}
                onCheckedChange={(checked) => {
                    handleOnChangeSendType(SendAvailability.Batch, checked);
                }}
            >
                <Ds.Switch.Toggle />
                <Ds.Switch.Label>
                    {/* To provide unique labels when there are multiple in a page - making visibility hidden for design purposes. */}
                    <StyledVisibilityHidden>{`${template.title} Availability `}</StyledVisibilityHidden>
                    Batch
                </Ds.Switch.Label>
            </Ds.Switch>
        </Ds.Flex>
    ) : (
        <Ds.Text>Only available for individual messaging</Ds.Text>
    );
};
