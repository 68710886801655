import { useCallback, useEffect, useRef, useState } from "react";

import { getEmbedMode } from "@accurx/native";
import { useRealtimeMeta } from "@accurx/realtime";
import { Log } from "@accurx/shared";
import { api } from "domains/concierge/internal/api/ticket";
import { mapUnreadTicketSummariesToUnreadItemsSummaries } from "domains/concierge/internal/api/ticket/mappers/mapUnreadTicketSummariesToUnreadItemsSummaries";
import {
    useConciergeGetState,
    useConciergeMeta,
    useConciergeSelector,
} from "domains/concierge/internal/context";
import { usePoller } from "domains/concierge/internal/hooks/usePoller";
import { ConciergeState } from "domains/concierge/internal/types/ConciergeState";
import { ConnectionState } from "domains/concierge/internal/types/ConnectionState";
import { PollerOptions } from "domains/concierge/internal/util/poller.types";
import { UnreadItemsSummary } from "domains/concierge/schemas/UnreadItemsSummarySchema";
import { createDraft, produce } from "immer";
import { v4 as uuidv4 } from "uuid";

const RECENTLY_RECEIVED_SIGNALR_MESSAGE_THRESHOLD = 1000 * 60 * 15;

const getWorkspaceUnreadCount = (state: ConciergeState): number => {
    const unreadCounts = state.conversations.unreadCounts.ticket;

    let unreadCount = unreadCounts.user;

    for (const teamId in state.teams.items) {
        if (state.teams.items[teamId].isMember) {
            unreadCount += unreadCounts.teams[teamId] ?? 0;
        }
    }

    return unreadCount;
};

const getOnlyUnreadItemSummaries = (state: ConciergeState, userId: string) => {
    return produce(state.conversations.unreadItems, (input) => {
        Object.keys(input).forEach((convId) => {
            const item = input[convId];

            if (item.system !== "Ticket") {
                delete input[convId];
            } else if (item.status !== "Open") {
                delete input[convId];
            } else if (
                item.assignee.type === "User" &&
                item.assignee.id !== userId
            ) {
                delete input[convId];
            } else if (
                item.assignee.type === "Team" &&
                !state.conversations.teamMembership[item.assignee.id]
            ) {
                delete input[convId];
            } else if (item.assignee.type === "None") {
                delete input[convId];
            }
        });
    });
};

const getMissingOrExtraneousItems = (
    concierge: UnreadItemsSummary,
    server: UnreadItemsSummary,
) => {
    const itemCounts: Record<string, number> = {};

    for (const itemId of concierge.itemIds) {
        itemCounts[itemId] = (itemCounts[itemId] ?? 0) + 1;
    }

    for (const itemId of server.itemIds) {
        itemCounts[itemId] = (itemCounts[itemId] ?? 0) - 1;
    }

    const missing: string[] = [];
    const extraneous: string[] = [];

    for (const itemId in itemCounts) {
        const count = itemCounts[itemId];

        if (count > 0) {
            extraneous.push(itemId);
        } else if (count < 0) {
            missing.push(itemId);
        }
    }

    return {
        missing,
        extraneous,
    };
};

type Diff = {
    conversationId: string;
    itemIds: string[];
}[];

const getUnreadItemsDiff = (
    _conciergeUnreadItems: ConciergeState["conversations"]["unreadItems"],
    responseUnreadItems: UnreadItemsSummary[],
) => {
    const conciergeUnreadItems = createDraft(_conciergeUnreadItems);
    const inResponseButNotInConcierge: Diff = [];
    const inConciergeButNotInResponse: Diff = [];

    for (const update of responseUnreadItems) {
        const conciergeItem = conciergeUnreadItems[update.conversationId];

        if (!conciergeItem) {
            inResponseButNotInConcierge.push({
                conversationId: update.conversationId,
                itemIds: update.itemIds,
            });
            continue;
        }

        const { missing, extraneous } = getMissingOrExtraneousItems(
            conciergeItem,
            update,
        );

        if (missing.length > 0) {
            inResponseButNotInConcierge.push({
                conversationId: update.conversationId,
                itemIds: missing,
            });
        }

        if (extraneous.length > 0) {
            inConciergeButNotInResponse.push({
                conversationId: update.conversationId,
                itemIds: extraneous,
            });
        }

        delete conciergeUnreadItems[update.conversationId];
    }

    // if we have anything left in conciergeUnreadItems that means the
    // conversation didn't appear in the response at all
    for (const conversationId in conciergeUnreadItems) {
        inConciergeButNotInResponse.push({
            conversationId,
            itemIds: conciergeUnreadItems[conversationId].itemIds,
        });
    }

    return {
        inResponseButNotInConcierge,
        inConciergeButNotInResponse,
    };
};

const getMismatchConversationIds = (
    conciergeUnreadItems: ConciergeState["conversations"]["unreadItems"],
    responseUnreadItems: UnreadItemsSummary[],
) => {
    const inResponseButNotInConcierge: string[] = [];
    const responseUnreadItemIds = new Set<string>();

    for (const unreadItem of responseUnreadItems) {
        if (!conciergeUnreadItems[unreadItem.conversationId]) {
            inResponseButNotInConcierge.push(unreadItem.conversationId);
        }

        responseUnreadItemIds.add(unreadItem.conversationId);
    }

    const inConciergeButNotInResponse = Object.keys(
        conciergeUnreadItems,
    ).filter((conversationId) => !responseUnreadItemIds.has(conversationId));

    return {
        inResponseButNotInConcierge,
        inConciergeButNotInResponse,
    };
};

const getConnectionId = (state: ConciergeState) => {
    const connectionState = state.conversations.connectionState;
    return connectionState.state === "Connected"
        ? connectionState.connectionId
        : null;
};

const hasDuplicates = <T>(input: T[]): boolean => {
    return new Set<T>(input).size < input.length;
};

const getSummariesWithDuplicates = (
    summaries: UnreadItemsSummary[],
): string[] => {
    const withDuplicates: string[] = [];

    for (const summary of summaries) {
        if (hasDuplicates(summary.itemIds)) {
            withDuplicates.push(summary.conversationId);
        }
    }

    return withDuplicates;
};

const useOnInitialConnection = (cb: (state: ConnectionState) => void) => {
    const connectionState = useConciergeSelector(
        (s) => s.conversations.connectionState,
    );
    const hasInitialisedRef = useRef(false);
    const cbRef = useRef(cb);

    useEffect(() => {
        if (
            !hasInitialisedRef.current &&
            connectionState.state !== "Initialising"
        ) {
            cbRef.current(connectionState);
            hasInitialisedRef.current = true;
        }
    }, [connectionState]);
};

export const useUnreadsDivergenceDebugger = () => {
    const { workspaceId, userId, sessionId, instanceId } = useConciergeMeta();
    const { getMeta } = useRealtimeMeta();
    const [debuggerId] = useState(() => uuidv4());
    const getState = useRef(useConciergeGetState());
    const { embedMode } = getEmbedMode();

    // Store the connection ID when it first initialises
    const connectionIdRef = useRef(getConnectionId(getState.current()));
    useOnInitialConnection((connectionState) => {
        connectionIdRef.current =
            connectionState.state === "Connected"
                ? connectionState.connectionId
                : null;
    });

    const fetchFn = useCallback<PollerOptions["fetchFn"]>(
        async ({ pollerId }) => {
            const beforeState = getState.current();
            const response = await api.getUnreadTickets(workspaceId);
            const afterState = getState.current();

            const responseSummaries =
                mapUnreadTicketSummariesToUnreadItemsSummaries(
                    response.unreadSummaries,
                );
            const conciergeSummaries = getOnlyUnreadItemSummaries(
                afterState,
                userId,
            );

            const unreadItemsDiff = getUnreadItemsDiff(
                conciergeSummaries,
                responseSummaries,
            );

            const connectionId = getConnectionId(afterState);

            const responseSummariesWithDuplicateItemIds =
                getSummariesWithDuplicates(responseSummaries);
            const conciergeSummariesWithDuplicateItemIds =
                getSummariesWithDuplicates(Object.values(conciergeSummaries));

            const memberTeams = Object.keys(
                afterState.conversations.teamMembership,
            );

            const unreadConversationsDiff = getMismatchConversationIds(
                conciergeSummaries,
                responseSummaries,
            );

            const { lastReceivedMessage } = getMeta();
            const hasRecentlyReceivedSignalRMessage =
                lastReceivedMessage !== null &&
                Date.now() - lastReceivedMessage <=
                    RECENTLY_RECEIVED_SIGNALR_MESSAGE_THRESHOLD;

            Log.info("Unread counts divergence debugger", {
                tags: {
                    // meta
                    date: new Date().toUTCString(),
                    embedMode,
                    userId,
                    workspaceId,
                    connectionId: getConnectionId(afterState),
                    conciergeSessionId: sessionId,
                    conciergeInstanceId: instanceId,
                    debuggerId,
                    pollerId,

                    // Difference detected
                    differenceDetected:
                        unreadItemsDiff.inResponseButNotInConcierge.length >
                            0 ||
                        unreadItemsDiff.inConciergeButNotInResponse.length > 0,

                    // Concierge unread counts, looking at conversation itmes
                    conciergeWorkspaceUnreadCountBeforeFetch:
                        getWorkspaceUnreadCount(beforeState),
                    conciergeUserUnreadCountBeforeFetch:
                        beforeState.conversations.unreadCounts.ticket.user,
                    conciergeWorkspaceUnreadCountAfterFetch:
                        getWorkspaceUnreadCount(afterState),
                    conciergeUserUnreadCountAfterFetch:
                        afterState.conversations.unreadCounts.ticket.user,
                    conciergeHasDuplicateItemIds:
                        conciergeSummariesWithDuplicateItemIds.length > 0,
                    conciergeIdsWithDuplicates:
                        conciergeSummariesWithDuplicateItemIds.join(","),

                    // Response details
                    responseTotalUnreadCount: response.totalUnreadTicketCount,
                    responseWorkspaceUnreadCount:
                        response.currentUserAndGroupsUnreadTicketCount,
                    responseHasDuplicateItemIds:
                        responseSummariesWithDuplicateItemIds.length > 0,
                    responseIdsWithDuplicates:
                        responseSummariesWithDuplicateItemIds.join(","),

                    // Count difference between client and server
                    inResponseButNotInConciergeCount:
                        unreadItemsDiff.inResponseButNotInConcierge.length,
                    inConciergeButNotInResponseCount:
                        unreadItemsDiff.inConciergeButNotInResponse.length,

                    // Detailed difference between client and server
                    inResponseButNotInConcierge: JSON.stringify(
                        unreadItemsDiff.inResponseButNotInConcierge,
                    ),
                    inConciergeButNotInResponse: JSON.stringify(
                        unreadItemsDiff.inConciergeButNotInResponse,
                    ),

                    // Connection status
                    currentConnectionState:
                        afterState.conversations.connectionState.state,
                    hasAlwaysBeenConnected:
                        !!connectionId &&
                        connectionId === connectionIdRef.current,

                    // Team membership
                    memberTeamsCount: memberTeams.length,
                    memberTeams: memberTeams.join(","),

                    // Conversation discrepancies, looking at whole-conversations
                    conversationsInConciergeButNotInResponse:
                        unreadConversationsDiff.inConciergeButNotInResponse.join(
                            ",",
                        ),
                    conversationsInResponseButNotInConcierge:
                        unreadConversationsDiff.inResponseButNotInConcierge.join(
                            ",",
                        ),
                    conversationsInConciergeButNotInResponseCount:
                        unreadConversationsDiff.inConciergeButNotInResponse
                            .length,
                    conversationsInResponseButNotInConciergeCount:
                        unreadConversationsDiff.inResponseButNotInConcierge
                            .length,
                    hasRecentlyReceivedSignalRMessage,
                },
            });

            connectionIdRef.current = connectionId;
        },
        [
            userId,
            workspaceId,
            embedMode,
            sessionId,
            instanceId,
            debuggerId,
            getMeta,
        ],
    );

    return usePoller({
        name: "DebugUnreadPoller",
        refreshRate: 1000 * 60 * 5, // 5 minutes
        skipInitialFetch: true,
        fetchFn,
    });
};
