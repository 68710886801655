import React from "react";

import { Ds, Tokens } from "@accurx/design";
import { Log } from "@accurx/shared";
import styled from "styled-components";

// Ensure badge is left-aligned and does not extend past content
const StyledLeftAlignedBadge = styled(Ds.Badge)`
    margin-top: ${Tokens.SIZES[1]};
    max-width: max-content;
    justify-content: flex-start;
`;
type SnomedType = {
    text: Optional<string>;
    code: Optional<string>;
};
const SnomedTag = ({ text, code }: SnomedType): JSX.Element | null => {
    if (!text || !code) {
        Log.warn("Snomed details undefined", {
            tags: {
                logger: "ManageTemplatesSnomedTag",
                ...(text && { snomedText: text }),
                ...(code && { snomedCode: code }),
            },
        });
        return null;
    }
    return (
        <StyledLeftAlignedBadge color="greyscale">
            <Ds.Icon name="Tag" />
            {`SNOMED Code: ${text} (${code})`}
        </StyledLeftAlignedBadge>
    );
};

export default SnomedTag;
