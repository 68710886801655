import { useRef, useState } from "react";

import { useFeatureFlag } from "@accurx/auth";
import { LabelTagItem } from "@accurx/concierge/types";
import { Ds } from "@accurx/design";
import {
    ConversationItemContent,
    ConversationThreadListItem,
} from "domains/inbox/components/ConversationItem/ConversationItem.styles";
import { SaveItemAction } from "domains/inbox/components/ConversationItem/components/SaveItemAction/SaveItemAction";
import { ConversationItemComponent } from "domains/inbox/components/ConversationItem/types";
import { getItemAlignment } from "domains/inbox/components/ConversationView/components/ConversationThread/conversationThread.utils";
import { OutcomeRecording } from "domains/inbox/components/ConversationView/components/OutcomeRecording/OutcomeRecording";
import { getLastConversationLabelTagItem } from "domains/inbox/util/getLastConversationLabelTag";

import { StyledLabelTagNoteContainer } from "./LabelTagNote.styles";
import { LabelTagNoteContent } from "./LabelTagNoteContent";

export const LabelTagNote = ({
    item,
    conversation,
}: ConversationItemComponent<LabelTagItem>) => {
    const textContentRef = useRef<HTMLDivElement>(null);

    const isOutcomeReportingEnabled = useFeatureFlag("OutcomeRecording");

    // If the item is the last in the conversation, it is editable
    const lastTagNoteItemInConversation =
        getLastConversationLabelTagItem(conversation);
    const isTagNoteItemEditable = lastTagNoteItemInConversation?.id === item.id;

    const [isEditing, setIsEditing] = useState(false);

    // Important to check that the tag note is editable,
    // to automatically hide the form to edit the outcome
    // once a new one come in
    if (isEditing && isTagNoteItemEditable) {
        return (
            <ConversationThreadListItem
                $align={getItemAlignment({
                    item,
                })}
            >
                <div data-testid={`conversation-item-${item.id}`}>
                    <Ds.Flex flexDirection="column" gap="2">
                        <StyledLabelTagNoteContainer>
                            <ConversationItemContent>
                                <Ds.Flex flexDirection="column" gap="3">
                                    <Ds.Flex.Item>
                                        <LabelTagNoteContent
                                            item={item}
                                            ref={textContentRef}
                                        />
                                    </Ds.Flex.Item>
                                </Ds.Flex>
                            </ConversationItemContent>
                        </StyledLabelTagNoteContainer>
                        <OutcomeRecording
                            conversation={conversation}
                            onClose={() => {
                                setIsEditing(false);
                            }}
                            title="Change outcome"
                        />
                    </Ds.Flex>
                </div>
            </ConversationThreadListItem>
        );
    } else {
        return (
            <ConversationThreadListItem
                $align={getItemAlignment({
                    item,
                })}
            >
                <StyledLabelTagNoteContainer
                    data-testid={`conversation-item-${item.id}`}
                >
                    <ConversationItemContent>
                        <Ds.Flex flexDirection="column" gap="3">
                            <Ds.Flex.Item>
                                <LabelTagNoteContent
                                    item={item}
                                    ref={textContentRef}
                                />
                            </Ds.Flex.Item>
                            <Ds.Flex.Item>
                                <Ds.Flex gap="1" alignItems="start">
                                    <SaveItemAction
                                        conversation={conversation}
                                        item={item}
                                        getTextToCopy={() =>
                                            textContentRef.current?.innerText ??
                                            ""
                                        }
                                    />
                                    {isOutcomeReportingEnabled &&
                                        isTagNoteItemEditable && (
                                            <Ds.Button
                                                appearance="secondary"
                                                size="small"
                                                onClick={() => {
                                                    setIsEditing(true);
                                                }}
                                            >
                                                Change outcome
                                            </Ds.Button>
                                        )}
                                </Ds.Flex>
                            </Ds.Flex.Item>
                        </Ds.Flex>
                    </ConversationItemContent>
                </StyledLabelTagNoteContainer>
            </ConversationThreadListItem>
        );
    }
};
