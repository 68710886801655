import { Ds, Flex, Tokens } from "@accurx/design";
import styled from "styled-components";

export const AppointmentReminderStatusWrapper = styled.div`
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: end;
    gap: ${Tokens.SIZES[1]};
    margin-bottom: ${Tokens.SIZES[1]};
`;

export const AppointmentReminderSelectWrapper = styled.div`
    width: 25%;
`;

export const StyledPaginationContainer = styled.div`
    display: flex;
    justify-content: right;
    margin-top: 1rem;
    padding-bottom: 3rem;
`;

export const StyledMessageComposeDefault = styled(Flex).attrs({
    flexDirection: "column",
    gap: "2",
})`
    background-color: ${Tokens.COLOURS.greyscale.cloud};
    white-space: pre-wrap;
    border-radius: ${Tokens.BORDERS.radius};
    padding: ${Tokens.SIZES[1]};
`;

export const StyledSpinnerPosition = styled(Ds.Flex)`
    justify-content: center;
`;
