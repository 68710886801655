import { SnomedCodeFull } from "@accurx/api/patient-messaging";
import { getApiEndpoint, httpClient, returnDataOrThrow } from "@accurx/shared";
import {
    UseQueryOptions,
    UseQueryResult,
    useQuery,
} from "@tanstack/react-query";

export const useSnomedCodeQuery = (
    options?: UseQueryOptions<SnomedCodeFull[], Error>,
): UseQueryResult<SnomedCodeFull[], Error> => {
    return useQuery({
        queryKey: ["SnomedCodes"],
        queryFn: async () => {
            const response = await httpClient.getReturnJsonSafeAsync(
                getApiEndpoint({
                    path: `/api/patientmessaging/ClinicalTerminology/codes/snomed`,
                }),
            );
            return returnDataOrThrow<SnomedCodeFull[]>(response);
        },
        ...options,
    });
};
