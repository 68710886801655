import { ReactNode } from "react";

import { ExternalEmailContact } from "@accurx/api/clinician-messaging";
import { Ds } from "@accurx/design";
import { SelectCard, SelectRadioCard } from "@accurx/navigation";
import styled from "styled-components";

type ContactSelectCardProps = {
    as?: "button" | "radio";
    onClick?: () => void;
    children: ReactNode;
    contact: ExternalEmailContact;
    ["data-testid"]?: string | null;
};

const ContentWrapper = styled(Ds.Flex).attrs({
    alignItems: "center",
    gap: "1",
})`
    min-height: 3rem;
`;

const ContactContent = ({
    contact,
    children,
}: {
    contact: ExternalEmailContact;
    children: ReactNode;
}) => {
    return (
        <ContentWrapper>
            <Ds.Flex.Item>
                <Ds.Icon
                    name="HealthProfessional"
                    appearance="outline"
                    size="small"
                />
            </Ds.Flex.Item>
            <Ds.Flex.Item>
                <Ds.Text weight="bold" color="night">
                    {contact.displayName ?? contact.email}
                </Ds.Text>
                {children}
            </Ds.Flex.Item>
        </ContentWrapper>
    );
};

export const ContactSelectCard = ({
    as = "radio",
    contact,
    children,
    onClick,
    ...props
}: ContactSelectCardProps) => {
    return as === "radio" ? (
        <SelectRadioCard
            name="contact"
            value={contact.id ?? ""}
            onClick={onClick}
            data-testid={props["data-testid"] || "ContactSelectCard"}
        >
            <ContactContent contact={contact}>{children}</ContactContent>
        </SelectRadioCard>
    ) : (
        <SelectCard
            onClick={onClick}
            data-testid={props["data-testid"] || "ContactSelectCard"}
        >
            <ContactContent contact={contact}>{children}</ContactContent>
        </SelectCard>
    );
};
