import { ComponentProps } from "react";

import { Ds } from "@accurx/design";
import { Pill } from "@accurx/inbox-design-library";
import { PatientName, calculateAge, formatPatientName } from "@accurx/patient";
import { NhsNumberHelpers } from "@accurx/shared";
import { PatientSearchResult } from "domains/inbox/components/PatientSearch/types";

import { StyledCard } from "./SuggestedPatientMatches.styles";

const PATIENT_INFO_PLACEHOLDER = "Unspecified";

type ButtonProps = Omit<ComponentProps<typeof Pill.Button>, "children">;
type Props = {
    buttonProps?: ButtonProps;
    patient: PatientSearchResult;
};

export const SuggestedPatientMatchResultCard = ({
    buttonProps,
    patient,
}: Props) => {
    const { dateOfBirth, gender, nhsNumber, firstName, familyName } = patient;
    const patientName = formatPatientName({
        firstName,
        familyName,
    });
    const ageYear = dateOfBirth ? calculateAge(dateOfBirth) : 0;
    const formattedNhsNumber = nhsNumber
        ? NhsNumberHelpers.formatNhsNumber(nhsNumber)
        : PATIENT_INFO_PLACEHOLDER;

    return (
        <StyledCard p="1.5">
            <Ds.Flex gap="1" alignItems="center" justifyContent="space-between">
                <Ds.Flex flexDirection="column" gap="0.25">
                    <PatientName
                        name={patientName}
                        ageYear={ageYear}
                        gender={gender}
                    />
                    <Ds.Text color="zinc" size="small">
                        NHS: {formattedNhsNumber}
                        <br />
                        DOB: {dateOfBirth ?? PATIENT_INFO_PLACEHOLDER}
                    </Ds.Text>
                </Ds.Flex>
                <Ds.Flex.Item>
                    <Pill.Button {...buttonProps}>
                        <Ds.Text color="zinc" weight="bold" size="small">
                            Select
                        </Ds.Text>
                    </Pill.Button>
                </Ds.Flex.Item>
            </Ds.Flex>
        </StyledCard>
    );
};
