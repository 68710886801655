import { useState } from "react";

import { FeatureName, useCurrentWorkspace, useFeatureFlag } from "@accurx/auth";
import { useAddPatientMessagesToConversation } from "@accurx/concierge/hooks/data/useAddPatientMessagesToConversation";
import { useAssignee } from "@accurx/concierge/hooks/data/useAssignee";
import {
    Conversation,
    ConversationItem,
    PatientSummary,
} from "@accurx/concierge/types";
import { patientNhsNumber } from "@accurx/concierge/util/patientNhsNumber";
import {
    Compose,
    ContactDetail,
    OnMessageSendFn,
    useIsComposing,
    usePatientMessageSendAnalytics,
    useSendMessageMutation,
} from "@accurx/message-component";
import { useMedicalRecordConnection } from "@accurx/native";
import { useComposeAreaVisibility } from "domains/inbox/components/ConversationView/ComposeAreaVisibilityContext/useComposeAreaVisibility";
import { ReplyFailedFeedback } from "domains/inbox/components/ConversationView/components/MessageActions/ReplyFailedFeedback";
import { SaveToRecordFailedFeedback } from "domains/inbox/components/ConversationView/components/MessageActions/SaveToRecordFailedFeedback";
import { useIsReplyPatientSameAsEmrPatient } from "domains/inbox/components/ConversationView/components/MessageActions/hooks/useIsReplyPatientSameAsEmrPatient";
import { useSendUserIsTyping } from "domains/inbox/components/ConversationView/components/MessageActions/hooks/useSendUserIsTyping";
import { SaveToRecordAction } from "domains/inbox/components/SaveToRecordAction/SaveToRecordAction";
import { useSearchForPatientQuery } from "domains/inbox/hooks/queries/useSearchForPatientQuery";
import { useViewportSize } from "domains/inbox/hooks/util";
import { isTestPatient } from "domains/inbox/util";
import { formatAssigneeDisplayName } from "domains/inbox/util/format/names";
import { getConversationServerId } from "domains/inbox/util/getConversationServerId";
import { toast } from "react-toastify";

import { useButtonClickAnalytics } from "../../useButtonClickAnalytics";
import { MessageActionButton } from "../MessageActionButton/MessageActionButton";
import { StyledInnerContainer, StyledOuterContainer } from "./Contents.styles";
import { EmrPatientSubmittedMismatchFeedback } from "./ReplyFeedbacks/EmrPatientSubmittedMismatchFeedback";
import { StyledReplyFeedbacksContainer } from "./ReplyFeedbacks/ReplyFeedbacksContainer.styles";

const formatPatientResponseAssigneeLabel = (
    conversationAssigneeType: Conversation["assignee"]["type"],
    mappedAssignee: ReturnType<typeof useAssignee>,
): string => {
    // If there is no assignee, response is going back to sender
    if (conversationAssigneeType === "None") {
        return "You";
    }
    return formatAssigneeDisplayName(mappedAssignee);
};

type ReplyContentsProps = {
    conversation: Conversation;
    patient: PatientSummary;
    contactDetails: ContactDetail[];
    isExpanded: boolean;
    onExpandClick: () => void;
};

export const ReplyContents = ({
    patient,
    conversation,
    contactDetails,
    isExpanded,
    onExpandClick,
}: ReplyContentsProps) => {
    const { replyState, setReplyState } = useComposeAreaVisibility();
    const viewPort = useViewportSize();
    const isMobileSize = viewPort === "xs" || viewPort === "sm";

    const isComposing = useIsComposing();
    const isReplyPatientSameAsCurrentMedicalRecordPatient =
        useIsReplyPatientSameAsEmrPatient({ replyPatient: patient });

    const addPatientMessagesToConversation =
        useAddPatientMessagesToConversation({ conversation });

    const mappedAssignee = useAssignee(conversation.assignee);
    const patientResponseAssigneeLabel = formatPatientResponseAssigneeLabel(
        conversation.assignee.type,
        mappedAssignee,
    );

    const mutation = useSendMessageMutation();

    const { trackPatientMessageSendResponse, trackPatientMessageSendClick } =
        usePatientMessageSendAnalytics();
    const { trackReplyButtonClick } = useButtonClickAnalytics(conversation);

    const [saveToRecordData, setSaveToRecordData] = useState<{
        conversationItem: ConversationItem;
        conversationSource: Conversation["source"];
        snomedCodes: string[];
    }>();
    // Medical record
    const connection = useMedicalRecordConnection();

    const { data: patientToken } = useSearchForPatientQuery({
        patient,
        select: (data) => data.searchedResult.patientToken,
        enabled: false,
        staleTime: Infinity,
    });
    const workspace = useCurrentWorkspace();

    // User typing
    const sendUserIsTyping = useSendUserIsTyping();

    const trySendViaNhsApp = useFeatureFlag(FeatureName.SendSmsViaNhsApp);

    const handleMessageSend: OnMessageSendFn = ({
        isModal,
        errors,
        warnings,
        data,
    }) => {
        // We only save to record if we're connected to EMR
        // and it allows to save to record
        // and compose is set to save to record
        const shouldSaveToRecord =
            connection.status === "Connected" &&
            connection.capabilities.saveToRecord &&
            data.isSaveToRecordEnabled;

        const isValid = errors.length === 0 && warnings.length === 0;

        // Always track the click
        trackPatientMessageSendClick({
            conversationId: getConversationServerId(conversation),
            isTestPatient: isTestPatient(patientNhsNumber(patient.externalIds)),
            shouldSaveToRecord,
            origin: "/inbox",
            isReply: true,
            ...data,
            isModal,
            warnings,
            errors,
        });

        // If it's valid, send the message
        if (isValid) {
            mutation.mutate(
                {
                    composedMessage: {
                        ...data,
                        // Assign on send is not enabled when replying
                        assignee: null,
                    },
                    options: {
                        patientToken: patientToken ?? null,
                        patientExternalIds: patient.externalIds,
                        workspaceId: workspace.orgId,
                        ticketIdentity:
                            // Only to make typescript happy.
                            // The conversation in this component is
                            // always of source Ticket
                            conversation.source.system === "Ticket"
                                ? conversation.source.ticketIdentity
                                : null,
                        trySendViaNhsApp,
                        // If patientDemographics are passed to this endpoint, they will be
                        // saved to the database.
                        // The inbox already uses the BackgroundUpdatePatientDemographics Native
                        // mutation for pushing patient demographics to the database.
                        //
                        // We can always update this in the future too if we feel it's neccessary
                        // to send them when replying too.
                        patientDemographics: null,
                    },
                },
                {
                    onSuccess: (res) => {
                        const addedItems =
                            addPatientMessagesToConversation(res);

                        if (
                            shouldSaveToRecord &&
                            addedItems &&
                            addedItems.length > 0
                        ) {
                            const snomedCodes = data.template.value?.snomedCodes
                                ? data.template.value.snomedCodes.map(
                                      ({ id }) => id,
                                  )
                                : [];

                            setSaveToRecordData({
                                conversationItem: addedItems[0],
                                conversationSource: conversation.source,
                                snomedCodes,
                            });
                        } else {
                            setReplyState("Closed");
                        }
                    },
                    onError: (err) => {
                        if (
                            err.message === "Save to record error! Item missing"
                        ) {
                            toast(<SaveToRecordFailedFeedback />);

                            setReplyState("Closed");
                        } else {
                            toast(<ReplyFailedFeedback />, {
                                autoClose: false,
                            });
                        }
                    },
                    onSettled: (_, err) => {
                        trackPatientMessageSendResponse({
                            conversationId:
                                getConversationServerId(conversation),
                            isTestPatient: isTestPatient(
                                patientNhsNumber(patient.externalIds),
                            ),
                            hasError: !!err,
                            shouldSaveToRecord,
                            origin: "/inbox",
                            isReply: true,
                            ...data,
                        });
                    },
                },
            );
        }
    };

    if (replyState === "Minimised") {
        return (
            <MessageActionButton
                onClick={() => {
                    setReplyState("Open");
                    trackReplyButtonClick();
                }}
                text={isComposing ? "Continue reply" : "Reply to patient"}
                theme="primary"
                icon="Reply"
            />
        );
    }

    return (
        <StyledOuterContainer>
            <StyledInnerContainer>
                <StyledReplyFeedbacksContainer>
                    <EmrPatientSubmittedMismatchFeedback
                        contactDetails={contactDetails}
                    />
                </StyledReplyFeedbacksContainer>
                <Compose
                    assigneeLabel={patientResponseAssigneeLabel}
                    contactDetails={contactDetails}
                    patient={patient}
                    patientMatchesCurrentMedicalRecordPatient={
                        isReplyPatientSameAsCurrentMedicalRecordPatient.isSamePatient
                    }
                    onMessageSend={handleMessageSend}
                    isMessageSending={mutation.isLoading}
                    onUserTyping={() => {
                        sendUserIsTyping({
                            workspaceId: workspace.orgId,
                            conversationId: conversation.id,
                            patientId: patient.patientId,
                        });
                    }}
                    onMinimiseClick={() => setReplyState("Minimised")}
                    //We now automatically expand the compose reply component on mobile.
                    //passing undefined down to ExpandMinimiseBar will not render the expand button
                    onExpandClick={isMobileSize ? undefined : onExpandClick}
                    isHeightRestricted={!isExpanded}
                />
                {saveToRecordData && (
                    <SaveToRecordAction
                        conversationSource={saveToRecordData.conversationSource}
                        patientId={patient.patientId}
                        patientExternalIds={patient.externalIds}
                        contentType={
                            saveToRecordData.conversationItem.contentType
                        }
                        itemServerId={
                            saveToRecordData.conversationItem.serverId
                        }
                        onSaveToRecordInitiated={() => setReplyState("Closed")}
                        snomedCodeIds={saveToRecordData.snomedCodes}
                    />
                )}
            </StyledInnerContainer>
        </StyledOuterContainer>
    );
};
