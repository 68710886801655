// this list of features is NOT final or authoritative. If you need to add to
// this list, in order to cover a new set of URLs, then please do so. Refer to
// the Product & Technical ownership map[1] for the names we use for
// features.
//
// [1] https://www.notion.so/accurx/Product-Technical-ownership-map-15813d06ee594fc686fe5ae663332f4b?pvs=4
type ProductFeature =
    | "AccuBook"
    | "Accumail (Web)"
    | "Appointment Reminders"
    | "Batch Messaging"
    | "Batch Messaging (Web)"
    | "Compose"
    | "Florey Builder"
    | "Inbox"
    | "Login"
    | "Message Receipts"
    | "Organisation Templates"
    | "Patient Lists"
    | "Patient Search"
    | "Patient Profile"
    | "Patient Triage (PT)"
    | "Questionnaires"
    | "Register"
    | "Record View"
    | "SelfBook"
    | "Settings"
    | "Two factor"
    | "Voip"
    | "Workspaces"
    | "Onboarding";

// anything not covered by the features above will be given this product tag
type Uncategorised = "[UNCATEGORISED]";
type AllProductFeatures = ProductFeature | Uncategorised;

/**
 * The map of product/feature names to routes
 *
 * Some important considerations around how this data is used
 *     - routes are matched top-to-bottom, and stops after the first match is found
 *     - paths are matched using Regex, but wildcard '*' is allowed, it's converted to regex '.*'
 *     - matching on activePath first and then matching on activeUrl as fallback
 */
const FeatureRouteDictionary: Partial<
    Record<ProductFeature, Readonly<string[]>>
> = {
    "Florey Builder": [
        "/w/:workspaceId/settings/questionnaire-builder*",
        "/practices/:orgId/questionnaire-builder*",
        "/import/:shareId*",
    ],
    Login: ["/login*"],
    Compose: ["/w/:workspaceId/compose*", "/compose/w/:workspaceId*"],
    Register: ["/register*"],
    "Two factor": ["*two-factor-auth*"],
    "Organisation Templates": [
        "/w/:workspaceId/settings/templates*",
        "/practices/:orgId/manage-templates*",
    ],
    Inbox: [
        "/inbox*",
        "/conversations*",
        "/w/:workspaceId/conversations/*",
        "*/patient-profile/all-conversations",
        "*/patient-profile/my-conversations",
        "*/patient-profile*/conversation/*",
    ],
    "Record View": [
        "/w/:workspaceId/settings/medical-records",
        "*record-view*",
    ],
    "Patient Profile": ["*patient-profile"],
    "Patient Lists": ["/patients/lists*", "/patient-lists"],
    "Patient Search": ["/patients/search*", "/patients/recent-searches"],
    "Batch Messaging (Web)": [
        "/batch-messages*",
        "/w/:workspaceId/batch-messages",
    ],
    "Batch Messaging": [
        "/w/:workspaceId/batch-messaging-*",
        "*/all-messages/batch-messages*",
        "/practices/:orgId/batch-messaging-*",
    ],
    "Message Receipts": [
        "/w/:workspaceId/settings/delivery-receipts*",
        "/organisations/:orgId/all-messages*",
        "/practices/:orgId/delayed-messages*",
    ],
    "Accumail (Web)": ["*clinician-conversation*", "/message-practice*"],
    "Appointment Reminders": [
        "/w/:workspaceId/settings/appointment-reminders*",
        "/organisations/:orgId/appointment-reminders/*",
        "/practices/:orgId/appointment-reminder-compose",
        "*appointment-reminder*",
    ],
    SelfBook: ["/w/:workspaceId/self-book*"],
    AccuBook: [
        "/w/:workspaceId/settings/accubook*",
        "/organisations/:orgId/accubook/*",
        "/practices/:orgId/vaccine*",
    ],
    Questionnaires: ["/w/:workspaceId/questionnaires"],
    "Patient Triage (PT)": [
        "/w/:workspaceId/settings/patient-triage*",
        "/practices/:orgId/patient-triage*",
    ],
    Settings: ["/account/user/*", "*/settings/*", "/w/:workspaceId/settings*"],
    Voip: ["*voip*"],
    Onboarding: [
        "/join-organisation",
        "/add-organisation",
        "/join-pharmacy",
        "/terms-and-conditions",
    ],
    Workspaces: [
        "/workspace*",
        "*/workspace-onboarding/*",
        "/w/:workspaceId/workspace-onboarding/*",
    ],
};

/**
 * Search for the relevant feature tags
 * @param activePath ie: "/organisations/:orgId/all-messages/:tab"
 * @param activeUrl ie: "/organisations/3/all-messages/batch-messages"
 * @returns AllProductFeatures ie: "Batch Messaging"
 */
export function categoriseRouteByFeature(
    activePath: string,
    activeUrl: string,
) {
    const routeToFeature = new Map(Object.entries(FeatureRouteDictionary));

    // fallback for all non-explicitly owned routes
    let feature: AllProductFeatures = "[UNCATEGORISED]";

    for (const [productFeature, routes] of routeToFeature) {
        const foundMatchingRoute = routes.find((r) => {
            // make sure we can handle the wildcards(*) from the route patterns in the dictionary
            const regex = new RegExp(`^${r.replace(/\*/gi, ".*")}`);

            // match the active route match to values in the dictionary
            return regex.test(activePath) || regex.test(activeUrl);
        });

        if (foundMatchingRoute) {
            feature = productFeature as ProductFeature;
            break; // stop now we've found a match
        }
    }

    return feature;
}
