import { ExternalEmailContact } from "@accurx/api/clinician-messaging";
import last from "lodash/last";

/**
 * Groups contacts by their institution while preserving the order of a pre-sorted list.
 * The list is typically sorted by distance to the requesting workspace,
 * but may also be sorted by message frequency or recency.
 *
 * Contacts with the same institution appearing in consecutive positions are grouped together.
 * A new group is created when the next contact belongs to a different institution.
 * If the same institution appears again further down the list, a separate group is created.
 *
 * Example:
 * Input: [Institution A, Institution A, Institution B, Institution A]
 * Output: [Group(Institution A), Group(Institution B), Group(Institution A)]
 */
export const groupContactsByInstitution = (
    contacts: ExternalEmailContact[],
) => {
    const groupedContacts: Array<ExternalEmailContact[]> = [];
    contacts.forEach((contact) => {
        const latestGroup = last(groupedContacts);
        if (
            latestGroup &&
            latestGroup[0] &&
            latestGroup[0].institution?.id === contact.institution?.id
        ) {
            latestGroup.push(contact);
        } else {
            groupedContacts.push([contact]);
        }
    });
    return groupedContacts;
};
