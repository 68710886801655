import { FormEvent, ReactNode, useEffect, useState } from "react";

import { Ds, Text } from "@accurx/design";
import { FormFieldFeedback, Input } from "@accurx/inbox-design-library";
import {
    PatientDemographics,
    useMedicalRecordConnection,
} from "@accurx/native";
import { useSearchForPatientInEmrMutation } from "domains/inbox/hooks/mutations/useSearchForPatientInEmrMutation";

import {
    StyledFormSpacer,
    StyledSearchFormSubmitButtonContainer,
} from "./Forms.styles";
import { NoPatientsFoundFeedback } from "./NoPatientsFoundFeedback";
import { SearchButton } from "./SearchButton";
import { SearchErrorFeedback } from "./SearchErrorFeedback";
import { sanitiseNhsNumber } from "./sanitiseNhsNumber";
import {
    FormErrorsEMRPatientDetails,
    FormStateEMRPatientDetails,
} from "./types";
import { validateEMRPatientDetailsForm } from "./validateEmrForms";

type Field = {
    label: string;
    sublabel?: string;
    fieldKey: keyof FormStateEMRPatientDetails;
    id: string;
};

export const formEMRPatientDetailsId =
    "search-for-patient-via-emr-patient-details-form";

const FIELDS: Field[] = [
    {
        label: "NHS number",
        id: "nhs-number",
        sublabel: "e.g. 111 222 3333",
        fieldKey: "nhsNumber",
    },
    {
        label: "First name",
        id: "first-name",
        fieldKey: "firstName",
    },
    {
        label: "Last name",
        id: "last-name",
        fieldKey: "lastName",
    },
];

export const FormEMRPatientDetails = ({
    onResultsFound,
    onSearchClick,
    onSearchSettled,
    setIsLoading,
    submitButton,
}: {
    onResultsFound: (data: PatientDemographics[]) => void;
    onSearchClick?: (options: { hasError: boolean }) => void;
    onSearchSettled?: (options: {
        hasError: boolean;
        countResult: number;
    }) => void;
    setIsLoading?: (isLoading: boolean) => void;
    submitButton?: ReactNode;
}) => {
    const [formState, setFormState] = useState<FormStateEMRPatientDetails>({
        nhsNumber: "",
        firstName: "",
        lastName: "",
    });
    const [formFieldErrors, setFormFieldErrors] =
        useState<FormErrorsEMRPatientDetails>({});
    const { system } = useMedicalRecordConnection();

    const { mutate, status, data } = useSearchForPatientInEmrMutation({
        onSuccess: (data) => {
            if (data.results.length > 0) {
                onResultsFound(data.results);
            }
        },
        onSettled: (data, error) =>
            onSearchSettled?.({
                hasError: !!error,
                countResult: data?.results.length ?? 0,
            }),
    });

    useEffect(() => {
        setIsLoading?.(status === "loading");
    }, [status, setIsLoading]);

    const handleFormSubmit = (e: FormEvent) => {
        e.preventDefault();

        const { isValid, errors } = validateEMRPatientDetailsForm(formState);

        onSearchClick?.({ hasError: !isValid });

        if (isValid) {
            setFormFieldErrors({});
            mutate({
                ...formState,
                nhsNumber: sanitiseNhsNumber(formState.nhsNumber),
            });
        } else {
            setFormFieldErrors(errors);
        }
    };

    const handleChange = (
        data: Partial<FormStateEMRPatientDetails>,
        field: keyof FormStateEMRPatientDetails,
    ) => {
        if (formFieldErrors[field]) {
            setFormFieldErrors((prev) => ({
                ...prev,
                [field]: undefined,
            }));
        }
        if (formFieldErrors.allFieldsError) {
            setFormFieldErrors((prev) => ({
                ...prev,
                allFieldsError: undefined,
            }));
        }

        setFormState((prev) => ({
            ...prev,
            ...data,
        }));
    };

    const searchIsError = status === "error";
    const searchHasNoResults =
        status === "success" && data.results.length === 0;

    return (
        <>
            <form
                id={formEMRPatientDetailsId}
                onSubmit={handleFormSubmit}
                aria-label={`Search for patient ${
                    system ? `in ${system}` : ""
                }`}
                aria-describedby={
                    searchHasNoResults
                        ? "patient-search-emr-form-no-results"
                        : searchIsError
                        ? "search-error"
                        : undefined
                }
            >
                <StyledFormSpacer>
                    {!!formFieldErrors.allFieldsError && (
                        <Ds.Text>
                            <Ds.Icon
                                name="Failed"
                                color="red"
                                size="small"
                                appearance="solid"
                            />
                            {formFieldErrors.allFieldsError}
                        </Ds.Text>
                    )}
                    {FIELDS.map((field) => {
                        const errorMessage =
                            formFieldErrors[field.fieldKey] ?? null;
                        return (
                            <div key={field.id}>
                                <Text
                                    as="label"
                                    variant="note"
                                    props={{ htmlFor: field.id }}
                                    skinny
                                >
                                    {field.label}
                                </Text>
                                {!!field.sublabel && (
                                    <Ds.Text size="small">
                                        {field.sublabel}
                                    </Ds.Text>
                                )}
                                <Input
                                    id={field.id}
                                    value={formState[field.fieldKey]}
                                    onChange={(e): void =>
                                        handleChange(
                                            {
                                                ...formState,
                                                [field.fieldKey]:
                                                    e.target.value,
                                            },
                                            field.fieldKey,
                                        )
                                    }
                                    aria-describedby={
                                        errorMessage
                                            ? `${field.id}-error`
                                            : undefined
                                    }
                                    hasErrors={!!errorMessage}
                                />
                                {!!errorMessage && (
                                    <div id={`${field.id}-error`}>
                                        <FormFieldFeedback
                                            text={errorMessage}
                                            variant="error"
                                        />
                                    </div>
                                )}
                            </div>
                        );
                    })}
                    {submitButton ?? (
                        <StyledSearchFormSubmitButtonContainer>
                            <SearchButton
                                buttonProps={{
                                    form: formEMRPatientDetailsId,
                                    loading: status === "loading",
                                }}
                            />
                        </StyledSearchFormSubmitButtonContainer>
                    )}
                    {searchHasNoResults && (
                        <NoPatientsFoundFeedback id="patient-search-emr-form-no-results" />
                    )}
                    {!!searchIsError && (
                        <SearchErrorFeedback id="search-error" />
                    )}
                </StyledFormSpacer>
            </form>
        </>
    );
};
