import { useEffect, useMemo, useRef } from "react";

import { useCurrentUser, useCurrentWorkspace } from "@accurx/auth";
import { Feedback } from "@accurx/design";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelectedOrganisation } from "reduxQuarantine/SelectedOrganisationProvider";

import { BasicSearchState } from "../components/PatientSearch/PatientSearchForm/PatientSearchForm.helpers";
import { useRedeemPatientToken } from "./mutations/useRedeemPatientToken";

const formatDate = (
    rawDay?: number | null,
    rawMonth?: number | null,
    rawYear?: number | null,
) => {
    const day = rawDay ? `0${rawDay}`.slice(-2) : "";
    const month = rawMonth ? `0${rawMonth}`.slice(-2) : "";
    const year = rawYear?.toString() ?? "";
    return { day, month, year };
};

type PatientTokenProps = (patientData: BasicSearchState) => void;

export const usePatientTokenCheck = (onSuccess: PatientTokenProps) => {
    const history = useHistory();
    const { user } = useCurrentUser();
    const workspace = useCurrentWorkspace();
    const { setSelectedOrgId } = useSelectedOrganisation();

    const isMounted = useRef(true);
    const { mutate: redeemToken, isLoading } = useRedeemPatientToken({
        onSuccess: (data) => {
            if (!isMounted.current) return; // Prevent state update after unmount
            const tokenOrgId = data.organisationId;
            const isJoiningWorkspaceRequired = user.organisations.every(
                (org) => org.orgId !== tokenOrgId,
            );
            if (isJoiningWorkspaceRequired) {
                toast(
                    Feedback({
                        colour: "warning",
                        content: "Join your workspace to view the patient",
                    }),
                );
                history.push("/join-organisation", { showBackButton: true });
                return;
            }

            const isWorkspaceSwitchRequired = tokenOrgId !== workspace.orgId;
            if (isWorkspaceSwitchRequired) {
                setSelectedOrgId(tokenOrgId);

                toast(
                    Feedback({
                        colour: "information",
                        content: "You have switched to a different workspace",
                    }),
                );
            }

            const { day, month, year } = formatDate(
                data.dateOfBirthDay,
                data.dateOfBirthMonth,
                data.dateOfBirthYear,
            );
            onSuccess({
                nhsNumber: data.nhsNumber ?? "",
                dateOfBirth: { day, month, year },
            });
        },
        onError: () => {
            if (!isMounted.current) return; // Prevent state update after unmount
            toast(
                Feedback({
                    colour: "error",
                    content:
                        "There was an issue with retrieving patient data, please try a new patient search",
                }),
            );
        },
    });

    const params = useMemo(
        () => new URLSearchParams(history.location.search),
        [history.location.search],
    );
    const patientToken = params.get("patientToken");

    useEffect(() => {
        isMounted.current = true;
        if (patientToken !== null && !isLoading) {
            redeemToken({ token: patientToken });
        }
        return () => {
            isMounted.current = false; // Mark as unmounted to prevent state updates
        };
    }, [redeemToken, patientToken, isLoading]);
};
