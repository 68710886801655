import { useState } from "react";

import { ExternalEmailContact } from "@accurx/api/clinician-messaging";
import { useCurrentWorkspace } from "@accurx/auth";
import { Ds, Feedback, FormFieldWrapper, Input } from "@accurx/design";
import { NetworkError } from "@accurx/shared";
import { useDirectory } from "domains/directory/context/DirectoryProvider";
import {
    EmailValidationError,
    useEmailValidation,
} from "domains/directory/hooks/mutations/useEmailValidationMutation";
import { toast } from "react-toastify";
import styled from "styled-components";

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@.]+$/;

type EmailValidationProps = {
    onValid: (email: string) => void;
};

export const EmailValidation = ({ onValid }: EmailValidationProps) => {
    const { orgId: workspaceId } = useCurrentWorkspace();
    const [error, setError] = useState("");
    const {
        mutate: validateEmail,
        isLoading,
        data,
    } = useEmailValidation({
        onSuccess: (data, variables) => {
            if (!data.contact) {
                onValid(variables.emailAddress);
            }
        },
        onError: (error) => {
            if (error instanceof EmailValidationError) {
                setError(error.message);
                return;
            }

            toast(
                Feedback({
                    colour: "error",
                    title:
                        error instanceof NetworkError
                            ? "Couldn’t validate email. Please check your connection and try again."
                            : "Couldn’t validate email. Please try again.",
                }),
            );
        },
    });

    const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
        e.preventDefault();
        setError("");

        const formData = new FormData(e.currentTarget);

        const emailAddress = formData.get("email") ?? "";

        if (typeof emailAddress === "string" && emailRegex.test(emailAddress)) {
            validateEmail({ workspaceId, emailAddress });
            return;
        }

        setError("Enter a valid email address");
    };

    return (
        <>
            <form id="add-contact" noValidate onSubmit={handleSubmit}>
                <FormFieldWrapper
                    label="Email"
                    labelProps={{ htmlFor: "email-input" }}
                    errors={[error]}
                >
                    <Input
                        autoFocus
                        type="text"
                        id="email"
                        name="email"
                        required
                        hasErrors={!!error}
                    />
                </FormFieldWrapper>
                {!!error && (
                    <Ds.Box ml="3">
                        <Ds.Link
                            target="_blank"
                            href="https://support.accurx.com/en/articles/5543623-accumail-who-can-i-email"
                        >
                            Learn about secure domains
                        </Ds.Link>
                    </Ds.Box>
                )}
            </form>

            {isLoading && (
                <Ds.Box mt="6">
                    <Ds.Flex alignItems="center" justifyContent="center">
                        <Ds.Spinner />
                    </Ds.Flex>
                </Ds.Box>
            )}

            {data?.contact && <ExistingContact contact={data.contact} />}
        </>
    );
};

const ExistingContact = ({ contact }: { contact: ExternalEmailContact }) => {
    const { setSelectedContactId, setView } = useDirectory();

    const handleViewContact = () => {
        if (!contact.id) return;
        setSelectedContactId(contact.id);
        setView("ContactDetails");
    };

    const institutionName = contact.institution?.name;
    const serviceName = contact.service?.name;

    return (
        <Ds.Box mt="3">
            <Ds.Text mb="1">A contact with this email already exists</Ds.Text>
            <Ds.DropdownMenu modal={false}>
                <ExistingContactWrapper>
                    <Ds.Flex.Item>
                        <Ds.Text weight="bold">
                            {contact.displayName ?? contact.email}
                        </Ds.Text>
                        <Ds.Text color="metal">
                            {institutionName}
                            {institutionName && serviceName && (
                                <>&nbsp;&bull;&nbsp;</>
                            )}
                            {serviceName}
                        </Ds.Text>
                    </Ds.Flex.Item>
                    <Ds.Flex.Item>
                        <Ds.DropdownMenu.Trigger asChild>
                            <Ds.Button appearance="tertiary">
                                <Ds.Button.Icon
                                    name="More"
                                    appearance="solid"
                                />
                            </Ds.Button>
                        </Ds.DropdownMenu.Trigger>
                    </Ds.Flex.Item>
                </ExistingContactWrapper>
                <StyledDropdownContent>
                    <Ds.DropdownMenu.Item>
                        <Ds.Flex justifyContent="start" gap="1">
                            <Ds.Icon
                                name="MessageWrite"
                                color="night"
                                size="small"
                            />
                            <Ds.Text color="night" weight="bold">
                                Message
                            </Ds.Text>
                        </Ds.Flex>
                    </Ds.DropdownMenu.Item>
                    <Ds.DropdownMenu.Item onClick={handleViewContact}>
                        <Ds.Flex justifyContent="start" gap="1">
                            <Ds.Icon
                                name="ArrowTail"
                                color="night"
                                size="small"
                                rotate="90"
                            />
                            <Ds.Text color="night" weight="bold">
                                View contact
                            </Ds.Text>
                        </Ds.Flex>
                    </Ds.DropdownMenu.Item>
                </StyledDropdownContent>
            </Ds.DropdownMenu>
        </Ds.Box>
    );
};

const ExistingContactWrapper = styled(Ds.Flex).attrs({
    alignItems: "center",
    justifyContent: "space-between",
})`
    border: solid 1px var(--ds-color-silver);
    padding: 0.75rem;
    border-radius: 0.25rem;
`;

const StyledDropdownContent = styled(Ds.DropdownMenu.Content)`
    width: 200px;
`;
