import { Text } from "@accurx/design";

import { RouterLink } from "app/organisations/shared/RouterLink";
import { ROUTES } from "shared/Routes";
import { useAppSelector } from "store/hooks";

import { BreadcrumbWrapper } from "./BreadcrumbWrapper";

export type BreadcrumbProps = {
    title?: string;
    wrapper?: boolean;
};

export const Breadcrumb = ({ title, wrapper = true }: BreadcrumbProps) => {
    const orgName = useAppSelector(({ account, practices }) => {
        const organisations = account.user?.organisations || [];
        const organisation = organisations.find(
            (org) => org.orgId.toString() === practices.selectedPractice,
        );
        return organisation?.organisationName || "";
    });

    return (
        <BreadcrumbWrapper wrapperContent={wrapper}>
            <RouterLink to={ROUTES.home}>{orgName || "Home"}</RouterLink>
            <Text as="span" props={{ className: "mx-2" }} skinny>
                /
            </Text>
            <Text as="span" skinny>
                {title}
            </Text>
        </BreadcrumbWrapper>
    );
};
