import { SnomedCodeFull } from "@accurx/api/patient-messaging";
import {
    Ds,
    Feedback,
    FormFieldFeedback,
    Option,
    SearchSelect,
} from "@accurx/design";
import { SearchSelectMode } from "@accurx/design/dist/components/Forms/Select/models";
import { useSnomedCodeQuery } from "domains/self-book/queries/useSnomedCodesQuery";

import { StyledSelectWrapper } from "./DeclineSettings.styles";

export type SnomedCodeSelectProps = {
    selectedSnomedCode: string;
    onSnomedCodeSelect: (snomedId: string) => void;
    errors: string | undefined;
};

export const SnomedCodeSelect = ({
    onSnomedCodeSelect,
    selectedSnomedCode,
    errors,
}: SnomedCodeSelectProps): JSX.Element | null => {
    const id = "decline-settings-snomed-dropdown";

    const { data, error, status } = useSnomedCodeQuery();

    if (status === "loading") {
        return <Ds.Spinner size="small" />;
    }

    if (!data && error.message) {
        return (
            <Feedback
                title="An error occurred while trying to get self book configuration"
                colour="error"
            >
                {error.message}
            </Feedback>
        );
    }

    const handleOptionChange = (selected: Option[] | Option) => {
        const selectedValue = (selected as Option).value;

        onSnomedCodeSelect(selectedValue === "RESET" ? "" : selectedValue);
    };

    const snomedCodes = data as SnomedCodeFull[];

    // Create options for the dropdown
    const options: Option[] = [
        {
            label: "No SNOMED code",
            value: "RESET",
        },
        ...snomedCodes.map((snomed: SnomedCodeFull) => ({
            label: snomed.term ?? undefined,
            value: snomed.conceptId ?? "",
        })),
    ];

    // Search for previously selected SNOMED
    const selectedSnomed = snomedCodes.find(
        (snomed: SnomedCodeFull) => selectedSnomedCode === snomed.conceptId,
    );
    const initialValue =
        selectedSnomed !== undefined &&
        selectedSnomed.term &&
        selectedSnomed.conceptId
            ? { label: selectedSnomed.term, value: selectedSnomed.conceptId }
            : undefined;

    return (
        <StyledSelectWrapper data-testid={`${id}-select-wrapper`}>
            <SearchSelect
                id={id}
                dimension="medium"
                options={options}
                onChangeHandler={handleOptionChange}
                initSelectedValues={initialValue}
                zIndex={2}
                shouldDisplayValues={true}
                valueDescription="Concept ID"
                searchMode={SearchSelectMode.IncludeValue}
                error={!!errors}
            />

            {errors && (
                <FormFieldFeedback
                    as="span"
                    text={
                        <Ds.Text size="small" as="span">
                            {errors}
                        </Ds.Text>
                    }
                    variant="error"
                />
            )}
        </StyledSelectWrapper>
    );

    return null;
};
