import { usePatient } from "@accurx/concierge/hooks/data/usePatient";
import { useUser } from "@accurx/concierge/hooks/data/useUser";
import { formatPatientName } from "@accurx/patient";
import { DateFormatOptions, DateHelpers } from "@accurx/shared";
import { formatUserDisplayName } from "domains/inbox/util/format/names";

export const useGetClinicianToPatientMessageInfo = (options: {
    patientId?: string | null;
    /** The email, phone number or other ways in which the patient was contacted */
    contactMethod?: string | null;
    userId: string;
    dateCreatedAt: string;
    messageBody: string;
}) => {
    const patient = usePatient({ patientId: options.patientId });
    const user = useUser({ userId: options.userId });

    return () => {
        const patientName = patient ? formatPatientName(patient) : null;
        const senderName = formatUserDisplayName({
            user,
            considerCurrentUser: false,
        });

        const formattedDateTime = DateHelpers.formatDate(
            options.dateCreatedAt,
            DateFormatOptions.HUMAN_READABLE_DATE_TIME,
        );

        const toCopy = [];

        patientName && toCopy.push(`Sent to:\n${patientName}`);
        options.contactMethod &&
            toCopy.push(`Contact method:\n${options.contactMethod}`);
        senderName && toCopy.push(`Sent by:\n${senderName}`);
        formattedDateTime &&
            toCopy.push(`Date and time:\n${formattedDateTime}`);
        toCopy.push(`Message text:\n${options.messageBody}`);

        return toCopy.join("\n\n");
    };
};

export const useGetPatientToClinicianMessageInfo = (options: {
    patientId?: string | null;
    viaProxy?: boolean;
    dateCreatedAt: string;
    messageBody: string;
}) => {
    const patient = usePatient({ patientId: options.patientId });

    return () => {
        const patientName = patient ? formatPatientName(patient) : null;
        const patientLabel = options.viaProxy ? "Via proxy" : "Patient";
        const formattedDateTime = DateHelpers.formatDate(
            options.dateCreatedAt,
            DateFormatOptions.HUMAN_READABLE_DATE_TIME,
        );

        const toCopy = [];

        patientName &&
            toCopy.push(`Sent by:\n${patientName} (${patientLabel})`);
        formattedDateTime &&
            toCopy.push(`Date and time:\n${formattedDateTime}`);
        toCopy.push(`Message text:\n${options.messageBody}`);

        return toCopy.join("\n\n");
    };
};
