import { usePatient } from "@accurx/concierge/hooks/data/usePatient";
import { Conversation } from "@accurx/concierge/types";
import { Ds } from "@accurx/design";
import { OpenInEmrButton } from "@accurx/native";
import { AutoFocusHeading } from "@accurx/navigation";
import { PatientHeader, formatPatientName } from "@accurx/patient";
import { useViewportSize } from "domains/inbox/hooks/util";
import { getPatientMatchState } from "domains/inbox/util/getPatientMatchState";

import { BackButton } from "./BackButton";
import { StyledContainer } from "./ConversationViewHeader.styles";
import {
    PatientMatchingActions,
    SearchForPatientButtonProps,
} from "./PatientMatchingActions";

type PatientHeaderProps = {
    conversation: Conversation;
    onClickBack: (() => void) | undefined;
    conversationFeedback?: JSX.Element;
    searchForPatientButtonProps?: SearchForPatientButtonProps;
};

export const ConversationViewHeader = ({
    conversation,
    onClickBack,
    conversationFeedback,
    searchForPatientButtonProps,
}: PatientHeaderProps) => {
    const size = useViewportSize();
    const isMobileSize = size === "xs" || size === "sm";
    const patientId = conversation.regardingPatientId;
    const patientMatchState = getPatientMatchState(conversation);
    const patient = usePatient({ patientId });

    const nhsNumber = patient?.externalIds.find(
        (externalId) => externalId.type === "NhsNumber",
    )?.value;

    const patientName = formatPatientName({
        firstName: patient?.firstName,
        familyName: patient?.familyName,
    });

    const showBackButton = !!onClickBack && isMobileSize;

    return (
        <StyledContainer $reducedPadding={showBackButton}>
            {showBackButton && <BackButton onClickBack={onClickBack} />}
            <Ds.Flex.Item flex="1">
                <Ds.Flex gap="1" flexDirection="column">
                    {conversationFeedback}
                    {patient && (
                        <PatientHeader>
                            <PatientHeader.Details>
                                <AutoFocusHeading as="h2">
                                    <PatientHeader.Details.Name
                                        name={patientName}
                                        ageYear={patient.ageYear}
                                        gender={patient.gender}
                                    />
                                </AutoFocusHeading>
                                <PatientHeader.Details.Additional
                                    nhsNumber={nhsNumber}
                                    dateOfBirth={patient.dateOfBirth}
                                />
                            </PatientHeader.Details>
                            <Ds.Flex.Item>
                                <PatientHeader.Actions>
                                    {!!patientMatchState &&
                                        conversation.status === "Open" && (
                                            <PatientMatchingActions
                                                searchForPatientButtonProps={
                                                    searchForPatientButtonProps
                                                }
                                                patientMatchState={
                                                    patientMatchState
                                                }
                                                conversation={conversation}
                                            />
                                        )}
                                    {!!patientId && (
                                        <OpenInEmrButton
                                            patientExternalIds={
                                                patient.externalIds
                                            }
                                        />
                                    )}
                                </PatientHeader.Actions>
                            </Ds.Flex.Item>
                        </PatientHeader>
                    )}
                </Ds.Flex>
            </Ds.Flex.Item>
        </StyledContainer>
    );
};
