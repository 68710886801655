import { ReactNode, useEffect, useState } from "react";

import { useAnalytics } from "@accurx/analytics";
import { Ds } from "@accurx/design";
import {
    NavTooltip,
    ProductErrorBoundary,
    SplitButtonGroup,
} from "@accurx/navigation";
import { BasicSearchState, usePatientTokenCheck } from "@accurx/patient";
import { Slot } from "@radix-ui/react-slot";
import { StyledTriggerButton } from "layouts/UnifiedLayout/UnifiedLayout.styles";
import { userflowIds } from "layouts/constants";
import { useHistory } from "react-router-dom";

import { ROUTES } from "shared/Routes";

import { NewPatientSearchModal } from "./NewPatientSearchModal";

type PatientSearchProps = {
    children?: ReactNode;
};

const removeQueryParam = (queryParams: string, target: string) => {
    const formattedQueryParams = new URLSearchParams(queryParams);
    formattedQueryParams.delete(target);
    return formattedQueryParams.toString();
};

export const NewPatientSearch = ({ children }: PatientSearchProps) => {
    const history = useHistory<BasicSearchState | undefined>();
    const track = useAnalytics();

    usePatientTokenCheck((data) => {
        // Navigate user to workplace entrypoint with modal open when patient token is redeemed
        history.replace({
            pathname: ROUTES.home,
            search: "?patientSearch=true",
            state: data,
        });
    });

    const [patientData, setPatientData] = useState<BasicSearchState>();
    const [isModalOpen, setIsModalOpen] = useState(false);

    // Prefill and open patient search modal when "patientSearch" query param is present
    useEffect(() => {
        const params = new URLSearchParams(history.location.search);
        const isPatientSearchOpen = params.get("patientSearch");
        const state = history.location.state;

        if (isPatientSearchOpen) {
            setIsModalOpen(true);

            if (state?.nhsNumber) {
                setPatientData({
                    nhsNumber: state.nhsNumber,
                    dateOfBirth: {
                        day: state.dateOfBirth.day,
                        month: state.dateOfBirth.month,
                        year: state.dateOfBirth.year,
                    },
                });
            }
        }
    }, [history.location]);

    const handleSearchOpen = () => {
        setIsModalOpen(true);
        track("NavigationMenu Button Click", {
            origin: history.location.pathname,
            navigationOptionSelected: "PatientSearch",
            navigationVersion: "Unified",
            eventVersion: 2,
        });
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        const queryParams = removeQueryParam(
            history.location.search,
            "patientSearch",
        );

        history.replace({ ...history.location, search: queryParams });
        setPatientData(undefined);
        track("PatientSearchClose Button Click", {
            navigationVersion: "Unified",
            productOrigin: "PatientSearch",
        });
    };

    return (
        <>
            <SplitButtonGroup>
                <NavTooltip text="Search patient">
                    <Slot onClick={handleSearchOpen}>
                        {children || (
                            <StyledTriggerButton
                                data-userflow-id={
                                    userflowIds.navigation.primary.searchPatient
                                }
                                aria-label="Search patient"
                            >
                                <Ds.Icon
                                    name="SearchPerson"
                                    appearance="solid"
                                    size="small"
                                    color="white"
                                />
                            </StyledTriggerButton>
                        )}
                    </Slot>
                </NavTooltip>
            </SplitButtonGroup>

            <ProductErrorBoundary name="PatientSearch" showToast>
                {isModalOpen && (
                    <NewPatientSearchModal
                        modalOpen={true}
                        setModalOpen={handleCloseModal}
                        initialData={patientData}
                    />
                )}
            </ProductErrorBoundary>
        </>
    );
};
