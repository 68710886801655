import { useAnalytics } from "@accurx/analytics";
import { Ds } from "@accurx/design";

export const listOfForgotReplyPhrases = [
    { label: "?", regex: new RegExp(`\\?`) },
    { label: "reply", regex: new RegExp(`\\breply\\b`, "i") },
    { label: "respond", regex: new RegExp(`\\brespond\\b`, "i") },
    { label: "tell me", regex: new RegExp(`\\btell me\\b`, "i") },
    { label: "tell us", regex: new RegExp(`\\btell us\\b`, "i") },
    { label: "let us know", regex: new RegExp(`\\blet us know\\b`, "i") },
    { label: "let me know", regex: new RegExp(`\\blet me know\\b`, "i") },
    { label: "back to me", regex: new RegExp(`\\bback to me\\b`, "i") },
    { label: "back to us", regex: new RegExp(`\\bback to us\\b`, "i") },
];

type ForgotAllowReplyModalProps = {
    isOpen: boolean;
    onClose: () => void;
    onSendMessage: () => void;
    triggers: string[];
};

export const ForgotAllowReplyModal = ({
    isOpen,
    onClose,
    onSendMessage,
    triggers,
}: ForgotAllowReplyModalProps) => {
    const track = useAnalytics();

    const trackAction = ({
        actionType,
    }: {
        actionType: "Confirm" | "Cancel";
    }) => {
        track("MessageComposeAllowReplyPrompt Button Click", {
            promptActionType: actionType,
            triggerTermList: triggers,
            eventVersion: 1,
        });
    };

    const handleClose = () => {
        trackAction({ actionType: "Cancel" });
        onClose();
    };

    const handleSend = () => {
        trackAction({ actionType: "Confirm" });
        onSendMessage();
    };

    return (
        <Ds.Modal open={isOpen} onOpenChange={handleClose}>
            <Ds.Modal.Section>
                <Ds.Flex flexDirection="column" gap="2">
                    <Ds.Modal.Title>Allow reply?</Ds.Modal.Title>
                    <Ds.Text>
                        It looks like you're expecting a reply to this message.
                        If so, tick 'Allow reply' before sending.
                    </Ds.Text>
                    <Ds.Flex justifyContent="flex-end" gap="2">
                        <Ds.Button
                            onClick={handleClose}
                            type="button"
                            appearance="secondary"
                        >
                            Back
                        </Ds.Button>
                        <Ds.Button onClick={handleSend} type="button">
                            Send now
                        </Ds.Button>
                    </Ds.Flex>
                </Ds.Flex>
            </Ds.Modal.Section>
        </Ds.Modal>
    );
};
