import { CrossOrgBookableOrganisation } from "@accurx/api/appointment";
import { Option } from "@accurx/design";
import { DateHelpers } from "@accurx/shared";
import { format } from "date-fns";

import { QueryParam } from "../queries/useSlotAggregateAvailability";
import { appointmentAvailabilityPeriodCalculation } from "./appointmentAvailabilityPeriodCalculation";
import { convertAvailabilityPeriodToDays } from "./appointmentAvailabilityPeriodConversions";
import {
    AppointmentAvailabilityPeriod,
    ClinicianConfig,
    DeclineSettingsConfig,
    DeclineSettingsErrors,
    KeyValueDTO,
    OrganisationOption,
    SelfBookFormData,
    SelfbookConfigurationPayload,
    SlotAvailabilityOption,
} from "./types";

export const DEFAULT_LINK_TIMEOUT_DAYS = 7;

export const transformToSlotTypesOptions = (
    selfbookAvailability: KeyValueDTO | undefined,
): Option[] =>
    // Transforming the test object into an array of objects with label and value properties
    Object.entries(selfbookAvailability || {}).map(([key, value]) => ({
        label: key,
        value: `${value}`,
    }));

export const transformToOrganisationOptions = (
    orgId: number,
    organisationName: string,
    crossOrgBookableOrganisations: CrossOrgBookableOrganisation[],
): OrganisationOption[] => {
    const currentOrgObj: OrganisationOption = {
        label: `${organisationName} (Default)`,
        value: `${orgId}`,
        isDefault: true,
    };

    // Transforming the test object into an array of objects with label, value and isDefault properties
    const crossOrgs: OrganisationOption[] = crossOrgBookableOrganisations.map(
        (value) => ({
            label: value.name,
            value: value.odsCode,
            isDefault: false,
        }),
    );

    return [currentOrgObj, ...crossOrgs];
};

export const validateOrganisationForErrors = (
    slotAggregateAvailabilityStatus: string,
): string[] | undefined => {
    if (slotAggregateAvailabilityStatus === "error") {
        return ["Organisation is not available, please try again later."];
    }
    return undefined;
};

export const validateSlotTypeForErrors = (
    slotType: SlotAvailabilityOption | undefined,
    addBookingLinkClicked: boolean,
): string[] | undefined => {
    if (addBookingLinkClicked && slotType === undefined) {
        return ["Select a slot type that patients will book into."];
    }
    if (slotType && slotType.count === 0) {
        return [
            "No appointments. Make more available in your clinical system.",
        ];
    }
    return undefined;
};

export const validateChooseClinicianForErrors = (
    clinicians: ClinicianConfig | undefined,
    addBookingLinkClicked: boolean,
): string[] | undefined => {
    if (
        addBookingLinkClicked &&
        clinicians?.clinicianType === "SpecificClinicians" &&
        clinicians.clinicians.length === 0
    ) {
        return ["Select at least one clinician."];
    } else if (
        clinicians?.clinicianType === "SpecificClinicians" &&
        clinicians.clinicians.length > 0 &&
        clinicians.clinicians.every((item) => item.count === 0)
    ) {
        return clinicians.clinicians.length === 1
            ? ["Selected clinician has no availability"]
            : ["Selected clinicians have no availability"];
    }
    return undefined;
};

const getTimeFrameValue = (
    appointmentAvailabilityPeriod: AppointmentAvailabilityPeriod,
    maxAppointmentAvailabilityWeeks: number,
): number => {
    switch (appointmentAvailabilityPeriod.units) {
        case "days":
            return maxAppointmentAvailabilityWeeks * 7;
        case "weeks":
            return maxAppointmentAvailabilityWeeks;
        default:
            return maxAppointmentAvailabilityWeeks;
    }
};

export const validateAppointmentAvailabilityForErrors = (
    appointmentAvailabilityPeriod: AppointmentAvailabilityPeriod,
    maxAppointmentAvailabilityWeeks: number,
    isSlotTypeAvailabilityError: boolean,
): string[] | undefined => {
    const isAvailabilityZero = appointmentAvailabilityPeriod.value === 0;
    const timeAvailability = getTimeFrameValue(
        appointmentAvailabilityPeriod,
        maxAppointmentAvailabilityWeeks,
    );
    const isAvailabilityExceedingMax =
        appointmentAvailabilityPeriod.value > timeAvailability;

    if (isAvailabilityZero || isAvailabilityExceedingMax) {
        return [
            `Enter a time frame within ${timeAvailability} ${appointmentAvailabilityPeriod.units}`,
        ];
    }
    if (isSlotTypeAvailabilityError) {
        return [
            `No appointments available in this time frame. Try increasing to up to ${timeAvailability} ${appointmentAvailabilityPeriod.units}`,
        ];
    }
};

const clinicianTransformation = (
    clinicians: ClinicianConfig | undefined,
): { clinicianGroupId: number; clinicianNames: string[] }[] => {
    if (!clinicians) {
        return [];
    }

    return clinicians.clinicians.map((clinician) => {
        return {
            clinicianGroupId: Number(clinician.value),
            clinicianNames: clinician.clinicians.map(
                (item) => item.displayName,
            ),
        };
    });
};

export const getSelfbookConfigObject = (
    selfBookData: SelfBookFormData,
    isLoadingAvailability: boolean,
    showCrossOrg: boolean,
    maxAppointmentAvailabilityWeeks: number,
): SelfbookConfigurationPayload => {
    const selectedClinicians = clinicianTransformation(selfBookData.clinicians);

    const validationSuccess =
        !isLoadingAvailability &&
        isFormValid(
            selfBookData.slotType,
            selfBookData.clinicians,
            selfBookData.appointmentAvailabilityPeriod,
            maxAppointmentAvailabilityWeeks,
            selfBookData.declineSettings,
        );

    return {
        validationSuccess: validationSuccess,
        appointmentType: selfBookData.appointmentType,
        slotType: selfBookData.slotType?.label,
        appointmentAvailabilityPeriod:
            selfBookData.appointmentAvailabilityPeriod,
        specificClinician:
            selfBookData.clinicians?.clinicianType === "SpecificClinicians"
                ? selectedClinicians
                : undefined,
        crossOrgBooking:
            showCrossOrg && !selfBookData.organisation.isDefault
                ? {
                      targetOdsCode: selfBookData.organisation.value,
                      targetOdsName: selfBookData.organisation.label,
                  }
                : undefined,
        timeoutDays: Math.min(
            DEFAULT_LINK_TIMEOUT_DAYS,
            convertAvailabilityPeriodToDays(
                selfBookData.appointmentAvailabilityPeriod,
            ),
        ),
        declineSettings:
            selfBookData.declineSettings.canPatientDecline === "Yes"
                ? selfBookData.declineSettings.declineSettingsData
                : undefined,
    };
};

export const isFormValid = (
    slotType: SlotAvailabilityOption | undefined,
    clinicians: ClinicianConfig | undefined,
    appointmentAvailabilityPeriod: AppointmentAvailabilityPeriod,
    maxAppointmentAvailabilityWeeks: number,
    declineSettings: DeclineSettingsConfig | undefined,
): boolean => {
    const isSlotTypeValid = !validateSlotTypeForErrors(slotType, true);
    const isClinicianValid = !validateChooseClinicianForErrors(
        clinicians,
        true,
    );
    const isAppointmentAvailabilityValid =
        !validateAppointmentAvailabilityForErrors(
            appointmentAvailabilityPeriod,
            maxAppointmentAvailabilityWeeks,
            slotType?.count === 0,
        );

    const areDeclineSettingsValid = !validateDeclineSettingsForErrors(
        declineSettings,
        true,
    );
    return (
        isSlotTypeValid &&
        isClinicianValid &&
        isAppointmentAvailabilityValid &&
        areDeclineSettingsValid
    );
};

export const createQueryParamsFromSelfbookData = (
    selfbookData: SelfBookFormData,
    sendAt: string,
): QueryParam => {
    const clinicians = selfbookData.clinicians?.clinicians.map((item) => {
        return Number.parseInt(item.value);
    });

    // creating to and from window based on availability period.
    const fromTime = DateHelpers.createDateFromDateString(sendAt).toDate();
    const availabilityPeriodEnd = appointmentAvailabilityPeriodCalculation(
        fromTime,
        selfbookData.appointmentAvailabilityPeriod,
    );

    return {
        appointmentType:
            selfbookData.appointmentType === "Telephone" ? [0] : [1],
        slotType: selfbookData.slotType?.slotName
            ? [selfbookData.slotType.slotName]
            : undefined,
        clinicianGroupId:
            selfbookData.clinicians?.clinicianType === "SpecificClinicians" &&
            clinicians &&
            clinicians.length > 0
                ? clinicians
                : undefined,
        targetOrganisationOdsCode: selfbookData.organisation.isDefault
            ? undefined
            : selfbookData.organisation.value,
        from: sendAt,
        to: format(availabilityPeriodEnd, "yyyy MM dd'T'HH:mm:ss"),
    };
};

export const validateDeclineSettingsForErrors = (
    declineSettingsConfig: DeclineSettingsConfig | undefined,
    addBookingLinkClicked: boolean,
): DeclineSettingsErrors | undefined => {
    let validationErrors: DeclineSettingsErrors = {
        confirmationTextError: undefined,
        snomedCodeError: undefined,
    };

    if (
        addBookingLinkClicked &&
        declineSettingsConfig?.canPatientDecline === "Yes" &&
        declineSettingsConfig.declineSettingsData.confirmationText === ""
    ) {
        validationErrors = {
            ...validationErrors,
            confirmationTextError:
                "Tell patients what will happen if they decline the invite. This will appear before patients confirm that they want to decline.",
        };
    }

    if (
        addBookingLinkClicked &&
        declineSettingsConfig?.canPatientDecline === "Yes" &&
        (!declineSettingsConfig.declineSettingsData.snomedConceptId ||
            declineSettingsConfig.declineSettingsData.snomedConceptId === "")
    ) {
        validationErrors = {
            ...validationErrors,
            snomedCodeError:
                "Select a SNOMED code to save to record when invite is declined.",
        };
    }

    return validationErrors.confirmationTextError ||
        validationErrors.snomedCodeError
        ? {
              confirmationTextError: validationErrors.confirmationTextError,
              snomedCodeError: validationErrors.snomedCodeError,
          }
        : undefined;
};
