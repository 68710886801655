import { Tokens } from "@accurx/design";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const StyledNavLink = styled(Link)`
    align-items: center;
    border: 1px solid ${Tokens.COLOURS.primary.green["100"]};
    border-radius: ${Tokens.BORDERS.radius};
    color: ${Tokens.COLOURS.greyscale.white};
    display: flex;
    gap: ${Tokens.SIZES[1]};
    overflow: hidden;
    padding: ${Tokens.SIZES[1]};
    transition: all 0.1s ease-in;
    white-space: nowrap;

    &[data-active="true"] {
        background-color: #026363;
        border: 1px solid #025454;
    }
    &[data-active="true"]:hover {
        border-color: #025454;
    }

    &:hover {
        background-color: #037070;
        border-color: #037070;
        text-decoration: none;
    }

    // Higher specificity to override GlobalStyle
    &:focus&:focus,
    &:focus-visible {
        outline: none;
        background-color: #037070;
        box-shadow: 0 0 0 2px ${Tokens.COLOURS.greyscale.white},
            0 0 0 4px ${Tokens.COLOURS.primary.blue[100]};
    }

    // Add extra padding to text to match icons' internal padding
    & > span:last-of-type {
        margin-right: ${Tokens.SIZES[0.5]};
    }
`;
