import { Ds } from "@accurx/design";

import { StyledLink } from "./FragmentCount.styles";

const SUPPORT_ARTICLE_URL =
    "https://support.accurx.com/en/articles/6922338-sms-costs-what-is-a-fragment";

type FragmentCountProps = {
    count: number;
};

export const FragmentCount = ({ count }: FragmentCountProps) => {
    return (
        <Ds.Text
            size="small"
            color="metal"
            aria-label="Count of fragments used"
        >
            {count}{" "}
            <StyledLink
                href={SUPPORT_ARTICLE_URL}
                target="_blank"
                rel="noopener noreferrer"
            >
                {count !== 1 ? "fragments" : "fragment"}
            </StyledLink>
        </Ds.Text>
    );
};
