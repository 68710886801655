import { Feedback, Flex, Tokens } from "@accurx/design";
import { Pill } from "@accurx/inbox-design-library";
import styled from "styled-components";

export const StyledSelfbookForm = styled.div`
    background-color: ${Tokens.COLOURS.greyscale.white};
    padding: 0 1rem 1rem 1rem;
    height: 100%;
    overflow-y: auto;
    scrollbar-gutter: stable;
`;

export const StyledSelfbookModal = styled.div`
    background-color: ${Tokens.COLOURS.greyscale.white};
    height: 100vh;
    overflow-y: hidden;
`;

export const StyledFeedback = styled(Feedback)`
    padding: 1rem 0.5rem;
`;

export const StyledSearchSelect = styled.div`
    // this is because Option doesn't support override styles
    * {
        font-size: 14px;
    }
`;

export const StyledSingleSelect = styled.div`
    // this is because Option doesn't support override styles
    * {
        font-size: 14px;
    }
`;

export const SpinnerPositioning = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
`;

export const StyledFooter = styled.div<{
    marginBottom?: number | string;
}>`
    bottom: 0;
    width: 100%;
    background-color: ${Tokens.COLOURS.greyscale.white};
    border-top: 1px solid ${Tokens.COLOURS.greyscale.silver};
    padding: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
    // added margin because a bottom margin is added in wpf
    // to avoid issue where webview corners are not rounded
    margin-bottom: ${(props) => props.marginBottom ?? 0};
`;

// This is a temporary loading button will be accomodated in button under design systems
export const StyledDisabledLoadingButton = styled(Pill.PrimaryButton)<{
    width: number | undefined;
}>`
    width: ${({ width }) => (width ? `${width}px` : "auto")};
    justify-content: center;
    min-width: 108px;
    height: 32px;
`;

export const StyledInnerWrapper = styled(Flex).attrs({
    flexDirection: "column",
})`
    height: 100%;
`;
export const StyledMiddleWrapper = styled.div`
    position: relative;
    height: 100%;
`;

export const StyledOuterWrapper = styled.div`
    overflow-y: hidden;
    // dvh is same as vh with dynamic changes with respect to change in ui such as components behind feauture flags
    height: 100dvh;
`;

export const TelephoneDetailsWrapper = styled.div`
    margin: 1.5rem 1rem;
`;

export const StyledClinicianSelect = styled.div`
    margin-left: 1.5rem;
    // reduce the font size of list items in search select component
    margin-bottom: 1.5rem;

    // this is because Option doesn't support override styles
    * {
        font-size: 14px;
    }
`;

export const ErrorPositioningWrapper = styled.span`
    margin-top: 0.25rem;
`;

export const SubTitlePositioning = styled.div`
    margin-left: o.25rem;
    margin-top: 0.125;
`;

export const StyledBoxSeparator = styled.div`
    border-bottom: 1px solid #e0e1eb;
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
`;
