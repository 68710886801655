import { Flex, Tokens } from "@accurx/design";
import styled from "styled-components";

export const UploadButtonWrapper = styled.div`
    @media screen and (max-width: ${Tokens.BREAKPOINTS.md}) {
        width: max-content;
        align-self: end;
        margin-top: ${Tokens.SIZES[2]};
    }
`;

export const PageContainer = styled(Flex).attrs(() => ({
    flexDirection: "column",
    gap: { xs: "2", md: "3" },
}))`
    padding: ${Tokens.SIZES[3]} ${Tokens.SIZES[2]};

    @media (min-width: ${Tokens.BREAKPOINTS.md}) {
        padding: ${Tokens.SIZES[3]} ${Tokens.SIZES[3]};
    }
`;
