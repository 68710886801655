import { ReactNode, useRef } from "react";

import { Ds } from "@accurx/design";

import { FileInputButton } from "../components/FileUploadInput";

const CardFooterWrapper = ({ children }: { children: ReactNode }) => (
    <Ds.Flex justifyContent={"flex-end"}>{children}</Ds.Flex>
);
export const ALLOWED_FILE_EXTENSIONS = ["csv"];

interface UploadFileProps {
    onFileSelect: (file: File) => void;
    onError: (failureReason?: string) => void;
    isUploading: boolean;
}

interface ValidationReason {
    isValid: boolean;
    failureReasons?: string | null;
}

const validateCsvFile = (file: File): ValidationReason => {
    if (file.size === 0) {
        return {
            isValid: false,
            failureReasons: "The selected file cannot be empty",
        };
    }
    return { isValid: true };
};

export const UploadFile = ({
    onFileSelect,
    onError,
    isUploading = false,
}: UploadFileProps) => {
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleFileSelect = (files: FileList | null) => {
        const filesArray = files ? Array.from(files) : [];

        if (filesArray.length === 1) {
            const file = filesArray[0];

            const isValid = validateCsvFile(file);
            if (isValid.isValid) {
                onFileSelect(file);
            } else {
                onError(isValid.failureReasons ?? "");
            }
        }
    };

    const handleUploadClick = () => {
        // Resetting input value for the case
        // when user select the same file after fixing
        // validation issues, because by default
        // onChange will not be called if the same file selected
        if (fileInputRef.current) {
            fileInputRef.current.value = "";
        }
    };

    return (
        <CardFooterWrapper>
            <FileInputButton
                ref={fileInputRef}
                id="file-for-upload"
                allowedFileExtensions={ALLOWED_FILE_EXTENSIONS}
                onFileChange={handleFileSelect}
                onClick={handleUploadClick}
                disabled={isUploading} // Disable button while uploading
                labelButton={{
                    text: isUploading ? "Uploading..." : "Upload new list",
                }}
                aria-describedby="file-requirements-message"
            />
        </CardFooterWrapper>
    );
};
