import { returnDataOrThrow } from "@accurx/api/QueryClient";
import { TemplateResponse } from "@accurx/api/content";
import {
    IWrappedResult,
    Log,
    getApiEndpoint,
    httpClient,
} from "@accurx/shared";
import { useQuery } from "@tanstack/react-query";

const getMessageTemplate = ({
    workspaceId,
    templateId,
}: {
    workspaceId: number;
    templateId: number;
}): Promise<IWrappedResult<TemplateResponse>> => {
    return httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: "/api/content/workspace/:workspaceId/templates/:templateId",
            params: {
                workspaceId: workspaceId.toString(),
                templateId: templateId.toString(),
            },
        }),
    );
};

export const useMessageTemplate = ({
    workspaceId,
    templateId,
    enabled,
}: {
    workspaceId: number;
    templateId: number;
    enabled: boolean;
}) =>
    useQuery({
        queryKey: ["GetMessageTemplate", { workspaceId, templateId }],
        queryFn: async () => {
            if (!workspaceId) {
                Log.error("Could not fetch template: missing workspace id");
                throw new Error(
                    "Could not edit template: missing workspace id",
                );
            }

            if (!templateId) {
                Log.error("Could not fetch template: missing template id");
                throw new Error("Could not edit template: missing template id");
            }

            return getMessageTemplate({ workspaceId, templateId }).then(
                returnDataOrThrow,
            );
        },
        refetchOnMount: true,
        enabled,
    });
