import { useState } from "react";

import { AppointmentReminder } from "@accurx/api/appointment";
import { FeatureName, useFeatureFlag } from "@accurx/auth";
import {
    Card,
    Ds,
    Feedback,
    Flex,
    Popover,
    PopoverContent,
    PopoverItemButton,
    PopoverTrigger,
    Text,
} from "@accurx/design";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";

import { getCreatePagePath } from "../../Routes";
import { useMessageParts } from "../../hooks";
import { useDeleteReminder } from "../../hooks/useDeleteReminder";
import { TemplateType } from "../../types";
import { DeleteReminderModal } from "../DeleteReminderModal";

const StyledPopoverButtonText = styled(Text).attrs({
    skinny: true,
    variant: "label",
})`
    text-align: left;
`;

export interface PreviewCardProps {
    reminder: AppointmentReminder;
    badgeText?: string;
    onEdit: () => void;
    workspaceId: number;
    workspaceHasMultipleSites: boolean;
    workspaceName?: string;
    isAdminUser: boolean;
}

export const PreviewCard = ({
    reminder,
    badgeText,
    onEdit,
    workspaceId,
    workspaceName,
    workspaceHasMultipleSites,
    isAdminUser,
}: PreviewCardProps) => {
    const history = useHistory();
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    const { mutateAsync, status } = useDeleteReminder({
        workspaceId: workspaceId.toString(),
        reminderId: reminder.id,
    });
    const headerText =
        reminder.slotTypes[0] +
        (reminder.slotTypes.length > 1
            ? ` (+ ${reminder.slotTypes.length - 1} more)`
            : "");

    const useClinicCodesEnabled = useFeatureFlag(
        FeatureName.AppointmentRemindersUseClinicCodes,
    );

    const { fullMessage } = useMessageParts({
        templateType: reminder.type as TemplateType,
        customMessage: reminder.customMessageBody || "",
        postAppointmentMessageBody: reminder.postAppointmentCustomMessage || "",
        workspaceHasMultipleSites,
        workspaceName: workspaceName || "",
    });

    const navigateToCreateFormWIthClinicCodes = () => {
        history.push(getCreatePagePath(workspaceId), {
            selectedClinics: reminder.slotTypes,
        });
    };

    return (
        <>
            <Card
                header={
                    <Flex
                        justifyContent="space-between"
                        alignItems="flex-start"
                    >
                        <Flex flexDirection="column" alignItems="flex-start">
                            <Ds.Text weight="bold">{headerText}</Ds.Text>
                            {badgeText && (
                                <Ds.Badge color="greyscale">
                                    {badgeText}
                                </Ds.Badge>
                            )}
                        </Flex>

                        {useClinicCodesEnabled && isAdminUser && (
                            <Popover>
                                <PopoverTrigger>
                                    <Ds.Button
                                        aria-label="Reminder actions"
                                        appearance="secondary"
                                    >
                                        <Ds.Button.Icon
                                            name="More"
                                            appearance="solid"
                                        />
                                    </Ds.Button>
                                </PopoverTrigger>
                                <PopoverContent>
                                    <PopoverItemButton
                                        onClick={
                                            navigateToCreateFormWIthClinicCodes
                                        }
                                    >
                                        <Flex gap="1">
                                            <Ds.Icon
                                                name="PenToSquare"
                                                size="xlarge"
                                            />
                                            <StyledPopoverButtonText>
                                                Use clinic codes for a new
                                                reminder
                                            </StyledPopoverButtonText>
                                        </Flex>
                                    </PopoverItemButton>
                                </PopoverContent>
                            </Popover>
                        )}
                    </Flex>
                }
            >
                <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    gap="2"
                >
                    <Ds.Text>{fullMessage}</Ds.Text>

                    {isAdminUser && (
                        <Flex
                            gap="2"
                            flexWrap={{ xs: "wrap", md: "nowrap" }}
                            justifyContent="flex-end"
                        >
                            <Ds.Button
                                appearance="secondary"
                                size="medium"
                                onClick={onEdit}
                            >
                                Edit
                            </Ds.Button>
                            <Ds.Button
                                appearance="secondary"
                                size="medium"
                                onClick={() => {
                                    setIsDeleteModalOpen(true);
                                }}
                            >
                                Delete
                            </Ds.Button>
                            <DeleteReminderModal
                                isOpen={isDeleteModalOpen}
                                setOpen={setIsDeleteModalOpen}
                                onCancel={() => setIsDeleteModalOpen(false)}
                                areButtonsDisabled={status === "loading"}
                                onDelete={() => {
                                    mutateAsync()
                                        .then(() => {
                                            setIsDeleteModalOpen(false);
                                            toast(
                                                <Feedback colour="success">
                                                    Reminder deleted!
                                                </Feedback>,
                                            );
                                        })
                                        .catch(() => {
                                            setIsDeleteModalOpen(false);
                                            toast(
                                                <Feedback colour="error">
                                                    Could not delete reminder,
                                                    refresh the page and try
                                                    again
                                                </Feedback>,
                                            );
                                        });
                                }}
                            />
                        </Flex>
                    )}
                </Flex>
            </Card>
        </>
    );
};
