import { ComposePatient } from "@accurx/message-component";
import { OpenInEmrButton } from "@accurx/native";
import { AutoFocusHeading } from "@accurx/navigation";
import {
    PatientHeader,
    calculateAge,
    formatPatientName,
} from "@accurx/patient";

interface PatientHeaderProps {
    patient: ComposePatient;
}

export const ComposeHeader = ({ patient }: PatientHeaderProps) => {
    const patientName = formatPatientName({
        firstName: patient.firstName,
        familyName: patient.familyName,
    });

    const nhsNumber = patient.externalIds.find(
        (id) => id.type === "NhsNumber",
    )?.value;

    const age = patient.dateOfBirth
        ? calculateAge(patient.dateOfBirth)
        : undefined;

    return (
        <PatientHeader>
            <PatientHeader.Details>
                <AutoFocusHeading>
                    <PatientHeader.Details.Name
                        name={patientName}
                        ageYear={age}
                        gender={patient.gender}
                    />
                </AutoFocusHeading>
                <PatientHeader.Details.Additional
                    nhsNumber={nhsNumber}
                    dateOfBirth={patient.dateOfBirth ?? undefined}
                />
            </PatientHeader.Details>
            <PatientHeader.Actions>
                <OpenInEmrButton patientExternalIds={patient.externalIds} />
            </PatientHeader.Actions>
        </PatientHeader>
    );
};
