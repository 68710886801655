import { IdType, PatientExternalIdentityDto } from "@accurx/api/ticket";
import { patientNhsNumber } from "@accurx/concierge/util/patientNhsNumber";
import { PatientDemographics } from "@accurx/native";
import { mapPatientsNames } from "@accurx/patient";
import { Log } from "@accurx/shared";

export const tryGetPostcodeFromAddress = (address: string) => {
    // Regex found on https://stackoverflow.com/questions/164979/regex-for-matching-uk-postcodes/51885364#51885364
    const postcodeRegexPattern =
        /^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$/;

    const addressParts = address.split(",");
    const possiblePostcode = addressParts[addressParts.length - 1].trim();

    const isPostcode = postcodeRegexPattern.test(possiblePostcode);

    if (possiblePostcode && !isPostcode) {
        Log.info("Opening Patient Triage without a postcode");
    }

    return isPostcode ? possiblePostcode : null;
};

export const tryGetPhoneNumber = (
    mobilePhone: Pick<
        PatientDemographics["contact"],
        "homeTelephone" | "mobileTelephone" | "workTelephone"
    >,
) => {
    const phoneNumber = [
        mobilePhone.mobileTelephone,
        mobilePhone.workTelephone,
        mobilePhone.homeTelephone,
    ].find((number): number is string => !!number);

    return phoneNumber ?? null;
};

export const mapDemographicsToPatientDataForRequestPayload = (
    patient: PatientDemographics,
) => {
    const patientExternalIdentityDto: PatientExternalIdentityDto = {
        patientExternalIds:
            patient.patientExternalIdentityDto.patientExternalIds.map(
                (externalId) => ({
                    value: externalId.value,
                    type: IdType[externalId.type],
                }),
            ),
    };

    const nhsNumber =
        patientNhsNumber(
            patient.patientExternalIdentityDto.patientExternalIds,
        ) ?? null;

    const postCode = patient.contact.address
        ? tryGetPostcodeFromAddress(patient.contact.address)
        : null;

    const mobilePhone = tryGetPhoneNumber(patient.contact);

    const names = mapPatientsNames(patient.patientName.usual);

    // Desktop currently logs if there is no phone number passed,
    // so we do the same
    if (!mobilePhone) {
        Log.info("Opening Patient Triage without a contact number");
    }

    return {
        dateOfBirth: patient.dateOfBirth,
        surname: names.familyName || null,
        name: names.firstName || null,
        phoneNumber: mobilePhone,
        gender: patient.gender,
        nhsNumber,
        postCode,
        externalIdentity: patientExternalIdentityDto,
    };
};
