import { PatientExternalIdentityDto } from "domains/native/schemas/PatientExternalIdentityDtoSchema";

import { NativeApi } from "../NativeApi";
import { createEndpoint } from "../api.utils";

export type SyncPatientDemographicsPayload = {
    patientExternalIdentityDto: PatientExternalIdentityDto;
};

export const mutateSyncPatientDemographics = createEndpoint<
    void,
    SyncPatientDemographicsPayload
>("MutateSyncPatientDemographics", async (context, payload) => {
    await NativeApi.request({
        transport: context.transport,
        request: {
            type: "MutateSyncPatientDemographics",
            workspaceId: context.workspaceId,
            payload: payload,
        },
    });
});
