import { ReactNode } from "react";

import { useAuth } from "@accurx/auth";
import { RealtimeProvider } from "@accurx/realtime";
import { useSelectedOrganisation } from "reduxQuarantine/SelectedOrganisationProvider";

import { useWorkspaceValidator } from "shared/useWorkspaceValidator";

export const OptionalRealtimeProvider = ({
    children,
}: {
    children: ReactNode;
}): JSX.Element => {
    const { user } = useAuth();
    const { selectedOrgId: workspaceId } = useSelectedOrganisation();
    const isValidWorkspace = useWorkspaceValidator();

    if (!user.isLoggedIn || !workspaceId || !isValidWorkspace) {
        return <>{children}</>;
    }

    return (
        <RealtimeProvider workspaceId={workspaceId}>
            {children}
        </RealtimeProvider>
    );
};
