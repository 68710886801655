import { ReactNode } from "react";

import styled from "styled-components";

export const StyledFooterActionsWrapper = styled.div<{
    additionalActions?: ReactNode;
}>`
    display: flex;
    justify-content: ${(props) =>
        props.additionalActions ? "space-between" : "flex-end"};
`;

export const StyledFooterGroupedActions = styled.div`
    display: inherit;
`;
