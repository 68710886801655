import React from "react";

import { useAccurxWebTitle } from "@accurx/navigation";

import { AccountPageLayout } from "./AccountPageLayout";
import { SettingsCookies } from "./components/SettingsCookies";

export const CookiesAccount = () => {
    useAccurxWebTitle("Update cookie consent");
    return (
        <AccountPageLayout title={"Cookies"}>
            <SettingsCookies hideHeader />
        </AccountPageLayout>
    );
};
