import { Ds } from "@accurx/design";
import styled from "styled-components";

import Linkify from "app/sharedComponents/linkify/Linkify";

const PLACEHOLDER_OPTIONS = [
    { id: "%PATIENT_PHONE_NUMBER%", text: "Patient Phone Number" },
    { id: "%PATIENT_ADDRESS%", text: "Patient Address" },
    { id: "%GP_ADDRESS%", text: "GP Address" },
    { id: "%GP_PHONE_NUMBER%", text: "GP Phone Number" },
] as const;

const StyledText = styled(Ds.Text)`
    white-space: pre-wrap;
`;

export const TemplateBody = ({
    bodyText,
}: {
    bodyText: string;
}): JSX.Element => {
    if (!bodyText.includes("%")) return <Linkify>{bodyText}</Linkify>;

    const matchedOptions = bodyText.split(/(%[^%]+%)/);

    const processedDetails = matchedOptions.map((option, index) => {
        const placeholderOptions = PLACEHOLDER_OPTIONS.find(
            (options) => options.id === option,
        );

        return placeholderOptions ? (
            <Ds.Badge key={`${option}-${index}`} color="blue" size="small">
                {placeholderOptions.text}
            </Ds.Badge>
        ) : (
            option
        );
    });

    return (
        <StyledText>
            <Linkify>{processedDetails}</Linkify>
        </StyledText>
    );
};
