import {
    AnalyticsProperties,
    EventAction,
    EventObjectType,
    EventUserType,
    GenericTrackEvent,
} from "@accurx/shared";

import {
    FlemingLoggedInCustomProperties,
    HasErrorProps,
    SharedLoggedInRudderStackAnalyticProps,
} from "./FlemingAnalyticsConstants";
import { TrackFlemingEvent } from "./FlemingEventTracker";

type IsRegisterFlowProps = {
    isRegisterFlow: boolean;
};

type CommonTwoFactorAnalyticsProps = SharedLoggedInRudderStackAnalyticProps &
    HasErrorProps &
    IsRegisterFlowProps;

export type TwoFactorAnalyticsFlowProps = FlemingLoggedInCustomProperties &
    IsRegisterFlowProps & {
        isRemembered: boolean;
        method: string;
    };

/**
 * Prepends `2FA - ` in front of all of the exported analytics functions
 * */
const TrackTwoFactorEvent = (
    eventName: string,
    props: AnalyticsProperties,
): void => {
    TrackFlemingEvent(`2FA - ${eventName}`, props);
};

// To have consistent naming within analytics
const AccountSecurity = "AccountSecurity";
const Start = "Start";
const Skip = "Skip";
const SkipConfirm = `${Skip}Confirm`;
const MessageSend = "MessageSend";
const EmailSend = "EmailSend";
const CodeConfirm = "CodeConfirm";

/**
 * User clicks on Skip
 * Users can choose to skip 2FA if they are not using any features which
 * require it. We will eventually make 2FA mandatory, so this event will
 * not live forever.
 *
 * Event type: Button click
 * */
export const trackSkipTwoFactor = (
    props: FlemingLoggedInCustomProperties,
): void => {
    TrackTwoFactorEvent("Clicked on Skip Button", props);

    const fullProps: SharedLoggedInRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: `${AccountSecurity}${Skip}`,
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: fullProps,
    });
};

/**
 * User clicks on Confirm Skip on the modal when clicking skip 2FA
 * Users can choose to skip 2FA if they are not using any features which
 * require it. We will eventually make 2FA mandatory, so this event will
 * not live forever.
 *
 * Event type: Button click
 * */
export const trackConfirmSkipTwoFactor = (
    props: FlemingLoggedInCustomProperties,
): void => {
    TrackTwoFactorEvent("Clicked on Confirm Skip Button", props);

    const fullProps: SharedLoggedInRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: `${AccountSecurity}${SkipConfirm}`,
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: fullProps,
    });
};

/**
 * User clicks to start the 2FA Flow.
 * This can be the first time they set up 2FA for their account
 * and every time they need to access Patient lists
 *
 * Event type: Button click
 * */
export const trackTwoFactorStarted = (
    props: FlemingLoggedInCustomProperties,
): void => {
    TrackTwoFactorEvent("Start 2FA Flow Clicked", props);

    const fullProps: SharedLoggedInRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: `${AccountSecurity}${Start}`,
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: fullProps,
    });
};

/**
 * User successfully entered their mobile number in the 2FA set up flow.
 * Only occurs when users set up 2FA
 *
 * Event type: API response
 * */
export const trackTwoFactorRegisterMobileSuccess = (
    props: FlemingLoggedInCustomProperties,
): void => {
    TrackTwoFactorEvent("Register Mobile Succeeded", props);

    const fullProps: CommonTwoFactorAnalyticsProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
        isRegisterFlow: true,
        hasError: false,
    };
    GenericTrackEvent({
        object: `${AccountSecurity}${MessageSend}`,
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: fullProps,
    });
};

/**
 * User entered a mobile number to set up 2FA, but request failed.
 * Only occurs when users set up 2FA
 *
 * Event type: API response
 * */
export const trackTwoFactorRegisterMobileFailure = (
    props: FlemingLoggedInCustomProperties,
): void => {
    TrackTwoFactorEvent("Register Mobile Failed", props);

    const fullProps: CommonTwoFactorAnalyticsProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
        isRegisterFlow: true,
        hasError: true,
    };
    GenericTrackEvent({
        object: `${AccountSecurity}${MessageSend}`,
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: fullProps,
    });
};

/**
 * User clicked to send the sms with the code
 * to the mobile number they entered to set up 2FA.
 * This happens if 2Fa is already set up,
 * if they need to access Patient lists.
 *
 * Event type: API response
 * */
export const trackTwoFactorSendSmsSuccess = (
    props: FlemingLoggedInCustomProperties,
): void => {
    TrackTwoFactorEvent("Send SMS Succeeded", props);

    const fullProps: CommonTwoFactorAnalyticsProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
        isRegisterFlow: false,
        hasError: false,
    };
    GenericTrackEvent({
        object: `${AccountSecurity}${MessageSend}`,
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: fullProps,
    });
};

/**
 * User clicked to send the sms with the code
 * to the mobile number they entered to set up 2FA,
 * but request failed.
 * This happens if 2Fa is already set up,
 * if they need to access Patient lists.
 *
 * Event type: API response
 * */
export const trackTwoFactorSendSmsFailure = (
    props: FlemingLoggedInCustomProperties,
): void => {
    TrackTwoFactorEvent("Send SMS Failed", props);

    const fullProps: CommonTwoFactorAnalyticsProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
        isRegisterFlow: false,
        hasError: true,
    };
    GenericTrackEvent({
        object: `${AccountSecurity}${MessageSend}`,
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: fullProps,
    });
};

/**
 * User clicked to send the email with the code
 * to the email address they entered to set up 2FA.
 * This happens if 2Fa is already set up,
 * if they need to access Patient lists.
 *
 * Event type: API response
 * */
export const trackTwoFactorSendEmailSuccess = (
    props: FlemingLoggedInCustomProperties,
): void => {
    TrackTwoFactorEvent("Send Email Succeeded", props);

    const fullProps: CommonTwoFactorAnalyticsProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
        isRegisterFlow: false,
        hasError: false,
    };
    GenericTrackEvent({
        object: `${AccountSecurity}${EmailSend}`,
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: fullProps,
    });
};

/**
 * User clicked to send the email with the code
 * to the email address they entered to set up 2FA,
 * but request failed.
 * This happens if 2Fa is already set up,
 * if they need to access Patient lists.
 *
 * Event type: API response
 * */
export const trackTwoFactorSendEmailFailure = (
    props: FlemingLoggedInCustomProperties,
): void => {
    TrackTwoFactorEvent("Send Email Failed", props);

    const fullProps: CommonTwoFactorAnalyticsProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
        isRegisterFlow: false,
        hasError: true,
    };
    GenericTrackEvent({
        object: `${AccountSecurity}${EmailSend}`,
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: fullProps,
    });
};

/**
 * User successfully enters the code to access Patient List.
 * This can be the first time they set up 2FA for their account
 * and every time they need to access Patient lists.
 *
 * Event type: API response
 * */
export const trackTwoFactorVerifySuccess = (
    props: TwoFactorAnalyticsFlowProps,
): void => {
    TrackTwoFactorEvent("Verify Code Succeeded", props);

    const fullProps: TwoFactorAnalyticsFlowProps &
        CommonTwoFactorAnalyticsProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
        hasError: false,
    };
    GenericTrackEvent({
        object: `${AccountSecurity}${CodeConfirm}`,
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: fullProps,
    });
};

/**
 * User tried to enter the code to access Patient List, but request failed.
 * This can be the first time they set up 2FA for their account
 * and every time they need to access Patient lists
 *
 * Event type: API response
 * */
export const trackTwoFactorVerifyFailure = (
    props: TwoFactorAnalyticsFlowProps,
): void => {
    TrackTwoFactorEvent("Verify Code Failed", props);

    const fullProps: SharedLoggedInRudderStackAnalyticProps &
        TwoFactorAnalyticsFlowProps &
        HasErrorProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
        hasError: true,
    };
    GenericTrackEvent({
        object: `${AccountSecurity}${CodeConfirm}`,
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: fullProps,
    });
};
