import { useState } from "react";

import { useAnalytics } from "@accurx/analytics";
import { Conversation } from "@accurx/concierge/types";
import { Ds } from "@accurx/design";
import {
    PatientDemographics,
    useMedicalRecordConnection,
} from "@accurx/native";
import { ErrorImage } from "domains/inbox/components/ErrorImage/ErrorImage";
import {
    FormEMRPatientDetails,
    formEMRPatientDetailsId,
} from "domains/inbox/components/PatientSearch/components/forms/FormEMRPatientDetails";
import { FormEmis } from "domains/inbox/components/PatientSearch/components/forms/FormEmis";
import { PatientMatchLayout } from "domains/inbox/components/PatientSearch/components/forms/PatientMatchLayout";
import { SkeletonLoader } from "domains/inbox/components/SkeletonLoader/SkeletonLoader";
import { useIsAppSearchingForPatient } from "domains/inbox/hooks/mutations/useSearchForPatientInEmrMutation";
import { useConversationAnalyticsFields } from "domains/inbox/hooks/util/useConversationAnalyticsFields";

import { StyledPatientMatchSpacer } from "./PatientMatch.styles";
import {
    StyledConnectionErrorWrapper,
    StyledPatientMatchSpacerWithBorder,
} from "./PatientMatchEmr.styles";
import { SuggestedPatientMatches } from "./SuggestedPatientMatches";

const formEmisId = "search-for-patient-match-via-emis-form";

/**
 * Flow to match patients via emr
 */
export const PatientMatchEmr = ({
    conversation,
    onMatchConfirmSuccess,
    onResultsFound,
}: {
    conversation: Conversation;
    onMatchConfirmSuccess?: () => void;
    onResultsFound: (data: PatientDemographics[]) => void;
}) => {
    const medicalRecordConnection = useMedicalRecordConnection();

    const track = useAnalytics();

    const trackingFields = useConversationAnalyticsFields({
        conversation,
        appOrigin: "ConversationActions",
    });

    const [isLoading, setIsLoading] = useState(false);

    /**
     * Searching for patient matches via the form and getting suggested patients use the same EMR endpoint.
     * However, we can only call one at a time. So we don't want users to search while the suggestions are loading to avoid API errors.
     */
    const isAppSearchingForPatient = useIsAppSearchingForPatient();
    const shouldHideFormAndShowLoadingState =
        !isLoading && isAppSearchingForPatient;

    if (medicalRecordConnection.status === "Disconnected") {
        return (
            <>
                <StyledPatientMatchSpacerWithBorder>
                    <Ds.Text as="h2" weight="bold" color="night">
                        Match patient to conversation
                    </Ds.Text>
                </StyledPatientMatchSpacerWithBorder>
                <StyledConnectionErrorWrapper>
                    <ErrorImage alt="Medical record disconnected error" />
                    <Ds.Text color="metal">
                        Sorry, it seems like you're disconnected from the
                        medical records system. Try again after connecting to
                        the system.
                    </Ds.Text>
                </StyledConnectionErrorWrapper>
            </>
        );
    }

    if (medicalRecordConnection.system === "Emis") {
        return (
            <PatientMatchLayout
                buttonProps={{
                    form: formEmisId,
                    loading: isLoading,
                }}
                showFooter={false}
            >
                <StyledPatientMatchSpacer>
                    {shouldHideFormAndShowLoadingState ? (
                        <FormLoadingState />
                    ) : (
                        <FormEmis
                            id={formEmisId}
                            onResultsFound={onResultsFound}
                            onSearchClick={({ hasError }) =>
                                track(
                                    "ConversationPatientMatchSearch Button Click",
                                    {
                                        ...trackingFields,
                                        hasError,
                                    },
                                )
                            }
                            onSearchSettled={({ hasError, countResult }) =>
                                track(
                                    "ConversationPatientMatchSearch Button Response",
                                    {
                                        ...trackingFields,
                                        hasError,
                                        countResult,
                                    },
                                )
                            }
                            setIsLoading={setIsLoading}
                            submitButton={null} // We render the submit button outside the form
                        />
                    )}
                    <Ds.Box mt="3">
                        <SuggestedPatientMatches
                            conversation={conversation}
                            searchMode="dateOfBirth"
                            onMatchConfirmSuccess={onMatchConfirmSuccess}
                        />
                    </Ds.Box>
                </StyledPatientMatchSpacer>
            </PatientMatchLayout>
        );
    }

    if (
        medicalRecordConnection.system === "SystmOne" ||
        medicalRecordConnection.system === "Vision"
    ) {
        return (
            <PatientMatchLayout
                buttonProps={{
                    form: formEMRPatientDetailsId,
                    loading: isLoading,
                }}
                showFooter={false}
            >
                <StyledPatientMatchSpacer>
                    {shouldHideFormAndShowLoadingState ? (
                        <FormLoadingState />
                    ) : (
                        <>
                            <Ds.Text size="small" mb="3">
                                Search for patients by their name or NHS number.
                            </Ds.Text>

                            <FormEMRPatientDetails
                                onResultsFound={onResultsFound}
                                onSearchClick={({ hasError }) =>
                                    track(
                                        "ConversationPatientMatchSearch Button Click",
                                        {
                                            ...trackingFields,
                                            hasError,
                                        },
                                    )
                                }
                                onSearchSettled={({ hasError, countResult }) =>
                                    track(
                                        "ConversationPatientMatchSearch Button Response",
                                        {
                                            ...trackingFields,
                                            hasError,
                                            countResult,
                                        },
                                    )
                                }
                                setIsLoading={setIsLoading}
                                submitButton={null} // We render the submit button outside the form
                            />
                        </>
                    )}
                    <Ds.Box mt="3">
                        <SuggestedPatientMatches
                            conversation={conversation}
                            searchMode="patientName"
                            onMatchConfirmSuccess={onMatchConfirmSuccess}
                        />
                    </Ds.Box>
                </StyledPatientMatchSpacer>
            </PatientMatchLayout>
        );
    }

    return (
        <>
            <StyledPatientMatchSpacerWithBorder>
                <Ds.Text as="h2" weight="bold" color="night">
                    Match patient to conversation
                </Ds.Text>
            </StyledPatientMatchSpacerWithBorder>
            <StyledConnectionErrorWrapper>
                <ErrorImage alt="Unsupported medical record system error" />
                <Ds.Text color="metal">
                    Sorry, it seems like we don't support patient search on{" "}
                    {medicalRecordConnection.system ??
                        "the medical records system"}
                    . Please contact support
                </Ds.Text>
            </StyledConnectionErrorWrapper>
        </>
    );
};

const FormLoadingState = () => (
    <Ds.Flex flexDirection="column" gap="0.25">
        <Ds.Box py="6px">
            <SkeletonLoader height="12px" width="58%" />
        </Ds.Box>
        <Ds.Box py="6px">
            <SkeletonLoader height="12px" width="91%" />
        </Ds.Box>
    </Ds.Flex>
);
