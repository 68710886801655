import { SearchForPatientByNhsNumberRequest } from "@accurx/api/portal";
import { useCurrentWorkspace } from "@accurx/auth";
import {
    IWrappedResult,
    Log,
    getApiEndpoint,
    httpClient,
} from "@accurx/shared";
import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import { isTestPatient } from "domains/patient/components/PatientSearch/PatientSearchForm/PatientSearchForm.helpers";
import {
    SearchForPatientSuccess,
    SearchForPatientSuccessSchema,
} from "domains/patient/schemas/SearchForPatientSchema";
import { PatientSearchResult } from "domains/patient/types";
import { mapPatientSearchResponse } from "domains/patient/utils/format/mappers";

import { useAddPatientToRecentSearches } from "./useAddPatientToRecentSearches";

export const searchForPatientByNhsNumberError = {
    generic:
        "Search for patient via /api/portal/SearchForPatient/ByNhsNumber failed",
};

export const SearchForPatientByNhsNumberKey = "SearchForPatientByNhsNumber";

type MutationVariables = {
    nhsNumber: string;
    isUserAction?: boolean;
};

const searchForPatient = async (
    payload: SearchForPatientByNhsNumberRequest,
): Promise<IWrappedResult<SearchForPatientSuccess>> =>
    httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: "/api/portal/SearchForPatient/ByNhsNumber",
        }),
        payload,
    );

export const useBasicTwoFactoredPatientSearch = (
    options: UseMutationOptions<
        PatientSearchResult[],
        Error,
        MutationVariables
    >,
) => {
    const { mutate: saveToRecentSearches } = useAddPatientToRecentSearches();
    const workspaceId = useCurrentWorkspace().orgId;

    return useMutation(
        [SearchForPatientByNhsNumberKey],
        async (variables: MutationVariables) => {
            const payload = {
                nhsNumber: variables.nhsNumber,
                organisationId: workspaceId,
                isTestPatient: isTestPatient(variables.nhsNumber),
                isUserAction: variables.isUserAction,
            };

            const response = await searchForPatient(payload);
            const result = SearchForPatientSuccessSchema.safeParse(
                response.result,
            );

            if (!result.success) {
                throw new Error(searchForPatientByNhsNumberError.generic);
            }

            if (!result.data.searchedResult.patient) {
                Log.warn("Patient search by NHS number failed to find a match");
            }

            return mapPatientSearchResponse(result.data);
        },
        {
            ...options,
            onSuccess: (data, variables, context) => {
                const patient = data.length ? data[0] : null;
                if (patient?.nhsNumber && !isTestPatient(patient.nhsNumber)) {
                    void saveToRecentSearches({
                        organisationId: workspaceId,
                        patientToken: patient.patientToken,
                    });
                }
                options.onSuccess?.(data, variables, context);
            },
        },
    );
};
