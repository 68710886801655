import React, { ChangeEvent, useEffect, useState } from "react";

import { FeatureName } from "@accurx/auth";
import { Accordion, Card, Feedback, Icon, Text } from "@accurx/design";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { generatePath, useHistory } from "react-router-dom";
import { useSelectedOrganisation } from "reduxQuarantine/SelectedOrganisationProvider";

import ChainApiClient from "api/VaccineApiHelper";
import { ChainAnalyticsTracker } from "app/analytics";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { StepsFooter } from "app/sharedComponents/footer/StepsFooter";
import { UpdatingStatus } from "shared/LoadingStatus";
import { ROUTES_WORKSPACE } from "shared/Routes";
import { useIsFeatureEnabled } from "store/hooks";

import {
    PdsMatchWarningLevel,
    boosterCopy,
    defaultInviteMessage,
} from "../VaccineCopy";
import { VaccineCapacityTypeStats } from "../models/VaccineCapacityDashboardDTO";
import {
    VaccineCourse,
    VaccineDeliveryItem,
    VaccineDeliveryStatus,
    VaccineExistingPatientState,
} from "../models/VaccineDeliveryDTO";
import { NimsVaccineProductType } from "../models/VaccineSharedDTO";
import { VaccineDeliveryHeader } from "../shared/VaccineDeliveryComponents";
import {
    getUploadStatus,
    getVaccineDeliveryDetails,
    sendMessage,
    updateCourseType,
    uploadList,
} from "../vaccineInvitesOldPage/vaccineDelivery.actions";
import { initialState as VaccineAllInvitesInitialState } from "../vaccineInvitesOldPage/vaccineDelivery.reducer";
import { VaccineDeliveryCapacityPanel } from "./VaccineDeliveryCapacityPanel";
import { VaccineDeliveryComposePatientTable } from "./VaccineDeliveryComposePatientTable";
import {
    UploadAllowedFileExtensions,
    UploadMaxAllowedFileSize,
    UploadMaxAllowedFileSizeHumanReadable,
    VaccineDeliveryAccordionDetails,
    getAllPatientWhoNeedBooster,
    getAllPatientsWhoHaveHadBoosterJab,
    getAllPatientsWhoHaveHadPrimaryJabs,
    getAllPatientsWhoNeedFirstJab,
    getAllPatientsWhoNeedSecondJab,
    getBadDataWarningLevel,
    getNonTextablePatientsWhoNeedFirstJab,
    getPatientsWhoNeedFirstOrSecondJab,
    getTextablePatientsWhoNeedFirstJab,
    getTotalSteps,
    renderEmptyPanel,
    renderUploadingText,
} from "./VaccineDeliveryUploadAndComposeHelpers";
import { VaccineDeliveryUploadButtonAndInfo } from "./VaccineDeliveryUploadButtonAndInfo";

// Use any as import Timout from NodeJS.Timout fails
let interval: any = null; // eslint-disable-line @typescript-eslint/no-explicit-any
let start: number;

export const VaccineDeliveryUploadAndReview = (): JSX.Element => {
    const dispatch = useDispatch();
    const history = useHistory();
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    const { selectedOrgId } = useSelectedOrganisation();
    const practiceId = useSelector(
        ({ practices }: ApplicationState) => practices.selectedPractice,
    );

    const csvUploadStatus = useSelector(
        ({ vaccineDelivery }: ApplicationState) =>
            vaccineDelivery?.csvUploadStatus ||
            VaccineAllInvitesInitialState.csvUploadStatus,
        shallowEqual,
    );
    const getDetailsStatus = useSelector(
        ({ vaccineDelivery }: ApplicationState) =>
            vaccineDelivery?.getDetailsStatus ||
            VaccineAllInvitesInitialState.getDetailsStatus,
        shallowEqual,
    );
    const inviteUploadStatus = useSelector(
        ({ vaccineDelivery }: ApplicationState) =>
            vaccineDelivery?.inviteUploadStatus ||
            VaccineAllInvitesInitialState.inviteUploadStatus,
        shallowEqual,
    );
    const patientList = useSelector(
        ({ vaccineDelivery }: ApplicationState) =>
            vaccineDelivery?.vaccineDeliveryDetails.items ||
            VaccineAllInvitesInitialState.vaccineDeliveryDetails.items,
        shallowEqual,
    );
    const vaccineDeliveryStatus = useSelector(
        ({ vaccineDelivery }: ApplicationState) =>
            vaccineDelivery?.vaccineDeliveryStatus ||
            VaccineAllInvitesInitialState.vaccineDeliveryStatus,
        shallowEqual,
    );
    const vaccineDeliveryId = useSelector(
        ({ vaccineDelivery }: ApplicationState) =>
            vaccineDelivery?.vaccineDeliveryId ||
            VaccineAllInvitesInitialState.vaccineDeliveryId,
        shallowEqual,
    );
    const uploadFailureMessage = useSelector(
        ({ vaccineDelivery }: ApplicationState) =>
            vaccineDelivery?.uploadFailureMessage ||
            VaccineAllInvitesInitialState.uploadFailureMessage,
        shallowEqual,
    );
    const badDataWarningLevel = useSelector(
        ({ vaccineDelivery }: ApplicationState) =>
            vaccineDelivery?.badDataWarningLevel ||
            VaccineAllInvitesInitialState.badDataWarningLevel,
        shallowEqual,
    );
    const badDataMessage = useSelector(
        ({ vaccineDelivery }: ApplicationState) =>
            vaccineDelivery?.badDataMessage ||
            VaccineAllInvitesInitialState.badDataMessage,
        shallowEqual,
    );
    const sendingStatus = useSelector(
        (
            { vaccineDelivery }: ApplicationState, // This is for activating the batch
        ) =>
            vaccineDelivery?.sendingStatus ||
            VaccineAllInvitesInitialState.sendingStatus,
        shallowEqual,
    );
    const vaccineCourse = useSelector(
        ({ vaccineDelivery }: ApplicationState) =>
            vaccineDelivery?.vaccineCourse ||
            VaccineAllInvitesInitialState.vaccineCourse,
    );

    const isSpring2024Enabled = useIsFeatureEnabled(
        FeatureName.VaccinePracticeSpring2024BoosterTab,
    );
    const isAutumn2024Enabled = useIsFeatureEnabled(
        FeatureName.VaccinePracticeAutumn2024BoosterTab,
    );

    const boosterLabel = boosterCopy(vaccineCourse);

    const [lastFileUploadErrors, setLastFileUploadErrors] = useState<string[]>(
        [],
    );
    const [uploadRequestCount, setUploadRequestCount] = useState<number>(0);
    const [capacityStatsStatus, setCapacityStatsStatus] =
        useState<UpdatingStatus>(UpdatingStatus.Initial);
    const [azCapacities, setAzCapacities] = useState<
        VaccineCapacityTypeStats | undefined
    >(undefined);
    const [modernaCapacities, setModernaCapacities] = useState<
        VaccineCapacityTypeStats | undefined
    >(undefined);
    const [pfizerCapacities, setPfizerCapacities] = useState<
        VaccineCapacityTypeStats | undefined
    >(undefined);
    const [pfizerPaediatricCapacities, setPfizerPaediatricCapacities] =
        useState<VaccineCapacityTypeStats | undefined>(undefined);
    const [janssenCapacities, setJanssenCapacities] = useState<
        VaccineCapacityTypeStats | undefined
    >(undefined);
    const [sanofiCapacities, setSanofiCapacities] = useState<
        VaccineCapacityTypeStats | undefined
    >(undefined);
    const [capacityStatsError, setCapacityStatsError] = useState("");

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        (async (): Promise<void> => {
            if (capacityStatsStatus === UpdatingStatus.Initial && practiceId) {
                setCapacityStatsStatus(UpdatingStatus.Loading);

                const { success, result } =
                    await ChainApiClient.getVaccineCapacityTypes(practiceId);
                if (success && result) {
                    setAzCapacities(
                        result.capacities.find(
                            (x) => x.vaccineType === NimsVaccineProductType.Az,
                        ),
                    );
                    setPfizerCapacities(
                        result.capacities.find(
                            (x) =>
                                x.vaccineType === NimsVaccineProductType.Pfizer,
                        ),
                    );
                    setPfizerPaediatricCapacities(
                        result.capacities.find(
                            (x) =>
                                x.vaccineType ===
                                NimsVaccineProductType.PfizerPaediatric,
                        ),
                    );
                    setModernaCapacities(
                        result.capacities.find(
                            (x) =>
                                x.vaccineType ===
                                NimsVaccineProductType.Moderna,
                        ),
                    );
                    setJanssenCapacities(
                        result.capacities.find(
                            (x) =>
                                x.vaccineType ===
                                NimsVaccineProductType.Janssen,
                        ),
                    );
                    setSanofiCapacities(
                        result.capacities.find(
                            (x) =>
                                x.vaccineType === NimsVaccineProductType.Sanofi,
                        ),
                    );
                    setCapacityStatsStatus(UpdatingStatus.Loaded);
                } else {
                    setCapacityStatsError(
                        "There was a problem fetching your capacity details. Please refresh the page.",
                    );
                    setCapacityStatsStatus(UpdatingStatus.Failed);
                }
            }
        })();
    });

    useEffect(() => {
        if (
            csvUploadStatus === UpdatingStatus.Loaded &&
            inviteUploadStatus !== UpdatingStatus.Failed
        ) {
            checkOnUpload();
        }

        return (): void => {
            if (interval !== null) {
                clearInterval(interval);
            }
        };
    }, [csvUploadStatus, inviteUploadStatus, vaccineDeliveryStatus]);

    useEffect(() => {
        if (
            csvUploadStatus === UpdatingStatus.Failed ||
            inviteUploadStatus === UpdatingStatus.Failed
        ) {
            ChainAnalyticsTracker.trackVaccineCsvUploadFailed(
                analyticsLoggedInProps,
            );
        }
    }, [csvUploadStatus, inviteUploadStatus]);

    // SendingStatus will change for the booster flow since we activate the batch here, can move forward once the activating is successfully done
    // Don't want to push forward if there is an error in activating the batch for booster - will need to show an error message on the page
    useEffect(() => {
        if (
            vaccineCourse !== VaccineCourse.Primary &&
            sendingStatus === UpdatingStatus.Loaded
        ) {
            history.push(
                generatePath(ROUTES_WORKSPACE.accubookInvitesSelect, {
                    workspaceId: practiceId,
                }),
            );
        }
    }, [sendingStatus, history, practiceId, vaccineCourse]);

    const moveForwardFunction = (): void => {
        const analyticsProps: ChainAnalyticsTracker.VaccineContinuedToWriteMessageProps =
            {
                ...analyticsLoggedInProps,
                patientsIncluded: textableFirstJabPatients.length,
                patientsExcluded: nonTextableFirstJabPatients.length,
                patientsWithFirstJab: secondJabPatients.length,
                patientsWithBothJabs: completedPatients.length,
                badDataWarninglevel: badDataWarningLevel,
            };
        ChainAnalyticsTracker.trackVaccineContinuedToWriteMessage(
            analyticsProps,
        );

        // For booster flow activate the batch here since we go straight onto the SelectSecond page to choose patients
        // N.B. The backend will know from the batch id that this is a booster batch and won't send out a message for this
        // If not booster flow, move to the next page straight away, not doing any action here as will activate the batch on the ComposeFirst page
        // Hence for the not booster flow don't need to wait to see if there's an error
        if (vaccineCourse !== VaccineCourse.Primary) {
            dispatch(
                sendMessage(
                    practiceId,
                    vaccineDeliveryId,
                    defaultInviteMessage,
                    true,
                ),
            );
        } else {
            history.push(
                generatePath(ROUTES_WORKSPACE.accubookInvitesComposeFirst, {
                    workspaceId: practiceId,
                }),
            );
        }
    };

    const checkOnUpload = (): void => {
        if (uploadRequestCount < 100) {
            if (
                vaccineDeliveryStatus !== VaccineDeliveryStatus.Created &&
                inviteUploadStatus !== UpdatingStatus.Loading
            ) {
                if (uploadRequestCount === 0) {
                    dispatch(getUploadStatus(practiceId, vaccineDeliveryId));
                    setUploadRequestCount(uploadRequestCount + 1);
                }
                if (interval !== null) {
                    clearInterval(interval);
                }
                interval = setInterval(
                    () => {
                        dispatch(
                            getUploadStatus(practiceId, vaccineDeliveryId),
                        );
                        setUploadRequestCount(uploadRequestCount + 1);
                    },
                    uploadRequestCount < 5 ? 2000 : 10000,
                );
            }
        }
        if (
            vaccineDeliveryStatus === VaccineDeliveryStatus.Created &&
            practiceId &&
            vaccineDeliveryId
        ) {
            if (interval !== null) {
                clearInterval(interval);
            }
            dispatch(getVaccineDeliveryDetails(practiceId, vaccineDeliveryId));
        }
    };

    const uploadFile = (e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        if (e.target.files !== null) {
            const files = Array.from(e.target.files);
            for (const file of files) {
                const isValid = validateFile(file);
                if (isValid) {
                    start = Date.now();
                    dispatch(uploadList(practiceId, vaccineCourse, file));
                }
            }
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const validateFile = (file: any): boolean => {
        const failureReasons = [];
        try {
            if (file.size > UploadMaxAllowedFileSize) {
                failureReasons.push(
                    "File too big, max file size: " +
                        UploadMaxAllowedFileSizeHumanReadable,
                );
            }

            const fileExtension = file.name.split(".").pop().toLowerCase();
            if (UploadAllowedFileExtensions.indexOf(fileExtension) === -1) {
                failureReasons.push("Unsupported file extension");
            }

            setLastFileUploadErrors(failureReasons);
            return failureReasons.length === 0;
        } catch (e) {
            setLastFileUploadErrors(failureReasons);
            return false;
        }
    };

    // These are the lists we show in the primary view - they need to be exhaustive of all the patients
    const firstJabPatients = getAllPatientsWhoNeedFirstJab(patientList);
    const textableFirstJabPatients =
        getTextablePatientsWhoNeedFirstJab(patientList);
    const nonTextableFirstJabPatients =
        getNonTextablePatientsWhoNeedFirstJab(patientList);
    const secondJabPatients = getAllPatientsWhoNeedSecondJab(patientList);
    const completedPatients = getAllPatientsWhoHaveHadPrimaryJabs(patientList);

    // These are the lists we show in the booster view - they need to be exhaustive of all the patients
    const firstAndSecondJabPatients =
        getPatientsWhoNeedFirstOrSecondJab(patientList);
    const boosterJabPatients = getAllPatientWhoNeedBooster(patientList);
    const allThreeJabPatients = getAllPatientsWhoHaveHadBoosterJab(patientList);
    const seasonalJabPatients = patientList; //changed to paitentList from getAllPatientWhoNeedSeasonalBooster(patientList) in book-1181

    useEffect(() => {
        if (
            csvUploadStatus === UpdatingStatus.Loaded &&
            getDetailsStatus === UpdatingStatus.Loaded
        ) {
            const end = Date.now();
            const uploadTime = Math.floor((end - start) / 1000) + " seconds";

            if (vaccineCourse !== VaccineCourse.Primary) {
                const analyticsProps: ChainAnalyticsTracker.VaccineCsvUploadedBoosterProps =
                    {
                        ...analyticsLoggedInProps,
                        patientsUploaded: patientList.length,
                        timeTaken: uploadTime,
                        needinitialCount:
                            firstAndSecondJabPatients.length.toString(),
                        needBoosterCount: boosterJabPatients.length.toString(),
                        hadAllCount: allThreeJabPatients.length.toString(),
                        course: vaccineCourse,
                    };
                ChainAnalyticsTracker.trackVaccineCsvUploadBoosterSucceeded(
                    analyticsProps,
                );
            } else {
                const analyticsProps: ChainAnalyticsTracker.VaccineCsvUploadedProps =
                    {
                        ...analyticsLoggedInProps,
                        patientsUploaded: patientList.length,
                        timeTaken: uploadTime,
                        awaitingResponse1stCount:
                            textableFirstJabPatients.length.toString(),
                        toManuallyBook1stCount:
                            nonTextableFirstJabPatients.length.toString(),
                        awaitingInvite2ndCount:
                            secondJabPatients.length.toString(),
                        hadBothCount: completedPatients.length.toString(),
                    };
                ChainAnalyticsTracker.trackVaccineCsvUploadSucceeded(
                    analyticsProps,
                );
            }
        }
    }, [csvUploadStatus, getDetailsStatus]);

    const changeToUploadPageForOtherCourse = (): void => {
        let courseType = VaccineCourse.Primary; // All boosters types would switch to primary
        if (vaccineCourse === VaccineCourse.Primary) {
            // Primary would switch to the latest booster
            courseType = isAutumn2024Enabled
                ? VaccineCourse.BoosterAutumn2024
                : isSpring2024Enabled
                ? VaccineCourse.BoosterSpring2024
                : VaccineCourse.BoosterAutumn2023;
        }
        dispatch(updateCourseType(courseType));
    };

    // only show jab state data if we have any (so that if NIMS display is off on the backend, we skip it)
    const showNims =
        patientList.filter(
            (item: VaccineDeliveryItem) =>
                item.jabState &&
                item.jabState !== VaccineExistingPatientState.Unknown,
        ).length > 0;

    const renderAccordions = (
        lists: VaccineDeliveryAccordionDetails[],
    ): JSX.Element[] => {
        return lists.map((details) => {
            return (
                <Accordion
                    className="mb-2"
                    icon={{ name: "Team", colour: "stone" }}
                    header={`${details.list.length} ${details.listSuffix}`}
                    key={details.testId}
                >
                    <VaccineDeliveryComposePatientTable
                        filteredList={details.list}
                        testIdPrefix={details.testId}
                        showNims={showNims}
                    />
                </Accordion>
            );
        });
    };

    const renderReviewPatientList = (
        lists: VaccineDeliveryAccordionDetails[],
    ): JSX.Element => {
        return (
            <div className="d-flex flex-column w-100">
                <div className="text-center">
                    <VaccineDeliveryHeader
                        stepNumber={1}
                        totalSteps={getTotalSteps(vaccineCourse)}
                        title={"Review patient list"}
                    />
                    <div className="mb-2">
                        {!!badDataWarningLevel && !!badDataMessage && (
                            <Feedback
                                colour={getBadDataWarningLevel(
                                    badDataWarningLevel,
                                )}
                                title={
                                    badDataWarningLevel ===
                                    PdsMatchWarningLevel.CRITICAL
                                        ? "Cannot upload your CSV"
                                        : "Please review the following before uploading"
                                }
                                props={{
                                    style: {
                                        whiteSpace: "pre-line",
                                        textAlign: "left",
                                    },
                                }}
                                content={badDataMessage}
                            />
                        )}
                    </div>
                </div>

                <div>
                    {vaccineCourse !==
                        (VaccineCourse.BoosterSpring2024 ||
                            VaccineCourse.BoosterAutumn2024) && (
                        <Card props={{ className: "mb-2" }} spacing={2}>
                            <Icon
                                name="Done"
                                colour="blue"
                                size={3}
                                props={{ className: "mr-2" }}
                            />
                            <Text variant="label" as="span" skinny>
                                {patientList.length} patients uploaded
                            </Text>
                        </Card>
                    )}
                    {renderAccordions(lists)}

                    {/* // Might have failed to activate the booster batch - show an error */}
                    {vaccineCourse !== VaccineCourse.Primary &&
                        sendingStatus === UpdatingStatus.Failed && (
                            <Feedback
                                colour="error"
                                title="Something has gone wrong, please try again"
                            />
                        )}
                </div>
            </div>
        );
    };

    const renderPrimaryReviewPatientList = (): JSX.Element => {
        return renderReviewPatientList([
            {
                list: firstJabPatients,
                listSuffix: "patients awaiting 1st vaccination",
                testId: "firstJab",
            },
            {
                list: secondJabPatients,
                listSuffix: "patients awaiting 2nd vaccination",
                testId: "secondJab",
            },
            {
                list: completedPatients,
                listSuffix: "patients have completed primary course",
                testId: "completed",
            },
        ]);
    };

    const renderBoosterReviewPatientList = (): JSX.Element => {
        return renderReviewPatientList([
            {
                list: firstAndSecondJabPatients,
                listSuffix: "patients who need primary vaccinations",
                testId: "firstAndSecondJab",
            },
            {
                list: boosterJabPatients,
                listSuffix: "patients awaiting booster vaccination",
                testId: "boosterJab",
            },
            {
                list: allThreeJabPatients,
                listSuffix:
                    "patients have had their booster vaccinations already",
                testId: "allThreeJab",
            },
        ]);
    };

    const renderSeasonalBoosterReviewPatientList = (): JSX.Element => {
        return renderReviewPatientList([
            {
                list: seasonalJabPatients,
                listSuffix: `patients uploaded for invitation to ${boosterLabel} vaccination`,
                testId: "boosterJab",
            },
        ]);
    };

    const contentLoaded =
        csvUploadStatus === UpdatingStatus.Loaded &&
        getDetailsStatus === UpdatingStatus.Loaded;

    return (
        <div className="d-flex align-items-flex-start justify-content-around mt-2">
            {(csvUploadStatus === UpdatingStatus.Initial ||
                csvUploadStatus === UpdatingStatus.Failed ||
                inviteUploadStatus === UpdatingStatus.Failed) && (
                <>
                    <VaccineDeliveryCapacityPanel
                        vaccineCourse={vaccineCourse}
                        capacityStatsStatus={capacityStatsStatus}
                        capacityStatsError={capacityStatsError}
                        warningLevel={badDataWarningLevel}
                        pfizerCapacities={pfizerCapacities}
                        pfizerPaediatricCapacities={pfizerPaediatricCapacities}
                        azCapacities={azCapacities}
                        modernaCapacities={modernaCapacities}
                        janssenCapacities={janssenCapacities}
                        sanofiCapacities={sanofiCapacities}
                    />
                    <VaccineDeliveryUploadButtonAndInfo
                        csvUploadStatus={csvUploadStatus}
                        inviteUploadStatus={inviteUploadStatus}
                        uploadFailureMessage={uploadFailureMessage}
                        lastFileUploadErrors={lastFileUploadErrors}
                        vaccineCourse={vaccineCourse}
                        changeUploadPageToOtherCourse={
                            changeToUploadPageForOtherCourse
                        }
                        uploadFile={uploadFile}
                    />
                    {renderEmptyPanel()}
                </>
            )}
            {(csvUploadStatus === UpdatingStatus.Loaded ||
                csvUploadStatus === UpdatingStatus.Loading) &&
                getDetailsStatus !== UpdatingStatus.Loaded &&
                inviteUploadStatus !== UpdatingStatus.Failed &&
                renderUploadingText()}
            {contentLoaded &&
                vaccineCourse === VaccineCourse.Primary &&
                renderPrimaryReviewPatientList()}
            {contentLoaded &&
                vaccineCourse === VaccineCourse.Booster &&
                renderBoosterReviewPatientList()}
            {contentLoaded &&
                (vaccineCourse === VaccineCourse.BoosterSpring2022 ||
                    vaccineCourse === VaccineCourse.BoosterAutumn2022 ||
                    vaccineCourse === VaccineCourse.BoosterSpring2023 ||
                    vaccineCourse === VaccineCourse.BoosterAutumn2023 ||
                    vaccineCourse === VaccineCourse.BoosterSpring2024 ||
                    vaccineCourse === VaccineCourse.BoosterAutumn2024) &&
                renderSeasonalBoosterReviewPatientList()}
            {/* Note below, different next page for the booster flow - go straight to the select patients page as opposed to the compose first page */}
            <StepsFooter
                backText="Back"
                backLink={generatePath(
                    ROUTES_WORKSPACE.accubookManagePatients,
                    { workspaceId: `${selectedOrgId}` },
                )}
                forwardText="Continue"
                forwardClickFunction={moveForwardFunction}
                disabled={
                    (csvUploadStatus === UpdatingStatus.Loaded &&
                        getDetailsStatus === UpdatingStatus.Loaded) === false ||
                    badDataWarningLevel === PdsMatchWarningLevel.CRITICAL ||
                    sendingStatus === UpdatingStatus.Loading
                }
            />
        </div>
    );
};

export default VaccineDeliveryUploadAndReview;
