import React from "react";

import { useCurrentUser } from "@accurx/auth";
import { ButtonLink, Ds } from "@accurx/design";
import { useGenerateTwoFactorToken } from "@accurx/workspace-management";
import { useLocation } from "react-router-dom";
import { useAnalytics } from "reduxQuarantine/useAnalytics";
import styled from "styled-components";

import { InnerStackPanel, OuterStackPanel } from "../LogIn/LoginStackPanel";

const StyledCenteredButtonLink = styled(ButtonLink)`
    width: 100%;
`;

const StyledContainer = styled(Ds.Flex).attrs({
    alignItems: "center",
    justifyContent: "center",
})`
    width: 100%;
    height: 100%;
`;

const StyledContent = styled(Ds.Flex).attrs({
    flexDirection: "column",
    alignItems: "center",
    gap: "1",
})`
    text-align: center;
`;

export const DesktopLaunch = (): JSX.Element => {
    const track = useAnalytics();

    const { user } = useCurrentUser();

    const { status, data } = useGenerateTwoFactorToken({ email: user.email });

    const location = useLocation();

    const getLoginMethod = () => {
        const params = new URLSearchParams(location.search);
        const loginMethod = params.get("loginMethod");

        if (loginMethod) {
            return loginMethod;
        }

        // potentially there will be also MagicLink here,
        // but magic link page is not moved here yet

        return "NhsSingleSignOn";
    };

    return (
        <OuterStackPanel>
            <StyledContainer>
                <StyledContent>
                    {status === "success" && (
                        <>
                            <Ds.Text size="medium" as="h1" weight="bold">
                                You are now logged in
                            </Ds.Text>
                            <InnerStackPanel horizontalContentAlignment="stretch">
                                <StyledCenteredButtonLink
                                    href={data.launchUrl}
                                    text="Open Accurx desktop toolbar"
                                    onClick={() =>
                                        track("DesktopLaunch Button Click", {
                                            loginMethod: getLoginMethod(),
                                        })
                                    }
                                />
                            </InnerStackPanel>
                            <Ds.Text size="small">
                                You can close this page once you're logged in on
                                the toolbar.
                            </Ds.Text>
                        </>
                    )}

                    {status === "error" && (
                        <InnerStackPanel horizontalContentAlignment="stretch">
                            <Ds.Text size="medium" weight="bold" as="h1">
                                Something went wrong
                            </Ds.Text>
                        </InnerStackPanel>
                    )}

                    {status === "loading" && <Ds.Spinner />}
                </StyledContent>
            </StyledContainer>
        </OuterStackPanel>
    );
};
