import { Tokens, focusBorderStyles } from "@accurx/design";
import styled from "styled-components";

export const SelectCard = styled.button`
    background: none;
    border: ${Tokens.BORDERS.normal.transparent};
    border-radius: ${Tokens.BORDERS.radius};
    padding: ${Tokens.SIZES[1]};
    text-align: left;
    transition: all 0.1s ease-in-out;

    svg {
        fill: ${Tokens.COLOURS.greyscale.stone};
    }

    &:disabled {
        p {
            color: ${Tokens.COLOURS.greyscale.metal};
        }
    }

    &:hover:not(:disabled, :focus) {
        background-color: ${Tokens.COLOURS.greyscale.cloud};
        border: ${Tokens.BORDERS.normal.transparent};

        p {
            color: ${Tokens.COLOURS.greyscale.night};
        }
        svg {
            fill: ${Tokens.COLOURS.greyscale.night};
        }
    }

    &:focus {
        background: ${Tokens.COLOURS.primary.blue["10"]};
        border: ${Tokens.BORDERS.normal.blue};

        p {
            color: ${Tokens.COLOURS.primary.blue["130"]};
        }
        svg {
            fill: ${Tokens.COLOURS.primary.blue["130"]};
        }
    }

    // Higher specificity to override GlobalStyle
    &:focus&:focus,
    &:focus-visible {
        ${focusBorderStyles};
    }
`;
