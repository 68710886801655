import { z } from "zod";

import { PatientDemographicsSchema } from "./PatientDemographicsSchema";

export const PatientDemographicsDtoSchema = z.object({
    patientDemographics: PatientDemographicsSchema,
});

export type PatientDemographicsDto = z.infer<
    typeof PatientDemographicsDtoSchema
>;
