import { PatientSearchResultBase } from "@accurx/api/portal";
import { Ds, Feedback, Tokens } from "@accurx/design";
import { MedicalRecordConnection } from "@accurx/native";
import { SelectCard, SelectRadioCard } from "@accurx/navigation";
import { NhsNumberHelpers } from "@accurx/shared";
import {
    formatDisplayAge,
    formatDisplayName,
    formatGender,
} from "domains/patient/utils/patient.helpers";
import styled from "styled-components";

export type PatientCardProps = {
    patient: PatientSearchResultBase & { deceased?: boolean };
    as?: "button" | "radio";
    onClick?: () => void;
    disabledMessage?: string;
    isLoading?: boolean;
    isDisabled?: boolean;
    medicalRecordSystem?: MedicalRecordConnection["system"];
    ["data-testid"]?: string;
};

/**
 * Patient cards can either be used as buttons that trigger a specific product
 * action directly on click, or as radios in conjunction with a patient panel
 * that displays product actions separately. Hence, the disabled state is only
 * relevant when used as a button.
 */
export const PatientCard = ({
    patient,
    as = "button",
    onClick,
    disabledMessage,
    isLoading,
    isDisabled,
    medicalRecordSystem,
    ...props
}: PatientCardProps) => {
    return as === "radio" ? (
        <SelectRadioCard
            name="patient"
            value={String(patient.nhsNumber)}
            onClick={onClick}
            data-testid={props["data-testid"] || "PatientCard"}
        >
            <PatientCardContent patient={patient} />
        </SelectRadioCard>
    ) : (
        <SelectCard
            onClick={onClick}
            disabled={isDisabled}
            data-testid={props["data-testid"] || "PatientCard"}
        >
            <PatientCardContent
                patient={patient}
                isLoading={isLoading}
                medicalRecordSystem={medicalRecordSystem}
            />
            {disabledMessage && (
                <StyledFeedback colour="secondary">
                    <Ds.Text as="span" weight="bold" color="night">
                        {disabledMessage}
                    </Ds.Text>
                </StyledFeedback>
            )}
        </SelectCard>
    );
};

const PatientCardContent = ({
    patient,
    isLoading = false,
    medicalRecordSystem,
}: {
    patient: PatientSearchResultBase & { deceased?: boolean };
    isLoading?: boolean;
    medicalRecordSystem?: MedicalRecordConnection["system"];
}) => {
    const displayName = formatDisplayName(patient);
    const displayAge = formatDisplayAge(patient.ageDisplay);
    const displayGender = formatGender(patient.gender);
    const displayNhsNumber = patient.nhsNumber
        ? NhsNumberHelpers.formatNhsNumber(patient.nhsNumber)
        : "Unknown";

    return (
        <StyledCardContent>
            {isLoading ? (
                <Ds.Box m="0.5">
                    <StyledSpinner />
                </Ds.Box>
            ) : (
                <Ds.Icon name="RecordPerson" size="small" />
            )}
            <Ds.Flex flexDirection="column" style={{ flexGrow: 1 }} gap="0.5">
                <Ds.Flex gap="1" alignItems={"center"}>
                    <Ds.Text weight="bold">
                        {displayName} (
                        {patient.deceased
                            ? "Deceased"
                            : `${String(displayAge)}, ${displayGender}`}
                        )
                    </Ds.Text>
                    {medicalRecordSystem && (
                        <Ds.Badge color="greyscale">
                            {medicalRecordSystem}
                        </Ds.Badge>
                    )}
                </Ds.Flex>
                <Ds.Text color="metal">
                    NHS: {displayNhsNumber} • DOB:{" "}
                    {patient.dateOfBirth?.replaceAll("-", " ")}
                </Ds.Text>
            </Ds.Flex>
        </StyledCardContent>
    );
};

const StyledCardContent = styled(Ds.Flex)`
    align-items: center;
    flex-shrink: 0;
    gap: ${Tokens.SIZES[1]};

    & > svg {
        fill: ${Tokens.COLOURS.greyscale.stone};
    }
`;

const StyledSpinner = styled(Ds.Spinner).attrs({ size: "xsmall" })`
    height: 16px;
    width: 16px;
`;

const StyledFeedback = styled(Feedback)`
    margin-top: ${Tokens.SIZES[1]};
`;
