import { useEffect } from "react";

import { Ds, VisuallyHidden } from "@accurx/design";
import { useLocation } from "react-router";

import { MobileNavButton } from "../../PrimaryNavigation/MobileNavItem/MobileNavItem";
import { StyledHeader, StyledModal } from "./MobileNavigationModal.styles";

export const MobileNavigationModal = ({
    open,
    onOpenChange,
    children,
}: Pick<Ds.ModalProps, "open" | "onOpenChange" | "children">) => {
    const { pathname } = useLocation();

    useEffect(() => onOpenChange(false), [onOpenChange, pathname]);

    return (
        <StyledModal
            open={open}
            onOpenChange={onOpenChange}
            fullscreen="lg"
            data-testid="modal-nav"
        >
            <StyledHeader>
                <MobileNavButton
                    onClick={() => onOpenChange(false)}
                    aria-label="Close"
                >
                    <Ds.Icon name="Cross" size="small" color="white" />
                </MobileNavButton>
                <VisuallyHidden as={Ds.Modal.Title}>
                    Navigation menu
                </VisuallyHidden>
            </StyledHeader>
            {children}
        </StyledModal>
    );
};
