import { useMemo } from "react";

import { useBrowserEnvironment } from "domains/native/hooks/useBrowserEnvironment";
import { useMedicalRecordConnection } from "domains/native/hooks/useMedicalRecordConnection";

type NativeTrackingFields = {
    accessType: "DesktopApp" | "Browser";
    medicalRecordSystem?: string;
    userMedicalRecordRole?: string;
};

export const useNativeTrackingFields = (): NativeTrackingFields => {
    const connection = useMedicalRecordConnection();
    const env = useBrowserEnvironment();

    return useMemo(
        () => ({
            accessType: env === "WebView" ? "DesktopApp" : "Browser",
            medicalRecordSystem: connection.system ?? undefined,
            userMedicalRecordRole:
                connection.userMedicalRecordRole ?? undefined,
        }),
        [env, connection.system, connection.userMedicalRecordRole],
    );
};
