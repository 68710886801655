import { ContactActivity as IContactActivity } from "@accurx/api/clinician-messaging";
import { useCurrentWorkspace } from "@accurx/auth";
import { Ds } from "@accurx/design";
import { StyledModalDetailsPanel } from "@accurx/navigation";
import { useDirectory } from "domains/directory/context/DirectoryProvider";
import { useContactDetailsQuery } from "domains/directory/hooks/queries/useContactDetailsQuery";
import { i } from "images";
import styled from "styled-components";

export const DirectoryContactDetails = () => {
    const { orgId: workspaceId } = useCurrentWorkspace();
    const { selectedContactId } = useDirectory();
    const { data, isLoading } = useContactDetailsQuery(
        { workspaceId, contactId: selectedContactId ?? "" },
        { enabled: !!selectedContactId },
    );

    if (selectedContactId && isLoading) {
        return (
            <StyledModalDetailsPanel>
                <>Loading...</>
            </StyledModalDetailsPanel>
        );
    }

    if (!selectedContactId || !data) {
        return <EmptyState />;
    }

    return (
        <StyledModalDetailsPanel>
            <Ds.Flex.Item flex="1 1 0">
                <Ds.Text mb="1" as="h2" weight="bold" size="large">
                    {data.contact.displayName ?? data.contact.email}
                </Ds.Text>
                <ContactActivity activity={data.activity} />
                {data.contact.notes && (
                    <NoteBox mb="2">
                        <Ds.Text mb="0.5" color="zinc" size="small">
                            Note
                        </Ds.Text>
                        <Ds.Text>{data.contact.notes}</Ds.Text>
                    </NoteBox>
                )}
                <MessageButton>
                    <Ds.Button.Icon appearance="outline" name="MessageWrite" />{" "}
                    Message
                </MessageButton>
                <Ds.Box my="2">
                    <Ds.Modal.Divider />
                </Ds.Box>
                <Ds.Flex flexDirection="column" gap="2">
                    <Ds.Flex.Item>
                        <Ds.Text as="h3" weight="bold" color="zinc">
                            About
                        </Ds.Text>
                    </Ds.Flex.Item>
                    <Ds.Flex flexDirection="column" gap="0.5">
                        <Ds.Text size="small" color="zinc">
                            Email
                        </Ds.Text>
                        <Ds.Text>{data.contact.email}</Ds.Text>
                    </Ds.Flex>
                    {data.contact.institution && (
                        <Ds.Flex flexDirection="column" gap="0.5">
                            <Ds.Text size="small" color="zinc">
                                Organisation
                            </Ds.Text>
                            <Ds.Text>{data.contact.institution.name}</Ds.Text>
                        </Ds.Flex>
                    )}
                    {data.contact.service && (
                        <Ds.Flex flexDirection="column" gap="0.5">
                            <Ds.Text size="small" color="zinc">
                                Service
                            </Ds.Text>
                            <Ds.Text>{data.contact.service.name}</Ds.Text>
                        </Ds.Flex>
                    )}
                    {data.contact.websiteUrl && (
                        <Ds.Flex flexDirection="column" gap="0.5">
                            <Ds.Text size="small" color="zinc">
                                Website
                            </Ds.Text>
                            <Ds.Link
                                target="_blank"
                                href={data.contact.websiteUrl}
                            >
                                Go to website
                            </Ds.Link>
                        </Ds.Flex>
                    )}
                </Ds.Flex>
            </Ds.Flex.Item>
            <Divider />
            <Ds.Flex gap="1" justifyContent="flex-end">
                <Ds.Button appearance="tertiary">
                    <Ds.Button.Icon appearance="outline" name="Pencil" /> Edit
                </Ds.Button>
                <Ds.Button appearance="tertiary">
                    <Ds.Button.Icon appearance="outline" name="Bin" /> Remove
                </Ds.Button>
            </Ds.Flex>
        </StyledModalDetailsPanel>
    );
};

const EmptyStateWrapper = styled(Ds.Flex).attrs({
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
})`
    height: 100%;
    margin: 0 20%;
    text-align: center;
`;

const ContactActivityWrapper = styled(Ds.Flex).attrs({
    alignItems: "center",
})`
    margin-bottom: 1rem;
`;

const NoteBox = styled(Ds.Box)`
    background-color: var(--ds-color-cloud);
    padding: 0.75rem 1rem;
    border-radius: 0.25rem;
`;

const MessageButton = styled(Ds.Button)`
    width: fit-content;
`;

const Divider = styled(Ds.Modal.Divider)`
    margin: 0 -1rem;
    margin-bottom: 0.5rem;
`;

const getContactActivityText = ({
    averageResponseTime,
    messagesSent,
}: {
    averageResponseTime: number;
    messagesSent: number;
}) => {
    if (averageResponseTime > 0) {
        if (averageResponseTime > 14)
            return "Average response time over 2 weeks";
        if (averageResponseTime > 0.5)
            return `${Math.ceil(
                averageResponseTime,
            )} day average response time`;
        return "Average response time less than a day";
    } else if (messagesSent > 0) {
        return `${messagesSent} messages sent recently from your workspace`;
    }
    return "No activity recorded";
};

export const ContactActivity = ({
    activity,
}: {
    activity?: IContactActivity | null;
}) => {
    const averageResponseTime = activity?.averageResponseTimeInDays ?? 0;
    const messagesSent = activity?.messagesFromThisWorkspaceInLast30Days ?? 0;

    const activityText = getContactActivityText({
        averageResponseTime,
        messagesSent,
    });

    return (
        <ContactActivityWrapper>
            <Ds.Icon name="Info" color="zinc" size="small" />
            <Ds.Text color="zinc">{activityText}</Ds.Text>
        </ContactActivityWrapper>
    );
};

const EmptyState = () => {
    return (
        <StyledModalDetailsPanel>
            <EmptyStateWrapper>
                <Ds.Flex.Item>
                    <img
                        src={i(`illustrations/clinician-80px@2x.png`)}
                        srcSet={`${i(`illustrations/clinician-80px.png`)}, ${i(
                            `illustrations/clinician-80px@2x.png`,
                        )} 2x, ${i(`illustrations/clinician-80px@3x.png`)} 3x`}
                        alt="No contact selected"
                        height="80"
                    />
                </Ds.Flex.Item>
                <Ds.Text>
                    Search or select a contact to see their details here
                </Ds.Text>
            </EmptyStateWrapper>
        </StyledModalDetailsPanel>
    );
};
