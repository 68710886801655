import { ComponentPropsWithRef, forwardRef } from "react";

import { Ds } from "@accurx/design";

export const ModalBackButton = forwardRef<
    HTMLButtonElement,
    ComponentPropsWithRef<"button">
>((props, forwardedRef) => {
    return (
        <Ds.Button
            ref={forwardedRef}
            appearance="tertiary"
            aria-label="Back"
            {...props}
        >
            <Ds.Button.Icon name="ArrowTail" rotate="270" />
        </Ds.Button>
    );
});

export const ModalMoreButton = forwardRef<
    HTMLButtonElement,
    ComponentPropsWithRef<"button">
>((props, forwardedRef) => {
    return (
        <Ds.Button
            ref={forwardedRef}
            appearance="tertiary"
            aria-label="More"
            {...props}
        >
            <Ds.Button.Icon appearance="solid" name="More" />
        </Ds.Button>
    );
});
