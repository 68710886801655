import { createQueryHook } from "@accurx/api/QueryClient";
import {
    ExternalEmailContact,
    GetContactsResponse,
} from "@accurx/api/clinician-messaging";
import { httpClient2 } from "@accurx/shared";

type FrequentContactsQueryVariables = {
    workspaceId: number;
    limit: number;
};

export const FrequentContactsQueryKey = "FrequentContactsQuery";

export const useFrequentContactsQuery = createQueryHook<
    FrequentContactsQueryVariables,
    ExternalEmailContact[]
>(({ workspaceId, limit }) => ({
    queryKey: [FrequentContactsQueryKey, { workspaceId, limit }],
    queryFn: async () => {
        const { contacts } = await httpClient2
            .get({
                path: "/api/clinicianmessaging/directory/:workspaceId/contacts",
                params: { workspaceId },
                query: `?sortBy=MessageFrequency&limit=${limit}`,
            })
            .json<GetContactsResponse>();

        return contacts;
    },
}));
