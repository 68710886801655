import { useEffect } from "react";

// import { Voip } from "./voip";
import { FeatureName } from "@accurx/auth";
import { ErrorSummaryProvider } from "@accurx/design";
import { shallowEqual, useDispatch } from "react-redux";
import { Redirect, Switch, useParams } from "react-router-dom";
import { useSelectedOrganisation } from "reduxQuarantine/SelectedOrganisationProvider";

import { AppRoute } from "app/AppRoute";
import { UpdatingStatus } from "shared/LoadingStatus";
import { ROUTES_CHAIN, ROUTES_ORGS } from "shared/Routes";
import { useAppSelector, useIsFeatureEnabled } from "store/hooks";

import { OrganisationHelper } from "../../shared/OrganisationHelper";
import { actionCreators as accountActionCreators } from "../account/AccountActions";
import { BatchType } from "../batchMessage/gp/BatchMessage.types";
import { BatchMessageChoose } from "../batchMessage/gp/pages/BatchMessageChoose";
// import {
//     AppointmentReminderCompose,
//     AppointmentReminderHome,
// } from "./appointmentReminder";
// TODO remove non-lazy import after code-splitting rollout
import { BatchMessageOverview } from "../batchMessage/gp/pages/BatchMessageOverview";
import { BatchMessageReview } from "../batchMessage/gp/pages/BatchMessageReview";
import { BatchMessageSchedule } from "../batchMessage/gp/pages/BatchMessageSchedule";
import { BatchMessageUpload } from "../batchMessage/gp/pages/BatchMessageUpload";
import { ComposeCopd } from "../batchMessage/gp/pages/compose/ComposeCopd";
import { ComposeFlorey } from "../batchMessage/gp/pages/compose/ComposeFlorey";
import { ComposeInhaler } from "../batchMessage/gp/pages/compose/ComposeInhaler";
import { ComposeSelfbook } from "../batchMessage/gp/pages/compose/ComposeSelfbook";
import { ComposeSms } from "../batchMessage/gp/pages/compose/ComposeSms";
import { Layout as OldLayout } from "../navbar/Layout";
import { loadPractices, selectPractice } from "./Practices.actions";
import { PracticeUrlParams } from "./Practices.types";
// TODO remove non-lazy import after code-splitting rollout
import { AppointmentReminderComposePage } from "./appointmentReminder/AppointmentReminderCompose/AppointmentReminderComposePage";
import { BeastDownloadCsv } from "./beast";
// import { FloreyBuilder } from "./floreyBuilder";
import { InstalledComputers } from "./computers/InstalledComputers";
// import {
//     BatchMessageOverview,
//     BatchMessageSchedule,
//     BatchMessageChoose,
//     BatchMessageReview,
//     BatchMessageUpload,
//     ComposeCopd,
//     ComposeFlorey,
//     ComposeInhaler,
//     ComposeSelfbook,
//     ComposeSms,
// } from "./batchMessage/gp/pages";
import { DelayedMessages } from "./delayedMessages/DelayedMessages";
// TODO remove non-lazy import after code-splitting rollout
import { DeliveryReceiptsContainer as DeliveryReceipts } from "./deliveryReceipts/DeliveryReceipts.container";
// import { PatientTriage } from "./patientTriage";
import { PracticeDetail } from "./details/PracticeDetail";
// import { DeliveryReceipts } from "./deliveryReceipts";
// TODO remove non-lazy import after code-splitting rollout
import { FloreyBuilder } from "./floreyBuilder/FloreyBuilder";
import { ManageTemplatesOverview } from "./manageTemplates/ManageTemplatesOverview";
import { ManageTemplatesFormPage } from "./manageTemplatesForm/ManageTemplatesFormPage";
import { ModuleAccess } from "./moduleAccess/moduleAccess";
// TODO remove non-lazy import after code-splitting rollout
import { PatientTriageContainer as PatientTriage } from "./patientTriage/PatientTriage.container";
import { TabIds } from "./patientTriage/types";
// TODO remove non-lazy import after code-splitting rollout
import { RecordViewOptIn } from "./recordView/RecordViewOptIn";
// import { RecordViewOptIn } from "./recordView";
import { RequestsList } from "./recordViewRequests/RequestsList";
import { VaccineApprovalRequests } from "./vaccine/approvalRequests/ApprovalRequests";
import { VaccineCapacityDashboard } from "./vaccine/capacityDashboard/CapacityDashboard";
import { VaccineCourse } from "./vaccine/models/VaccineDeliveryDTO";
import { VaccineSiteSetup } from "./vaccine/siteSetup/SiteSetup";
// TODO remove non-lazy import after code-splitting rollout
import { VaccineAllPatientsInvitedContainer as VaccineAllPatientsInvited } from "./vaccine/vaccineAllPatientsInvited/VaccineAllPatientsInvited.container";
import { VaccineCancel } from "./vaccine/vaccineCancel/VaccineCancel.component";
import { VaccineDeliverySinglePatient } from "./vaccine/vaccineInviteSinglePatient/VaccineDeliverySinglePatient";
import { updateCourseType } from "./vaccine/vaccineInvitesOldPage/vaccineDelivery.actions";
import { VaccineDeliveryComposeFirst } from "./vaccine/vaccineInvitesUploadAndCompose/VaccineDeliveryComposeFirst";
import { VaccineDeliveryComposeSecond } from "./vaccine/vaccineInvitesUploadAndCompose/VaccineDeliveryComposeSecond";
import { VaccineDeliveryReviewInvites } from "./vaccine/vaccineInvitesUploadAndCompose/VaccineDeliveryReviewInvites";
import { VaccineDeliverySelectSecond } from "./vaccine/vaccineInvitesUploadAndCompose/VaccineDeliverySelectSecond";
import { VaccineDeliveryUploadAndReview } from "./vaccine/vaccineInvitesUploadAndCompose/VaccineDeliveryUploadAndReview";
import { VaccineDeliveryUploadSecond } from "./vaccine/vaccineInvitesUploadAndCompose/VaccineDeliveryUploadSecond";
import { VaccineRecordingList } from "./vaccine/vaccineRecording/VaccineRecordingList.component";
import { VaccineRecordingAllSessions } from "./vaccine/vaccineRecording/vaccineAllSessions/VaccineRecordingAllSessions.component";
import { VaccineReviewPatients } from "./vaccine/vaccineReviewPatients/VaccineReviewPatients.component";
import { VaccineSmsPreview } from "./vaccine/vaccineSmsPreview/VaccineSmsPreview.component";
// import {
//     VaccineAllPatientsInvited,
//     VaccineApprovalRequests,
//     VaccineCancel,
//     VaccineCapacityDashboard,
//     VaccineDeliveryComposeFirst,
//     VaccineDeliveryComposeSecond,
//     VaccineDeliveryReviewInvites,
//     VaccineDeliverySelectSecond,
//     VaccineDeliveryUploadAndReview,
//     VaccineDeliveryUploadSecond,
//     VaccineDeliverySinglePatient,
//     VaccineRecordingAllSessions,
//     VaccineRecordingList,
//     VaccineReviewPatients,
//     VaccineSiteSetup,
//     VaccineSmsPreview,
// } from "./vaccine";
// TODO remove non-lazy import after code-splitting rollout
import { Voip } from "./voip/Voip";

export const PracticesRoutes = (): JSX.Element => {
    const dispatch = useDispatch();
    const { orgId }: PracticeUrlParams = useParams();

    const selectedPractice = useAppSelector(
        ({ practices }) => practices.selectedPractice,
    );
    const { setSelectedOrgId } = useSelectedOrganisation();

    const hasEverLoaded = useAppSelector(
        ({ practices }) => practices.hasEverLoaded,
    );

    const residentPracticeIds = useAppSelector(({ practices }) =>
        practices.items.filter((x) => x.isResident).map((x) => x.id.toString()),
    );

    const isResident = !!orgId && residentPracticeIds.includes(orgId);

    // Appointment Reminder
    const appointmentRemindersStatus = useAppSelector(
        ({ appointmentReminders }) =>
            appointmentReminders?.getAppointmentRemindersStatus,
        shallowEqual,
    );
    const isAppointmentLoaded =
        appointmentRemindersStatus !== UpdatingStatus.Initial;

    // Inhaler Reminders
    const accountState = useAppSelector(({ account }) => account, shallowEqual);
    const userId = accountState.user?.accuRxUserId;
    const org = OrganisationHelper.getOrganisation(accountState);
    const hasFeatureEnabled = (feature: FeatureName): boolean =>
        OrganisationHelper.isFeatureEnabled(org, feature);

    const isInhalerEnabled = hasFeatureEnabled(
        FeatureName.BehaviourChangeInhalerRct,
    );

    // Batch messaging
    const batchType = useAppSelector(
        ({ batchMessage }) => batchMessage.batchType,
    );
    // batchType is set on practicesBatchMessagingChoose. All users has to go to through that page where we check feature flags
    const hasChooseBatchType = batchType !== BatchType.NONE;
    const batchMessageRedirectPath =
        ROUTES_CHAIN.practicesBatchMessaging.replace(":orgId", orgId || "");
    const batchMessageChooseRedirectPath =
        ROUTES_CHAIN.practicesBatchMessagingChoose.replace(
            ":orgId",
            orgId || "",
        );
    const isBatchEnabled =
        hasFeatureEnabled(FeatureName.BatchGskFlorey) ||
        hasFeatureEnabled(FeatureName.BatchMessagingFlorey) ||
        hasFeatureEnabled(FeatureName.BatchSelfBook);

    // Florey Builder
    const isFloreyBuilderEnabled = hasFeatureEnabled(FeatureName.FloreyBuilder);

    const practicesRedirectPath = ROUTES_CHAIN.practicesDetails.replace(
        ":orgId",
        orgId || "",
    );

    // Load all Practices
    useEffect(() => {
        if (!hasEverLoaded) {
            dispatch(loadPractices());
        }
    }, [dispatch, hasEverLoaded]);

    // Select current Practice
    useEffect(() => {
        if (hasEverLoaded && orgId && orgId !== selectedPractice) {
            const numericOrgId = parseInt(orgId, 10);
            /**
             * Note: selectedPractice is used for all Chain apis, selectedOrganisation for all fleming api.
             * In the long run we want to only use one, but we that would involve to much refactor for now
             * Both field are not the same type (string vs number) and should be refactor into string
             */
            dispatch(selectPractice(orgId));
            dispatch(
                accountActionCreators.selectOrganisation(userId, numericOrgId),
            );
            setSelectedOrgId(numericOrgId);
        }
    }, [
        userId,
        dispatch,
        hasEverLoaded,
        orgId,
        selectedPractice,
        setSelectedOrgId,
    ]);

    // Select default vaccine course based on feature flag

    const hasSpring2024Tab = useIsFeatureEnabled(
        FeatureName.VaccinePracticeSpring2024BoosterTab,
    );
    const hasAutumn2024Tab = useIsFeatureEnabled(
        FeatureName.VaccinePracticeAutumn2024BoosterTab,
    );

    const vaccineCourse = useAppSelector(
        ({ vaccineDelivery }) => vaccineDelivery.vaccineCourse,
    );
    useEffect(() => {
        if (vaccineCourse === VaccineCourse.None && hasEverLoaded) {
            dispatch(
                updateCourseType(
                    hasAutumn2024Tab
                        ? VaccineCourse.BoosterAutumn2024
                        : hasSpring2024Tab
                        ? VaccineCourse.BoosterSpring2024
                        : VaccineCourse.BoosterAutumn2023,
                ),
            );
        }
    }, [
        dispatch,
        hasEverLoaded,
        selectedPractice,
        hasSpring2024Tab,
        hasAutumn2024Tab,
        vaccineCourse,
    ]);

    const newManageOrgPageEnabled = useIsFeatureEnabled(
        FeatureName.NewManageOrganisationPage,
    );

    return (
        <>
            <Switch>
                {/* Redirection is needed because old flyers or users references this old url */}
                <Redirect
                    from={"/practices/:orgId/recordview/optin"}
                    to={ROUTES_CHAIN.practicesRecordViewOptIn}
                />
                {/*
                    This is slightly annoyingly complicated isn't it.
                    Fortunately it's only temporary. If the new manage org
                    page feature flag is enabled we need to capture any
                    routes that have new versions and redirect them.
                    The paths in the <AppRoute/> here have to be `exact` so we
                    don't unintentionally trap any other routes that share a prefix.
                    And the redirects have to be in a `<Switch/>` due to the way
                    React Router 5 works - redirects only get access to the path
                    params if they're in a `<Switch/>` ¯\_(ツ)_/¯.
                */}
                {newManageOrgPageEnabled && (
                    <AppRoute
                        path={[
                            ROUTES_CHAIN.practicesOrgId,
                            ROUTES_CHAIN.practicesDetails,
                            ROUTES_CHAIN.practicesVaccineAllPatientsInvited,
                            ROUTES_CHAIN.practicesVaccineSiteSetup,
                            ROUTES_CHAIN.practicesVaccineBookedClinics,
                            ROUTES_CHAIN.practicesVaccineApprovalRequests,
                            ROUTES_CHAIN.practicesVaccineCapacityDashboard,
                            ROUTES_CHAIN.practicesAppointmentReminder,
                            ROUTES_CHAIN.practicesBatchMessaging,
                        ]}
                        exact
                    >
                        <Switch>
                            <Redirect
                                from={ROUTES_CHAIN.practicesDetails}
                                to={ROUTES_ORGS.org}
                            />
                            <Redirect
                                from={
                                    ROUTES_CHAIN.practicesVaccineAllPatientsInvited
                                }
                                to={ROUTES_ORGS.accubook.replace(
                                    ":tab?",
                                    "manage-patients",
                                )}
                            />
                            <Redirect
                                from={ROUTES_CHAIN.practicesVaccineSiteSetup}
                                to={ROUTES_ORGS.accubook.replace(
                                    ":tab?",
                                    "manage-clinics",
                                )}
                            />
                            <Redirect
                                from={
                                    ROUTES_CHAIN.practicesVaccineBookedClinics
                                }
                                to={ROUTES_ORGS.accubook.replace(
                                    ":tab?",
                                    "booked-patients",
                                )}
                            />
                            <Redirect
                                from={
                                    ROUTES_CHAIN.practicesVaccineApprovalRequests
                                }
                                to={ROUTES_ORGS.accubook.replace(
                                    ":tab?",
                                    "delivery-sites",
                                )}
                            />
                            <Redirect
                                from={
                                    ROUTES_CHAIN.practicesVaccineCapacityDashboard
                                }
                                to={ROUTES_ORGS.accubook.replace(
                                    ":tab?",
                                    "dashboard",
                                )}
                            />
                            <Redirect
                                from={ROUTES_CHAIN.practicesAppointmentReminder}
                                to={ROUTES_ORGS.appointmentReminders}
                            />
                            <Redirect
                                from={ROUTES_CHAIN.practicesBatchMessaging}
                                to={ROUTES_ORGS.allMessages.replace(
                                    ":tab?",
                                    "batch-messages",
                                )}
                            />
                            <Redirect
                                from={ROUTES_CHAIN.practicesOrgId}
                                to={ROUTES_ORGS.org}
                            />
                        </Switch>
                    </AppRoute>
                )}
                <AppRoute
                    path={ROUTES_CHAIN.practicesOrgId}
                    allowRoute={{
                        // If hasn't loaded, don't evaluate the condition so that the AppRoute doesn't kick in before user membership is known
                        isAllowed: !hasEverLoaded || isResident,
                        redirectTo: ROUTES_CHAIN.practices,
                    }}
                >
                    <OldLayout>
                        <Switch>
                            <AppRoute
                                path={
                                    ROUTES_CHAIN.practicesAppointmentReminderCompose
                                }
                                allowRoute={{
                                    isAllowed: isAppointmentLoaded,
                                    redirectTo:
                                        ROUTES_CHAIN.practicesAppointmentReminder.replace(
                                            ":orgId",
                                            orgId || "",
                                        ),
                                }}
                            >
                                <AppointmentReminderComposePage />
                            </AppRoute>
                            <AppRoute
                                path={ROUTES_CHAIN.practicesBatchMessaging}
                                component={BatchMessageOverview}
                            />
                            <AppRoute
                                path={
                                    ROUTES_CHAIN.practicesBatchMessagingChoose
                                }
                                allowRoute={{
                                    isAllowed: isBatchEnabled,
                                    redirectTo: batchMessageRedirectPath,
                                }}
                            >
                                <BatchMessageChoose />
                            </AppRoute>
                            <AppRoute
                                exact
                                path={
                                    ROUTES_CHAIN.practicesBatchMessagingComposeCopd
                                }
                                allowRoute={{
                                    isAllowed: hasChooseBatchType,
                                    redirectTo: batchMessageChooseRedirectPath,
                                }}
                            >
                                <ComposeCopd />
                            </AppRoute>

                            <AppRoute
                                exact
                                path={
                                    ROUTES_CHAIN.practicesBatchMessagingComposeFlorey
                                }
                                allowRoute={{
                                    isAllowed: hasChooseBatchType,
                                    redirectTo: batchMessageChooseRedirectPath,
                                }}
                            >
                                <ComposeFlorey />
                            </AppRoute>

                            <AppRoute
                                exact
                                path={
                                    ROUTES_CHAIN.practicesBatchMessagingComposeInhaler
                                }
                                allowRoute={{
                                    isAllowed: hasChooseBatchType,
                                    redirectTo: batchMessageChooseRedirectPath,
                                }}
                            >
                                <ComposeInhaler />
                            </AppRoute>
                            <AppRoute
                                exact
                                path={
                                    ROUTES_CHAIN.practicesBatchMessagingComposeSms
                                }
                                allowRoute={{
                                    isAllowed: hasChooseBatchType,
                                    redirectTo: batchMessageChooseRedirectPath,
                                }}
                            >
                                <ComposeSms />
                            </AppRoute>
                            <AppRoute
                                exact
                                path={
                                    ROUTES_CHAIN.practicesBatchMessagingComposeSelfbook
                                }
                                allowRoute={{
                                    isAllowed: hasChooseBatchType,
                                    redirectTo: batchMessageChooseRedirectPath,
                                }}
                            >
                                <ComposeSelfbook />
                            </AppRoute>
                            <AppRoute
                                exact
                                path={
                                    ROUTES_CHAIN.practicesBatchMessagingUpload
                                }
                                allowRoute={{
                                    isAllowed: hasChooseBatchType,
                                    redirectTo: batchMessageChooseRedirectPath,
                                }}
                            >
                                <BatchMessageUpload />
                            </AppRoute>
                            <AppRoute
                                exact
                                path={
                                    ROUTES_CHAIN.practicesBatchMessagingSchedule
                                }
                                allowRoute={{
                                    isAllowed: hasChooseBatchType,
                                    redirectTo: batchMessageChooseRedirectPath,
                                }}
                            >
                                <BatchMessageSchedule />
                            </AppRoute>
                            <AppRoute
                                exact
                                path={
                                    ROUTES_CHAIN.practicesBatchMessagingReview
                                }
                                allowRoute={{
                                    isAllowed: hasChooseBatchType,
                                    redirectTo: batchMessageChooseRedirectPath,
                                }}
                            >
                                <BatchMessageReview />
                            </AppRoute>
                            <AppRoute
                                path={ROUTES_CHAIN.practicesDelayedMessages}
                                component={DelayedMessages}
                            />
                            <AppRoute
                                path={ROUTES_CHAIN.practicesDeliveryReceipts}
                                component={DeliveryReceipts}
                            />
                            <AppRoute
                                path={ROUTES_CHAIN.practicesDetails}
                                component={PracticeDetail}
                            />
                            <AppRoute
                                path={ROUTES_CHAIN.practicesBeastPatients}
                                allowRoute={{
                                    isAllowed: isInhalerEnabled,
                                    redirectTo:
                                        ROUTES_CHAIN.practicesDetails.replace(
                                            ":orgId",
                                            orgId || "",
                                        ),
                                }}
                            >
                                <BeastDownloadCsv />
                            </AppRoute>
                            <AppRoute
                                path={ROUTES_CHAIN.practicesInstalledComputers}
                                component={InstalledComputers}
                            />

                            <AppRoute
                                path={[
                                    ROUTES_CHAIN.practicesManageTemplatesCreate,
                                    ROUTES_CHAIN.practicesManageTemplatesForm,
                                ]}
                            >
                                <ErrorSummaryProvider>
                                    <ManageTemplatesFormPage />
                                </ErrorSummaryProvider>
                            </AppRoute>

                            <AppRoute
                                path={ROUTES_CHAIN.practicesManageTemplates}
                            >
                                <ManageTemplatesOverview />
                            </AppRoute>

                            <AppRoute
                                path={ROUTES_CHAIN.practicesModuleAccess}
                                component={ModuleAccess}
                            />
                            <AppRoute
                                path={ROUTES_CHAIN.practicesPatientTriage}
                                component={PatientTriage}
                            />
                            <AppRoute
                                path={ROUTES_CHAIN.practicesPatientTriageSetup}
                                render={(props) => (
                                    <PatientTriage
                                        {...props}
                                        deepLinkActivePage={
                                            TabIds.SetUpPatientTriage
                                        }
                                    />
                                )}
                            />
                            <AppRoute
                                path={
                                    ROUTES_CHAIN.practicesPatientTriageSettings
                                }
                                render={(props) => (
                                    <PatientTriage
                                        {...props}
                                        deepLinkActivePage={TabIds.Settings}
                                    />
                                )}
                            />
                            <AppRoute
                                path={ROUTES_CHAIN.practicesRecordViewOptIn}
                                component={RecordViewOptIn}
                            />
                            <AppRoute
                                path={ROUTES_CHAIN.practicesRecordViewRequests}
                                component={RequestsList}
                            />
                            <AppRoute
                                path={
                                    ROUTES_CHAIN.practicesVaccineApprovalRequests
                                }
                                component={VaccineApprovalRequests}
                            />
                            <AppRoute
                                path={
                                    ROUTES_CHAIN.practicesVaccineCapacityDashboard
                                }
                                component={VaccineCapacityDashboard}
                            />
                            <AppRoute
                                path={ROUTES_CHAIN.practicesVaccineSiteSetup}
                                component={VaccineSiteSetup}
                            />
                            <AppRoute
                                path={
                                    ROUTES_CHAIN.practicesVaccineAllPatientsInvited
                                }
                                component={VaccineAllPatientsInvited}
                            />
                            <AppRoute
                                path={
                                    ROUTES_CHAIN.practicesVaccineInvitesUploadAndReview
                                }
                                component={VaccineDeliveryUploadAndReview}
                            />
                            <AppRoute
                                path={
                                    ROUTES_CHAIN.practicesVaccineInvitesComposeFirst
                                }
                                component={VaccineDeliveryComposeFirst}
                            />
                            <AppRoute
                                path={
                                    ROUTES_CHAIN.practicesVaccineInvitesComposeSecond
                                }
                                component={VaccineDeliveryComposeSecond}
                            />
                            <AppRoute
                                path={
                                    ROUTES_CHAIN.practicesVaccineInvitesSelectSecond
                                }
                                component={VaccineDeliverySelectSecond}
                            />
                            <AppRoute
                                path={
                                    ROUTES_CHAIN.practicesVaccineInvitesUploadSecond
                                }
                                component={VaccineDeliveryUploadSecond}
                            />
                            <AppRoute
                                path={
                                    ROUTES_CHAIN.practicesVaccineInvitesReviewInvites
                                }
                                component={VaccineDeliveryReviewInvites}
                            />
                            <AppRoute
                                path={
                                    ROUTES_CHAIN.practicesVaccineInvitesSingle
                                }
                                component={VaccineDeliverySinglePatient}
                            />
                            <AppRoute
                                path={
                                    ROUTES_CHAIN.practicesVaccineCancelBookings
                                }
                                component={VaccineCancel}
                            />
                            <AppRoute
                                path={ROUTES_CHAIN.practicesVaccineSmsPreview}
                                component={VaccineSmsPreview}
                            />
                            <AppRoute
                                path={
                                    ROUTES_CHAIN.practicesVaccineReviewPatients
                                }
                                component={VaccineReviewPatients}
                            />
                            <AppRoute
                                path={
                                    ROUTES_CHAIN.practicesVaccineBookedClinicsId
                                }
                                component={VaccineRecordingList}
                            />
                            <AppRoute
                                path={
                                    ROUTES_CHAIN.practicesVaccineBookedClinics
                                }
                                component={VaccineRecordingAllSessions}
                            />
                            <AppRoute
                                path={ROUTES_CHAIN.practicesVoip}
                                component={Voip}
                            />

                            <AppRoute
                                path={ROUTES_CHAIN.practicesFloreyBuilderBase}
                                allowRoute={{
                                    isAllowed: isFloreyBuilderEnabled,
                                    redirectTo: practicesRedirectPath,
                                }}
                            >
                                <FloreyBuilder />
                            </AppRoute>
                            {/* This will catch all random url mistake like "practices/3/random" */}
                            {/* A Redirect on this route is required both inside and outside this switch (see below), to guarantee the redirect whether or not the AppRoute is allowed */}
                            <Redirect
                                from={ROUTES_CHAIN.practicesOrgId}
                                to={ROUTES_CHAIN.practicesDetails}
                            />
                        </Switch>
                    </OldLayout>
                </AppRoute>
                {/* This will catch all random url mistake like "practices/3/random" */}
                <Redirect
                    from={ROUTES_CHAIN.practicesOrgId}
                    to={ROUTES_CHAIN.practicesDetails}
                />
            </Switch>
        </>
    );
};

export default PracticesRoutes;
