import React, { useContext, useEffect, useRef, useState } from "react";

import { useAnalytics } from "@accurx/analytics";
import {
    CollaborativeWorkspaceResponse,
    ExampleWorkspace,
} from "@accurx/api/account";
import {
    FeatureName,
    isFeatureEnabledForWorkspace,
    useCurrentUser,
} from "@accurx/auth";
import {
    Button,
    Ds,
    Feedback,
    Input,
    Text,
    Tokens,
    usePagination,
} from "@accurx/design";
import { LinkIcon } from "@accurx/design/dist/components/Link/LinkIcon";
import { LinkText } from "@accurx/design/dist/components/Link/LinkText";
import { useFuzzyFilter } from "@accurx/hooks";
import { BackButton } from "@accurx/navigation";
import { Log } from "@accurx/shared";
import {
    PaginationControls,
    PaginationLabel,
    ROOT_ROUTES,
    SUPPORT_ARTICLE_WORKSPACE_GUIDANCE,
    WORKSPACE_MANAGEMENT_ROUTES,
    useGetExampleWorkspacesByNationalCodeQuery,
    useGetWorkspacesByNationalCodeQuery,
} from "@accurx/workspace-management";
import {
    Link,
    Redirect,
    generatePath,
    useHistory,
    useLocation,
    useRouteMatch,
} from "react-router-dom";
import { toast } from "react-toastify";
import { useSelectedOrganisation } from "reduxQuarantine/SelectedOrganisationProvider";
import styled from "styled-components";

import { useGetAllowedNhsOrganisationsQuery } from "app/hooks/queries/useGetAllowedNhsOrganisationsQuery";
import { StyledTwoThirdsContainer } from "app/layout/TwoThirdsContainer/TwoThirdsContainer.styles";
import { SkeletonLoader } from "app/sharedComponents/SkeletonLoader/SkeletonLoader";
import { SkeletonList } from "app/sharedComponents/loadingSkeleton/SkeletonList";
import { SelectedAllowedOrganisationContext } from "app/workspace/context/SelectedAllowedOrganisationProvider";
import { StyledUnderlinedText } from "app/workspaceSettings/pages/UserManagement/ApprovedUserCard/ApprovedUserCard.styles";
import { ROUTES } from "shared/Routes";

import {
    StyledCardItem,
    StyledCardList,
    StyledLink,
    StyledMain,
    StyledPageWrapper,
} from "./Layout.styles";
import { ExampleWorkspaceItem } from "./WorkspaceItem/ExampleWorkspaceItem";
import { ExistingWorkspaceItem } from "./WorkspaceItem/ExistingWorkspaceItem";
import { UserflowIds } from "./constants";
import { getCombinedWorkspaces } from "./helpers";

const StyledText = styled(Text)`
    font-size: 1rem;
`;

const exampleWorkspaceTypeCheck = (
    workspace: ExampleWorkspace | CollaborativeWorkspaceResponse,
): workspace is ExampleWorkspace => {
    return !("id" in workspace);
};

const CreateNewWorkspaceButton = ({
    workspaceId,
}: {
    workspaceId?: number;
}) => {
    const { search, state } = useLocation();

    const isOnboarding = Boolean(useRouteMatch([ROOT_ROUTES.workspaceJoin]));
    const workspaceCreationPath = isOnboarding
        ? {
              pathname: ROOT_ROUTES.workspaceCreate,
              search,
              state,
          }
        : generatePath(WORKSPACE_MANAGEMENT_ROUTES.workspaceCreate, {
              workspaceId,
          });

    return (
        <Link to={workspaceCreationPath}>
            <Button
                as="span"
                theme="secondary"
                text="Create workspace"
                icon={{
                    id: "arrow",
                    name: "Plus",
                    colour: "blue",
                }}
                data-userflow-id={UserflowIds.joinWorkspace.createNewButton}
            />
        </Link>
    );
};

const CreateNewWorkspaceCard = ({ workspaceId }: { workspaceId?: number }) => {
    return (
        <StyledCardItem>
            <Text skinny>
                If you cannot find a relevant workspace you can create one here.
            </Text>
            <CreateNewWorkspaceButton workspaceId={workspaceId} />
        </StyledCardItem>
    );
};

export const JoinWorkspacePage = () => {
    const history = useHistory<{ workspaceName?: string } | undefined>();

    const { selectedOrgId } = useSelectedOrganisation();

    const { user } = useCurrentUser();

    const currentWorkspace = user.organisations?.find(
        (w) => w.orgId === selectedOrgId,
    );

    const { orgId: workspaceId, organisationNodeName } = currentWorkspace ?? {};

    const { allowedOrganisation } = useContext(
        SelectedAllowedOrganisationContext,
    );

    const location = useLocation();

    const {
        data: allAllowedOrganisations = [],
        isLoading: isLoadingAllowedOrganisations,
    } = useGetAllowedNhsOrganisationsQuery();

    const existingNationalCodes =
        user?.organisations.map((item) => item.nationalCode) ?? [];

    // as allowed organisations endpoint now return all allowed user organisations,
    // for this page we would like to filter orgs, that user has already joined
    const allowedOrganisations = allAllowedOrganisations.filter(
        (org) => !existingNationalCodes.includes(org.nationalCode),
    );

    const isOnboarding = Boolean(useRouteMatch([ROOT_ROUTES.workspaceJoin]));

    const nationalCode =
        isOnboarding && allowedOrganisation
            ? allowedOrganisation.nationalCode
            : currentWorkspace?.nationalCode;

    const defaultOrgName =
        isOnboarding && allowedOrganisation
            ? allowedOrganisation.name
            : organisationNodeName || "";

    const track = useAnalytics();

    const pageWrapperRef = useRef<HTMLElement>(null);

    const [searchTerm, setSearchTerm] = useState("");

    const supportsMultipleWorkspaces = allowedOrganisation
        ? allowedOrganisation.metadata.supportsMultipleWorkspaces
        : currentWorkspace &&
          isFeatureEnabledForWorkspace(
              FeatureName.FlexibleWorkspace,
              currentWorkspace,
          );

    const { data: existingWorkspaces, status: workspacesQueryStatus } =
        useGetWorkspacesByNationalCodeQuery(
            { nationalCode, supportsMultipleWorkspaces },
            { refetchOnMount: true },
        );

    const { data: exampleWorkspaces, status: exampleWorkspacesStatus } =
        useGetExampleWorkspacesByNationalCodeQuery(
            { nationalCode, supportsMultipleWorkspaces },
            { refetchOnMount: true },
        );

    const isLoading = [workspacesQueryStatus, exampleWorkspacesStatus].includes(
        "loading",
    );

    // List does not include `exampleWorkspacesStatus` since if this errors, the rest of the page can still function fine
    const hasError = [workspacesQueryStatus].includes("error");

    const combinedWorkspaces = getCombinedWorkspaces({
        existingWorkspaces: existingWorkspaces?.workspaces ?? [],
        exampleWorkspaces: exampleWorkspaces?.exampleWorkspaces ?? [],
    });

    const filteredWorkspaces = useFuzzyFilter<
        CollaborativeWorkspaceResponse | ExampleWorkspace
    >(combinedWorkspaces, searchTerm, {
        threshold: 0.3,
        keys: ["name", "specialties", "description"],
    });

    const { currentPage, totalPages, onNext, onPrevious, goToPage, paginate } =
        usePagination({
            defaultPage: 1,
            defaultPageSize: 20,
            total: filteredWorkspaces.length,
        });

    const paginatedWorkspaces = paginate(filteredWorkspaces);

    const userTotalOrgs =
        user.organisations?.length + allowedOrganisations.length;

    const onboardingRedirectedPage =
        allowedOrganisations.length <= 1
            ? ROUTES.addOrganisation
            : ROUTES.joinOrganisation;

    const nonOnboardingRedirectPage = () => {
        if (!isOnboarding && userTotalOrgs <= 1) {
            return ROUTES.addOrganisation;
        }

        if (selectedOrgId === null) {
            toast(
                "Something went wrong. Please refresh the page and try again.",
            );
            return;
        }

        return generatePath(WORKSPACE_MANAGEMENT_ROUTES.selectOrganisation, {
            workspaceId: selectedOrgId,
        });
    };

    const redirectPage = isOnboarding
        ? onboardingRedirectedPage
        : nonOnboardingRedirectPage();

    useEffect(() => {
        if (!isLoading && !hasError) {
            track("WorkspaceJoin Page View", {});
        }
    }, [isLoading, hasError, track]);

    const handleBackClick = () => {
        history.goBack();
    };

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        goToPage(1);
        setSearchTerm(e.target.value);
    };

    const showPreviousResults = () => {
        onPrevious();
        pageWrapperRef.current?.scrollTo(0, 0);
    };

    const showNextResults = () => {
        onNext();
        pageWrapperRef.current?.scrollTo(0, 0);
    };

    if (!(allowedOrganisation && isOnboarding) && !currentWorkspace) {
        return (
            <Redirect to={{ ...location, pathname: ROUTES.joinOrganisation }} />
        );
    }

    if (!nationalCode) {
        Log.error("There is no national code to fetch workspaces for");
        return (
            <StyledPageWrapper>
                <StyledMain gap="3" flexDirection="column">
                    <Feedback colour="error" title="Something went wrong" />
                </StyledMain>
            </StyledPageWrapper>
        );
    }

    if (!supportsMultipleWorkspaces) {
        return (
            <StyledPageWrapper>
                <StyledMain gap="3" flexDirection="column">
                    <Feedback
                        colour="error"
                        title="This organisation does not support workspaces"
                    />
                </StyledMain>
            </StyledPageWrapper>
        );
    }

    return (
        <StyledPageWrapper ref={pageWrapperRef} flexDirection="column">
            {!isOnboarding && <BackButton backCallback={handleBackClick} />}
            <StyledMain gap="3" flexDirection="column">
                {hasError ? (
                    <Feedback
                        colour="error"
                        title="Sorry, there was a problem. Please refresh the page."
                    />
                ) : (
                    <>
                        <Text as="h1" variant="subtitle" skinny>
                            Join or create a workspace
                        </Text>
                        {isLoadingAllowedOrganisations ? (
                            <SkeletonLoader
                                width="450px"
                                height="25px"
                                backgroundColor={
                                    Tokens.COLOURS.greyscale.silver
                                }
                            />
                        ) : (
                            <Ds.Flex
                                gap="1"
                                flexDirection="row"
                                alignItems="center"
                            >
                                <StyledText variant="label" skinny>
                                    {defaultOrgName}
                                </StyledText>
                                {redirectPage && (
                                    <Link to={redirectPage}>
                                        <StyledUnderlinedText
                                            style={{
                                                fontSize: "1rem",
                                            }}
                                        >
                                            Change organisation
                                        </StyledUnderlinedText>
                                    </Link>
                                )}
                            </Ds.Flex>
                        )}

                        <Ds.Flex justifyContent="space-between">
                            <StyledTwoThirdsContainer>
                                <Text skinny>
                                    A workspace is a group of people at your
                                    organisation who would like to collaborate
                                    in Accurx e.g. your specialty.{" "}
                                    <StyledLink
                                        href={
                                            SUPPORT_ARTICLE_WORKSPACE_GUIDANCE
                                        }
                                        openInNewTab
                                    >
                                        <LinkText text="Find out more about workspaces" />
                                        <LinkIcon />
                                    </StyledLink>
                                </Text>
                            </StyledTwoThirdsContainer>
                            <CreateNewWorkspaceButton
                                workspaceId={workspaceId}
                            />
                        </Ds.Flex>

                        <Input
                            isSearchInput
                            placeholder={`Search workspaces at ${defaultOrgName}`}
                            onChange={handleSearch}
                            disabled={isLoading}
                            aria-label={`Search workspaces at ${defaultOrgName}`}
                        />
                        {isLoading ? (
                            <SkeletonList quantity={3} />
                        ) : (
                            <>
                                <PaginationLabel
                                    totalPages={totalPages}
                                    currentPage={currentPage}
                                    totalCount={filteredWorkspaces.length}
                                    appliedLimit={20}
                                />
                                <StyledCardList
                                    key={`${searchTerm}-${currentPage}`}
                                >
                                    {paginatedWorkspaces.map((workspace) => {
                                        const isExampleWorkspace =
                                            exampleWorkspaceTypeCheck(
                                                workspace,
                                            );

                                        return isExampleWorkspace ? (
                                            <ExampleWorkspaceItem
                                                key={`example-workspace-${workspace.name}`}
                                                name={workspace.name}
                                                specialtyId={
                                                    workspace.specialties[0].id
                                                }
                                                isRecommended={
                                                    workspace.isRecommended
                                                }
                                                nationalCode={nationalCode}
                                            />
                                        ) : (
                                            <ExistingWorkspaceItem
                                                id={workspace.id}
                                                key={workspace.id}
                                                name={workspace.name}
                                                description={
                                                    workspace.description
                                                }
                                                users={workspace.users}
                                                specialty={
                                                    workspace.specialties[0]
                                                }
                                                isRecommended={
                                                    workspace.isRecommended
                                                }
                                                defaultOrgName={defaultOrgName}
                                                currentWorkspaceId={workspaceId}
                                            />
                                        );
                                    })}
                                    {currentPage === totalPages && (
                                        <CreateNewWorkspaceCard
                                            workspaceId={workspaceId}
                                        />
                                    )}
                                </StyledCardList>
                                {filteredWorkspaces.length > 0 && (
                                    <PaginationControls
                                        currentPage={currentPage}
                                        totalPages={totalPages}
                                        onPrevious={showPreviousResults}
                                        onNext={showNextResults}
                                    />
                                )}
                            </>
                        )}
                    </>
                )}
            </StyledMain>
        </StyledPageWrapper>
    );
};
