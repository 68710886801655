import { ReactNode } from "react";

import { createRouterState as createRouterStatePatientForCompose } from "@accurx/compose";
import { ComposeNewMessageContext } from "@accurx/inbox";
import { PatientDemographics } from "@accurx/native";
import { useFullScreenNavigate } from "@accurx/navigation";
import { useLocation } from "react-router-dom";

export const ComposeNewMessageProvider = ({
    children,
}: {
    children: ReactNode;
}) => {
    const fullScreenNavigate = useFullScreenNavigate();
    const location = useLocation();

    const onNavigateToCompose = ({
        patientDemographics,
        workspaceId,
    }: {
        patientDemographics: PatientDemographics;
        workspaceId: number;
    }) => {
        const composeRouterState = createRouterStatePatientForCompose({
            patientDemographics,
            appOrigin: "Inbox",
        });

        fullScreenNavigate(`/w/${workspaceId}/compose/inbox-compose`, {
            ...(location.state ?? {}),
            ...composeRouterState,
        });
    };

    return (
        <ComposeNewMessageContext.Provider
            value={{
                onNavigateToCompose,
            }}
        >
            {children}
        </ComposeNewMessageContext.Provider>
    );
};
