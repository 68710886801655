import { Button, Card, Ds, Icon, Text } from "@accurx/design";

import {
    SkeletonBody,
    SkeletonLabel,
} from "app/sharedComponents/loadingSkeleton/SkeletonText";

import { Template } from "../../MessageTemplates.types";
import {
    StyledButtonsContainer,
    StyledTemplateContent,
    StyledTemplateDetails,
    StyledTemplateManagementCardContainer,
    StyledTruncatedText,
    StyledUseTemplateButton,
} from "./MessageTemplateCard.styles";

type MessageTemplateCardProps =
    | {
          template: Template;
          onClickUseTemplate: (template: Template) => void;
          isLoading?: false;
          onClickPreviewTemplate?: (template: Template) => void;
      }
    | {
          template?: never;
          onClickUseTemplate?: never;
          isLoading: true;
          onClickPreviewTemplate?: never;
      };

export const MessageTemplateCard = ({
    template,
    isLoading,
    onClickUseTemplate,
    onClickPreviewTemplate,
}: MessageTemplateCardProps): JSX.Element => {
    return (
        <StyledTemplateManagementCardContainer>
            <Card spacing={1.5}>
                <StyledTemplateDetails>
                    <StyledTemplateContent>
                        <div>
                            <Icon
                                name="Document"
                                theme="Line"
                                colour="night"
                                size={6}
                                halo={{
                                    colour: "blue",
                                }}
                            />
                        </div>
                        <div style={{ minWidth: 0 }}>
                            <Text variant="label" skinny>
                                {isLoading ? (
                                    <SkeletonLabel charCount={20} />
                                ) : (
                                    <>
                                        {template.title}{" "}
                                        {!!template.attachedDocuments
                                            ?.length && (
                                            <Ds.Badge color="greyscale">
                                                <Ds.Icon name="Paperclip" />
                                                Has attachment
                                            </Ds.Badge>
                                        )}
                                    </>
                                )}
                            </Text>
                            <StyledTruncatedText variant="body" skinny>
                                {isLoading ? (
                                    <SkeletonBody charCount={40} />
                                ) : (
                                    template.body
                                )}
                            </StyledTruncatedText>
                        </div>
                    </StyledTemplateContent>
                    <StyledButtonsContainer>
                        <Button
                            theme="secondary"
                            dimension="large"
                            text="Preview"
                            onClick={() => onClickPreviewTemplate?.(template)}
                            disabled={isLoading}
                        />
                        <StyledUseTemplateButton
                            theme="secondary"
                            dimension="large"
                            text="Use template"
                            icon={{ name: "Done" }}
                            disabled={isLoading}
                            onClick={() => onClickUseTemplate?.(template)}
                        />
                    </StyledButtonsContainer>
                </StyledTemplateDetails>
            </Card>
        </StyledTemplateManagementCardContainer>
    );
};
