import { SearchForPatientResponse } from "@accurx/api/portal";
import { createRouterState as createRouterStatePatientForCompose } from "@accurx/compose";
import { useFullScreenNavigate } from "@accurx/navigation";
import { shallowEqual, useDispatch } from "react-redux";
import { useHistory } from "react-router";

import FlemingApi from "api/FlemingApiClient";
import { actionCreators as searchForPatientActions } from "app/searchForPatient/SearchForPatientActions";
import { useNavigateToWorkspaceRoute } from "app/workspace/hooks";
import { OrganisationHelper } from "shared/OrganisationHelper";
import { PatientHelper } from "shared/PatientHelper";
import { ROUTES, ROUTES_BASE, ROUTES_EXTENSION } from "shared/Routes";
import { findBaseRoute } from "shared/RoutesHelper";
import { useAppSelector } from "store/hooks";

type SearchPatientArgs = {
    dateOfBirth: string;
    nhsNumber: string;
    organisationId: number;
};

type ViewPatientProfileArgs =
    | {
          shouldInvokePdsSearch: true;
          searchPatient: SearchPatientArgs;
          onError?: () => void;
      }
    | {
          shouldInvokePdsSearch: false;
          onError?: () => void;
      };

type ViewMessagePatientArgs =
    | {
          shouldInvokePdsSearch: true;
          searchPatient: SearchPatientArgs;
          onError?: () => void;
          organisationId: number;
      }
    | {
          shouldInvokePdsSearch: false;
          onError?: () => void;
          organisationId: number;
      };

export const useGetPatientDetailsActions = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { navigateToWorkspaceRoute } = useNavigateToWorkspaceRoute();

    const fullScreenNavigate = useFullScreenNavigate();
    const organisation = useAppSelector(
        ({ account }) => OrganisationHelper.getOrganisation(account),
        shallowEqual,
    );
    const patientResultFound = useAppSelector(
        ({ searchForPatient }) =>
            PatientHelper.getPatient(searchForPatient.lastResponse),
        shallowEqual,
    );
    const patientResultFoundToken = useAppSelector(({ searchForPatient }) =>
        PatientHelper.getPatientToken(searchForPatient.lastResponse),
    );

    const searchPatientAndSaveInStore = async ({
        dateOfBirth,
        nhsNumber,
        organisationId,
    }: SearchPatientArgs): Promise<{
        error: boolean;
        data?: SearchForPatientResponse;
    }> => {
        dispatch(searchForPatientActions.searchForPatientStarted());

        const date = new Date(dateOfBirth);
        // Triggers a search on the BE to retrieve patient token
        const res = await FlemingApi.searchForPatientByNhsNumber(
            organisationId,
            nhsNumber,
            date.getFullYear(),
            date.getMonth() + 1,
            date.getDate(),
            false,
        );

        // Save to recent search in the background, but don't wait for call to complete
        if (
            PatientHelper.getPatient(res) &&
            // don't save to recent searches if demo patient
            !PatientHelper.isDemoPatient(nhsNumber)
        ) {
            FlemingApi.savePatientToRecentSearches({
                organisationId,
                patientToken: res.searchedResult?.patientToken || "",
            });
        }

        dispatch(searchForPatientActions.searchForPatientFinished(res));

        return {
            error: !PatientHelper.getPatient(res),
            data: res,
        };
    };

    const onViewPatientProfile = async (
        viewPatientProfileParams: ViewPatientProfileArgs,
    ) => {
        if (viewPatientProfileParams.shouldInvokePdsSearch) {
            const { dateOfBirth, nhsNumber, organisationId } =
                viewPatientProfileParams.searchPatient;
            const { error } = await searchPatientAndSaveInStore({
                dateOfBirth,
                nhsNumber,
                organisationId,
            });
            if (error) {
                viewPatientProfileParams?.onError?.();
                return;
            }
        }

        navigateToWorkspaceRoute(
            `${findBaseRoute(history.location.pathname)}${
                ROUTES_EXTENSION.patientProfile
            }`,
        );
    };

    const onViewMessagePatient = async (
        viewMessagePatientParams: ViewMessagePatientArgs,
    ) => {
        if (viewMessagePatientParams.shouldInvokePdsSearch) {
            const { dateOfBirth, nhsNumber, organisationId } =
                viewMessagePatientParams.searchPatient;
            const { error } = await searchPatientAndSaveInStore({
                dateOfBirth,
                nhsNumber,
                organisationId,
            });
            if (error) {
                viewMessagePatientParams?.onError?.();
                return;
            }
        }

        if (
            viewMessagePatientParams.organisationId &&
            organisation?.orgId === viewMessagePatientParams.organisationId
        ) {
            fullScreenNavigate(
                `/w/${viewMessagePatientParams.organisationId}/compose`,
                createRouterStatePatientForCompose({
                    patient: patientResultFound,
                    patientToken: patientResultFoundToken,
                    appOrigin: "MessagePractice",
                }),
            );
            return;
        }

        const redirectPath = `/w/${viewMessagePatientParams.organisationId}${ROUTES_BASE.patientsSearch}${ROUTES_EXTENSION.patientProfile}/conversation/new`;

        viewMessagePatientParams.organisationId
            ? history.push(redirectPath)
            : history.push(ROUTES.joinOrganisation, { showBackButton: true });
    };

    return {
        searchPatientAndSaveInStore,
        viewPatientProfile: onViewPatientProfile,
        viewMessagePatient: onViewMessagePatient,
    };
};
