import { useReducer } from "react";

import { MedicalRecordConnection } from "@accurx/native";

import { DateInputValue } from "./DateInput/DateInput";

export const TEST_ID = {
    BASIC: "BasicSearch",
    BASIC_TWO_FACTORED: "BasicTwoFactoredSearch",
    ADVANCED: "AdvancedSearch",
    EMIS: "EmisSearch",
    EMR: "EmrSearch",
};

export const FORM_LABELS = {
    firstName: "First name",
    lastName: "Last name",
    postcode: "Post code",
    gender: "Gender",
    nhsNumber: "NHS number",
    date: "Date of birth (DD, MM, YYYY)",
    dateDay: "Date of birth (DD, MM, YYYY) day",
    dateMonth: "Date of birth (DD, MM, YYYY) month",
    dateYear: "Date of birth (DD, MM, YYYY) year",
    searchTerm: "Name, NHS number or date of birth",
};

export const FORM_ID = {
    BASIC: "PatientSearch-BasicForm",
    BASIC_TWO_FACTORED: "PatientSearch-BasicTwoFactoredForm",
    ADVANCED: "PatientSearch-AdvancedForm",
    EMIS: "PatientSearch-EmisForm",
    EMR: "PatientSearch-EmrForm",
};

export const FIELDS_ID = {
    FIRST_NAME: "firstName",
    LAST_NAME: "lastName",
    POSTCODE: "postcode",
    GENDER: "gender",
    NHS_NUMBER: "nhsNumber",
    DATE_OF_BIRTH: "dateOfBirth",
    SEARCH_TERM: "searchTerm",
} as const;

export const SEARCH_METHOD = {
    BASIC: "BASIC",
    ADVANCED: "ADVANCED",
} as const;

export type SearchMethod = keyof typeof SEARCH_METHOD;

export type EmrNhsSearchState = {
    nhsNumber: string;
};

export type EmrNameSearchState = {
    firstName: string;
    lastName: string;
};

export type EmrSearchState = EmrNhsSearchState | EmrNameSearchState;

export type EmisSearchState = {
    searchTerm: string;
};

export type AdvancedSearchState = {
    firstName: string;
    lastName: string;
    postcode: string;
    gender: string;
    dateOfBirth: DateInputValue;
};

export type BasicSearchState = {
    nhsNumber: string;
    dateOfBirth: DateInputValue;
};

export type BasicTwoFactoredSearchState = {
    nhsNumber: string;
};

const EMR_NHS: EmrNhsSearchState = {
    nhsNumber: "",
};

const EMR_NAME: EmrNameSearchState = {
    firstName: "",
    lastName: "",
};

const EMIS: EmisSearchState = {
    searchTerm: "",
};

const ADVANCED: AdvancedSearchState = {
    firstName: "",
    lastName: "",
    postcode: "",
    gender: "",
    dateOfBirth: { day: "", month: "", year: "" },
};

const BASIC: BasicSearchState = {
    nhsNumber: "",
    dateOfBirth: { day: "", month: "", year: "" },
};

const BASIC_TWO_FACTORED: BasicTwoFactoredSearchState = {
    nhsNumber: "",
};

export const INIT_STATE = {
    BASIC,
    BASIC_TWO_FACTORED,
    ADVANCED,
    EMIS,
    EMR_NHS,
    EMR_NAME,
} as const;

export const isBasicSearchState = (
    data: BasicSearchState | BasicTwoFactoredSearchState,
): data is BasicSearchState => {
    return "dateOfBirth" in data;
};

export const isEmrNhsSearchState = (
    data: EmrNhsSearchState | EmrNameSearchState,
): data is EmrNhsSearchState => {
    return "nhsNumber" in data;
};

export const FORM_ACTION = {
    UPDATE: "UPDATE",
    SET: "SET",
} as const;

export type FormAction<T> =
    | {
          type: typeof FORM_ACTION.UPDATE;
          payload: Partial<T>;
      }
    | {
          type: typeof FORM_ACTION.SET;
          payload: T;
      };

const formReducer = <
    T extends
        | BasicSearchState
        | BasicTwoFactoredSearchState
        | AdvancedSearchState
        | EmisSearchState
        | EmrSearchState,
>(
    state: T,
    action: FormAction<T>,
): T => {
    switch (action.type) {
        case "UPDATE":
            return {
                ...state,
                ...action.payload,
            };
        case "SET":
            return action.payload;
    }
    return state;
};

export const useFormReducer = <
    T extends
        | BasicSearchState
        | BasicTwoFactoredSearchState
        | AdvancedSearchState
        | EmisSearchState
        | EmrSearchState,
>(
    initialState: T,
) => useReducer<React.Reducer<T, FormAction<T>>>(formReducer, initialState);

export const sanitise = (string: string) => string.replace(/[^0-9]/g, "");

export const TEST_PATIENT_DATA: BasicSearchState = {
    nhsNumber: "7777777777",
    dateOfBirth: { day: "16", month: "07", year: "1984" },
};

export const ADVANCED_TEST_PATIENT_DATA = {
    firstName: "Dwayne",
    lastName: "CASE",
    postcode: "HD4 5ET",
    gender: "Male",
    dateOfBirth: { day: "26", month: "02", year: "1935" },
};

export const isTestPatient = (nhsNumber: string) =>
    nhsNumber === TEST_PATIENT_DATA.nhsNumber;

export const isTestPatientInFormData = (
    basicForm: BasicSearchState | BasicTwoFactoredSearchState,
    advancedForm: AdvancedSearchState,
) =>
    basicForm.nhsNumber === "7777777777" || advancedForm.lastName === "EXAMPLE";

export const getSearchFormLabel = ({
    system,
    searchMethod,
    isApprovedAnd2FAed,
}: {
    system: MedicalRecordConnection["system"];
    searchMethod: SearchMethod;
    isApprovedAnd2FAed: boolean;
}) => {
    if (system === "Emis") {
        return "SearchByEmis";
    } else if (system === "SystmOne") {
        return "SearchBySystmOne";
    } else if (system === "Vision") {
        return "SearchByVision";
    } else if (searchMethod === "ADVANCED") {
        return "SearchByName";
    } else if (isApprovedAnd2FAed) {
        return "SearchByNHSNumberOnly";
    } else {
        return "SearchByNHSNumber";
    }
};
