import { generatePath } from "react-router";

import { ROUTES_WORKSPACE } from "shared/Routes";

type CreateProps = {
    orgId: number;
    isOrgTemplate: boolean;
};
type EditProps = {
    orgId: number;
    templateId: string;
    isOrgTemplate: boolean;
};
type CopyToPracticeProps = {
    orgId: number;
    templateId: string;
};

export const routeToCreateWebTemplate = ({
    orgId,
    isOrgTemplate,
}: CreateProps): string =>
    generatePath(ROUTES_WORKSPACE.workspaceCreateTemplate, {
        workspaceId: orgId,
    }) + `?isWorkspaceTemplate=${isOrgTemplate}`;

export const routeToEditWebTemplate = ({
    orgId,
    templateId,
    isOrgTemplate,
}: EditProps): string =>
    generatePath(ROUTES_WORKSPACE.workspaceEditTemplate, {
        workspaceId: orgId,
        templateId,
        action: "edit",
    }) + `?isWorkspaceTemplate=${isOrgTemplate}`;

export const routeToCopyWebTemplates = ({
    orgId,
    templateId,
}: CopyToPracticeProps): string =>
    generatePath(ROUTES_WORKSPACE.workspaceCopyTemplate, {
        workspaceId: orgId,
        templateId,
        action: "copy",
    }) + `?isWorkspaceTemplate=true`;
