import { usePatient } from "@accurx/concierge/hooks/data/usePatient";
import { PatientSummary } from "@accurx/concierge/types";
import { Ds } from "@accurx/design";
import { formatPatientName } from "@accurx/patient";
import { MessageNameTag } from "domains/inbox/components/ConversationItem/components/MessageNameTag/MessageNameTag";
import { PatientNotFound } from "domains/inbox/components/PatientNotFound/PatientNotFound";

type PatientNameTagProps = {
    patientId: PatientSummary["patientId"] | undefined;
};

export const PatientNameTag = ({ patientId }: PatientNameTagProps) => {
    const patient = usePatient({ patientId });

    if (!patientId || !patient) {
        return <PatientNotFound />;
    }

    return (
        <MessageNameTag
            avatar={<Ds.Avatar size="small" color="blue" />}
            name={formatPatientName({
                firstName: patient.firstName,
                familyName: patient.familyName,
                prefixName: patient.prefixName,
            })}
        />
    );
};
