import { useCurrentWorkspace } from "@accurx/auth";
import {
    NativeTransportError,
    queryPatientDemographics,
    useNativeTransport,
} from "@accurx/native";
import { Log } from "@accurx/shared";
import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import { PatientSearchResult } from "domains/patient/types";
import { mapPatientDemographicsToPatientSearchResult } from "domains/patient/utils/format/mappers";

type MutationVariables = {
    externalIds: PatientSearchResult["externalIds"];
};

export const GetFullEmrPatientDetailsKey = "GetFullEmrPatientDetails";

export const useGetFullEmrPatientDetails = (
    options: UseMutationOptions<
        PatientSearchResult,
        NativeTransportError,
        MutationVariables
    >,
) => {
    const transport = useNativeTransport();
    const workspaceId = useCurrentWorkspace().orgId;

    return useMutation(
        [GetFullEmrPatientDetailsKey],
        async (variables: MutationVariables) => {
            if (!transport) {
                throw new NativeTransportError(
                    "useGetFullEmrPatientDetails: Called with no native transport",
                );
            }

            if (!variables.externalIds) {
                const message =
                    "useGetFullEmrPatientDetails: No externalIds passed to search for patient";
                Log.warn(message);
                throw new Error(message);
            }

            const result = await queryPatientDemographics(
                { transport, workspaceId },
                {
                    patientExternalIdentityDto: {
                        patientExternalIds: variables.externalIds,
                    },
                    pushToServer: false,
                },
            );

            return mapPatientDemographicsToPatientSearchResult(
                result.patientDemographics,
            );
        },
        { ...options },
    );
};
