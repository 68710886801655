import { PatientSearchResult } from "@accurx/patient";
import { getRouteFromComposeVariant } from "domains/compose/utils/getRouteFromComposeVariant";
import { generatePath, useHistory } from "react-router-dom";

import { AppOriginType, ComposeVariant } from "../Routes.types";

export const handleSelectPatient = ({
    result,
    history,
    workspaceId,
    composeVariant,
    appOrigin,
}: {
    workspaceId: number;
    result: PatientSearchResult | null;
    history: ReturnType<typeof useHistory>;
    composeVariant: ComposeVariant;
    appOrigin?: AppOriginType;
}) => {
    if (result) {
        const route = getRouteFromComposeVariant(composeVariant);

        history.push(
            generatePath(route, {
                workspaceId,
            }),
            { searchedPatient: result, appOrigin },
        );
    }
};
