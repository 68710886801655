import * as UI from "@accurx/design";
import { Pill } from "@accurx/inbox-design-library";
import styled from "styled-components";

export type Theme = "primary" | "secondary";
type ThemeStyle = { [T in Theme]: string };

const { primary, greyscale } = UI.Tokens.COLOURS;

const buttonColour: ThemeStyle = {
    primary: primary.blue[100],
    secondary: greyscale.white,
};
const buttonBorderColour: ThemeStyle = {
    primary: primary.blue[130],
    secondary: greyscale.silver,
};
const buttonHoverCover: ThemeStyle = {
    primary: primary.blue[130],
    secondary: greyscale.white,
};

const buttonHoverShadow: ThemeStyle = {
    primary: `inset 0 -2px 0 ${primary.blue[130]}, inset 0 0 0 1px ${primary.blue[130]}`,
    secondary: `inset 0 -2px 0 ${primary.blue[100]}, inset 0 0 0 1px ${primary.blue[100]}`,
};

const blurShadow = "0px 4px 16px 0px rgba(68, 72, 126, 0.32)";

export const StyledPillButton = styled(Pill.Button)<{ $theme: Theme }>`
    border-color: ${({ $theme }) => buttonBorderColour[$theme]};
    background-color: ${({ $theme }) => buttonColour[$theme]};
    box-shadow: ${blurShadow};
    padding-inline: 0.75rem;

    &:focus,
    &:active {
        border: 1px solid ${greyscale.metal};
        box-shadow: 0 0 0 1px ${greyscale.metal}, 0 0 0 3px #${greyscale.white},
            0 0 0 5px ${primary.blue[100]};
        background: ${({ $theme }) => buttonColour[$theme]};
    }

    &:hover:not(:focus, :disabled) {
        background: ${({ $theme }) => buttonHoverCover[$theme]};
        box-shadow: ${blurShadow}, ${({ $theme }) => buttonHoverShadow[$theme]};
    }

    &:disabled {
        border: none;
        background-color: ${greyscale.cloud};
        box-shadow: ${blurShadow}, inset 0 -2px 0 ${greyscale.silver},
            inset 0 0 0 1px ${greyscale.silver};
    }
`;
