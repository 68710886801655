import { FeatureName, useCurrentWorkspace, useFeatureFlag } from "@accurx/auth";
import { useIsGpBatchEnabled } from "@accurx/batch-messaging";
import { Ds } from "@accurx/design";
import { AppOrigin } from "@accurx/message-component";
import {
    NavTooltip,
    StyledDropdownMenuContent,
    StyledDropdownMenuItem,
} from "@accurx/navigation";
import { ArchivedWorkspaceHiddenItemWrapper } from "@accurx/workspace-management";
import { StyledMessageButton } from "layouts/UnifiedLayout/UnifiedLayout.styles";
import { userflowIds } from "layouts/constants";
import { Link, generatePath, useHistory } from "react-router-dom";
import { useAnalytics } from "reduxQuarantine/useAnalytics";

import { ROUTES_WORKSPACE } from "shared/Routes";

type NewMessageDropdownProps = {
    openVideoConsultModal: () => void;
    hideText?: boolean;
};

export const NewMessageDropdown = ({
    openVideoConsultModal,
    hideText,
}: NewMessageDropdownProps) => {
    const { orgId: workspaceId, latestIntegratedSystem } =
        useCurrentWorkspace();
    const history = useHistory();
    const track = useAnalytics();

    const canMessageCareProviders = useFeatureFlag(
        FeatureName.CaregiverInitiated,
    );
    const isTrustBatchEnabled = useFeatureFlag(FeatureName.WebBatchMessaging);
    const isGpBatchEnabled = useIsGpBatchEnabled();

    const showBatchButton = latestIntegratedSystem
        ? isGpBatchEnabled
        : isTrustBatchEnabled;

    const isVideoConsultEnabled = useFeatureFlag(FeatureName.VideoConsultWeb);

    const batchPath = generatePath(
        latestIntegratedSystem
            ? ROUTES_WORKSPACE.batchMessageGpChoose
            : ROUTES_WORKSPACE.batchMessageTrustUpload,
        { workspaceId },
    );

    const handleOpenChange = (open: boolean) => {
        if (open) {
            track("NavigationMenu Button Click", {
                origin: history.location.pathname,
                navigationOptionSelected: "NewMessage",
                navigationVersion: "Unified",
                eventVersion: 2,
            });
        }
    };

    const handleVideoConsultOpen = () => {
        handleMenuItemClick("VideoInvite");
        openVideoConsultModal();
    };

    const handleMenuItemClick = (
        optionSelected:
            | "MessagePatient"
            | "MessagePractice"
            | "VideoInvite"
            | "BatchMessage",
    ) => {
        track("NavigationMessage MenuItem Click", {
            navigationVersion: "Unified",
            origin: history.location.pathname,
            optionSelected,
        });
    };

    const composeAppOrigin = AppOrigin.NavigationNewMessageDropdown;

    return (
        <Ds.DropdownMenu onOpenChange={handleOpenChange} modal={false}>
            <NavTooltip align="start" text="New message options">
                <Ds.DropdownMenu.Trigger asChild>
                    <StyledMessageButton
                        icon={{
                            name: "Plus",
                            title: "New message options",
                            colour: "green",
                        }}
                        text={hideText ? undefined : "New"}
                        data-userflow-id={
                            userflowIds.navigation.primary.newMessage
                        }
                    />
                </Ds.DropdownMenu.Trigger>
            </NavTooltip>

            <StyledDropdownMenuContent
                side="bottom"
                sideOffset={4}
                align="start"
            >
                <Ds.DropdownMenu.Item asChild>
                    <StyledDropdownMenuItem
                        as={Link}
                        to={{
                            pathname: `/w/${workspaceId}/compose/search`,
                            state: {
                                originLocation: history.location,
                                appOrigin: composeAppOrigin,
                            },
                        }}
                        onClick={() => handleMenuItemClick("MessagePatient")}
                        data-userflow-id={
                            userflowIds.navigation.primary.newMessagePatient
                        }
                    >
                        <Ds.Icon
                            name="preview-Message"
                            size="small"
                            color="stone"
                        />
                        <Ds.Text weight="bold">Message patient</Ds.Text>
                    </StyledDropdownMenuItem>
                </Ds.DropdownMenu.Item>
                <ArchivedWorkspaceHiddenItemWrapper>
                    {canMessageCareProviders && (
                        <Ds.DropdownMenu.Item asChild>
                            <StyledDropdownMenuItem
                                as={Link}
                                to={{
                                    pathname: `/w/${workspaceId}/compose/search`,
                                    search: "?composeVariant=Clinician",
                                    state: {
                                        originLocation: history.location,
                                        appOrigin: composeAppOrigin,
                                    },
                                }}
                                onClick={() =>
                                    handleMenuItemClick("MessagePractice")
                                }
                                data-userflow-id={
                                    userflowIds.navigation.primary.newMessageGp
                                }
                            >
                                <Ds.Icon
                                    name="preview-MessageHealthProfessional"
                                    size="small"
                                    color="stone"
                                />
                                <Ds.Text weight="bold">Message GP</Ds.Text>
                            </StyledDropdownMenuItem>
                        </Ds.DropdownMenu.Item>
                    )}
                </ArchivedWorkspaceHiddenItemWrapper>
                {isVideoConsultEnabled && (
                    <StyledDropdownMenuItem
                        onSelect={handleVideoConsultOpen}
                        data-userflow-id={
                            userflowIds.navigation.primary
                                .newMessageVideoConsult
                        }
                    >
                        <Ds.Icon name="Video" size="small" color="stone" />
                        <Ds.Text weight="bold">Video consult</Ds.Text>
                    </StyledDropdownMenuItem>
                )}
                {showBatchButton && (
                    <ArchivedWorkspaceHiddenItemWrapper>
                        <Ds.DropdownMenu.Item asChild>
                            <StyledDropdownMenuItem
                                as={Link}
                                to={{
                                    pathname: batchPath,
                                    state: { from: history.location },
                                }}
                                onClick={() =>
                                    handleMenuItemClick("BatchMessage")
                                }
                                data-userflow-id={
                                    userflowIds.navigation.primary
                                        .newMessageBatch
                                }
                            >
                                <Ds.Icon
                                    name="Messages"
                                    size="small"
                                    color="stone"
                                />
                                <Ds.Text weight="bold">Batch message</Ds.Text>
                            </StyledDropdownMenuItem>
                        </Ds.DropdownMenu.Item>
                    </ArchivedWorkspaceHiddenItemWrapper>
                )}
            </StyledDropdownMenuContent>
        </Ds.DropdownMenu>
    );
};
