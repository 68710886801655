import { useEffect, useRef } from "react";

import { useNativeSubscription } from "@accurx/native";

export const useWindowOpenCloseEvents = ({
    onOpen,
    onClose,
}: {
    onOpen: () => void;
    onClose: () => void;
}) => {
    const { data } = useNativeSubscription("SubscribeWindowOpenStatus");
    const onOpenRef = useRef(onOpen);
    const onCloseRef = useRef(onClose);

    const isOpen = data?.open;

    useEffect(() => {
        if (isOpen === undefined) {
            return;
        }

        if (isOpen === true) {
            onOpenRef.current();
        } else {
            onCloseRef.current();
        }
    }, [isOpen]);
};
