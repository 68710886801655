import { useState } from "react";

import {
    AppointmentChangeDeadline,
    AppointmentTypeFilter,
} from "@accurx/api/appointment";
import { FeatureName, useFeatureFlag } from "@accurx/auth";
import { useErrorSummary } from "@accurx/design";
import { z } from "zod";

import { MAX_SMS_CHAR_LIMIT } from "../constants";

export const reduceReminderMessage = `Reduce the message below ${
    MAX_SMS_CHAR_LIMIT + 1
} characters`;
export const reducePostAppointmentMessage = `Reduce the post-appointment message below ${
    MAX_SMS_CHAR_LIMIT + 1
} characters`;

const validationRules = {
    selectedSiteNames: z
        .array(z.string())
        .nonempty({ message: "Select at least one site location" }),
    selectedSlotTypes: z
        .array(z.object({ value: z.string() }))
        .nonempty({ message: "Select at least one slot type" }),
    selectedClinics: z
        .array(z.object({ value: z.string() }))
        .nonempty({ message: "Select at least one clinic" }),
    selectedAppointmentType: z.nativeEnum(AppointmentTypeFilter, {
        errorMap: () => ({ message: "Select an appointment type" }),
    }),
    reminderMessageCharacterCount: z.number().max(MAX_SMS_CHAR_LIMIT, {
        message: reduceReminderMessage,
    }),
    postAppointmentMessageBody: z.string().min(1, {
        message:
            "Write the message you want to send to patients after their appointment",
    }),
    postAppointmentMessageCharacterCount: z.number().max(MAX_SMS_CHAR_LIMIT, {
        message: reducePostAppointmentMessage,
    }),
};

export const changeDeadlineAndRemindersSchema = z
    .object({
        threeDaysEnabled: z.boolean(),
        oneWeekEnabled: z.boolean(),
        changeDeadline: z.nativeEnum(AppointmentChangeDeadline).optional(),
    })
    .refine(
        ({ changeDeadline, threeDaysEnabled, oneWeekEnabled }) => {
            switch (changeDeadline) {
                case AppointmentChangeDeadline.OneWorkingDay:
                    return threeDaysEnabled || oneWeekEnabled;
                case AppointmentChangeDeadline.ThreeWorkingDays:
                    return oneWeekEnabled;
                default:
                    return true;
            }
        },
        {
            path: ["changeDeadline"],
            message:
                "Deadline must be later than the earliest scheduled reminder",
        },
    );

export const commonFieldsSchema = z
    .object({
        reminderMessageCharacterCount:
            validationRules.reminderMessageCharacterCount,
    })
    .and(
        z.discriminatedUnion("postAppointmentMessageEnabled", [
            z.object({
                postAppointmentMessageEnabled: z.literal(false),
                postAppointmentMessageCharacterCount: z.number(),
                postAppointmentMessageBody: z.string(),
            }),
            z.object({
                postAppointmentMessageEnabled: z.literal(true),
                postAppointmentMessageCharacterCount:
                    validationRules.postAppointmentMessageCharacterCount,
                postAppointmentMessageBody:
                    validationRules.postAppointmentMessageBody,
            }),
        ]),
    );

export const clinicsSchema = commonFieldsSchema.and(
    z.object({
        selectedSlotTypes: validationRules.selectedClinics,
        selectedAppointmentType: validationRules.selectedAppointmentType,
    }),
);

export const siteNamesAndSlotTypesSchema = commonFieldsSchema.and(
    z.object({
        selectedSiteNames: validationRules.selectedSiteNames,
        selectedSlotTypes: validationRules.selectedSlotTypes,
    }),
);

type ValidationSchema =
    | typeof commonFieldsSchema
    | typeof clinicsSchema
    | typeof siteNamesAndSlotTypesSchema
    | typeof changeDeadlineAndRemindersSchema;

type AvailableFilters = "site-name-slot-type" | "clinic-appointment-type";

const initialValuesCommonSchema = z.object({
    selectedSlotTypes: z
        .array(z.object({ label: z.string(), value: z.string() }))
        .nonempty(),
    templateType: z.enum(["FaceToFace", "Telephone"]),
    customMessage: z.string(),
    oneWeekEnabled: z.boolean(),
    threeDaysEnabled: z.boolean(),
    postAppointmentMessageEnabled: z.boolean(),
    postAppointmentMessageBody: z.string(),
});

const initialValuesWithSiteAndSlotTypeFiltersSchema =
    initialValuesCommonSchema.merge(
        z.object({
            selectedAppointmentType: z.undefined(),
            selectedSiteNames: z.array(z.string()).nonempty(),
        }),
    );

const initialValuesWithClinicCodeAndAppointmentTypeFiltersSchema =
    initialValuesCommonSchema.merge(
        z.object({
            selectedAppointmentType: z.enum(["FaceToFace", "Telephone"]),
            selectedSiteNames: z.array(z.string()).max(0),
        }),
    );

type InitialValuesValidationSchema =
    | typeof initialValuesWithSiteAndSlotTypeFiltersSchema
    | typeof initialValuesWithClinicCodeAndAppointmentTypeFiltersSchema;

const getInitialValuesValidationSchemaForAvailableFilters = (
    availableFilters: AvailableFilters,
): InitialValuesValidationSchema => {
    switch (availableFilters) {
        case "site-name-slot-type":
            return initialValuesWithSiteAndSlotTypeFiltersSchema;
        case "clinic-appointment-type":
            return initialValuesWithClinicCodeAndAppointmentTypeFiltersSchema;
    }
};

export const validateInitialFormData = (
    formValues: unknown,
    availableFilters: AvailableFilters,
) => {
    const parseResult =
        getInitialValuesValidationSchemaForAvailableFilters(
            availableFilters,
        ).safeParse(formValues);

    if (parseResult.success) {
        return { success: true };
    }

    const flattenedErrors = parseResult.error.flatten();
    const errors: Record<string, string> = {};
    Object.entries(flattenedErrors.fieldErrors).forEach(([key, value]) => {
        errors[key] = value.join(", ");
    });

    if (flattenedErrors.formErrors.length) {
        errors.formErrors = flattenedErrors.formErrors.join(", ");
    }

    return { success: false, errors };
};

const partialSchema = z.object(validationRules).partial();

export type RawFormData = {
    selectedSiteNames: string[];
    selectedSlotTypes: Array<{ value: string }> | string[];
    selectedAppointmentType: string;
    reminderMessageCharacterCount: number;
    postAppointmentMessageEnabled: boolean;
    postAppointmentMessageBody: string;
    postAppointmentMessageCharacterCount: number;
    /** @TODO make this required once the appointment changes feature flag is removed */
    changeDeadline?: AppointmentChangeDeadline;
    threeDaysEnabled: boolean;
    oneWeekEnabled: boolean;
};

type ValidationErrors = Partial<Record<keyof RawFormData, string[]>>;

type FieldsWithVisibleError = Exclude<
    keyof ValidationErrors,
    "postAppointmentMessageEnabled"
>;

const formFieldIds: Record<FieldsWithVisibleError, string> = {
    selectedSiteNames: "site_names",
    selectedSlotTypes: "slot_types",
    selectedAppointmentType: "appointment-type-filter",
    reminderMessageCharacterCount: "message-body",
    postAppointmentMessageBody: "post-appointment-message",
    postAppointmentMessageCharacterCount: "post-appointment-message",
    changeDeadline: "appointmentChangeDeadline",
    threeDaysEnabled: "three-days-reminder-checkbox",
    oneWeekEnabled: "one-week-reminder-checkbox",
};

export const isValid = (schema: ValidationSchema, rawFormData: RawFormData) => {
    const parseResult = schema.safeParse(rawFormData);

    if (parseResult.success) {
        return {
            success: true,
        };
    }

    return {
        success: false,
        error: parseResult.error.flatten().fieldErrors,
    };
};

const getSchemaForAvailableFilters = (
    availableFilters: AvailableFilters,
): ValidationSchema => {
    switch (availableFilters) {
        case "site-name-slot-type":
            return siteNamesAndSlotTypesSchema;
        case "clinic-appointment-type":
            return clinicsSchema;
    }
};

export const useFormValidation = (availableFilters: AvailableFilters) => {
    const isAppointmentChangesFeatureEnabled = useFeatureFlag(
        FeatureName.AppointmentRemindersConfigAmendmentsUIv1,
    );
    const { clearAllErrors, addMultipleErrorsByKey } = useErrorSummary();
    const [hasValidationErrors, setHasValidationErrors] = useState(false);
    const [validationErrors, setValidationErrors] = useState<ValidationErrors>(
        {},
    );

    const addErrorsToSummary = (errors: ValidationErrors) => {
        (Object.keys(errors) as FieldsWithVisibleError[]).forEach(
            (fieldName) => {
                if (errors[fieldName]?.length) {
                    errors[fieldName]?.forEach((errorMessage) => {
                        addMultipleErrorsByKey({
                            [fieldName]: {
                                id: formFieldIds[fieldName],
                                body: errorMessage,
                            },
                        });
                    });
                }
            },
        );
    };

    return {
        hasValidationErrors,
        validationErrors,
        validate: (rawFormData: RawFormData) => {
            clearAllErrors();
            setHasValidationErrors(false);
            setValidationErrors({});

            let schema = getSchemaForAvailableFilters(availableFilters);

            /** Once this feature flag is ready to be removed the changeDeadlineSchema should be added to the commonFieldsSchema */
            if (isAppointmentChangesFeatureEnabled) {
                schema = schema.and(
                    changeDeadlineAndRemindersSchema,
                ) as unknown as ValidationSchema;
            }

            const validationResult = isValid(schema, rawFormData);
            if (validationResult.error) {
                addErrorsToSummary(validationResult.error);
                setHasValidationErrors(true);
                setValidationErrors(validationResult.error);
            }

            return validationResult;
        },
        /**
         * Validate a subset of form fields.
         * Useful for onChange validation.
         */
        validatePartial: (
            partialFormValues:
                | z.infer<typeof partialSchema>
                | z.infer<typeof changeDeadlineAndRemindersSchema>,
        ) => {
            const schema =
                isAppointmentChangesFeatureEnabled &&
                "changeDeadline" in partialFormValues
                    ? changeDeadlineAndRemindersSchema
                    : partialSchema;
            const validationResult = schema.safeParse(partialFormValues);

            setValidationErrors((prevErrors) => {
                const nextErrors = { ...prevErrors };
                // Clear existing errors for validated fields
                (
                    Object.keys(partialFormValues) as Array<keyof RawFormData>
                ).forEach((fieldName) => {
                    nextErrors[fieldName] = undefined;
                });

                if (validationResult.success) {
                    return nextErrors;
                }

                // Add new errors from partial validation
                return {
                    ...nextErrors,
                    ...validationResult.error.flatten().fieldErrors,
                };
            });
        },
    };
};
