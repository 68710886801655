import {
    BasicUserResponse,
    CollaborativeWorkspaceResponse,
    ExampleWorkspace,
} from "@accurx/api/account";
import orderBy from "lodash/orderBy";

/**
 * Sorts user list so that the current user appears first in the list
 */
export const sortUsersByCurrentUser = ({
    currentUserIndex,
    users,
}: {
    currentUserIndex: number;
    users: BasicUserResponse[];
}): BasicUserResponse[] => {
    const usersList = [...users];

    const currentUser = usersList.splice(currentUserIndex, 1);
    usersList.unshift(currentUser[0]);

    return usersList;
};

/**
 * Combines existing workspaces and example workspaces in one list
 * Returns the combined list,
 * with any recommended specialties displayed first,
 * any workspace strating from  non A-Z or 0-9 symbols displayed last,
 * and then sorted in alphabetical order (A-Z)
 */
export const getCombinedWorkspaces = ({
    existingWorkspaces,
    exampleWorkspaces,
}: {
    existingWorkspaces: CollaborativeWorkspaceResponse[];
    exampleWorkspaces: ExampleWorkspace[];
}) => {
    const allWorkspaces = [...existingWorkspaces, ...exampleWorkspaces];

    return orderBy(
        allWorkspaces,
        [
            (workspace) => {
                return /^[^a-zA-Z0-9]/.test(workspace.name.toLowerCase());
            },
            (workspace) => workspace.isRecommended === true,
            (workspace) => workspace.name.toLowerCase(),
        ],
        ["asc", "desc", "asc"],
    );
};
