import React, { useEffect, useState } from "react";

import {
    Accordion,
    Card,
    Ds,
    Feedback,
    Icon,
    Text,
    TextareaAutosize,
    Tokens,
} from "@accurx/design";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { generatePath, useHistory } from "react-router-dom";
import { useSelectedOrganisation } from "reduxQuarantine/SelectedOrganisationProvider";

import { ChainAnalyticsTracker } from "app/analytics";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { StepsFooter } from "app/sharedComponents/footer/StepsFooter";
import { UpdatingStatus } from "shared/LoadingStatus";
import { ROUTES_WORKSPACE } from "shared/Routes";

import { MaxBatchSMSSize, MaxSingleSMSSize } from "../Vaccine.type";
import {
    calculateMessageLength,
    characterCountWarning,
    defaultInviteMessage,
    linkAutogenerateCopy,
    patientGreetingCopy,
} from "../VaccineCopy";
import {
    VaccineDeliveryItem,
    VaccineExistingPatientState,
} from "../models/VaccineDeliveryDTO";
import { VaccineDeliveryHeader } from "../shared/VaccineDeliveryComponents";
import { sendMessage } from "../vaccineInvitesOldPage/vaccineDelivery.actions";
import { initialState as VaccineAllInvitesInitialState } from "../vaccineInvitesOldPage/vaccineDelivery.reducer";
import { VaccineDeliveryComposePatientTable } from "./VaccineDeliveryComposePatientTable";
import {
    getNonTextablePatientsWhoNeedFirstJab,
    getTextablePatientsWhoNeedFirstJab,
    listHasOnlyNonTextablePatientsWhoNeedFirstJab,
    listHasOnlyPatientsWhoHaveHadSecondJab,
    listHasOnlyPatientsWhoNeedSecondJab,
} from "./VaccineDeliveryUploadAndComposeHelpers";

export const renderConfirmCopy = (
    nonTextableFirstJabPatientsOnly: boolean,
    secondJabPatientsOnly: boolean,
    hadBothPatientsOnly: boolean,
): JSX.Element => {
    let headingCopy;
    let explanationCopy;
    if (nonTextableFirstJabPatientsOnly) {
        headingCopy = "To manually book (1st invites):";
        explanationCopy =
            "You have not uploaded anyone with with a mobile number that will be sent a text invite to their first vaccination. Click 'upload' below to add these patients to accuBook to then manually book them in.";
    } else if (secondJabPatientsOnly) {
        headingCopy =
            "All patients for 2nd invites (no patients for 1st invites):";
        explanationCopy =
            "All of the patients you've selected have already had their first vaccinations (according to national vaccination data). Click 'upload' below to add these patients to accuBook to then invite them for their second vaccinations.";
    } else if (hadBothPatientsOnly) {
        headingCopy = "All patients have had all primary vaccinations:";
        explanationCopy =
            "All of the patients you've selected have already had all of their primary vaccinations (according to national vaccination data). Click ‘upload’ below to add these patients to accuBook, these patients will appear under the ‘Had all’ filter.";
    } else {
        headingCopy =
            "Patients in To manually book, 2nd invites or Had primary:";
        explanationCopy =
            "You have not uploaded anyone with with a mobile number that will be sent a text invite to their first vaccination. Click 'upload' below to add these patients to accuBook to then manually book them in, or to then invite them for their second vaccinations.";
    }
    return (
        <>
            <Text as="h2" variant="label">
                {headingCopy}
            </Text>
            <Text>{explanationCopy}</Text>
        </>
    );
};

export const VaccineDeliveryComposeFirst = (): JSX.Element => {
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    const { selectedOrgId } = useSelectedOrganisation();
    const practiceId = useSelector(
        ({ practices }: ApplicationState) => practices.selectedPractice,
    );
    const practiceName = useSelector(
        ({ practices }: ApplicationState) =>
            practices?.items.find(
                (x) => x.id.toString() === practices.selectedPractice,
            )?.name,
        shallowEqual,
    );

    const patientList = useSelector(
        ({ vaccineDelivery }: ApplicationState) =>
            vaccineDelivery?.vaccineDeliveryDetails.items ||
            VaccineAllInvitesInitialState.vaccineDeliveryDetails.items,
        shallowEqual,
    );
    const vaccineDeliveryId = useSelector(
        ({ vaccineDelivery }: ApplicationState) =>
            vaccineDelivery?.vaccineDeliveryId ||
            VaccineAllInvitesInitialState.vaccineDeliveryId,
        shallowEqual,
    );
    const patientMessage = useSelector(
        ({ vaccineDelivery }: ApplicationState) =>
            vaccineDelivery?.patientMessage ||
            VaccineAllInvitesInitialState.patientMessage,
        shallowEqual,
    );
    const sendingStatus = useSelector(
        ({ vaccineDelivery }: ApplicationState) =>
            vaccineDelivery?.sendingStatus ||
            VaccineAllInvitesInitialState.sendingStatus,
        shallowEqual,
    );

    const history = useHistory();
    const dispatch = useDispatch();

    const [messageBody, setMessageBody] = useState<string>(patientMessage);
    const [validMessageBody, setValidMessageBody] = useState<boolean>(false);
    const [messageErrorMessage, setMessageErrorMessage] = useState<string>(
        characterCountWarning,
    );
    const [characterCount, setCharacterCount] = useState<number>(0);
    const [sendingFailedMessage, setSendingFailedMessage] =
        useState<string>("");

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        checkMessageTextAndSetState(messageBody);
    }, [messageBody]);

    useEffect(() => {
        if (sendingStatus === UpdatingStatus.Loaded) {
            history.push(
                generatePath(ROUTES_WORKSPACE.accubookInvitesSelect, {
                    workspaceId: String(selectedOrgId),
                }),
            );
        }
        if (sendingStatus === UpdatingStatus.Failed) {
            setSendingFailedMessage(
                "Something went wrong trying to send your invites. Please try again.",
            );
        }
    }, [history, sendingStatus, selectedOrgId]);

    const handleSendMessage = (): void => {
        const analyticsProps: ChainAnalyticsTracker.VaccineSentMessageProps = {
            ...analyticsLoggedInProps,
            hasEditedMessage: (messageBody !== defaultInviteMessage).toString(),
            isIndividualInvite: false,
        };
        ChainAnalyticsTracker.trackVaccineSentMessage(analyticsProps);
        dispatch(
            sendMessage(
                practiceId,
                vaccineDeliveryId,
                messageBody,
                false, // we don't want to reload the batch here, just in the booster invite flow!
            ),
        );
    };

    const updateMessageText = (
        event: React.ChangeEvent<HTMLTextAreaElement>,
    ): void => {
        const messageText = event.target.value;
        checkMessageTextAndSetState(messageText);
        setMessageBody(messageText);
    };

    const checkMessageTextAndSetState = (textToCheck: string): boolean => {
        const assembled = `${patientGreetingCopy}\n${textToCheck}\n${footerMessage}`;
        const totalCharacterCount = calculateMessageLength(assembled, 0);
        setCharacterCount(totalCharacterCount);

        if (textToCheck.length <= 2) {
            // Need some body
            setValidMessageBody(false);
            return false;
        }

        if (totalCharacterCount > MaxBatchSMSSize) {
            setValidMessageBody(false);
            setMessageErrorMessage(
                `Sorry, your message must be equal to or less than 600 characters. ${characterCountWarning}`,
            );
            return false;
        }

        if (totalCharacterCount >= MaxSingleSMSSize) {
            setValidMessageBody(true);
            setMessageErrorMessage(
                `Reduce the number of characters to send a single text message. ${characterCountWarning}`,
            );
            return true;
        }

        setValidMessageBody(true);
        setMessageErrorMessage(characterCountWarning);
        return true;
    };

    const textableFirstJabPatients =
        getTextablePatientsWhoNeedFirstJab(patientList);
    const nonTextableFirstJabPatients =
        getNonTextablePatientsWhoNeedFirstJab(patientList);
    const nonTextableFirstJabPatientsOnly =
        listHasOnlyNonTextablePatientsWhoNeedFirstJab(patientList);
    const secondJabPatientsOnly =
        listHasOnlyPatientsWhoNeedSecondJab(patientList);
    const completedPatientsOnly =
        listHasOnlyPatientsWhoHaveHadSecondJab(patientList);

    const footerMessage = `Please click the link to book your first vaccination, or to tell us you've already booked elsewhere: ${linkAutogenerateCopy}\n${practiceName}`;

    const renderMessageCompose = (): JSX.Element => {
        return (
            <Card spacing={2} props={{ className: "mb-2" }}>
                <>
                    <div className="d-flex align-items-center">
                        <Icon theme="Line" size={3} name="Mail" />
                        <Text variant="label" skinny>
                            Invite text
                        </Text>
                    </div>
                    <div className="d-flex flex-column mb-3">
                        <TextareaAutosize
                            style={{
                                width: "100%",
                                padding: "1rem",
                                backgroundColor: Tokens.COLOURS.greyscale.white,
                                color: Tokens.COLOURS.greyscale.stone,
                                borderStyle: "solid",
                                borderColor: Tokens.COLOURS.greyscale.silver,
                                borderWidth: "1px 1px 0px 1px",
                                resize: "vertical",
                                paddingBottom: "0",
                                lineHeight: "2rem",
                            }}
                            disabled
                            value={patientGreetingCopy}
                            data-testid="message-greeting"
                        />
                        <TextareaAutosize
                            style={{
                                width: "100%",
                                padding: "1rem",
                                borderStyle: "solid",
                                borderColor: Tokens.COLOURS.greyscale.silver,
                                borderWidth: "0px 1px 0px 1px",
                                resize: "vertical",
                                outline: "none",
                                paddingBottom: "0",
                                paddingTop: "0",
                                lineHeight: "2rem",
                            }}
                            autoFocus
                            value={messageBody}
                            onChange={updateMessageText}
                            data-testid="message-body"
                            minRows={1}
                        />
                        <TextareaAutosize
                            style={{
                                width: "100%",
                                padding: "1rem",
                                backgroundColor: Tokens.COLOURS.greyscale.white,
                                color: Tokens.COLOURS.greyscale.stone,
                                borderStyle: "solid",
                                borderColor: Tokens.COLOURS.greyscale.silver,
                                borderWidth: "0px 1px 1px 1px",
                                resize: "vertical",
                                paddingTop: "0",
                                lineHeight: "2rem",
                            }}
                            disabled
                            value={footerMessage}
                            data-testid="message-footer"
                        />
                    </div>

                    <div className="d-flex justify-content-between">
                        <div>
                            {messageErrorMessage && (
                                <div className="d-flex pr-4">
                                    <div>
                                        <Icon
                                            theme="Fill"
                                            name="Warning"
                                            size={2}
                                            colour="orange"
                                        />
                                    </div>
                                    <Text skinny>{messageErrorMessage}</Text>
                                </div>
                            )}
                        </div>
                        <Text
                            colour={
                                characterCount > MaxBatchSMSSize
                                    ? "red"
                                    : "night"
                            }
                            props={{ style: { minWidth: "4.5rem" } }}
                            skinny
                        >
                            {characterCount} / {MaxBatchSMSSize}
                        </Text>
                    </div>
                </>
            </Card>
        );
    };

    const renderSmsPreview = (): JSX.Element => {
        return (
            <>
                <div className="text-center">
                    <VaccineDeliveryHeader
                        stepNumber={2}
                        totalSteps={6}
                        title={"Invite to 1st vaccination"}
                    />
                </div>
                <div className="row mb-5">
                    <div className="col-12 mb-3">
                        <Card
                            spacing={2}
                            header={
                                <>
                                    <Text variant="label" as="span" skinny>
                                        {textableFirstJabPatients.length +
                                            nonTextableFirstJabPatients.length}{" "}
                                        patients{" "}
                                    </Text>
                                    <Text as="span" skinny>
                                        awaiting 1st vaccination
                                    </Text>
                                </>
                            }
                        >
                            <>
                                <Feedback
                                    colour="secondary"
                                    title="This message invites patients to their first dose only, not their second dose"
                                    props={{ className: "mb-2" }}
                                />

                                <Text>
                                    These patients will be sent SMS invites with
                                    unique booking links
                                </Text>
                                <Accordion
                                    header={`${textableFirstJabPatients.length} patients`}
                                    extra={
                                        <Ds.Badge color="yellow">
                                            Will be sent SMS invite
                                        </Ds.Badge>
                                    }
                                    icon={{ name: "Team" }}
                                    className="mb-2"
                                >
                                    <VaccineDeliveryComposePatientTable
                                        filteredList={textableFirstJabPatients}
                                        testIdPrefix="textable"
                                        showNims={showNims}
                                    />
                                </Accordion>

                                {renderMessageCompose()}

                                <hr />
                                <Text>
                                    These patients do not have valid UK mobile
                                    numbers, and need to be manually booked in
                                </Text>
                                <Accordion
                                    header={`${nonTextableFirstJabPatients.length} patients`}
                                    extra={
                                        <Ds.Badge>
                                            Will need to be manually booked
                                        </Ds.Badge>
                                    }
                                    icon={{ name: "Team" }}
                                >
                                    <VaccineDeliveryComposePatientTable
                                        filteredList={
                                            nonTextableFirstJabPatients
                                        }
                                        testIdPrefix="nontextable"
                                        showNims={showNims}
                                    />
                                </Accordion>
                            </>
                        </Card>
                    </div>
                </div>
            </>
        );
    };

    // only show jab state data if we have any (so that if NIMS display is off on the backend, we skip it)
    const showNims =
        patientList.filter(
            (item: VaccineDeliveryItem) =>
                item.jabState &&
                item.jabState !== VaccineExistingPatientState.Unknown,
        ).length > 0;

    return (
        <div>
            <div className="row mb-5">
                <div className="col-8 offset-md-2">
                    {textableFirstJabPatients.length !== 0 &&
                        renderSmsPreview()}
                    {textableFirstJabPatients.length === 0 &&
                        renderConfirmCopy(
                            nonTextableFirstJabPatientsOnly,
                            secondJabPatientsOnly,
                            completedPatientsOnly,
                        )}
                    {sendingFailedMessage && (
                        <Feedback
                            colour="error"
                            title="Something went wrong while uploading your patient list"
                            content={sendingFailedMessage}
                        />
                    )}
                </div>
            </div>
            <StepsFooter
                backText="Back"
                backLink={generatePath(
                    ROUTES_WORKSPACE.accubookManagePatients,
                    { workspaceId: String(selectedOrgId) },
                )}
                forwardText={
                    textableFirstJabPatients.length === 0
                        ? "Confirm"
                        : "Invite to 1st vaccination"
                }
                forwardClickFunction={handleSendMessage}
                disabled={
                    !validMessageBody ||
                    sendingStatus === UpdatingStatus.Loading
                }
            />
        </div>
    );
};

export default VaccineDeliveryComposeFirst;
