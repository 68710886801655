import {
    ContactDetail,
    mapPatientDemographicsContactsToContactDetails,
} from "@accurx/message-component";

import { SearchedPatient } from "../pages/Routes.types";

export const mapSearchedPatientToContactDetails = (
    searchedPatient: SearchedPatient,
): ContactDetail[] => {
    if (searchedPatient.source === "Pds") {
        return searchedPatient.mobileNumber
            ? [
                  {
                      origin: "PDS",
                      method: "Mobile",
                      value: searchedPatient.mobileNumber,
                  },
              ]
            : [];
    }

    return mapPatientDemographicsContactsToContactDetails({
        mobileTelephone: searchedPatient.mobileNumber,
        homeTelephone: searchedPatient.homeNumber,
        workTelephone: searchedPatient.workNumber,
        email: searchedPatient.email,
        address: searchedPatient.address,
    });
};
