import { useCallback, useEffect, useState } from "react";

import {
    PatientDemographics,
    useMutateClearPatientCache,
    useNativeSubscription,
} from "@accurx/native";
import { Log } from "@accurx/shared";
import { mapDemographicsToCurrentPatient } from "domains/compose/utils/mapDemographicsToCurrentPatient";

import { useCurrentPatient } from "../../context/CurrentPatientProvider";
import { useWindowOpenCloseEvents } from "../../hooks/useWindowOpenCloseEvents";
import { ChangePatientModal } from "../ChangePatientModal/ChangePatientModal";
import { isSamePatient } from "./isSamePatient";
import { useRefreshPatientDemographics } from "./useRefreshPatientDemographics";

/**
 * Component that checks for medical record patient changes
 * and either updates the compose patient in the background,
 * or shows a modal to get confirmation from the user
 */
export const MedicalRecordPatientUpdater = ({
    patientFeedPatient,
    medicalRecordSystemFriendlyName,
}: {
    patientFeedPatient: PatientDemographics | null;
    medicalRecordSystemFriendlyName: string;
}) => {
    const { data: windowVisibility } = useNativeSubscription(
        "SubscribeWindowOpenStatus",
    );
    const isComposeWindowClosed = windowVisibility?.open === false;

    const {
        currentPatient,
        setCurrentPatient,
        setIsPatientStale,
        isPatientStale,
    } = useCurrentPatient();

    const hasCurrentPatientBeenSet = !!currentPatient;
    const isCurrentPatientSameAsPatientFeedPatient = isSamePatient(
        currentPatient?.patient.externalIds,
        patientFeedPatient?.patientExternalIdentityDto.patientExternalIds,
    );

    const [showConfirmModal, setShowConfirmModal] = useState(false);

    const { mutate: clearPatientCache } = useMutateClearPatientCache();

    const clearCacheForCurrentPatient = useCallback(() => {
        if (currentPatient) {
            clearPatientCache({
                patientExternalIds: currentPatient.patient.externalIds,
            });
        }
        setIsPatientStale(true);
    }, [clearPatientCache, currentPatient, setIsPatientStale]);

    const refreshDemographics = useRefreshPatientDemographics();

    const refreshStalePatient = async () => {
        if (currentPatient && isPatientStale) {
            try {
                const refreshedPatient = await refreshDemographics(
                    currentPatient,
                );
                setCurrentPatient(refreshedPatient);
            } catch (error) {
                Log.warn(
                    "Compose: Unable to refetch stale patient, using stale patient",
                    { originalException: error },
                );
            }
        }
        setIsPatientStale(false);
    };

    useWindowOpenCloseEvents({
        onOpen: () => {
            void refreshStalePatient();
        },
        onClose: clearCacheForCurrentPatient,
    });

    useEffect(() => {
        // Change the current patient if Compose is closed and the EMR patient
        // is changed.
        if (
            isComposeWindowClosed &&
            patientFeedPatient !== null &&
            !isCurrentPatientSameAsPatientFeedPatient
        ) {
            setCurrentPatient(
                mapDemographicsToCurrentPatient(patientFeedPatient),
            );
        }
    }, [
        isComposeWindowClosed,
        isCurrentPatientSameAsPatientFeedPatient,
        patientFeedPatient,
        setCurrentPatient,
    ]);

    useEffect(() => {
        // Clear the current patient if Compose is closed and the EMR patient
        // is cleared.
        if (
            isComposeWindowClosed &&
            patientFeedPatient === null &&
            hasCurrentPatientBeenSet
        ) {
            setCurrentPatient(null);
        }
    }, [
        hasCurrentPatientBeenSet,
        isComposeWindowClosed,
        patientFeedPatient,
        setCurrentPatient,
    ]);

    useEffect(() => {
        // When the window is open and the EMR patient changes, we show a modal
        // to prompt the user to change their current patient to the new EMR patient.
        if (
            hasCurrentPatientBeenSet &&
            !isComposeWindowClosed &&
            !isCurrentPatientSameAsPatientFeedPatient &&
            !!patientFeedPatient
        ) {
            setShowConfirmModal(true);
        }
    }, [
        hasCurrentPatientBeenSet,
        isComposeWindowClosed,
        isCurrentPatientSameAsPatientFeedPatient,
        patientFeedPatient,
        setCurrentPatient,
    ]);

    return (
        <ChangePatientModal
            isOpen={showConfirmModal}
            onClose={() => setShowConfirmModal(false)}
            onConfirm={() => {
                if (patientFeedPatient) {
                    clearCacheForCurrentPatient();
                    setCurrentPatient(
                        mapDemographicsToCurrentPatient(patientFeedPatient),
                    );
                }
                setShowConfirmModal(false);
            }}
            medicalRecordSystemFriendlyName={medicalRecordSystemFriendlyName}
        />
    );
};
