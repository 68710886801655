import { forwardRef, useState } from "react";

import { useAnalytics } from "@accurx/analytics";
import { Ds } from "@accurx/design";
import { useMedicalRecordConnection } from "@accurx/native";
import {
    PatientSearchProductOrigin,
    PatientSearchResult,
} from "domains/patient/types";

import { EmrNameSearch } from "../EmrNameSearch/EmrNameSearch";
import { EmrNhsSearch } from "../EmrNhsSearch/EmrNhsSearch";
import {
    EmrSearchState,
    INIT_STATE,
    isEmrNhsSearchState,
    useFormReducer,
} from "../PatientSearchForm.helpers";

type SelectedEmrSearch = "EmrNhsSearch" | "EmrNameSearch";

export type EmrSearchProps = {
    onSubmit: (data: PatientSearchResult[]) => void;
    onError: (error: Error) => void;
    onValidationError: (errorReason: string[]) => void;
    onClear?: () => void;
    isFormDisabled?: boolean;
    onLoading?: (isLoading: boolean) => void;
    productOrigin: PatientSearchProductOrigin;
};

export const EmrSearch = forwardRef<HTMLFormElement, EmrSearchProps>(
    (
        {
            onSubmit,
            onError,
            onValidationError,
            onClear,
            onLoading,
            isFormDisabled,
            productOrigin = "PatientSearch",
        },
        forwardedRef,
    ) => {
        const track = useAnalytics();
        const [formData, formAction] = useFormReducer<EmrSearchState>(
            INIT_STATE.EMR_NHS,
        );
        const medicalRecordConnection = useMedicalRecordConnection();
        const searchMethodLabel =
            medicalRecordConnection.system === "SystmOne"
                ? "SearchBySystmOne"
                : "SearchByVision";

        const [selectedSearchMethod, setSelectedSearchMethod] =
            useState<SelectedEmrSearch>("EmrNhsSearch");

        const handleFormSwitch = (searchMethod: SelectedEmrSearch) => {
            setSelectedSearchMethod(searchMethod);
            const initData =
                searchMethod === "EmrNhsSearch"
                    ? INIT_STATE.EMR_NHS
                    : INIT_STATE.EMR_NAME;
            formAction({
                type: "SET",
                payload: initData,
            });
            onClear?.();
            track("PatientSearchMethod Button Click", {
                navigationVersion: "Unified",
                patientSearchMethodType: searchMethodLabel,
                productOrigin,
            });
        };

        if (selectedSearchMethod === "EmrNhsSearch") {
            return (
                <EmrNhsSearch
                    ref={forwardedRef}
                    formData={
                        isEmrNhsSearchState(formData)
                            ? formData
                            : INIT_STATE.EMR_NHS
                    }
                    {...{
                        formAction,
                        onSubmit,
                        onError,
                        onValidationError,
                        onClear,
                        onLoading,
                        isFormDisabled,
                    }}
                >
                    <Ds.Button
                        appearance="tertiary"
                        onClick={() => handleFormSwitch("EmrNameSearch")}
                        type="button"
                    >
                        Search by name
                        <Ds.Button.Icon name="ArrowTail" rotate="90" />
                    </Ds.Button>
                </EmrNhsSearch>
            );
        }

        return (
            <EmrNameSearch
                ref={forwardedRef}
                formData={
                    !isEmrNhsSearchState(formData)
                        ? formData
                        : INIT_STATE.EMR_NAME
                }
                {...{
                    formAction,
                    onSubmit,
                    onError,
                    onValidationError,
                    onClear,
                    onLoading,
                    isFormDisabled,
                }}
            >
                <Ds.Button
                    appearance="tertiary"
                    onClick={() => handleFormSwitch("EmrNhsSearch")}
                    type="button"
                >
                    <Ds.Button.Icon name="ArrowTail" rotate="270" />
                    Back
                </Ds.Button>
            </EmrNameSearch>
        );
    },
);
