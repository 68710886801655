import { useRef, useState } from "react";

import { useAnalytics } from "@accurx/analytics";
import { useFeatureFlag } from "@accurx/auth";
import { Ds } from "@accurx/design";
import { Feedback } from "@accurx/inbox-design-library";
import {
    useMedicalRecordConnection,
    useUploadPatientRecordAttachmentMutation,
} from "@accurx/native";
import { QuickViewPortal } from "@accurx/quick-view";
import { PatientRecordAttachmentSelector } from "domains/message-component/components/Compose/components/Attach/components/PatientRecordAttachmentSelector/PatientRecordAttachmentSelector";
import { formatPatientRecordAttachmentName } from "domains/message-component/components/Compose/components/Attach/components/PatientRecordAttachmentSelector/formatPatientRecordAttachmentName";
import { getIsFileLimitReached } from "domains/message-component/components/Compose/components/Attach/getIsFileLimitReached";
import { NHSAdviceSelector } from "domains/message-component/components/Compose/components/NhsAdviceSelector/NhsAdviceSelector";
import { Attachment } from "domains/message-component/reducer.types";
import { NHSAdviceLink } from "domains/message-component/types";
import {
    getNativeUploadErrorMessage,
    getValidPatientNhsNumber,
} from "domains/message-component/utils";
import { formatPatientName } from "domains/message-component/utils/format/formatPatientName";

import { useCompose } from "../../../../context";
import { MessageActionsControlBarProps } from "../../types/Compose.types";
import { AttachSelector } from "../Attach/components/AttachSelector/AttachSelector";
import { AttachFileFromDesktopButtonBySourceType } from "../Attach/components/AttachSelector/components/AttachFileFromDesktopButton";
import { AttachFileFromPatientRecordButton } from "../Attach/components/AttachSelector/components/AttachFileFromPatientRecordButton";
import { AttachNHSAdviceButton } from "../Attach/components/AttachSelector/components/AttachNHSAdviceButton";
import { BookingInvite } from "../BookingInvite/BookingInvite";
import { AllowReplyCheckbox } from "../PatientResponseCheckbox/AllowReplyCheckbox";
import { SaveToRecordCheckbox } from "../SaveToRecordCheckbox/SaveToRecordCheckbox";

export const MessageActionsControlBar = ({
    setAssignee,
    patient,
}: MessageActionsControlBarProps) => {
    const connection = useMedicalRecordConnection();
    const track = useAnalytics();
    const { state, dispatch } = useCompose();

    const [isAttachSelectorOpen, setIsAttachSelectorOpen] = useState(false);
    const [showPatientRecordAttachments, setShowPatientRecordAttachments] =
        useState(false);
    const [showNhsAdviceSelector, setShowNhsAdviceSelector] = useState(false);

    const isBookingInviteEnabled = useFeatureFlag(
        "SelfBookFromComposeSendLink",
    );

    const attachSelectorButtonRef = useRef<HTMLButtonElement>(null);

    const uploadPatientRecordAttachment =
        useUploadPatientRecordAttachmentMutation();
    const onSelectNHSAdvice = (nhsAdviceLink: NHSAdviceLink) => {
        dispatch({ type: "ADD_NHS_ADVICE_LINK", payload: { nhsAdviceLink } });
        setShowNhsAdviceSelector(false);
    };

    const hasReachedAttachmentLimit = getIsFileLimitReached(state);
    const hasQuestionnaire = state.template.type === "QuestionnaireTemplate";

    const hasReachedUploadLimit =
        !hasQuestionnaire && hasReachedAttachmentLimit;
    const hasReachedNHSAdviceLinkLimit = !!state.nhsAdviceLink;
    const selfBookLinkSelected = state.selfBookLink !== null;

    const shouldDisableNHSAdvice =
        hasQuestionnaire ||
        hasReachedNHSAdviceLinkLimit ||
        selfBookLinkSelected ||
        state.isSelfbookConfigurationOpen;
    const shouldDisableAttachments =
        hasQuestionnaire ||
        hasReachedUploadLimit ||
        selfBookLinkSelected ||
        state.isSelfbookConfigurationOpen;
    const isPatientResponseCheckboxEnabled =
        !hasQuestionnaire && !selfBookLinkSelected;

    const patientResponseCheckboxOnChange = () => {
        track("PatientMessageResponse Option Select", {
            oldValue: state.isPatientResponseEnabled,
            newValue: !state.isPatientResponseEnabled,
        });

        // Reset assignee if patient response is being turned off
        if (state.isPatientResponseEnabled) {
            setAssignee?.(null);
        } else {
            setAssignee?.("DEFAULT");
        }

        dispatch({
            type: "UPDATE_IS_PATIENT_RESPONSE_ENABLED",
            payload: {
                isPatientResponseEnabled: !state.isPatientResponseEnabled,
            },
        });
    };

    return (
        <>
            <Ds.Flex
                gap="1"
                justifyContent="space-between"
                alignItems={{ xs: "flex-end", sm: "flex-start" }}
            >
                <Ds.Flex.Item>
                    <Ds.Flex
                        justifyContent="flex-start"
                        flexDirection={{ xs: "column-reverse", sm: "row" }}
                        gap={"1"}
                    >
                        <Ds.Flex.Item>
                            <AllowReplyCheckbox
                                checked={state.isPatientResponseEnabled}
                                onChange={patientResponseCheckboxOnChange}
                                disabled={
                                    !isPatientResponseCheckboxEnabled ||
                                    state.isSelfbookConfigurationOpen ||
                                    !!state.selfBookLink ||
                                    state.template.type ===
                                        "PendingQuestionnaireTemplate"
                                }
                            />
                        </Ds.Flex.Item>
                        <Ds.Flex gap="1">
                            <Ds.Flex.Item>
                                <AttachSelector
                                    ref={attachSelectorButtonRef}
                                    isOpen={isAttachSelectorOpen}
                                    toggle={() => {
                                        // only track when its being opened
                                        if (!isAttachSelectorOpen) {
                                            track("Attachment Button Click", {
                                                conversationParticipant:
                                                    state.conversationParticipant,
                                                eventVersion: 2,
                                            });
                                        }

                                        setIsAttachSelectorOpen(
                                            (prev) => !prev,
                                        );
                                    }}
                                    iconOnlyButton={isBookingInviteEnabled}
                                    disabled={
                                        state.isSelfbookConfigurationOpen ||
                                        selfBookLinkSelected ||
                                        state.template.type ===
                                            "PendingQuestionnaireTemplate" ||
                                        hasQuestionnaire ||
                                        (shouldDisableAttachments &&
                                            shouldDisableNHSAdvice)
                                    }
                                >
                                    <Ds.Flex flexDirection="column" gap="1">
                                        {state.maxAttachmentCount === 1 &&
                                            hasReachedUploadLimit &&
                                            !hasReachedNHSAdviceLinkLimit && (
                                                <Feedback
                                                    colour="warning"
                                                    title="File attachment limit reached"
                                                />
                                            )}
                                        <AttachFileFromDesktopButtonBySourceType
                                            source="PatientMessaging"
                                            isDisabled={
                                                shouldDisableAttachments
                                            }
                                            onClick={() => {
                                                track(
                                                    "Attachment MenuItem Click",
                                                    {
                                                        eventVersion: 2,
                                                        conversationParticipant:
                                                            state.conversationParticipant,
                                                        attachmentType:
                                                            "LocalFile",
                                                    },
                                                );
                                            }}
                                            onChange={() =>
                                                setIsAttachSelectorOpen(false)
                                            }
                                        />
                                        <AttachFileFromPatientRecordButton
                                            isDisabled={
                                                shouldDisableAttachments
                                            }
                                            patientNhsNumber={getValidPatientNhsNumber(
                                                patient.externalIds,
                                            )}
                                            onClick={() => {
                                                track(
                                                    "Attachment MenuItem Click",
                                                    {
                                                        conversationParticipant:
                                                            state.conversationParticipant,
                                                        attachmentType:
                                                            "MedicalRecord",
                                                    },
                                                );

                                                setShowPatientRecordAttachments(
                                                    true,
                                                );
                                                setIsAttachSelectorOpen(false);
                                            }}
                                        />

                                        <AttachNHSAdviceButton
                                            onClick={() => {
                                                track(
                                                    "Attachment MenuItem Click",
                                                    {
                                                        conversationParticipant:
                                                            state.conversationParticipant,
                                                        attachmentType:
                                                            "NHSAdvice",
                                                    },
                                                );

                                                setShowNhsAdviceSelector(true);
                                            }}
                                            isDisabled={shouldDisableNHSAdvice}
                                            showWarning={
                                                hasReachedNHSAdviceLinkLimit &&
                                                !hasReachedUploadLimit
                                            }
                                        />
                                    </Ds.Flex>
                                </AttachSelector>
                            </Ds.Flex.Item>
                            {isBookingInviteEnabled && (
                                <Ds.Flex.Item>
                                    <BookingInvite />
                                </Ds.Flex.Item>
                            )}
                        </Ds.Flex>
                    </Ds.Flex>
                </Ds.Flex.Item>

                {connection.capabilities?.saveToRecord && (
                    <Ds.Flex.Item>
                        <SaveToRecordCheckbox />
                    </Ds.Flex.Item>
                )}
            </Ds.Flex>

            <QuickViewPortal
                isOpen={showPatientRecordAttachments}
                onClose={() => setShowPatientRecordAttachments(false)}
            >
                <PatientRecordAttachmentSelector
                    patientExternalIds={patient.externalIds}
                    patientName={formatPatientName({
                        firstName: patient.firstName,
                        familyName: patient.familyName,
                        prefixName: patient.prefixName,
                    })}
                    onSelect={(attachment) => {
                        setShowPatientRecordAttachments(false);

                        const attachmentName =
                            formatPatientRecordAttachmentName(attachment);
                        const tempAttachment: Attachment = {
                            id: attachment.documentId,
                            name: attachmentName,
                            origin: "Upload",
                            status: "loading",
                        };

                        dispatch({
                            type: "ADD_ATTACHMENTS",
                            payload: {
                                attachments: [tempAttachment],
                            },
                        });

                        uploadPatientRecordAttachment.mutate(
                            {
                                documentId: attachment.documentId,
                                patientExternalIds: patient.externalIds,
                            },
                            {
                                onError: (error) =>
                                    dispatch({
                                        type: "UPDATE_ATTACHMENT",
                                        payload: {
                                            attachmentId: tempAttachment.id,
                                            attachmentOrigin: "Upload",
                                            attachment: {
                                                ...tempAttachment,
                                                origin: "Upload",
                                                status: "error",
                                                error: getNativeUploadErrorMessage(
                                                    error,
                                                ),
                                            },
                                        },
                                    }),
                                onSuccess: (data) => {
                                    dispatch({
                                        type: "UPDATE_ATTACHMENT",
                                        payload: {
                                            attachmentId: tempAttachment.id,
                                            attachmentOrigin: "Upload",
                                            attachment: {
                                                ...tempAttachment,
                                                status: "success",
                                                id: data.serverId,
                                                previewUrl: data.previewUrl,
                                            },
                                        },
                                    });
                                },
                            },
                        );
                    }}
                />
            </QuickViewPortal>
            <QuickViewPortal
                onClose={() => setShowNhsAdviceSelector(false)}
                isOpen={showNhsAdviceSelector}
                returnFocusRef={attachSelectorButtonRef}
                autoFocus={false}
            >
                <NHSAdviceSelector onSelectNHSAdvice={onSelectNHSAdvice} />
            </QuickViewPortal>
        </>
    );
};
