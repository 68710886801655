import { createQueryHook } from "@accurx/api/QueryClient";
import { GetContactWithMetadataResponse } from "@accurx/api/clinician-messaging";
import { httpClient2 } from "@accurx/shared";

type ContactDetailsQueryVariables = {
    workspaceId: number;
    contactId: string;
};

export const ContactDetailsQueryKey = "ContactDetailsQuery";

export const useContactDetailsQuery = createQueryHook<
    ContactDetailsQueryVariables,
    GetContactWithMetadataResponse
>(({ workspaceId, contactId }) => ({
    queryKey: [ContactDetailsQueryKey, { workspaceId, contactId }],
    queryFn: async () =>
        await httpClient2
            .get({
                path: "/api/clinicianmessaging/directory/:workspaceId/contacts/:contactId",
                params: { workspaceId, contactId },
            })
            .json<GetContactWithMetadataResponse>(),
}));
