import { Action, Reducer } from "redux";

import { MessageTemplateType } from "api/FlemingDtos";
import { FlemingAnalyticsTracker } from "app/analytics";
import { TemplateInList } from "app/workspaceConversations/components/MessageTemplates/MessageTemplates.types";

import { SELECT_ORG } from "../account/AccountActions";
import {
    KnownAction,
    REQUEST_CREATE_EDIT_MESSAGE_TEMPLATES_SUCCESS,
    REQUEST_MESSAGE_TEMPLATES_FAILURE,
    REQUEST_MESSAGE_TEMPLATES_STARTED,
    REQUEST_MESSAGE_TEMPLATES_SUCCESS,
    SET_LAST_ACTION_LOCATION,
} from "./MessageTemplatesActions";

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface MessageTemplatesState {
    isMessageTemplatesLoading: boolean;
    lastGetMessageTemplatesFailed: boolean;
    smsTemplates: TemplateInList[];
    videoConsultTemplates: TemplateInList[];
    vcDefaultTemplate: TemplateInList | null;
    /* For analytics purposes */
    lastMessageTemplateActionLocation: FlemingAnalyticsTracker.FlemingAnalyticsTemplateLocationType;
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const initialState: MessageTemplatesState = {
    isMessageTemplatesLoading: false,
    lastGetMessageTemplatesFailed: false,
    smsTemplates: [],
    videoConsultTemplates: [],
    vcDefaultTemplate: null,
    lastMessageTemplateActionLocation: null,
};

export const reducer: Reducer<MessageTemplatesState> = (
    state = initialState,
    incomingAction: Action,
): MessageTemplatesState => {
    const action = incomingAction as KnownAction;

    let actionedTemplate: TemplateInList | undefined;
    let updatedTemplatesList: TemplateInList[];
    let isVideoConsultTemplate: boolean;
    let isSmsTemplate: boolean;
    let templatesList: TemplateInList[]; // Could be type video or SMS
    let smsTemplatesList: TemplateInList[];
    let vcTemplatesList: TemplateInList[];
    let isDefaultTemplate: boolean;
    let vcDefaultTemplate: TemplateInList | null;

    const shouldResetTemplates = [SELECT_ORG].includes(incomingAction.type);

    if (shouldResetTemplates) {
        return initialState;
    }

    switch (action.type) {
        case REQUEST_MESSAGE_TEMPLATES_STARTED:
            return {
                ...state,
                isMessageTemplatesLoading: true,
                lastGetMessageTemplatesFailed: false,
                smsTemplates: [],
                videoConsultTemplates: [],
                vcDefaultTemplate: null,
            };

        case REQUEST_MESSAGE_TEMPLATES_SUCCESS:
            vcTemplatesList = action.userTemplates.filter(
                ({ type }) => type === MessageTemplateType.VideoConsult,
            );
            smsTemplatesList = action.userTemplates.filter(
                ({ type }) => type === null || type === MessageTemplateType.Sms,
            );

            return {
                ...state,
                isMessageTemplatesLoading: false,
                lastGetMessageTemplatesFailed: false,
                smsTemplates: smsTemplatesList,
                videoConsultTemplates: vcTemplatesList,
                vcDefaultTemplate:
                    vcTemplatesList.find((t) => !!t.isDefault) || null,
            };

        case REQUEST_MESSAGE_TEMPLATES_FAILURE:
            return {
                ...state,
                isMessageTemplatesLoading: false,
                lastGetMessageTemplatesFailed: true,
                smsTemplates: [],
                videoConsultTemplates: [],
                vcDefaultTemplate: null,
            };

        case REQUEST_CREATE_EDIT_MESSAGE_TEMPLATES_SUCCESS:
            isVideoConsultTemplate =
                action.userTemplate.type === MessageTemplateType.VideoConsult;
            isSmsTemplate =
                action.userTemplate.type === MessageTemplateType.Sms;
            isDefaultTemplate = !!action.userTemplate.isDefault;
            // select the list that needs to be updated
            templatesList = isVideoConsultTemplate
                ? state.videoConsultTemplates
                : state.smsTemplates;
            actionedTemplate = templatesList.find(
                (template) => template.id === action.userTemplate.id,
            );

            // If we are editing an existing list
            if (actionedTemplate) {
                updatedTemplatesList = templatesList.map((template) => {
                    // Update the edited template
                    if (template.id === action.userTemplate.id) {
                        return action.userTemplate;
                    }
                    // If it's a default, make sure the other ones are updated appropriately
                    if (isDefaultTemplate && template.isDefault) {
                        return { ...template, isDefault: false };
                    }
                    return template;
                });

                // Otherwise, if it is a new template...
            } else {
                // If it has been set as default
                if (isDefaultTemplate) {
                    // Remove the default status from the templates that currently are
                    templatesList = templatesList.map((template) =>
                        template.isDefault
                            ? { ...template, isDefault: false }
                            : template,
                    );
                }

                updatedTemplatesList = [action.userTemplate, ...templatesList];
            }

            // Work out VC Default template
            if (
                state.vcDefaultTemplate &&
                action.userTemplate.id === state.vcDefaultTemplate.id &&
                isVideoConsultTemplate &&
                !isDefaultTemplate
            ) {
                // If we edited the id to not be default anymore, set the default template to null
                vcDefaultTemplate = null;
            } else {
                // Update the id if the new/edited template is a default, otherwise leave it as is
                vcDefaultTemplate =
                    isDefaultTemplate && isVideoConsultTemplate
                        ? action.userTemplate
                        : state.vcDefaultTemplate;
            }

            return {
                ...state,
                // Depending on what type of template we created/edited, use updated list or keep state as is
                smsTemplates: isSmsTemplate
                    ? updatedTemplatesList
                    : state.smsTemplates,
                videoConsultTemplates: isVideoConsultTemplate
                    ? updatedTemplatesList
                    : state.videoConsultTemplates,
                vcDefaultTemplate,
            };

        case SET_LAST_ACTION_LOCATION:
            return {
                ...state,
                lastMessageTemplateActionLocation: action.location,
            };

        default:
            return state;
    }
};
