import React, { useState } from "react";

import { useCurrentWorkspace } from "@accurx/auth";
import { Ds, Feedback, TabProps, Tabs, Tokens } from "@accurx/design";
import { AutoFocusHeading, useAccurxWebTitle } from "@accurx/navigation";
import { Log } from "@accurx/shared";
import keyBy from "lodash/keyBy";
import { useHistory, useLocation } from "react-router-dom";

import { trackChangeTab } from "app/analytics/FlemingAnalytics";
import { StyledContainer } from "app/layout/Container";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { useIsUserApproved } from "store/hooks";

import { AccurxTemplatesPanel } from "./AccurxTemplatesPanel";
import {
    FEEDBACK_UNAPPROVED_USER_BODY_TEXT,
    FEEDBACK_UNAPPROVED_USER_TITLE_TEXT,
} from "./ManageTemplates.utils";
import { OrganisationTemplatesPanel } from "./OrganisationTemplatesPanel";
import { UserTemplatesPanel } from "./UserTemplatesPanel";

//These tabIds should be kept in sync with BuildManageTemplatesRoute and BuildManageAccuRxTemplatesRoute in AccuRxDotCom.cs in Shared
export const TabIds = {
    UserTemplates: "UserTemplates",
    OrganisationTemplates: "OrganisationTemplates",
    AccurxTemplates: "AccurxTemplates",
} as const;

type TabPanelProps = {
    tabId: string;
};

export type TabId = (typeof TabIds)[keyof typeof TabIds];

const ManageTemplatesTabPanel = ({ tabId }: TabPanelProps): JSX.Element => {
    useAccurxWebTitle("Manage message templates");
    const { orgId, organisationName: orgName } = useCurrentWorkspace();

    if (orgId === null) {
        return (
            <Feedback
                colour="error"
                title="There was a problem loading this page"
                children="Please refresh the page."
            />
        );
    }
    switch (tabId) {
        case TabIds.UserTemplates:
            return <UserTemplatesPanel orgId={orgId} orgName={orgName} />;
        case TabIds.OrganisationTemplates:
            return (
                <OrganisationTemplatesPanel orgId={orgId} orgName={orgName} />
            );
        case TabIds.AccurxTemplates:
            return <AccurxTemplatesPanel orgId={orgId} />;
        default:
            return <></>;
    }
};

export const ManageTemplatesOverview = (): JSX.Element => {
    const flemingLoggedInProperties = useFlemingLoggedInAnalytics();
    const history = useHistory();
    const location = useLocation();
    const isUserApproved: boolean = useIsUserApproved();
    const searchParams = new URLSearchParams(history.location.search);
    const tab = searchParams.get("tab");

    const tabs: TabProps<TabId>[] = [
        { tabId: TabIds.UserTemplates, text: "My Templates" },
        { tabId: TabIds.OrganisationTemplates, text: "Workspace Templates" },
        { tabId: TabIds.AccurxTemplates, text: "Accurx Templates" },
    ];

    const TabLookup = keyBy(tabs, "tabId");
    const initialTab =
        TabLookup[tab || TabIds.UserTemplates] ||
        TabLookup[TabIds.UserTemplates];
    const [currentTabId, setCurrentTabId] = useState<TabId>(initialTab.tabId);
    const handleTabChange = (tabId: TabId) => {
        switch (tabId.toLowerCase()) {
            case TabIds.UserTemplates.toLowerCase():
            case TabIds.OrganisationTemplates.toLowerCase():
            case TabIds.AccurxTemplates.toLowerCase():
                const params = new URLSearchParams({ tab: tabId });
                history.replace({
                    pathname: location.pathname,
                    search: params.toString(),
                });

                trackChangeTab({
                    ...flemingLoggedInProperties,
                    currentTab: currentTabId,
                    newTab: tabId,
                });
                return setCurrentTabId(tabId);
            default:
                Log.error(
                    `[ManageTemplatesOverview] - tab id ${tabId} is not on list`,
                );
                return setCurrentTabId(TabIds.UserTemplates);
        }
    };

    return (
        <StyledContainer>
            <Ds.Flex flexDirection="column" gap="3">
                <AutoFocusHeading size="large" mt={Tokens.SIZES[3]}>
                    Manage templates
                </AutoFocusHeading>
                {!isUserApproved && (
                    <Feedback
                        colour="warning"
                        title={FEEDBACK_UNAPPROVED_USER_TITLE_TEXT}
                    >
                        {FEEDBACK_UNAPPROVED_USER_BODY_TEXT}
                    </Feedback>
                )}
                <Tabs
                    selected={currentTabId}
                    onTabChange={(tab) => handleTabChange(tab as TabId)}
                >
                    <Tabs.TabList tabs={tabs} />
                    {tabs.map(({ tabId }) => (
                        <Tabs.Panel key={tabId} tabId={tabId}>
                            <ManageTemplatesTabPanel tabId={tabId} />
                        </Tabs.Panel>
                    ))}
                </Tabs>
            </Ds.Flex>
        </StyledContainer>
    );
};
