import { useState } from "react";

import { useAnalytics } from "@accurx/analytics";
import {
    SelfbookConfigurationQuickView,
    useAppointmentsConfigurationQuery,
} from "@accurx/self-book";
import { DateHelpers } from "@accurx/shared";
import { addDays, isAfter, parseISO } from "date-fns";
import { useCompose } from "domains/message-component/context";
import { userflowIds } from "domains/message-component/userflow";
import { mapSelfBookFormDataToSelfBookLink } from "domains/message-component/utils/mapSelfBookFormDataToSelfBookLink";

import { BookingInviteButton } from "./BookingInviteButton";

export const BookingInvite = () => {
    const { state, dispatch } = useCompose();
    const track = useAnalytics();
    const [showSelfbookConfig, setShowSelfbookConfig] = useState(false);

    const { data, status } = useAppointmentsConfigurationQuery();

    const isCustomDateAndTime =
        state.sendAt?.type === "calendar" || state.sendAt?.type === "frame";

    const selectedSendDateAndTime =
        state.sendAt?.sendAtDateTime && parseISO(state.sendAt.sendAtDateTime);

    const maxDateForScheduling =
        status === "success" &&
        data.selfBookSchedulingWeeksIntoFuture &&
        addDays(new Date(), data.selfBookSchedulingWeeksIntoFuture * 7); // n*7 equivalent to n days
    const selectedTimeIsAfterAllowedSchedulingTimeFrame =
        selectedSendDateAndTime &&
        maxDateForScheduling &&
        isAfter(selectedSendDateAndTime, maxDateForScheduling);

    // Pre-conditions necessary for a user to set up self book link
    const canAddSelfBook =
        state.nhsAdviceLink === null &&
        state.template.type !== "QuestionnaireTemplate" &&
        state.template.type !== "PendingQuestionnaireTemplate" &&
        state.selfBookLink === null &&
        state.attachments.length === 0 &&
        !state.isPatientResponseEnabled &&
        state.contactDetails.method !== "Email";

    const isBookingInviteDisabled =
        !canAddSelfBook ||
        (isCustomDateAndTime &&
            selectedTimeIsAfterAllowedSchedulingTimeFrame === true) ||
        state.isSelfbookConfigurationOpen;

    const dispatchShowSelfbookConfig = (show: boolean) => {
        setShowSelfbookConfig(show);
        dispatch({
            type: "SET_SHOW_SELF_BOOK_CONFIGURATION",
            payload: { show },
        });
    };
    return (
        <>
            <BookingInviteButton
                data-testid={`booking-invite-configuration-${status}`}
                onClick={() => {
                    dispatchShowSelfbookConfig(true);
                    track("ConversationBookingAddStart Button Click", {
                        appOrigin: "QuickReply",
                    });
                }}
                userflowId={userflowIds.bookingLinkButton}
                disabled={isBookingInviteDisabled}
            />

            <SelfbookConfigurationQuickView
                showSelfbookConfiguration={showSelfbookConfig}
                sendAt={
                    state.sendAt?.sendAtDateTime ??
                    DateHelpers.getCurrentTimeStamp()
                }
                setShowSelfbookConfiguration={dispatchShowSelfbookConfig}
                onSelfbookConfigComplete={(selfbookConfigData) => {
                    const selfBookLink =
                        mapSelfBookFormDataToSelfBookLink(selfbookConfigData);

                    dispatch({
                        type: "ADD_SELF_BOOK_LINK",
                        payload: {
                            selfBookLink,
                        },
                    });
                }}
            />
        </>
    );
};
