import {
    BaseAppointmentRemindersResponse,
    CreateAppointmentReminderRequest,
    GetAppointmentRemindersResponse,
    UpdateAppointmentReminderRequest,
} from "@accurx/api/appointment";
import { IWrappedResult, getApiEndpoint, httpClient } from "@accurx/shared";

const ENDPOINTS = {
    apptReminders: "/api/appointments/appointmentReminders/:practiceId",
    apptRemindersCreate:
        "/api/appointments/appointmentReminders/:practiceId/create",
    apptRemindersUpdate:
        "/api/appointments/appointmentReminders/:practiceId/update",
    apptRemindersDelete:
        "/api/appointments/:practiceId/appointmentReminders/:appointmentReminderId",
} as const;

export const getApptReminder = (
    practiceId: string,
): Promise<IWrappedResult<GetAppointmentRemindersResponse>> =>
    httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.apptReminders,
            params: { practiceId },
        }),
    );

export const postApptReminderCreate = (
    practiceId: string,
    request: CreateAppointmentReminderRequest,
): Promise<IWrappedResult<BaseAppointmentRemindersResponse>> =>
    httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.apptRemindersCreate,
            params: { practiceId },
        }),
        request,
    );

export const postApptReminderUpdate = (
    practiceId: string,
    request: UpdateAppointmentReminderRequest,
): Promise<IWrappedResult<BaseAppointmentRemindersResponse>> =>
    httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.apptRemindersUpdate,
            params: { practiceId },
        }),
        request,
    );

export const deleteApptReminder = (
    practiceId: string,
    appointmentReminderId: number,
): Promise<IWrappedResult<void>> =>
    httpClient.deleteReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.apptRemindersDelete,
            params: {
                practiceId,
                appointmentReminderId: appointmentReminderId.toString(),
            },
        }),
    );
