import { useCurrentWorkspace } from "@accurx/auth";
import {
    NativeTransportError,
    querySearchPatientsQuery,
    useNativeTransport,
} from "@accurx/native";
import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import {
    EmisSearchState,
    EmrSearchState,
} from "domains/patient/components/PatientSearch/PatientSearchForm/PatientSearchForm.helpers";
import { PatientSearchResult } from "domains/patient/types";
import { mapNativePatientSearchResponse } from "domains/patient/utils/format/mappers";

type MutationVariables = EmisSearchState | EmrSearchState;

export type SearchPatientsQueryPayload = {
    searchTerm?: string;
    nhsNumber?: string;
    firstName?: string;
    lastName?: string;
    dateOfBirth?: string;
};

export const SearchForPatientInEmrKey = "SearchForPatientInEmr";

export const useEmrPatientSearch = (
    options: UseMutationOptions<
        PatientSearchResult[],
        NativeTransportError,
        MutationVariables
    >,
) => {
    const transport = useNativeTransport();
    const workspaceId = useCurrentWorkspace().orgId;

    return useMutation(
        [SearchForPatientInEmrKey],
        async (variables: MutationVariables) => {
            if (!transport) {
                throw new NativeTransportError(
                    "SearchPatientsQuery: Called with no native transport",
                );
            }

            const result = await querySearchPatientsQuery(
                { transport, workspaceId },
                variables,
            );
            return mapNativePatientSearchResponse(result);
        },
        { ...options },
    );
};
