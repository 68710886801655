import {
    CollapsibleInfo,
    Ds,
    FormFieldWrapper,
    Option,
    Select,
} from "@accurx/design";

type AppointmentTypeFilterProps = {
    options: Option[];
    initialOption?: Option;
    onChange: (selected: Option) => void;
    errors: string[] | undefined;
};

export function AppointmentTypeFilter({
    options,
    initialOption,
    onChange,
    errors,
}: AppointmentTypeFilterProps) {
    const label = "Appointment Type";
    const id = "appointment-type-filter";

    return (
        <>
            <FormFieldWrapper
                label={label}
                labelProps={{ htmlFor: id }}
                subLabel="Select appointment type for this reminder"
                errors={errors}
            >
                <Select
                    id={id}
                    label={label}
                    options={options}
                    // Types here are inaccurate, returns Option for single select
                    onChangeHandler={(selected) => onChange(selected as Option)}
                    initSelectedValues={initialOption}
                    defaultCopy={{
                        placeholder: "Select appointment type",
                        valueSelectAll: `Select all appointment types`,
                    }}
                    error={errors && errors.length > 0}
                />
            </FormFieldWrapper>
            <CollapsibleInfo title="Why are some appointment types not available?">
                <Ds.Text>
                    If you can’t select an appointment type, it's because it has
                    either been used with all clinics or with one of your
                    currently selected clinics.
                    {/* TODO: Add a link to the new support article */}
                </Ds.Text>
            </CollapsibleInfo>
        </>
    );
}
