import { Ds } from "@accurx/design";
import { i } from "images";

export const NavAccurxLogo = () => {
    return (
        <Ds.Box ml="1">
            <img
                src={i("accurx-logo-small.svg")}
                width={24}
                height={14}
                alt=""
            />
        </Ds.Box>
    );
};
