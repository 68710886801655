import { createQueryHook } from "@accurx/api/QueryClient";
import {
    ExternalEmailContact,
    GetContactsResponse,
} from "@accurx/api/clinician-messaging";
import { httpClient2 } from "@accurx/shared";

type DirectorySearchQueryVariables = {
    workspaceId: number;
    searchTerm: string;
};

export const DirectorySearchQueryKey = "DirectorySearchQuery";

export const useDirectorySearchQuery = createQueryHook<
    DirectorySearchQueryVariables,
    ExternalEmailContact[]
>(({ workspaceId, searchTerm }) => ({
    queryKey: [DirectorySearchQueryKey, { workspaceId, searchTerm }],
    queryFn: async () => {
        const { contacts } = await httpClient2
            .get({
                path: "/api/clinicianmessaging/directory/:workspaceId/contacts",
                params: { workspaceId },
                query: searchTerm
                    ? `?searchTerm=${searchTerm}`
                    : "?sortBy=Distance",
            })
            .json<GetContactsResponse>();

        return contacts;
    },
}));
