import { useEffect, useRef, useState } from "react";

type Callback = () => void;

/**
 * Defers the execution of a callback function until a specific condition is met (e.g. data is fully loaded, another async function is completed).
 * This allows components to remain "actionable" or "idle" and only show a loading state once actioned but are still waiting for something else.
 *
 * Example usage:
 * ```tsx
 * const {status} = useMyQuery();
 * const {pending, defer} = useDeferredCallback(status === "loading");
 *
 * <Button
 *  loading={pending}
 *  onClick={() => {
 *      defer(() => doSomething());
 *  }}
 * />
 * ```
 */
export const useDeferredCallback = (
    loading: boolean,
): {
    pending: boolean;
    defer: (callback: Callback) => void;
} => {
    const callbackToDefer = useRef<Callback>();

    const [isDeferring, setIsDeferring] = useState(false);

    useEffect(() => {
        if (callbackToDefer.current && !loading) {
            callbackToDefer.current();

            callbackToDefer.current = undefined;
            setIsDeferring(false);
        }
    }, [isDeferring, loading]);

    return {
        pending: isDeferring,
        defer: (callback) => {
            callbackToDefer.current = callback;
            setIsDeferring(true);
        },
    };
};
