import { useCurrentWorkspace } from "@accurx/auth";
import * as UI from "@accurx/design";
import {
    useMedicalRecordConnection,
    useNativeSubscription,
} from "@accurx/native";
import { Redirect, generatePath, useLocation } from "react-router-dom";

import { ROUTES_COMPOSE } from "../Routes.constants";
import { ComposeVariant } from "../Routes.types";
import { ClinicianComposeNewMessage } from "../clinicianCompose/ClinicianComposeNewMessage";
import { StyledPatientHeaderWrapper } from "../components/Page.styles";
import { ComposeHeader } from "../components/PatientHeader/ComposeHeader";
import { RestrictedWidthContainer } from "../components/RestrictedWidthContainer";
import { ComposeNewMessage } from "./ComposeNewMessage";
import { useCurrentPatient } from "./context/CurrentPatientProvider";
import { StyledSpinnerWrapper } from "./context/CurrentPatientProvider.styles";
import { useAllowWindowClose } from "./hooks/useAllowWindowClose";

export const ComposePage = ({
    composeVariant,
    onMessageSent,
}: {
    composeVariant: ComposeVariant;
    onMessageSent: (conversationId: string) => void;
}) => {
    const medicalRecordConnection = useMedicalRecordConnection();
    const workspace = useCurrentWorkspace();

    const { currentPatient, isPatientStale } = useCurrentPatient();
    const { status: medicalRecordPatientFeed } = useNativeSubscription(
        "SubscribeCurrentPatient",
    );
    const location = useLocation();

    // Since there's no compose state while refetching the stale patient,
    // allow the window to close since there is no message to discard.
    useAllowWindowClose({ enabled: isPatientStale });

    if (currentPatient === null) {
        return (
            <Redirect
                to={{
                    pathname: generatePath(ROUTES_COMPOSE.search, {
                        workspaceId: workspace.orgId,
                    }),
                    search: `?composeVariant=${composeVariant}`,
                    state: location.state,
                }}
            />
        );
    }

    const { patient, contactDetails } = currentPatient;

    return (
        <>
            {medicalRecordConnection.status === "Disconnected" && (
                <UI.Ds.Box p="2" backgroundColor="white" pb="0">
                    <UI.Feedback
                        colour="warning"
                        title="Unable to connect to the medical system"
                    >
                        Some features including save to record are unavailable
                    </UI.Feedback>
                </UI.Ds.Box>
            )}

            <StyledPatientHeaderWrapper>
                <ComposeHeader patient={patient} />
            </StyledPatientHeaderWrapper>

            {medicalRecordConnection.status === "Connected" &&
                medicalRecordPatientFeed === "error" && (
                    <UI.Feedback colour="warning">
                        There was a problem fetching the patient form the EMR,
                        the current patient may not match the one selected in
                        the EMR.
                    </UI.Feedback>
                )}

            {isPatientStale && (
                <StyledSpinnerWrapper>
                    <UI.Ds.Spinner />
                </StyledSpinnerWrapper>
            )}
            {!isPatientStale && (
                <RestrictedWidthContainer>
                    {composeVariant === "Clinician" ? (
                        <ClinicianComposeNewMessage
                            patient={patient}
                            onMessageSent={onMessageSent}
                        />
                    ) : (
                        <ComposeNewMessage
                            patient={patient}
                            contactDetails={contactDetails}
                            onMessageSent={onMessageSent}
                        />
                    )}
                </RestrictedWidthContainer>
            )}
        </>
    );
};
