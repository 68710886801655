import {
    ChangeEvent,
    ClipboardEvent,
    FormEvent,
    forwardRef,
    useEffect,
    useState,
} from "react";

import { Ds, Input } from "@accurx/design";
import { StyledClearButton, StyledFormFieldWrapper } from "@accurx/navigation";
import { useEmrPatientSearch } from "domains/patient/hooks/mutations/useEmrPatientSearch";
import { PatientSearchResult } from "domains/patient/types";

import {
    EmrNhsSearchState,
    FIELDS_ID,
    FORM_ACTION,
    FORM_ID,
    FORM_LABELS,
    FormAction,
    INIT_STATE,
    TEST_ID,
    sanitise,
} from "../PatientSearchForm.helpers";
import {
    EmrNhsSearchError,
    EmrNhsSearchSchema,
    formatErrors,
} from "../PatientSearchFormSchema";

export type EmrNhsSearchProps = {
    formData: EmrNhsSearchState;
    formAction: React.Dispatch<FormAction<EmrNhsSearchState>>;
    onSubmit: (data: PatientSearchResult[]) => void;
    onError: (error: Error) => void;
    onValidationError: (errorReason: string[]) => void;
    onClear?: () => void;
    isFormDisabled?: boolean;
    onLoading?: (isLoading: boolean) => void;
    children: React.ReactChild;
};

export const EmrNhsSearch = forwardRef<HTMLFormElement, EmrNhsSearchProps>(
    (
        {
            formData,
            formAction,
            onSubmit,
            onError,
            onValidationError,
            onClear,
            onLoading,
            isFormDisabled,
            children,
        },
        forwardedRef,
    ) => {
        const { mutate, isLoading } = useEmrPatientSearch({
            onError: (error) => onError(error),
            onSuccess: (data) => onSubmit(data),
        });
        const [fieldErrors, setFieldErrors] =
            useState<EmrNhsSearchError | null>(null);

        useEffect(() => {
            onLoading?.(isLoading);
        }, [isLoading, onLoading]);

        const handleNhsNumberChange = (
            e: ChangeEvent<HTMLInputElement>,
        ): void => {
            const nhsNumber = sanitise(e.target.value);
            formAction({ type: FORM_ACTION.UPDATE, payload: { nhsNumber } });
            checkFormIsCleared({
                ...formData,
                nhsNumber: nhsNumber,
            });
        };

        // Dedicated paste handler for NHS numbers formatted with spaces
        const handleNhsNumberPaste = (
            e: ClipboardEvent<HTMLInputElement>,
        ): void => {
            e.preventDefault();
            const nhsNumber = sanitise(e.clipboardData.getData("Text"));
            formAction({ type: FORM_ACTION.UPDATE, payload: { nhsNumber } });
        };

        const checkFormIsCleared = (formData: EmrNhsSearchState) => {
            if (!formData.nhsNumber) {
                onClear?.();
            }
        };

        const handleClear = () => {
            setFieldErrors(null);
            formAction({
                type: FORM_ACTION.UPDATE,
                payload: INIT_STATE.EMR_NHS,
            });
            onClear?.();
        };

        const handleSubmit = (e: FormEvent) => {
            e.preventDefault();

            const result = EmrNhsSearchSchema.safeParse(formData);
            if (result.success) {
                setFieldErrors(null);
                mutate(formData);
            } else {
                const submitErrors = formatErrors<EmrNhsSearchError>(
                    result.error,
                );
                setFieldErrors(submitErrors);
                if (submitErrors.nhsNumber) {
                    onValidationError([submitErrors.nhsNumber]);
                }
            }
        };

        return (
            <form
                ref={forwardedRef}
                onSubmit={handleSubmit}
                id={FORM_ID.EMR}
                data-testid={TEST_ID.EMR}
                noValidate
            >
                <Ds.Flex gap="1.5" flexDirection="column">
                    <StyledFormFieldWrapper
                        label={FORM_LABELS.nhsNumber}
                        labelProps={{
                            htmlFor: `${FORM_ID.EMR}-input-${FIELDS_ID.NHS_NUMBER}`,
                        }}
                        errors={[fieldErrors?.nhsNumber ?? null]}
                    >
                        <Input
                            id={`${FORM_ID.EMR}-input-${FIELDS_ID.NHS_NUMBER}`}
                            value={formData.nhsNumber}
                            inputMode="numeric"
                            pattern="[0-9]*"
                            minLength={10}
                            maxLength={10}
                            onChange={handleNhsNumberChange}
                            onPaste={handleNhsNumberPaste}
                            disabled={isFormDisabled}
                            data-userflow-id="patient-search-nhs-number-input"
                            hasErrors={Boolean(fieldErrors?.nhsNumber)}
                        />
                        {formData.nhsNumber && (
                            <StyledClearButton
                                type="button"
                                onClick={handleClear}
                            >
                                <Ds.Text weight="bold">Clear</Ds.Text>
                            </StyledClearButton>
                        )}
                    </StyledFormFieldWrapper>
                    <Ds.Flex gap="1" justifyContent="space-between">
                        {children}
                        <Ds.Button
                            type="submit"
                            disabled={isFormDisabled}
                            aria-label="Search for a patient"
                        >
                            <Ds.Button.Icon name="Search" />
                        </Ds.Button>
                    </Ds.Flex>
                </Ds.Flex>
            </form>
        );
    },
);
