import { SearchForPatientByDemographicsRequest } from "@accurx/api/portal";
import { useCurrentWorkspace } from "@accurx/auth";
import {
    IWrappedResult,
    getApiEndpoint,
    httpClient,
    returnDataOrThrow,
} from "@accurx/shared";
import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import { isTestPatient } from "domains/patient/components/PatientSearch/PatientSearchForm/PatientSearchForm.helpers";
import { SearchForPatientSuccess } from "domains/patient/schemas/SearchForPatientSchema";
import { PatientSearchResult } from "domains/patient/types";
import { mapPatientSearchResponse } from "domains/patient/utils/format/mappers";

import { useAddPatientToRecentSearches } from "./useAddPatientToRecentSearches";

export const SearchForPatientByDemographicsKey =
    "SearchForPatientByDemographics";

type MutationVariables = {
    firstName: string;
    lastName: string;
    postcode: string;
    gender: string;
    dateOfBirth: {
        year: string;
        month: string;
        day: string;
    };
};

const searchForPatient = async (
    payload: SearchForPatientByDemographicsRequest,
): Promise<IWrappedResult<SearchForPatientSuccess>> =>
    httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: "/api/portal/SearchForPatient/ByDemographics",
        }),
        payload,
    );

export const useAdvancedPatientSearch = (
    options: UseMutationOptions<
        PatientSearchResult[],
        Error,
        MutationVariables
    >,
) => {
    const { mutate: saveToRecentSearches } = useAddPatientToRecentSearches();
    const workspaceId = useCurrentWorkspace().orgId;

    return useMutation(
        [SearchForPatientByDemographicsKey],
        async ({
            firstName,
            lastName,
            postcode,
            gender,
            dateOfBirth,
        }: MutationVariables) => {
            const payload = {
                firstName,
                lastName,
                postcode,
                gender,
                dateOfBirthYear: Number(dateOfBirth.year),
                dateOfBirthMonth: Number(dateOfBirth.month),
                dateOfBirthDay: Number(dateOfBirth.day),
                organisationId: workspaceId,
            };

            const response = await searchForPatient(payload);
            const result = returnDataOrThrow(response);
            return mapPatientSearchResponse(result);
        },
        {
            ...options,
            onSuccess: (data, variables, context) => {
                const patient = data.length ? data[0] : null;
                if (patient?.nhsNumber && !isTestPatient(patient.nhsNumber)) {
                    void saveToRecentSearches({
                        organisationId: workspaceId,
                        patientToken: patient.patientToken,
                    });
                }
                options.onSuccess?.(data, variables, context);
            },
        },
    );
};
