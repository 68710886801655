import { Ds } from "@accurx/design";
import { PatientHeaderLoading } from "@accurx/patient";

import { StyledContainer } from "./ConversationViewHeader.styles";

export const ConversationViewHeaderLoading = () => (
    <StyledContainer>
        <Ds.Flex.Item flex="1">
            <PatientHeaderLoading />
        </Ds.Flex.Item>
    </StyledContainer>
);
