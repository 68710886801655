import { Ds } from "@accurx/design";
import styled from "styled-components";

type FooterProps = {
    children: React.ReactNode;
};

export const DirectoryModalFooter = ({ children }: FooterProps) => {
    return (
        <ModalSection>
            <ModalDivider />
            {children}
        </ModalSection>
    );
};

const ModalSection = styled(Ds.Modal.Section)`
    margin: 0 -1rem;
`;

const ModalDivider = styled(Ds.Modal.Divider)`
    margin-bottom: 1rem;
`;
