import { MultiSelectOption } from "../types";

export const formatSelectedInputObjects = (
    selectedArr: string[],
): MultiSelectOption[] => {
    return selectedArr.map((item) => {
        return {
            value: item,
            label: item,
            disabled: false,
        };
    });
};
