import React, { ChangeEvent, useState } from "react";

import {
    Button,
    Card,
    Feedback,
    Text,
    TextareaAutosize,
    Tokens,
} from "@accurx/design";

import { UpdatingStatusType } from "shared/LoadingStatus";

import {
    PatientTriageStatus,
    WorkingHoursSuspensionMessageSettings,
} from "../../types";
import { UrgentHelpSection } from "../UrgentHelpSection/UrgentHelpSection";

export type WorkingHoursLimitMessageFormProps = {
    isEditable: boolean;
    status: PatientTriageStatus;
    triageSettingsUpdatingStatus: UpdatingStatusType;
    workingHoursSuspensionPageMessage: string | null;
    handleSubmit: (
        values: Omit<
            WorkingHoursSuspensionMessageSettings,
            "handleSubmit" | "isEditable"
        >,
    ) => void;
};

const defaultSuspensionMessageText =
    "We have paused access to contact us online as we have reached capacity for the day. Please select one of the available options.";

export const WorkingHoursSuspensionMessageForm = ({
    status,
    triageSettingsUpdatingStatus,
    workingHoursSuspensionPageMessage,
    handleSubmit,
}: WorkingHoursLimitMessageFormProps): JSX.Element => {
    const isDisabled =
        status.isAdmin === false || triageSettingsUpdatingStatus.updating;
    const [isEditing, setIsEditing] = useState(false);
    const [suspensionMessage, setSuspensionMessage] = useState(
        workingHoursSuspensionPageMessage,
    );

    const onSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
        e.preventDefault();

        handleSubmit({
            messageHeader: suspensionMessage,
        });

        setIsEditing(false);
    };

    const updateSuspensionMessage = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setSuspensionMessage(e.target.value);
    };

    return (
        <form onSubmit={onSubmit}>
            <Card
                props={{ className: "mb-3" }}
                header={
                    <Text as="h3" skinny variant="subtitle">
                        Edit daily limit suspension message
                    </Text>
                }
                footer={
                    <div className="d-flex justify-content-end">
                        {isEditing ? (
                            <>
                                <Button
                                    theme="primary"
                                    data-testid="workingHoursSuspensionMessageSave"
                                    disabled={isDisabled}
                                    type="submit"
                                    text="Save Changes"
                                />
                                <Button
                                    className="ml-3"
                                    theme="secondary"
                                    onClick={(): void => {
                                        setSuspensionMessage(
                                            defaultSuspensionMessageText,
                                        );
                                        handleSubmit({
                                            messageHeader: null,
                                        });
                                        setIsEditing(false);
                                    }}
                                    data-testid="workingHoursSuspensionMessageReset"
                                    disabled={isDisabled}
                                    type="button"
                                    text="Reset to default"
                                />
                                <Button
                                    className="ml-3"
                                    dimension="medium"
                                    theme="transparent"
                                    onClick={(): void => {
                                        setIsEditing(false);
                                        setSuspensionMessage(
                                            workingHoursSuspensionPageMessage,
                                        );
                                    }}
                                    data-testid="workingHoursSuspensionMessageCancel"
                                    text="Cancel"
                                    type="button"
                                />
                            </>
                        ) : (
                            <Button
                                icon={{ name: "Pencil" }}
                                onClick={(): void => setIsEditing(true)}
                                disabled={isDisabled}
                                data-testid="workingHoursSuspensionMessageEdit"
                                type="button"
                                text="Edit"
                            />
                        )}{" "}
                    </div>
                }
            >
                <div>
                    <Text>
                        This is the message shown when Patient Triage is
                        suspended due to hitting your daily limit. The message
                        is only available during working hours.
                    </Text>

                    <div
                        className="p-2"
                        style={{
                            backgroundColor: Tokens.COLOURS.greyscale.cloud,
                        }}
                    >
                        <Text variant="label" props={{ className: "mb-2" }}>
                            Your current message:
                        </Text>

                        <Feedback
                            colour="warning"
                            content={
                                <>
                                    {isEditing ? (
                                        <TextareaAutosize
                                            cols={80}
                                            className="w-100"
                                            name="workingHoursSuspensionCopy"
                                            id="workingHoursSuspensionCopy"
                                            data-testid="workingHoursSuspensionCopy"
                                            disabled={isDisabled}
                                            onChange={updateSuspensionMessage}
                                            value={
                                                suspensionMessage ??
                                                defaultSuspensionMessageText
                                            }
                                        />
                                    ) : (
                                        <Text skinny>
                                            {suspensionMessage ??
                                                defaultSuspensionMessageText}
                                        </Text>
                                    )}
                                    <UrgentHelpSection region={status.region} />
                                </>
                            }
                        />
                    </div>
                </div>
            </Card>
        </form>
    );
};
