import { MessageTemplateResponse } from "@accurx/api/patient-messaging";
import { Feedback } from "@accurx/design";
import { IWrappedResult, Log } from "@accurx/shared";
import { toast } from "react-toastify";

import { IMessageTemplateRequest } from "api/FlemingDtos";
import {
    getTemplatesByOrganisation,
    getTemplatesByUser,
    getTemplatesManagementView,
} from "api/PatientMessagingServer/PatientMessagingServerApi";
import { FlemingAnalyticsTracker } from "app/analytics";
import { TemplateInList } from "app/workspaceConversations/components/MessageTemplates/MessageTemplates.types";

import {
    mapMessageTemplateManagementViewToTemplateManagementEntity,
    mapMessageTemplateResponseToTemplateInList,
} from "./MessageTemplatesHelper";

// ACTION TYPES
export const REQUEST_MESSAGE_TEMPLATES_STARTED =
    "REQUEST_MESSAGE_TEMPLATES_STARTED";
export const REQUEST_MESSAGE_TEMPLATES_SUCCESS =
    "REQUEST_MESSAGE_TEMPLATES_SUCCESS";
export const REQUEST_MESSAGE_TEMPLATES_FAILURE =
    "REQUEST_MESSAGE_TEMPLATES_FAILED";

export const REQUEST_CREATE_EDIT_MESSAGE_TEMPLATES_SUCCESS =
    "REQUEST_CREATE_EDIT_MESSAGE_TEMPLATES_SUCCESS";

export const SET_LAST_ACTION_LOCATION = "SET_LAST_ACTION_LOCATION";

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestMessageTemplatesStartedAction {
    type: typeof REQUEST_MESSAGE_TEMPLATES_STARTED;
}

interface RequestMessageTemplatesSuccessAction {
    type: typeof REQUEST_MESSAGE_TEMPLATES_SUCCESS;
    userTemplates: TemplateInList[];
}

interface RequestMessageTemplatesFailureAction {
    type: typeof REQUEST_MESSAGE_TEMPLATES_FAILURE;
}

interface RequestCreateEditMessageTemplatesSuccessAction {
    type: typeof REQUEST_CREATE_EDIT_MESSAGE_TEMPLATES_SUCCESS;
    userTemplate: TemplateInList;
}

interface SetLastActionLocation {
    type: typeof SET_LAST_ACTION_LOCATION;
    location: FlemingAnalyticsTracker.FlemingAnalyticsTemplateLocationType;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction =
    | RequestMessageTemplatesStartedAction
    | RequestMessageTemplatesSuccessAction
    | RequestMessageTemplatesFailureAction
    | RequestCreateEditMessageTemplatesSuccessAction
    | SetLastActionLocation;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

const editOrCreateMessageTemplateSuccess = (
    template: TemplateInList,
): RequestCreateEditMessageTemplatesSuccessAction => {
    return {
        type: REQUEST_CREATE_EDIT_MESSAGE_TEMPLATES_SUCCESS,
        userTemplate: template,
    };
};

const fetchUserAndOrganisationMessageTemplates = async (
    organisationId: number,
    isApprovedUser: boolean,
): Promise<IWrappedResult<MessageTemplateResponse[]>> => {
    const request = {
        organisationId,
        includeVideo: true,
    };
    const promiseGetTemplatesByUser = getTemplatesByUser(request);
    const promiseGetTemplatesByOrganisation = isApprovedUser
        ? getTemplatesByOrganisation(request)
        : Promise.resolve<IWrappedResult<MessageTemplateResponse[]>>({
              success: true,
              result: [],
              error: null,
          });

    const resultAll = await Promise.all<
        IWrappedResult<MessageTemplateResponse[]>
    >([promiseGetTemplatesByUser, promiseGetTemplatesByOrganisation]);
    const resultByUser = resultAll[0];
    const resultByOrg = resultAll[1];

    if (
        resultByUser.success &&
        resultByUser.result &&
        resultByOrg.success &&
        resultByOrg.result
    ) {
        return {
            success: true,
            result: resultByUser.result.concat(resultByOrg.result),
            error: null,
        };
    }

    return { success: false, result: null, error: null };
};

type getUserMessageTemplatesRequest = IMessageTemplateRequest & {
    isApprovedUser: boolean;
};

export const actionCreators = {
    getUserMessageTemplates:
        (
            request: getUserMessageTemplatesRequest,
            showErrorToast = false,
        ): AppThunkAction<KnownAction> =>
        async (dispatch): Promise<void> => {
            dispatch({
                type: REQUEST_MESSAGE_TEMPLATES_STARTED,
            });

            const response = request.organisationId
                ? await fetchUserAndOrganisationMessageTemplates(
                      request.organisationId,
                      request.isApprovedUser,
                  )
                : { success: false, result: null, error: null };

            if (response.success && response.result) {
                /**
                 * Map PatientMessaging dtos into TemplateInList and add preview URLs for attachments
                 */
                const userTemplates = response.result.map((template) =>
                    mapMessageTemplateResponseToTemplateInList(
                        template,
                        request.organisationId,
                    ),
                );

                return dispatch({
                    type: REQUEST_MESSAGE_TEMPLATES_SUCCESS,
                    userTemplates,
                });
            }

            if (showErrorToast) {
                toast(
                    Feedback({
                        colour: "error",
                        title: "Couldn't fetch custom templates",
                        content: "Please refresh to try again",
                    }),
                );
            }

            return dispatch({
                type: REQUEST_MESSAGE_TEMPLATES_FAILURE,
            });
        },

    getTemplatesForManagementView:
        (
            request: IMessageTemplateRequest,
            waitingListValidationEnabled: boolean,
            showErrorToast = false,
        ): AppThunkAction<KnownAction> =>
        async (dispatch): Promise<void> => {
            dispatch({
                type: REQUEST_MESSAGE_TEMPLATES_STARTED,
            });

            if (request.organisationId !== null) {
                const response = await getTemplatesManagementView({
                    organisationId: request.organisationId,
                    includeVideo: true,
                });
                if (response.success && response.result) {
                    return dispatch({
                        type: REQUEST_MESSAGE_TEMPLATES_SUCCESS,
                        userTemplates:
                            mapMessageTemplateManagementViewToTemplateManagementEntity(
                                response.result.templates.filter(
                                    (templateManagementView) => {
                                        let isPassingFilter =
                                            templateManagementView.owner ===
                                                "User" ||
                                            templateManagementView.owner ===
                                                "Organisation";

                                        if (!waitingListValidationEnabled) {
                                            isPassingFilter =
                                                isPassingFilter &&
                                                templateManagementView.isAllowedAsSms;
                                        }

                                        return isPassingFilter;
                                    },
                                ),
                                request.organisationId,
                            ),
                    });
                }
            }

            if (showErrorToast) {
                toast(
                    Feedback({
                        colour: "error",
                        title: "Couldn't fetch custom templates",
                        content: "Please refresh to try again",
                    }),
                );
            }

            Log.error(
                "Attempted to get template for management view but required info is missing",
            );

            return dispatch({
                type: REQUEST_MESSAGE_TEMPLATES_FAILURE,
            });
        },

    editOrCreateMessageTemplateSuccess,

    setTemplateLastActionLocation:
        (
            location: FlemingAnalyticsTracker.FlemingAnalyticsTemplateLocationType,
        ): AppThunkAction<KnownAction> =>
        (dispatch): void => {
            dispatch({
                type: SET_LAST_ACTION_LOCATION,
                location,
            });
        },
};
