import React from "react";

import { useAccurxWebTitle } from "@accurx/navigation";

import { AccountPageLayout } from "./AccountPageLayout";
import { SettingsProfile } from "./components/SettingsProfile";

const UserProfile = () => {
    useAccurxWebTitle("Update name and email address");
    return (
        <AccountPageLayout title="Name and email">
            <SettingsProfile hideHeader />
        </AccountPageLayout>
    );
};
export { UserProfile };
