import { ExternalEmailContact } from "@accurx/api/clinician-messaging";
import { Ds } from "@accurx/design";

import { ContactSelectCard } from "../ContactSelectCard/ContactSelectCard";
import { groupContactsByInstitution } from "./groupContactsByInstitution";

type GroupedContactListProps = {
    contacts: ExternalEmailContact[];
};

export const GroupedContactList = ({ contacts }: GroupedContactListProps) => {
    const groupedContacts = groupContactsByInstitution(contacts);
    return (
        <Ds.Flex flexDirection="column" gap="2">
            {groupedContacts.map((contacts) => {
                const primaryContact = contacts[0];
                const institutionId = primaryContact.institution?.id;
                const institutionName =
                    primaryContact.institution?.name ?? "Unknown organisation";

                return (
                    <Ds.Flex
                        flexDirection="column"
                        data-testid="contact-group-wrapper"
                        key={`${institutionId}-${primaryContact.id}`}
                    >
                        <Ds.Box mb="1">
                            <Ds.Text as="h3">{institutionName}</Ds.Text>
                        </Ds.Box>
                        <Ds.Flex flexDirection="column" gap="1">
                            {contacts.map((contact) => (
                                <ContactSelectCard
                                    contact={contact}
                                    data-testid={contact.id}
                                    key={contact.id}
                                >
                                    <Ds.Text color="metal">
                                        {contact.service?.name}
                                    </Ds.Text>
                                </ContactSelectCard>
                            ))}
                        </Ds.Flex>
                    </Ds.Flex>
                );
            })}
        </Ds.Flex>
    );
};
