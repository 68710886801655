import { useMemo } from "react";

import { Conversation, PatientSummary } from "@accurx/concierge/types";
import {
    ContactDetail,
    mapPatientDemographicsContactsToContactDetails,
} from "@accurx/message-component";
import { PatientDemographicsDto, useBrowserEnvironment } from "@accurx/native";
import { usePatientDemographicsQuery } from "domains/inbox/hooks/queries/usePatientDemographicsQuery";
import { useSearchForPatientQuery } from "domains/inbox/hooks/queries/useSearchForPatientQuery";
import { SearchForPatientSuccess } from "domains/inbox/schemas/SearchForPatientSchema";

import { getPatientContactsWithDefault } from "./usePatientContactDetails/getPatientContactsWithDefault";

const emrDataToContactDetails = (
    emrData: PatientDemographicsDto["patientDemographics"],
): ContactDetail[] => {
    return mapPatientDemographicsContactsToContactDetails(emrData.contact);
};

const pdsDataToContactDetails = (
    pdsData: SearchForPatientSuccess,
): ContactDetail[] => {
    if (!pdsData.searchedResult.patient?.mobileNumber) {
        return [];
    }

    // We don't validate mobile numbers from PDS. This is because the server obfuscates them before returning
    // E.g. ********645 - which would always fail validation.
    return [
        {
            method: "Mobile",
            value: pdsData.searchedResult.patient.mobileNumber,
            origin: "PDS",
        },
    ];
};

/** This hook will return patient contact details from either the medical record or a PDS search,
 * If there is a medical record connection, we'll query the the medical record. If there is not a
 * medical record connection, we'll do a PDS search.
 *
 * It will also return contact details from the provided conversation.
 */
export const usePatientContactDetails = ({
    patient,
    conversation,
    enabled = true,
}: {
    patient: PatientSummary;
    conversation: Conversation;
    enabled?: boolean;
}) => {
    const browserEnv = useBrowserEnvironment();

    const shouldFetchDemographicsFromMedicalRecord = browserEnv === "WebView";

    const { data: emrData, status: emrStatus } = usePatientDemographicsQuery<
        ContactDetail[]
    >({
        patientId: patient.patientId,
        externalIds: patient.externalIds,
        enabled: shouldFetchDemographicsFromMedicalRecord && enabled,
        select: emrDataToContactDetails,
    });

    const { data: pdsData, status: pdsStatus } = useSearchForPatientQuery<
        ContactDetail[]
    >({
        patient,
        enabled: !shouldFetchDemographicsFromMedicalRecord && enabled,
        select: pdsDataToContactDetails,
    });

    const searchForPatientQueryResult = shouldFetchDemographicsFromMedicalRecord
        ? { data: emrData, status: emrStatus }
        : { data: pdsData, status: pdsStatus };

    const patientContactDetails = useMemo(() => {
        return getPatientContactsWithDefault({
            conversation,
            demographicsData: searchForPatientQueryResult.data,
        });
    }, [conversation, searchForPatientQueryResult.data]);

    return {
        data: patientContactDetails,
        status: searchForPatientQueryResult.status,
    };
};
