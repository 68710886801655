import { useAnalytics } from "@accurx/analytics";
import { useNativeTrackingFields } from "@accurx/native";

type MessageSaveCustomPropertiesBase = {
    isCurrentUserMessage: boolean;
    withAttachment: boolean;
    medicalRecordSystem: string;
};

export const useMessageSaveAnalytics = () => {
    const track = useAnalytics();
    const nativeTrackingFields = useNativeTrackingFields();

    const trackMessageSaveButtonClick = (
        customProperties: MessageSaveCustomPropertiesBase,
    ) => {
        track("MessageSave Button Click", {
            ...nativeTrackingFields,
            ...customProperties,
            eventVersion: 2,
        });
    };

    const trackMessageSaveButtonResponse = (
        customProperties: MessageSaveCustomPropertiesBase & {
            hasError: boolean;
        },
    ) => {
        track("MessageSave Button Response", {
            ...nativeTrackingFields,
            ...customProperties,
            eventVersion: 2,
        });
    };

    return { trackMessageSaveButtonClick, trackMessageSaveButtonResponse };
};
