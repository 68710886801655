import {
    MaterialisedPatientDemographics,
    MaterialisedPatientDemographicsSchema,
} from "domains/native/schemas/MaterialisedPatientDemographicsSchema";
import { PatientExternalIdentityDto } from "domains/native/schemas/PatientExternalIdentityDtoSchema";

import { NativeApi } from "../NativeApi";
import { createEndpoint } from "../api.utils";

export type PatientDemographicsQueryPayload = {
    patientExternalIdentityDto: PatientExternalIdentityDto;
    /**
     * Whether or not the demographics found in the query should be saved
     * to the database. Before setting to `true` consider if we have a legitimate
     * reason to store these demographics.
     *
     * If the user has not taken any action on this patient (e.g. sent them a
     * message) we likely have no basis for storing this information.
     */
    pushToServer: boolean;
};

export const queryPatientDemographics = createEndpoint<
    MaterialisedPatientDemographics,
    PatientDemographicsQueryPayload
>("QueryPatientDemographics", async (context, payload) => {
    const result = await NativeApi.request({
        transport: context.transport,
        request: {
            type: "QueryPatientDemographics",
            workspaceId: context.workspaceId,
            payload: payload,
        },
    });

    return MaterialisedPatientDemographicsSchema.parse(result);
});
