import { Patient } from "@accurx/api/portal";
import { FeatureName } from "@accurx/auth";
import { SearchForPatientPatient } from "@accurx/patient";
import { shallowEqual } from "react-redux";

import { PatientHelper } from "shared/PatientHelper";
import { useAppSelector, useIsFeatureEnabled } from "store/hooks";

import { isMessageGpDisabled } from "./PatientProfile.helper";

type MessagePracticeAvailabilityWithPatientData =
    | {
          canMessageGp: true;
          patient: Patient;
      }
    | {
          canMessageGp: false;
          patient?: Patient;
      };

export const useMessagePracticeAvailabilityWithPatientData = (
    patient?: SearchForPatientPatient,
): MessagePracticeAvailabilityWithPatientData => {
    const reduxPatient = useAppSelector(
        ({ searchForPatient }) =>
            PatientHelper.getPatient(searchForPatient.lastResponse),
        shallowEqual,
    );
    const currentPatient = patient ?? reduxPatient;

    const enabledForUser = useIsFeatureEnabled(FeatureName.CaregiverInitiated);

    if (!currentPatient) {
        return {
            canMessageGp: false,
        };
    }

    const enabledForPatient = isMessageGpDisabled(currentPatient) === null;

    const canMessageGp = enabledForUser && enabledForPatient;

    return { canMessageGp, patient: currentPatient };
};
