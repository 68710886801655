import React from "react";

import { Ds } from "@accurx/design";

type AccessBadgeProps = {
    status: "available" | "limited" | "unavailable";
};

export const AccessBadge = ({ status }: AccessBadgeProps): JSX.Element => {
    switch (status) {
        case "available":
            return (
                <Ds.Badge color="green">
                    <Ds.Icon name="Tick" />
                    Available
                </Ds.Badge>
            );

        case "limited":
            return (
                <Ds.Badge color="yellow">
                    <Ds.Icon name="LockLocked" appearance="solid" />
                    Limited
                </Ds.Badge>
            );

        case "unavailable":
        default:
            return (
                <Ds.Badge>
                    <Ds.Icon name="LockLocked" appearance="solid" />
                    Unavailable
                </Ds.Badge>
            );
    }
};
