import { useEffect } from "react";

import { useCurrentWorkspace } from "@accurx/auth";
import { Feedback, Text } from "@accurx/design";

import { trackManageTemplatePageView } from "app/analytics/FlemingAnalytics";
import { useAccumailTemplatesQuery } from "app/hooks/queries";
import { Spinner } from "app/organisations/shared";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";

import { AccumailTemplateList } from "../../templateList/AccumailTemplateList";
import { IntroductionContainer } from "../ManageAccumailTemplates.styles";

export const ManageAccurxTemplates = () => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const { orgId: workspaceId } = useCurrentWorkspace();
    const { isLoading, isError, data } = useAccumailTemplatesQuery({
        workspaceId,
        templateType: "GlobalTemplate",
    });
    const flemingLoggedInProperties = useFlemingLoggedInAnalytics();

    useEffect(() => {
        trackManageTemplatePageView({
            ...flemingLoggedInProperties,
            countTemplateCategories: 0, // Accumail templates don't have categories
            currentTab: "AccurxTemplates",
            productOrigin: "ClinicianMessage",
        });
    }, [flemingLoggedInProperties]);

    return (
        <>
            <IntroductionContainer>
                <Text variant="subtitle" as="h2">
                    Accurx Templates
                </Text>
                <Text>
                    These templates are provided by Accurx and cannot be
                    deleted. If you would like to create your own version of one
                    of these templates, you can copy the text and create one in
                    'My templates'.
                </Text>
            </IntroductionContainer>
            {isLoading && <Spinner />}
            {isError && (
                <Feedback title="Unable to fetch templates" colour="error">
                    Please refresh the page to try again.
                </Feedback>
            )}
            {!isLoading && !isError && (
                <AccumailTemplateList
                    tableTitle="Accurx Templates"
                    data={data}
                    hasActions={false}
                />
            )}
        </>
    );
};
