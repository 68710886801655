import { useAuth } from "@accurx/auth";
import { Ds } from "@accurx/design";
import { useCustomIntercomLauncher } from "@accurx/navigation";

import { HelpPopover } from "./HelpPopover/HelpPopover";
import { NavAccurxLogo } from "./NavAccurxLogo";
import { StyledPrimaryNavigation } from "./UnifiedPrimaryNavigation.styles";
import { SimpleWorkspaceMenu } from "./WorkspaceMenu/SimpleWorkspaceMenu";

/**
 * The simplified navigation might be used whilst logged out, so we need to be
 * careful with assumptions regarding user or workspace status
 */
export const UnifiedSimplePrimaryNavigation = () => {
    const { user } = useAuth();
    useCustomIntercomLauncher();

    return (
        <StyledPrimaryNavigation data-testid="simple-primary-nav">
            <Ds.Flex justifyContent="space-between" alignItems="center">
                <NavAccurxLogo />
                <Ds.Flex justifyContent="flex-end" gap="1">
                    {user.isLoggedIn && <SimpleWorkspaceMenu />}
                    <HelpPopover />
                </Ds.Flex>
            </Ds.Flex>
        </StyledPrimaryNavigation>
    );
};
