import React, { useMemo, useState } from "react";

import {
    OrganisationSetupUserViewModel,
    UserRole,
    UserStatus,
} from "@accurx/api/portal";
import { useCurrentUser, useCurrentWorkspace } from "@accurx/auth";
import {
    Feedback,
    Icon,
    Popover,
    PopoverContent,
    PopoverItemButton,
    PopoverTrigger,
} from "@accurx/design";
import {
    isArchivedWorkspace,
    useLeaveWorkspaceMutation,
    useUpdateUserRoleMutation,
    useWorkspaceUsersQuery,
} from "@accurx/workspace-management";
import { toast } from "react-toastify";

import { trackUserManagementAction } from "app/analytics/FlemingAnalytics";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";

import { ArchiveModal } from "../ConfirmationModal/ArchiveModal";
import { ConfirmationModal } from "../ConfirmationModal/ConfirmationModal";
import { UserCard } from "../UserCard/UserCard";
import {
    canLeaveWorkspace,
    isRoleChangeAllowed,
    isStatusChangeAllowed,
} from "../utils/permissions";
import { showErrorToast, showSuccessToast } from "../utils/toasts";
import {
    StyledUnderlinedText,
    StyledUserActionsContainer,
} from "./ApprovedUserCard.styles";
import { useLeaveWorkspaceRedirect } from "./useLeaveWorkspaceRedirect";

type UserManagementAction = {
    label: string;
    onClick: () => void;
};

const getUserName = (user: OrganisationSetupUserViewModel) => {
    return user.name || user.email || "";
};

export enum OrgUserRole {
    Member, // 0
    Admin, // 1
    Visitor, // 2
}

export const OrgUserRoleLabel = (newRole?: OrgUserRole | UserRole): string => {
    switch (newRole) {
        case OrgUserRole.Member:
            return "Member";
        case OrgUserRole.Admin:
            return "Admin";
        case OrgUserRole.Visitor:
            return "Visitor";
        default:
            return "";
    }
};

export const ApprovedUserCard = ({
    user,
}: {
    user: OrganisationSetupUserViewModel;
}) => {
    const { user: currentUser } = useCurrentUser();
    const workspace = useCurrentWorkspace();

    const { orgId: workspaceId } = workspace;

    const { data: { hasApprovalRights = false, approvedUsers = [] } = {} } =
        useWorkspaceUsersQuery({ workspaceId });

    const [showPopover, setShowPopover] = useState(false);
    const [confirmationModal, setConfirmationModal] =
        useState<React.ReactNode>(null);

    const handleCloseModal = () => setConfirmationModal(null);

    const actions = useMemo<UserManagementAction[]>(() => {
        const actionsArray: UserManagementAction[] = [];

        if (hasApprovalRights && !isArchivedWorkspace(workspace)) {
            const username = getUserName(user);

            if (isRoleChangeAllowed(user, UserRole.Member)) {
                actionsArray.push({
                    label: "Remove admin role",
                    onClick: () =>
                        setConfirmationModal(
                            <RemoveAdminModal
                                name={username}
                                userId={user.userId}
                                onCloseModal={handleCloseModal}
                            />,
                        ),
                });
            }
            if (isRoleChangeAllowed(user, UserRole.Admin)) {
                actionsArray.push({
                    label: "Make user an admin",
                    onClick: () =>
                        setConfirmationModal(
                            <MakeAdminModal
                                name={username}
                                userId={user.userId}
                                onCloseModal={handleCloseModal}
                            />,
                        ),
                });
            }
            if (
                isStatusChangeAllowed(user, UserStatus.Archived) &&
                String(user.userId) !== currentUser.accuRxUserId
            ) {
                actionsArray.push({
                    label: "Archive from workspace",
                    onClick: () =>
                        setConfirmationModal(
                            <ArchiveModal
                                name={username}
                                userId={user.userId}
                                onCloseModal={handleCloseModal}
                            />,
                        ),
                });
            }
        }

        if (String(user.userId) === currentUser.accuRxUserId) {
            const handleLeaveWorkspace = () => {
                if (canLeaveWorkspace(user, approvedUsers)) {
                    setConfirmationModal(
                        <LeaveWorkspaceModal onCloseModal={handleCloseModal} />,
                    );
                } else {
                    setShowPopover(false);
                    toast(
                        <Feedback colour="error" title="There is a problem">
                            There must be at least 1 admin in the workspace. You
                            must give another user the admin role first.
                        </Feedback>,
                    );
                }
            };
            actionsArray.push({
                label: "Leave workspace",
                onClick: handleLeaveWorkspace,
            });
        }

        return actionsArray;
    }, [user, hasApprovalRights, currentUser, approvedUsers, workspace]);

    return (
        <UserCard name={user.name} email={user.email || ""}>
            <div>
                <span>
                    {OrgUserRoleLabel(user.userRoleState?.currentValue)}
                </span>
                <StyledUserActionsContainer>
                    {actions.length > 0 && (
                        <Popover
                            open={showPopover}
                            onOpenChange={setShowPopover}
                        >
                            <PopoverTrigger>
                                <StyledUnderlinedText>
                                    Actions
                                </StyledUnderlinedText>
                                <Icon
                                    colour="blue"
                                    name="Arrow"
                                    rotation="down"
                                    size={3}
                                    theme="Line"
                                />
                            </PopoverTrigger>
                            <PopoverContent align="end" side="bottom">
                                {actions.map(({ label, onClick }) => (
                                    <PopoverItemButton
                                        key={label}
                                        onClick={onClick}
                                    >
                                        {label}
                                    </PopoverItemButton>
                                ))}
                            </PopoverContent>
                        </Popover>
                    )}
                </StyledUserActionsContainer>
            </div>
            {confirmationModal}
        </UserCard>
    );
};

const RemoveAdminModal = ({
    name,
    userId,
    onCloseModal,
}: {
    name: string;
    userId: number;
    onCloseModal: () => void;
}) => {
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    const { orgId: workspaceId } = useCurrentWorkspace();

    const { refetch: refetchUsers } = useWorkspaceUsersQuery({ workspaceId });

    const { mutate: mutateRole } = useUpdateUserRoleMutation({
        onSuccess: async () => {
            onCloseModal();
            showSuccessToast(`${name} is no longer an admin`);
            trackUserManagementAction({
                ...analyticsLoggedInProps,
                targetUserId: userId,
                actionSelected: "RemoveAdmin",
            });
            await refetchUsers();
        },
        onError: () => showErrorToast(),
    });

    return (
        <ConfirmationModal
            open
            onOpenChange={onCloseModal}
            onConfirm={() =>
                mutateRole({ workspaceId, userId, role: UserRole.Member })
            }
            headerText="Remove admin role"
            confirmText="Remove admin role"
        >
            {name} will no longer be able to archive users or make other users
            admins.
        </ConfirmationModal>
    );
};

const MakeAdminModal = ({
    name,
    userId,
    onCloseModal,
}: {
    name: string;
    userId: number;
    onCloseModal: () => void;
}) => {
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    const { orgId: workspaceId } = useCurrentWorkspace();

    const { refetch: refetchUsers } = useWorkspaceUsersQuery({ workspaceId });

    const { mutate: mutateRole } = useUpdateUserRoleMutation({
        onSuccess: async () => {
            onCloseModal();
            showSuccessToast(`${name} is now an admin`);
            trackUserManagementAction({
                ...analyticsLoggedInProps,
                targetUserId: userId,
                actionSelected: "MakeAdmin",
            });
            await refetchUsers();
        },
        onError: () => showErrorToast(),
    });

    return (
        <ConfirmationModal
            open
            onOpenChange={onCloseModal}
            onConfirm={() =>
                mutateRole({ workspaceId, userId, role: UserRole.Admin })
            }
            headerText="Make user an admin"
            confirmText="Make admin"
        >
            This will grant {name} admin privileges. They will be able to
            archive users and make other users admins.
        </ConfirmationModal>
    );
};

const LeaveWorkspaceModal = ({
    onCloseModal,
}: {
    onCloseModal: () => void;
}) => {
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();
    const { handleRedirect } = useLeaveWorkspaceRedirect();

    const { user, refetch: refetchUser } = useCurrentUser();
    const currentWorkspace = useCurrentWorkspace();

    const { orgId: workspaceId, organisationName } = currentWorkspace;

    const { mutate } = useLeaveWorkspaceMutation({
        onSuccess: async () => {
            showSuccessToast(`You have left ${organisationName}`);
            trackUserManagementAction({
                ...analyticsLoggedInProps,
                targetUserId: Number(user.accuRxUserId),
                actionSelected: "LeaveWorkspace",
            });
            handleRedirect();
            await refetchUser();
        },
        onError: () => showErrorToast(),
    });

    return (
        <ConfirmationModal
            open
            onOpenChange={onCloseModal}
            onConfirm={() => mutate({ workspaceId })}
            headerText="Leave workspace"
            confirmText="Leave workspace"
        >
            You will no longer have access to this workspace, including any
            messages you have sent from it. You can still rejoin later.
        </ConfirmationModal>
    );
};
