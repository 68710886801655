import { ChangeEvent, useMemo, useState } from "react";

import { useCurrentWorkspace } from "@accurx/auth";
import { Ds, Input, Tokens } from "@accurx/design";
import {
    StyledModalSearchPanel as ModalSearchPanel,
    StyledClearButton,
    StyledFormFieldWrapper,
    StyledModalBody,
} from "@accurx/navigation";
import { useDirectory } from "domains/directory/context/DirectoryProvider";
import { useDirectorySearchQuery } from "domains/directory/hooks/queries/useDirectorySearchQuery";
import _debounce from "lodash/debounce";
import styled from "styled-components";

import { DirectoryContactDetails } from "./DirectoryContactDetails/DirectoryContactDetails";
import { FrequentContacts } from "./FrequentContacts/FrequentContacts";
import { LocalContacts } from "./LocalContacts/LocalContacts";
import { SearchResults } from "./SearchResults/SearchResults";

export const DirectorySearch = () => {
    const { view, setView, selectedContactId, setSelectedContactId } =
        useDirectory();
    const { orgId: workspaceId } = useCurrentWorkspace();

    const [searchTerm, setSearchTerm] = useState("");
    const [searchTermDebounced, setSearchTermDebounced] = useState(searchTerm);

    const { data: contacts = [], isLoading } = useDirectorySearchQuery({
        workspaceId,
        searchTerm: searchTermDebounced,
    });

    const debounceSearch = useMemo(
        () => _debounce(setSearchTermDebounced, 500),
        [],
    );

    const isSearch = searchTermDebounced.length > 0;

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
        debounceSearch(e.target.value);
    };

    const handleInputClear = () => {
        setSearchTerm("");
        setSearchTermDebounced("");
    };

    const handleContactSelected = (contactId?: string) => {
        setSelectedContactId(contactId);
        setView("ContactDetails");
    };

    return (
        <StyledContainer $isSecondPanelOpen={view === "ContactDetails"}>
            <StyledModalSearchPanel>
                <StyledForm>
                    <StyledFormFieldWrapper
                        label="Name, organisation or specialty"
                        labelProps={{ htmlFor: "directory-input" }}
                    >
                        <Input
                            autoFocus
                            type="text"
                            id="directory-input"
                            value={searchTerm}
                            onChange={handleInputChange}
                            required
                        />
                        {searchTerm && (
                            <StyledClearButton
                                type="button"
                                onClick={handleInputClear}
                            >
                                <Ds.Text weight="bold">Clear</Ds.Text>
                            </StyledClearButton>
                        )}
                    </StyledFormFieldWrapper>
                </StyledForm>
                <StyledContactsWrapper>
                    <Ds.RadioGroup
                        value={selectedContactId}
                        onValueChange={handleContactSelected}
                    >
                        {isSearch ? (
                            <SearchResults
                                isLoading={isLoading}
                                contacts={contacts}
                            />
                        ) : (
                            <>
                                <FrequentContacts />
                                <StyledDivider />
                                <LocalContacts
                                    isLoading={isLoading}
                                    contacts={contacts}
                                />
                            </>
                        )}
                    </Ds.RadioGroup>
                </StyledContactsWrapper>
            </StyledModalSearchPanel>
            <DirectoryContactDetails />
        </StyledContainer>
    );
};

// On mobile breakpoints we only show one panel at a time, so this controls
// what panel is shown/hidden
const StyledContainer = styled(StyledModalBody)<{
    $isSecondPanelOpen: boolean;
}>`
    @media (max-width: ${Tokens.BREAKPOINTS.md}) {
        &
            > :nth-child(
                ${({ $isSecondPanelOpen }) => ($isSecondPanelOpen ? "1" : "2")}
            ) {
            display: none;
        }
    }
`;

const StyledModalSearchPanel = styled(ModalSearchPanel)`
    padding: 0;
`;

const StyledForm = styled.form`
    padding: 1rem;
`;

const StyledContactsWrapper = styled(Ds.Flex)`
    flex-direction: column;
    padding: 0 1rem 1rem 1rem;

    @media (min-width: ${Tokens.BREAKPOINTS.md}) {
        overflow: auto;
    }
`;

const StyledDivider = styled.hr`
    margin-top: 0.5rem;
    margin-bottom: 1rem;
`;
