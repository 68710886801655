import {
    BaseAppointmentRemindersResponse,
    CreateAppointmentReminderRequest,
    GetAppointmentRemindersResponse,
} from "@accurx/api/appointment";
import { HttpError, httpClient2 } from "@accurx/shared";
import { useMutation, useQueryClient } from "@tanstack/react-query";

type CreateReminderParameters = {
    workspaceId: string;
};

export const useCreateReminder = ({
    workspaceId,
}: CreateReminderParameters) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationKey: [
            "AppointmentReminders",
            "CreateReminder",
            { workspaceId },
        ],
        mutationFn: async (reminder: CreateAppointmentReminderRequest) => {
            try {
                return await httpClient2
                    .post({
                        path: `/api/appointments/AppointmentReminders/${workspaceId}/create`,
                        json: reminder,
                    })
                    .json<BaseAppointmentRemindersResponse>();
            } catch (error) {
                let message = "Could not create appointment reminder";
                if (error instanceof HttpError) {
                    type ExpectedErrorResponse =
                        | { message: string }
                        | undefined;
                    const errorResponse =
                        (await error.response.json()) as ExpectedErrorResponse;
                    if (errorResponse?.message) {
                        message = errorResponse.message;
                    }
                }

                throw new Error(message);
            }
        },
        onSuccess(data) {
            queryClient.setQueryData<GetAppointmentRemindersResponse>(
                [
                    "AppointmentReminders",
                    "RemindersAndFilterOptions",
                    { workspaceId },
                ],
                (previousData) => {
                    if (!previousData) {
                        return;
                    }

                    return {
                        ...previousData,
                        appointmentReminders: data.appointmentReminders,
                    };
                },
            );
        },
    });
};
