import { useState } from "react";

import { Ds, Feedback } from "@accurx/design";
import { useBrowserEnvironment } from "@accurx/native";
import { SkeletonLoader } from "@accurx/navigation";
import { ArchivedWorkspaceHiddenItemWrapper } from "@accurx/workspace-management";
import { useGetFullEmrPatientDetails } from "domains/patient/hooks/mutations/useGetFullEmrPatientDetails";
import {
    PatientSearchProductOrigin,
    PatientSearchResult,
} from "domains/patient/types";
import { getDisabledMessage } from "domains/patient/utils/patient.helpers";
import styled from "styled-components";

import { CurrentEmrPatientCard } from "../CurrentEmrPatientCard/CurrentEmrPatientCard";
import { PatientCard } from "../PatientCard/PatientCard";
import { PatientCardSkeleton } from "../PatientCard/PatientCardSkeleton";
import { RecentPatients } from "../RecentPatients/RecentPatients";

export type PatientResultsProps = {
    onSelect: (patient: PatientSearchResult | null, error?: Error) => void;
    onToggleFilter?: (state: boolean) => void;
    onClickTestPatient?: () => void;
    results?: PatientSearchResult[] | null;
    isLoading?: boolean;
    cardRole?: "button" | "radio";
    productOrigin?: PatientSearchProductOrigin;
};

export const PatientResults = ({
    onSelect,
    onToggleFilter,
    onClickTestPatient,
    results,
    isLoading = false,
    cardRole = "button",
    productOrigin = "PatientSearch",
}: PatientResultsProps) => {
    const browserEnv = useBrowserEnvironment();
    const [selectedPatient, setSelectedPatient] =
        useState<PatientSearchResult | null>(null);
    const {
        mutate: getFullEmrPatientDetails,
        isLoading: isLoadingFullEmrDetails,
    } = useGetFullEmrPatientDetails({
        onError: (error) => {
            setSelectedPatient(null);
            onSelect(null, error);
        },
        onSuccess: (data) => {
            setSelectedPatient(null);
            onSelect(data);
        },
    });

    // Native integrations don't surface vital contact details from search results,
    // so a full patient look-up is required on selection of patient.
    const handleSelect = (
        patient: PatientSearchResult | null,
        error?: Error,
    ) => {
        if (!patient || error || browserEnv !== "WebView") {
            onSelect(patient, error);
            return;
        }
        setSelectedPatient(patient);
        getFullEmrPatientDetails({ externalIds: patient.externalIds });
    };

    const isPatientSelected = (patient: PatientSearchResult) => {
        return (
            selectedPatient?.externalIds?.[0].value ===
            patient.externalIds?.[0].value
        );
    };

    const isPatientCardLoading = (patient: PatientSearchResult) =>
        isPatientSelected(patient) && isLoadingFullEmrDetails;

    const isPatientCardDisabled = (patient: PatientSearchResult) => {
        if (!isPatientSelected(patient) && isLoadingFullEmrDetails) {
            return true;
        }
        return Boolean(getDisabledMessage({ productOrigin, patient }));
    };

    if (isLoading) {
        return (
            <StyledPatientResults role="status">
                <Ds.Box mt="2">
                    <SkeletonLoader width="60px" />
                </Ds.Box>
                <Ds.Box mt="0.75">
                    <PatientCardSkeleton />
                </Ds.Box>
            </StyledPatientResults>
        );
    }

    if (!results) {
        return (
            <ArchivedWorkspaceHiddenItemWrapper>
                {browserEnv === "WebView" ? (
                    <CurrentEmrPatientCard onSelect={onSelect} />
                ) : (
                    <RecentPatients
                        onSelect={onSelect}
                        onToggleFilter={onToggleFilter}
                        onClickTestPatient={onClickTestPatient}
                        cardRole={cardRole}
                        productOrigin={productOrigin}
                    />
                )}
            </ArchivedWorkspaceHiddenItemWrapper>
        );
    }

    if (results.length === 0) {
        return (
            <StyledPatientResults>
                <Feedback colour="warning" title="No results found">
                    Try searching again with different details
                </Feedback>
            </StyledPatientResults>
        );
    }

    return (
        <StyledPatientResults>
            <StyledResultCountText weight="bold" color="zinc">
                {results.length} result{results.length > 1 ? "s" : ""}
            </StyledResultCountText>
            {results.map((patient, index) =>
                cardRole === "button" ? (
                    <PatientCard
                        key={`${patient.nhsNumber ?? "unknown"}-${index}`}
                        patient={patient}
                        isLoading={isPatientCardLoading(patient)}
                        isDisabled={isPatientCardDisabled(patient)}
                        onClick={() => handleSelect(patient)}
                        disabledMessage={getDisabledMessage({
                            productOrigin,
                            patient,
                        })}
                    />
                ) : (
                    <StyledRadioGroup
                        key={patient.nhsNumber}
                        defaultValue={String(patient.nhsNumber)}
                    >
                        <PatientCard as="radio" patient={patient} />
                    </StyledRadioGroup>
                ),
            )}
        </StyledPatientResults>
    );
};

const StyledResultCountText = styled(Ds.Text)`
    margin-top: 14px; // Additional padding to match "Search recents" button padding
`;

const StyledPatientResults = styled(Ds.Flex).attrs({
    flexDirection: "column",
    gap: "1",
})`
    flex: 1;
    padding: 5px;
`;

const StyledRadioGroup = styled(Ds.RadioGroup)`
    display: flex;
    align-items: flex-start;
`;
