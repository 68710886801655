import {
    PatientNameProps,
    PatientName as StandardPatientName,
} from "domains/patient/components/PatientName/PatientName";

export const PatientName = ({
    name,
    ageYear,
    gender,
}: Omit<PatientNameProps, "textProps">) => (
    <StandardPatientName
        name={name}
        ageYear={ageYear}
        gender={gender}
        textProps={{
            size: "large",
        }}
    />
);
