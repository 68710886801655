import { Ds, Tokens } from "@accurx/design";
import { SkeletonLoader } from "@accurx/navigation";
import styled from "styled-components";

export const ContactListSkeleton = ({
    role,
    count,
    showGroupHeadings,
}: {
    role?: string;
    count: number;
    showGroupHeadings?: boolean;
}) => (
    <Ds.Flex flexDirection="column" role={role}>
        {showGroupHeadings && (
            <Ds.Box mb="1">
                <SkeletonText width="100px" />
            </Ds.Box>
        )}
        {Array.from(Array(count)).map((_, i) => (
            <ContactSelectCardSkeleton key={i} />
        ))}
    </Ds.Flex>
);

export const ContactSelectCardSkeleton = ({ role }: { role?: string }) => (
    <StyledSkeletonCard role={role}>
        <StyledSkeletonIcon />
        <Ds.Flex flexDirection="column" gap="1.5">
            <SkeletonText width="200px" />
            <SkeletonText width="300px" />
        </Ds.Flex>
    </StyledSkeletonCard>
);

const StyledSkeletonCard = styled(Ds.Flex)`
    align-items: center;
    gap: ${Tokens.SIZES[1.5]};
    padding: 14px;
`;

const StyledSkeletonIcon = styled(SkeletonLoader)`
    height: 24px;
    width: 24px;
`;

export const SkeletonText = styled(SkeletonLoader)`
    margin: ${Tokens.SIZES[0.25]} 0;
`;
