import React from "react";

import { AppointmentRemindersRoutes } from "@accurx/appointment-reminders";
import {
    AppointmentsManualUploadFeatureFlags,
    AppointmentsManualUploadRoutes,
} from "@accurx/appointments-manual-upload";
import {
    FeatureName,
    WorkspaceFeatureGuard,
    useCurrentWorkspace,
    useFeatureFlag,
} from "@accurx/auth";
import {
    WORKSPACE_MANAGEMENT_ROUTES,
    hasAccurxInstalled,
    isArchivedWorkspace,
    isMyWorkspace,
} from "@accurx/workspace-management";
import { Redirect, Switch, generatePath } from "react-router-dom";

import { SimpleRoute } from "app/AppRoute";
import { FeatureGuard } from "app/FeatureGuard";
import DeliveryReceipts from "app/account/DeliveryReceipts/DeliveryReceipts";
import { OrganisationResources } from "app/account/OrganisationResources/OrganisationResources";
import { AccubookRoutesSection } from "app/accubook/AccubookRoutesSection";
import { AccubookFeatureFlags } from "app/accubook/utils/isAccubookEnabled";
import { BatchMessageOverviewPage } from "app/batchMessage/shared/BatchMessageOverviewPage";
import { Container } from "app/layout/Container";
import { CopyTemplatePage } from "app/messageTemplates/CopyTemplatePage";
import { CreateTemplatePage } from "app/messageTemplates/CreateTemplatePage";
import { EditTemplatePage } from "app/messageTemplates/EditTemplatePage";
import { PatientTriageFeatureFlags } from "app/organisations/utils";
import { PatientTriageSettingsPage } from "app/patientTriage/pages/PatientTriageSettingsPage";
import { SetupPatientTriagePage } from "app/patientTriage/pages/SetupPatientTriagePage";
import { WhatIsPatientTriagePage } from "app/patientTriage/pages/WhatIsPatientTriagePage";
import { FloreyBuilder } from "app/practices/floreyBuilder/FloreyBuilder";
import { ManageTemplatesOverview } from "app/practices/manageTemplates";
import MedicalRecord from "app/recordView/MedicalRecordComponent";
import MedicalRecords from "app/recordView/MedicalRecordsComponent";
import { RequestMedicalRecord } from "app/recordView/RequestMedicalRecord";
import { RecordRequestsPage } from "app/recordView/pages/RecordRequestsPage";
import { ReportingRoutes } from "app/reporting/ReportingRoutes";
import { SmsStatusPage } from "app/smsStatus/SmsStatusPage";
import { EditWorkspacePage } from "app/workspace/pages/EditWorkspace/EditWorkspacePage";
import { AvailableFeaturesPage } from "app/workspaceSettings/pages/AvailableFeatures/AvailableFeaturesPage";
import { GeneralWorkspaceSettings } from "app/workspaceSettings/pages/GeneralWorkspaceSettings/GeneralWorkspaceSettings";
import { InstalledComputersPage } from "app/workspaceSettings/pages/InstalledComputers/InstalledComputersPage";
import { SmsSettingsPage } from "app/workspaceSettings/pages/SmsSettings/SmsSettingsPage";
import { TeamNotificationsPage } from "app/workspaceSettings/pages/TeamNotifications/TeamNotificationsPage";
import { InviteUserPage } from "app/workspaceSettings/pages/UserManagement/InviteUserPage";
import { UserManagementPage } from "app/workspaceSettings/pages/UserManagement/UserManagementPage";
import { ROUTES_EXTENSION, ROUTES_WORKSPACE } from "shared/Routes";

import NotFound from "../../../NotFound";
import { GuardedRoute } from "../GuardedRoute";

export const WorkspaceSettingsRoutesSection = () => {
    const workspace = useCurrentWorkspace();

    const isArchived = isArchivedWorkspace(workspace);

    const isFloreyBuilderEnabled = useFeatureFlag(FeatureName.FloreyBuilder);

    // New delivery receipts page contains collaborative features so user must have correct permissions
    const isEnhancedDeliveryReceiptsEnabled = !isMyWorkspace(workspace);

    const accurxInstalled = hasAccurxInstalled(workspace);

    return (
        <Switch>
            <SimpleRoute path={ROUTES_WORKSPACE.workspaceGeneralSettings} exact>
                <GeneralWorkspaceSettings />
            </SimpleRoute>
            <GuardedRoute
                path={ROUTES_WORKSPACE.workspaceEdit}
                exact
                requires={{
                    authenticationStatus: "LoggedInWith2FA",
                    onboarded: true,
                }}
            >
                <FeatureGuard all={[FeatureName.FlexibleWorkspace]}>
                    <EditWorkspacePage />
                </FeatureGuard>
            </GuardedRoute>
            {workspace.settings.isApprovedUser && (
                <SimpleRoute
                    path={ROUTES_WORKSPACE.workspaceUserManagement}
                    exact
                >
                    <UserManagementPage />
                </SimpleRoute>
            )}
            {workspace.settings.isApprovedUser && (
                <SimpleRoute path={WORKSPACE_MANAGEMENT_ROUTES.inviteUsers}>
                    <InviteUserPage />
                </SimpleRoute>
            )}
            <SimpleRoute path={ROUTES_WORKSPACE.workspaceAvailableFeatures}>
                <AvailableFeaturesPage />
            </SimpleRoute>
            <SimpleRoute path={ROUTES_WORKSPACE.workspaceTeamNotifications}>
                <TeamNotificationsPage />
            </SimpleRoute>
            <SimpleRoute path={ROUTES_WORKSPACE.workspaceSmsSettings}>
                <SmsSettingsPage />
            </SimpleRoute>
            {accurxInstalled && (
                <SimpleRoute
                    path={ROUTES_WORKSPACE.workspaceInstalledComputers}
                    exact
                >
                    <InstalledComputersPage />
                </SimpleRoute>
            )}

            <SimpleRoute
                path={ROUTES_WORKSPACE.workspaceDeliveryReceipts}
                exact
            >
                {isEnhancedDeliveryReceiptsEnabled ? (
                    <SmsStatusPage />
                ) : (
                    <DeliveryReceipts />
                )}
            </SimpleRoute>
            <SimpleRoute path={ROUTES_WORKSPACE.workspaceBatchMessages}>
                <BatchMessageOverviewPage />
            </SimpleRoute>
            <SimpleRoute path="/w/:workspaceId/settings/shared-templates" exact>
                <Redirect
                    to={{
                        pathname: generatePath(
                            ROUTES_WORKSPACE.workspaceTemplatesOverview,
                            { workspaceId: workspace.orgId },
                        ),
                        search: "?tab=OrganisationTemplates",
                    }}
                />
            </SimpleRoute>
            <SimpleRoute
                path="/w/:workspaceId/settings/personal-templates"
                exact
            >
                <Redirect
                    to={{
                        pathname: generatePath(
                            ROUTES_WORKSPACE.workspaceTemplatesOverview,
                            { workspaceId: workspace.orgId },
                        ),
                        search: "?tab=UserTemplates",
                    }}
                />
            </SimpleRoute>
            <SimpleRoute
                path={ROUTES_WORKSPACE.workspaceTemplatesOverview}
                exact
            >
                <ManageTemplatesOverview />
            </SimpleRoute>
            <SimpleRoute path={ROUTES_WORKSPACE.workspaceCreateTemplate} exact>
                <CreateTemplatePage />
            </SimpleRoute>
            <SimpleRoute path={ROUTES_WORKSPACE.workspaceEditTemplate} exact>
                <EditTemplatePage />
            </SimpleRoute>
            <SimpleRoute path={ROUTES_WORKSPACE.workspaceCopyTemplate} exact>
                <CopyTemplatePage />
            </SimpleRoute>
            <SimpleRoute path={ROUTES_WORKSPACE.workspaceQuestionnaireBuilder}>
                {isFloreyBuilderEnabled && <FloreyBuilder />}
            </SimpleRoute>
            <SimpleRoute path={ROUTES_WORKSPACE.workspaceResources} exact>
                <FeatureGuard all={[FeatureName.Wayfinder]}>
                    <Container>
                        <OrganisationResources />
                    </Container>
                </FeatureGuard>
            </SimpleRoute>

            {accurxInstalled && !isArchived && (
                <SimpleRoute
                    path={ROUTES_WORKSPACE.workspaceMedicalRecords}
                    exact
                >
                    <RecordRequestsPage />
                </SimpleRoute>
            )}

            {!accurxInstalled && !isArchived && (
                <GuardedRoute
                    path={ROUTES_WORKSPACE.workspaceMedicalRecords}
                    exact
                    requires={{
                        authenticationStatus: "LoggedInWith2FA",
                        onboarded: true,
                    }}
                >
                    <FeatureGuard all={[FeatureName.RecordAccessBeta]}>
                        <Container>
                            <MedicalRecords />
                        </Container>
                    </FeatureGuard>
                </GuardedRoute>
            )}
            {!isArchived && (
                <GuardedRoute
                    path={`${ROUTES_WORKSPACE.workspaceMedicalRecords}${ROUTES_EXTENSION.remoteRecordView}`}
                    exact
                    requires={{
                        authenticationStatus: "LoggedInWith2FA",
                        onboarded: true,
                    }}
                >
                    <FeatureGuard all={[FeatureName.RecordAccessBeta]}>
                        <Container>
                            <RequestMedicalRecord />
                        </Container>
                    </FeatureGuard>
                </GuardedRoute>
            )}
            <GuardedRoute
                path={[ROUTES_WORKSPACE.workspaceAppointmentReminders]}
                requires={{
                    authenticationStatus: "LoggedIn",
                    onboarded: true,
                }}
            >
                <WorkspaceFeatureGuard
                    all={[FeatureName.AppointmentRemindersAlpha]}
                >
                    <AppointmentRemindersRoutes />
                </WorkspaceFeatureGuard>
            </GuardedRoute>

            <GuardedRoute
                path={[ROUTES_WORKSPACE.workspaceAppointmentsUpload]}
                requires={{
                    authenticationStatus: "LoggedIn",
                    onboarded: true,
                }}
            >
                <WorkspaceFeatureGuard
                    all={AppointmentsManualUploadFeatureFlags}
                >
                    <AppointmentsManualUploadRoutes />
                </WorkspaceFeatureGuard>
            </GuardedRoute>

            <SimpleRoute path={ROUTES_WORKSPACE.workspacePatientTriage} exact>
                <WorkspaceFeatureGuard all={PatientTriageFeatureFlags}>
                    <WhatIsPatientTriagePage />
                </WorkspaceFeatureGuard>
            </SimpleRoute>
            <SimpleRoute path={ROUTES_WORKSPACE.workspacePatientTriageSettings}>
                <WorkspaceFeatureGuard all={PatientTriageFeatureFlags}>
                    <PatientTriageSettingsPage />
                </WorkspaceFeatureGuard>
            </SimpleRoute>
            <SimpleRoute path={ROUTES_WORKSPACE.workspacePatientTriageSetup}>
                <WorkspaceFeatureGuard all={PatientTriageFeatureFlags}>
                    <SetupPatientTriagePage />
                </WorkspaceFeatureGuard>
            </SimpleRoute>

            {!isArchived && (
                <GuardedRoute
                    path={`${ROUTES_WORKSPACE.workspaceMedicalRecords}${ROUTES_EXTENSION.remoteRecordViewMedicalRecord}`}
                    exact
                    requires={{
                        authenticationStatus: "LoggedInWith2FA",
                        onboarded: true,
                    }}
                >
                    <FeatureGuard all={[FeatureName.RecordAccessBeta]}>
                        <Container>
                            <MedicalRecord />
                        </Container>
                    </FeatureGuard>
                </GuardedRoute>
            )}
            {workspace.settings.isApprovedUser && (
                <SimpleRoute path={ROUTES_WORKSPACE.reporting}>
                    <ReportingRoutes />
                </SimpleRoute>
            )}
            <SimpleRoute path={ROUTES_WORKSPACE.accubook}>
                <WorkspaceFeatureGuard any={AccubookFeatureFlags}>
                    <AccubookRoutesSection />
                </WorkspaceFeatureGuard>
            </SimpleRoute>
            <SimpleRoute path="*">
                <NotFound />
            </SimpleRoute>
        </Switch>
    );
};
